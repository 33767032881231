import React, { useState } from "react"
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap"
import { Formik } from "formik"
import { userRoleConstants } from "@iso/constants/user"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"
import AuthService from "@iso/services/AuthService"
import { SignUpFormValidator } from "./validator"
import { setCurrentUser } from "@iso/actions/User"
import './styles.scss'
import { IconButton } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import InputAdornment from '@material-ui/core/InputAdornment'
import IntlMessage from "@iso/components/intlMessage";
import TextField from '@material-ui/core/TextField';
import WelcomePrayerCloudActions from "@iso/actions/WelcomePrayerCloudActions"
import confirmRequestModalActions from "@iso/actions/confirmRequestModal"
import { isTablet } from "react-device-detect"
import gtag from 'ga-gtag';
import { recommended_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

function FormSignUp(props) {
  const {handleSwitchSignIn, title, step } = props
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [errorAcceptTerms, setErrorAcceptTerms] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  let initValue = {
    full_name: "",
    email: "",
    password: "",
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch()

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const switchPrayerCloudModal = () => {
    // SignUp header
    if(title === "user.confirm.form.receive"){
      dispatch(
        WelcomePrayerCloudActions.openModal({
          type: WelcomePrayerCloudActions.SHOW_PRAYER_CLOUD_MODAL,
          payload :{
            isOpen: true,
            isHeader: true,
          }
        })
      )
    }
    // SignUp landing
    else{
      dispatch(
        WelcomePrayerCloudActions.openModal({
          type: WelcomePrayerCloudActions.SHOW_PRAYER_CLOUD_MODAL,
          payload :{
            isOpen: true,
            isSubcribe: false,
          }
        })
      )
    }
  }

  const handleSubmit =  (values, bag) => {
    gtag('event', recommended_events.SIGN_UP, {
      method : 'Email'
    });
    ReactPixel.track(fb_pixel_event.recommended_events.SIGN_UP, {
      method : 'Email'
    });
    const time_zone = -(new Date().getTimezoneOffset() / 60);
    AuthService.registerApi({full_name: values.full_name || values.email, email: values.email, password: values.password, time_zone: time_zone}).then((res) => {
      if(!res){
        bag.setErrors({ password: "Something went wrong. Please try again later" })
        bag.setSubmitting(false)
      }
      const { code, data, errors } = res.data;

      if (code === 200 && data.role === userRoleConstants.USER_ROLE) {
        props.setCurrentUser({ ...data, rememberMe: values.remember_me })
        switchPrayerCloudModal();
      } else {
        if (errors.email) {
          bag.setErrors({ email: errors.email[0] })
          bag.setSubmitting(false)
        }

        if (errors.password) {
          bag.setErrors({ password: errors.password[0] })
          bag.setSubmitting(false)
        }
      }
    }).catch((res) => {
      if (!res.errors) {
        bag.setErrors({ password: "Something went wrong. Please try again later" })
        bag.setSubmitting(false)
        return
      }
      if (res.errors.email) {
        bag.setErrors({ email: res.errors.email })
        bag.setSubmitting(false)
      }

      if (res.errors.password) {
        bag.setErrors({ password: res.errors.password })
        bag.setSubmitting(false)
      }
    });
  }

  const handleAcceptTerms = () => {
    setAcceptTerms(!acceptTerms)
  }

  return (
    <Formik
      initialValues={initValue}
      enableReinitialize={false}
      onSubmit={handleSubmit}
      validationSchema={SignUpFormValidator}
    >
      {({ handleSubmit, handleChange, touched, errors }) => (
        <form onSubmit={handleSubmit} noValidate id="sign-up-form">
          <Form.Group as={Row} className="justify-content-center">
            <Col sm={{ span: "12" }} style={{marginBottom:'-7px'}}>
              <TextField
                name="full_name"
                fullWidth={true}
                variant="filled"
                className='my-control custom_field_sign_up_full_name'
                type="text"
                label={
                  <IntlMessage id="public.request.form.fullname.label" />
                }
                onChange={handleChange}
              />
              <ErrorMessageWrapper
                touched={touched}
                errors={errors}
                fieldName="full_name"
                className={`${errors.full_name && 'mb-2'} custom_error_form_sign_up`}
              />
            </Col>

            <Col sm={{ span: "12" }} style={{marginBottom:'-7px'}}>
              <TextField
                name="email"
                fullWidth={true}
                variant="filled"
                className='my-control custom_field_sign_up_email'
                type="text"
                label={
                  <IntlMessage id="public.request.form.email.label" />
                }
                onChange={handleChange}
              />
              <ErrorMessageWrapper
                touched={touched}
                errors={errors}
                fieldName="email"
                className={`${errors.email && 'mb-2'} custom_error_form_sign_up`}
              />
            </Col>


            <Col sm={{ span: "12" }} style={{marginBottom:'-20px'}}  >

            <TextField
              fullWidth={true}
              variant="filled"
              className='my-control custom_field_sign_up_password'
              name="password"
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              label={
                <IntlMessage id="public.request.form.password.label" />
              }

              InputProps={{
              endAdornment:(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password VisibilityOff"
                  onClick={handleToggleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}/>

              <ErrorMessageWrapper
                touched={touched}
                errors={errors}
                fieldName="password"
                className={`${errors.password && 'mb-2'} custom_error_form_sign_up`}
              />
            </Col>
          </Form.Group>

          <div className="buttons text-center" >
            <Button className="hippie-blue-sign-in-button" type="submit" style={{width: '100%'}}>
              Sign Up
            </Button>

          </div>

          <div className="checkbox p-0">

        {
          title === 'user.confirm.subscribe.title' ?
          <>
          <label className ="font-size-14px">
            <span className="box"></span>
            <span className="accept-terms-notice ml-4 ml-sm-3 ">
            <IntlMessage id="user.confirm.form.subscribe.policy1" />
            <br/>
            <span className="hippie-blue-link pointer ml-1 d-inline-block d-md-inline">
            <IntlMessage id="user.confirm.form.subscribe.policy2" /></span>
            <span className="ml-1"><IntlMessage id="user.confirm.form.subscribe.policy3" />
            <span  className="hippie-blue-link pointer ml-1">
              <IntlMessage id="user.confirm.form.subscribe.policy4" /></span>
            </span>
            </span>
          </label>
          {errorAcceptTerms && <p className="px-4 mt-1 text-danger"><IntlMessage id="user.confirm.form.policy5" /></p>}
          </>
          :
          <label className ="font-size-14px">
            <span className="box"></span>
            <span className="accept-terms-notice  ">
            <IntlMessage id="user.confirm.form.policy1" />
            <br/>
            <a href="/terms" target="_blank" className="hippie-blue-link pointer ml-1 d-inline-block d-md-inline">
            <IntlMessage id="user.confirm.form.policy2" /></a>
            <span className="ml-1"><IntlMessage id="user.confirm.form.policy3" />
              <a href="/terms" target="_blank" className="hippie-blue-link pointer ml-1">
              <IntlMessage id="user.confirm.form.policy4" /></a>
            </span>
            </span>
          </label>
        }
      </div>
          <hr className="control-hr"/>
          <div className = {`text-center mt-3  font-size-18px ${title === "user.confirm.subscribe.title" ? 'd-block' : 'd-block d-md-none font-weight-bold'}`}>
           {
             step !== 4 &&
             <>
             <IntlMessage id="user.confirm.form.policy6" />
             <span className ="color-hippie-blue ml-1" onClick={() => handleSwitchSignIn()}>
             {
               title === "user.confirm.subscribe.title" ?
               <IntlMessage id="public.request.form.login_btn_title" />
               :
               <IntlMessage id="public.request.form.signin_btn_title" />
             }
           </span>
           </>
           }
          </div>
        </form>
      )}
    </Formik>
  )
}
const mapStoreToProps = (store) => {
  const { welcomePrayerCloudReducer } = store
  return {isHeader: welcomePrayerCloudReducer.isHeader, isSubcribe: welcomePrayerCloudReducer.isSubcribe}
}


const mapDispatchToProps = {
  setCurrentUser: setCurrentUser,
}

const AddFormSignUp = connect(mapStoreToProps, mapDispatchToProps)(FormSignUp)
export default AddFormSignUp
