import React from "react"
import styled from 'styled-components'
import { TextField } from "@material-ui/core"
import { Formik } from "formik"
import { Col, Form, Modal, Row } from "react-bootstrap"
import { isMobile, isTablet } from 'react-device-detect'
import Button from '@iso/components/AtomicComponents/Button.js'
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import IntlMessage from "@iso/components/intlMessage";
import { TellUsAboutFormValidator } from "./validator"
import AboutService from "@iso/services/AboutService"
import ThankIcon from "@iso/assets/images/thank_icon.png";
import { useHistory } from "react-router"

const CallToActionBtn = styled(Button)`
    width: 100%;
    height: 48px;
    font-family: "BrandonText";
    margin: 0;
    font-size: 16px;

    @media only screen and (min-width: 768px) {
        width: 303px;
    }
`

const TellUsAboutModal = (props) => {
    const history = useHistory()
    const [currentScr, setCurrentScr] = React.useState("form") // form, thank_you
    let initValue = {
        email: "",
        firstName: "",
        lastName: "",
        cityAndCountry: "",
        country: "",
        denomination: "",
        reason: "",
    }

    const returnHomePage = () => {
        history.push("/")
    }

    const handleClose = () => {
        props.setIsOpen(false);
        setCurrentScr("form")
    }

    const handleSubmit = (values) => {

        AboutService.sendTellUsAbout(values)
            .then((res) => {
                console.log("res: ", res)
                if (res.status === 200) {
                    const data = res.data;
                    if (data.status) {
                        // change view thank you
                        setCurrentScr("thank_you")
                    }
                }

            }).catch((res) => {

            });
    }

    return (
        <Modal
            show={props.isOpen}
            onHide={handleClose}
            size='lg'
            id='modalTellUsAbout'
            dialogClassName='modalTellUsAbout'
        >
            {currentScr === "form" ? (
                <React.Fragment>
                    <Modal.Header className='m-header' >
                        <button type="button" className="d-block d-md-none close" style={{ fontSize: '46px' }} onClick={handleClose}>
                            <span aria-hidden="true">×</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className='m-body'>
                        <Modal.Title className='m-title'><IntlMessage id="tellUsAbout.modal.title" /></Modal.Title>
                        <Formik
                            initialValues={initValue}
                            enableReinitialize={false}
                            onSubmit={handleSubmit}
                            validationSchema={TellUsAboutFormValidator}
                        >
                            {({ handleSubmit, handleChange, touched, errors }) => (
                                <form onSubmit={handleSubmit} noValidate className='form-container'>
                                    <Form.Group className="d-block custom_add_form_tell_us_about">
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <div className='left-part'>
                                                    <Row>
                                                        <Col>
                                                            <TextField
                                                                fullWidth={true}
                                                                variant="filled"
                                                                className='my-control'
                                                                name="email"
                                                                type="text"
                                                                onChange={handleChange}
                                                                label={(isMobile || isTablet) ?
                                                                    <IntlMessage id="tellUsAbout.form.field.emailAddress" />
                                                                    :
                                                                    <IntlMessage id="tellUsAbout.form.field.email" />
                                                                }
                                                            />
                                                            {errors.email && touched.email ? (
                                                                <ErrorMessageWrapper
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    fieldName="email"
                                                                    className="font-size-12px min-height_unset"
                                                                />
                                                            ) : null}
                                                        </Col>
                                                    </Row>

                                                    <Row className="mt-20px">
                                                        <Col xs="6" className="pr-6px">
                                                            <TextField
                                                                fullWidth={true}
                                                                variant="filled"
                                                                className='my-control'
                                                                name="firstName"
                                                                type="text"
                                                                onChange={handleChange}
                                                                label={
                                                                    <IntlMessage id="tellUsAbout.form.field.firstName" />
                                                                }
                                                            />

                                                            {errors.firstName && touched.firstName ? (
                                                                <ErrorMessageWrapper
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    fieldName="firstName"
                                                                    className="font-size-12px min-height_unset"
                                                                />
                                                            ) : null}

                                                        </Col>
                                                        <Col xs="6" className="pl-6px">
                                                            <TextField
                                                                fullWidth={true}
                                                                variant="filled"
                                                                className='my-control'
                                                                name="lastName"
                                                                type="text"
                                                                onChange={handleChange}
                                                                label={
                                                                    <IntlMessage id="tellUsAbout.form.field.lastName" />
                                                                }
                                                            />

                                                            {errors.lastName && touched.lastName ? (
                                                                <ErrorMessageWrapper
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    fieldName="lastName"
                                                                    className="font-size-12px min-height_unset"
                                                                />
                                                            ) : null}
                                                        </Col>
                                                    </Row>

                                                    <Row className="mt-20px">
                                                        <Col xs={6} lg={12} className='city-and-country'>
                                                            <TextField
                                                                fullWidth={true}
                                                                variant="filled"
                                                                className='my-control'
                                                                name="cityAndCountry"
                                                                type="text"
                                                                onChange={handleChange}
                                                                label={(isMobile || isTablet) ?
                                                                    <IntlMessage id="tellUsAbout.form.field.city" />
                                                                    :
                                                                    <IntlMessage id="tellUsAbout.form.field.cityAndCountry" />
                                                                }
                                                            />

                                                            {errors.cityAndCountry && touched.cityAndCountry ? (
                                                                <ErrorMessageWrapper
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    fieldName="cityAndCountry"
                                                                    className="font-size-12px min-height_unset"
                                                                />
                                                            ) : null}
                                                        </Col>
                                                        <Col xs={6} lg={12} className='d-block d-lg-none pl-6px'>
                                                            <TextField
                                                                fullWidth={true}
                                                                variant="filled"
                                                                className='my-control'
                                                                name="country"
                                                                type="text"
                                                                onChange={handleChange}
                                                                label={
                                                                    <IntlMessage id="tellUsAbout.form.field.country" />
                                                                }
                                                            />

                                                            {errors.country && touched.country ? (
                                                                <ErrorMessageWrapper
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    fieldName="country"
                                                                    className="font-size-12px min-height_unset"
                                                                />
                                                            ) : null}
                                                        </Col>
                                                    </Row>

                                                    <Row className="mt-20px">
                                                        <Col>
                                                            <TextField
                                                                fullWidth={true}
                                                                variant="filled"
                                                                className='my-control'
                                                                name="denomination"
                                                                type="text"
                                                                onChange={handleChange}
                                                                label={(isMobile || isTablet) ?
                                                                    <IntlMessage id="tellUsAbout.form.field.denominationReligion" />
                                                                    :
                                                                    <IntlMessage id="tellUsAbout.form.field.denomination" />
                                                                }
                                                            />

                                                            {errors.denomination && touched.denomination ? (
                                                                <ErrorMessageWrapper
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    fieldName="denomination"
                                                                    className="font-size-12px min-height_unset"
                                                                />
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <div className='right-part'>
                                                    <Row className="h-100">
                                                        <Col className="h-100">
                                                            <TextField
                                                                fullWidth={true}
                                                                variant="filled"
                                                                className='my-control h-100'
                                                                name="reason"
                                                                type="text"
                                                                rows={isTablet ? 12 : isMobile ? 6 : 12}
                                                                multiline
                                                                onChange={handleChange}
                                                                label={
                                                                    <IntlMessage id="tellUsAbout.form.field.textarea.request" />
                                                                }
                                                                InputProps={{
                                                                    className: 'h-100'
                                                                }}
                                                            />

                                                            {errors.reason && touched.reason ? (
                                                                <ErrorMessageWrapper
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    fieldName="reason"
                                                                    className="font-size-12px min-height_unset"
                                                                />
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <div className="btn-submit-tellus">
                                        <CallToActionBtn variant="primary" type="submit">
                                            <IntlMessage id="common.submit" />
                                        </CallToActionBtn>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Modal.Body>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Modal.Header className='m-header'>
                        <button type="button" className="d-block d-md-none close" style={{ fontSize: '46px' }} onClick={handleClose}>
                            <span aria-hidden="true">×</span>
                            <span class="sr-only">Close</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className={`m-body text-center ${currentScr === 'thank_you' ? 'thank-you' : ''}`}>
                        <Modal.Title className='m-title'>
                            {isTablet ?
                                <IntlMessage id="tellUsAbout.modal.thank.title" />
                                : isMobile
                                    ? <IntlMessage id="tellUsAbout.modal.thank.titleMobile" />
                                    : <IntlMessage id="tellUsAbout.modal.thank.title" /> 
                            }
                        </Modal.Title>
                        <p className="txt-content mt-3">
                            <IntlMessage id="tellUsAbout.modal.subTitle" />
                        </p>
                        <div className="img-thank">
                            <img src={ThankIcon} alt="" />
                        </div>

                        <div className="btn-return-home mb-4">
                            <CallToActionBtn variant="primary" handleClick={returnHomePage}>
                                <IntlMessage id="tellUsAbout.modal.btn.returnHomepage" />
                            </CallToActionBtn>
                        </div>
                    </Modal.Body>
                </React.Fragment>
            )}

        </Modal>
    )
}

export default TellUsAboutModal