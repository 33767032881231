import React, { Component,useEffect,useState } from "react"
import { Modal } from "react-bootstrap"
import '../SignIn/style.scss'
import {useSelector, useDispatch} from 'react-redux';
import confirmRequestModalActions from '@iso/actions/confirmRequestModal'
import AddFormContinueAsGuest from "../../../components/ContinueAsGuest/ContinueAsGuestForm"
import ReceiveConfirmForm from "./ReceiveConfirmForm"
import AddForm from "../SignIn/AddFormSignIn";
import actionsSignIn from '@iso/actions/signinmodal'
import WelcomePrayerCloudModal from "@iso/Website/Pages/WelcomePrayerCloud/WelcomePrayerCloudModal"
const {closeModal} = confirmRequestModalActions;


function ReceiveYourConfirmModal(props){
  const {handleContinueAsGuestSubmit} = props
  const dispatch = useDispatch()
  const [modalType, setModalType] = useState('signUp') // signUp / signIn / asGuest
  const requestStepData = useSelector((state) => state.requestStepReducer);
  const showModal = useSelector((state) => state.confirmRequestModal);
  const {isOpen, title} = showModal;
  useEffect(() => {
    if (isOpen) {
    setModalType('signUp')
  }
  },[showModal])

  const setShowModal = () => {

  }

  const user = useSelector((state) => state.authentication.user);

  const handleClose = () => {
    if (!user && requestStepData.step === 4){
      return
    }
    dispatch(closeModal());
  }

  const handleContinueAsGuest = () => {
    setModalType('asGuest')
  }

  const handleSwitchSignUp = () => {
    setModalType('signUp')
  }

  const handleSignIn = () => {
    dispatch(closeModal());
    dispatch(actionsSignIn.openModal({
      isForgotPassword : false,
    }))
  }

const renderForm = (formType) =>  {
  switch(formType) {
    case 'signUp':
      return <ReceiveConfirmForm step={requestStepData.step} title = {title}
      handleSwitchSignIn={handleSignIn} handleContinueAsGuest={handleContinueAsGuest}></ReceiveConfirmForm>;
    case 'signIn':
      return <AddForm></AddForm>;
    case 'asGuest':
      return <AddFormContinueAsGuest handleContinueAsGuestSubmit={handleContinueAsGuestSubmit} handleSwitchSignup={handleSwitchSignUp}></AddFormContinueAsGuest>;
    case 'PrayerCloud':
      return <WelcomePrayerCloudModal />
    default:
      return <></>;
  }
}


return   <Modal  show={isOpen} onHide={handleClose} backdropClassName='recieveYourConfirmModalBackDrop'
                  className={`receiveModalContainer ${modalType == 'asGuest' ? 'continueAsGuestModal' : ''}
                              ${title === 'user.confirm.subscribe.title' &&  'receiveModalContainerSubcribe' }`} >
  {renderForm(modalType)}
</Modal>

}




export default ReceiveYourConfirmModal
