import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import IntlMessage from "@iso/components/intlMessage";
import { Row, Col, Card, Button, Badge, Container } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import Loader from "react-loaders";
import { useDispatch } from "react-redux";
import alertActions from "@iso/actions/Alert";
import CheckIcon from "@material-ui/icons/Check";
import "./Detail.scss";
import { COLOR_CODE, RESPONDED_STATUS } from "./constants";
import SupportCenterService from "@iso/services/SupportCenterService";
import SendResponseModal from "./components/SendResponseModal";
import ProcessRequest from "@iso/utils/ProcessRequest";
import RequestDetailBuilder from "./components/RequestDetailBuilder";
import RespondedBuilder from "./components/RespondedBuilder";

const { openAlert } = alertActions;
const SupportRequestDetail = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { id } = useParams();
  const [requestValue, setRequestValue] = React.useState({ id });
  const [isResponded, setResponded] = React.useState(
      requestValue.message_response
  );

  const [openSendModal, setOpenSendModal] = React.useState(false);

  const getRequestData = () => {
    setLoading(true);
    try {
      SupportCenterService.getRequestDetail(id).then((res) => {
        const { code, data, message } = res.data;
        ProcessRequest.run({
          res,
          successCallback: () => {
            setRequestValue(data);
            setResponded(data.message_response);
          },
        });
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getRequestData();
  }, [id]);

  return (
    <div className="mint-tulip-background p-3 admin_support_request_wrapper">
      <Row className="my-2">
        <Col xs="12" sm="6" className="request_title">
          <h2>
            <IntlMessage id="admin.support_center.detail.header" />
          </h2>
        </Col>
      </Row>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
                <h1>
                  <IntlMessage id="admin.requests.details.loading" />
                </h1>
              </div>
            </div>
          </div>
        }
      >
        <Card className="p-3 height_100per request_detail">
          <Row>
            <Col xs="12" sm="6" md="5">
              <h3 className="font-weight-bold">
                <IntlMessage
                  id="admin.support_center.detail.id"
                  values={{ idNumber: requestValue.id }}
                />
              </h3>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="7"
              className="text-center text-md-right mb-3"
            >
              {!isResponded ? (
                <Button
                  className="hippie-blue-button app_btn mx-1"
                  onClick={() => setOpenSendModal(true)}
                >
                  <Typography variant="body2" noWrap>
                    <IntlMessage id="admin.support_center.detail.send_response" />
                  </Typography>
                </Button>
              ) : (
                <Button
                  className="btn_responded app_btn mx-1 font-weight-bold"
                  disabled
                >
                  <CheckIcon />
                  <IntlMessage id="admin.support_center.detail.btn.responded" />
                </Button>
              )}
            </Col>
          </Row>

          <Row className="request_status_wrapper">
            <Col sm="12">
              <span>
                <IntlMessage id="admin.requests.details.status" />
              </span>
              <Badge
                className={`${requestValue.status} ml-3 text-capitalize p-1 status`}
                style={{ backgroundColor: COLOR_CODE[requestValue.status] }}
              >
                <IntlMessage
                  id={`admin.support_center.detail.status.${requestValue.status}`}
                />
              </Badge>
            </Col>
          </Row>

          <RequestDetailBuilder requestValue={requestValue} />
          {isResponded && <RespondedBuilder requestValue={requestValue} />}

          <p className="support_note">
            <IntlMessage id="admin.support_center.detail.note" />
          </p>

        </Card>

        <SendResponseModal
          open={openSendModal}
          handleClose={() => setOpenSendModal(false)}
          requestId={id}
          successCallback={getRequestData}
        />
      </Suspense>
    </div>
  );
};

export default SupportRequestDetail;
