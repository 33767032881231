import React from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { SignInFormValidator } from "./validator"
import { useDispatch } from "react-redux"
import { setCurrentUser } from "@iso/actions/User"
import { connect } from "react-redux"
import { userRoleConstants } from "@iso/constants/user"
import AuthService from "@iso/services/AuthService"
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IntlMessage from "@iso/components/intlMessage";
import TextField from '@material-ui/core/TextField';
import { Redirect } from "react-router-dom"
import gtag from 'ga-gtag';
import { recommended_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

import './style.scss'

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

function CAddForm(props) {
  const {handleSwitchRegister} = props
  let initValue = {
    email: "",
    password: "",
  }

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  }); 
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch()

  const handleSubmit =  (values, bag) => {
    gtag('event', recommended_events.LOGIN, {
      method : 'Email'
    });
    ReactPixel.track(fb_pixel_event.custom_events.LOGIN, {
      method : 'Email'
    });

    
    AuthService.loginApi(values).then((res) => {
      if(!res){
        bag.setErrors({ password: "Password and email don't match" })
        bag.setSubmitting(false)
      }
      const { code, data } = res.data;
      if (code === 200 && (data.role === userRoleConstants.USER_ROLE || data.role === userRoleConstants.PRAYER_ROLE) ) {
        props.setCurrentUser({ ...data, rememberMe: values.remember_me })
      } else{
          bag.setErrors({ password: res.data.message })
          bag.setSubmitting(false)
        } 
    }).catch((res) => {
      bag.setErrors({ password: "Password and email don't match" })
      bag.setSubmitting(false)
    });

  }

  return (
    <>
    <Formik
    initialValues={initValue}
    enableReinitialize={false}
    onSubmit={handleSubmit}
    validationSchema={SignInFormValidator}
  >

    {({ handleSubmit, handleChange, touched, errors }) => (
      <form onSubmit={handleSubmit} noValidate>
        <Form.Group as={Row} className = "d-block custom_add_form_sign_in">
          <Col>
            <TextField
              fullWidth={true}
              variant="filled"
              className='my-control'
              name="email"
              type="text"
              onChange={handleChange}
              label={
                <IntlMessage id="public.request.form.email.label" />
              }
            />

            <ErrorMessageWrapper
              touched={touched}
              errors={errors}
              fieldName="email"
              className ="font-size-12px p-1"/>
          </Col>

          <Col>
           <TextField
              fullWidth={true}
              variant="filled"
              className='my-control'
              name="password"
              type={values.showPassword ? 'text' : 'password'}
              onChange={handleChange}
              label={
                <IntlMessage id="public.request.form.password.label" />
              }

              InputProps={{
              endAdornment:(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password VisibilityOff"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          />

            <Form.Text className="color-hippie-blue float-right font-size-14px custom_forgot_password"
            onClick = {props.sendToForgotPassword}>
              Forgot password?
            </Form.Text>

            <ErrorMessageWrapper
              touched={touched}
              errors={errors}
              fieldName="password"
              className ="font-size-12px p-1"/>
          </Col>
        </Form.Group>

        <div className="buttons text-center mt-2" >
          <Button className="hippie-blue-sign-in-button py-3 font-weight-bold font-size-20px" type="submit" >
            <IntlMessage id="public.request.form.signin_btn_title" />
          </Button>
        </div>

          <div className = "text-center mt-3 font-weight-bold font-size-18px">
            <IntlMessage id="public.request.form.account_title" />
            <span className ="color-hippie-blue ml-1" onClick={() => handleSwitchRegister()}>
            <IntlMessage id="public.request.form.signup_btn_title" /></span>
          </div>
        </form>
      )}
    </Formik>
    </>
  
  )
}
const mapStoreToProps = (store) => ({})

const mapDispatchToProps = {
  setCurrentUser: setCurrentUser,
}

const AddForm = connect(mapStoreToProps, mapDispatchToProps)(CAddForm)
export default AddForm
