import React from 'react'
import styled from 'styled-components'
import OurValueClouds from '@iso/assets/images/landing_clouds_seperator.svg'
import Button from '@iso/components/AtomicComponents/Button'
import { useHistory } from 'react-router'
import IntlMessage from "@iso/components/intlMessage";
import PopupBeta from "../../../../../actions/PopupBeta";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;

    ${props => props.withCloud && 'height: 210px;'}
`
//@media only screen and (min-width: 1920px){
    // padding-left: 200px;
// }

const Cloud = styled.img`
    position: absolute;
    bottom: 0px;
`

const CallToActionBtn = styled(Button)`
    width: 303px;
    height: 60px;
    font-family: "BrandonText";
    @media only screen and (min-width: 768px){
        width: 250px;
    }
`

const RequestPrayerSeperator = props => {
    const {withCloud = false, className, style, withButton = true} = props
    const [show, setShow] = React.useState(false);

    const history = useHistory()

    const handleRequestNew = () => {
        history.push('/requests/step1')
        // setShow(true)
    }

    return (   
        <Wrapper withCloud={withCloud} className={className} style={style}>
            {withCloud && <Cloud src={OurValueClouds}></Cloud>}
            {withButton &&
            <CallToActionBtn variant="primary" handleClick={() => handleRequestNew()}><IntlMessage id="landing.request.new.prayer" /></CallToActionBtn> }
            <PopupBeta show={show} setShow={setShow}/>
        </Wrapper>

    )
}

export default RequestPrayerSeperator