import * as Yup from "yup";
import {
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH, 
} from "./constants.js"; 

const ProfileInfoFormValidator = Yup.object().shape({
  first_name: Yup.string()
    .label("First name")
    .required()
    .test(
      "minLength",
      "First name must contain from 2 to 20 characters.",
      (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
      "maxLength",
      "First name must contain from 2 to 20 characters.",
      (val) => val && val.length <= NAME_MAX_LENGTH
    )
    .matches(/^\S+(?: \S+)*$/, "Invalid input. Please try again.")
    .matches(
      /^[^`~!@#$%^&*/()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/,
      "Invalid input. Please try again."
    ),
  last_name: Yup.string()
    .label("Last name")
    .required()
    .test(
      "minLength",
      "Last name must contain from 2 to 20 characters.",
      (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
      "maxLength",
      "Last name must contain from 2 to 20 characters.",
      (val) => val && val.length <= NAME_MAX_LENGTH
    )
    .matches(/^\S+(?: \S+)*$/, "Invalid input. Please try again.")
    .matches(
      /^[^`~!@#$%^&*/()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/,
      "Invalid input. Please try again."
    ),
  email: Yup.string()
    .label("Email")
    .email("Please enter a correct email!")
    .required(),
});


const CreateAdminPasswordValidator = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  first_name: Yup.string()
    .label("First name")
    .required()
    .test(
      "minLength",
      "First name must contain from 2 to 20 characters.",
      (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
      "maxLength",
      "First name must contain from 2 to 20 characters.",
      (val) => val && val.length <= NAME_MAX_LENGTH
    )
    .matches(/^\S+(?: \S+)*$/, "Invalid input. Please try again.")
    .matches(
      /^[^`~!@#$%^&*/()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/,
      "Invalid input. Please try again."
    ),
  last_name: Yup.string()
    .label("Last name")
    .required()
    .test(
      "minLength",
      "Last name must contain from 2 to 20 characters.",
      (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
      "maxLength",
      "Last name must contain from 2 to 20 characters.",
      (val) => val && val.length <= NAME_MAX_LENGTH
    )
    .matches(/^\S+(?: \S+)*$/, "Invalid input. Please try again.")
    .matches(
      /^[^`~!@#$%^&*/()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/,
      "Invalid input. Please try again."
    ),
  role: Yup.string().label("Role").required(),
  password: Yup.string()
    .label("Password")
    .matches(/^.{8,}$/, "Have at least 8 characters.")
    .matches(/(^[\S]*$)/, "Password must not contain space.")
    .matches(
      /(?=.*[A-Z])/,
      "Contain at least 1 uppercase (i.e. A, B, C, D, etc.)"
    )
    .matches(
      /(?=.*[a-z])/,
      "Contain at least 1 lowercase (i.e. a, b, c, d, etc.)"
    )
    .matches(
      /(?=.*\d)/,
      "Contain at least 1 number digit (i.e. 1, 2, 3, 4, etc.)"
    )
    .matches(
      /(?=.*\W)/,
      "Contain at least 1 special character (i.e. $, #, @, !, etc.)"
    )
    .required(),
  passwordConfirmation: Yup.string()
    .label("Password Confirmation")
    .oneOf(
      [Yup.ref("password"), null],
      "Passwords does not match! Please try again."
    )
    .required(),
   

})

export { 
  ProfileInfoFormValidator, 
  CreateAdminPasswordValidator,
};
