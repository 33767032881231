import React from 'react'
import {isTablet} from 'react-device-detect'
import styled from 'styled-components'
import IntlMessage from "@iso/components/intlMessage";
import OurValueClouds from "@iso/assets/images/cloud_about.svg";
import OurValueCloudsTablet from "@iso/assets/images/about_section3_cloud_footer.svg";
import WhereIsMyContributeCloud from "@iso/assets/images/where_is_my_contribute_cloud.svg";
import Cloud_About_Bottom from "@iso/assets/images/Cloud_About_Bottom.svg";
import { useHistory } from 'react-router';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "BrandonText";
  background: #AFDAF5;
  color: #1C1634;
  padding: 50px 40px 180px 40px;
  @media only screen and (max-width: 767px) {
      padding-bottom: 60px;
      padding-right: 30px;
      padding-left: 30px;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 89%);
  }

  @media only screen and (min-width: 767px) {
      padding: 80px 140px 60px 140px;
      height: 698px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
      padding: 130px 40px 157px 40px;
      height: 606px;
  }

  @media only screen and (min-width: 960px) and (max-width: 1300px) {
      padding: 50px 70px 180px 70px;
  }
  
  @media only screen and (min-width: 1920px) {
      padding: 50px 130px 180px 400px;
  }
`



const Title = styled.p`
  font-size:  46px;
  line-height: 59px;
  font-weight: 700;
  margin-top: 100px;
  margin-bottom: 58px;

  @media only screen and (max-width: 767px) {
      font-size: 30px;
      margin-bottom: 37px;
      line-height: 40px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 40px;
      margin-bottom: 35px;
      margin-top: 200px;
      line-height: 51px;
  }

  @media only screen and (max-width: 376px) {
    text-align: center;
    margin-top: 0;
  }
  @media only screen and (min-width: 377px) and (max-width: 500px){
    padding: 0 15px;
    text-align: center;
    margin-top: 0;
  }
`
const SubTitle = styled.p`
  font-size:  18px;   
  margin-bottom: 137px;
  margin-top: -30px;
  line-height: 32px;
  padding-right: 10px;

  @media (min-width: 768px) and (max-width: 1024px) {
      margin-top: 0px;
    padding-right: 20px;
  }
`
const SubtitleContainer = styled.div`
  margin-bottom: 190px;
  text-align: center;
  @media only screen and (max-width: 360px){
    color: #000000;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding: 0 0px;
  }
  @media only screen and (min-width: 361px) and (max-width: 375px){
    color: #000000;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding: 0 0px;
  }
  @media only screen and (min-width: 410px) and (max-width: 424px){
    color: #000000;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding: 0 17px;
  }
  @media only screen and (min-width: 425px) and (max-width: 500px){
    color: #000000;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding: 0 31px;
  }
  @media only screen and (min-width: 400px) and (max-width: 410px){
    color: #000000;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding: 0 17px;
  }
  @media only screen and (min-width: 390px) and (max-width: 400px){ 
    color: #000000; 
    font-size: 18px; 
    line-height: 30px; 
    font-weight: 400; 
    padding: 0 9px; 
  }
  @media only screen and (min-width: 390px) and (max-width: 400px){ 
    color: #000000; 
    font-size: 18px; 
    line-height: 30px; 
    font-weight: 400; 
    padding: 0 23px; 
  }
`

const SubTitleMobile1 = styled.div`
  font-size:  18px;     
  line-height: 32px;
  padding-right: 15px;
  padding-left: 15px;
  @media only screen and (max-width: 360px){
    font-size: 17px;
  }
`


const SubTitleMobile2 = styled.div`
  font-size:  18px;     
  line-height: 32px;
  padding-right: 10px;
  @media only screen and (max-width: 360px){
    font-size: 17px;
    padding-right: 9px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`
// const BottomCloud = styled.img`
//   width: 1465.71px;
//   height: 74px;
//   position: absolute;

//   @media only screen and (max-width: 767px){
//     display: none;
//   }
//   @media only screen and (min-width: 1025px) {
//     margin-top: 50px;
//     left: -114px;
//     top: 597px;
//   }
// `;
const BottomCloud = styled.img`
  width: 1440px;
  height: 120px;
  position: absolute;
  bottom: -2px;
  right: -725px;

  @media only screen and (max-width: 767px){
    display: none;
  }
`;

const CloudImgMobileWrapper = styled.img`
  position: absolute;
  top: -57px;
  left: 24px;
`
const LinkToPage = styled.a`
  color: #429E9D !important;
  cursor: pointer;
`

const LeftSide = props => {
  const history = useHistory();

  return <Wrapper>
    <Title> <IntlMessage id = "user.form.about.section3.contribution.title"/></Title>
    <SubTitle className="d-none d-md-block">
      <IntlMessage id = "user.form.about.section3.sub.contribution.title"/>
      <br />
      <IntlMessage id = "user.form.about.section3.sub.contribution.mobile.title4"/>
      <LinkToPage className='' onClick={() => history.push('/contributions')}>
        <IntlMessage id = "user.form.about.section3.sub.contribution.mobile.title.contributionPage"/>
      </LinkToPage>
    </SubTitle>
    <SubtitleContainer className="d-block d-md-none">
      <SubTitleMobile1><IntlMessage id = "user.form.about.section3.sub.contribution.mobile.title1"/></SubTitleMobile1>
      <SubTitleMobile2><IntlMessage id = "user.form.about.section3.sub.contribution.mobile.title2"/></SubTitleMobile2>
      <SubTitleMobile1><IntlMessage id = "user.form.about.section3.sub.contribution.mobile.title3"/></SubTitleMobile1>
      <SubTitleMobile1>
        <IntlMessage id = "user.form.about.section3.sub.contribution.mobile.title4"/>
        <LinkToPage className='' onClick={() => history.push('/contributions')}>
          <IntlMessage id = "user.form.about.section3.sub.contribution.mobile.title.contributionPage"/>
        </LinkToPage>
      </SubTitleMobile1>
    </SubtitleContainer>
    {/*<BottomCloud src={isTablet ? OurValueCloudsTablet : OurValueClouds}></BottomCloud>*/}
    <BottomCloud src={WhereIsMyContributeCloud}/>
  </Wrapper>
}

export default LeftSide
