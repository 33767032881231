import React from "react"
import { withRouter } from "react-router-dom"
import Header from "./Header"
import PrivateLayout from "@iso/components/Layout/PrivateLayout"
import { MENU_SIDEBAR } from "./constants"
import IntlMessage from "@iso/components/intlMessage"
import CheckUserRoles from "@iso/utils/CheckUserRoles"
import { USER_PERMISSIONS } from "@iso/constants"

const AdminPrivateLayout = (props) => {
  CheckUserRoles.run({ roles: USER_PERMISSIONS.SUPER_USER.roles })

  return (
    <PrivateLayout
      component={props.component}
      headerComponent={Header}
      key={props.location.pathname}
      title={<IntlMessage id="sidebar.requests" />}
      menuSidebar={MENU_SIDEBAR}
    />
  )
}

export default withRouter(AdminPrivateLayout)
