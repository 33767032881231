import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, ButtonGroup } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import AdminsService from "@iso/services/AdminsService";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import RequestStatus from "@iso/components/Request/RequestStatus";
import ProfileForm from "@iso/components/Admins/Profile/ProfileForm";
import { useParams } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import alertActions from "@iso/actions/Alert";
import { history } from "@iso/helpers";
import { store } from "@iso/store/root";
import "./styles.scss";
import LoginAs from "@iso/components/Common/LoginAs";
import { userRoleConstants } from "@iso/constants";

const { openAlert } = alertActions;
const DetailsAdmin = (props) => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [disableAdmin, setDisableAdmin] = useState(true);
  const [profile, setProfile] = React.useState();
  const [request, setRequest] = useState({ status: "disabled" });
  const [isShowDelDialog, setShowDelDialog] = useState(false);

  const handleDataDetailsAdmin = (id) => {
    AdminsService.GetDetailsAdminById(id).then((res) => {
      if (res && res.data) {
        const { code, data } = res.data;
        if (res && code === 200) {
          setProfile(data);
          setRequest({
            ...request,
            status: data.status,
          });
        }
      }
    });
  };

  useEffect(() => {
    if (id) {
      handleDataDetailsAdmin(id);
    }
  }, [id]);

  return (
    <div className="mint-tulip-background p-3 min-vh-100 details_admin">
      <Row>
        <Col xs="12" md="6" lg="4">
          <h2 className="mb-3">
            <IntlMessage id="sidebar.admin.admins.tabs.title" />

            <Link to="/admin/adminsTab" className="text-hippie-blue header_back_link d-block d-md-none">
              {"< Back"}
            </Link>

          </h2>
        </Col>
        <Col xs="12" md="6" lg={{ span: 4, offset: 4 }}>
          <Row>
            <Col xs="12" md="4" className="text-center mb-3 pt-2 d-none d-md-block">
              <Link to="/admin/adminsTab" className="text-hippie-blue">
                {"< Back"}
              </Link>
            </Col>
            <Col xs="12" md="8">
              <LoginAs
                className="mb-3 w-100"
                id={id}
                role={userRoleConstants.ADMIN_ROLE}
                allowLoginAs={false}
                allowRemove={true}
                disableAdmin={profile?.status !== 'banned'}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Card className="card_swapper">
        <Card.Title className="d-flex title p-0 m-0 px-3 justify-content-between">
          <span>
            <IntlMessage id="prayer_partners.account_settings.profile.title" />
          </span>

          <ButtonGroup className="float-right custom_button_edit_admin">
            <Button
              className=" hover_pointer btn hippie-blue-link edit_btn "
              onClick={() => setIsEdit(true)}
            >
              <EditIcon className="pr-1 pb-1" />
              <IntlMessage id="prayer_partners.account_settings.profile.btn.edit" />
            </Button>
          </ButtonGroup>
        </Card.Title>

        <Row className="mt-3">
          <Col xs="12" sm="6" md="5">
            <RequestStatus request={request} />
          </Col>
        </Row>

        <Card.Body>
          {profile && (
            <ProfileForm
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              handleUpdateProfile={AdminsService.updateAdminProfile}
              setProfile={setProfile}
              profile={profile}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default DetailsAdmin;
