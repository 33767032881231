import axios from "axios";
import { apiEndpoints } from "@iso/helpers";

const getContributions = ({ religion }) => {
  return axios.get(`${apiEndpoints.contribution}?religion=${religion}`);
};

const getAllContributions = ({ religion }) => {
  return axios.get(`${apiEndpoints.get_all_contributions}?religion=${religion}`);
};

const getAllFrequencies = ({ religion }) => {
  return axios.get(`${apiEndpoints.frequency}?religion=${religion}`);
}

export default{
  getContributions,
  getAllFrequencies,
  getAllContributions
}