import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import {isMobile, isMobileSafari, isTablet} from "react-device-detect";
import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const RequestPrayer = (props) => {
    const isDesktop = !isMobile && !isMobileSafari && !isTablet
    const [openPopoverIsGift, setOpenPopoverIsGift] = React.useState(false);
    const tooltipGiftNoti = () => {
        let title = (<div className={`tooltip-wrap`} style={{maxWidth: "220px"}}><div className="tooltip-message" style={{maxWidth: "220px"}}>Both you and your gift recipient will receive a letter from your Prayer Partner and updates as the prayers are completed.</div></div>);
        let style = isDesktop ? {zIndex: "1", right: "0px", top: "20px", width: "22px"} : {zIndex: "1", right: "-15px", top: "20px"};
    
        return isDesktop && props.tooltipTemplate(title, openPopoverIsGift, setOpenPopoverIsGift, style, `${props.formikValues.for_who === "formyself" ? " reason_margin_top" : " mt-0"}`)
    }
    return(
    <>
        <Row className="position-relative tooltip-group">
          <Col xs="12">
            <Row className="form-group font-size-18px radio-container custom_radio_container">
              <Col xs="12" md={isTablet ? 12 : 5} className="form-group margin_top_for_myself">
                <Form.Check
                  custom
                  className="custom-radio text-md-right"
                  type="radio"
                  name="for_who"
                  value="formyself"
                  checked={props.formikValues.for_who == "formyself"}
                  onChange={(event) => {
                    props.setFormikValues({
                      ...props.formikValues,
                      for_who: event.target.value,
                      is_gift: false,
                      recipient_name: ''
                    });
                  }}
                  onClick={(event)=>{
                    gtag('event', custom_events.CHANGE_INPUT, {
                      input_type : 'radio',
                      input_value : event.target.value,
                      input_label : 'Request for'
                    });
                    ReactPixel.track(fb_pixel_event.custom_events.CHANGE_INPUT, {
                      input_type : 'radio',
                      input_value : event.target.value,
                      input_label : 'Request for'
                    });
                  }}
                  label={<IntlMessage id="public.request.form.for_myself" />}
                  id="for_myself"
                />
              </Col>
              <Col xs="12" md={isTablet ? 12 : 7}>
                <Form.Check
                  custom
                  className="custom-radio"
                  type="radio"
                  name="for_who"
                  value="forsomeoneelse"
                  checked={props.formikValues.for_who == "forsomeoneelse"}
                  onChange={(event) => {
                    props.setFormikValues({
                      ...props.formikValues,
                      for_who: event.target.value,
                      recipient_name: '',
                      is_gift: true
                    });
                  }}
                  onClick={(event)=>{
                    gtag('event', custom_events.CHANGE_INPUT, {
                      input_type : 'radio',
                      input_value : event.target.value,
                      input_label : 'Request for'
                    });
                    ReactPixel.track(fb_pixel_event.custom_events.CHANGE_INPUT, {
                      input_type : 'radio',
                      input_value : event.target.value,
                      input_label : 'Request for'
                    });
                  }}
                  label={<IntlMessage id="public.request.form.for_someone_else" />}
                  id="forsomeoneelse"
                />
              </Col>
            </Row>
            <Row className={`gift_notificaction_custom position-relative tooltip-group tooltip-reason ${
            props.formikValues.for_who == "forsomeoneelse"
              ? ""
              : "d-none"
            }`}>
              <Col xs="12" className={`pt-3 pb-3 font-size-18px custom-style-message-gift-notification`}>
                  <IntlMessage id="public.request.step1.receive.gift.notification" />
              </Col>
              {tooltipGiftNoti()}
            </Row>
            <Row className={`form-group font-size-18px radio-container yes-no-input-custom ${
            props.formikValues.for_who == "forsomeoneelse"
              ? "yes-no-input-custom"
              : "d-none"
            }`}>
              <Col xs="3" md={isTablet ? 3 : 3} className="form-group margin_top_for_myself">
                <Form.Check
                  custom
                  className="custom-radio text-md-left"
                  type="radio"
                  name="yes"
                  value="Yes"
                  checked={props.formikValues.is_gift}
                  onChange={() => {
                    props.setFormikValues({
                      ...props.formikValues,
                      is_gift: true,
                      recipient_name: ''
                    });
                  }}
                  onClick={(event)=>{
                    gtag('event', custom_events.CHANGE_INPUT, {
                      input_type : 'radio',
                      input_value : event.target.value,
                      input_label : 'Is gift'
                    });
                    ReactPixel.track(fb_pixel_event.custom_events.CHANGE_INPUT, {
                      input_type : 'radio',
                      input_value : event.target.value,
                      input_label : 'Is gift'
                    });
                  }}
                  label={`Yes`}
                  id="yes_gift"
                />
              </Col>
              <Col xs="9" md={isTablet ? 9 : 9} className="form-group margin_top_for_myself">
                <Form.Check
                  custom
                  className="custom-radio"
                  type="radio"
                  name="no"
                  value="No"
                  checked={!props.formikValues.is_gift}
                  onChange={(event) => {
                    props.setFormikValues({
                      ...props.formikValues,
                      is_gift: false,
                      recipient_name: ''
                    });
                  }}
                  onClick={(event)=>{
                    gtag('event', custom_events.CHANGE_INPUT, {
                      input_type : 'radio',
                      input_value : event.target.value,
                      input_label : 'Is gift'
                    });
                    ReactPixel.track(fb_pixel_event.custom_events.CHANGE_INPUT, {
                      input_type : 'radio',
                      input_value : event.target.value,
                      input_label : 'Is gift'
                    });
                  }}
                  label={`No`}
                  id="no_gift"
                />
              </Col>
            </Row>
          </Col>
        </Row>
    </>)
}

export default RequestPrayer;