import React from "react";

import PrayerTypeService from "@iso/services/PrayerTypeService";
import DetailItem from "./DetailItem";
import "./PrayerTypeDetails.scss";

const PrayerType = ({ id }) => {
  const [typeService, setTypeService] = React.useState();

  React.useEffect(() => {
    PrayerTypeService.list().then((res) => {
      const { code, data, message } = res.data;
      if (code === 200 && data) {
        setTypeService(data.find((item) => item.id === id));
      } else {
      }
    });
  }, [id]);

  return (
    <>
      {typeService ? (
        <DetailItem
          label="public.request.step3.left.request_details.customization.prayer_type"
          displayValue={typeService.name}
        />
      ):  <DetailItem
      className="margin_top_prayer_type"
      label="public.request.step3.left.request_details.customization.prayer_type"
      displayValue=" "
    />}
    </>
  );
};

export default PrayerType;
