import React, { Component, useState } from "react"
import IntlMessage from "@iso/components/intlMessage"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { Form, Row, Col, Button } from "react-bootstrap"
import { ExpandMore } from "@material-ui/icons"
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { aboutMyself } from "@iso/Pages/Common/AboutMyself"
import CheckIcon from '@material-ui/icons/Check'
import { userRoleConstants } from "@iso/constants"
import "./styles.scss"
import {isMobile, isMobileSafari, isTablet} from "react-device-detect";

const FormContactRightPart = (props) => {
  const { initValue, errors, touched,setInitValue } = props;
  const isDesktop = !isMobile && !isMobileSafari && !isTablet
  const countries = [
    { code: 'en', label: 'English', suggested: true },
    { code: 'de', label: 'Germany', suggested: true },
    { code: 'pl', label: 'Polish', suggested: true }
  ];

  return(
  <>
    <Row className='form-group'>
      <Col xs='12' id = "reason_contact_id">
        <Form.Control
          as="textarea"
          rows={5}
          name = "message"
          type="text"
          className={`my-control2 data-hj-allow reason-contact p-1 ${errors.message ? 'border-errors2' : ''}`}
          value={initValue.message}
          onChange={(event) => {
            setInitValue({
              ...initValue,
              message: event.target.value
            })
          }}
          placeholder={ isDesktop ? "Please share your reasons for contacting us. For previous requests, include your Request ID."
              : "Please share your reasons for contacting us. For previous requests, please include Request ID."
          // <IntlMessage id="public.request.right.form.special_request.placeholde" />
          }
          />
        <ErrorMessageWrapper
          touched={touched}
          errors={errors}
          className="error_contact_msg"
          fieldName="message" />
      </Col>
    </Row>
  </>
  )
}

export default FormContactRightPart
