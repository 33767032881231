import React, { useEffect } from "react";
import Section1 from "./components/Section1";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import styled from "styled-components";
import "./styles.scss";
import PricingHeaderClouds from "./components/headerClouds";
import CloudPrayerPathnerCenter from "@iso/assets/images/CloudPrayerPathnerCenter.svg";
import CloudPrayerPathnerCenterTablet from "@iso/assets/images/CloudPrayerPathnerCenterTablet.svg";
import CloudPrayerPathnerCenterMobile from "@iso/assets/images/CloudPrayerPathnerCenterMobile.svg";
import LastCloudFooter from "@iso/assets/images/LastCloudFooter.svg";
import { isMobile, isTablet, isMobileSafari } from "react-device-detect";

const PricingPage = (props) => {
  const isDesktop = !isMobile && !isMobileSafari && !isTablet;

  // scroll to top when click link from about
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className={`container-full-width bg_blueSky`}>
        <div className="container-fixed">
          <PricingHeaderClouds></PricingHeaderClouds>
        </div>
      </div>

      <Section1></Section1>
      <img
        className="cloud-prayer-pathner-center"
        src={
          isDesktop
            ? CloudPrayerPathnerCenter
            : isTablet
            ? CloudPrayerPathnerCenterTablet
            : CloudPrayerPathnerCenterMobile
        }
      />
      <div className={`container-full-width bg-blue-sky-half`}>
        <div className="container-fixed">
          <Section3 />
        </div>
      </div>
     
      {!isDesktop && !isTablet && <img className="last-cloud-footer" src={LastCloudFooter} />}
      <Section4></Section4>
    </>
  );
};

export default PricingPage;
