import * as Yup from "yup";

const PercentageValidator = Yup.object().shape({
  percentage: Yup.number()
    .label("Percentage")
    .required()
    .min(1)
    .max(100)
});

export { PercentageValidator };
