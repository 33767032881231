import React from "react";
import { Col, Button } from "react-bootstrap";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PrayerPartnerService from "@iso/services/PrayerPartnerService";
import { useDispatch } from "react-redux";

import IntlMessage from "@iso/components/intlMessage";
import alertActions from "@iso/actions/Alert";
import prayerPartnerActions from "@iso/actions/PartnerActions";

const { openAlert } = alertActions;
const { setPrayerPartnerProfile } = prayerPartnerActions;

const ButtonAvatarActionGroup = (props) => {
  const { profile } = props;

  const dispatch = useDispatch();

  const handleUpdateStatusAvatar = ({ id, status }) => {
    try {
      PrayerPartnerService.adminPutUpdateAvatarStatus({
        id: profile.id,
        status,
      }).then((res) => {
        const { code, data, message } = res.data;
        if (code === 200 && data) {
          dispatch(setPrayerPartnerProfile({ profile: data }));

          dispatch(
            openAlert({
              message: "Update success",
              severity: "success",
            })
          );
        } else {
          dispatch(
            openAlert({
              message: message,
              severity: "error",
            })
          );
        }
      });
    } catch (error) {}
  };

  return (
    <>
      <Col sm="12" className="text-center p-0 m-1">
        <Button
          className="btn_custom bg_approve"
          onClick={() =>
            handleUpdateStatusAvatar({
              id: profile.id,
              status: "approve",
            })
          }
        >
          <Typography>
            <DoneIcon />
            <IntlMessage id="common.approve" />
          </Typography>
        </Button>
      </Col>
      <Col sm="12" className="text-center p-0 m-1">
        <Button
          className="btn_custom bg_reject"
          onClick={() =>
            handleUpdateStatusAvatar({
              id: profile.id,
              status: "reject",
            })
          }
        >
          <Typography noWrap>
            <CloseIcon />
            <IntlMessage id="common.reject" />
          </Typography>
        </Button>
      </Col>
    </>
  );
};

export default ButtonAvatarActionGroup; /*  */
