import React from "react";

import { TextField } from "@material-ui/core";
import { Button, Row, Col, Container, Card } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DetailItem from "./DetailItem";
import moment from "moment";
import PrayerTypeDetails from "./PrayerTypeDetails";
import requestStepActions from "@iso/actions/RequestStepActions";
import { requestCustomizationValidator } from "../validators";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ExpandMore } from "@material-ui/icons";
import { religions, messageTones } from "@iso/Pages/Common/Religions";
import CheckIcon from "@material-ui/icons/Check";
import PrayerTypes from "@iso/Website/Pages/Request/step1/PrayerTypes";
import { isMobile, isTablet } from "react-device-detect";

import "./CustomizationDetails.scss";
import PrayerTypesDropdown from "./PrayerTypesDropdown";
import DatepickerCustom from "../../../../../components/Datepicker/DatepickerCustom";
import { showPickerCustom } from "../../../../../utils/DatePickerCustomAction";
import { escapeObject } from "@iso/helpers/index";

const CustomizationDetails = ({ handleChange, touched, requestErrors, setFormikValues, formikValues }) => {
  const requestStepData = useSelector(
    (state) => state.requestStepReducer,
    shallowEqual
  );
  const [valueMostRequest, setValueMostRequest] = React.useState(false);
  const [valueSpecialRequest, setValueSpecialRequest] = React.useState(false)
  const [valueReligions, setValueReligion] = React.useState(false);
  const [valueMessageTone, setValueMessageTone] = React.useState(false);
  const [values, setValues] = React.useState(formikValues);
  const [edit, setEdit] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [openNote, setOpenNote] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [isAutoSaved, setIsAutoSaved] = React.useState(false);

  const dispatch = useDispatch();

  const isChangeValue = () => {
    return (
      values.prayer_customization.religion !== formikValues.prayer_customization.religion ||
      values.prayer_customization.date !== formikValues.prayer_customization.date ||
      values.prayer_customization.prayer_type_id !== formikValues.prayer_customization.prayer_type_id ||
      values.prayer_customization.special_request !== formikValues.prayer_customization.special_request || 
      values.prayer_customization.message_tone !== formikValues.prayer_customization.message_tone
    );
  }

  const handleEdit = (isAuto = false) => {
    if (edit) {
      try {
        requestCustomizationValidator.validate(values).then(
          () => {
            setValueReligion(false);
            setValueMessageTone(false);
            setOpenPicker(false);
            setValueSpecialRequest(false);
            if (!isAuto) {
              setEdit(false);
            }
            setErrors({});
            let data = {
              ...formikValues,
              prayer_customization: values.prayer_customization,
            };
            setFormikValues(data);
            dispatch(requestStepActions.setDataStep(data));
          },
          (error) => {
            setErrors({ [error.path]: error.message });
          }
        );
      } catch (error) { }
    } else {
      setIsAutoSaved(false);
      setEdit(true);
    }
  };

  const autoSaveTimeout = React.useRef(null);
  React.useEffect(() => {
    if (isChangeValue()) {
      setIsAutoSaved(true);
      clearTimeout(autoSaveTimeout.current);
      autoSaveTimeout.current = setTimeout(() => { handleEdit(true) }, 1000)
    }
  }, [values])

  const showNotSelected = () => {
    return !values?.prayer_customization?.date &&
      !values?.prayer_customization?.religion &&
      !values?.prayer_customization?.message_tone &&
      !values?.prayer_customization.prayer_type_id &&
      !values.prayer_customization.special_request &&
      !edit;
  }

  return (
    <Container className={`wrapper_contribution_details ${edit && "margin_bottom_wrapper_contribution"} CustomizationDetails`}>
      <Row className={` ${!edit && "margin_custom_card_wrapper_not_edit"} margin_custom_card_wrapper`}>
        <Col sm="12">
          <Card className={`card_wrapper ${edit ? "border-none" : ""}`}>
            <Card.Body className={`custom_padding_right_edit_mode ${edit && "padding_top_custom_edit_mode"} `}>
              <Card.Title className="custom_card_title" style={isTablet ? { marginBottom: "20px" } : {}}>
                <Row>
                  <Col xs="8" className="custom_customization_title" >
                    <IntlMessage id="public.request.step3.left.request_details.customization.title" />
                  </Col>
                  <Col
                    xs="4"
                    className="text-right text-hippie-blue hover_pointer padding_right_handle_click padding_handle_click "
                  >
                    <b onClick={(e) => { handleEdit() }}>
                      {edit ? (
                        isAutoSaved ? (
                          isChangeValue() ? (<span style={{ color: "#cd5651" }}><IntlMessage id="common.auto_saved" /></span>) : <IntlMessage id="common.auto_saved" />
                        ) : (
                          isChangeValue() ? (<span style={{ color: "#cd5651" }}><IntlMessage id="common.save" /></span>) : <IntlMessage id="common.save" />
                        )
                      ) : (
                        <IntlMessage id="common.edit" />
                      )}
                    </b>
                  </Col>
                </Row>
              </Card.Title>

              {
                showNotSelected() ? (
                  <Card.Title className="custom_card_title" style={isTablet ? { marginBottom: "20px" } : {}}>
                    <IntlMessage id="public.request.step3.left.request_details.customization.no_selected" />
                  </Card.Title>
                )
                  :
                  (
                    <div>
                      {!edit ? (
                        <Row>
                          <Col lg="6">
                            <DetailItem
                              label="public.request.step3.left.request_details.customization.prayer_date"
                              className="_first_custom_label"
                              displayValue={
                                values?.prayer_customization?.date &&
                                moment(values?.prayer_customization?.date).format(
                                  "MMM DD, YYYY"
                                )
                              }
                            />
                            <ErrorMessageWrapper
                              touched={{ date: true }}
                              errors={requestErrors}
                              fieldName="date"
                            />
                          </Col>
                          <Col lg="6" className="custom_religion_is_not_edit width_calendar_custom">
                            <DetailItem
                              label="public.request.right.form.religion.placeholder"
                              displayValue={`religion.name.${values.prayer_customization.religion}`}
                            />
                            <ErrorMessageWrapper
                              touched={{ religion: true }}
                              errors={requestErrors}
                              fieldName="religion"
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row className={(!isMobile ? "form-group margin_bottom_form_group" : "")}>
                          <Col
                            lg="6"
                            className={` ${openPicker && "background_focus_changed"} ${openPicker || isMobile || isTablet ? " custom_height_prayer_date step3-datepicker-custom bg_lighter_hippie_blue" : "bg_lighter_hippie_blue step3-datepicker-custom picker-wrapper"} width_calendar_custom `}
                          >
                            <p className={` ${!isMobile && "_first_custom_label"} _custom_label padding_custom_label `}
                              style={openPicker ? { color: '#499e9e' } : {}} >
                              <IntlMessage
                                id={
                                  "public.request.step3.left.request_details.customization.prayer_date"
                                }
                              />
                            </p>
                            <TextField
                              fullWidth={true}
                              onClick={() => {
                                setOpenPicker(true)
                                showPickerCustom(moment(), null, true);
                              }}
                              inputProps={{ readOnly: true }}
                              className="my-control2 selectComp"
                              value={values?.prayer_customization?.date ? moment(values?.prayer_customization?.date).format("MMM DD, YYYY") : null}
                            />
                            <DatepickerCustom
                              calendarClass="border_custom_calendar"
                              min={moment().add(0, "days")}
                              className="my-picker d-none"
                              value={
                                values?.prayer_customization?.date
                                  ? moment(values?.prayer_customization?.date)
                                  : null
                              }
                              isOpen={openPicker}
                              onChange={(date) => {
                                let formattedDate = null;
                                if (date)
                                  formattedDate = moment(date).utcOffset('UTC +00:00').format()
                                setValues(escapeObject({
                                  ...values,
                                  prayer_customization: {
                                    ...values.prayer_customization,
                                    date: formattedDate,
                                  },
                                }, false));
                                setOpenPicker(false);
                              }}
                            />
                          </Col>

                          <Col lg="6" className={` ${valueReligions && "background_focus_changed"} ${(valueReligions || isMobile || isTablet) ? "custom_height_prayer_date bg_lighter_hippie_blue pr-lg-0" : "pr-lg-0 "} bg_lighter_hippie_blue denomination_width_custom`}
                          // style={isTablet ? {marginBottom: "-3px", marginTop: "5px"} : {}}
                          >

                            <p className="_custom_label padding_custom_label" style={valueReligions ? { color: '#499e9e' } : {}} >
                              <IntlMessage
                                id={"public.request.right.form.religion.placeholder"}
                              />
                            </p>
                            <Select
                              fullWidth
                              className={`${(!valueReligions && !isMobile && !isTablet) && "my-control2"} `}
                              IconComponent={ExpandMore}
                              value={values?.prayer_customization?.religion || ""}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                className: "new-design border_selected_custom",
                              }}
                              onOpen={() => {
                                setValueReligion(true)
                              }}
                              onChange={event => {
                                setValueReligion(false)
                                setValues(escapeObject({
                                  ...values,
                                  prayer_customization: {
                                    ...values.prayer_customization,
                                    religion: event.target.value,
                                  },
                                }, false))
                              }}
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                                variant: "filled",
                              }}
                            >
                              <MenuItem value="" disabled>
                                <IntlMessage id="admin.requests.details.change_assignee.no_preference" />
                              </MenuItem>
                              {religions.map(religion => (
                                <MenuItem key={religion.id} value={religion.id}>
                                  {religion.label}
                                  <CheckIcon />
                                </MenuItem>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                      )}

                      {!edit ? (
                        <Row>
                          <Col lg="6" className="custom_religion_is_not_edit most_request_group">
                            <PrayerTypeDetails
                              id={values?.prayer_customization.prayer_type_id}
                            />
                            <ErrorMessageWrapper
                              touched={{ prayer_type_id: true }}
                              errors={requestErrors}
                              fieldName="prayer_type_id"
                            />
                          </Col>
                          <Col
                            lg="6"
                            className={`${isMobile || isTablet ? " custom_height_prayer_date step3-datepicker-custom bg_lighter_hippie_blue_custom" : "bg_lighter_hippie_blue_custom step3-datepicker-custom picker-wrapper"} width_calendar_custom custom_religion_is_not_edit`}
                          >
                            <DetailItem
                              label="public.request.step3.left.request_details.customization.message_tone"
                              className="_first_custom_label"
                              displayValue={values.prayer_customization.message_tone ? `message_tone.name.${values.prayer_customization.message_tone}` : ''}
                            />
                            <ErrorMessageWrapper
                              touched={{ message_tone: true }}
                              errors={requestErrors}
                              fieldName="message_tone"
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row className={(!isMobile ? "form-group margin_bottom_form_group" : "")}>
                          <Col
                            lg="6"
                            className={`${isMobile || isTablet ? " custom_height_prayer_date step3-datepicker-custom bg_lighter_hippie_blue" : "bg_lighter_hippie_blue step3-datepicker-custom picker-wrapper"} width_calendar_custom `}
                          >
                            <p className="_custom_label"></p>
                            <PrayerTypesDropdown
                              formikValues={values}
                              setFormikValues={setValues}
                              setValueMostRequest={setValueMostRequest}
                            />
                          </Col>

                          <Col lg="6" className={` ${valueReligions && "background_focus_changed"} ${(valueReligions || isMobile || isTablet) ? "custom_height_prayer_date bg_lighter_hippie_blue pr-lg-0" : "pr-lg-0 "} bg_lighter_hippie_blue denomination_width_custom`}
                          // style={isTablet ? {marginBottom: "-3px", marginTop: "5px"} : {}}
                          >
                            <p className="_custom_label padding_custom_label">
                              <IntlMessage
                                id={"public.request.right.form.message_tone.placeholder"}
                              />
                            </p>
                            <Select
                              fullWidth
                              className={`${(!valueMessageTone && !isMobile && !isTablet) && "my-control2"} `}
                              IconComponent={ExpandMore}
                              value={values?.prayer_customization?.message_tone || ""}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                className: "new-design border_selected_custom",
                              }}
                              onOpen={() => {
                                setValueMessageTone(true)
                              }}
                              onChange={event => {
                                setValueMessageTone(false)
                                setValues(escapeObject({
                                  ...values,
                                  prayer_customization: {
                                    ...values.prayer_customization,
                                    message_tone: event.target.value,
                                  },
                                }, false))
                              }}
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                                variant: "filled",
                              }}
                            >
                              <MenuItem value="" disabled>
                                <IntlMessage id="message_tone.name.not_selected" />
                              </MenuItem>
                              {messageTones.map(messageTone => (
                                <MenuItem key={messageTone.id} value={messageTone.id}>
                                  {messageTone.label}
                                  <CheckIcon />
                                </MenuItem>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                      )}

                      {!edit ? (
                        <>
                          <DetailItem
                            className="custom_special_request_not_edit"
                            label="public.request.step3.left.request_details.customization.special_request"
                            displayValue={values.prayer_customization.special_request}
                            i18n={false}
                          />
                          <ErrorMessageWrapper
                            touched={{ special_request: true }}
                            errors={requestErrors}
                            fieldName="special_request"
                          />
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col lg="12" className={"step3-customization-input padding_left_special_request "}>
                              <TextField
                                id="special_request"
                                label={
                                  <IntlMessage
                                    id={`public.request.step3.left.request_details.customization.special_request`}
                                  />
                                }
                                value={values.prayer_customization.special_request}
                                className={` ${valueSpecialRequest && "background_focus_changed custom_label_color_hover"}  margin_top_special_request_edit_mode bg_lighter_hippie_blue padding_left_special_request custom_label_text_input width_special_request `}
                                multiline
                                onBlur={() => {
                                  setValueSpecialRequest(false)
                                }}
                                onFocus={() => {
                                  setValueSpecialRequest(true)
                                }}
                                onChange={e => {
                                  setValues(escapeObject({
                                    ...values,
                                    prayer_customization: {
                                      ...values.prayer_customization,
                                      special_request: e.target.value,
                                    },
                                  }, false))
                                }}
                              />
                              <ErrorMessageWrapper
                                touched={{
                                  prayer_customization: { special_request: true },
                                }}
                                errors={errors}
                                fieldName="prayer_customization.special_request"
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomizationDetails;
