import axios from "axios";

import { apiEndpoints } from "@iso/helpers"

const list = () => {
  return axios.get(apiEndpoints.prayer_types);
}

const getById = ({ id }) => {
  return axios.get(`${apiEndpoints.prayer_types}/${id}`);
};

export default {
  list, 
  getById
};
