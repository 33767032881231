import axios from "axios";
import { apiEndpoints } from "@iso/helpers";
import { escapeObject } from "@iso/helpers/index";

const flagRequest = (id, body) => {
  return axios.put(`${apiEndpoints.requests}/${id}/flag`, body);
};

const createRequest = (body) => {
  body = escapeObject(body, false);
  return axios.post(apiEndpoints.requests, body)
}

const adminShowRequest = (id) => {
  return axios.get(`${apiEndpoints.admin_prayer_requests}/${id}`);
};


const adminApprovalRequest = ({ id, action }) => {
  return axios.put(`${apiEndpoints.admin_prayer_requests}/${id}/${action}`);
};

const adminGetRequests = (status, options = {}) => {
  return axios.get(apiEndpoints.admin_prayer_requests, {
    params: {
      status: status,
      page_size: options.page_size,
      ...options
    },
  });
};

const adminChangeAssignee = (id, prayer_id, isAutoAssign = false) => {
  return axios.put(
    `${apiEndpoints.admin_prayer_requests}/${id}/change_assign`,
    {
      prayer_partner_id: prayer_id,
      is_auto_assign: isAutoAssign
    }
  );

};

const adminUpdateCompletion = (body) => {
  return axios.put(
    `${apiEndpoints.admin_prayer_requests}/${body.id}/update_complete`,
    body
  );
};

const adminTogglePauseRequest = ({ id, status, flag_types }) => {
  return axios.put(`${apiEndpoints.admin_prayer_requests}/${id}/${status}`, flag_types);
};

const adminDeleteRequest = ({ id }) => {
  return axios.delete(`${apiEndpoints.admin_prayer_requests}/${id}`);
};

const adminArchiveRequest = ({ id }) => {
  return axios.delete(`${apiEndpoints.admin_prayer_requests}/${id}/archive`);
};

const adminTranslateRequest = ({ id }) => {
  return axios.put(`${apiEndpoints.admin_prayer_requests}/${id}/translate`);
}

const partnerTranslateRequest = ({ id }) => {
  return axios.put(`${apiEndpoints.prayer_requests}/${id}/translate`);
}

export const updateEmailRequest = (id, body) => {
  return axios.put(`${apiEndpoints.requests}/${id}/update`, body);
};

const acceptPrayerRequest = (id) => {
  return axios.put(`/prayer_requests/${id}/accept`);
};

const declinePrayerRequest = (id) => {
  return axios.put(`/prayer_requests/${id}/decline`);
};

const confirmCompletePrayerRequest = (id, body) => {
  return axios.put(`/prayer_requests/${id}/complete`, body);
};

const sendRequestPdfViaEmail = (id) => {
  return axios.get(`${apiEndpoints.prayer_requests}/${id}/send_pdf`);
}

const getPartnerChainRequest = (id) => {
  return axios.get(`${apiEndpoints.prayer_requests}/${id}/chain_request`);
}

const getAdminChainRequest = (id) => {
  return axios.get(`${apiEndpoints.admin_prayer_requests}/${id}/chain_request`);
}

const noticeWrongEmail = (id) => {
  return axios.post(`${apiEndpoints.admin_prayer_requests}/${id}/notice-wrong-email`);
};

const updateRecipientEmail = (body) => {
  return axios.put(`${apiEndpoints.prayer_requests}/update-recipient-email`, body);
};

const checkUpdateEmailToken = (token) => {
  return axios.post(`${apiEndpoints.prayer_requests}/update-recipient-email/check-token`, {
    token: token
  });
};

const RequestService = {
  flagRequest,
  createRequest,
  adminGetRequests,
  adminShowRequest,
  adminChangeAssignee,
  adminApprovalRequest,
  adminUpdateCompletion,
  adminTogglePauseRequest,
  adminDeleteRequest,
	adminArchiveRequest,
  adminTranslateRequest,
  partnerTranslateRequest,
  updateEmailRequest,
  acceptPrayerRequest,
  declinePrayerRequest,
  confirmCompletePrayerRequest,
  sendRequestPdfViaEmail,
  getPartnerChainRequest,
  getAdminChainRequest,
  noticeWrongEmail,
  updateRecipientEmail,
  checkUpdateEmailToken
};

export default RequestService;
