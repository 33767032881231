import * as Yup from "yup";
import { NAME_MIN_LENGTH, NAME_MAX_LENGTH } from "./constants"

export const ProfileInfoFormValidator = Yup.object().shape({
    first_name: Yup.string()
    .label("First name")
    .trim()
    .required()
    .test(
        "minLength",
        "First name is invalid! Please check your input!",
        (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
        "maxLength",
        "First name is invalid! Please check your input!",
        (val) => val && val.length <= NAME_MAX_LENGTH
    )
    .matches(/^[A-Za-z ]+$/, 'The field should have letters only'),
    last_name: Yup.string()
    .label("Last name")
    .trim()
    .required()
    .nullable()
    .test(
        "minLength",
        "Last name is invalid! Please check your input!",
        (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
        "maxLength",
        "Last name is invalid! Please check your input!",
        (val) => val && val.length <= NAME_MAX_LENGTH
    )
    .matches(/^[A-Za-z ]+$/, 'The field should have letters only'),
    email: Yup.string()
    .label("Email")
    .email("Please enter a correct email!")
    .required(),
    phone_number: Yup.string()
    .label("Phone number")
    .nullable()
    .trim()
    .test("phoneNumber", "Only allow numbers", function(value){
        if (value == '') {
            return true
        } else {
        let letters = /^[0-9+ ]+$/
        if (value.match(letters)) {
            return true
        }
        return false
        }
    })

})