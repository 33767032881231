import React from "react";
import styled from "styled-components";
import Button from "@iso/components/AtomicComponents/Button";
import SubscribeTitle from "@iso/assets/images/landing_subscribe_title.svg";
import SubscribeTitleMobile from "@iso/assets/images/landing_subscribe_title_mobile.svg";
import IntlMessage from "@iso/components/intlMessage";
import { useSelector, useDispatch } from "react-redux";
import confirmRequestModalActions from "@iso/actions/confirmRequestModal";
import welcomePrayerCloudActions from '@iso/actions/WelcomePrayerCloudActions'
import {isMobile, isTablet} from "react-device-detect";
const Wrapper =styled.div`
    display: flex;
    justify-content: center;
    font-family: "BrandonText";
    flex-direction: column;

    @media only screen and (max-width: 960px) {
        align-items: center;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      align-items: baseline;
    }
`;

const Title = styled.img`
  @media only screen and (min-width: 960px) {
    align-self: flex-start;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    height: 120px;
  }
`;

const Subtitle = styled.p`
  font-weight: bold;
  font-size: 46px;
  margin-top: 20px;

  @media (min-width: 768px) and (max-width: 1024px) {
    font-weight: bold;
    font-size: 38px;
    line-height: 48px;
    margin-top: 24px;
  }
  @media only screen and (max-width: 767px) {
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #1C1634;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    font-weight: normal;
    font-size: 20px;
    text-align: center;
    color: #1C1634;
  }
`;

const SubscribeButton = styled(Button)`
  margin-top: 40px;
  width: 250px;
  height: 60px;
  @media only screen and (max-width: 375px){
    height: 50px;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    height: 50px;
    margin-top: 12px;
    margin-bottom: 10px;
    width: 290px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    float: left;
  }
`;

const RightSide = props => {
    const user = useSelector((state) => state.authentication.user);
    const dispatch = useDispatch();

  const handleClick = () => {
    if(!user){
      if(isMobile && !isTablet){
        dispatch(confirmRequestModalActions.openCustomModalMobile())
      }
      else{
        dispatch(confirmRequestModalActions.openCustomModal())
      }
    }else{
        dispatch(
            welcomePrayerCloudActions.openModal({
              type: welcomePrayerCloudActions.SHOW_PRAYER_CLOUD_MODAL,
              payload : {
                isOpen: true
              }
            })
          )
    }
}
    return <Wrapper>
        <Title className="subject-title-img" src={isMobile ? (isTablet ? SubscribeTitle : SubscribeTitleMobile) : SubscribeTitle} alt="Subscribe"></Title>
        <Subtitle><IntlMessage id="landing.subscribe.subtitle" /></Subtitle>
        <SubscribeButton variant="primary"
        handleClick = {handleClick}>
        {
            user? <IntlMessage id = "user.form.subscribed.Subscribe.button.title"/> :
            <IntlMessage id = "user.form.subscribed.SignUp.button.title"/>
        }
        </SubscribeButton>
    </Wrapper>
};

export default RightSide;
