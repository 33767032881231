import React from "react";
import PaymentCardItem from "@iso/components/Share/Payment/PaymentCardItem";
import { Row, Col } from "react-bootstrap";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import IntlMessage from "@iso/components/intlMessage";
import PaymentUtils from "@iso/components/Share/Payment/utils";

const PaymentMethodForm = ({
  paymentCards,
  setPaymentCards,
  setDefaultCard,
  defaultCard,
}) => {
  const [selectedCard, setSelectedCard] = React.useState({});
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = React.useState(
    false
  );

  return (
    <Row>
      {paymentCards.map((item) => {
        return (
          <Col xs="12" md="6">
            <PaymentCardItem
              key={item.id}
              item={item}
              defaultCard={defaultCard}
              setDefaultCard={setDefaultCard}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              setOpenConfirmDeleteModal={setOpenConfirmDeleteModal}
              allowDelete={false}
              allowSetDefaultCard={false}
            />
          </Col>
        );
      })}

      <ConfirmModal
        open={openConfirmDeleteModal}
        handleConfirm={() =>
          PaymentUtils.handleDetachCard({
            id: selectedCard.id,
            successCallback: (data) => {
              setPaymentCards(data);
            },
            closeModalCallback: () => {
              setOpenConfirmDeleteModal(false);
            },
          })
        }
        handleClose={() => setOpenConfirmDeleteModal(false)}
        title={<IntlMessage id="confirm_modal.delete_cards.title" />}
        content={<IntlMessage id="confirm_modal.delete_cards.content" />}
        okText={<IntlMessage id="common.yes" />}
        cancelText={<IntlMessage id="common.no" />}
      />
    </Row>
  );
};

export default PaymentMethodForm;
