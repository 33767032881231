import { SwipeableDrawer, withStyles } from '@material-ui/core'
import React from 'react'
import './OopsPopup.scss'
import OopsPopupValidator from './OopsPopupValidator'
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap"
import { Formik } from "formik"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import IntlMessage from "@iso/components/intlMessage";
import TextField from '@material-ui/core/TextField';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import requestStepActions from "@iso/actions/RequestStepActions";

const OopsPopup = (props) => {
    const {isOpen, handleOpen, handleClose} = props
    const dispatch = useDispatch();
    const requestStepData = useSelector(
        (state) => state.requestStepReducer,
        shallowEqual
      );
    let initValue = {
        recipient_email: "",
      }

      const handleSubmit =  (values, bag) => {
        dispatch(requestStepActions.setDataStep({...requestStepData, recipient_email: values.recipient_email}));
        handleClose()
      }
    

    return <SwipeableDrawer  
    anchor='right'
    open={isOpen}
    onClose={handleClose}
    onOpen={handleOpen}
    >
        <div id="oops-popup">
            <h2 className="status">Your payment was accepted</h2>

            <h3 className="title">Oops!</h3>

            <p>Please add gift recipient's email for notification</p>

            <Formik
      initialValues={initValue}
      enableReinitialize={false}
      onSubmit={handleSubmit}
      validationSchema={OopsPopupValidator}
      className="modalSignInContainer"
    >
      {({ handleSubmit, handleChange, touched, errors }) => (
        <form onSubmit={handleSubmit} noValidate id="sign-up-form">
          <Form.Group as={Row} className="justify-content-center">
            <Col sm={{ span: "12" }}>
              <TextField
                name="recipient_email"
                fullWidth={true}
                variant="filled"
                className='my-control'
                type="text"
                label={
                  <IntlMessage id="public.request.form.is_git.label" />
                }
                onChange={handleChange}
              />
              <ErrorMessageWrapper
                touched={touched}
                errors={errors}
                fieldName="recipient_email"
              />
            </Col>
          </Form.Group>

          <div className="buttons text-center mt-2" >
            <Button className="hippie-blue-sign-in-button" type="submit" style={{width: '100%'}}>
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
 
        </div>


    </SwipeableDrawer>
}

export default OopsPopup
