import { userConstants } from "@iso/constants"
const initialState = {}

export function cardData(state = initialState, action) {
  switch (action.type) {
    case userConstants.SET_CARD:
      return {
        is_default_card: action.payload.is_default_card,
        token: action.payload.token,
      }
    case userConstants.REMOVE_CARD:
      return {}
    case userConstants.SET_IS_SAVE_CARD:
      return {
        is_save_card: action.payload
      }
    default:
      return state
  }
}
