import {Grid} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Leftside from "./Leftside";
import RightSide from "./Rightside";
import {isTablet} from "react-device-detect";

const Wrapper = styled(Grid)`
  position: relative;
  margin-top: 50px;

  @media only screen and (min-width: 1025px) {
    margin-top: 80px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 85px;
  }
`;

const CloudTop = styled.img`
  position: absolute;
  top: -100px;
  right: 9%;
`;

const CloudBottom = styled.img`
  width: 100%;
  position: absolute;
  bottom: -70px;

  @media only screen and (min-width: 767px) {
    width: 80%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Section3 = (props) => {
  return (
    isTablet
      ?
      <Wrapper container spacing={0}>
        <Grid item xs={6}>
          <Leftside></Leftside>
        </Grid>
        <Grid item xs={6}>
          <RightSide></RightSide>
        </Grid>
      </Wrapper>
      :
      <Wrapper container spacing={0}>
        <Grid item xs={12} md={6}>
          <Leftside></Leftside>
        </Grid>
        <Grid item xs={12} md={6}>
          <RightSide></RightSide>
        </Grid>
      </Wrapper>
  );
};

export default Section3;
