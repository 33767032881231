import React from "react";
import { Card, Button, Collapse } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import IntlMessage from "@iso/components/intlMessage";
import EditIcon from "@material-ui/icons/Edit";
import "./styles.scss";
import ProfileForm from "./ProfileForm";
import ApproveAllButton from "./ApproveAllButton";
import PrayerPartnerService from "@iso/services/PrayerPartnerService";

import alertActions from "@iso/actions/Alert";
import prayerPartnerActions from "@iso/actions/PartnerActions";

import withAuthorization from "@iso/utils/withAuthorization";
import { USER_PERMISSIONS, userRoleConstants } from "@iso/constants";
import banAccountActions from "@iso/actions/banAccountActions";

const { openAlert } = alertActions;
const { setPrayerPartnerProfile } = prayerPartnerActions;

const ProfileCard = (props) => {
  const {
    openProfile = true,
    profileId,
    handleUpdateProfile,
    handleUpdateNoteAdmin,
    idForUpdate,
    role = userRoleConstants.ADMIN_ROLE,
  } = props;

  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { profile } = useSelector((state) => state.prayerPartnerReducer);
  
  const getProfile = ({ id }) => {
    setIsLoading(true);
    try {
      PrayerPartnerService.getPrayerPartnerById({ id }).then((res) => {
        const { code, data, message } = res.data;
        if (code === 200 && data) {
          if (!data.religion) {
            data.religion = [];
          }

          if (!data.phone_number) {
            data.phone_number = "";
          }
          
          dispatch(banAccountActions.setBanAccountData(data))
          dispatch(setPrayerPartnerProfile({ profile: data }));
        } else {
          dispatch(
            openAlert({
              message: message,
              severity: "error",
            })
          );
        }
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (profileId) {
      getProfile({ id: profileId });
    }
  }, [profileId]);

  const ApproveAllButtonWithAuth = withAuthorization(
    USER_PERMISSIONS.SUPER_USER
  )(ApproveAllButton);

  return (
    <>
      {!isLoading && Object.keys(profile).length > 0 && (
        <div className="p-md-3 profile_card">
          <Card.Title className="title p-0 m-0 px-3">
            <span>
              <IntlMessage id="prayer_partners.account_settings.profile.title" />
            </span>
            {
              (role === userRoleConstants.SUPER_ADMIN_ROLE && !profile?.deleted_at) &&
              <Button
                disabled={profile.status === 'invitation'}
                className="float-right hover_pointer btn hippie-blue-link edit_btn"
                onClick={() => setIsEdit(true)}
                >
                <EditIcon className="pr-1 pb-1" />
                <IntlMessage id="prayer_partners.account_settings.profile.btn.edit" />
              </Button>
            }
            
            {role === userRoleConstants.SUPER_ADMIN_ROLE && <ApproveAllButtonWithAuth profile={profile} />}
          </Card.Title>
          <Collapse in={openProfile}>
            <Card.Body>
              <ProfileForm
                profile={profile}
                isLoading={isLoading}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                handleUpdateNoteAdmin={handleUpdateNoteAdmin}
                handleUpdateProfile={handleUpdateProfile}
                idForUpdate={idForUpdate}
              />
            </Card.Body>
          </Collapse>
        </div>
      )}
    </>
  );
};

export default ProfileCard;
