import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    height: "100%",
    minHeight: "calc(100vh - 255px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 130px)",
    },
  },
}));

export default useStyles;
