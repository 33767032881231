import React from "react"
import styled from "styled-components"
import PrayerTitleImg from "@iso/assets/images/landing_most_requested_title.svg"
import PrayerTitleImgMobile from "@iso/assets/images/landing_most_requested_title_mobile.svg"

import "./index.scss"
import PrayerDad from "@iso/assets/images/landing_prayers_dad.png"
import PrayerAngel from "@iso/assets/images/landing_prayers_angel.png"
import PrayerPet from "@iso/assets/images/landing_prayers_pet.png"
import PrayerPetition from "@iso/assets/images/landing_prayers_petition.png"
import PrayerHealth from "@iso/assets/images/landing_prayers_health.png"
import PrayerBirthday from "@iso/assets/images/landing_prayers_birthday.png"
import MemorialPrayer from "@iso/assets/images/landing_prayers_memorial.png"
import PetitionForLove from "@iso/assets/images/landing_prayers_love.png"
import PrayerMom from "@iso/assets/images/landing_prayers_mom.png"
import PrayerWork from "@iso/assets/images/landing_prayers_work.png"
import PrayerOfPeace from "@iso/assets/images/landing_prayer_of_peace.png"
import PregnancyPrayer from "@iso/assets/images/landing_pregnancy_prayer.png"
import Section4PrayerTitle from "@iso/assets/images/section4_prayer_title.svg"

import { isBrowser, isMobile, isTablet } from "react-device-detect"
import PrayerItem from "./PrayerItem"
import { Grid } from "@material-ui/core"
import splitArr from "@iso/utils/SplitArray"
import IntlMessage from "@iso/components/intlMessage"
import PopupBeta from "../../../../../actions/PopupBeta";

const Wrapper = styled.div`
  font-family: "BrandonText";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 50px;

  @media only screen and (min-width: 900px) {
    margin-top: 104px;
  }
  @media only screen and (max-width: 500px){
    padding-left: 10px;
    padding-right: 10px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    margin-top: 60px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  @media only screen and (min-width: 1920px) {
    margin-top: -10px;
  }
  
  @media only screen and (min-width: 1000px) {
    margin-left: 110px;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 375px){
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    margin-bottom: 23px;
  }
`

const Title = styled.h3`
  font-size: 46px;
  font-weight: bold;
  margin-top:12px;
  @media only screen and (max-width: 1000px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 375px) {
    margin-top:-6px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    margin-top:-6px;
    font-size: 32px;
  }
`

const PrayerTitle = styled.img`
  @media only screen and (min-width: 1000px) {
    margin-left: 0px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    height: 110px;
  }
`

const Subtitle = styled.p`
  font-size: 18px;
  text-align: center;
  color: #1C1634;
  @media only screen and (min-width: 1000px) {
    margin-top: -87px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 376px) {
    color: #000000;
    font-size: 16px;
    width: 80%;
    margin-top: -6px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    color: #000000;
    font-size: 18px;
    width: 80%;
    margin-top: -4px;
  }
`

const PrayerContainer = styled(Grid)`
  justify-content: center;

  @media only screen and (min-width: 900px) {
    margin-top: 50px;
  }

  @media only screen and (max-width: 375px) {
    margin-top: -40px;
    padding-left:5px;
    padding-right:5px;
    width: 375px!important;
    margin-left:auto;
    margin-right:auto;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    margin-top: -40px;
    padding-left:0px;
    padding-right:5px;
    width: 430px!important;
    margin-left:auto;
    margin-right:auto;
  }
`

const PrayerListTablet = [
  {
    id: 1,
    icon: PrayerDad,
    title: <IntlMessage id="landing.prayers.item1" />,
  },

  {
    id: 3,
    icon: PrayerAngel,
    title: <IntlMessage id="landing.prayers.item2" />,
  },

  {
    id: 4,
    icon: PrayerPet,
    title: <IntlMessage id="landing.prayers.item3" />,
  },

  {
    id: 7,
    icon: PrayerBirthday,
    title: <IntlMessage id="landing.prayers.item6" />,
  },

  {
    id: 9,
    icon: PetitionForLove,
    title: <IntlMessage id="landing.prayers.item8" />,
  },

  {
    id: 8,
    icon: MemorialPrayer,
    title: <IntlMessage id="landing.prayers.item7" />,
  },

  {
    id: 5,
    icon: PrayerPetition,
    title: <IntlMessage id="landing.prayers.item4" />,
  },

  {
    id: 6,
    icon: PrayerHealth,
    title: <IntlMessage id="landing.prayers.item5" />,
  },

  {
    id: 2,
    icon: PrayerMom,
    title: <IntlMessage id="landing.prayers.item9" />,
  },

  {
    id: 10,
    icon: PrayerWork,
    title: <IntlMessage id="landing.prayers.item10" />,
  },

  {
    id: 13,
    icon: PrayerOfPeace,
    title: <IntlMessage id="landing.prayers.item11" />,
  },

  {
    id: 12,
    icon: PregnancyPrayer,
    title: <IntlMessage id="landing.prayers.item12" />,
  },
];

const PrayerListGlobal = [
  {
    id: 1,
    icon: PrayerDad,
    title: <IntlMessage id="landing.prayers.item1" />,
  },

  {
    id: 3,
    icon: PrayerAngel,
    title: <IntlMessage id="landing.prayers.item2" />,
  },

  {
    id: 4,
    icon: PrayerPet,
    title: <IntlMessage id="landing.prayers.item3" />,
  },

  {
    id: 5,
    icon: PrayerPetition,
    title: <IntlMessage id="landing.prayers.item4" />,
  },

  {
    id: 6,
    icon: PrayerHealth,
    title: <IntlMessage id="landing.prayers.item5" />,
  },

  {
    id: 7,
    icon: PrayerBirthday,
    title: <IntlMessage id="landing.prayers.item6" />,
  },

  {
    id: 8,
    icon: MemorialPrayer,
    title: <IntlMessage id="landing.prayers.item7" />,
  },

  {
    id: 9,
    icon: PetitionForLove,
    title: <IntlMessage id="landing.prayers.item8" />,
  },

  {
    id: 2,
    icon: PrayerMom,
    title: <IntlMessage id="landing.prayers.item9" />,
  },

  {
    id: 10,
    icon: PrayerWork,
    title: <IntlMessage id="landing.prayers.item10" />,
  },
]

const Section4 = (props) => {
  let PrayerList1 = []
  let PrayerList2 = []

  const [show, setShow] = React.useState(false);
  const PrayerList = isTablet ? PrayerListTablet : PrayerListGlobal;

  if (isBrowser) {
    const cloneList = [...PrayerList]
    const SplitPrayerList = splitArr(cloneList)
    PrayerList1 = SplitPrayerList.arr1
    PrayerList2 = SplitPrayerList.arr2
  }

  return (
    <Wrapper id="section4">
      <div className="cover-title">
        {
          isTablet
              ? <img className="tablet-title" src={Section4PrayerTitle}/>
              : <TitleContainer>
                <Title>
                  <IntlMessage id="landing.step4.title"/>
                </Title>
                <PrayerTitle
                    src={PrayerTitleImg}
                    className="d-none d-md-block"
                ></PrayerTitle>
                <PrayerTitle
                    src={PrayerTitleImgMobile}
                    className="d-block d-md-none"
                ></PrayerTitle>
              </TitleContainer>
        }

        <Subtitle className="subtitle">
          <IntlMessage id="landing.step4.subtitle" />
        </Subtitle>
      </div>

      {/* Browser Responsive */}
      {isBrowser && !isTablet && (
        <>
          <PrayerContainer container spacing={3} className="margin_top_item_list_1">
            {PrayerList1.map((prayer, idx) => {
              return (
                <Grid
                  key={idx}
                  item
                  xs={6}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PrayerItem
                    setShow={setShow}
                    key={idx}
                    icon={prayer.icon}
                    title={prayer.title}
                    index={prayer.id}
                  />
                </Grid>
              )
            })}
          </PrayerContainer>

          <PrayerContainer container spacing={3} className="margin_top_item_list_2">
            {PrayerList2.map((prayer, idx) => {
              return (
                <Grid
                  key={idx}
                  item
                  xs={6}
                  md={2}
                  lg={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PrayerItem
                    key={idx}
                    icon={prayer.icon}
                    title={prayer.title}
                    index={prayer.id}
                    setShow={setShow}
                  />
                </Grid>
              )
            })}
          </PrayerContainer>
        </>
      )}

      {/* Phone Responsive */}
      {isMobile && (
        <PrayerContainer container className="prayer-container" spacing={4}>
          {PrayerList.map((prayer, idx) => {
            return (
              <Grid
                key={idx}
                item
                xs={isTablet ? 3 : 6}
                md={2}
                lg={2}
                className="d-flex justify-content-center align-items-center custom_item_prayer"
              >
                <PrayerItem
                  key={idx}
                  icon={prayer.icon}
                  title={prayer.title}
                  index={prayer.id}
                  setShow={setShow}
                />
              </Grid>
            )
          })}
        </PrayerContainer>
      )}
      {/* <PopupBeta show={show} setShow={setShow}/> */}
    </Wrapper>
  )
}

export default Section4
