import React from 'react'
import styled from 'styled-components'
import WhoPrayer from '@iso/assets/images/who-prayer-parter.svg'
import WhoPrayerMobile from '@iso/assets/images/who-prayer-parter-mobile.svg'
import WhoPrayerTablet from '@iso/assets/images/about_who_prayer_partner_tablet.svg'
import Rabbi from '@iso/assets/images/rabbi.png'
import LineSky from '@iso/assets/images/line_sky.png'
import { Grid } from '@material-ui/core'
import {isMobile, isTablet} from 'react-device-detect'
import IntlMessage from "@iso/components/intlMessage";
import CloudImgMobile from "@iso/assets/images/CloudAboutUsMobileSection2.svg"
import CloudImgMobileFooter from "@iso/assets/images/CloudAboutUsMobileFooterSection2.svg"
import Button from '@iso/components/AtomicComponents/Button.js'
import './index.scss'
import TellUsAboutModal from './TellUsAboutModal'
import { useHistory } from 'react-router';

const Wrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: BrandonText;
  padding: 56px 0px;
  color: #000000;
  @media only screen and (min-width: 1025px){
      padding-bottom: 0px;
      margin-bottom: 154px;

  }
  @media (min-width: 768px) and (max-width: 1024px){
      padding-bottom: 0px;
      padding-top: 30px;
  }

  @media only screen and (max-width: 500px){
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 1920px){
    padding-left: 220px;
    padding-right: 220px;
  }
`

const ImageCloud  = styled.img`
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 500px){
    display: block;
  }
`
const Section2ValueContainer = styled(Grid)`
  transform: translateX(4%);
  @media only screen and (max-width: 500px) {
      transform: translateX(9%);
      text-align: center;
      padding: 0 37px;
  }
`
const CallToActionBtn = styled(Button)`
    width: 173px;
    height: 48px;
    font-family: "BrandonText";
    margin: 0;
    font-size: 16px;

    @media only screen and (max-width: 500px) {
      margin-left: 5px;
      margin-bottom: 50px;
      padding: 10px 10px;
    }
`

const HeaderSubtitle = styled.span`
  font-weight: bold;
  display: block;
  font-size: 22px;
  margin-bottom: 35px;
  @media only screen and (max-width: 500px){
    font-size: 18px;
    margin-bottom: 27px;
    padding: 0px 15px!important;
  }
`

const ContentSubtitle = styled.span`
  display: block;
  font-size: 18px;
  margin-bottom: 35px;
  color: #000000;
  @media only screen and (max-width: 500px){
    margin-bottom: 27px;
  }
  @media only screen and (max-width: 360px){
    font-size: 17px;
  }
  @media only screen and (min-width: 1024px) {
    margin-bottom: 20px;
  }
`

const ContentSubtitleTablet = styled.span`
  display: block;
  font-size: 18px;
  margin-bottom: 35px;
  color: #000000;
  line-height: 32px;
  @media only screen and (max-width: 360px){
    font-size: 17px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 15px;
  }
`
const ContentUrlSubtitle = styled.a`
  color: #499e9e;
  text-decoration: underline;
`

const ImageContainer = styled.div`
  margin-top: 30px;
  text-align: center;
  border-radius: 50%;
  padding-top: 60px;
  @media only screen and (max-width: 500px){
    margin-left: -75px;
    margin-bottom: -25px;
    padding-top: 0;
  }
`
const ImageJana = styled.img`
  margin-bottom: 20px;
  height: 144px;
  @media only screen and (max-width: 500px) {
    width: 80px;
    height: 80px;
  }
`

const ImageTitle = styled.div`
  margin-top: 6px;
  font-size: 18px;
  color: #1C1634;
`

const ImageAuthorTitle = styled.div`
  margin-top: 10px;
  font-size: 22px;
  font-weight:bold;
  @media (min-width: 768px) and (max-width: 1024px){
      margin-top: 13px
  }
`

const ImageWhoPrayer = styled.img`

`

const ContentContainer = styled.div`
  padding-right: 207px;
  margin-left: -73px;
  @media only screen and (max-width: 376px){
    margin-left: -75px;
    padding: 0 37px;
    margin-top: -65px;
    line-height: 30px;
  }
  @media only screen and (min-width: 410px) and (max-width: 425px){
    margin-left: -75px;
    padding: 0 55px;
    margin-top: -65px;
    line-height: 30px;
  }
  @media only screen and (min-width: 425px) and (max-width: 500px){
    margin-left: -75px;
    padding: 0 67px;
    margin-top: -65px;
    line-height: 30px;
  }
  @media only screen and (min-width: 400px) and (max-width: 410px){
    margin-left: -75px;
    padding: 0 37px;
    margin-top: -65px;
    line-height: 30px;
  }

  @media only screen and (max-width: 280px){
    margin-left: -30px;
  }
  @media only screen and (min-width: 390px) and (max-width: 400px){
    margin-left: -75px;
    padding: 0 45px;
    margin-top: -65px;
    line-height: 30px;
  }
`

const Title = styled.div`
  font-size: 46px;
  font-weight: bold;
  text-align: center;
  padding-top: 32px;
  width: 100%;
  @media only screen and (max-width: 500px){
    font-size: 30px;
  }
`
const CloudMobileFooterContainer = styled.div`
  position: relative;
`
const CloudImgMobileWrapper = styled.img`
  position: absolute;
  right: -187px;
  top: -7px;
  z-index: 1;
  @media only screen and (min-width: 377px) and (max-width: 500px){
    right: -207px;
    top: -13px;
    width: 390px;
  }
`
const LinkToPage = styled.a`
  color: #429E9D !important;
  cursor: pointer;
`
const Section2 = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory();

  const handleOpen = () => {
    setIsOpen(true);
  }

  return (
  <Wrapper container>

    {/* Mobile */}

    <ImageCloud src = {CloudImgMobile} alt ="Cloud" className="custom_img_cloud_mobile_section_2 d-block d-md-none mt-2"/>

    {
      isTablet
      ?
        <img src={WhoPrayerTablet} alt="Who Prayer"/>
      :
      <TitleWrapper>
        <Title><IntlMessage id ="user.form.about.section2.title"/></Title>
        {
          !isMobile ?  <ImageWhoPrayer src={WhoPrayer} alt ="Who Prayer"/>
          : <ImageWhoPrayer src={WhoPrayerMobile} alt ="Who Prayer"/>
        }
      </TitleWrapper>
    }

    {
      isTablet
        ?
        <Grid style={{ marginTop: "30px" }} container>
          <Grid item xs={6} className="d-flex justify-content-center align-items-right">
            <ImageContainer>
              <ImageJana src={Rabbi}/>
              <ImageTitle className="d-none d-md-block"><IntlMessage
                id="user.form.about.section2.person.comment.title"/></ImageTitle>
              <ImageAuthorTitle className="font-weight-bold d-none d-md-block"><IntlMessage id="user.form.prayer.jana"/></ImageAuthorTitle>
            </ImageContainer>
          </Grid>
          <Grid item xs={6} className="d-flex justify-content-center align-items-right">
            <div style={{width: "330px", marginLeft: "-20px"}}>
              <HeaderSubtitle className="px-2 px-md-0"><IntlMessage
                id="user.form.about.section2.mission.title"/></HeaderSubtitle>
              <ContentSubtitleTablet>
                <IntlMessage id="user.form.about.section2.reason1.title"/><br></br>
                <IntlMessage id = "user.form.about.section2.reason1.sub.about.title"/>{" "}
                  <LinkToPage className='' onClick={() => history.push('/prayer-partners')}>
                    <IntlMessage id = "user.form.about.section2.reason1.sub.about.prayerPartnerPage"/>
                  </LinkToPage>
              </ContentSubtitleTablet>

              <div className='border-dashed-sky mb-4'>
                <img src={LineSky} alt="Break line"/>
              </div>
              <ContentSubtitleTablet>
                <IntlMessage id="user.form.about.section2.reason2.part2.title"/>
              </ContentSubtitleTablet>
              <CallToActionBtn variant="primary" handleClick={handleOpen}>
                <IntlMessage id="user.form.about.section2.reason2.part2.btn" />
              </CallToActionBtn>
            </div>
          </Grid>
        </Grid>
        :
        <Section2ValueContainer container spacing={4}>
          <Grid item xs={12} lg={6} className="d-flex justify-content-center align-items-right about-left-custom">
            <ImageContainer>
              <ImageJana src={Rabbi}/>
              <ImageTitle className="d-none d-md-block"><IntlMessage
                id="user.form.about.section2.person.comment.title"/></ImageTitle>
              <ImageAuthorTitle className="font-weight-bold d-none d-md-block"><IntlMessage id="user.form.prayer.jana"/></ImageAuthorTitle>
            </ImageContainer>
          </Grid>
          <Grid item xs={12} lg={6} className="d-flex justify-content-center align-items-right about-right-custom"
                style={{paddingTop: '80px'}}>
            <ContentContainer>
              <HeaderSubtitle className="px-2 px-md-0"><IntlMessage
                id="user.form.about.section2.mission.title"/></HeaderSubtitle>
              <ContentSubtitle className="d-none d-md-block">
                <IntlMessage id="user.form.about.section2.reason1.title"/><br></br>
                <IntlMessage id = "user.form.about.section2.reason1.sub.about.title"/>{" "}
                  <LinkToPage className='' onClick={() => history.push('/prayer-partners')}>
                    <IntlMessage id = "user.form.about.section2.reason1.sub.about.prayerPartnerPage"/>
                  </LinkToPage>
              </ContentSubtitle>
              {/* Mobile  */}
              <ContentSubtitle className="d-block d-md-none mb-0"><IntlMessage
                id="user.form.about.section2.reason1.subtitle1"/></ContentSubtitle>
              <ContentSubtitle className="d-block d-md-none mb-0"><IntlMessage
                id="user.form.about.section2.reason1.subtitle2"/></ContentSubtitle>
              <ContentSubtitle className="d-block d-md-none">
                <IntlMessage id="user.form.about.section2.reason1.subtitle3"/><br></br>
                  <IntlMessage id = "user.form.about.section2.reason1.sub.about.title"/>{" "}
                  <LinkToPage className='' onClick={() => history.push('/prayer-partners')}>
                    <IntlMessage id = "user.form.about.section2.reason1.sub.about.prayerPartnerPage"/>
                  </LinkToPage>
              </ContentSubtitle>
              {/*  */}

              <div className='border-dashed-sky mb-4'>
                <img src={LineSky} alt="Break line"/>
              </div>

              <ContentSubtitle>
                <IntlMessage id="user.form.about.section2.reason2.part2.title"/>
              </ContentSubtitle>
              <CallToActionBtn variant="primary" handleClick={handleOpen}>
                <IntlMessage id="user.form.about.section2.reason2.part2.btn" />
              </CallToActionBtn>
            </ContentContainer>
          </Grid>
        </Section2ValueContainer>
    }

    {/* Mobile footer cloud */}
    <CloudMobileFooterContainer className="d-block d-md-none">
      <CloudImgMobileWrapper src={CloudImgMobileFooter} />
    </CloudMobileFooterContainer>

    <TellUsAboutModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />

  </Wrapper>
  )
}

export default Section2
