import React, { useState } from "react";

import { Row, Col, Container, Card, Badge, Button } from "react-bootstrap";
import TranslateReason from "@iso/components/TranslateReason/TranslateReason";
import { DATE_FORMAT_PRAYER_REQUEST } from "./constants";
import RequestItem from "@iso/components/Request/Item";
import moment from "moment";
import { roundUpAmount } from "@iso/utils/FormatData";
import IntlMessage from "@iso/components/intlMessage";
import RequestService from "@iso/services/RequestService";
import { useDispatch } from 'react-redux'
import alertActions from '../../actions/Alert'

const RequestDisplayBuilder = (props) => {
  const {
    request,
    forceShowTransactionContent,
    showTransactionCallback,
  } = props;

  const forSomeone = request.recipient === "for_someone"
  const isGift = !!request.is_gift
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  const [chainRequest, setChainRequest] = React.useState(false);
  const dispatch = useDispatch();
  const { openAlert } = alertActions;
  React.useEffect(() => {
    getChainRequest()
  }, [])

  const getChainRequest = () => {
    RequestService.getAdminChainRequest(request.id).then((res => {
      if(!res){
        dispatch(
          openAlert({
            message: <IntlMessage id="common.message.update.fail" />,
            severity: "error",
          })
        );
      }

      const { code, message } = res.data;
      if (code === 200 ) {
        setChainRequest(res.data.data);
      } else {
        dispatch(
          openAlert({
            message: message,
            severity: "error",
          })
        );
      }
    }))
  }

  const showChainRequest = () => {
    if (chainRequest && chainRequest.length > 0) {
      return chainRequest.map((request, index) =>
        <a href={request}>{request}{(index !== (chainRequest.length -1)) ? ',' : '' } </a>
      );
    }else {
      return (
        <span className={`font-weight-bold`}>(None)</span>
      );
    }
  }

  const getFrequencyLabel = (frequencyID) => {
    switch(frequencyID){
      case 'one_time':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.one_time" />
        )
      case 'daily':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.daily_1_week" />
        )
      case 'weekly':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.weekly_1_month" />
        )
      case 'monthly':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.monthly_1_year" />
        )
    }
  }

  return (
    <Container className="mt-5 request-details">
      <h2 className="text-center">
        {<IntlMessage id="admin.requests.details.request_details" />}
      </h2>
      <RequestItem
        title={<IntlMessage id="prayer_partners.table.title.prayer_number" />}
        value={request.id}
    />
      <RequestItem
        title={<IntlMessage id="prayer_partners.table.title.related_chain_request" />}
        value={showChainRequest()}
      />
      <RequestItem
        title={<IntlMessage id="admin.requests.details.client" />}
        value={request.client_name}
      />

      <RequestItem
        title={<IntlMessage id="admin.requests.details.email" />}
        value={request.client_email}
      />
      {forSomeone && (
        <>
          {isGift ? (
            <RequestItem
              title={<IntlMessage id="admin.requests.details.recipient" />}
              value={
                <IntlMessage
                  id="admin.requests.details.recipient_for_some_one"
                  values={{ recipient_name: request.recipient_name }}
                />
              }
            />
          ):(
            <RequestItem
              title={<IntlMessage id="admin.requests.details.recipient" />}
              value={
                <IntlMessage
                  id="admin.requests.details.recipient_for_some_one_not_gift"
                  values={{ recipient_name: request.recipient_name }}
                />
              }
            />
          )}
            <RequestItem
              title={<IntlMessage id="admin.requests.details.prayer_as_a_gift" />}
              value={
                isGift ? (
                  <IntlMessage id="common.yes" />
                ) : (
                  <IntlMessage id="common.no" />
                )
              }
            />
          {isGift && (
            <RequestItem
              title={<IntlMessage id="admin.requests.details.recipient_email" />}
              value={request.recipient_email}
            />
          )}
        </>
      )}

      {!forSomeone && (
          <>
            <RequestItem
                title={<IntlMessage id="admin.requests.details.recipient" />}
                value={
                  <IntlMessage
                      id="admin.requests.details.recipient_for_myself"
                      values={{ recipient_name: (request.client_id === null ? request.recipient_name : request.client_name ) }}
                  />
                }
            />
            <RequestItem
                title={<IntlMessage id="admin.requests.details.prayer_as_a_gift" />}
                value={
                  isGift ? (
                      <IntlMessage id="common.yes" />
                  ) : (
                      <IntlMessage id="common.no" />
                  )
                }
            />
            {isGift && (
                <RequestItem
                    title={<IntlMessage id="admin.requests.details.recipient_email" />}
                    value={request.recipient_email}
                />
            )}
          </>
      )}

      <TranslateReason
        title={<IntlMessage id="admin.requests.details.reason_for_prayer" />}
        translatedContent={request.reason_translate}
        originContent={request.reason}
        lang={request.reason_language}
        showOriginContent={
          forceShowTransactionContent && forceShowTransactionContent["reason"]
        }
        showTransactionCallback={showTransactionCallback}
        name="reason"
      />

      <RequestItem
        title={<IntlMessage id="admin.requests.details.due_date" />}
        value={<>
          {moment.utc(request.end_date_utc).utcOffset(time_zone).format(DATE_FORMAT_PRAYER_REQUEST)}
          {' '}
          <IntlMessage id={request.is_auto_assigned_end_date
            ? 'admin.requests.details.due_date_system'
            : 'admin.requests.details.due_date_user'
          }/>
        </>}
      />
      <RequestItem
        title={<IntlMessage id="admin.requests.details.denomination" />}
        value={request.denomination_label}
      />
      <RequestItem
        title={<IntlMessage id="admin.requests.details.type" />}
        value={request.type && request.type.name}
      />

       <RequestItem
        title={<IntlMessage id="admin.requests.details.message_tone" />}
        value={request.message_tone_label}
      />

      <TranslateReason
        title={<IntlMessage id="admin.requests.details.special_request" />}
        translatedContent={request.special_request_translate}
        originContent={request.special_request}
        lang={request.special_request_language}
        showOriginContent={
          forceShowTransactionContent &&
          forceShowTransactionContent["special_request"]
        }
        defaultShow={true}
        showTransactionCallback={showTransactionCallback}
        name="special_request"
      />

      <RequestItem
        title={<IntlMessage id="admin.requests.details.contribution_option" />}
        value={request.contribution.name}
      />
      <RequestItem
        title={<IntlMessage id="admin.requests.details.frequency" />}
        value={getFrequencyLabel(request.frequency)}
      />
      { request?.discount_amount 
        ? (
          <RequestItem
            title={<IntlMessage id="admin.requests.details.coupon_details" />}
            value={request?.coupon_info?.code 
              ? <>Code: {request?.coupon_info?.code} - Discount amount: ${(request?.discount_amount == null) 
                          ? (roundUpAmount(request?.transaction?.price_original - request?.transaction?.amount)) 
                          : roundUpAmount(request?.discount_amount)}</> 
              : <>Discount amount: ${(request?.discount_amount == null) 
                          ? (roundUpAmount(request?.transaction?.price_original - request?.transaction?.amount)) 
                          : roundUpAmount(request?.discount_amount)}</>
            }
          />
        ) : (
          (request?.transaction?.amount !== 0 || request?.transaction?.revenue !== 0) && 
          (
            <RequestItem
              title={<IntlMessage id="admin.requests.details.coupon_details" />}
              value={<>None</>}
            />
          )
        )
      }
    </Container>
  );
};

export default RequestDisplayBuilder;
