import React from "react";
import Title from "../Title";
import IntlMessage from "@iso/components/intlMessage";
import { Container, Row, Col } from "react-bootstrap";

import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, TextField, Button, CardHeader } from "@material-ui/core";
import { changePasswordValidator } from "./validations";
import useStyles from "./ChangePassword.style";
import "./ChangePassword.scss";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { useDispatch } from "react-redux";
import { setCurrentProfile } from "@iso/actions/User";
import { isMobile, isTablet } from "react-device-detect";

const ChangePassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch(); 
  const [showPassword, setShowPassword] = React.useState(false); 
  let initValue = {
    old_password: "",
    password: "",
    password_confirmation: "", 
  }
  const handleCancel = () => { 
    formik.resetForm(); 
  }
  
  const formik = useFormik({
    initialValues: initValue,
    validationSchema: changePasswordValidator,
    onSubmit: (values, { setErrors, resetForm }) => {
      UserService.changePassword(values).then((res) => {
        ProcessRequest.run({
          res,
          successCallback: (data) => {
            dispatch(setCurrentProfile(data));
            formik.resetForm()
          },
          errorCallback: ({ errors, message }) => {
            setErrors(errors);
          },
        });
      });
    },
  });

  const VisiblePasswordIcon = ({ name, setShowPassword }) => {
    const handleChangeVisiblePassword = () => {
      setShowPassword({ ...showPassword, [name]: !showPassword[name] });
    };

    return (
      <>
        {showPassword[name] ? (
          <VisibilityIcon
            onClick={handleChangeVisiblePassword}
            className="hover_pointer"
          />
        ) : (
          <VisibilityOffIcon
            onClick={handleChangeVisiblePassword}
            className="hover_pointer"
          />
        )}
      </>
    );
  };

  return (
    <Card className={`${classes.root} account_page__change_password`}>
      <CardHeader
        className={classes.header}
        title={<IntlMessage id="user.account.settings.change_password.title" />}
      />
      <CardContent className={classes.content}>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.rootGrid}>
            <Grid container spacing={0} className="justify-content-around">
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6} className={classes.gridItemRoot}>
                  <TextField
                    fullWidth={true}
                    autoComplete="old_password"
                    autoFocus
                    id="old_password"
                    name="old_password"
                    className={`my-control active-Form psd_confirm ${formik?.touched?.old_password && 
                      formik?.errors?.old_password ? 'border-errors' : 'active-Form'}`}
                    variant="filled"
                    label={
                      <>
                      {
                        formik.values.old_password ? 
                        <IntlMessage id="user.account.settings.form.label.old_password.focus" />
                        :
                        <>
                          <span className="for-focus">
                          <IntlMessage id="user.account.settings.form.label.old_password.focus" />
                          </span>
  
                          <span className="for-no-focus">
                          <IntlMessage id="user.account.settings.form.label.old_password" />
                          </span>
                        </>
                      }
                      </>
                      
                    }
                    type={showPassword["old_password"] ? "text" : "password"}
                    value={formik.values.old_password}
                    onChange={formik.handleChange}
                    error={
                      formik?.touched?.old_password &&
                      Boolean(formik?.errors?.old_password)
                    }
                    helperText={
                      formik?.touched?.old_password &&
                      formik?.errors?.old_password
                    }
                    InputProps={{
                      endAdornment: (
                        <VisiblePasswordIcon
                          name="old_password"
                          setShowPassword={setShowPassword}
                        />
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6} className={classes.gridItemRoot}>
                  <TextField
                    fullWidth={true}
                    autoComplete="password"
                    id="password"
                    name="password"
                    className={`my-control psd_confirm ${formik?.touched?.password && 
                      formik?.errors?.password ? 'border-errors' : 'active-Form'}`}
                    variant="filled"
                    label={
                      <>
                      {
                        formik.values.password ? 
                        <IntlMessage id="user.account.settings.form.label.new_password.focus" /> 
                        :
                        <>
                        <span className="for-focus">
                          <IntlMessage id="user.account.settings.form.label.new_password.focus" />
                        </span>

                        <span className="for-no-focus">
                          <IntlMessage id="user.account.settings.form.label.new_password" />
                      </span>
                        </>
                      }
                     </>
                    }
                    type={showPassword["password"] ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik?.touched?.password &&
                      Boolean(formik?.errors?.password)
                    }
                    helperText={
                      formik?.touched?.password && formik?.errors?.password
                    }
                    InputProps={{
                      endAdornment: (
                        <VisiblePasswordIcon
                          name="password"
                          setShowPassword={setShowPassword}
                        />
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} lg={6} className={classes.gridItemRoot}>
                  <TextField
                    fullWidth={true}
                    autoComplete="password_confirmation"
                    id="password_confirmation"
                    name="password_confirmation"
                    className={`my-control psd_confirm ${formik?.touched?.password_confirmation && 
                      formik?.errors?.password_confirmation ? 'border-errors' : 'active-Form'}`}
                    variant="filled"
                    label={
                      <IntlMessage id="user.account.settings.form.label.password_confirmation" />
                    }
                    type={showPassword["password_confirmation"] ? "text" : "password"}
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    error={
                      formik?.touched?.password_confirmation &&
                      Boolean(formik?.errors?.password_confirmation)
                    }
                    helperText={
                      formik?.touched?.password_confirmation &&
                      formik?.errors?.password_confirmation
                    }

                    InputProps={
                      formik?.touched?.password_confirmation &&
                      Boolean(formik?.errors?.password_confirmation) ?
                      {
                      endAdornment: (
                          <VisiblePasswordIcon
                              name="password_confirmation"
                              setShowPassword={setShowPassword}
                          />
                      ),
                    } : ''}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} className="mt-1">
              <Grid
                container
                item
                xs={12}
                lg={6}
                className="justify-content-around"
              >
                {!isMobile && <Grid item xs={6} lg={6}>
                  <Button
                    fullWidth={true}
                    type="button"
                    className={`${classes.buttonRoot} save-password-buttons`}
                    onClick={handleCancel}
                  >
                    <span className="font-weight-bold"><IntlMessage id="common.cancel" /></span>
                  </Button>
                </Grid>}
                <Grid item xs={isMobile ? (!isTablet ? 7 : 6) : 6} md={6}>
                  <Button
                    variant="contained"
                    fullWidth={true}
                    type="submit"
                    className={`${classes.buttonRoot} ${classes.buttonSave} save-password-buttons`}
                  >
                    <span className="font-weight-bold"><IntlMessage id="common.save" /></span>
                  </Button>
                </Grid>
                {isMobile && <Grid item xs={!isTablet ? 5 : 6} md={6}>
                  <Button
                    fullWidth={true}
                    type="button"
                    className={`${classes.buttonRoot} save-password-buttons`}
                    onClick={handleCancel}
                  >
                    <span className="font-weight-bold"><IntlMessage id="common.cancel" /></span>
                  </Button>
                </Grid>}
              </Grid>
            </Grid>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default ChangePassword;
