import React, { useState, useEffect } from "react";
import { Row, Col, Button, Badge, Card } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import {MDBBadge, MDBDataTable} from "mdbreact";
import { history } from "@iso/helpers/history";

import "./styles.scss";
import UserService from "@iso/services/UserService";

const UserIndex = () => {
  const tabArray = ["active", "banned", "unverified", "verified", "all"];
  const [userList, setUserList] = useState({});
  const [currentStatus, setCurrentStatus] = useState("active");
  const [counts, setCounts] = useState({});
  const headers = {
    active: ["id", "first_name", "last_name", "email", "denomination"],
    banned: ["id", "first_name", "last_name", "email", "denomination"],
	  verified: ["id", "first_name", "last_name", "email", "denomination"],
	  unverified: ["id", "first_name", "last_name", "email", "denomination"],
    all: ["id", "first_name", "last_name", "email", "denomination", "status"],
  };

  useEffect(() => {
    handleGetUsers("active");
  }, []);

  const handleClick = (id) => {
    history.push(`/admin/users/${id}`);
  };

  const formatTableData = (data, status) => {
    let dataRow = [];
    let header = headers[status].map((field) => {
      return {
        label: <IntlMessage id={`admin.users.table_header.${field}`} />,
        field: field,
      };
    });

    data.forEach((e, index) => {
      dataRow.push({
        id: e.id,
        first_name: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.first_name ? e.first_name.toLowerCase() : ''}
          >
            {
              e.first_name
            }
          </MDBBadge>
        ),
        last_name: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.last_name ? e.last_name.toLowerCase() : ''}
          >
            {
              e.last_name
            }
          </MDBBadge>
        ),
        email: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.email ? e.email.toLowerCase() : ''}
          >
            {
              e.email
            }
          </MDBBadge>
        ),
        denomination: e.religion_label,
        status: <span className="text-capitalize">{e.status}</span>,
        clickEvent: () => handleClick(e.id),
      });
    });
    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    };
  };

  const handleGetUsers = (status, options) => {
    UserService.adminGerUsers(status, options).then((res) => {
      if (res) {
        const { code, data, count } = res.data;
        if (res && code === 200) {
          let users = formatTableData(data.data, status);

          setUserList(users.data);
          setCurrentStatus(status);
          setCounts(count);
          if (users.data.rows.length == 0) {
            const tbody = document.querySelector(
              "tbody[data-test=table-body] tr td"
            )
            if(tbody) tbody.innerText = `There is no ${status} user.`;
          }
        }
      }
    });
  };

  return (
    <div className="mint-tulip-background p-3 admin-request-list-wrapper">
      <Row>
        <Col sm="6" className="text-md-left text-center mb-3">
          <h2>
            <IntlMessage id="admin.users.title" />
          </h2>
        </Col>
      </Row>

      <Card>
        <Row className="mt-3 mb-3 pl-5 pr-5 row">
          {tabArray.map((tab) => {
            return (
              <Col sm={{ span: "3" }} className="mb-1" key={tab}>
                <Button
                  variant={`${
                    currentStatus == tab ? "active-status" : "light"
                  } btn-block`}
                  onClick={() => {
                    handleGetUsers(tab);
                  }}
                >
                  <IntlMessage id={`admin.users.tab.${tab}`} />
                  <Badge className={`ml-2 ${tab !== 'active'?'badge-background': ''}`} pill variant="danger">
                    {counts ? (counts[tab] == 0 ? "" : counts[tab]) : 0}
                  </Badge>
                </Button>
              </Col>
            );
          })}
        </Row>
        <Row className="ml-2 mr-2">
          <Col sm="12">
            <MDBDataTable
              hover
              paging={true}
              id="requests_list_table"
              entries={25}
              searching={false}
              noBottomColumns={true}
              entriesOptions={[5, 20, 25]}
              data={userList}
              sortRows={['first_name', 'last_name', 'email']}
              className="hide_scrollbar hide_scollbar"
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default UserIndex;
