import React from "react"; 
import {  Form } from "react-bootstrap"
import { ExpandMore } from "@material-ui/icons"
import Select from "@material-ui/core/Select"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import IntlMessage from "@iso/components/intlMessage";
import MenuItem from "@material-ui/core/MenuItem"
import { translate } from "@iso/utils/Translate";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import "./RecordModal.scss"
import "./RecordPPForm.scss"

const RecordPPForm = (props) => {
  const {formikValues, setFormikValues, handleFieldChange, isEditBtn, 
    statusList, requestIdList, prayerPartnerList,touched, errors } = props; 
  return(
    <>
     <FormControl variant="outlined" id="prayer_request_id" className="w-100" >
       {
         !isEditBtn ? 
         <>
          {/* Add record */}
          <Autocomplete 
          id="prayer_request_id"
          options={requestIdList}
          getOptionLabel={(option) => `${option.id}`}
          getOptionValue={(option) => option.id} 
          onInputChange={(e, value) => {
              handleFieldChange('prayer_request_id', value)
          }}
          onChange={(e, value) => {
            if (value) {
              handleFieldChange('prayer_request_id', value?.id)
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="requestID"
              variant="outlined" 
              className="custom_field_record_pp_form_request_id_not_edit"
              label={<IntlMessage id ="admin.financial.add_record.requestID.title"/> }
              placeholder={translate(
                "admin.financial.add_record.requestID.title"
              )}
              fullWidth={true}
            />
          )}

        />
        <ErrorMessageWrapper
          touched={touched}
          errors={errors}
          fieldName="requestID"
        />
        </> 
        : 
        // Edit record
          <TextField
            name="requestID"
            variant="outlined" 
            disabled= {true}
            className="custom_field_record_pp_form_request_id"
            label={<IntlMessage id ="admin.financial.add_record.requestID.title"/> }
            placeholder={translate(
              "admin.financial.add_record.requestID.title"
            )}
            fullWidth={true}
            value={formikValues?.prayer_request_id}
          />
       }
       
        </FormControl>

        {/* Prayer Partner */}
        <FormControl variant="outlined" id="prayer_partner" className="w-100 custom_field_record_pp_form" >
        <InputLabel><IntlMessage id="admin.financial.header_table.pp"/></InputLabel>
        <Select
          fullWidth={true} 
          value={formikValues?.user_id || ''}
          IconComponent={ExpandMore} 
          disabled= {isEditBtn}
          name="user_id"
          label= {translate("admin.financial.header_table.pp")}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            className: "menuList",
          }}
          onChange={(e) => handleFieldChange('user_id', e.target.value)} 
          displayEmpty
          inputProps={{
            "aria-label": "Without label",
            variant: "filled",
          }}
          >
          {
            props.prayerPartnerList.map((item) => { 
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.first_name} {item.last_name}
                  {/* <CheckIcon /> */}
                </MenuItem>
              )
            })}
        </Select>
        <ErrorMessageWrapper
        touched={touched}
        errors={errors}
        fieldName="user_id"
        />
        </FormControl>

        {/* Revenue */}
        {/* {
          isEditBtn &&
          <>
            <Form.Group controlId="revenue" className="mb-4">
              <TextField
                label={<IntlMessage id ="admin.financial.header_table.revenue"/> }
                variant="outlined"
                name="revenue"
                autoFocus
                className="w-100"
                value = {formikValues?.revenue || ''} 
                onChange={(e) => handleFieldChange('revenue', e.target.value)} 
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="revenue"
                />
            </Form.Group>

            <Form.Group controlId="pp_percent" className="mb-4">
              <TextField
                label={<IntlMessage id ="admin.financial.add_record.pp.percent.title"/> }
                variant="outlined"
                disabled={formikValues?.status === 'completed'}
                name="pp_percent"
                autoFocus
                className="w-100"
                value = {formikValues?.pp_percent || ''}  
                onChange={(e) => handleFieldChange('pp_percent', e.target.value)}  
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="pp_percent"
                />
            </Form.Group>
          </>
        } */}
      
      <FormControl variant="outlined" className="w-100 custom_field_record_pp_form" > 
         <CurrencyTextField
          label={<IntlMessage id ="admin.financial.header_table.amount_owed"/> }
          variant="outlined"
          autoFocus
          value = {formikValues?.amount_owed} 
          currencySymbol="$" 
          outputFormat="string"
          textAlign="left"
          decimalCharacter="."
          digitGroupSeparator=","
          onChange={(e, value) => {handleFieldChange('amount_owed', value)}}
          className="currencyTextField"
          />

          <ErrorMessageWrapper
          touched={touched}
          errors={errors}
          fieldName="amount_owed"
          /> 
      </FormControl>
     
      <FormControl variant="outlined" className="w-100 custom_field_record_pp_form" >
        <InputLabel><IntlMessage id="admin.financial.add_record.status.title"/></InputLabel>
        <Select
            fullWidth={true} 
            value={formikValues?.status || ''}
            IconComponent={ExpandMore} 
            name="status"
            label= {translate("admin.financial.add_record.status.title")}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              className: "menuList",
            }}
            onChange={(e) => handleFieldChange('status', e.target.value)} 
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
              variant: "filled",
            }}
          >
             {statusList.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.label}
                {/* <CheckIcon /> */}
              </MenuItem>
            ))}
            
          </Select> 
          <ErrorMessageWrapper
          touched={touched}
          errors={errors}
          fieldName="status"
        />
      </FormControl>
      
      <Form.Group controlId="note" className="mb-4">
        <TextField
          label={<IntlMessage id ="admin.financial.add_record.notes.title"/> }
          variant="outlined"
          name="note"
          autoFocus
          className="w-100"
          rows={4}
          multiline
          value = {formikValues?.note || '' } 
          onChange={(e) => handleFieldChange('note', e.target.value)} 
          />
      </Form.Group>
    </>
  
  )
}

export default RecordPPForm;