import React from "react";
import IntlMessage from "@iso/components/intlMessage";
import "./DetailItem.scss";

const DetailItem = ({ label, displayValue, i18n = true, className }) => {
  return (
    <>
      <div className={`_custom_label ${className} `}>
        <IntlMessage id={label} />
        <div className="pt-1 paragraph">
          {i18n ? <IntlMessage id={displayValue} /> : displayValue}
        </div>
      </div>
    </>
  );
};

export default DetailItem;
