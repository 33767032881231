import {prayerPartnerReligions} from "@iso/Pages/Common/Religions"

export * from "./user"
export * from "./gta"
export * from "./fbPixel"
export const IDLING_DAYS_EXPIRE_TOKEN = 7
export const DATE_FORMAT_PRAYER_REQUEST = "MMMM Do, YYYY"
export const DATE_FORMAT_FOR_PRAYER_REQUEST = "MMM Do, YYYY"
export const DATE_FORMAT_PROFILE_FORM = "yyyy-M-dd"
export const DATE_FORMAT_LEAVE_PERIODS = "MMMM d, yyyy"
export const RELIGIONS = [
  {id : "catholic", label :"Catholic"},
  {id : "jewish", label :"Jewish"},
  {id : "protestant", label :"Protestant"},
  {id : "eastern-orthodox", label :"Eastern Orthodox"},
  {id : "evangelical", label :"Evangelical"},
  {id : "anglican", label :"Anglican"},
  {id : "non-denomination", label :"Non-Denominational"},
  {id : "no-preference", label :"No Preference"},
  {id : "other", label :"Other"},
]

export const timezones = {
  '0': "UTC +00:00",
  '1': "UTC +01:00",
  '2': "UTC +02:00",
  '3': "UTC +03:00",
  '4': "UTC +04:00",
  '5': "UTC +05:00",
  '6': "UTC +06:00",
  '7': "UTC +07:00",
  '8': "UTC +08:00",
  '9': "UTC +09:00",
  '10': "UTC +10:00",
  '11': "UTC +11:00",
  '12': "UTC +12:00",
  '-11': "UTC -11:00",
  '-10': "UTC -10:00",
  '-9': "UTC -09:00",
  '-8': "UTC -08:00",
  '-7': "UTC -07:00",
  '-6': "UTC -06:00",
  '-5': "UTC -05:00",
  '-4': "UTC -04:00",
  '-3': "UTC -03:00",
  '-2': "UTC -02:00",
  '-1': "UTC -01:00",
};

const breakPoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const DISPLAY_COMPLETED_INFO = ["completed", "overdue_done", "overdue_done_approved", "approved"];
export const SEARCHABLE_FIELDS = ["name", "id", "email"]

export const UNSET_HEIGHT_ROOT_PAGES = [
  '/requests/step1',
  '/requests/step2',
  '/requests/step3',
  '/about',
  '/faq',
  '/contact_us',
  '/admin',
  '/prayer_partners',
  '/contributions'
]
