import React, { Suspense } from "react"
import Loader from "react-loaders"
import { Container, Card, Row, Col, Button } from "react-bootstrap"
import "./styles.scss"
import { getPrayPartnerReports } from "@iso/PrayerPartner/api_v1";
import { useDispatch } from "react-redux";
import alertActions from "@iso/actions/Alert";
import IntlMessage from "@iso/components/intlMessage";
import LinearProgress from "@material-ui/core/LinearProgress";

const ReportItem = React.lazy(() => import("./ReportItem"))
const ReportDashboard = () => {
  const { openAlert } = alertActions
  const dispatch = useDispatch()

  const [isLoad, setIsLoad] = React.useState(false)
  const [report, setReport] = React.useState()

  React.useEffect(() => {
    if (!isLoad) {
      setIsLoad(true);
      getPrayPartnerReports()
        .then((res) => {
          const { code, data } = res.data
          if (code === 200 && data) {
            setReport(data)
          } else {
            dispatch(
              openAlert({
                message: data.message,
                severity: "error",
              })
            )
          }
        })
        .catch((err) => {
          dispatch(
            openAlert({
              message: "Somethings went wrong",
              severity: "error",
            })
          )
        })
    }
  }, [])

  return (
    <div className="container-fluid report_dashboard_wrapper p-3">
      <h2 className="py-3">
        <IntlMessage id="prayer_partners.reports.title" />
      </h2>

      {isLoad && report ? (
        <Card className="card_wrapper p-3">
          {report &&
            Object.keys(report).map((item) => {
              return <ReportItem key={item} title={item} value={report[item]} />
            })}

          <p className="report_note  pl-4">
            <IntlMessage id={`prayer_partners.reports.note.not_included`} />
          </p>
        </Card>
      ) : (
        <LinearProgress />
      )}
    </div>
  )
}

export default ReportDashboard
