import React from 'react'
import styled from 'styled-components'
import GiveImg from "@iso/assets/images/about_give_text.svg" 
import GiveImgMobile from "@iso/assets/images/about_give_text_mobile.svg" 
import { Grid } from '@material-ui/core'
import Button from '@iso/components/AtomicComponents/Button.js'
import { useHistory } from 'react-router'
import IntlMessage from "@iso/components/intlMessage";
import PopupBeta from "../../../../../actions/PopupBeta";

import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const Wrapper = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 

    font-family: BrandonText;
    padding: 56px 0px;
    margin-bottom: 99px;
    @media only screen and (min-width: 767px){
        padding-bottom: 0px;
    }
    @media (min-width: 768px) and (max-width: 1024px){
        padding-top: 85px;
        padding-bottom: 95px;
        margin-bottom: 0px;
    }
    @media only screen and (max-width: 500px) {
      padding: 0;
      margin-bottom: 60px;
    }
`

const TitleWrapper = styled.div`
    height: 200px;
    display: flex;
    align-items: center;
    margin-left: 30px;
    height: 200px;
`

const TitleMobileWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 50px; 
    margin-left: -20px;
`

const CallToActionBtn = styled(Button)`
    width: 303px;
    font-family: "BrandonText";
    @media only screen and (min-width: 768px){
        width: 250px;
        height: 60px;
        margin-left: 40px;
    }

    @media only screen and (max-width: 500px) {
      width: 220px;
      height: 50px;
      margin-left: 5px;
      padding: 10px 10px;
    }
`
const Section4 = (props) => {
    const history = useHistory()
    const [show, setShow] = React.useState(false);
    
    const handleRequestNew = () => {
      gtag('event', custom_events.CLICK_BUTTON, {
        button_label : 'Request a Prayer (From About page)',
      });
      ReactPixel.track(fb_pixel_event.custom_events.CLICK_BUTTON, {
        button_label : 'Request a Prayer (From About page)',
      });
      history.push('/requests/step1')
      // setShow(true)
    }
    
    return <Wrapper container> 
        <TitleWrapper className="d-none d-md-block ">
            <img src={GiveImg} />
         </TitleWrapper>
        
        <TitleMobileWrapper className="d-block d-md-none">
          <img src={GiveImgMobile} />
        </TitleMobileWrapper>

         <CallToActionBtn handleClick={() => handleRequestNew()} variant="primary"><IntlMessage id="landing.request.new.prayer" /></CallToActionBtn>
         <PopupBeta show={show} setShow={setShow}/>
    </Wrapper>
}

export default Section4