import React, {useEffect} from "react";
import { Modal } from "react-bootstrap";
import FlagRequestForm from "./FlagRequestForm";
import IntlMessage from "@iso/components/intlMessage";

import FlagRequestConfirmModal from "./FlagRequestConfirmModal";

const FlagRequestModal = (props) => {
  const { showModal, closeModal, requestId, successCallback } = props;
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [isShowModel, setIsShowModel] = React.useState(showModal);

  useEffect(() => {
    setIsShowModel(showModal)
  }, [showModal])
  
  const handleCloseModal = ({ openConfirm }) => {
    setIsShowModel(false);
    setOpenConfirm(openConfirm);
  };

  return (
    <div>
      <Modal show={isShowModel} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <IntlMessage id="prayer_partners.requests.flag_modal.title"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FlagRequestForm
            handleClose={handleCloseModal}
            requestId={requestId}
            successCallback={successCallback}
          />
        </Modal.Body>
      </Modal>
      <FlagRequestConfirmModal
        showModal={openConfirm}
        closeModal={() => {setOpenConfirm(false); closeModal()}}
      />
    </div>
  );
};

export default FlagRequestModal;