import React from "react"
import { Grid, MenuItem, TextField } from "@material-ui/core"
import IntlMessage from "@iso/components/intlMessage"
import Card from "@material-ui/core/Card"
import { logoutUser, getUser } from "@iso/actions/User"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import useStyles from "./DeactivateAccount.style"
import "./DeactivateAccount.scss"
import { Link } from "react-router-dom"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import { Formik, Field, Form } from "formik"
import { translate } from "@iso/utils/Translate"
import CustomButton from "@iso/components/AtomicComponents/Button"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper" 
import UserService from "@iso/services/UserService"
import ProcessRequest from "@iso/utils/ProcessRequest"
import { setCurrentUser } from "@iso/actions/User"
import { useDispatch } from "react-redux"
import { history } from "@iso/helpers"
import { isMobile } from "react-device-detect"

const DeactivateAccount = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [submitted, setSubmitted] = React.useState(false)
  const [formikValues, setFormiklValues] = React.useState({
    reason: "",
  })

  const handleDeactivateAccount = () => {
    UserService.deactivateAccount(formikValues).then((res) => {
      ProcessRequest.run({
        res,
        successCallback: () => {
          dispatch(setCurrentUser({}))
          dispatch(logoutUser())
          history.push("/")
        },
      })
    })
  }

  const renderForm = () => {
    return (
      <Grid item xs={12}>
      <h5 className={`${classes.title} mb-4 mb-md-2`}>
        <IntlMessage id="user.account.settings.deactivate_account.content.header" />
      </h5>
      {
        isMobile ? 
        // Mobile Version
        <>
          <p className={`${classes.description} mb-3`}>
          <div><IntlMessage id="user.account.settings.deactivate_account.mobile.content.content1" /></div>
          <div><IntlMessage id="user.account.settings.deactivate_account.mobile.content.content2" /></div>
          <div>
            <IntlMessage id="user.account.settings.deactivate_account.mobile.content.content3" />
            <b><IntlMessage id="user.account.settings.deactivate_account.content.content1_bold" /></b>
          </div>
          
          <div><IntlMessage id="user.account.settings.deactivate_account.mobile.content.content4" /> </div>
          <div><IntlMessage id="user.account.settings.deactivate_account.mobile.content.content5" /></div>
          <div><IntlMessage id="user.account.settings.deactivate_account.mobile.content.content6" /></div>
          <div><IntlMessage id="user.account.settings.deactivate_account.mobile.content.content7" /></div>
          <div><IntlMessage id="user.account.settings.deactivate_account.mobile.content.content8" /></div>
          </p>
          <h5 className={`${classes.title} mb-3 mb-md-2`}>
            <IntlMessage id="user.account.settings.deactivate_account.content.anything_else" />
          </h5>
          <p className={`${classes.description}`}>
          <IntlMessage
            id="user.account.settings.deactivate_account.mobile.content.please_visit1"
            values={{
              help: (
                <a className="text-hippie-blue" href="/faq">
                  <IntlMessage id="user.account.settings.deactivate_account.content.help_fqa" />
                </a>
              ),
            }}
          />
          <div>
          <IntlMessage
            id="user.account.settings.deactivate_account.mobile.content.please_visit2"
            values={{
              contact_us: (
                <a className="text-hippie-blue" href="/contact_us">
                  <IntlMessage id="user.account.settings.deactivate_account.content.contact_us" />
                </a>
              ),
            }}
          />
          </div>
        </p>
        </>
        :
        // DeskTop version
        <>
          <p className={`${classes.description} mb-0 mb-md-3`}>
            <IntlMessage id="user.account.settings.deactivate_account.content.content1" />
            <b><IntlMessage id="user.account.settings.deactivate_account.content.content1_bold" /></b>
          </p>
          <p className={`${classes.description} mb-3`}>
            <IntlMessage id="user.account.settings.deactivate_account.content.content2" />
          </p>
          <h5 className={`${classes.title} ${classes.titleAnyThingElse} mb-3 mb-md-2`}>
            <IntlMessage id="user.account.settings.deactivate_account.content.anything_else" />
          </h5>
          <p className={`${classes.description}`}>
            <IntlMessage
              id="user.account.settings.deactivate_account.content.please_visit"
              values={{
                help: (
                  <a className="text-hippie-blue" href="/faq">
                    <IntlMessage id="user.account.settings.deactivate_account.content.help_fqa" />
                  </a>
                ),
                contact_us: (
                  <a className="text-hippie-blue" href="/contact_us">
                    <IntlMessage id="user.account.settings.deactivate_account.content.contact_us" />
                  </a>
                ),
              }}
            />
          </p>
        </>
      }
      
      <Formik
        initialValues={formikValues}
        enableReinitialize={true} 
        onSubmit={(values, { setSubmitting }) => {
          setFormiklValues(values)
          setSubmitted(true)
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6} lg={6}>
                <TextareaAutosize
                  name="reason"
                  aria-label="minimum height"
                  onChange={handleChange}
                  rowsMin={3}
                  defaultValue={formikValues.reason}
                  placeholder={translate(
                    "user.account.settings.deactivate_account.form.placeholder"
                  )}
                  className={`${classes.textArea} textArea__focused mt-3 mt-md-0`}
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="reason"
                />
              </Grid>
            </Grid>
            <CustomButton
              className="button__save m-0 deactivate_account__button"
              variant="primary"
              type="submit"
              isSubmitting={isSubmitting}
            >
              <IntlMessage id="user.account.settings.deactivate_account.form.step1.submit_button" />
            </CustomButton>
          </Form>
        )}
      </Formik>
    </Grid>
    )
  }

  return (
    <Card className={`${classes.root} account_page__deactivate_account`}>
      <CardHeader
        className={classes.header}
        title={
          <IntlMessage id="user.account.settings.deactivate_account.title" />
        }
      />
        {!submitted ? (
          <CardContent className='content'>
            <Grid container spacing={0} className="justify-content-around">
            {renderForm()}
            </Grid>
          </CardContent>
        ) : (
          <CardContent className='content_decision'>
          <Grid container spacing={0} className="justify-content-around">
          <Grid item xs={12}>
            <h5 className={classes.title}>
              <IntlMessage id="user.account.settings.deactivate_account.step2.title" />
            </h5>
            
            <p className={`mt-4 font-size-16px`}>
              <IntlMessage id="user.account.settings.deactivate_account.step2.content" />
            </p>

            <CustomButton
              className="button__save button__save--cancel m-0 mt-3 hover_pointer"
              variant="primary"
              handleClick={() => setSubmitted(false)}
              type="submit"
              className="submitBtn"
              style={{marginLeft:'0px'}}
            >
              <IntlMessage id="user.account.settings.deactivate_account.step2.cancel_button" />
            </CustomButton>
            <CustomButton
              className="button__save button__save--submit mt-3 hover_pointer"
              handleClick={handleDeactivateAccount}
              type="submit"
              className="cancelBtn"
              style={{ boxShadow: 'none', color: '#1c1634'}}
            >
              <IntlMessage id="user.account.settings.deactivate_account.step2.submit_button" />
            </CustomButton>
          </Grid>
          </Grid>
          </CardContent>
        )}
    </Card>
  )
}

export default DeactivateAccount
