import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Check } from "react-bootstrap";
import { Checkbox } from "@material-ui/core"
import { Formik } from "formik";
import CouponService from "@iso/services/CouponService";
import ContributionService from "@iso/services/ContributionService";
import { useDispatch } from "react-redux";
import { FormControl, FormGroup, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import IntlMessage from "@iso/components/intlMessage";
import alertActions from "@iso/actions/Alert";
import { AddCouponFormValidator } from "./validator";
const { openAlert } = alertActions;

const couponsType = [
    { id: 'default', name: 'Free'},
    { id: 'discount_by_percent', name: 'Discount by percent (%)'},
    { id: 'discount_by_money', name: 'Discount by money ($)'},
]

const AddForm = (props) => {
    let initValue = {
        name: "",
        code: "",
        type: "",
        description: "",
        contribution_ids: [],
        frequency_ids: [],
        limit: "",
        discount_value: "",
        check_all_contribution: false,
        check_all_frequency: false,
    }

    const [formikValues, setFormikValues] = useState(initValue);
    const [isDisabledContribution, setDisabledContribution] = useState(false);
    const [isDisabledFrequency, setDisabledFrequency] = useState(false);
    const [contributions, setContributions] = React.useState();
    const [frequencies, setFrequencies] = React.useState();
    const dispatch = useDispatch();

    const getAllContribution = ({ religion }) => {
        ContributionService.getAllContributions({ religion }).then((res) => {
            if (res.status === 200) {
                setContributions(res.data.data)
            }
        });
    };

    const getAllFrequency = ({ religion }) => {
        ContributionService.getAllFrequencies({ religion }).then((res) => {
            if (res.status === 200) {
                setFrequencies(res.data.data)
            }
        });
    }

    useEffect(() => {
        getAllContribution({ religion: '' });
        getAllFrequency({ religion: '' });
    }, [])


    const handleSubmit = async (values) => {
        CouponService.createCoupon(values).then((res) => {
            if (res) {
                const { code } = res.data;
                if (code === 200) {
                    // update list coupons
                    props.handleCouponRequests();

                    props.handleClose();
                    dispatch(
                        openAlert({
                            message: <IntlMessage id="admin.coupons.form.add_success" />,
                            severity: "success"
                        })
                    )
                } else {
                    dispatch(
                        openAlert({
                            message: <IntlMessage id="admin.coupons.form.add_fail" />,
                            severity: "fail"
                        })
                    )
                }
            }
        })

    }

    const handleFieldChange = (name, value) => {
        formikValues[name] = value;
        const formValueTmp = formikValues;
        if(name === 'check_all_contribution') {
            if (value) {
                setDisabledContribution(true);
                formValueTmp.contribution_id = "";
            } else {
                setDisabledContribution(false);
            }
        }

        if(name === 'check_all_frequency') {
            if (value) {
                setDisabledFrequency(true);
                formValueTmp.frequency_id = "";
            } else {
                setDisabledFrequency(false);
            }
        }
        setFormikValues({ ...formikValues });
    }

    const handleChangeMultiSelect= (field, value) => {
        const ids = typeof value === 'string' ? value.split(',') : value
        setFormikValues({ ...formikValues, [field]:  ids});
    }

    const renderSelectedContributions = (selected) => {
        const contributionNames = []
        if(selected && contributions){
            selected.forEach((contributionId)=>{
                contributions.forEach((contribution)=>{
                    if(contribution.id == contributionId){
                        contributionNames.push(contribution.name)
                    }
                })
            })
        }
        return contributionNames.join(', ')
    }

    const renderSelectedFrequencies = (selected) => {
        const frequenciesNames = []
        if(selected && frequencies){
            selected.forEach((frequencyId)=>{
                frequencies.forEach((frequency)=>{
                    if(frequency.id == frequencyId){
                        frequenciesNames.push(frequency.name)
                    }
                })
            })
        }
        return frequenciesNames.join(', ')
    }

    return (
        <Formik
            initialValues={formikValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={AddCouponFormValidator}
        >
            {({ handleSubmit, handleChange, touched, errors }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col sm="12">
                            <Form.Group controlId="formName" className="mb-4">
                                <TextField
                                    label={<IntlMessage id="common.name" />}
                                    variant="outlined"
                                    name="name"
                                    autoFocus
                                    value={formikValues.name}
                                    onChange={(e) => handleFieldChange('name', e.target.value)}
                                    error={touched.name && !!errors.name}
                                    helperText={touched.name && errors.name}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group controlId="formCode" className="mb-4">
                        <TextField
                            label={<IntlMessage id="common.code" />}
                            variant="outlined"
                            name="code"
                            autoFocus
                            value={formikValues.code}
                            onChange={(e) => handleFieldChange('code', e.target.value)}
                            error={touched.code && !!errors.code}
                            helperText={touched.code && errors.code}
                        />
                    </Form.Group>

                    <FormControl variant="outlined" className="mb-4">
                        <InputLabel id="couponTypeLabel"><IntlMessage id="admin.coupons.form.field.type" /> </InputLabel>
                        <Select
                            labelId="couponTypeLabel"
                            id="type"
                            value={formikValues.type}
                            onChange={(e) => handleFieldChange('type', e.target.value)}
                            label={<IntlMessage id="admin.coupons.form.field.type" />}
                            error={touched.type && !!errors.type}
                        >
                            {couponsType.map((type) => (
                                <MenuItem key={type.id} value={type.id} >
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {touched.type && errors.type && <FormHelperText error>{errors.type}</FormHelperText>}
                    </FormControl>

                    {
                        formikValues.type === 'discount_by_money' || formikValues.type === 'discount_by_percent'
                        ? (
                            <Form.Group controlId="formCouponDiscountValue" className="mb-4">
                                <TextField
                                    label={<IntlMessage id="admin.coupons.form.field.discountValue" />}
                                    variant="outlined"
                                    name="discount_value"
                                    type="number"
                                    value={formikValues.discount_value}
                                    onChange={(e) => handleFieldChange('discount_value', e.target.value)}
                                    error={touched.discount_value && !!errors.discount_value}
                                    helperText={touched.discount_value && errors.discount_value}
                                />
                            </Form.Group>
                        ) : null
                    }


                    <Form.Group controlId="formDescription" className="mb-4">
                        <TextField
                            label={<IntlMessage id="common.description" />}
                            variant="outlined"
                            name="description"
                            onChange={(e) => handleFieldChange('description', e.target.value)}
                            error={touched.description && !!errors.description}
                            helperText={touched.description && errors.description}
                        />
                    </Form.Group>

                    {contributions &&
                        <FormControl variant="outlined" className="mb-4">
                            <InputLabel id="contributionLabel"><IntlMessage id="admin.coupons.form.field.contribution" /> </InputLabel>
                            <Select
                                labelId="contributionLabel"
                                id="contribution_ids"
                                multiple
                                value={formikValues.contribution_ids}
                                renderValue={renderSelectedContributions}
                                onChange={(e) => handleChangeMultiSelect('contribution_ids', e.target.value)}
                                label={<IntlMessage id="admin.coupons.form.field.contribution" />}
                                error={touched.contribution_ids && !!errors.contribution_ids}
                                disabled={isDisabledContribution}
                            >
                                {contributions.map((contribution) => (
                                    <MenuItem key={contribution.id} value={contribution.id} >
                                        <Checkbox checked={formikValues.contribution_ids.indexOf(contribution.id) > -1} />
                                        {contribution.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {touched.contribution_ids && errors.contribution_ids && <FormHelperText error>{errors.contribution_ids}</FormHelperText>}
                        </FormControl>
                    }
                    <Form.Group controlId="formRequest" className="mb-4 check_all_contribution">
                        <Checkbox type="checkbox" 
                            variant="outlined"
                            name="check_all_contribution"
                            onChange={(e) => handleFieldChange('check_all_contribution', e.target.checked)}
                            error={touched.check_all_contribution && !!errors.check_all_contribution}
                            helperText={touched.check_all_contribution && errors.check_all_contribution}/>
                         <p className="label_check_all_contribution">
                         <IntlMessage id="common.contribution.all" />
                        </p>
                    </Form.Group>
                    {frequencies &&
                        <FormControl variant="outlined" className="mb-4">
                            <InputLabel id="contributionLabel"><IntlMessage id="admin.coupons.form.field.frequency" /> </InputLabel>
                            <Select
                                labelId="contributionLabel"
                                id="frequency_ids"
                                multiple
                                value={formikValues.frequency_ids}
                                renderValue={renderSelectedFrequencies}
                                onChange={(e) => handleChangeMultiSelect('frequency_ids', e.target.value)}
                                label={<IntlMessage id="admin.coupons.form.field.frequency" />}
                                error={touched.frequency_ids && !!errors.frequency_ids}
                                disabled={isDisabledFrequency}
                            >
                                {frequencies.map((frequency) => (
                                    <MenuItem key={frequency.id} value={frequency.id} >
                                        <Checkbox checked={formikValues.frequency_ids.indexOf(frequency.id) > -1} />
                                        {frequency.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {touched.frequency_ids && errors.frequency_ids && <FormHelperText error>{errors.frequency_ids}</FormHelperText>}
                        </FormControl>
                    }
                    <Form.Group controlId="formRequest" className="mb-4 check_all_contribution">
                        <Checkbox type="checkbox" 
                            variant="outlined"
                            name="check_all_frequency"
                            onChange={(e) => handleFieldChange('check_all_frequency', e.target.checked)}
                            error={touched.check_all_frequency && !!errors.check_all_frequency}
                            helperText={touched.check_all_frequency && errors.check_all_frequency}/>
                         <p className="label_check_all_contribution">
                         <IntlMessage id="common.frequency.all" />
                        </p>
                    </Form.Group>
                    <Form.Group controlId="formCouponLimit" className="mb-4">
                        <TextField
                            label={<IntlMessage id="admin.coupons.form.field.limit" />}
                            variant="outlined"
                            name="limit"
                            type="number"
                            value={formikValues.limit}
                            onChange={(e) => handleFieldChange('limit', e.target.value)}
                            error={touched.limit && !!errors.limit}
                            helperText={touched.limit && errors.limit}
                        />
                    </Form.Group>

                    {/* Invitation button */}
                    <div className="buttons text-center mt-4">
                        <Button className="hippie-blue-button" type="submit">
                            <IntlMessage id="admin.coupons.form.submit" />
                        </Button>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default AddForm;