import React from "react";
import { Button, Row, Col, Form, Spinner } from "react-bootstrap";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";
import { getNames } from "country-list";

import { Formik } from "formik";
import IntlTelInput from "react-intl-tel-input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import IntlMessage from "@iso/components/intlMessage";
import {
  RELIGIONS,
  USER_PERMISSIONS,
  DATE_FORMAT_PROFILE_FORM,
} from "@iso/constants";

import { ProfileInfoFormValidator } from "./validators";
import ProcessRequest from "@iso/utils/ProcessRequest";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import CustomButton from "@iso/components/AtomicComponents/Button";
import UserService from "@iso/services/UserService";

const countryListByName = getNames();
const ProfileForm = (props, ref) => {
  const {
    isEdit,
    setIsEdit,
    profile,
    setProfile,
  } = props;

  const defaultValue = {
    first_name: profile.first_name || "",
    last_name: profile.last_name || "",
    phone_number: profile.phone_number || "",
    calling_code: profile.calling_code || "",
    tagline: profile.tagline || "",
    city: profile.city || "",
    country: profile.country || "",
    admin_note: profile.admin_note || "",
    ...profile,
    religion: profile.religion || [],
    birth: profile?.birth ? new Date(profile.birth) : null,
  };

  const phoneRef = React.createRef(null);
  const [formikValues, setFormikValues] = React.useState(defaultValue);
  const [errorMessages, setErrorMessages] = React.useState({});
  const [isLoading, setLoading] = React.useState(false)
  const handleValueChange = (name, value) => {
    formikValues[name] = value;
    setFormikValues({ ...formikValues, timestamp: Date.now() });
  };

  const setCaretPosition = (caretPos) => {
    const elem = document.getElementsByClassName('phone_number')[0];
    if (elem) {
      elem.focus();
      setTimeout(() => {
        elem.setSelectionRange(caretPos, caretPos);
      }, 0);
    }
  };

  const handleSubmit = (values) => {
    if(values.phone_number.length < 6 || values.phone_number === " "){
      values.phone_number = "";
    }
    setLoading(true);
    UserService.adminUpdateUserProfile({
      ...values,
      birth: values.birth ? moment(values.birth).format("YYYY-MM-DD") : null,
    }).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: true,
        successCallback: (data) => {
          setProfile(data.data);
          setIsEdit(false);
          setLoading(false);
          setErrorMessages({});
        },
        errorCallback: ({ errors }) => {
          console.log(errors);
          setLoading(false);
          setErrorMessages(errors);
        },
      });
      if(values.phone_number.length < 6){
        values.phone_number = "+1 ";
      };
    });
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setFormikValues({...defaultValue});
  };
  const phoneNumberDisplay = formikValues.calling_code ?  `(+${formikValues.calling_code}) ${formikValues.phone_number}` : '' + (formikValues.phone_number || ' ')
  return (
    <Formik
      validationSchema={ProfileInfoFormValidator}
      enableReinitialize={true}
      initialValues={formikValues}
      onSubmit={handleSubmit}
    >
      {({
        setFieldValue,
        setFieldTouched,
        values,
        touched,
        errors,
        handleSubmit,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit} className="p-2">
            <Row>
              <Col xs={12} className="pt-3 pl-2 pr-2 pl-md-4">
                <Form.Group controlId="formFirstName" className="mb-1">
                  <TextField
                    label="First name"
                    variant="outlined"
                    name="first_name"
                    autoFocus={true}
                    value={values.first_name}
                    onChange={(e) => {
                      setFieldValue("first_name", e.target.value);
                      handleValueChange("first_name", e.target.value);
                    }}
                    
                    // error={touched.first_name && !!errors.first_name}
                    // helperText={touched.first_name && errors.first_name}
                    disabled={!isEdit}
                    className="custom_material_input"
                  />
                </Form.Group>
                <ErrorMessageWrapper
                  className={`${!errors.first_name && 'custom_error_message_first_name'}`}
                  touched={{ first_name: true }}
                  errors={{ ...errorMessages, ...errors }}
                  fieldName="first_name"
                />
              </Col>
              <Col xs={12} className="pt-3 pl-2 pr-2 pl-md-4">
                <Form.Group controlId="formLastName" className="mb-1">
                  <TextField
                    label="Last name"
                    variant="outlined"
                    name="last_name"
                    value={values.last_name}
                    onChange={(e) => {
                      setFieldValue("last_name", e.target.value);
                      handleValueChange("last_name", e.target.value);
                    }}
                    // error={touched.last_name && !!errors.last_name}
                    // helperText={touched.last_name && errors.last_name}
                    disabled={!isEdit}
                    className="custom_material_input"
                  />
                </Form.Group>
                <ErrorMessageWrapper
                  className={`${!errors.last_name && 'custom_error_message_first_name'}`}
                  touched={{ last_name: true }}
                  errors={{ ...errorMessages, ...errors }}
                  fieldName="last_name"
                />
              </Col>
              <Col xs={12} className="pt-3 pl-2 pr-2 pl-md-4">
                <Form.Group controlId="formEmail" className="mb-0">
                  <TextField
                    label="Email"
                    variant="outlined"
                    name="email"
                    value={values.email}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                      handleValueChange("email", e.target.value);
                    }}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    disabled={!isEdit}
                    className="custom_material_input"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="pl-2 pr-2 pl-md-4">
                <Form.Group as={Row}>
                  <Col xs="12">
                    <Form.Label className="custom_label">
                      <InputLabel id="birth_date-label">
                        <IntlMessage id="common.birth_date" />
                      </InputLabel>
                    </Form.Label>
                    <Form.Control
                      label="Date of birth"
                      variant="outlined"
                      name="birth"
                      onSelect={(value) => {
                        setFieldValue("birth", value);
                        handleValueChange("birth", value);
                      }}
                      as={DatePicker}
                      selected={formikValues.birth}
                      disabled={!isEdit}
                      dateFormat={DATE_FORMAT_PROFILE_FORM}
                    />
                    <ErrorMessageWrapper
                      touched={{ birth: true }}
                      errors={{ ...errorMessages, ...errors }}
                      fieldName="birth"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} className="pl-2 pr-2 pl-md-4 ">
                <Form.Group as={Row} className="select_religion mb-2">
                  <Col sm="12" className ="margin-top-denomination">
                    <Form.Label className="custom_label_select_religion">
                      <InputLabel id="select_religion-label">
                        <IntlMessage id="common.denomination" />
                      </InputLabel>
                    </Form.Label>

                    <Select
                      labelId="religion-label"
                      id="religion"
                      defaultValue={
                        formikValues.religion && formikValues.religion[0]
                      }
                      value={formikValues.religion && formikValues.religion[0]}
                      name="religion"
                      disabled={!isEdit}
                      onChange={(e) => {
                        setFieldValue("religion", [e.target.value]);
                        handleValueChange("religion", [e.target.value]);
                      }}
                      className="w-100 mt-2 custom_select_religion remove-border-bottom-muiInput"
                    >
                      {RELIGIONS.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Col>
                </Form.Group>
              </Col>

              <Col xs={12} className="mt-3 pl-2 pr-2 pl-md-4">
                <Form.Group as={Row} className="select_religion mb-3">
                  <Col sm="12" className = "margin-top-country">
                    <Form.Label className="custom_label_select_religion">
                      <InputLabel id="select_religion-label">
                        <IntlMessage id="common.country" />
                      </InputLabel>
                    </Form.Label>

                    <Select
                        labelId="religion-label"
                        id="religion"
                        defaultValue={
                          formikValues.country
                        }
                        value={formikValues.country}
                        name="country"
                        disabled={!isEdit}
                        onChange={(e) => {
                          setFieldValue("country", e.target.value);
                          handleValueChange("country", e.target.value);
                        }}
                        className="w-100 mt-2 custom_select_religion remove-border-bottom-muiInput"
                    >
                      {countryListByName.map((country, idx) => (
                          <MenuItem key={idx} value={country}>
                            {country}
                          </MenuItem>
                      ))}
                    </Select>
                  </Col>
                </Form.Group>
              </Col>

              <Col xs={12} className="pt-2 pl-2 pr-2 pl-md-4">
                <Form.Group
                  controlId="formPhone"
                  className="row phone-input mb-1"
                >
                  <Col sm="12" className="position-relative">
                    <Form.Label className="phone_number_label">
                      <IntlMessage id="common.phone_number" />
                    </Form.Label>
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control phone_number"
                      defaultCountry="us"
                      placeholder="Enter phone number"
                      autoComplete={false}
                      fieldId="phone_number"
                      fieldName="phone_number"
                      onPhoneNumberBlur={() => {
                        setFieldTouched("phone_number", true);
                        setFieldTouched("calling_code", true);
                      }}
                      onPhoneNumberChange={(isValid, phone, country, fullNumber) => {
                        fullNumber = fullNumber ? fullNumber.replace(/[-]/g, "") : ' ';
                        setFieldValue("phone_number", fullNumber);
                        handleValueChange("phone_number", fullNumber);
                        setFieldValue("calling_code", country.dialCode);
                        handleValueChange("calling_code", country.dialCode);
                        values.phone_number = fullNumber;
                        setCaretPosition(values.phone_number.length)
                      }}
                      onSelectFlag={(currentNumber, country, fullNumber, phone)=>{
                        fullNumber = fullNumber.replace(/[-]/g, "");
                        values.phone_number = fullNumber;
                        setFieldValue("calling_code", country.dialCode);
                        handleValueChange("calling_code", country.dialCode);
                      }}
                      preferredCountries={["us", "gb", "au", "ca"]}
                      value={ ((values.phone_number  || " ").replace(/[-]/g, ""))}
                      disabled={!isEdit}
                    />
                    <ErrorMessageWrapper
                      touched={{ phone_number: true }}
                      errors={{ ...errorMessages, ...errors }}
                      fieldName="phone_number"
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {isEdit && (
              <Row className="justify-content-center card_info_control m-0 row">
                <CustomButton
                  type="button"
                  className="box_shadow"
                  handleClick={cancelEdit}
                >
                  <IntlMessage id="common.cancel" />
                </CustomButton>
                <CustomButton
                  className="box_shadow"
                  type="submit"
                  variant="primary"
                  disabled={isLoading}
                >
                  <IntlMessage id="common.save" />
                  {isLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </CustomButton>
              </Row>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfileForm;
