export const NAME_MIN_LENGTH = 2
export const NAME_MAX_LENGTH = 20
export const TAG_MIN_LENGHT = 50
export const TAG_MAX_LENGHT = 150

export const popupConfig = {
  success: {
    title: "prayer_partners.account_settings.profile.save_success.title",
    description:
      "prayer_partners.account_settings.profile.save_success.description",
    ok: true,
  },
  confirm: {
    title: "prayer_partners.account_settings.profile.confirm.title",
    description: "prayer_partners.account_settings.profile.confirm.description",
    ok: true,
    cancel: true,
  },
  tagline: {
    title: "prayer_partners.account_settings.profile.tagline_popup.title",
    description:
      "prayer_partners.account_settings.profile.tagline_popup.description",
    ok: true,
  },
  other_field: {
    title: "prayer_partners.account_settings.profile.other_field_popup.title",
    description:
      "prayer_partners.account_settings.profile.other_field_popup.description",
    ok: true,
  },
}
