import React, { Component } from "react"
import "@iso/assets/pages/access_denied.scss" 
import IntlMessage from "@iso/components/intlMessage"
import { translate } from "@iso/utils/Translate";
import {Link} from "react-router-dom"
import * as qs from "query-string"
import { useLocation } from "react-router"
  const AccessDenied = (props) =>{ 
    const location = useLocation()
    const { ip } = qs.parse(location.search) 
    return (
      <div>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* The above 3 meta tags *must* come first in the head; any other head content must come *after* these tags */}
        <title>Access Denied</title>
        {/* Google font */}
        <link
          href="https://fonts.googleapis.com/css?family=Cabin:400,700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:900"
          rel="stylesheet"
        />
        {/* Custom stlylesheet */}
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        {/* HTML5 shim and Respond.js for IE8 support of HTML5 elements and media queries */}
        {/* WARNING: Respond.js doesn't work if you view the page via file:// */}
        {/*[if lt IE 9]>
            
            
          <![endif]*/}
        <div id="accessDenied">
          <div className="accessDenied">
            <div className="accessDeniedTitle">
              <h1>
                <IntlMessage id="accessDenied.title" />
              </h1> 
            </div>
            <h2> <IntlMessage id="accessDenied.content.title" 
              values={{ web: <Link to='/' className = "hippie-blue-color" href = "">{translate("accessDenied.content.website.title")}</Link>, IP : ip}}
              /></h2>
          </div>
        </div>
        {/* This templates was made by Colorlib (https://colorlib.com) */}
      </div>
    )
  } 
export default AccessDenied