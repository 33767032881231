import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Badge,
  Card,
  Form,
} from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import SupportCenterService from "@iso/services/SupportCenterService";
import {MDBBadge, MDBDataTable} from "mdbreact";
import { history } from "@iso/helpers/history" 
import "./styles.scss"; 
import * as qs from "query-string"
import { useLocation } from "react-router"
import moment from "moment";
import { DATE_FORMAT_PRAYER_REQUEST, CATEGORIES } from "./constants";
import { InputGroup, FormControl } from "react-bootstrap";
import { translate } from "@iso/utils/Translate";
import { debounce } from "lodash";

const SupportCenter = (props) => {
  const [prayerLists, setPrayerList] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [categoryValue,setCategoryValue] = useState("")
  const [counts, setCounts] = useState(0);
  const location = useLocation()
  const time_zone = -(new Date().getTimezoneOffset() / 60);
   
  const { filter } = qs.parse(location.search)  
  const defaultApiData = {
    filter: filter,
    status: currentStatus,
  }
  const formatTableData = (data, status) => {
    let dataRow = [];
    let header = [
      {
        label: <IntlMessage id="admin.support_center.header_table.id" />,
        field: "id",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.support_center.header_table.email" />
        ),
        field: "email",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.support_center.header_table.date" />
        ),
        field: "date",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.support_center.header_table.type" />,
        field: "type",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.support_center.header_table.detail" />,
        field: "detail",
        sort: "asc",
      }      
    ];

    if(status == "all"){
      header.push({
        label: <IntlMessage id="admin.support_center.header_table.status" />,
        field: "status",
        sort: "asc",
      })
    }

    data.forEach((e, index) => {
      dataRow.push({
        id: e.id,
        email: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.email ? e.email.toLowerCase() : ''}
          >
            {
              e.email
            }
          </MDBBadge>
        ),
        date: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.created_at}
          >
            {
              moment.utc(e.created_at).utcOffset(time_zone).format(DATE_FORMAT_PRAYER_REQUEST)
            }
          </MDBBadge>
        ),
        type: CATEGORIES?.find(category => category?.id === e?.category )?.label,
        detail:(
          <div className = "ellipsis"> 
            {e.message}
          </div> 
        ),
        status: e.status === 'pending' ? 'New' : 'Responded',
        clickEvent: () => handleClick(e.id),
      });
    });
    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    };
  };
  
  const handleGetSupportCenter = ({status =defaultApiData.status,filter = defaultApiData.filter, search = searchValue,category = categoryValue  }) => {
     
    SupportCenterService.adminSupportCenterGetListRequests(filter,status,search,category ).then((res) => {
      if (res) {
        const { code, data, count} = res.data;
         if (res && code === 200 ) {
          const prayer_lists = formatTableData(data.data, status);
          setPrayerList(prayer_lists.data);
          setCounts(count); 
          setCurrentStatus(status);
          if (prayer_lists.data.rows.length == 0) {
            document.querySelector(
              "tbody[data-test=table-body] tr td"
            ).innerText = `There is no ${status === 'pending' ? 'New' : status === 'completed' ? 'Responded' : ''} support request.`
          }
        }
      }
    });
  };

  const handleClick = id => {
    history.push(`/admin/support_centers/${id}`)
  }
  
  const debounceSearch = React.useCallback(
    debounce(({status, filter, search,category }) => {
      handleGetSupportCenter({status,filter, search,category});
    }, 500),
    []
  );

  React.useEffect(() => {
    debounceSearch({status:currentStatus, filter : defaultApiData.filter, search: searchValue,category: categoryValue });
  }, [searchValue, categoryValue,filter]);

  return (
    <div className="mint-tulip-background p-3 min-vh-100 support_center_list">
      <Row>
        <Col sm="6" className="text-md-left text-center mb-3">
          <h2>
            <IntlMessage id="sidebar.admin.support_center.title" />
          </h2>
        </Col> 
      </Row>

      <Card className="card_swapper">
        <Row className="mt-3 mb-3 pl-5 pr-5 row">
          <Col xs="12" sm="6" md="4" className="mt-1 p-1 p-0">
            <Button
              variant={`${
                currentStatus === "pending" ? "active-status" : "light"
              } btn-block`}
              onClick={() => handleGetSupportCenter({status :'pending'})}
            >
              <IntlMessage id="admin.support_center.tabs.new.title" />
              <Badge className="ml-2" pill variant="danger">
                {counts.pending === 0 ? "" : counts.pending}
              </Badge>
            </Button>
          </Col>
          <Col xs="12" sm="6" md="4" className="mt-1 p-1 p-0">
            <Button
              variant={`${
                currentStatus === "completed" ? "active-status" : "light"
              } btn-block`}
              onClick={() => handleGetSupportCenter({status :"completed"})}
            >
              <IntlMessage id="admin.support_center.tabs.resolved.title" />
              <Badge className="ml-2 badge-background" pill variant="danger">
                {counts.completed === 0 ? "" : counts.completed}
              </Badge>
            </Button>
          </Col>
          <Col xs="12" sm="6" md="4" className="mt-1 p-1 p-0">
            <Button
              variant={`${
                currentStatus === "all" ? "active-status" : "light"
              } btn-block`}
              onClick={() => handleGetSupportCenter({status :"all"})}
            >
              <IntlMessage id="admin.support_center.tabs.all.title" />
              <Badge className="ml-2 badge-background" pill variant="danger">
                 {counts.all === 0 ? "" : counts.all}
              </Badge>
            </Button>
          </Col>
           
        </Row>
        <Row className="ml-2 mr-2">
          <Col sm="12">
              <Row>
                <Col className="px-4 px-md-0 col-md-8 input_search_data_table" >
                  <InputGroup>
                  <Col sm= "6">
                  <FormControl as="select"
                      onChange={(e) => {
                        setCategoryValue(e.target.value)
                      }}
                    > 
                      <option value=""> {translate("admin.support_center.filter.category.all")} </option>
                      { 
                        CATEGORIES.filter((category) => 
                        (!category.role || category?.role === defaultApiData.filter))
                        .map((item) => (
                          <option key={item.id} value={item.id} >
                            {item.label} 
                          </option>
                        ))
                      } 
                    </FormControl>
                    </Col>
                    <Col sm= "6" className="mt-sm-0 mt-2">
                    <FormControl
                      value={searchValue}
                      placeholder={translate( "admin.support_center.search.placeholder" )}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    /> 
                    </Col>
                  </InputGroup>
                </Col>
              </Row> 
              <MDBDataTable
                hover
                paging={true}
                id="dt-filter-select"
                entries={25}
                searching={false}
                noBottomColumns={true}
                entriesOptions={[5, 20, 25]}
                data={prayerLists}
                className="hide_scrollbar"
                sortRows={['date', 'email']}
              /> 
          </Col>
        </Row>
      </Card>

       
    </div>
  );
};

export default SupportCenter;
