import React from "react";
import RequestStatement from "./RequestStatement";
import moment from "moment";
import { DATE_FORMAT_PRAYER_REQUEST } from "../constants";
import IntlMessage from "@iso/components/intlMessage";
import { Container } from "react-bootstrap";

const RespondedBuilder = ({ requestValue }) => {
  return (
    <Container className="wrapper_completion_details">
      <hr className="responded_hr"/>
      <h3 className="text-center">
        <IntlMessage id="admin.support_center.detail.responded.title"/>
      </h3>
      <div className="pb-4">
        <RequestStatement
          title={<IntlMessage id="admin.support_center.detail.responded.date_responded" />}
          value={moment(requestValue.updated_at).format(DATE_FORMAT_PRAYER_REQUEST)}
        />
        <RequestStatement
          title={<IntlMessage id="admin.support_center.detail.responded.reason_contact" />}
          value={requestValue.message_response}
        />
      </div>
    </Container>
  );
};

export default RespondedBuilder;
