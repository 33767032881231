import React from "react";
import IntlMessage from "@iso/components/intlMessage";

const TitleForTogglePauseModal = ({ status }) => {
  switch (status) {
    case "paused":
      return (
        <IntlMessage id="admin.requests.request_details_card.confirm_unpause.title" />
      );

    default:
      return (
        <IntlMessage id="admin.requests.request_details_card.confirm_pause.title" />
      );
  }
};
export default TitleForTogglePauseModal;
