import Home from "@iso/Admin/Pages/Home/Index";
import RequestDetails from "@iso/Admin/Pages/Requests/RequestDetails";
import PrayerPartnerAccountSetting from "@iso/Admin/Pages/PrayerPartner/AccountSettings/AccountSettings";
import PrayerPartners from "@iso/Admin/Pages/PrayerPartner";
import Users from "@iso/Admin/Pages/Users";
import UserDetails from "@iso/Admin/Pages/Users/show";

import SupportCenter from "@iso/Admin/Pages/SupportCenter";
import Reports from "@iso/Admin/Pages/Reports";
import RequestReports from "@iso/Admin/Pages/Reports/RequestReports";
import PrayerPartnersReports from "@iso/Admin/Pages/Reports/PrayerPartnersReports";
import SupportRequestDetail from "@iso/Admin/Pages/SupportCenter/Detail";
import Admins from "@iso/Admin/Pages/Admins";

import DetailsAdmin from "@iso/Admin/Pages/Admins/DetailsAdmin";
import FinancialTransactions from "@iso/Admin/Pages/FinancialTrans";
import DetailsFinancialTransactions from "@iso/Admin/Pages/FinancialTrans/Detail";

import Coupons from "@iso/Admin/Pages/Coupons";

export default {
  Home: {
    component: Home,
    path: "/admin",
  },
  PrayerPartner: {
    component: PrayerPartners,
    path: "/admin/prayer_partners",
  },
  RequestDetails: {
    component: RequestDetails,
    path: "/admin/requests/:id",
  },
  PrayerPartnerAccountSetting: {
    component: PrayerPartnerAccountSetting,
    path: "/admin/prayer_partners/:id/account_settings",
  },
  Users: {
    component: Users,
    path: "/admin/users",
  },
  UserDetails: {
    component: UserDetails,
    path: "/admin/users/:id",
  },
  SupportCenter: {
    component: SupportCenter,
    path: "/admin/support_centers",
  },
  Reports: {
    component: Reports,
    path: "/admin/reports",
  },
  SupportRequestDetail: {
    component: SupportRequestDetail,
    path: "/admin/support_centers/:id",
  },
  Admins: {
    component: Admins,
    path: "/admin/adminsTab",
  },
  DetailsAdmin: {
    component: DetailsAdmin,
    path: "/admin/DetailsAdmin/:id",
  },
  RequestReports: {
    component: RequestReports,
    path: "/admin/reports/requests",
  },
  PrayerPartnersReports: {
    component: PrayerPartnersReports,
    path: "/admin/reports/prayer-partners",
  },
  FinancialTransactions: {
    component: FinancialTransactions,
    path: "/admin/financial",
  },
  DetailsFinancialTransactions: {
    component: DetailsFinancialTransactions,
    path: "/admin/financial/:id",
  },
  // coupon
  Coupons: {
    component: Coupons,
    path: "/admin/Coupons",
  },
};
