import React from "react";
import { Col } from "react-bootstrap";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import IntlMessage from "@iso/components/intlMessage";

const StatusText = ({ displayCondition, itemStatus, hideText }) => {
  let icon = <></>;

  switch (itemStatus) {
    case "pending":
      icon = <AccessTimeIcon className="mr-1" />;
      break;
    case "approved":
      icon = <DoneIcon className="mr-1" />;
      break;
    case "reject":
      icon = <CloseIcon className="mr-1" />;
      break;
    case "changed":
      icon = <DoneIcon className="mr-1" />;
      break;
    default:
      break;
  }
  return (
    <>
      {displayCondition && !hideText && (
        <span className={`border-0 btn_${itemStatus}`}>
          {icon}
          <IntlMessage id={`common.${itemStatus}`} />
        </span>
      )}
    </>
  );
};

const MemoizedStatusText = React.memo(StatusText);

export default MemoizedStatusText;
