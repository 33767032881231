import React, { Component } from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { SignInFormValidator } from "./validator"
import { Link } from "react-router-dom"
import "./styles.scss"
import AuthService from "@iso/services/AuthService"
import { setCurrentUser } from "@iso/actions/User"
import { connect } from "react-redux"
import { userRoleConstants, USER_PERMISSIONS } from "@iso/constants/user"
import RegistrationBox from "@iso/Pages/Common/RegistrationBox/RegistrationBox"
import IntlMessage from "@iso/components/intlMessage"
import {translate} from '@iso/utils/Translate';

class CSignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initValue: {
        email: "",
        password: "",
        remember_me: false,
      },
    }
  }

  handleSubmit =  (values, bag) => {
    AuthService.loginApi(values).then((res) => {
      if(!res){
        bag.setErrors({ password: <IntlMessage id="admin.sign_in.error.incorrect_info"/> })
        bag.setSubmitting(false)
      }
      const { code, data } = res.data;
      if (code === 200 && USER_PERMISSIONS.SUPER_USER.roles.includes(data.role)) {
        this.props.setCurrentUser({ ...data, rememberMe: values.remember_me })
      } 
      else if(!USER_PERMISSIONS.SUPER_USER.roles.includes(data.role)){
        bag.setErrors({ password: "Incorrect login information!" })
        bag.setSubmitting(false)
      }
      else {
        bag.setErrors({ password: res.data.message })
        bag.setSubmitting(false)
      }
    }).catch((res) => {
      bag.setErrors({ password: <IntlMessage id="admin.sign_in.error.incorrect_info"/> })
      bag.setSubmitting(false)
    });

  }

  renderSignInForm() {
    return (
      <Formik
        initialValues={this.state.initValue}
        enableReinitialize={false}
        onSubmit={this.handleSubmit}
        validationSchema={SignInFormValidator}
      >
        {({ values, handleSubmit, handleChange, touched, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form.Group as={Row} controlId="loginEmail">
              <Col sm={{ span: "8", offset: "2" }}>
                <Form.Label>
                  <IntlMessage id="admin.sign_in.email.label" />
                </Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="email"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="loginPassword">
              <Col sm={{ span: "8", offset: "2" }}>
                <Form.Label><IntlMessage id="admin.sign_in.password.label"/></Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder={translate('admin.sign_in.password.place_holder')}
                  onChange={handleChange}
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="password"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="loginRememberMe">
              <Col xs="12" className="text-center">
                <Form.Check 
                  type="checkbox"
                  name="remember_me"
                  onChange={handleChange}
                  label={<IntlMessage id="admin.sign_in.remember_me.label"/>}
                  className="text-bold checkbox_custom"
                />
              </Col>
            </Form.Group>
            <div className="buttons text-center mt-4">
              <Button className="hippie-blue-button sign-in-btn" type="submit">
                <IntlMessage id="admin.sign_in.submit"/>
              </Button>
            </div>
            <Row>
              <Col xs="12" className="text-center mt-5 small-text">
                <Link
                  className="non-underline-link"
                  to="/admin/forgot_password"
                >
                  <IntlMessage id="admin.sign_in.forgot_password"/>
                </Link>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    )
  }

  render() {
    return (
      <div className="prayer-portal sign-up-container">
        <RegistrationBox title={<IntlMessage id="admin.sign_in.title"/>} form={this.renderSignInForm()} />
      </div>
    )
  }
}

const mapStoreToProps = (store) => ({})

const mapDispatchToProps = {
  setCurrentUser: setCurrentUser,
}

const SignIn = connect(mapStoreToProps, mapDispatchToProps)(CSignIn)

export default SignIn
