import banAccountActions from "@iso/actions/banAccountActions";

export const initialState = {
  account_id: null,
  is_banned: false,
};

export default function banAccountReducers(state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case banAccountActions.SET_BAN_ACCOUNT_DATA:
      return {
        ...state,
        ...payload.data,
      };
    case banAccountActions.RESET_DATA:
      return initialState;
    default:
      return state;
  }
}
