import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ManageAndBillingFAQ from "./components/manageAndBillingFAQ";
import AccountSettingsFAQ from "./components/accountSettingsFAQ";
import PrayerFAQ from "./components/prayerFAQ";
import PrayerPartnerFAQ from "./components/prayerPartnerFAQ";
import ContributionFAQ from "./components/contributionFAQ";
import GiftFAQ from "./components/giftsFAQ";
import PrivacyFAQ from "./components/privacyFAQ";
import {useSelector} from "react-redux";
import EmailPopup from "../../../components/EmailPopup/EmailPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px 150px",
  },
  accordion: {
    border: "1px solid #AFDAF5",
    borderRadius: "4px",
    boxShadow: "none",
    marginBottom: "12px",
  },
  heading: {
    fontSize: "22px",
    fontWeight: "700",
    padding: "28px 40px",
    fontFamily: "BrandonText",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  body: {
    padding: "28px 40px",
    fontFamily: "BrandonText",
  },
  bodyText: {
    fontFamily: "BrandonText",
  },
  expanded: {},
  headingContainer: {
    height: "90px",
    padding: "0px",
    [theme.breakpoints.down("md")]: {
      height: "60px",
    },
  },
}));

export default function FAQAccordions() {
  const user = useSelector((state) => state.authentication.user);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root} className="faq__wrapper">
      <ManageAndBillingFAQ
        handleChange={handleChange}
        expanded={expanded}
      ></ManageAndBillingFAQ>
      <AccountSettingsFAQ
        handleChange={handleChange}
        expanded={expanded}
      ></AccountSettingsFAQ>
      <PrayerFAQ handleChange={handleChange} expanded={expanded}></PrayerFAQ>
      <PrayerPartnerFAQ
        handleChange={handleChange}
        expanded={expanded}
      ></PrayerPartnerFAQ>
      <ContributionFAQ
        handleChange={handleChange}
        expanded={expanded}
      ></ContributionFAQ>
      <PrivacyFAQ handleChange={handleChange} expanded={expanded}></PrivacyFAQ>
      <GiftFAQ handleChange={handleChange} expanded={expanded}></GiftFAQ>
      { !user && <EmailPopup/>}
    </div>
  );
}
