import React from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";

const NotifyModal = ({ open, handleClose }) => {
  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <IntlMessage id="prayer_partners.requests.modal.thank_you.title" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <IntlMessage id="prayer_partners.requests.modal.thank_you.content" />
        </p>
        <div className="buttons text-center mt-4">
          <Button
            className="hippie-blue-button"
            type="button"
            onClick={handleClose}
          >
            <IntlMessage id="common.ok" />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NotifyModal;
