import React from "react";
import { Carousel } from "react-bootstrap";
import QuoteItem from "./QuoteItem";
import "./QuoteCarousel.scss";

const QuoteCarousel = (props) => {
  const { items } = props;
  return (
    <Carousel
      className="quote__carousel"
      prevIcon={
        <span
          aria-hidden="true"
          className="carousel-control-prev-icon d-none"
        />
      }
      nextIcon={
        <span
          aria-hidden="true"
          className="carousel-control-prev-icon d-none"
        />
      }
    >
      {items.map((item, idx) => {
        return (
          <Carousel.Item key={idx}>
            <QuoteItem
              quote1={item.quote1}
              quote2={item.quote2}
              author={item.author}
              location={item.location} 
            ></QuoteItem>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default QuoteCarousel;
