import React from 'react';
import IntlMessage from "@iso/components/intlMessage";
import {date} from "yup";
import moment from "moment";

const ContentForTogglePauseModal = ({ status, endDate }) => {
  switch (status) {
    case "paused":
      let now = moment().format("YYYY-MM-DD HH:mm:ss")
      return now >= endDate ? (
        <IntlMessage id="admin.requests.request_details_card.confirm_unpause.content" />
      ) : (
        <IntlMessage id="admin.requests.request_details_card.confirm_unpause.content.unassigned" />
      );

    default:
      return (
        <IntlMessage id="admin.requests.request_details_card.confirm_pause.content" />
      );
  }
};

export default ContentForTogglePauseModal;