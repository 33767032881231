import React, { Fragment, Component } from "react";

class Welcome extends Component {

  render() {
    return (
      <Fragment>
        <h2>Welcome!</h2>
      </Fragment>
    );
  }
}

export default Welcome;
