import React from "react"
import { Link } from "react-router-dom"

import "./Sidebar.styles.scss"
import IntlMessage from "@iso/components/intlMessage" 
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router";
import {  useSelector } from "react-redux";
import { Badge } from "react-bootstrap";

const Accordion = withStyles({
  root: {
    backgroundColor: '#499e9e', 
    color: 'white',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      fontWeight: 'bold', 
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {  
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: { 
    marginTop: '0px', 
  },
}))(MuiAccordionDetails);

const Sidebar = (props) => {
  const { title, menuSidebar } = props
  const history = useHistory() 
  const notiState = useSelector((state) => state.notificationReducers);
  const contactState = useSelector((state) => state.contactReducers);
  const handleClick = (path) => {
    history.push(path) 
    
  }
  const [numNoti, setNumNoti] = React.useState(0);
  const [numContact, setNumContact] = React.useState(0);
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const user = useSelector((state) => state.authentication.user);
  React.useEffect(() => {
    setNumNoti(notiState.data);
    setNumContact(contactState.numberOfContact);
  })
  return (
    <div
      className="hippie-blue-background hippie-side-bar vertical-menu col-2 col-sm-3 col-md-3 col-lg-2  show d-md-flex d-none p-0 pt-4 min-vh-100"
      id="sidebar"
    >
      {menuSidebar && (
        <ul className="nav flex-column flex-nowrap overflow-hidden w-100">
          {menuSidebar.map((item, index) => {
            return (
              <li className="nav-item" key={item.path}>
                {
                  !item.subMenu &&  ( !item.role || (item?.role && item?.role === user?.role)) && 
                  (
                    <Link className="nav-link text-truncate" to={item.path}>
                      <span className="d-none d-sm-inline">
                      <IntlMessage id={item.title} />
                      {
                        item?.badge &&
                        <Badge className="ml-2" pill variant="danger">
                          {numNoti}
                        </Badge>
                      }
                     
                      </span>
                    </Link>
                  )
                }
                {/* subMenu */}
                {
                  item.subMenu &&  ( !item.role || (item?.role && item?.role === user?.role)) && 
                  (
                    <Accordion square  onChange={handleChange('panel1')}  >
                      <AccordionSummary  
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                      <Typography>
                          <span className="d-none d-sm-inline">
                          <IntlMessage id={item.title} />
                          {
                            item?.section == 'support_centers' && numContact > 0 &&
                            <Badge className="ml-2" pill variant="danger">
                              {numContact}
                            </Badge>
                          }
                        
                          </span>
                      </Typography>
                      </AccordionSummary>
                      {
                        item.subMenu.map((subMenuItem, index) => {
                          return ( 
                            <AccordionDetails onClick = {() => handleClick(subMenuItem?.subPath)}>
                            <Typography>
                            <IntlMessage id={subMenuItem.title} />
                            </Typography>
                          </AccordionDetails>
                          )
                        })
                      } 
                      </Accordion>
                    )
                  }
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Sidebar
