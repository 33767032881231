const prayerPartnerReligions = [
    { id: 'catholic', label: 'Catholic'},
    { id: 'protestant', label: 'Protestant'},
    { id: 'eastern-orthodox', label: 'Eastern Orthodox'},
    { id: 'evangelical', label: 'Evangelical'},
    { id: 'anglican', label: 'Anglican'},
    { id: 'jewish', label: 'Jewish'},
		{ id: 'non-denomination', label: 'Non-Denominational'},
   // { id: 'christian', label: 'Christian'},
    // { id: 'other', label: 'Other'},
];

const messageTones = [
    { id: 'religious', label: 'Religious'},
    { id: 'spiritual_humanitarian', label: 'Spiritual / Humanitarian'},
];

export {prayerPartnerReligions}

const religions = prayerPartnerReligions.concat([
    { id: 'no-preference', label: 'No Preference'}
]);
export {religions, messageTones}
