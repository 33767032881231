import React from "react";
import { Modal } from "react-bootstrap";
import FlagRequestForm from "./SendResponseForm";
import IntlMessage from "@iso/components/intlMessage";


const SendResponseModal = (props) => {
  const { open, handleClose, requestId, successCallback } = props;

  return (
    <div>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <IntlMessage id="admin.support_center.modal.title"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FlagRequestForm
            handleClose={handleClose}
            requestId={requestId}
            successCallback={successCallback}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SendResponseModal;
