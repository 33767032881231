import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQAccordionStyle from "./AccordionStyle";
import './faq_common.scss';
import { history } from '@iso/helpers';
import { useDispatch, useSelector } from "react-redux";
import registerActions from "@iso/actions/confirmRequestModal";

const PrayerFAQ = (props) => {
  const { handleChange, expanded } = props;
  const classes = FAQAccordionStyle();
  const handleRequestAPrayer = () => {
    history.push("/requests/step1")
  }

  const dispatch = useDispatch();

  const gotoSignup = () => {
    dispatch(
      registerActions.openModal({
        type: registerActions.SHOW_REGISTER_MODAL,
      })
    );
  }

  return (
    <>
      <Accordion
        className={`${classes.accordion} ${classes.expanded} section-prayer`}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          className={classes.headingContainer}
          expandIcon={
            <ExpandMoreIcon style={{ fill: "#AFDAF5" }} fontSize="large" />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Prayer</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            {`Is there anything that I cannot
             request a Prayer Partner to pray for?`}
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`Prayers summoning evil spirits, or
            wishing for any negative event such
            as sickness, disease, harm, and the
            pain upon others are considered in
            violation of the community guidelines
            of this platform. Each Prayer Partner
            is obliged to flag prayer requests in
            violation of these standards.
            PrayerCloud will review all flagged
            prayers, and contact the user or
            delete the account.`}
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            Does a prayer guarantee an outcome?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`Outcomes of prayer are ultimately up
            to the will of God. Therefore,
            PrayerCloud and our Prayer Partners
            cannot guarantee any outcome from
            any prayer. We can guarantee that
            the prayer you request is made and
            completed by a loyal, dedicated, and
            empathetic Prayer Partner.`}
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            {`How do I know that my prayer was
            made?`}
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`PrayerCloud conducts an extensive
            selection process to find our Prayer
            Partners. Our Prayer Partners are
            selected based on their devotion,
            knowledge, the joy that they find in
            prayer, and in their ability to
            empathize with others. \n
            Upon the completion of each prayer,
            our Prayer Partners formally notify
            PrayerCloud. PrayerCloud also
            conducts internal audits to ensure
            that the prayers have been made.`}
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            {`What happens when I request a prayer with candle lighting or incense burning?`}
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`Candle lighting and incense burning are meaningful symbolic acts
              for many people. Some view the candlelight as a symbol of our prayer
              entering the light of God. For others, the burning of incense signifies
              prayers going up to God.\n
              If you have requested a prayer with a candle lighting or incense burning, 
              your Prayer Partner will burn incense or light a candle on your behalf 
              while praying for you. `}
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            {`If I request a prayer, how should I
            write to petition God?`}
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`How you petition God is a very
            personal choice, and whatever
            petition you choose, as long as it is in
            line with our guidelines, our Prayer
            Partners will incorporate it into their
            prayers. Some of us like to think of
            prayer as little love letters to God.
            Perhaps he is reading and saving them
            all. We believe that it is best to be
            humble and trustful.`}
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
           {`Is this a replacement for going to
           church or my place of worship?`}
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`We do not view PrayerCloud or
            the platform we provide as a
            substitute for attending church,
            but as a supplemental service to
            provide support for you and your
            loved ones in the form of prayer.`}
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
           {`When will my prayer be completed?`}
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            When you <span className="text-hippie-blue hover_pointer" onClick={handleRequestAPrayer}>Request a Prayer</span>,
            you have the option to choose a prayer date in custom options. If no date is selected, we aim to complete
            your prayer as soon as possible, but no later than 1 week from your request. If a date is specified,
            we aim to complete your prayer on this date.
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            Do I need to fill out the Custom Options?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            No. All custom options are optional and are not required to complete a prayer request. Custom
            options are there to help you to customize your prayer, as you wish it to be made.
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            What is Message Tone, and what is the difference between Religious and Spiritual/Humanitarian?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
             Your Prayer Partner will write you (or your gift recipient) a personalized letter after they have completed your prayer. Letters are by default religious in tone and often make a reference to God and/or specific prayers. However, some users wish to request a letter with a non-religious tone (if for instance, they are gifting a prayer to a non-believer). You can specify your wishes through the ‘Message Tone’ field found in the custom options. 
            <br/><br/>
            You can select from a ‘religious’ tone or a ‘spiritual/humanitarian’ tone. If you specify a spiritual/humanitarian tone, the prayer itself will remain religious; however, the accompanying letter from your Prayer Partner will provide fewer details about the specific prayer and contain fewer or no references to God. 
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            Can I track my prayer?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            Yes, you can track your prayer. In order to do this you will need to <span className="text-hippie-blue hover_pointer" onClick={gotoSignup}>sign up</span> for an account before you complete your order. Once you have signed in, click on your name in the top corner (or alternatively on the menu in the corner if you are using a mobile device) and go to My Prayers. 
            <br/><br/>
            Here you can view all of your requests. If you go into a Prayer Request’s details, you can see if the letter from your Prayer Partner is in-progress, sent, or viewed. If you have not received an email that is listed as sent, be sure to check your spam folder.
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            I have an urgent request, can you accommodate this?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            If your request is urgent, please let us know in the ‘special request’ area under custom options.
            Please note that we cannot guarantee a quicker timeline / special timing. Our Prayer Partners
            will do their best to meet your needs, and they will complete your request with utmost care. As
            our Prayer Partner network expands, we hope to be able to better accommodate urgent
            requests.
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            What can I request in the ‘special request’ section?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            You can use the special request section to request for example, a specific timing or a beloved
            prayer. If suggesting a specific passage or prayer, please keep in mind that your Prayer Partner
            must deem the prayer to be of a reasonable length for a single prayer request. Regarding
            urgent requests and specific timing, please see note above regarding our ability to
            accommodate these wishes. <br/><br/>
            If you would like to request a previous Prayer Partner, please use this section to let us know. Be
            sure to include their first name and allow for a little extra time to make sure that this Prayer
            Partner is available.
          </Typography>
  
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            If I request a prayer for someone else, will they be notified?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            If you request a prayer for someone else and select that it is a gift, you and the recipient will
            both be notified. You will be able to write the recipient a gift note, and PrayerCloud will send
            them a gift notification. After the prayer has been completed, they will also receive a
            personalized note from their Prayer Partner, and you will receive a copy. <br/><br/>
            If you request a prayer for someone else that is not a gift, only you will be notified. PrayerCloud
            will not contact the third party. You will be the sole recipient of both a letter confirming your
            request, as well a personal note from your Prayer Partner after they have completed your
            prayer.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PrayerFAQ;
