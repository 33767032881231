const Menu = [
  // {
  //   id: 0,
  //   name: "",
  //   href: "/",
  //   title: "Main",
  // },

  {
    id: 1,
    name: "about",
    href: "/about",
    title: "About",
  },
  //   menu with subMenus
  //   {
  //     name: "about",
  //     href: "/about",
  //     title: "About",
  //     subMenu: [
  //       {
  //         name: "about",
  //         href: "/about",
  //         title: "About",
  //       },

  //       {
  //         name: "about",
  //         href: "/about",
  //         title: "About",
  //       },
  //     ],
  //   },
];

export default Menu;
