import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "rgba(28, 22, 52, 1)",
    boxShadow: "none",
    height: "100%",
    fontSize: "15px",
    minHeight: "calc(100vh - 255px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 130px)",
    },
    [theme.breakpoints.up("md")]: {
      borderLeft: "1px solid #D9E9F1",
      fontSize: "16px",
    },
  },
  header: {
    height: "121px",
    padding: "28px 50px;",
    borderBottom: "1px solid #D9E9F1",
    display: "flex",
    alignItems: "center",
    fontSize: "33px",
    color: '#000000',
  },
  content: {
    padding: "30px 35px",
    [theme.breakpoints.up("lg")]: {
      padding: "30px 112px 30px 48px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "43px",
      paddingRight: "21px",
    },
  },

  title: {
    fontSize: "18px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]:{
      fontSize: '16px',
    }
  },
  titleAnyThingElse: {
    marginTop: "30px",
    [theme.breakpoints.down("sm")]:{
      marginTop: "20px",
    }
  },
  textArea: {
    border: "border: 1px solid rgba(0, 0, 0, 0.4)",
    borderRadius: "6px", 
    padding: "13px 22px",
    height: "120px!important",
    width: "359px",
    [theme.breakpoints.down('sm')]: {
      width: "288px",
    }
  },
  description: {
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: "12px",
    }
  }
}));

export default useStyles;
