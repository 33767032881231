import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import { RELIGIONS } from "@iso/constants";
import { Form, Row, Col, Button } from "react-bootstrap";
import PrayerPartnerService from "@iso/services/PrayerPartnerService";

import { Formik } from "formik";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import { ChangeAssigneeValidator } from "./validator";
import HTTPRequests from "./utils";

function ChangeAssigneeModal(props) {
  const { showModal, closeModal, requestId, handleSubmit, request, showCloseButton, setShowChangeAssigneeModal, setRequestValue } = props;
  const [prayers, setPrayers] = useState([]);
  const [firstPrayer, setFirstPrayer] = useState();

  let initValue = {
    denomination: request.denomination,
    prayer_partner: "",
  };

  const [formikValues, setFormikValues] = React.useState(initValue);

  const getPrayers = (value) => {
    const denomination = RELIGIONS.find(item => item.id == value) || RELIGIONS[0]
    try {
      PrayerPartnerService.adminGetPrayersByDenomination(denomination?.id, requestId).then(
        (res) => {
          const { code, data, errors } = res.data;
          if (code === 200 && data) {
            const firstItem = data.shift();
            setFirstPrayer(firstItem);
            setFormikValues({
              denomination: denomination.id,
              prayer_partner: firstItem && firstItem.id,
            });
            setPrayers(data);
          }
        }
      );
    } catch (error) {}
  };

  const handleAutoAssign = () => {
    HTTPRequests.handleChangeAssignee({
      values: {
        prayer_partner: '',
      },
      isAutoAssign: true,
      request,
      setShowChangeAssigneeModal,
      setRequestValue,
    })
  }

  React.useEffect(() => {
    getPrayers(request.denomination);
  }, [request?.denomination]);

  return (
    <div>
      <Modal show={showModal} onHide={showCloseButton ? closeModal : () => {}}>
        <Modal.Header closeButton={showCloseButton}>
          <Modal.Title>
            <IntlMessage id="admin.requests.details.change_assignee.title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={formikValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={ChangeAssigneeValidator}
          >
            {({ values, handleSubmit, handleChange, touched, errors }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Form.Group as={Row}>
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label>
                      <IntlMessage id="admin.requests.details.change_assignee.select_denomination" />
                    </Form.Label>
                    <Form.Control
                      name="denomination"
                      as="select"
                      className="form-control"
                      value={values.denomination}
                      onChange={(event) => {
                        getPrayers(event.target.value);
                      }}
                    >
                      <option value="" disabled defaultValue></option>
                      {RELIGIONS.map((denomination) => {
                        return (
                          <option value={denomination.id} key={denomination.id}>
                            {denomination.label}
                            {/* <IntlMessage
                              id={`admin.requests.details.change_assignee.${denomination}`}
                            /> */}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="denomination"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label>
                      <IntlMessage id="admin.requests.details.change_assignee.select_prayer_partner" />
                    </Form.Label>
                    {!firstPrayer ? (
                        <Form.Control
                        name="prayer_partner"
                        as="select"
                        className="form-control"
                        value={values.prayer_partner}
                        onChange={handleChange}
                        disabled
                      >
                        {firstPrayer && (
                          <option
                            value={firstPrayer.id}
                            key={firstPrayer.id}
                            defaultValue={firstPrayer.id}
                          >
                            {firstPrayer.full_name}
                          </option>
                        )}
                        {prayers.map((prayer, index) => {
                          return (
                            <option value={prayer.id} key={prayer.id}>
                              {prayer.full_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    ):(
                        <Form.Control
                        name="prayer_partner"
                        as="select"
                        className="form-control"
                        value={values.prayer_partner}
                        onChange={handleChange}
                      >
                        {firstPrayer && (
                          <option
                            value={firstPrayer.id}
                            key={firstPrayer.id}
                            defaultValue={firstPrayer.id}
                          >
                            {firstPrayer.full_name}
                          </option>
                        )}
                        {prayers.map((prayer, index) => {
                          return (
                            <option value={prayer.id} key={prayer.id}>
                              {prayer.full_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    )}
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="prayer_partner"
                    />
                  </Col>
                </Form.Group>
                <div className="buttons text-center mt-4">
                  <Button className="hippie-blue-button" type="submit">
                    <IntlMessage id="admin.requests.details.change_assignee.assign" />
                  </Button>
                  {
                    request.status !== "paused" && 
                      <Button className="light-gray-button ml-4" onClick={()=>{handleAutoAssign()}}>
                        <IntlMessage id="admin.requests.details.change_assignee.auto_assign" />
                      </Button>
                  }
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChangeAssigneeModal;
