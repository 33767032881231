import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import alertActions from "@iso/actions/Alert";
import CloseAlertImg from "@iso/assets/images/alert_close.svg";
import "./Alert.scss";
import { makeStyles } from "@material-ui/core/styles";
const { closeAlert } = alertActions;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  muiAlert: {
    width: "100vw",
    maxWidth: "1440px",
    top: "-90vh",
    height: "80px",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  muiAlertContainer: {
    width: "100vw",
  },
  success: {
    backgroundColor: "#52B9D4 !important",
  },
  error: {
    backgroundColor: "#EF2020 !important",
  },
  textBig: {
    fontSize: "24px",
  },
  textSmall: {
    fontSize: "16px",
  },
  textWhite: {
    color: "white",
  },
}));

function Alert(props) {
  const { severity, variant, onClose } = props;

  const classes = useStyles();

  let variantClass = {};
  let fontSizeClass = classes.textSmall;
  if (variant === "new") {
    variantClass = classes.muiAlert;
    fontSizeClass = classes.textBig;
  }

  let colorClass = {};
  if (severity) {
    colorClass =
      severity.toLowerCase() === "success" ? classes.success : classes.error;
  }

  const cancelButton = () => {
    if (variant === "new") {
      return (
        <img
          className="d-sm-block d-xs-none"
          src={CloseAlertImg}
          onClick={() => onClose()}
        ></img>
      );
    }

    return <></>;
  };
  return (
    <MuiAlert
      icon={false}
      action={cancelButton()}
      className={`${variantClass} ${colorClass} ${classes.textWhite} ${fontSizeClass} alert-desktop`}
      elevation={0}
      variant="filled"
      {...props}
    />
  );
}

const CAlert = () => {
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    dispatch(closeAlert());
  };

  const classes = useStyles();

  const alertStore = useSelector((state) => state.alert);
  const { severity, message, variant } = alertStore.data;

  let variantClass = {};
  if (variant === "new") {
    variantClass = classes.muiAlertContainer;
  }
  return (
    <div className={classes.root}>
      <Snackbar
        open={alertStore.alertShow}
        className={variantClass}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} variant={variant} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CAlert;
