import React from "react";

import { TextField } from "@material-ui/core";
import { Button, Row, Col, Container, Card } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import { Formik, Field, Form } from "formik";
import { useSelector } from "react-redux";
import DetailItem from "./DetailItem";
import { isTablet } from "react-device-detect";

const ContributionDetails = ({ handleChange, touched, errors }) => {
  const requestStepData = useSelector(state => state.requestStepReducer);

  const [values, setValues] = React.useState(requestStepData);
  const [edit, setEdit] = React.useState(false);

  return (
    <Container className="wrapper_contribution_details">
      <Row>
        <Col sm="12">
          <Card className="card_wrapper">
            <Card.Body>
              <Card.Title className="custom_card_title">
                <Row>
                  <Col xs="9">
                    <IntlMessage id="public.request.step3.left.contribution.title" />
                  </Col>
                </Row>
              </Card.Title>

              {!edit && (
                <Row className="mb-3">
                  <Col sm={isTablet ? 12 : 6}>
                    {values?.prayer_contribution && (
                      <DetailItem
                        className="mb-4  _first_custom_label custom_prayer_contribution"
                        label="public.request.step3.left.contribution.prayer_contribution"
                        displayValue={values?.prayer_contribution?.name}
                        i18n={false}
                      />
                    )}
                  </Col>
                  <Col sm={isTablet ? 12 : 6}>
                    {values?.prayer_frequency && (
                      <DetailItem
                        label="public.request.step2.frequency.title"
                        displayValue={values?.prayer_frequency?.name}
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ContributionDetails;
