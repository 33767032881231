export * from './history';
export * from './endpoints';

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
  }
  return "";
}

export function escapeObject(object, isEscape = true) {
  let escapedObj = object;
  for(let i in object){
    if(typeof object[i] == 'object'){
      escapeObject(object[i], isEscape);
    }else if(typeof object[i] == 'string'){
      escapedObj[i] = unescape(object[i]);
      if(isEscape){
        escapedObj[i] = escape(object[i]);
      }
    }else{
      escapedObj[i] = object[i]
    }
  }
  return escapedObj;
}