import React from "react";
import { Button, Row, Col, Form, Spinner } from "react-bootstrap";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";
import IntlMessage from "@iso/components/intlMessage";
import { ExpandMore } from "@material-ui/icons";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { ProfileInfoFormValidator } from "./validator";
import { Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import "@iso/assets/common.scss";
import { userRoleConstants } from "@iso/constants";

const ProfileForm = (props, ref) => {
  const {
    handleUpdateProfile,
    isEdit,
    setIsEdit,
    isLoading,
    profile,
    setProfile,
  } = props;

  const admins = [
    { id: userRoleConstants.ADMIN_ROLE, label: "Admin" },
    { id: userRoleConstants.SUPER_ADMIN_ROLE, label: "Super Admin" },
  ];

  const [errorMessages, setErrorMessages] = React.useState({});
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    role: "",
  };

  const defaultValue = new Object({
    ...initialValues,
    ...profile,
  });

  const [formikValues, setFormikValues] = React.useState(defaultValue);

  const handleValueChange = (name, value) => {
    formikValues[name] = value;
    setFormikValues({ ...formikValues });
  };

  React.useEffect(() => {
    setFormikValues({ ...formikValues });
  }, [profile]);

  const handleSubmit = (values, bag) => {
    try {
      handleUpdateProfile(values).then((res) => {
        ProcessRequest.run({
          res,
          hasNoti: true,
          successCallback: (data) => {
            setProfile(data.data);
          },
        });
      });
    } catch (error) {
    } finally {
      setIsEdit(false);
    }
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setFormikValues(defaultValue);
  };

  return (
    <Formik
      validationSchema={ProfileInfoFormValidator}
      enableReinitialize={true}
      initialValues={formikValues}
      onSubmit={handleSubmit}
    >
      {({
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        handleChange,
        resetForm,
        values,
        touched,
        errors,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit} className="p-2 profile_card">
            <Row>
              <Col xs={12} md={12} className="p-3 pl-md-4">
                <Row>
                  <Col sm="12">
                    <Form.Group controlId="formFirstName" className="mb-4">
                      <TextField
                        label="First name"
                        variant="outlined"
                        name="first_name"
                        autoFocus
                        value={values.first_name}
                        onChange={(e) => {
                          handleValueChange("first_name", e.target.value);
                        }}
                        error={touched.first_name && !!errors.first_name}
                        helperText={touched.first_name && errors.first_name}
                        disabled={!isEdit}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <Form.Group controlId="formLastName" className="mb-4">
                      <TextField
                        label="Last name"
                        variant="outlined"
                        name="last_name"
                        value={values.last_name}
                        onChange={(e) => {
                          handleValueChange("last_name", e.target.value);
                        }}
                        error={touched.last_name && !!errors.last_name}
                        helperText={touched.last_name && errors.last_name}
                        disabled={!isEdit}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <Form.Group controlId="formEmail" className="mb-4">
                      <TextField
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={values.email}
                        onChange={(e) => {
                          handleValueChange("email", e.target.value);
                        }}
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        disabled={!isEdit}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="form-group">
                  <Col xs="12" className="picker-wrapper">
                    <Select
                      fullWidth={true}
                      className="my-control2 religion_margin_top "
                      IconComponent={ExpandMore}
                      disabled={!isEdit}
                      value={values.role}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        className: "new-design",
                      }}
                      onChange={(e) => {
                        handleValueChange("role", e.target.value);
                      }}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                        variant: "filled",
                      }}
                    >
                      {admins.map((admin) => (
                        <MenuItem key={admin.id} value={admin.id}>
                          {admin.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
            {isEdit && (
              <Row className="justify-content-center card_info_control m-0 row">
                <Button
                  className="card_info_btn_cancel btn_control mx-1 mb-1 btn btn-primary"
                  onClick={() => cancelEdit()}
                >
                  <IntlMessage id="prayer_partners.account_settings.profile.btn.cancel" />
                </Button>
                <Button
                  type="submit"
                  disabled={isLoading}
                  className="btn_control hippie-blue-button mx-1 mb-1 btn btn-primary"
                >
                  <IntlMessage id="prayer_partners.account_settings.profile.btn.save" />
                  {isLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </Row>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfileForm;
