import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import "./styles.scss";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ReportsService from "@iso/services/ReportsService";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { roundUpAmount } from "@iso/utils/FormatData";

import {MDBBadge, MDBDataTable} from "mdbreact";
import { DATE_FORMAT_REPORT_REQUEST, STATUS_REPORTS } from "./constants";
import { history } from "@iso/helpers/history";
import alertActions from "@iso/actions/Alert";
import { useDispatch } from "react-redux";
const { openAlert } = alertActions;

const RequestReports = props => {
  const dispatch = useDispatch();
  const [values, setValues] = useState();
  const [tableLists, setTableList] = useState([]);
  const [showSelectFrom, setShowSelectFrom] = useState(false);
  const [showSelectTo, setShowSelectTo] = useState(false);
  const time_zone = -(new Date().getTimezoneOffset() / 60);

  const formatDate = date => {
    return moment(date).format("YYYY-MM-DD");
  };
  const handleReportRequests = () => {
    ReportsService.adminReportsRequestByDate(
      formatDate(
        selectedDate?.fromDate ? selectedDate?.fromDate : "1970-01-01"
      ),
      formatDate(selectedDate.toDate)
    ).then(res => {
      if (res) {
        const { code } = res.data;
        if (res && code === 200) {
          setValues(res.data);
          const tableValues = formatTableDataPrayerRequests(res.data.data);
          setTableList(tableValues.data);
          if (!tableValues) {
            const tbody = document.querySelector(
              "tbody[data-test=table-body] tr td"
            )
            if(tbody) tbody.innerText = `There is no partner.`;
          }
        }
      }
    });
  };

  const [selectedDate, setSelectedDate] = useState({
    fromDate: null,
    toDate: new Date(),
  });

  const handleSubmit = () => {
    handleReportRequests();
  };

  const handleKeyDown = e => {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode > 47 && keyCode < 58) {
      e.preventDefault();
    }
  };

  const handleClick = id => {
    history.push(`/admin/requests/${id}`);
  };
  const handleShowSelectFrom = () => {
    setShowSelectFrom(!showSelectFrom);
  };
  const handleShowSelectTo = () => {
    setShowSelectTo(!showSelectTo);
  };
  const handleDateChangeRaw = e => {
    console.log("sfs");
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    handleReportRequests();
  }, []);

  const formatTableDataPrayerRequests = data => {
    let dataRow = [];
    let header = [
      {
        label: <IntlMessage id="admin.reporting.request.user_id.table.title" />,
        field: "client_id",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.request.user_status.table.title" />,
        field: "client",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.transaction_id.table.title" />,
        field: "request_id",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.sub_transaction_id.table.title" />,
        field: "sub_request_id",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.request.number.table.title" />,
        field: "id",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.request.date.table.title" />,
        field: "request_date",
        sort: "asc",
        render: function(data) {
          return;
        },
      },
      {
        label: (
          <IntlMessage id="admin.reporting.request.due_date.table.title" />
        ),
        field: "due_date",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.reporting.request.denomination.table.title" />
        ),
        field: "denomination",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.frequency.table.title" />,
        field: "frequency",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.reporting.request.contribution.table.title" />
        ),
        field: "contribution",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.reporting.request.user_location.table.title" />
        ),
        field: "user_location",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.reporting.request.prayer_type.table.title" />
        ),
        field: "prayer_type",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.reporting.request.contribution_amount.table.title" />
        ),
        field: "contribution_amount",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.reporting.request.discount.table.title" />
        ),
        field: "discount",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.reporting.request.final_revenue.table.title" />
        ),
        field: "final_revenue",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.request.status.table.title" />,
        field: "status",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.request.late.table.title" />,
        field: "late",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.reporting.request.amount_late.table.title" />
        ),
        field: "amount_late",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.reporting.request.prayer_partner.table.title" />
        ),
        field: "prayer_partner",
        sort: "asc",
      },
    ];

    data.forEach((e, index) => {
      dataRow.push({
        client_id: e.client ? e.client.id : '',
        client: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={ e.client?.email_verified_at}
          >
            { e.client?.email_verified_at ? 'Verified' : 'Unverified'}
          </MDBBadge>
        ),
        request_id: e.transaction_parent?.id || (e.transaction_user?.id || ''),
        sub_request_id: e.transaction_partner?.id || '',
        id: e.id,
        request_date: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.created_at}
          >
            {moment.utc(e.created_at).utcOffset(time_zone).format(DATE_FORMAT_REPORT_REQUEST)}
          </MDBBadge>
        ),
        due_date: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.end_date}
          >
            {moment.utc(e.end_date).utcOffset(time_zone).format(DATE_FORMAT_REPORT_REQUEST)}
          </MDBBadge>
        ),
        denomination: e?.denomination_label,
        frequency: e?.frequency_label,
        contribution: e?.contribution.name,
        user_location: e?.client?.country,
        prayer_type: e?.type?.name,
        contribution_amount: "$" + e?.contribution?.price,
        discount: e?.discount_amount 
          ? 
            "$" + ((e?.discount_amount !== null) 
            ? roundUpAmount(e?.discount_amount)
            : (roundUpAmount(e?.transaction?.price_original - e?.transaction?.amount))) 
          : "",
        final_revenue: "$" + (roundUpAmount(e?.final_revenue)),
        status: STATUS_REPORTS?.find(category => category?.id === e?.status)
          ?.label,
        late: e?.amount_late === "N/A" ? "No" : "Yes",
        amount_late: e?.amount_late,
        prayer_partner: e?.prayer_partner?.full_name,
        clickEvent: () => handleClick(e.id),
      });
    });
    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    };
  };

  const handleExportExcel = () => {
    ReportsService.adminExportReportsRequestByDate(
      formatDate(
        selectedDate?.fromDate ? selectedDate?.fromDate : "1970-01-01"
      ),
      formatDate(selectedDate.toDate)
    ).then(res => {
      if (res.data.code === 200) {
        dispatch(
          openAlert({
            message: <IntlMessage id="admin.export.notification.success" />,
            severity: "success",
          })
        );
      } else {
        dispatch(
          openAlert({
            message: <IntlMessage id="admin.export.notification.error" />,
            severity: "error",
          })
        );
      }
    });
  };
  return (
    <div className="mint-tulip-background p-3 min-vh-100 reports_list">
      <Row className="my-2">
        <Col xs="12" sm="6" className="request_title">
          <h2>
            <IntlMessage id="admin.reporting.request.title" />
          </h2>
        </Col>
      </Row>
      <Card className="card_swapper">
        <Container>
          <div>
            {" "}
            <IntlMessage id="admin.reporting.request.date.title" />{" "}
          </div>
          {/* Date picker */}
          <Row className="mt-2">
            <Col xs="12" lg="3" className="picker-wrapper mb-3 mb-xs-0">
              <KeyboardDatePicker
                autoOk
                allowKeyboardControl={false}
                inputProps={{readOnly: true}}
                disableToolbar
                inputVariant="outlined"
                fullWidth={true}
                className="my-picker"
                open={showSelectFrom}
                onClick={handleShowSelectFrom}
                onClose={handleShowSelectFrom}
                maxDate={moment(selectedDate.toDate).subtract(1, "days")}
                label={
                  <IntlMessage id="admin.reporting.request.date.from.title" />
                }
                value={selectedDate?.fromDate ? selectedDate?.fromDate : null}
                format="MMM DD, yyyy"
                InputAdornmentProps={{ position: "end" }}
                onKeyDown={handleKeyDown}
                onChange={date => {
                  if (date && !date.isValid()) {
                    setSelectedDate({
                      ...selectedDate,
                      fromDate: null,
                    });
                    return false;
                  } else {
                    setSelectedDate({
                      ...selectedDate,
                      fromDate: date,
                    });
                  }
                }}
              />
            </Col>

            <Col xs="12" lg="3" className="picker-wrapper mb-3 mb-xs-0">
              <KeyboardDatePicker
                autoOk
                allowKeyboardControl={false}
                inputProps={{readOnly: true}}
                disableToolbar
                inputVariant="outlined"
                fullWidth={true}
                className="my-picker"
                maxDate={moment()}
                open={showSelectTo}
                onClick={handleShowSelectTo}
                onClose={handleShowSelectTo}
                label={
                  <IntlMessage id="admin.reporting.request.date.to.title" />
                }
                value={selectedDate?.toDate ? selectedDate?.toDate : null}
                format="MMM DD, yyyy"
                InputAdornmentProps={{ position: "end" }}
                onChange={date => {
                  setSelectedDate({
                    ...selectedDate,
                    toDate: date,
                  });
                }}
              />
            </Col>

            <Col xs="12" lg="2" className="mb-3 mb-xs-0">
              <Button
                className="hippie-blue-button"
                type="button"
                onClick={handleSubmit}
              >
                <IntlMessage id="common.show" />
              </Button>
            </Col>
          </Row>
          {/* Request list */}
          <div className="list-requests">
            <p>
              <IntlMessage id="admin.reporting.new_request.title" />
              {values?.count?.new_request}
            </p>
            <p>
              <IntlMessage id="admin.reporting.late_request.date.title" />
              {values?.count?.late_request}
            </p>
            <p>
              <IntlMessage id="admin.reporting.total_request.date.title" />
              <span className="unit-dollar">
                <IntlMessage id="common.price.unit.dollar" />
                {values?.total_contribution}
                {/* {values.total} */}
              </span>
            </p>
          </div>

          {/* export excel */}
          <div className="export-excel" onClick={handleExportExcel}>
            <ArrowUpwardIcon className="arrowUpward" />
            <IntlMessage id="admin.reporting.request.export.excel.title" />
          </div>

          {/* Table */}

          <MDBDataTable
            hover
            paging={true}
            id="table_lists_table"
            entries={25}
            searching={false}
            noBottomColumns={true}
            entriesOptions={[5, 20, 25]}
            sortRows={['due_date', 'request_date', 'client']}
            data={tableLists}
            className="request-table"
          />
        </Container>
      </Card>
    </div>
  );
};

export default RequestReports;
