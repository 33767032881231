import "./styles.scss";

import React from "react";
import RequestService from "@iso/services/RequestService";
import LinearProgress from "@material-ui/core/LinearProgress";
import RequestDetailsCard from "./RequestDetailsCard";
import IntlMessage from "@iso/components/intlMessage";
import RequestDetailsPending from "./RequestDetailPending";
import RequestDetailsFlagged from "./RequestDetailsFlagged";
import { history } from "@iso/helpers";

import {
  APPROVAL_STATUS,
  FLAGGED_STATUS,
} from "@iso/components/Request/constants";
import { useDispatch } from "react-redux";
import alertActions from "@iso/actions/Alert";
const { openAlert } = alertActions;

const RequestDetailsPanel = (props) => {
  const { prayerRequestId } = props;
  const [requestValue, setRequestValue] = React.useState();
  const [isLoad, setIsLoad] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showChangeAssigneeModal, setShowChangeAssigneeModal] = React.useState(false);

  const dispatch = useDispatch();

  const getRequestData = () => {
    setLoading(true);
    try {
      RequestService.adminShowRequest(prayerRequestId).then((res) => {
        const { code, data, message } = res.data;
        if (code === 200 && data) {
          setRequestValue(res.data.data);
        } else {
          dispatch(
            openAlert({
              message: message,
              severity: "error",
            })
          );
        }
        setIsLoad(false);
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getRequestData();
  },[requestValue?.status]);

  const [showTranslate, setShowTranslate] = React.useState({});

  const showTransactionCallback = (value) => {
    setShowTranslate({ ...showTranslate, [value.name]: value.open });
  };

  if (isLoad) {
    return (
      <h3 className="text-center text-secondary">
        <IntlMessage id="admin.requests.details.not_found" />
      </h3>
    );
  }

  const handleRequestChangeCallback = (newRequest) => {
    setRequestValue((prev) => {
      return {
        ...prev,
        ...newRequest,
      };
    });
  };

  const displayRequestPending = APPROVAL_STATUS.find(
    (status) => requestValue && requestValue.status === status
  );
  const displayRequestFlagged = FLAGGED_STATUS.find(
    (status) => requestValue && requestValue.status === status
  );

  const displayRequest = !displayRequestPending && !displayRequestFlagged;
  return (
    <>
      {loading && <LinearProgress />}
      {requestValue && displayRequest && (
        <RequestDetailsCard
          requestValue={requestValue}
          showTransactionCallback={showTransactionCallback}
          forceShowTransactionContent={showTranslate}
          setRequestValue={handleRequestChangeCallback}
          showChangeAssigneeModal={showChangeAssigneeModal}
          setShowChangeAssigneeModal={setShowChangeAssigneeModal}
        />
      )}

      {requestValue && displayRequestPending && (
        <RequestDetailsPending
          requestValue={requestValue}
          showTransactionCallback={showTransactionCallback}
          forceShowTransactionContent={showTranslate}
          setRequestValue={handleRequestChangeCallback}
        />
      )}

      {requestValue && displayRequestFlagged && (
        <RequestDetailsFlagged
          requestValue={requestValue}
          showTransactionCallback={showTransactionCallback}
          forceShowTransactionContent={showTranslate}
          setRequestValue={handleRequestChangeCallback}
          showChangeAssigneeModal={showChangeAssigneeModal}
          setShowChangeAssigneeModal={setShowChangeAssigneeModal}
        />
      )}
    </>
  );
};

export default RequestDetailsPanel;
