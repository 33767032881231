import React from 'react'
import {Grid} from '@material-ui/core'
import ValueItem from './ValueItem'
import OurValueTitle from '@iso/assets/images/landing_value_title.svg'
import DesktopSerenity from '@iso/assets/images/desktop_homepage_serenity.svg'
import DesktopEmpathy from '@iso/assets/images/desktop_homepage_empathy.svg'
import DesktopCommunity from '@iso/assets/images/desktop_homepage_community.svg'
import ValueSerenityImg from '@iso/assets/images/landing_value_serenity.svg'
import ValueSerenityImgMobile from '@iso/assets/images/landing_value_serenity_mobile.svg'
import ValueCommunityImg from '@iso/assets/images/landing_value_community.svg'
import ValueEmpathyImg from '@iso/assets/images/landing_value_empathy.svg'
import ValueEmpathyImgMobile from '@iso/assets/images/landing_value_empathy_mobile.svg'
import ValueCommunityImgMobile from '@iso/assets/images/landing_value_community_mobile.svg'
import OurValueTitleMobile from '@iso/assets/images/landing_value_title_mobile.svg'
import Section3ClodHeader from '@iso/assets/images/section3_cloud_header.svg'
import Section3Serenity from '@iso/assets/images/section3_serenity_background.svg'
import Section3Empathy from '@iso/assets/images/section3_empathy_background.svg'
import Section3Community from '@iso/assets/images/section3_community_background.svg'
import styled from 'styled-components'
import {isBrowser, isTablet, isMobile} from 'react-device-detect'
import IntlMessage from "@iso/components/intlMessage";
import ItemForTablet from "./ItemForTablet";
import './Section3.scss';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 50px;

    @media only screen and (min-width: 900px) {
        margin-top: 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px){
        margin-top: 77px!important;
    }

    @media only screen and (max-width: 375px) {
        margin-top: 32px!important;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        margin-top: 37px!important;
    }
    `

const TitleContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-bottom: 60px;
@media only screen and (min-width: 768px) and (max-width: 1024px){
    margin-bottom: 65px;
}
`

const Title = styled.img`

`

const ValueContainer = styled(Grid)`
    padding: 0px 36px;

    @media only screen and (min-width: 1440px) {
        max-width: 1440px;
    }

    @media only screen and (max-width: 900px) {
        margin-top: -50px !important;
    }

    ${isBrowser
        && `padding-left: 150px;
            padding-right: 150px;
            `
    }
`


const ValueList = [
    {
        icon: ValueSerenityImg,
        iconTablet: Section3Serenity,
        title: <IntlMessage id="landing.values.item1.title" />,
        description: <IntlMessage id="landing.values.item1.description" />
    },
    {
        icon: ValueEmpathyImg,
        iconTablet: Section3Empathy,
        title: <IntlMessage id="landing.values.item2.title" />,
        description: <IntlMessage id="landing.values.item2.description" />
    },
    {
        icon: ValueCommunityImg,
        iconTablet: Section3Community,
        title: <IntlMessage id="landing.values.item3.title" />,
        description: <IntlMessage id="landing.values.item3.description" />
    },
]


const ValueListMobile = [
    {
        icon: ValueSerenityImgMobile,
        iconTablet: Section3Serenity,
        title: <IntlMessage id="landing.values.item1.title" />,
        description: <IntlMessage id="landing.values.item1.description" />
    },
    {
        icon: ValueEmpathyImgMobile,
        iconTablet: Section3Empathy,
        title: <IntlMessage id="landing.values.item2.title" />,
        description: <IntlMessage id="landing.values.item2.description" />
    },
    {
        icon: ValueCommunityImgMobile,
        iconTablet: Section3Community,
        title: <IntlMessage id="landing.values.item3.title" />,
        description: <IntlMessage id="landing.values.item3.description" />
    },
]

const gridMobile = (index) => {
    if(isTablet) {
        return index === 2 ? {paddingTop: '50px'} : {paddingTop: '90px'}
    } else if(isMobile) {
        if (index === 0) {
             return {padding: '75px 31px 10px 31px'}
        }
        if (index === 2) {
             return {padding: '80px 31px 20px 31px'}
        }
        return {padding: '67px 31px 0 31px'}
    }
    
    return index === 0 ? {padding: '80px 31px 14px 31px'} : {padding: '67px 31px 0 31px'}
}

const Section3 = props => {

    let valueList = ValueListMobile ;
    if(isBrowser) valueList = ValueList;
    return (
    <Wrapper>
        {
            isTablet && <img style={{marginBottom: "10px"}} src={Section3ClodHeader}/>
        }
        <TitleContainer>
            <Title className="d-none d-md-block" src={OurValueTitle}></Title>
            <Title className="d-block d-md-none" src={OurValueTitleMobile}></Title>
        </TitleContainer>
        <ValueContainer container spacing={4} className={isTablet ? 'tablet-value-container justify-content-center' : 'text-center'}>
            {
                (isMobile ?
                
                (!isTablet
                ? valueList.map((value, idx) => {
                    return (
                        <Grid key={idx} item xs={12} lg={4} className="d-flex justify-content-center align-items-center custom_grid_section_3"
                              style={isBrowser ? {paddingTop: '80px'} : gridMobile(idx)}>
                            <ValueItem icon={value.icon} index={idx} title={value.title}
                                       description={value.description}></ValueItem>
                        </Grid>
                    )
                })
                : valueList.map((value, idx) => {
                    return (
                        <Grid key={idx} item xs={4} className="d-flex justify-content-center align-items-center p-0 tablet-item">
                            <ItemForTablet icon={value.iconTablet} index={idx} title={value.title}
                                       description={value.description}></ItemForTablet>
                        </Grid>
                    )
                }))
                :
                (
                      <>
                          <Grid xs={12} lg={4} style={{ padding: "10px"}}>
                              <img style={{marginTop: "26px", marginLeft: "-35px", width: "109%"}} src={DesktopSerenity} alt=""/>
                          </Grid>
                          <Grid xs={12} lg={4} style={{ padding: "8px"}}>
                              <img style={{marginTop: "26px", width: "100%"}} src={DesktopEmpathy} alt=""/>
                          </Grid>
                          <Grid xs={12} lg={4} style={{ padding: "10px"}}>
                              <img style={{marginTop: "11.5px", width: "100%"}} src={DesktopCommunity} alt=""/>
                          </Grid>
                      </>
                )
                )
            }
        </ValueContainer>

    </Wrapper>
    )
}

export default Section3
