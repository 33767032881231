import React, {useEffect} from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./styles.scss";
import { Formik, Field, Form } from "formik";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";
import IntlMessage from "@iso/components/intlMessage";
import { FormValidator } from "./validator";
import { history } from "@iso/helpers";
import {isTablet} from "react-device-detect";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import requestStepActions from "@iso/actions/RequestStepActions";

import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const Index = (props) => {
  const dispatch = useDispatch();
  const requestStepData = useSelector(
    (state) => state.requestStepReducer,
    shallowEqual
  );

  useEffect(() => {
    document.cookie = "requestStep=; expires=0; path=/";
  }, []);

  const [formikValues, setFormikValues] = React.useState(requestStepData);
  const handleSubmitForm = (values, errors) => {
    gtag('event', custom_events.CLICK_BUTTON, {
      button_label : 'Next step',
      button_value : 1,
    });
    ReactPixel.track(fb_pixel_event.custom_events.CLICK_BUTTON, {
      button_label : 'Next step',
      button_value : 1,
    });
    dispatch(
      requestStepActions.setDataStep({
        ...formikValues,
        step: 1,
      })
    );
    history.push("/requests/step2");

  };

  const [needOption, setNeedOption] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      const headerSelector = document.getElementById('public-header')
      if(headerSelector){
        const headerHeight = headerSelector.offsetHeight;
        const rightPart = document.getElementById('rightside');
        if (rightPart) {
          rightPart.style.minHeight = `calc(100vh - ${headerHeight}px)`
        }
      }
    }, 1000);
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if(!formikValues.is_gift){
      setFormikValues({
        ...formikValues,
        recipient_email: "",
        sender_name: "",
      });
    }
  }, [formikValues.is_gift])

  return (
    <div className={`container-full-width bg-for-request-half`}>
      <div className="container-fixed">
        <div className={`home-index ${needOption ? "need-option" : ""}`}>
          <Formik
            initialValues={formikValues}
            enableReinitialize={true}
            onSubmit={handleSubmitForm}
            validationSchema={FormValidator}
          >
            {({
              values,
              submitForm,
              handleSubmit,
              handleChange,
              touched,
              errors,
            }) => (
              <Form>
                <Row className="m-0">
                  <Col sm={isTablet ? 6 : 7} id="leftside">
                    <LeftPart
                      formikValues={formikValues}
                      handleChange={handleChange}
                      setFormikValues={setFormikValues}
                      touched={touched}
                      needOption={needOption}
                      setNeedOption={setNeedOption}
                      errors={errors}
                    />
                  </Col>
                  <Col
                    sm={isTablet ? 6 : 5}
                    id="rightside"
                    className={`blue-part ${needOption ? "need-option" : ""} `}
                  >
                    <RightPart
                      formikValues={formikValues}
                      handleChange={handleChange}
                      setFormikValues={setFormikValues}
                      needOption={needOption}
                      touched={touched}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <Col sm="7" >
                  <Row className="form-group d-block d-md-none next-button-mobile">
                    <Col xs="12">
                      <Button
                        className="form-control hippie-blue-button next-button button_margin_top"
                        type="submit"
                      >
                        <b className="title_next_btn">
                          <IntlMessage id="common.next" />
                        </b>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Index;
