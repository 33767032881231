import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";

import IntlMessage from "@iso/components/intlMessage";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import RequestService from "@iso/services/RequestService";
import { FlagRequestValidator } from "./validator";
import "./styles.scss";
import ProcessRequest from "@iso/utils/ProcessRequest";

const { flagRequest } = RequestService;

function FlagRequestForm(props) {
  const { requestId, handleClose, successCallback } = props;
  let initValue = {
    reason: "",
  };

  const handleSubmit = async (values, bag) => {
    flagRequest(requestId, values).then((res) => {
      ProcessRequest.run({
        res,
        successCallback: () => {
          handleClose({ openConfirm: true });
          if (typeof successCallback === "function") {
            successCallback();
          }
        },
      });
    });
  };

  return (
    <>
      <Formik
        initialValues={initValue}
        enableReinitialize={false}
        onSubmit={handleSubmit}
        validationSchema={FlagRequestValidator}
      >
        {({ handleSubmit, handleChange, touched, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form.Group as={Row}>
              <Col sm={{ span: "8", offset: "2" }}>
                <div className="mt-2 mb-2">
                  {" "}
                  <IntlMessage id="prayer_partners.requests.flag_modal.description" />
                </div>
                <Form.Control
                  name="reason"
                  as="textarea"
                  className="textarea-css"
                  placeholder="Please enter the reasons"
                  onChange={handleChange}
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="reason"
                />
              </Col>
            </Form.Group>
            <div className="buttons text-center mt-4">
              <Button className="hippie-blue-button" type="submit">
                <IntlMessage id="common.send" />
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default FlagRequestForm;
