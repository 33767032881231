import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import "./styles.scss"
import FullLogoImg from "@iso/Pages/Common/FullLogoImg"

class RegistrationBox extends Component {
  render() {
    return (
      <div className="registration-box-container mint-tulip-background">
        <div className="col-10 col-lg-6 inner-box">
          <Row>
            <Col sm={{ span: "10", offset: "1" }}>
              <FullLogoImg />
            </Col>
          </Row>
          <div className="text-center mb-5 mt-4 title">{this.props.title}</div>
          {this.props.form}
        </div>
      </div>
    )
  }
}

export default RegistrationBox
