import React from "react"
import {  Form, Row, Col, Button } from "react-bootstrap"
import { Formik} from 'formik';
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { ForgotPasswordFormValidator } from "./validator"
import { useDispatch } from "react-redux"
import checkemailActions from '@iso/actions/checkemailmodal'
import AuthService from "@iso/services/AuthService"
import { userRoleConstants } from "@iso/constants/user"
import alertActions from "@iso/actions/Alert"
import IntlMessage from "@iso/components/intlMessage";
import TextField from '@material-ui/core/TextField';
import './style.scss'

function AddFormForgotPassword(props) {
  const [initValue, setInitValue] = React.useState({
    email: "",
  });
  
  const { openAlert } = alertActions;
  const dispatch = useDispatch()
  const handleSubmit =  (values, bag) => {
    AuthService.requestResetPasswordApi(values).then((res) => {
      if (!res) {
        bag.setErrors({
          passwordConfirmation: "Somethings went wrong!",
        })
        bag.setSubmitting(false)
      }
      
      const { code, data } = res.data
      // success
      if (code === 200 && data.role === userRoleConstants.userRole) {
        dispatch(
          checkemailActions.openCheckEmailModal({
            type: checkemailActions.SHOW_CHECK_EMAIL_MODAL,
            payload : values.email
          })
        )
        props.setShowSignIn(true)
        
        // error
      } else {
        setInitValue({
          email: ''
        });
        props.setValueEmail(values.email);
        props.setValueForgetPassword(true);
      }
    })
    .catch((res) => {
      bag.setErrors({
        passwordConfirmation: "Somethings went wrong!",
      })
      bag.setSubmitting(false)
    })
  }
  
  return (
    <Formik
      initialValues={initValue}
      enableReinitialize={false}
      onSubmit={handleSubmit}
      validationSchema={ForgotPasswordFormValidator}>
      {({ handleSubmit, handleChange, touched, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Form.Group as={Row}>
            <Col>
              <TextField
                fullWidth={true}
                variant="filled"
                className='my-control'
                name="email"
                type="text"
                value={initValue.email}
                onChange={(e) => {
                  setInitValue({email: e.target.value})
                  setFieldValue('email', e.target.value)
                }}
                label={
                  <IntlMessage id="public.request.form.email.label" />
                }
              />
              
              <ErrorMessageWrapper
                touched={touched}
                errors={errors}
                fieldName="email"
                className ="font-size-12px p-1"/>
            </Col>
          </Form.Group>
          
          <div className="text-center mt-2" >
            <Button className="submit-btn" type="submit">
              <IntlMessage id="forgot_password.send.password.email" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default AddFormForgotPassword