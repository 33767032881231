import React from 'react'
import styled from 'styled-components'
import TalkingGirlImg from '@iso/assets/images/talking_girl.svg'

const TalkingGirl = styled.img`
  margin-top: 48px;
  margin-left: -40px;
  @media only screen and (max-width: 375px){
    height: 200px;
    margin-left: 0;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    height: 220px;
    margin-left: 0px;
    margin-top: 54px;
  }
`

const RightSide = props => {
    return (
        <TalkingGirl src={TalkingGirlImg}></TalkingGirl>
    )
}

export default RightSide