import React from "react";
import IntlMessage from "@iso/components/intlMessage";
import ImgOrthodox from "@iso/assets/images/orthodox.svg";
import ImgAnglianRosary from "@iso/assets/images/anglican_rosary.svg";
import ImgJewishPrayer from "@iso/assets/images/jewish_prayer.svg";
import ImgOtherRosary from "@iso/assets/images/other_rosary.svg";
import ImgCatholic from "@iso/assets/images/catholic.svg";
import ImgCatholicMobile from "@iso/assets/images/ImgCatholicMobile.svg";
import ImgCatholicAuthor from "@iso/assets/images/catholic_author.svg";

import { Row, Col } from "react-bootstrap";
import "./TitleAdapter.scss";
const TitleAdapter = ({ denomination }) => {
  const OrthodoxTitle = () => {
    return (
      <Row
        className={`mb-4 mb-md-0 text-center title_adapter OrthodoxTitle ${denomination}`}
      >
        <img src={ImgOrthodox} className="mx-auto title_image custom_title_image_orthodox" />
      </Row>
    );
  };

  const CatholicTitle = () => {
    return (
      <Row
        className={`text-center title_adapter CatholicTitle ${denomination}`}
      >
        <Col xs="12" className="mb-2 mb-md-0 custom_catholic_title">
          <img src={ImgCatholic} className="mx-auto title_image d-none d-md-block" />
          <img src={ImgCatholicMobile} className="mx-auto title_image d-block d-md-none" />
        </Col>
        <Col xs="12" className="custom_title_catholic_author">
          <img src={ImgCatholicAuthor} className="mx-auto author" />
        </Col>
      </Row>
    );
  };

  const AnglicanTitle = () => {
    return (
      <Row
        className={`text-center title_adapter CatholicTitle ${denomination}`}
      >
        <Col xs="12" className="custom_title_anglican">
          <img src={ImgAnglianRosary} className="mx-auto title_image mb-3 mb-md-0 custom_title_image_anglican" />
          <p className="title_anglican">
            <IntlMessage id="public.request.step2.right.title.anglican" />
          </p>
        </Col>
      </Row>
    );
  };

  const JewishTitle = () => {
    return (
      <Row
        className={`text-center title_adapter CatholicTitle ${denomination}`}
      >
        <Col xs="12" className="custom_title_anglican">
          <img src={ImgJewishPrayer} className="mx-auto title_image mb-3 mb-md-0 custom_title_image_anglican" />
        </Col>
      </Row>
    );
  };

  const OtherTitle = () => {
    return (
      <Row
        className={`text-center title_adapter CatholicTitle ${denomination}`}
      >
        <Col xs="12">
          <img src={ImgOtherRosary} className="mx-auto title_image custom_image_other_title" />
        </Col>
      </Row>
    );
  };

  switch (denomination) {
    case "eastern-orthodox":
      return <OrthodoxTitle />;
    case "catholic":
      return <CatholicTitle />;
    case "anglican":
      return <AnglicanTitle />;
    case "jewish":
      return <JewishTitle />;

    default:
      return <OtherTitle />;
  }
};

export default TitleAdapter;
