import contactActions from '@iso/actions/contactActions'

const initialState = {
  numberOfContact: 0, 
};

export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case contactActions.UPDATE_NUMBER_OF_CONTACT:
      return {
        numberOfContact: action.numberOfContact,
      }
    default:
      return initialState;
  }
}
