import React from "react";
import styled from "styled-components";
import IntlMessage from "@iso/components/intlMessage";
import { isMobile, isTablet } from 'react-device-detect'
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { useHistory } from "react-router";
import requestStepActions from "@iso/actions/RequestStepActions";

const ContributionContainer = styled.div`
	display: inline-block;
	width: calc(100% * (1/2) - 10px - 1px);

	@media only screen and (min-width: 768px) and (max-width: 1024px) {
		
	}

	@media only screen and (min-width: 1025px) {
		width: calc(25% - 19px * (3/4));
	}
`

const ContributionItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	border: 1px solid #DBDEE7;
	border-radius: 6px;
	@media only screen and (min-width: 375px) and (max-width: 767px) {
		min-height: 160px
	}
	min-height: 180px;

	// padding: 26px 10px 10px 10px;
	// cursor: pointer;

	// @media only screen and (min-width: 768px) {
	// 	padding: 26px 10px 10px 10px;
	// }

	// @media only screen and (min-width: 1025px) {
	// 	padding: 19px 10px 10px 10px;
	// }
`

const ItemPrice = styled.div`
	display: flex;
`

const ItemName = styled.div`
	display: flex;
`


const ContributionBox = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const requestStepData = useSelector((state) => state.requestStepReducer);
	const { index, item, denomination } = props;

	const handleClickContribution = (index, item) => {
		dispatch(
			requestStepActions.setDataStep({
				...requestStepData,
				prayer_contribution: item,
				contribution_id: item.id
			})
		)
		history.push('/requests/step1');		
	}
	function replaceWithBr(value) {
		return value.replace("with", "");
	}

	return (
		<ContributionContainer>
			<ContributionItem 
				className={`prayer-option prayer-partner_${denomination ? denomination : 'non-denomination'} prayer-candle-${index + 1}`}
				onClick={() => handleClickContribution(index, item)}
			>
				<ItemPrice>
					<span className="contribution-price">{item.price}&nbsp;<IntlMessage id="common.price.unit.dollar" /></span>
				</ItemPrice>

				<ItemName>
					<span className={`${(isMobile && !isTablet) ? 'contribution-name-mobile' : 'contribution-name'}`}>
						{(item.slug === 'prayer-candle' || item.id === 5) 
							? <>
								Prayer with
								<br />
								{replaceWithBr(item.name.toLowerCase())}
							</>
							: ((item.slug === 'prayer-candle-incense' || item.id === 6)
								? 'Prayer ' + item.name.toLowerCase() 
								: 
									(
										index === 0 
											? (
												isMobile && !isTablet 
													? <><span>{item.name}</span></> 
													: <><span className="contribution-price-first">{item.name}</span></>
											  )
										 : item.name
									)
							  )}
					</span>
				</ItemName>

				<div className="suffix-option"></div>
			</ContributionItem>
		</ContributionContainer>
	)
}


export default ContributionBox;