import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQAccordionStyle from "./AccordionStyle";

const PrivacyFAQ = (props) => {
  const { handleChange, expanded } = props;
  const classes = FAQAccordionStyle();
  return (
    <>
      <Accordion
        className={`${classes.accordion} ${classes.expanded} section-privacy`}
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          className={classes.headingContainer}
          expandIcon={
            <ExpandMoreIcon style={{ fill: "#AFDAF5" }} fontSize="large" />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Privacy</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            What are your Privacy Policies? I am praying for something very private.
          </Typography>
          <Typography className={`${classes.bodyText} `}>
            Your privacy and data security are a priority at PrayerCloud.
            PrayerCloud is committed to best practice security standards. All
            Prayer Partners sign and abide by a strict confidentiality
            agreement. Our prayers are private. Prayer Partners will only
            receive your first name and will not receive access to your contact
            details. Furthermore, PrayerCloud will never share your data with
            third parties.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PrivacyFAQ;
