import React from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);

export default function StripeComponent(props) {
  const { handleStripeError, price } = props;
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm showForm={true} handleStripeError={handleStripeError} price={price} />
    </Elements>
  );
}
