import React from "react";
import Title from "../Title";
import IntlMessage from "@iso/components/intlMessage";
import { Container, Row, Col } from "react-bootstrap";

import { Grid, TextField, Button, CardHeader } from "@material-ui/core";
import useStyles from "./index.style";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PaymentCardItem from "@iso/components/Share/Payment/PaymentCardItem";
import CustomButton from "@iso/components/AtomicComponents/Button";
import "./index.scss";
import SaveStripeCard from "@iso/components/Stripe/SaveCard";
import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import PaymentUtils from "@iso/components/Share/Payment/utils";

const PaymentMethod = (props) => {
  const classes = useStyles();

  const [showForm, setShowForm] = React.useState(false);
  const [paymentCards, setPaymentCards] = React.useState([]);
  const [stripeCustomer, setStripeCustomer] = React.useState();
  const [selectedCard, setSelectedCard] = React.useState({});
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = React.useState(
    false
  );

  const [defaultCard, setDefaultCard] = React.useState({});
  const cardHeaderContent = () => {
    return (
      <div className="cardHeaderContainer">
        <div className="cardHeaderTitle text-center text-sm-left"><IntlMessage id="user.account.settings.payment_method.title" /></div>
        <div className="cardHeaderSubtitle"><IntlMessage id="user.account.settings.payment_method.subtitle" /></div>
      </div>
    )
  }

  React.useEffect(() => {
    PaymentUtils.getStripeCustomerOnMount({
      successCallback: (data) => {
        setStripeCustomer(data);
      },
    });
    PaymentUtils.getListCardOnMount({
      successCallback: (data) => {
        setPaymentCards(data);
      },
    });
  }, []);

  React.useEffect(() => {
    if (paymentCards && paymentCards.length && stripeCustomer) {
      setDefaultCard({
        card_id: paymentCards.find(
          (item) => item?.id === stripeCustomer.default_source
        )?.id,
      });
    }
  }, [paymentCards, stripeCustomer]);

  return (
    <Card className={`${classes.root}`} id="account_page__payment_methods">
      <CardHeader component={cardHeaderContent} />
      <CardContent className={classes.content}>
        <Grid container>
          {paymentCards &&
            paymentCards.length > 0 &&
            paymentCards.map((item) => {
              return (
                <Grid item xs={12} md={5} className="p-0">
                  <PaymentCardItem
                    item={item}
                    defaultCard={defaultCard}
                    setDefaultCard={setDefaultCard}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                    setOpenConfirmDeleteModal={setOpenConfirmDeleteModal}
                  />
                </Grid>
              );
            })}
          <Grid item xs={12}>
            <Grid container spacing={3} >
              <Grid item xs={12} md={6} className="ml-0 pt-3">
                {showForm && (
                  <SaveStripeCard
                    successCallback={() => {
                      PaymentUtils.getStripeCustomerOnMount({
                        successCallback: (data) => {
                          setStripeCustomer(data);
                        },
                      });
                      PaymentUtils.getListCardOnMount({
                        successCallback: (data) => {
                          setPaymentCards(data);
                        },
                      });
                    }}
                    toggleShowForm={() => setShowForm(!showForm)}
                  />
                )}
              </Grid>
            </Grid>

            {!showForm && (
              <CustomButton
                className="button__save ml-0 mr-0"
                variant="primary"
                aria-label="edit account"
                handleClick={() => setShowForm(true)}
              >
                <IntlMessage id="user.account.settings.payment_method.form.add_a_card" />
              </CustomButton>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <ConfirmModal
        open={openConfirmDeleteModal}
        handleConfirm={() =>
          PaymentUtils.handleDetachCard({
            id: selectedCard.id,
            successCallback: (data) => {
              setPaymentCards(data);
            },
            closeModalCallback: () => {
              setOpenConfirmDeleteModal(false);
            },
          })
        }
        handleClose={() => setOpenConfirmDeleteModal(false)}
        title={<IntlMessage id="confirm_modal.delete_cards.title" />}
        content={<IntlMessage id="confirm_modal.delete_cards.content" />}
        okText={<IntlMessage id="common.yes" />}
        cancelText={<IntlMessage id="common.no" />}
      />
    </Card>
  );
};

export default PaymentMethod;
