import React, { Component, useState } from "react"
import IntlMessage from "@iso/components/intlMessage"
import FormContactUs from "./FormContactUs"
import { Row, Col, Button } from "react-bootstrap"
import FormThankContactUs from "./FormThankContactUs"
import SignInModal from "@iso/Website/Pages/SignIn/SignInModal";
import RegisterModal from "@iso/Website/Pages/ReceiveConfirm/ReceiveYourConfirmModal";
import { Link } from "react-router-dom"
import "./styles.scss"

const ContactUs = (props) => {
  const { setflagSendContact } = props
  return (
    <div className="contactUs-container">
      <h2 className="contact-header">
        <IntlMessage id="contactUs.header" />
      </h2>

      <p className="contactUs-title d-none d-md-block">
        <span className="d-inline d-md-block">
          <IntlMessage id="contactUs.title1" />
        </span>
        <span>
          <IntlMessage id="contactUs.title2" />
          <a className="ml-1 hippie-blue-color" href="/faq">
            <IntlMessage id="contactUs.title3" />
          </a>
          <span className="ml-1">
            <IntlMessage id="contactUs.title4" />
          </span>
        </span>
      </p>

      <p className="contactUs-title d-block d-md-none">
        <span className="d-block"><IntlMessage id="contactUs.mobile.title1"/></span>
        <span className="d-block"><IntlMessage id="contactUs.mobile.title2"/></span>
        <span className="d-block">
          <IntlMessage id="contactUs.mobile.title3"/>
          <Link to='/faq' className="hippie-blue-color"><IntlMessage id="contactUs.mobile.title4"/></Link>
          <IntlMessage id="contactUs.mobile.title5"/>
        </span>
        <span className="d-block"><IntlMessage id="contactUs.mobile.title6"/></span>
      </p>

      <div className="contactus-form">
        <FormContactUs setflagSendContact={setflagSendContact} />
      </div>
      <SignInModal />
      <RegisterModal></RegisterModal>
    </div>
  )
}

export default ContactUs
