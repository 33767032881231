import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Modal } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import "./styles.scss";

class ModalCropImage extends PureComponent {
  state = {
    src: null,
    crop: {
      unit: "px",
      width: 120,
      x: 25,
      y: 25,
      aspect: 16 / 16
    },
    croppedImageUrl: "",
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);

        resolve(this.fileUrl);

        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.dataURLtoFile(reader.result, fileName);
        };
      }, "image/jpeg");
    });
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }

  render() {
    const { crop, croppedImage } = this.state;
    const { source, onShow, onHide, saveChangeImg } = this.props;

    return (
      <Modal
        show={onShow}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-crop"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          <IntlMessage id="prayer_partners.account_settings.profile.crop_image.title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactCrop
            src={source}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}> <IntlMessage id="prayer_partners.account_settings.profile.btn.close" /></Button>
          <Button onClick={() => saveChangeImg(croppedImage)}>
            <IntlMessage id="prayer_partners.account_settings.profile.btn.save" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalCropImage;
