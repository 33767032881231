import React from "react";
import Profile from "./Profile";
import Box from "@material-ui/core/Box";

import PropTypes from "prop-types";
import ChangePassword from "./Settings/ChangePassword";
import DeactivateAccount from "./Settings/DeactivateAccount";
import PaymentMethod from "./PaymentMethod";
import useStyles from './index.style'
import { TAB_MENU } from "@iso/Website/Pages/Account/constants";
import MyPrayers from "./MyPrayers";

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.box}>
          {children}
        </Box>
      )}
    </div>
  );
}

const RightSide = (props) => {
  const { value } = props;
  return (
    <>
      <TabPanel value={value} index={TAB_MENU.profile}>
        <Profile />
      </TabPanel>
      <TabPanel value={value} index={TAB_MENU.billing_history}>
        <Profile />
      </TabPanel>
      <TabPanel value={value} index={TAB_MENU.payment_methods}>
        <PaymentMethod />
      </TabPanel>
      <TabPanel value={value} index={TAB_MENU.my_prayers}>
        <MyPrayers></MyPrayers>
      </TabPanel>
      <TabPanel value={value} index={TAB_MENU.change_password}>
        <ChangePassword />
      </TabPanel>
      <TabPanel value={value} index={TAB_MENU.deactivate_account}>
      <DeactivateAccount />
      </TabPanel>
      <TabPanel value={value} index={TAB_MENU.logout}>
        <Profile />
      </TabPanel>
    </>
  );
};

export default RightSide;
