import actionsSignIn from '@iso/actions/signinmodal'

const initialState = {
  isOpen : false,
  isForgotPassword : false,
  title: 'user.confirm.form.receive',
};

export function signinmodal(state = initialState, action) {
  switch (action.type) {
    case actionsSignIn.SHOW_MODAL:
      return {
        isOpen : true,
        isForgotPassword : action.data.isForgotPassword || false,
        title: action.data.title || state.title,
        backDropClassName: action.data.backDropClassName
      }
    case actionsSignIn.HIDE_MODAL:
      return {
        isOpen: false,
        isForgotPassword : action.data.isForgotPassword || false,
      };
    default:
      return initialState;
  }
}
