import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Collapse,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import { Formik } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { localTimezone } from "@iso/utils/GetLocalData";

import IntlMessage from "@iso/components/intlMessage";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { USER_PERMISSIONS } from "@iso/constants";

import {
  initialValues,
  itemLeavePeriodsDefault,
  settings,
  timezones,
  DEFAULT_TIMEZONE,
} from "./constants";

import LeavePeriods from "@iso/components/PrayerPartner/Availability/LeavePeriods";
import "./styles.scss";

import PrayerPartnerService from "@iso/services/PrayerPartnerService";
import alertActions from "@iso/actions/Alert";
import { userRoleConstants } from "@iso/constants";

const { openAlert } = alertActions;

const AvailabilityCard = ({ profileId, role, handleUpdateProfile }) => {
  const { profile } = useSelector(
    (state) => state.prayerPartnerReducer,
    shallowEqual
  );
  const user = useSelector((state) => state.authentication.user);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  const [availabilites, setAvailabilities] = useState(null);
  const [leavePeriods, setLeavePeriods] = useState(itemLeavePeriodsDefault);
  const [statusInactive, setStatusInactive] = React.useState(false);
  const [statusAssign, setStatusAssign] = React.useState(profile.status_assign);
  const [isPriority, setIsPriority] = React.useState(parseInt(profile.is_priority));

  const isAdmin = USER_PERMISSIONS.SUPER_USER.roles.includes(user?.role);

  const dispatch = useDispatch();
 
  const handleValueChange = (name, value) => {

    if(statusInactive === false) {
      setStatusAssign(0)
    } else {
      setStatusAssign(1)
    }
    setStatusInactive(!statusInactive)
  }

  const handleChangePriority = (value) => {
    setIsPriority(!isPriority)
  }

  const getDataAvailabilities = ({ id }) => {
    PrayerPartnerService.adminGetAvailabilitiesById({ id }).then((res) => {
      if (res && res.data) {
        const { data } = res.data;
        if (data) {
          setAvailabilities(data);
        }
        if (data?.day_off && data.day_off.length > 0) {
          setLeavePeriods([
            ...data.day_off.filter((item) => item.start_date && item.end_date),
            ...itemLeavePeriodsDefault,
          ]);
        }

        if (data?.timezone) {
          const tmpTimezone = timezones.filter(
            (item) => item.code === data.timezone
          );
          if (tmpTimezone.length > 0) {
            setTimezone(tmpTimezone[0]);
          }
        } else {
          setTimezone(localTimezone);
        }
      }
    });
  };

  const handleSubmit = (values) => {
    try {
      handleUpdateProfile({
        ...profile,
        status_assign: statusAssign,
        is_priority: isPriority,
      }).then((res) => {
        const {code, data, errors } = res.data;
        if (code === 200 && data) {
          setStatusAssign(data.status)
          setIsPriority(data.is_priority)
          setIsEdit(false);
          handleSubmitProfile(values)
        } else {
          if (typeof errors === 'object') {
            if (Object.keys(errors).length > 0) {
              dispatch(
                openAlert({
                  message: errors[Object.keys(errors)[0]],
                  severity: "error",
                })
              );
            }
          }
          else {
            dispatch(
              openAlert({
                message: errors,
                severity: "error",
              })
            );
          }
        }
      })
    } catch (err) {
      dispatch(
        openAlert({
          message: <IntlMessage id="common.message.update.fail" />,
          severity: "error",
        })
      );
    }
  };

  const handleSubmitProfile = (values) => {
    try {
      if (!values.timezone) values.timezone = localTimezone().code;
      PrayerPartnerService.adminUpdateAvailabilities({
        ...values,
        id: profileId,
      }).then((res) => {
        const { code, data } = res.data;
        if (code === 200 && data) {
          if (data?.day_off && data.day_off.length > 0) {
            setLeavePeriods([
              ...data.day_off.filter(
                (item) => item.start_date && item.end_date
              ),
              ...itemLeavePeriodsDefault,
            ]);
          }
          window.location.reload();

          dispatch(
            openAlert({
              message: <IntlMessage id="common.message.update.success" />,
              severity: "success",
            })
          );
          setIsEdit(false);
        } else {
          dispatch(
            openAlert({
              message: <IntlMessage id="common.message.update.fail" />,
              severity: "error",
            })
          );
        }
      });
    } catch (err) {
      dispatch(
        openAlert({
          message: <IntlMessage id="common.message.update.fail" />,
          severity: "error",
        })
      );
    }
  } 

  React.useEffect(() => {
    if (profileId) {
      getDataAvailabilities({ id: profileId });
    }
  }, [profileId]);

  React.useEffect(() => {

    if (profile) {
      if (profile.status_assign === 1){
        setStatusInactive(false)
      }else{
        setStatusInactive(true)
      }
    }

  }, [profile]);

  const { time, day } = settings;
  
  return (
    <div className="p-md-3 availability common">
      <Card.Title className="title p-0 m-0 px-3">
        <span>
          <IntlMessage id="prayer_partners.account_settings.availability.title" />
        </span>
        {
          (role === userRoleConstants.SUPER_ADMIN_ROLE && !profile?.deleted_at) &&
          <Button
          className="float-right hover_pointer btn hippie-blue-link edit_btn btn-primary"
          onClick={() => setIsEdit(!isEdit)}
          >
            <EditIcon className="pr-1 pb-1" />
            <IntlMessage id="prayer_partners.account_settings.profile.btn.edit" />
          </Button>
        }
        
      </Card.Title>
      <Collapse in={true}>
        <Card.Body className="p-md-3 p-0">
          <h5>
            <IntlMessage id="prayer_partners.account_settings.availability.subtitle" />
          </h5>
          <Formik
            initialValues={availabilites || initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setValues,
              setFieldValue,
            }) => {
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group as={Row} className="mb-3 justify-content-md-end">
                    <Col sm="3"></Col>
                    <Col sm="9">
                      <Form.Group as={Row} className="mb-3 day-header px-0">
                        {Object.entries(day).map(([key, value]) => (
                          <div className="day-title" key={key}>
                            <span>{value}</span>
                          </div>
                        ))}
                      </Form.Group>
                    </Col>
                  </Form.Group>

                  {Object.entries(time).map(
                    ([keyTime, timeValue], indexTime) => (
                      <Form.Group
                        as={Row}
                        className="mb-3 day-body-container"
                        key={indexTime}
                      >
                        <Col sm="3">
                          <p>{timeValue}</p>
                        </Col>

                        <Col sm="9">
                          <Form.Group as={Row} className="mb-3 day-body">
                            {Object.entries(day).map(([key, value], index) => (
                              <div className="day-title" key={index}>
                                <Checkbox
                                  disabled={!isEdit}
                                  color="default"
                                  inputProps={{ "aria-label": "checked" }}
                                  name={`time_weekly.${keyTime}[${index}]`}
                                  checked={
                                    values?.time_weekly &&
                                    values?.time_weekly[keyTime][index]
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            ))}
                          </Form.Group>
                        </Col>
                      </Form.Group>
                    )
                  )}

                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-md-end day-body-container"
                  >
                    <Col sm="3">
                      <p>
                        <IntlMessage id="prayer_partners.account_settings.availability.timezone" />
                      </p>
                    </Col>

                    <Col sm="9">
                      <Autocomplete
                        disabled={!isEdit}
                        id="timezone"
                        className="timezone-select"
                        options={timezones}
                        getOptionLabel={(option) => `${option.label}`}
                        defaultValue={DEFAULT_TIMEZONE || timezone}
                        value={timezone}
                        onChange={(e, value) => {
                          if (value) {
                            setFieldValue("timezone", value.code);
                            setTimezone(value);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="timezone"
                            variant="outlined"
                            placeholder="Timezone"
                            fullWidth={true}
                          />
                        )}
                      />
                    </Col>
                  </Form.Group>

                  <LeavePeriods
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    setValues={setValues}
                    leavePeriods={leavePeriods}
                    setLeavePeriods={setLeavePeriods}
                    isEdit={isEdit}
                    title="prayer_partners.account_settings.availability.leave_periods_admin.title"
                    subtitle="prayer_partners.account_settings.availability.leave_periods_admin.subtitle"
                  />

                  {
                    isAdmin && (
                      <Form.Group className={'checkbox-inactive-pp'} controlId="inactive">
                        <Checkbox
                          checked={statusInactive}
                          name="statusAssign"
                          value={statusAssign}
                          onChange={(e) => {
                            handleValueChange("statusAssign", e.target.value)
                          }}
                          disabled={!isEdit}
                        />
                        <p>
                          <IntlMessage id="prayer_partners.account_settings.manage_notification.status.inactive" />
                        </p>
                      </Form.Group>
                    )
                  }

                  {
                    isAdmin && (
                      <Form.Group className={'checkbox-inactive-pp'} controlId="priority">
                        <Checkbox
                          checked={isPriority}
                          name="isPriority"
                          value={isPriority}
                          onChange={(e) => {
                            handleChangePriority(e.target.value)
                          }}
                          disabled={!isEdit}
                        />
                        <p>
                          <IntlMessage id="prayer_partners.account_settings.manage_notification.priority" />
                        </p>
                      </Form.Group>
                    )
                  }

                  {isEdit && (
                    <Row className="justify-content-center card_info_control m-0">
                      <Button
                        className="card_info_btn_cancel btn_control mx-1 mb-1"
                        onClick={() => {
                          setIsEdit(false)
                          setStatusInactive(!statusInactive)
                        }}
                      >
                        <IntlMessage id="prayer_partners.account_settings.profile.btn.cancel" />
                      </Button>
                      <Button
                        type="submit"
                        className="btn_control hippie-blue-button mx-1 mb-1"
                        disabled={isLoading}
                      >
                        <IntlMessage id="prayer_partners.account_settings.profile.btn.save" />
                        {isLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </Row>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Collapse>
    </div>
  );
};

export default AvailabilityCard;
