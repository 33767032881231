import React from "react"
import {isMobile, isTablet} from "react-device-detect"
import "./style.scss"

const PrayerTypes = (props) => {
  const { setDeno, deno} = props;
  let types = [
    {id : "catholic", label :"Catholic"},
    {id : "protestant", label :"Protestant"},
    {id : "anglican", label :"Anglican"},
    {id : "eastern-orthodox", label :"Eastern Orthodox"},
    {id : "christian", label :"Christian - Other"},
    {id : "evangelical", label :"Evangelical"},
    {id : "other", label :"Other"},
    {id : "non-denomination", label :"Non-Denominational"},
  ];
  if(isMobile && !isTablet){
    types = [
      {id : "catholic", label :"Catholic"},
      {id : "anglican", label :"Anglican"},
      {id : "jewish", label :"Jewish"},
      {id : "protestant", label :"Protestant"},
      {id : "eastern-orthodox", label :"Eastern Orthodox"},
      {id : "christian", label :"Christian - Other"},
      {id : "evangelical", label :"Evangelical"},
      {id : "non-denomination", label :"Non-Denominational"},
      {id : "other", label :"Other"},
    ];
  }else{
    types = [
      {id : "catholic", label :"Catholic"},
      {id : "anglican", label :"Anglican"},
      {id : "protestant", label :"Protestant"},
      {id : "evangelical", label :"Evangelical"},
      {id : "eastern-orthodox", label :"Eastern Orthodox"},
      {id : "christian", label :"Christian - Other"},
      {id : "jewish", label :"Jewish"},
      {id : "non-denomination", label :"Non-Denominational"},
      {id : "other", label :"Other"},
    ];
  }

  return(
    <>
     {types.map((type) => {
        return(
          <div key={type.id}  className={`pray-item-prayercloud ${deno == type.id ? 'active' : ''} `}
          onClick = { () => setDeno(type.id)} >
            {type.label}
          </div>
        )
      })}
    </>
  )
}

export default PrayerTypes;
