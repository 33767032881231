import React, { Component,useState } from "react"
import { Modal, Button } from "react-bootstrap"
import {useSelector, useDispatch} from 'react-redux';
import checkemailmodal from '@iso/actions/checkemailmodal'
import actionsSignIn from '@iso/actions/signinmodal'
import IntlMessage from "@iso/components/intlMessage"
import { Link } from "react-router-dom";
import './style.scss'
import AuthService from "@iso/services/AuthService"
const {closeCheckEmailModal} = checkemailmodal;

function Checkyouremailmodal(props){
  const dispatch = useDispatch();
  const showModal = useSelector((state) =>  state.checkemailmodal);

  const handleClose = () => {
    dispatch(closeCheckEmailModal());
  }

  const handleTryAgain = () => {
    setTimeout(() => {
      dispatch(actionsSignIn.openModal({
        type: actionsSignIn.SHOW_MODAL,
        isForgotPassword: true,
      }));
      
    },500)
  }

  const openForgotPassword = () => {
    setTimeout(() => {
      dispatch(actionsSignIn.openModal({
        type: actionsSignIn.SHOW_MODAL,
        isForgotPassword : true,
      }));
    }, 500);
  }
  return(
    <Modal
    show={showModal.isShowModal}
    onHide={handleClose}>
      <div className = "checkemailmodalContainer">
        <Modal.Header id = "modalCheckEmailHeader" closeButton>
        <Modal.Title className ="modalCheckEmailTitle custom_padding_content_check_email">

        <h2 className = "font-weight-bold mb-3 custom_title_check_email">
          <IntlMessage id="forgot_password.check.email" />
        </h2>
        <p className = "fontStyle">
           <IntlMessage id="forgot_password.instructions.email" />
           <span> {showModal.data.payload} </span>
        </p>

        <div className="text-center mt-2 d-block d-sm-none" >
        <Button className="submit-btn my-3 py-2" type="submit" >
          <IntlMessage id="forgot_password.email.app" />
        </Button>
        </div>
        </Modal.Title>
        </Modal.Header>

        <Modal.Body className ="modalCheckEmailTitle">
        <p className = "fontStyle">
          <IntlMessage id="forgot_password.check.receive.email"/>
          <span className = "color-hippie-blue ml-1"
          onClick = {openForgotPassword}>
          <Link>
            <IntlMessage id="forgot_password.try.another.email" />
          </Link>
          </span>
        </p>
        <p className = "fontStyle">
          <IntlMessage id="forgot_password.not.email"/>
          <span className = "color-hippie-blue ml-1"
          onClick = {handleTryAgain}>
          <Link>
            <IntlMessage id="forgot_password.try.again" />
          </Link>
          </span>
        </p>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export default Checkyouremailmodal
