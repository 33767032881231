import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import { setCurrentUser } from "@iso/actions/User"
import { connect } from "react-redux"
import AddForm from "./AddForm"
import IntlMessage from "@iso/components/intlMessage"

class CAddModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: props.showModal,
    }
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({ showModal: this.props.showModal })
    }
  }

  handleClose() {
    this.props.closeModal()
  }

  render() {
    return (
      <div>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <IntlMessage id="admin.prayer_partner.add" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddForm handleClose={this.handleClose} />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStoreToProps = (store) => ({})

const mapDispatchToProps = {
  setCurrentUser: setCurrentUser,
}

const AddModal = connect(mapStoreToProps, mapDispatchToProps)(CAddModal)

export default AddModal
