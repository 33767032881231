import $ from "jquery";

var minDate = null;
var minMonth;
var minYear;
var maxDate = null;
var maxMonth;
var maxYear;

function isLastDay() {
  var test = new Date(),
    month = test.getMonth();
  
  test.setDate(test.getDate() + 2);
  return test.getMonth() !== month;
}

export function showPickerCustom(min = null, max = null, showTooltip = false) {
  document.querySelector('.custom-options-datepicker').click();
  setTimeout(() => {
    if (!$('.datepicker-custom input').val() && isLastDay()) {
      $('.calendarContainer .heading .prev').click();
    }
  }, 100)
  
  handleClickMonth();
  if (min) {
    minDate = new Date(min);
    minMonth = minDate.getMonth() + 1;
    minYear = minDate.getFullYear();
    if (showTooltip) {
      //Tooltip Calendar
    //   let date = minDate.getDate();
    //   setTimeout(() => {
    //     $(`.dayWrapper`).eq(date).addClass('tooltip-datepicker');
    //     $('.today button').attr('disabled', true);
    //     $('.today').append(`<span class="tooltiptext">Please allow us up to 24 hours to complete your prayer.</span>`);
    //     let index = $('.dayWrapper').index($('.today')) % 7;
    //     let left = (39 * index) - 30;
    //     $('.today span.tooltiptext').css('left', `-${left}px`);
    //   }, 100)
    }
  }
  if (max) {
    maxDate = new Date(max);
    maxMonth = maxDate.getMonth() + 1;
    maxYear = maxDate.getFullYear();
  }
}


var btnMonthClass = '.monthsList button';

function handleClickMonth() {
  setTimeout(function () {
    $('.calendarContainer .heading button.title').on('click', function () {
      disabledMonth();
      setTimeout(function () {
        $('button.prev').on('click', function () {
          disabledMonth();
        })
        $('button.next').on('click', function () {
          disabledMonth();
        })

      }, 200);
    });
  }, 300);
}

function disabledMonth() {
  setTimeout(function () {
    let selectedYear = parseInt($('.month-selector .heading button.title').html());
    if (minDate) {
      disabledWithMin(selectedYear);
      $(btnMonthClass).on('click', function () {
        if (!$(this).attr('disabled')) {
          handleClickMonth();
        }
      })
    }
    if (maxDate) {
      disabledWithMax(selectedYear);
      $(btnMonthClass).on('click', function () {
        if (!$(this).attr('disabled')) {
          handleClickMonth();
        }
      })
    }
  }, 200);
}

function disabledWithMin(selectedYear) {
  if (minYear > selectedYear) {
    $(btnMonthClass).attr('disabled', true)
  } else if (minYear === selectedYear) {
    $(btnMonthClass).each(function (index, e) {
      let selectedMonth = index + 1;
      let isDisabled = minMonth > selectedMonth;
      $(e).attr('disabled', isDisabled)
    })
  } else {
    $(btnMonthClass).attr('disabled', false)
  }
}

function disabledWithMax(selectedYear) {
  if (maxYear < selectedYear) {
    $(btnMonthClass).attr('disabled', true)
  } else if (maxYear === selectedYear) {
    $(btnMonthClass).each(function (index, e) {
      let selectedMonth = index + 1;
      let isDisabled = maxMonth < selectedMonth;
      $(e).attr('disabled', isDisabled)
    })
  } else {
    $(btnMonthClass).attr('disabled', false)
  }
}
