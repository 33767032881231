import SignIn from "@iso/Admin/Pages/SignIn/SignIn"
import ForgotPassword from "@iso/Admin/Pages/ForgotPassword/ForgotPassword"
import ResetPassword from "@iso/Admin/Pages/ResetPassword/ResetPassword" 
import CreateAdminPassword from "@iso/components/Admins/Profile/CreateAdminPassword"
import AccessDenied from "@iso/Pages/Common/access_denied"

export default {
  SignIn: {
    component: SignIn,
    path: "/admin/sign_in",
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/admin/forgot_password",
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/admin/reset_password",
  },
  CreateAdminPassword: {
    component: CreateAdminPassword,
    path: '/admin/sign_up'
  },
  AccessDenied: {
    component: AccessDenied,
    path: "/access_denied",
  },  
}
