import { Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import IntlMessage from "@iso/components/intlMessage";
import { Formik, Form } from "formik";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { ProfileInfoValidator } from "./validator";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import {
  ArrowDownward,
  KeyboardArrowDown,
  LocalGasStation,
} from "@material-ui/icons";
import { religions } from "@iso/Pages/Common/Religions";
import Select from "@material-ui/core/Select";
import { getNames } from "country-list";
import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { useDispatch } from "react-redux";
import { setCurrentProfile } from "@iso/actions/User";
import alertActions from "@iso/actions/Alert";
import { translate } from "@iso/utils/Translate";
import './ProfileInfo.scss'
import { SORTED_COUNTRIES_LIST_BY_NAME, HIGH_PRIORITY_COUNTRIES } from '../../../constants'
import DatepickerCustom from "../../../../../../components/Datepicker/DatepickerCustom";
import {showPickerCustom} from "../../../../../../utils/DatePickerCustomAction";
import ViewItem from "./ViewItem";
import { Row } from 'react-bootstrap'
const countryListByName = getNames();

const ProfileInfo = (props) => {
  const {
    edit,
    user,
    formRef,
    handleToggleEdit,
    setProfile,
    setLoadingProfile,
  } = props;
  const dispatch = useDispatch();
  const religionsWithOther = [...religions,     { id: 'other', label: 'Other'},]
  const initValue = {
    first_name: user.first_name || "",
    last_name: user.last_name || "",
    email: user.email || "",
    birth: user.birth || "",
    birth_year: moment(user.birth).format('yyyy') || "",
    religion: user.religion ? user.religion[0] : [],
    country: user.country || "",
    phone_number: user.phone_number || "",
    ...user,
  };
  const [formikValues, setFormikValues] = React.useState(initValue);
  const [openPicker, setOpenPicker] = React.useState(false);
  const inputEditClassname = edit ? "edit" : "view";
  return (
    <Formik
      id="formikProfile"
      innerRef={formRef}
      initialValues={formikValues}
      enableReinitialize={true}
      onSubmit={(values,actions) => {
        if (!edit) {
          return;
        }

        setLoadingProfile(true);
          UserService.updateProfile(values).then((res) => {
            ProcessRequest.run({
              res,
              notiVariant: "new",
              hasNoti: true,
              i18nSuccess: "Your profile has been updated",
              successCallback: (data) => {
                handleToggleEdit();
                dispatch(setCurrentProfile(data.data));
                setProfile(data.data);
                setLoadingProfile(false);
              },
              errorCallback: () => {
                setLoadingProfile(false);
              },
            });
          });
      }}
      validationSchema={ProfileInfoValidator}
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        errors,
        values,
        setValues,
        submitForm,
        resetForm,
        setErrors,
      }) => {
        return (
          <Form className="form" className="profile_info__form">
            <Grid container spacing={3}>
              
              {edit ? <Grid item xs={6}>
                <TextField
                  className={`${inputEditClassname} my-control safari-input-disabled`}
                  disabled={!edit}
                  fullWidth={true}
                  variant="filled"
                  name="first_name"
                  value={values.first_name}
                  type="text"
                  onChange={handleChange}
                  label={
                    <IntlMessage id="public.request.form.firstName.label" />
                  }
                />
                {errors.first_name && (
                  <ErrorMessageWrapper
                    touched={touched}
                    errors={errors}
                    shouldDisplayErrorDiv={false}
                    fieldName="first_name"
                  />
                )}
              </Grid>
                :
                <ViewItem
                  cols={6}
                  value={values.first_name}
                  label={<IntlMessage id="public.request.form.firstName.label" />}
                />
              }
              
              {
                edit ?
              <Grid item xs={6}>
                <TextField
                  disabled={!edit}
                  fullWidth={true}
                  variant="filled"
                  className={`${inputEditClassname} my-control safari-input-disabled`}
                  name="last_name"
                  value={values.last_name}
                  type="text"
                  onChange={handleChange}
                  label={
                    <IntlMessage id="public.request.form.lastName.label" />
                  }
                />
                {errors.last_name && (
                  <ErrorMessageWrapper
                    touched={touched}
                    errors={errors}
                    shouldDisplayErrorDiv={false}
                    fieldName="last_name"
                  />
                )}
              </Grid>
                :
                <ViewItem
                  cols={6}
                value={values.last_name}
                label={<IntlMessage id="public.request.form.lastName.label" />}
                />
              }

              {
                edit ?
              <Grid item xs={12}>
                <TextField
                  className={`${inputEditClassname} my-control safari-input-disabled`}
                  disabled={!edit}
                  fullWidth={true}
                  variant="filled"
                  name="email"
                  value={values.email}
                  type="text"
                  onChange={handleChange}
                  label={<IntlMessage id="public.request.form.email.label" />}
                />
                {errors.email && (
                  <ErrorMessageWrapper
                    touched={touched}
                    errors={errors}
                    shouldDisplayErrorDiv={false}
                    fieldName="email"
                  />
                )}
              </Grid>
                  :
                  <ViewItem
                    cols={6}
                    value={values.email}
                    label={<IntlMessage id="public.request.form.email.label" />}
                  />
              }
              
              {
                edit ?
              <Grid item xs={6} className="position-relative">
                <TextField
                  disabled={!edit}
                  inputProps={{ readOnly: true }}
                  fullWidth={true}
                  onClick={() => {{edit && showPickerCustom(null, moment())}}}
                  variant="filled"
                  className={`${inputEditClassname} my-control my-picker safari-input-disabled`}
                  value={values?.birth ? moment(values?.birth).format('MM/DD') : ''}
                  type="text"
                  label={
                    <IntlMessage id="public.request.form.dateOfBirth.label" />
                  }
                />
                <DatepickerCustom
                  max={moment()}
                  isOpen={openPicker}
                  className={`${inputEditClassname} my-picker d-none`}
                  value={values?.birth ? moment(values?.birth) : null}
                  format="DD/MM"
                  InputAdornmentProps={{ position: "end" }}
                  keyboardIcon={<KeyboardArrowDown />}
                  onChange={(date) => {
                    let formattedDate = null;
                    if (date) formattedDate = moment(date).format("MM-DD");
                    setValues({
                      ...values,
                      birth: formattedDate,
                    });
                    setOpenPicker(false)
                  }}
                />
                {errors.birth && (
                  <ErrorMessageWrapper
                    touched={touched}
                    shouldDisplayErrorDiv={false}
                    errors={errors}
                    fieldName="birth"
                  />
                )}
              </Grid>
                  :
                  <ViewItem
                    cols={12}
                    value={values?.birth ? moment(values?.birth).format("DD/MM/YYYY") : ''}
                    label={<IntlMessage id="public.request.form.dateOfBirth.label" />}
                  />
              }

              {
                edit ?
                  <Grid item xs={6} className="position-relative">
                    <TextField
                      disabled={!edit}
                      fullWidth={true}
                      variant="filled"
                      name="birth_year"
                      className={`${inputEditClassname} my-control`}
                      value={values?.birth_year ?? ''}
                      type="number"
                      onChange={handleChange}
                      label={
                        <IntlMessage id="public.request.form.year.label" />
                      }
                    />
                    {errors.birth && (
                      <ErrorMessageWrapper
                        touched={touched}
                        shouldDisplayErrorDiv={false}
                        errors={errors}
                        fieldName="birth_year"
                      />
                    )}
                  </Grid>
                  :
                  ''
              }

              
              <Grid item xs={12} className="position-relative">
                <label
                  className="custom-label MuiFormLabel-root MuiInputLabel-root
                            MuiInputLabel-formControl MuiInputLabel-animated account-form-label
                            MuiInputLabel-shrink MuiInputLabel-filled MuiFormLabel-filled"
                  data-shrink="true"
                >
                  Religious identification
                </label>
                <Select
                  fullWidth={true}
                  disabled={!edit}
                  className={`${inputEditClassname} my-control2 safari-input-disabled`}
                  IconComponent={KeyboardArrowDown}
                  value={values?.religion || ""}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    className: "new-design",
                  }}
                  onChange={(event) =>
                    setValues({
                      ...values,
                      religion: [event.target.value],
                    })
                  }
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                    variant: "filled",
                  }}
                >
                  <MenuItem value="" disabled>
                    <IntlMessage id="public.request.right.form.religious_none_selected" />
                  </MenuItem>
                  {religionsWithOther.map((religion) => (
                    <MenuItem key={religion.id} value={religion.id}>
                      {religion.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.religion && (
                  <ErrorMessageWrapper
                    touched={touched}
                    errors={errors}
                    shouldDisplayErrorDiv={false}
                    fieldName="religion"
                  />
                )}
              </Grid>
              <Grid item xs={12} className="position-relative">
                <label
                  className="custom-label MuiFormLabel-root MuiInputLabel-root account-form-label
                            MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink
                            MuiInputLabel-filled MuiFormLabel-filled"
                  data-shrink="true"
                >
                  Country
                </label>
                <Select
                  fullWidth={true}
                  disabled={!edit}
                  className={`${inputEditClassname} my-control2 safari-input-disabled`}
                  IconComponent={KeyboardArrowDown}
                  value={values?.country || ""}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    className: "new-design",
                  }}
                  onChange={(event) =>
                    setValues({
                      ...values,
                      country: event.target.value,
                    })
                  }
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                    variant: "filled",
                  }}
                >
                  <MenuItem value="" disabled>
                    <IntlMessage id="public.request.form.country_none_selected" />
                  </MenuItem>
                  {[...HIGH_PRIORITY_COUNTRIES, ...SORTED_COUNTRIES_LIST_BY_NAME()].map((country, idx) => (
                    <MenuItem key={idx} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.country && (
                  <ErrorMessageWrapper
                    touched={touched}
                    shouldDisplayErrorDiv={false}
                    errors={errors}
                    fieldName="country"
                  />
                )}
              </Grid>
              {
                edit ?
              <Grid item xs={12}>
                <TextField
                  className={`${inputEditClassname} my-control safari-input-disabled`}
                  disabled={!edit}
                  fullWidth={true}
                  variant="filled"
                  name="phone_number"
                  value={values.phone_number !== null ? values.phone_number : translate("public.request.form.profile.phone_number.label")}
                  type="text"
                  onChange={handleChange}
                  label={
                    <IntlMessage id="public.request.form.phoneNumber.label" />
                  }
                  onClick={() => {
                    if(values.phone_number === null && edit){
                      setValues({
                        ...values,
                        phone_number: '',
                      })
                    }
                  }}
                />
                {errors.phone_number && (
                  <ErrorMessageWrapper
                    touched={touched}
                    errors={errors}
                    shouldDisplayErrorDiv={false}
                    fieldName="phone_number"
                  />
                )}
              </Grid>
                  :
                  <ViewItem
                    cols={12}
                    value={values.phone_number !== null ? values.phone_number : translate("public.request.form.profile.phone_number.label")}
                    label={<IntlMessage id="public.request.form.phoneNumber.label" />}
                  />
              }
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfileInfo;
