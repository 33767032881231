import React from "react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Grid, TextField, Button } from "@material-ui/core";
import IntlMessage from "@iso/components/intlMessage";
import "./SaveCardForm.scss";
import { Form } from "react-bootstrap";

import CustomButton from "@iso/components/AtomicComponents/Button";
import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import alertActions from "@iso/actions/Alert";
import { useDispatch } from "react-redux";

const SaveCardForm = (props) => {
  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "18px",
        color: "#000000",

        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#000000",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
    classes: {
      focus: "focused",
      empty: "empty",
      invalid: "invalid",
    },
  };

  const ELEMENT_CARD_NUMBER_OPTIONS = {
    placeholder: 'Card number',
    style: {
      base: {
        fontSize: "18px",
        color: "#000000",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#000000",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
    classes: {
      focus: "focused",
      empty: "empty",
      invalid: "invalid",
    },
  };

  const { toggleShowForm, successCallback } = props;

  const elements = useElements();
  const stripe = useStripe();
  const [name, setName] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [isDefaultCard, setDefaultCard] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [paymentMethod, setPaymentMethod] = React.useState(null);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const { openAlert } = alertActions;
  const [loading, setLoading] =React.useState(true)
  const dispatch = useDispatch();

  const handleValidate = () => {
    let error = ''
    if (name.length < 1) {
      error = 'Name is a required field.'
    }

    if (country.length < 1) {
      error = 'Country is a required field.'
    }

    setErrorMessage(error)
    return error
  }

  React.useEffect(() => {
    if(!loading){
      handleValidate()
    }
  },[name, country])
  const handleSubmit = async (event) => {
    event.preventDefault();
    const error = handleValidate()
    setLoading(false)
    if (error.length > 0) {
      return
    }
    setSubmitting(true);
    try {

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      const payload = await stripe.createToken(cardElement, {
        name: name,
        address_country: country,
      });

      if (payload.error) {
        console.log(payload.error)
        setErrorMessage(payload.error.message);
        setPaymentMethod(null);
      } else {
        setPaymentMethod(payload?.paymentMethod);
        setErrorMessage(null);

        UserService.addCardToStripe({
          token: payload?.token?.id,
          is_default_card: isDefaultCard,
        }).then((res) => {
          ProcessRequest.run({
            res,
            hasNoti: true,
            successCallback: () => {
              if (typeof toggleShowForm === "function") {
                toggleShowForm();
              }
              if (typeof successCallback === "function") {
                successCallback();
              }
            },
          });
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="save_stripe_card_form">
      <Grid item xs={12} className="mt-4">
        <div className="save_stripe_header">
          <IntlMessage id="user.account.settings.payment_method.form.title" />
        </div>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <TextField
          fullWidth={true}
          id="name"
          name="name"
          className="my-control"
          variant="filled"
          label={<IntlMessage id="stripe.save_card.form.label.name" />}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={12} className="mt-4 custom_stripe_input_wrapper">
        <CardNumberElement id="cardNumber" options={ELEMENT_CARD_NUMBER_OPTIONS} />
      </Grid>
      <Grid container>
        <Grid item xs={6} className="pr-3">
          <Grid item xs={12} className="mt-4 custom_stripe_input_wrapper">
            <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12} className="mt-4 custom_stripe_input_wrapper">
            <CardCvcElement id="cvc" options={ELEMENT_OPTIONS} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="mt-4">
        <TextField
          fullWidth={true}
          id="country"
          name="country"
          className="my-control"
          variant="filled"
          label={<IntlMessage id="stripe.save_card.form.label.country" />}
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} className="mt-4">
        <Form.Check
          custom
          type="checkbox"
          className="checkbox_custom"
          name="is_default_card"
          size="sm"
          checked={isDefaultCard}
          onChange={(event) => {
            setDefaultCard(event.target.checked);
          }}
          label={
            <IntlMessage id="user.account.settings.payment_method.form.is_default_card" />
          }
          id="is_default_card"
        />
      </Grid>
        <p className="mb-1 text-danger">{errorMessage}</p>

      <Grid container className="justify-content-between">
        <Grid item xs={5} className="mt-1 text-left">
          <CustomButton
            className="m-0 mt-3 submitBtn"
            variant="primary"
            type="submit"
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
          >
            <IntlMessage id="user.account.settings.payment_method.form.add_a_card" />
          </CustomButton>
        </Grid>

        <Grid item xs={5} className="mt-1 text-left ml-3 d-none d-md-block">
          <CustomButton className="m-0 mt-3 ml-3 cancelBtn" handleClick={toggleShowForm}>
            <IntlMessage id="common.cancel" />
          </CustomButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SaveCardForm;
