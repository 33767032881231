import React from "react";

import PPEmily from "@iso/assets/images/PPEmily.png";
import PPAlexis from "@iso/assets/images/PPAlexis.png";
import PPTuan from "@iso/assets/images/PPTuan.png";
import PPBrett from "@iso/assets/images/PPBrett.png";
import PPAlexandra from "@iso/assets/images/PPAlexandra.png";
import PPEllen from "@iso/assets/images/PPEllen.png";
import "./index.scss";
import IntlMessage from "@iso/components/intlMessage";
import LeftShortCloud from "@iso/assets/images/LeftShortCloud.svg";
import LeftCloudImg from "@iso/assets/images/LeftCloud.svg";
import { isMobile, isTablet } from "react-device-detect";

export const FEEDBACK_LIST = {
    emily: {
      avatar: PPEmily,
      name: "Emily",
      address: "Dallas",
      denomination: <IntlMessage id="religion.name.protestant" />,
      introduction: (isTablet) ? "Mom, partner and faith-inspired <br> advocate who works for the church" : "Mom, partner and <br> faith-inspired advocate ",
      feedback: "Giving the gift of a prayer is a great way<br><span class='text-highlight'>to care for someone</span> in difficult or exciting <br>times. Giving a prayer invites your loved<br>one into a spiritual connection with<br> another individual, and sometimes that<br>is exactly what they need. So instead<br>of asking, “what do you need?” …give<br>a prayer and <span class='text-highlight'>give connection.</span>",
      height: "87",
      width: "85"
    },
    tuan: {
      avatar: PPTuan,
      name: "Tuan",
      address: "Saigon",
      denomination: <IntlMessage id="religion.name.catholic" />,
      introduction: "Retired structural engineer<br>dedicated to volunteer work",
      feedback: "We all face our own weakness.<br>However, with God’s healing, we do not<br>travel on earth alone but with the faithful companion, our God. <span class='text-highlight'>It is my honour</span><br>to be of service to you and humbly<br><span class='text-highlight'>pray to our Lord upon your behalf.</span>",
      height: "77",
      width: "79"
    },
    alexis: {
      avatar: PPAlexis,
      name: "Alexis",
      address: "Berlin",
      denomination: <IntlMessage id="religion.name.non-denomination" />,
      introduction: "Artist & teacher, believes in<br>angels and love",
      feedback: "For me, prayer is a way of reaching out,<br>but reaching out with the arms of a higher<br>power. There is <span class='text-highlight'>meaning and hope</span> in<br>every prayer and I enjoy knowing that I am<br>part of that. I know prayer works!",
      leftCloud: LeftShortCloud,
      height: "64",
      width: "88"
    },
    brett: {
      avatar: PPBrett,
      name: "Rabbi Brett",
      address: "Miami",
      denomination: <IntlMessage id="religion.name.jewish" />,
      introduction: "Ordained Reform Rabbi, dedicated<br>to service for over 50 years",
      feedback: "Over the course of the past half century<br>devoted to religion and sociology, I have<br><span class='text-highlight'>dedicated myself to critical moments</span> with<br>those whom I serve.",
      leftCloud: LeftShortCloud,
      height: "57.12",
      width: "78.4"
    },
    alexandra: {
      avatar: PPAlexandra,
      name: "Alexandra",
      address: "London",
      denomination: <IntlMessage id="religion.name.anglican" />,
      introduction: "Loving mom, baptised as an adult in<br>the Church of England",
      feedback: "Praying is a way <span class='text-highlight'>to connect</span><br><span class='text-highlight'> with God every day.</span>",
      leftCloud: (isMobile && !isTablet) ? LeftShortCloud : LeftCloudImg,
      height: (isMobile && !isTablet) ? "53" : "77",
      width: (isMobile && !isTablet) ? "73" : "79" 
    },
    ellen: {
      avatar: PPEllen,
      name: "Ellen",
      address: "Red Deer",
      denomination: <IntlMessage id="religion.name.protestant" />,
      introduction:  "Grandma who cherishes sharing<br>the Love & Grace of God",
      feedback: "Praying for someone else - isn’t that the<br>essence of prayer -<span class='text-highlight'> to hold each other up<br>to our Lord?</span> For you to know I’m thinking<br>of you even when you don’t know me!<br>This is the way He intended us to be - an<br>extension of Himself!",
      height: "77",
      width: "79"
    },
  }