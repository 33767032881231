import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";

import "./styles.scss";
import ProfileCard from "./components/Profile";
import ManageNotification from "./components/ManageNotification";
import { getPrayerProfile } from "@iso/PrayerPartner/api_v1";
import SiteLanguage from "./components/SiteLanguage";
import Availability from "./components/Availability";

const AccountSettings = (props) => {
  const [openProfile, setOpenProfile] = useState(true);
  const [profile, setProfile] = useState();

  const getProfile = () => {
    getPrayerProfile().then((res) => {
      if (res && res.data && res.data.data) {
        if (!res.data.data.tagline) {
          res.data.data.tagline = "";
        }
        if (!res.data.data.phone_number) {
          res.data.data.phone_number = "";
        }
        if (!res.data.data.religion) {
          res.data.data.religion = [];
        }
        setProfile(res.data.data);
      }
    });
  };

  const handleUpdateAvatar = (args) => {};

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div
      className="prayer_partners account_settings mint-tulip-background p-3"
      id="account_settings"
    >
      <h2 className="mb-3">
        <IntlMessage id="prayer_partners.account_settings.title" />
      </h2>
      <Card className="p-3">
        {profile && (
          <ProfileCard
            setProfile={setProfile}
            openProfile={openProfile}
            profile={profile}
            reloadProfile={getProfile}
          />
        )}

        <Availability />

        {profile && (
          <ManageNotification
            openNotification={openProfile}
            profile={profile}
          />
        )}

        {profile?.language ? (
          <SiteLanguage currentLanguage={profile?.language || ""} />
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

export default AccountSettings;
