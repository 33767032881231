import React from "react";
import { Button, Modal } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";


const NotificationModal = (props) => {
    const { open, handleClose } = props;

    return (
        <div>
            <Modal show={open} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <IntlMessage id="prayer.notification.profile.modal.noti.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <IntlMessage id="prayer.notification.profile.modal.noti.content" />
                </Modal.Body>
                <Modal.Footer className='text-center' style={{ display: 'block' }}>
                    <Button
                        onClick={handleClose}
                        variant="primary"
                        className="btn hippie-blue-button text-white"
                    >
                        <IntlMessage id="common.ok" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default NotificationModal;
