import All from "../en.json";
import Sidebar from "./sidebar.json";
import PublicRequest from "./public_request.json";
import PrayerRequest from "./prayer_request.json";
import Common from "./common.json";
import ConfirmModal from "./confirm_modal.json";
import LandingPage from './landing_page.json'
import UserAccountSetting from './user_account_settings.json'
import Stripe from './stripe.json'
import AdminUsers from './admin_users.json'
import SupportCenter from './support_center.json'
import LoginAs from './login_as.json'
import AdminReport from './adminReport.json'
import Financial from './financial.json'
import Notification from './notification.json'
const enLang = {
  ...All,
  ...Sidebar,
  ...PublicRequest,
  ...PrayerRequest,
  ...ConfirmModal,
  ...Common,
  ...LandingPage,
  ...UserAccountSetting,
  ...Stripe,
  ...AdminUsers,
  ...SupportCenter,
  ...LoginAs,
  ...AdminReport,
  ...Financial,
  ...Notification,
};
export default enLang;
