import React, { Component, useState } from "react"
import CreateNewPasswordBox from "@iso/Pages/Common/RegistrationBox/CreateNewPasswordBox"
import FormCreateNewPassword from "./FormCreateNewPassword"
import FormPasswordChanged from "./FormPasswordChanged"
import IntlMessage from "@iso/components/intlMessage"
import CloudImg from "@iso/assets/images/CloudCrtPassword.svg"
import "./styles.scss"
import { isTablet } from "react-device-detect"

function CreateNewPassword(props){
    const [flagCrtPassword,setflagCrtPassword] = useState(false);
    const [data, setData] = useState([]);
    return (
      <div className = "createNewPassword-containerFluid">
        <div className="d-none d-md-block img-container">
            <img src = {CloudImg} alt ="Cloud" className="image_cloud"/>
         </div>

        <div className="createNewPassword-container">
          {
            !flagCrtPassword ?
            <CreateNewPasswordBox title={<IntlMessage id="prayer_partner.create_new_password.title"/>}
            form={<FormCreateNewPassword setflagCrtPassword ={setflagCrtPassword} setData = {setData}/>}  />
            : <CreateNewPasswordBox className="successfully" title={<IntlMessage id="prayer_partner.create_new_password.success.title"/>}
            form={<FormPasswordChanged data = {data}/>} />
          }

        </div>
      </div>
    )
}

export default CreateNewPassword
