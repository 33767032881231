import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import "./styles.scss"

function CreateNewPasswordBox(props){
    return (
      <div className={`createNewPassword-box-container ${props.className || ''}`}>
        <div className="col-10 col-lg-5 inner-box">
          <div className="mb-4 title">{props.title}</div>
          {props.form}
        </div>
      </div>
    )
  }

export default CreateNewPasswordBox
