import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import "./CompleteRequest.scss";

const CompleteRequestButton = (props) => {
  const { handleSwitchShowContribution, resetRequestErrors } = props;

  return (
    <Col id="complete_request_container">
      <Button
        id="complete_request__button"
        className="hippie-blue-button"
        onClick={() => {
          handleSwitchShowContribution();
          resetRequestErrors();
        }}
      >
        Complete Request
      </Button>
    </Col>
  );
};

export default CompleteRequestButton;
