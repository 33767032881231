import { userConstants } from "@iso/constants";
import { userRoleConstants } from "@iso/constants";
import { setToken, clearUserData } from "@iso/utils/TokenService";
import { history } from "@iso/helpers/history";
import axios from "axios";

export const setCurrentProfile = (user) => {
  return (dispatch) => {
    dispatch({
      type: userConstants.SET_CURRENT_PROFILE,
      payload: { user },
    });
  };
};

export const setCurrentUser = (
  data,
  option = { redirectAfterLogin: true, remoteLogin: false }
) => {
  return (dispatch) => {
    if (data?.access_token) {
      setToken(data, { remoteLogin: option.remoteLogin });
    }
    const user = new Object({ ...data?.user, role: data?.role });
    if(option.remoteLogin) {
      sessionStorage.setItem("user", JSON.stringify(user));
      if (!sessionStorage.getItem("user")) {
        sessionStorage.setItem("user", JSON.stringify(user));
      }
    }
    if (data?.rememberMe) {
       if (!option.remoteLogin) {
         localStorage.setObject("user", user);
         if (!localStorage.getItem("user")) {
           localStorage.setItem("user", JSON.stringify(user));
         }
       }
    } else {
      if (!option.remoteLogin) {
        document.cookie = `user=${JSON.stringify(user)}; expires=0; path=/`;
      }
    }
    dispatch({
      type: userConstants.SET_CURRENT_USER,
      payload: { currentUser: user },
    });

    if (!!sessionStorage.getItem("previous_path")) {
      history.push(sessionStorage.getItem("previous_path"));
      sessionStorage.setItem("previous_path", "");
      return;
    }
    if (option?.redirectAfterLogin) {
      switch (data?.role) {
        case userRoleConstants.USER_ROLE:
          break;
        case userRoleConstants.ADMIN_ROLE:
        case userRoleConstants.SUPER_ADMIN_ROLE:
          history.push("/admin");
          break;

        case userRoleConstants.PRAYER_ROLE:
          if(window.location.pathname == '/prayer_partners/sign_in'){
            history.push("/prayer_partners");
          }
          break;
        default:
          history.push("/");
          return;
      }
    }
  };
};

export const logoutUser = (skipCallAPI = false) => {
  return (dispatch) => {
    const remoteLogin = sessionStorage.getItem("remoteLogin") == "true";
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/logout`)
      .then((res) => {
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      }).finally(function(){
        clearUserData();
        if(!remoteLogin){
          document.cookie = "user=; expires=0; path=/";
          document.cookie = "token=; expires=0; path=/";
        }
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        sessionStorage.clear();

        dispatch({
          type: userConstants.LOGOUT,
        });
      });
  };
};

export const getUser = () => {
  const remoteLogin = sessionStorage.getItem("remoteLogin") == "true";

  if (sessionStorage.getItem("user")) {
    return JSON.parse(sessionStorage.getItem("user"));
  }
  if (getCookie("user") && !remoteLogin) {
    return JSON.parse(getCookie("user"));
  }
  if (!remoteLogin) {
    return localStorage.getObject("user");
  }
  return null;
};

//function getCookie
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
  }
  return "";
}
