import React, { useEffect, useState } from "react"; 
import {
  Row,
  Col, 
  Card, 
} from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import ReportsService from "@iso/services/ReportsService";  

import "./styles.scss";  


const Reports = (props) => {
  const initValue = {
    assigned :  0,
    unassigned : 0,
    completed : 0,
    paused : 0,
    overdue : 0
  }

  const [values, setValues] = useState(initValue)
  const handleReportRequests = () => {
    
    ReportsService.adminReportsGetRequests().then((res) => {
      if (res) {
        const { code, data } = res.data;
         if (res && code === 200 ) {
            setValues(data) 
        }
        console.log(values)
      }
    });
  };

  useEffect(() => {
    handleReportRequests();
  }, []);
  
  const reportRequests = [
    {id : 'assigned',   label : 'Assigned requests' ,  value : values.assigned},
    {id : 'unassigned', label : 'Unassigned requests', value : values.unassigned},
    {id : 'completed',  label : 'Completed requests',  value : values.completed},
    {id : 'paused',     label : 'Paused requests',     value : values.paused},
    {id : 'overdue',    label : 'Overdue requests',    value : values.overdue},
  ]
  
  return (
    <div className="mint-tulip-background p-3 min-vh-100 reports_list">
      <Row>
        <Col sm="6" className="text-md-left text-center mb-3">
          <h2>
            <IntlMessage id="sidebar.admin.reports.title" />
          </h2>
        </Col> 
      </Row>
      <Card className = "card_swapper">
      <Row >
       {
         reportRequests.map((item,index) => {
            return( 
              <Col xs = '12' lg = '6' key = {index} className ="mb-5">
                <Card className = "report-card">
                  <Card.Header className = "report-card-header">{item.label}</Card.Header>
                  <Card.Body>
                    <Card.Title className = "report-card-title"> {item.value}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            )
         })
       }
       </Row>
       </Card>
    </div>
  );
};

export default Reports;
