import DatePicker from "react-datepicker2";
import React, {useEffect} from "react";
import "./datepicker.scss"

const DatepickerCustom = ({value, onChange, min, isOpen, calendarClass, className, max}) => {
  
  useEffect(() => {
    if (isOpen) {
      document.querySelector('.custom-options-datepicker').click();
    }
  }, [isOpen]);

  return (
    <DatePicker
      calendarClass={`${calendarClass}`}
      className={`custom-options-datepicker ${className}`}
      min={min}
      max={max || null}
      value={value}
      onChange={onChange}
      isGregorian={true}
      showTodayButton={false}
      timePicker={false}
    />
  )
};

export default DatepickerCustom;
