import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Badge,
  Card,
} from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import FinancialService from "@iso/services/FinancialService";
import {MDBBadge, MDBDataTable} from "mdbreact";
import { history } from "@iso/helpers/history"
import "./styles.scss";
import * as qs from "query-string"
import { useLocation } from "react-router"
import moment from "moment";
import { roundUpAmount } from "@iso/utils/FormatData";
import { DATE_FORMAT, CATEGORIES, STATUS } from "./constants";
import { isRegExp } from "lodash";
import RecordModal from "./components/RecordModal"
import alertActions from "@iso/actions/Alert";
import { useDispatch } from "react-redux";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {adminFinancialExport} from "../../../services/FinancialService";
import ConfirmModal from "@iso/components/Common/ConfirmModal";

const FinancialTrans = (props) => {
  let dataPrayerLists = [];
  let dataStatus = "all";
  const [openPaidModal, setOpenPaidModal] = React.useState(false);
  const [isRevert, setIsRevert] = React.useState(false);
  const [markedItem, setMarkedItem] = React.useState(null);
  const [markedIndex, setMarkedIndex] = React.useState(null);
  const [items, setItems] = React.useState({});
  const [prayerLists, setPrayerList] = React.useState([]);
  const [currentStatus, setCurrentStatus] = React.useState("all");
  const [counts, setCounts] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const { filter } = qs.parse(location.search)
  const dispatch = useDispatch();
  const { openAlert } = alertActions;
  const [reload, setReload] = useState(false)
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  const defaultApiData = {
    filter: filter,
    status: currentStatus,
  }
  let disableRedirect = false;
  const handleSubmit = (formikValues) => {
    try{
      FinancialService.adminSetDetailsFinancial(formikValues).then((res) => {
        if(res){
          const { code, data} = res.data
          if( code === 200){
            setReload(!reload)
            setShowModal(false)
            dispatch(
              openAlert({
                message: <IntlMessage id="common.message.update.success" />,
                severity: "success",
              })
            )
          }else{
            dispatch(
              openAlert({
                message: <IntlMessage id="common.message.update.fail" />,
                severity: "error",
              })
            )
          }
        }
      });
    }catch(error){
      console.log(error)
    }finally{}
  };

  const handleClickPaid = (item, index, isRevert = false) => {
    disableRedirect = true;
    setOpenPaidModal(true);
    setMarkedIndex(index);
    setMarkedItem(item);
    setIsRevert(isRevert);
  }
  const formatTableData = (data, status) => {
    let dataRow = [];
    let header = [
      {
        label: <IntlMessage id="admin.financial.header_table.parent_id.title" />,
        field: "parent_id",
        sort:'asc',
      },
    ];
    
    if(filter === 'prayer_partners'){
      header.push({
        label: <IntlMessage id="admin.financial.header_table.id.title" />,
        field: "id",
        sort:'asc',
      })
    }
    
    header = header.concat([
      {
        label: (
          <IntlMessage id="admin.financial.header_table.date_created.title" />
        ),
        field: "date_created",
        sort:'asc',
      },
      {
        label: <IntlMessage id="admin.financial.header_table.created_by.title" />,
        field: "created_by",
        sort:'asc',
      }
    ]);
    
    if(filter === 'users'){
      header = header.concat(
      [
      {
        label: <IntlMessage id="admin.financial.header_table.item.client" />,
        field: "client",
        sort:'asc',
      },
      {
        label: <IntlMessage id="admin.financial.header_table.amount" />,
        field: "amount",
        sort:'asc',
      },
      {
        label: <IntlMessage id="admin.financial.header_table.discount" />,
        field: "discount",
        sort:'asc',
      },
      {
        label: <IntlMessage id="admin.financial.header_table.revenue" />,
        field: "revenue",
        sort:'asc',
      }
    ]);
    }

    if(filter === 'prayer_partners'){
      header = header.concat(
      [
        {
          label: <IntlMessage id="admin.financial.header_table.pp" />,
          field: "prayer_partner",
          sort:'asc',
        },
        {
        label: <IntlMessage id="admin.financial.header_table.amount_owed" />,
        field: "amount_owed",
        sort:'asc',
        }
      ]
      )
    }

    if(status == "all"){
      header.push({
        label: <IntlMessage id="admin.support_center.header_table.status" />,
        field: "status",
        sort:'asc',
      })
    }
  
    if (filter === 'prayer_partners' && status !== 'paid') {
      header.push({
        label: <IntlMessage id="admin.support_center.header_table.action"/>,
        field: "action",
        sort: 'asc',
      })
    }

    data.forEach((e, index) => {
      dataRow.push({
        parent_id: e.prayer_request?.transaction_parent?.id || (e.prayer_request?.transaction_user?.id || ''),
        data_id: e?.id,
        id: (<div data-id={e?.id}>{e?.id}</div>),
        date_created: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.created_at}
          >
            {moment.utc(e.created_at).utcOffset(time_zone).format(DATE_FORMAT)}
          </MDBBadge>
        ),
        created_by: e?.created_by_text,
        client: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e?.prayer_request?.client?.full_name ? e?.prayer_request?.client?.full_name.toLocaleLowerCase() : ''}
          >
            {
              e?.prayer_request?.client?.full_name
            }
          </MDBBadge>
        ),
        amount: e?.formatted_amount,
        discount: (e?.discount_amount !== null) ? "$" + (roundUpAmount(e?.discount_amount)) : "",
        revenue: e?.formatted_revenue,
        prayer_partner: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e?.user?.full_name ? e?.user?.full_name.toLocaleLowerCase() : ''}
          >
            {
              e?.user?.full_name
            }
          </MDBBadge>
        ),
        amount_owed: '$' + e?.amount_owed,
        status: (
          <div className="text-capitalize">{e?.status}</div>
          ),
        action: (
          <>
            {
              e.status !== 'paid' &&
              <Button  className="hippie-blue-button float-md-right btn btn-primary" style={{padding: "6px 24px", height: '35px'}} onClick={() => handleClickPaid(e, index)}>
                <IntlMessage id="admin.financial.tabs.paid.title" />
              </Button>
            }
          </>
          ),
        clickEvent: () => handleClick(e.id),
      });
    });
    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    };
  };
  
  const handlePrayerRequests = ({status,filter = defaultApiData.filter }) => {
    setLoading(true);
    try{
      FinancialService.adminFinancialGetListRequests(filter, status).then((res) => {
        if (res) {
          const { code, data, count } = res.data;
           if (res && code === 200 ) {
            const prayer_lists = formatTableData(data.data, status);
            setPrayerList(prayer_lists.data);
             setItems(prayer_lists.data);
            dataPrayerLists = prayer_lists.data
            setCounts(count);
            setCurrentStatus(status);
            dataStatus = status
            if (prayer_lists.data.rows.length == 0) {
              const tbody = document.querySelector(
                "tbody[data-test=table-body] tr td"
              )
              if(tbody) tbody.innerText = `There is no ${status} transactions.`
            }
          }
        }
      });
    }catch(error){

    }finally{
        setLoading(false);
      }
  };

  const handleClick = id => {
    if (!disableRedirect) {
      history.push(`/admin/financial/${id}`)
    }
    disableRedirect = false
  }
  const handleMarkPaid = (item, index, isRevert = false) => {
    disableRedirect = true;
    
    FinancialService.adminMarkPaidFinancial(item, isRevert).then((res) => {
      dataPrayerLists = prayerLists;
      if (res) {
        const { message, count, code, data } = res.data;
        if (res && code === 200 ) {
          dataPrayerLists.rows.map((transaction, key) => {
            if (transaction.data_id === item.id) {
              index = key
              transaction.status = (<div className="text-capitalize">{data.status}</div>)
              if(isRevert){
                transaction.action = (<Button  className="hippie-blue-button float-md-right btn btn-primary" style={{padding: "6px 24px", height: '35px'}} onClick={(e) => {e.stopPropagation(); handleClickPaid(item, index)}}>
                  <IntlMessage id="admin.financial.tabs.paid.title" />
                </Button>);
              }else{
                transaction.action = (<Button  className="light-gray-button float-md-right btn btn-primary" style={{padding: "6px 24px", height: '35px'}} onClick={(e) => {e.stopPropagation(); handleClickPaid(item, index, true)}}>
                  <IntlMessage id="admin.financial.tabs.paid.revert" />
                </Button>);
              }
            }
            return transaction;
          });
          let checkStatus = !isRevert ? 'outstanding' : 'paid';
          if (dataStatus === checkStatus) {
            dataPrayerLists.rows.splice(index, 1);
            setPrayerList({
              columns: dataPrayerLists.columns,
              rows: dataPrayerLists.rows
            });
          }
          else {
            setPrayerList(dataPrayerLists)
          }
          
          dispatch(
            openAlert({
              message: message,
              severity: "success",
            })
          )
          
          setCounts({
            ...counts,
            ...count
          });
        }
      }
    });

    setOpenPaidModal(false);
  }

  const handleExportExcel = () => {
    FinancialService.adminFinancialExport(defaultApiData.filter, currentStatus).then((res) => {
      if (res.data.code === 200) {
        dispatch(
          openAlert({
            message: <IntlMessage id="admin.export.notification.success"/>,
            severity: "success",
          })
        );
      }
      else {
        dispatch(
          openAlert({
            message: <IntlMessage id="admin.export.notification.error"/>,
            severity: "error",
          })
        );
      }
    })
  }

  useEffect(() => {
    console.log(prayerLists)
  }, [prayerLists]);

  useEffect(() => {
    handlePrayerRequests({status:currentStatus, filter : defaultApiData.filter });
  }, [filter, reload]);

  return (
    <div className="mint-tulip-background p-3 min-vh-100 financial_list">
      <Row>
        <Col sm="6" className="text-md-left text-center mb-3">
          <h2>
            <IntlMessage id="sidebar.admin.financial.title" />
          </h2>
        </Col>
        <Col sm="6" className="text-md-right text-center mb-3">
          {
            filter === "prayer_partners" &&
            <Button
              className="hippie-blue-button float-md-right"
              onClick={() => setShowModal(true)}
            >
              <IntlMessage id="admin.financial.btn_add_record.title" />
            </Button>
          }
         
        </Col>
      </Row>

     {
       !loading &&
       <Card className="card_swapper">
       <Row className="mt-3 mb-3 pl-5 pr-5 row">
           <Col xs="12" sm="6" md="4" className="mt-1 p-1 p-0">
             {
               filter === 'users' ?
                // Button received
               <Button
                  variant={`${
                    currentStatus === "received" ? "active-status" : "light"
                  } btn-block`}
                  onClick={() => handlePrayerRequests({status :'received'})}
                >
                  <IntlMessage id="admin.financial.tabs.received.title" />
                  <Badge className="ml-2 badge-background" pill variant="danger">
                  {
                    counts.received === 0 ? "" : counts.received
                  }
                  </Badge>
                </Button>
                // Button outstanding
                :
                <Button
                variant={`${
                  currentStatus === "outstanding" ? "active-status" : "light"
                } btn-block`}
                onClick={() => handlePrayerRequests({status :'outstanding'})}
                >
                  <IntlMessage id="admin.financial.tabs.outstading.title" />
                  
                  <Badge className="ml-2" pill variant="danger">
                    {
                      counts.outstanding === 0 ? "" : counts.outstanding
                    }
                  </Badge>
                </Button>

             }
           </Col>

           <Col xs="12" sm="6" md="4" className="mt-1 p-1 p-0">
             {
               filter === 'users' ?
                // Button refunded
               <Button
               variant={`${
                 currentStatus === "refunded" ? "active-status" : "light"
               } btn-block`}
               onClick={() => handlePrayerRequests({status :"refunded"})}
             >
               <IntlMessage id="admin.financial.tabs.refunded.title" />
               <Badge className="ml-2 badge-background" pill variant="danger">
                 {
                   counts.refunded === 0 ? "" : counts.refunded
                 }
                </Badge>
              </Button>
              :
              // Button paid
              <Button
               variant={`${
                 currentStatus === "paid" ? "active-status" : "light"
               } btn-block`}
               onClick={() => handlePrayerRequests({status :"paid"})}
             >
               <IntlMessage id="admin.financial.tabs.paid.title" />
               <Badge className="ml-2 badge-background" pill variant="danger">
                 {
                   counts.paid === 0 ? "" : counts.paid
                 }
                </Badge>
             </Button>
             }
             
           </Col>
          
         <Col xs="12" sm="6" md="4" className="mt-1 p-1 p-0">
           <Button
             variant={`${
               currentStatus === "all" ? "active-status" : "light"
             } btn-block`}
             onClick={() => handlePrayerRequests({status :"all"})}
           >
             <IntlMessage id="admin.support_center.tabs.all.title" />
             <Badge className="ml-2 badge-background" pill variant="danger">
                {counts.all === 0 ? "" : counts.all}
             </Badge>
           </Button>
         </Col>
         
       </Row>
       
       <Row className="ml-2 mr-2 position-relative">
  
         <Col sm="12" className="position-relative my-3">
           {/* export excel */}
           <div className="export-excel " style={{right: "20px", cursor: 'pointer'}}  onClick={handleExportExcel}>
             <ArrowUpwardIcon className="arrowUpward"/>
             <IntlMessage id="admin.reporting.request.export.excel.title" />
           </div>
         </Col>
         <Col sm="12">
           <MDBDataTable
             hover
             paging={true}
             id="prayer_lists_table"
             entries={25}
             searching={false}
             noBottomColumns={true}
             entriesOptions={[5, 20, 25]}
             data={prayerLists}
             sortRows={['date_created', 'client', 'prayer_partner']}
             className="hide_scrollbar"
           />
         </Col>
       </Row>
     </Card>
     }
     <RecordModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={handleSubmit}
      />

      <ConfirmModal
            open={openPaidModal}
            handleConfirm={() => handleMarkPaid(markedItem, markedIndex, isRevert)}
            handleClose={() => setOpenPaidModal(false)}
            title={<IntlMessage id={isRevert ? 'confirm_modal.transaction.revert.title' : 'confirm_modal.transaction.paid.title'} />}
            content={<IntlMessage id={isRevert ? 'confirm_modal.transaction.revert.content' : 'confirm_modal.transaction.paid.content'} />}
            okText={<IntlMessage id="common.yes" />}
            cancelText={<IntlMessage id="common.no" />}
          />
    </div>
  );
};

export default FinancialTrans;
