import React from "react";
import { Col } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    color: '#000000',
    padding: "0",
    [theme.breakpoints.down('xs')]: {
      paddingRight: "10px",
    }
  },
  wrapperRight: {
    display: "flex",
    alignItems: "center",
  },
  deleteBtn: {
    color: "#D45252",
    right: 0,
    position: "absolute",
    [theme.breakpoints.down('sm')]: {
      right: "-3px",
    },
    [theme.breakpoints.up('sm')]: {
      top: 0,
    }
  }
}));

const PaymentCardFooter = ({
  handleDelete,
  lastDigit,
  cardType,
  isPrefer,
  allowDelete,
}) => {
  const classes = useStyles();

  return (
    <Col xs="8" lg="12" className={`${classes.root} mt-lg-2`}>
      <div className={classes.wrapperRight}>
        <Col xs="10" lg="8" className={`text-left pl-lg-0 custom-pl-22px font-size-18px ${cardType === 'visa' && 'text-uppercase'}`}>
            {isMobile && cardType === 'mastercard' ?
              <IntlMessage
                id={'common.mastercardShort'}
                values={{ lastDigit: lastDigit }}
              /> : <IntlMessage
              id={`common.${cardType}`}
              values={{ lastDigit: lastDigit }}
              />
            }
          {isPrefer && (
            <p className="text-hippie-blue font-weight-bold hover_pointer font-size-18px text-capitalize m-0">
              <IntlMessage id="user.account.settings.payment_method.preferable" />
            </p>
          )}
        </Col>
        {allowDelete && (
          <Col
            xs="4"
            className={`${classes.deleteBtn} text-right p-0`}
          >
            <span onClick={handleDelete} className={`hover_pointer`}>
              <IntlMessage id="common.delete" />
            </span>
          </Col>
        )}
      </div>
    </Col>
  );
};

export default PaymentCardFooter;
