import React from 'react'
import styled from 'styled-components'
import BeliefItem from './BeliefItem'
import ConnectTitleImg from '@iso/assets/images/landing_connect_title.svg'
import ConnectTitleImgMobile from '@iso/assets/images/landing_connect_title_mobile.svg'
import { Grid } from '@material-ui/core'
import IntlMessage from "@iso/components/intlMessage";
import { isMobile, isTablet } from "react-device-detect";
import "./styles.scss";
import "../index.scss";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "BrandonText";
    background: #AFDAF5;
    color: #1c1634;
    padding: 50px 40px 180px 40px;


    @media only screen and (max-width: 768px) {
        padding-bottom: 40px;
        padding-left: 36px;
        align-items: flex-start;
    }

    @media only screen and (min-width: 768px) {
        padding: 50px 60px 180px 140px;
    }

    @media only screen and (min-width: 960px) and (max-width: 1300px) {
        padding: 50px 70px 180px 70px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        padding: 56px 40px 49px 40px;
    }
    @media only screen and (max-width: 375px){
        padding-bottom: 45px!important;
    }
`

const Title = styled.img`
    margin-bottom: 23px;
    @media only screen and (min-width: 376px) and (max-width: 500px){
        height: 118px;
        margin-bottom: 20px;
    }
`

const Subtitle = styled.p`
    font-size:  46px;
    line-height: 59px;
    font-weight: 700;
    margin-bottom: 0px;
    @media only screen and (max-width: 768px) {
        font-size: 30px; 
        margin-top: -15px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        font-size: 32px; 
        margin-top: -15px;
    }
    @media only screen and (min-width: 1300px){
        margin-left: -100px;
    }

    @media only screen and (max-width: 500px){
        width: 100%;
        margin-top: -17px;
    }
`

const BeliefContainer = styled(Grid)`
    display: flex;
    margin-top: 40px;
    @media only screen and (max-width: 600px) {
        margin-top: 13px;
        width: 305px!important;
        .MuiGrid-root.MuiGrid-item div {
            padding: 5px 20px!important;
            &.non-denomination {
                padding: 5px 16px!important;
            }
            p {
                min-width: fit-content!important;
            }
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px){
        margin-top: 8px;
        min-width: 350px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        margin-top: 16px;
    }
`

const GridCustom = styled(Grid)`
    display: flex;
    margin-top: 7px;
    padding: 8px 16px 8px 0 !important;
    @media only screen and (max-width: 768px){
        padding: 8px!important;
    }
`

const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
`

const BeliefList = [
    {
        id: "catholic",
        title: <IntlMessage id="religion.name.catholic" />,
    },

    {
        id: "protestant",
        title: <IntlMessage id="religion.name.protestant" /> ,
    },

    {
        id: "evangelical",
        title: <IntlMessage id="religion.name.evangelical" />,
    },

    {
        id: "eastern-orthodox",
        title: <IntlMessage id="religion.name.eastern-orthodox" />,
    },

    {
        id: "anglican",
        title: <IntlMessage id="religion.name.anglican" />,
    },

    {
        id: "jewish",
        title: <IntlMessage id="religion.name.jewish" />,
    },

    {
        id: "non-denomination",
        title: <IntlMessage id="religion.name.none-denominational" />,
    },

    {
        id: "",
        title: <IntlMessage id="religion.name.find-denomination" />,
    }
]

const BeliefListTablet = [
    {
        id: "catholic",
        title: <IntlMessage id="religion.name.catholic" />,
    },

    {
        id: "protestant",
        title: <IntlMessage id="religion.name.protestant" /> ,
    },

    {
        id: "eastern-orthodox",
        title: <IntlMessage id="religion.name.eastern-orthodox" />,
    },

    {
        id: "evangelical",
        title: <IntlMessage id="religion.name.evangelical" />,
    },

    {
        id: "anglican",
        title: <IntlMessage id="religion.name.anglican" />,
    },

    {
        id: "non-denomination",
        title: <IntlMessage id="religion.name.none-denominational" />,
    },

    {
        id: "jewish",
        title: <IntlMessage id="religion.name.jewish" />,
    },

    {
        id: "",
        title: <IntlMessage id="religion.name.find-denomination" />,
    }
]

const BeliefListMobile = [
    {
        id: "catholic",
        title: <IntlMessage id="religion.name.catholic" />,
    },

    {
        id: "protestant",
        title: <IntlMessage id="religion.name.protestant" /> ,
    },

    {
        id: "eastern-orthodox",
        title: <IntlMessage id="religion.name.eastern-orthodox" />,
    },

    {
        id: "anglican",
        title: <IntlMessage id="religion.name.anglican" />,
    },

    {
        id: "evangelical",
        title: <IntlMessage id="religion.name.evangelical" />,
    },

    {
        id: "jewish",
        title: <IntlMessage id="religion.name.jewish" />,
    },

    {
        id: "non-denomination",
        title: <IntlMessage id="religion.name.none-denominational" />,
    },

    {
        id: "",
        title: <IntlMessage id="religion.name.find-denomination" />,
    }
]

const LeftSide = props => {
    let beliefList = BeliefList;
    if(isMobile) beliefList = BeliefListMobile;
    if(isTablet) beliefList = BeliefListTablet;
    return <Wrapper id="section5-left-side">
        <TitleContainer>
          <Title src={ConnectTitleImg} className="d-none d-md-block"></Title>
          <Title src={ConnectTitleImgMobile} className="d-md-none"></Title>
        </TitleContainer>
        <Subtitle><IntlMessage id="landing.connect.subtitle1" /></Subtitle>
        <Subtitle className="pl-md-2 subtitle2"><IntlMessage id="landing.connect.subtitle2" /></Subtitle>

        <BeliefContainer container spacing={2} xs={12}>
            {beliefList.map((belief, idx) => {
                return (
                    <GridCustom item key={idx}>
                        <BeliefItem title={belief.title} id ={belief.id} key={idx} ></BeliefItem>
                    </GridCustom>
                )
            })}
        </BeliefContainer>
    </Wrapper>
}

export default LeftSide