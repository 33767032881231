import React from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik"

import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import PrayerPartnerService from "@iso/services/PrayerPartnerService";
import { AddPrayerFormValidator } from "./validator"
import alertActions from "@iso/actions/Alert"
import { useDispatch } from "react-redux"
import IntlMessage from "@iso/components/intlMessage"

const { openAlert } = alertActions

function AddForm(props) {
  let initValue = {
    first_name: "",
    last_name: "",
    email: "",
  }
  const dispatch = useDispatch()

  const handleSubmit = async (values, bag) => {
    PrayerPartnerService.createPrayerPartner(values)
    props.handleClose()
    dispatch(
      openAlert({
        message: <IntlMessage id="common.message.send.fail" />,
        severity: "success",
      })
    )
  }

  return (
    <Formik
      initialValues={initValue}
      enableReinitialize={false}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={AddPrayerFormValidator}
    >
      {({ handleSubmit, handleChange, touched, errors }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Form.Group as={Row}>
            <Col sm={{ span: "8", offset: "2" }}>
              <Form.Label>
                <IntlMessage id="admin.prayer_partner.form.first_name" />
              </Form.Label>
              <Form.Control
                name="first_name"
                type="text"
                placeholder="First name"
                onChange={handleChange}
                maxLength={20}
              />
              <ErrorMessageWrapper
                touched={touched}
                errors={errors}
                fieldName="first_name"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={{ span: "8", offset: "2" }}>
              <Form.Label>
                <IntlMessage id="admin.prayer_partner.form.last_name" />
              </Form.Label>
              <Form.Control
                name="last_name"
                type="text"
                placeholder="Last name"
                onChange={handleChange}
                maxLength={20}
              />
              <ErrorMessageWrapper
                touched={touched}
                errors={errors}
                fieldName="last_name"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="loginEmail">
            <Col sm={{ span: "8", offset: "2" }}>
              <Form.Label>
                <IntlMessage id="admin.prayer_partner.form.email" />
              </Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter email"
                onChange={handleChange}
              />
              <ErrorMessageWrapper
                touched={touched}
                errors={errors}
                fieldName="email"
              />
            </Col>
          </Form.Group>
          <div className="buttons text-center mt-4">
            <Button className="hippie-blue-button" type="submit">
              <IntlMessage id="admin.prayer_partner.form.submit" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default AddForm
