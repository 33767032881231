import axios from "axios";
import { apiEndpoints } from "@iso/helpers";

const sendTellUsAbout = (body) => {
    return axios.post(
        `${apiEndpoints.about}`,
        body
    );
};

export default {
	sendTellUsAbout,
};
