import React from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import AuthService from "@iso/services/AuthService"
import { setCurrentUser } from "@iso/actions/User"
import { connect } from "react-redux"
import { userRoleConstants } from "@iso/constants/user"
import { history } from "@iso/helpers/history"
import IntlMessage from "@iso/components/intlMessage"
import './styles.scss'
function FormPasswordChanged(props) {

  const dispatch = useDispatch();
  const { data } = props;

  const handleSubmit =  () => {
    dispatch(
      setCurrentUser(data)
    )

    history.push("/");
  }

  return (
    <form  noValidate>
      <Form.Group as={Row} className = "d-block">
       <Form.Text className = "text-msg">
            <IntlMessage id="prayer_partner.create_new_password.change_password_success" />
        </Form.Text>
      <Button variant="primary" size="lg"
      block className ="hippie-blue-sign-in-button py-3 font-weight-bold w-85" type="button" onClick = {handleSubmit}>
          <IntlMessage id="prayer_partner.create_new_password.signin" />
      </Button>
      </Form.Group>
    </form>
  )
}

export default FormPasswordChanged
