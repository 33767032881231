import { Grid, Typography } from '@material-ui/core'
import HowItWorksIcon1 from '@iso/assets/images/how_it_works_item1.svg'
import HowItWorksIcon2 from '@iso/assets/images/how_it_works_item2.svg'
import HowItWorksIcon3 from '@iso/assets/images/how_it_works_item3.svg'
import HowItWorksIcon4 from '@iso/assets/images/how_it_works_item4.svg'
import HowItWorksIcon1Mobile from '@iso/assets/images/how_it_works_item1_mobile.svg'
import HowItWorksIcon2Mobile from '@iso/assets/images/how_it_works_item2_mobile.svg'
import HowItWorksIcon3Mobile from '@iso/assets/images/how_it_works_item3_mobile.svg'
import HowItWorksIcon4Mobile from '@iso/assets/images/how_it_works_item4_mobile.svg'
import React from 'react'
import styled from 'styled-components'
import HowItWorksItem from './HowItWorksItem'
import {isMobile, isTablet} from 'react-device-detect'
import HowItWorksCarousel from './HowItWorksCarousel'
import IntlMessage from "@iso/components/intlMessage"
import './index.scss'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: space-around;
    flex-direction: column;
    font-family: BrandonText;
    padding: 35px 0px;
    background-color: white;

    @media only screen and (min-width: 768px) and (max-width: 1919px){
        padding: 100px 100px 60px;
        padding-top: 157px;
        margin-top: -20px;
    }
    @media (min-width: 768px) and (max-width: 1024px){
        padding-top: 117px!important;
        padding-left: 40px!important;
        padding-right: 40px!important;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        padding-top: 15px!important;
    }
`

const Title = styled.h2`
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    line-height: 64px;
    color: #1C1634;

    @media (min-width: 768px) and (max-width: 1024px){
        font-size: 38px;
        font-size: 38px;
    }

    @media only screen and (min-width: 1025px){
        font-size: 46px;
    }
    
    @media (min-width: 768px) and (max-width: 1024px){
        margin-bottom: 40px!important;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        font-size: 32px;
        margin-bottom: 13px!important;
    }
`

const ItemContainer = styled(Grid)`

`

const items = [
    {
        icon: HowItWorksIcon1,
        title: <IntlMessage id="landing.how.it.works.item1.title" />,
        description: <IntlMessage id="landing.how.it.works.item1.description" />,
        style: {}
    },
    {
        icon: HowItWorksIcon2,
        title: <IntlMessage id="landing.how.it.works.item2.title" />,
        description: <IntlMessage id="landing.how.it.works.item2.description" />,
        style: {}
    },
    {
        icon: HowItWorksIcon3,
        title: <IntlMessage id="landing.how.it.works.item3.title" />,
        description: <IntlMessage id="landing.how.it.works.item3.description" />,
        style: {}
    },
    {
        icon: HowItWorksIcon4,
        title: <IntlMessage id="landing.how.it.works.item4.title" />,
        description: <IntlMessage id="landing.how.it.works.item4.description" />,
        style: {}
    }
]

const itemsMobile = [
    {
        icon: HowItWorksIcon1Mobile,
        title: <IntlMessage id="landing.how.it.works.item1.title" />,
        description: <IntlMessage id="landing.how.it.works.item1.description" />,
        style: {}
    },
    {
        icon: HowItWorksIcon2Mobile,
        title: <IntlMessage id="landing.how.it.works.item2.title" />,
        description: <IntlMessage id="landing.how.it.works.item2.description" />,
        style: {}
    },
    {
        icon: HowItWorksIcon3Mobile,
        title: <IntlMessage id="landing.how.it.works.item3.title" />,
        description: <IntlMessage id="landing.how.it.works.item3.mobile.description" />,
        style: {}
    },
    {
        icon: HowItWorksIcon4Mobile,
        title: <IntlMessage id="landing.how.it.works.item4.title" />,
        description: <IntlMessage id="landing.how.it.works.item4.description" />,
        style: {paddingRight: "50px"}
    }
]

const Section2 = props => {
    return <Wrapper style={{paddingTop: `${isMobile ? '10px' : '157px'}`}}>
        <Title><IntlMessage id="landing.how.it.works.title" /></Title>
        <Grid container spacing={3} className="mt-lg-5 px-3 px-md-0">
            {
                isMobile && !isTablet
                ? <HowItWorksCarousel items={itemsMobile}></HowItWorksCarousel>
                : items.map((item, idx) => {
                    return (
                        <Grid key={idx} item xs={12} sm={6} lg={3} className="p-lg-2">
                            <HowItWorksItem icon={item.icon} title={item.title} description={item.description} ></HowItWorksItem>
                        </Grid>
                    )
                })
            }
        </Grid>

      
    </Wrapper>
}

export default Section2
