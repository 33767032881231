import { Link } from "react-router-dom";
import React from "react";

const ButtonMailTo = (props) => {
    const { mailTo, label } = props;

    return (
        <Link
            to="#"
            onClick={(e) => {
                window.location.href = mailTo
                e.preventDefault()
            }}
            className="text-hippie-blue"
        >{ label }</Link>
    )
}

export default ButtonMailTo;