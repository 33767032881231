import React  from "react"
import PrayerTypeService from '@iso/services/PrayerTypeService'
import IntlMessage from "@iso/components/intlMessage";
import {Form}  from "react-bootstrap"
import {isTablet} from "react-device-detect";
import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const PrayerTypes = ({formikValues, setFormikValues}) => {
  const [types, setTypes] = React.useState([]);
  const { prayer_type_id } = formikValues.prayer_customization;
  // const most_types = [
  //   'birthday',
  //   'good-health',
  //   'guardian-angel',
  //   'memorial',
  //   'mom' ,
  //   'dad' ,
  //   'love',
  //   'wedding' ,
  //   'pregnancy',
  //   'birth',
  //   'exam',
  //   'work',
  //   'miracle',
  //   'prosperity',
  //   'earth',
  //   'pet',
  // ];
  const most_types = [
    'birthday',
    'good-health',
    'gratitude',
  ];
  
  React.useEffect(() => {

    if(types.length > 0){
      // open seeMore
      if(prayer_type_id ){
        let item = getSlugFromId(prayer_type_id)
        // if(!most_types.includes(item.slug)){
          setSeeMore(true);
        // }
      }
      return
    }

    PrayerTypeService.list().then((res) => {
      const { code, data } = res.data
      if (code === 200) {
        setTypes(data);
      }
    });
  });

  const [seeMore, setSeeMore] = React.useState(false);

  const getDataFromSlug = (slug) => {
    let item = types.find((it) => {
      return it.slug == slug
    });
    if(item)
      return item
    else
      return null
  }

  const getSlugFromId = (id) => {
    let item = types.find((it) => {
      return it.id == id
    });
    if(item)
      return item
    else
      return null
  }

  const handleClick = (id) => {
    gtag('event', custom_events.CLICK_BUTTON, {
      button_label : 'Most requested prayers',
      button_value : id,
    });
    ReactPixel.track(fb_pixel_event.custom_events.CLICK_BUTTON, {
      button_label : 'Most requested prayers',
      button_value : id,
    });

    if(formikValues.prayer_customization.prayer_type_id === id){
      id = null;
    }
    setFormikValues({
      ...formikValues,
      prayer_customization: {
        ...formikValues.prayer_customization,
        prayer_type_id: id
      }
    })
  }
  return(
    <>
      <Form.Label className ="prayerTypes_margin">
        <IntlMessage id='public.request.right.form.most.label'/>
        {!seeMore && <a className={`see-more-link mobile float-right d-block ${!isTablet && "d-sm-none"} float-right tablet-see-more`} href="javascript:void(0)" onClick={() => setSeeMore(true)}>
            <IntlMessage id='common.see_more' />
        </a>}
      </Form.Label>
      <br/>
      <div className="groupPrayerItem">
        {!seeMore && types.length > 0 && most_types.map((slug) => {
          let t = getDataFromSlug(slug)
          return(
            <div key={t?.id} className={`pray-item ${prayer_type_id == t?.id ? 'active' : ''}`}
                 onClick={() => handleClick(t?.id) }>
              {t?.name}
            </div>
          )
        })}
        {!seeMore && !isTablet && <a className='see-more-link d-none d-sm-inline-block' href="javascript:void(0)" onClick={() => setSeeMore(true)}>
          <b>
            <IntlMessage id='common.see_more' />
          </b>
        </a>}
        {seeMore && types.map((t, index) => {
          return(
            <>
              <div key={t?.id}  className={`pray-item ${prayer_type_id == t.id ? 'active' : ''}`}
                  onClick={() => handleClick(t?.id) }>
                {t.name}
              </div>
              { (index + 1) > 0 && (index + 1) % 2 === 0 && <br/> }
            </>
          )
        })}

      </div>
    </>
  )
}

export default PrayerTypes;
