import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import ShareYourRequest from "@iso/components/ShareRequest/ShareYourRequest";

import ImgTalkingGirl from "@iso/assets/images/step_4_talking_girl.svg";
import ImgTalkingGirlTablet from "@iso/assets/images/step4_talking_girl_tablet.svg";
import {isTablet} from "react-device-detect"

const LeftPart = (props) => {
  return (
    <>
      <Row id="left-part-wrapper-step4">
        <Col className="custom_col p-lg-5">
          <ShareYourRequest variant="step4" showImage={true} />
          <div className="image-container d-none d-sm-flex justify-content-center align-items-center img-girl-talking">
            <img className="d-none d-sm-block step4-img-talking-girl" src={isTablet ? ImgTalkingGirlTablet : ImgTalkingGirl}></img>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LeftPart;
