import React, {useEffect} from "react";
import FAQHeaderClouds from "../FAQ/components/headerClouds";

const TermPage = (props) => {

  useEffect(() => {
    let js, tjs = document.getElementsByTagName('script')[0];
    if (document.getElementById('termly-jssdk')) return;
    js = document.createElement('script'); js.id = 'termly-jssdk';
    js.src = "https://app.termly.io/embed-policy.min.js";
    tjs.parentNode.insertBefore(js, tjs);

  }, [])

  useEffect(() => {

    let js, tjs = document.getElementsByTagName('script')[0];
    if (document.getElementById('termly-banner')) return;
    js = document.createElement('script');
    js.id = 'termly-banner';
    js.setAttribute('data-website-uuid', 'df929410-aaee-4093-a450-a7d0699fda79');
    js.setAttribute('data-auto-block', 'on');
    js.src = "https://app.termly.io/embed.min.js";
    tjs.parentNode.insertBefore(js, tjs);

    let cover = document.getElementById('termly-banner-cover');
    let btn = document.createElement('button');
    btn.setAttribute('class', 'termly-cookie-preference-button')
    btn.setAttribute('type', 'button')
    btn.setAttribute('onclick', 'displayPreferenceModal()')
    btn.innerText = 'Manage Cookie Preferences'
    cover.appendChild(btn);
  }, [])

  return (
    <div>
      <div className={`container-full-width bg_blueSky`}>
        <div className="container-fixed">
          <FAQHeaderClouds/>
        </div>
      </div>

      <div className="container">
        <div name="termly-embed" data-id="49df504b-f2c1-4a0e-bcf3-b50f64d86c56" data-type="iframe"></div>

        <div style={{marginTop: '-120px'}} name="termly-embed" data-id="43dfc4c7-1af0-4569-a6db-74ea9ac6bd1b" data-type="iframe"></div>

        <div style={{position: 'relative', marginTop: '-90px'}}>
          <div style={{paddingLeft: '30px', paddingRight: '30px'}}>
            Visit Cookie Preference Center to change your consent settings.
          </div>
          <div
              style={{paddingLeft: '30px', paddingRight: '30px', position: 'absolute', top: '30px', zIndex: '10'}}
              className="container"
              id="termly-banner-cover"
          >
          </div>
        </div>

        <div name="termly-embed" data-id="3df1cc3d-503b-4bc1-b136-228ee69eb4c2" data-type="iframe"></div>

        <div style={{marginTop: '-120px'}} name="termly-embed" data-id="7afcfa45-9e8f-439d-812a-a2331ecddc7e" data-type="iframe"></div>
      </div>
    </div>
  );
};

export default TermPage;
