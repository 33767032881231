import React from "react";
import styled from "styled-components";
import PrayerPartnerAvatar from "@iso/assets/images/janan.png"; 
import { Grid } from "@material-ui/core";
import Button from "../../../../../../components/AtomicComponents/Button";
import "@iso/assets/common.scss";
import { useHistory } from "react-router";
import IntlMessage from "@iso/components/intlMessage";
import { Link } from "react-router-dom"
import { isMobile } from "react-device-detect"
import PopupBeta from "../../../../../../actions/PopupBeta";
const Avatar = styled.img`
  width: 90px;
  height: 90px;
  @media only screen and (min-width: 376px) and (max-width: 500px){
    width: 100px;
    height: 100px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "BrandonText";

  padding: 50px 40px 180px 40px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 10px;
    padding-top: 30px;
    padding: 50px 17px 180px 17px;
  }

  @media only screen and (min-width: 768px) {
    padding: 100px 140px 100px 140px;
  }

  @media only screen and (min-width: 960px) {
    padding: 150px 70px 180px 70px;
  }

  @media only screen and (max-width: 375px) {
    padding-top: 25px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    padding-top: 27px;
    padding-bottom: 44px;
  }

`;

const Title = styled.p`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
  width: 100%;
  @media only screen and (max-width: 375px) {
    margin-top: 15px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    font-size: 24px;
    margin-top: 15px;
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 32px;
  margin-top: 17px;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 375px){
    margin-top: 5px;
    margin-bottom: 16px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 18px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 12px;
    margin-bottom: 41px;
  }

`;

const About = styled.p`
  margin-top: 28px;
  @media only screen and (min-width: 960px) {
    margin-top: 22px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 23px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

const SubtitleMobile = styled.span`
  display: block;
  margin-bottom: -5px;
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 0;
  }
`

const RequestBtn = styled(Button)`
  width: 250px;
  height: 60px; 
  @media only screen and (max-width: 375px){
    height: 50px; 
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    width: 290px;
    height: 54px;
  }
`

const RightSide = (props) => {
  const history = useHistory();
  const [show, setShow] = React.useState(false);

  const handleRequestNew = () => {
    history.push("/requests/step1");
    // setShow(true)
  };

  return (
    <Wrapper id="section5-right-side">
      <Avatar src={PrayerPartnerAvatar}></Avatar>
      <TitleContainer>
        {
          isMobile
            ?
            <Title className="">
              <SubtitleMobile className=""><IntlMessage
                id="landing.prayerPartner.mobile.title1"/></SubtitleMobile>
              <IntlMessage id="landing.prayerPartner.mobile.title2"/>
            </Title>
            :
            <Title className="">
              <IntlMessage id="landing.prayerPartner.title"/>
            </Title>
        }
      </TitleContainer>
      <Subtitle>
        <IntlMessage id="landing.prayerPartner.description" />
      </Subtitle>
      <RequestBtn 
        variant="primary"
        handleClick={() => handleRequestNew()}
      >
        <IntlMessage id="landing.request.new.prayer" />
      </RequestBtn>
      {/* <PopupBeta show={show} setShow={setShow}/> */}
      <About className="d-none d-md-block">
        <a href="/about" className="hippie-blue-link hover_pointer ">
          <IntlMessage id="landing.prayerPartner.about" />
        </a>{" "}
        <IntlMessage id="landing.prayerPartner.ourPrayerPartners" />
      </About>
    </Wrapper>
  );
};

export default RightSide;
