import React from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { ForgotPasswordFormValidator } from "./validator"
import "./styles.scss"
import { Link } from "react-router-dom"

import { requestResetPasswordApi } from "./api_v1"
import ConfirmModal from "@iso/components/Common/ConfirmModal"
import IntlMessage from "@iso/components/intlMessage"


const ForgotPasswordForm = ({ loginURL }) => {
  const [openModal, setOpenModal] = React.useState(false)
  const [messageContent, setMessageContent] = React.useState("")

  const handleRequestResetpassword = (values, bag) => {
    requestResetPasswordApi(values)
      .then((res) => {
        if (!res) {
          bag.setErrors({ email: "Incorrect login information!" })
          bag.setSubmitting(false)
        }
        if (res.status == 200) {
          setOpenModal(true)
          setMessageContent(
            `An email has been sent to ${values.email}. Please check your inbox for password resetting instructions.`
          )
        }
      })
      .catch((res) => {
        bag.setErrors({
          email:
            "The email address you entered is not recognized. Please try another email address.",
        })
        bag.setSubmitting(false)
      })
  }

  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={ForgotPasswordFormValidator}
        onSubmit={handleRequestResetpassword}
      >
        {({ values, handleSubmit, handleChange, touched, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form.Group as={Row} controlId="loginEmail">
              <Col sm={{ span: "8", offset: "2" }}>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="email"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-1">
              <Col sm="6" className="text-md-right text-center mt-1">
                <Button className="hippie-blue-button" type="submit">
                  <IntlMessage id="forgot_password.form.submit" />
                </Button>
              </Col>
              <Col sm="6" className="text-md-left text-center mt-1">
                <Link
                  className="btn hippie-blue-button text-white"
                  to={loginURL}
                >
                  <IntlMessage id="forgot_password.back_to_login" />
                </Link>
              </Col>
            </Form.Group>
          </form>
        )}
      </Formik>
      <ConfirmModal
        open={openModal}
        handleConfirm={() => setOpenModal(false)}
        handleClose={() => setOpenModal(false)}
        title={<IntlMessage id="forgot_password.confirm_modal.title"/>}
        okText={<IntlMessage id="forgot_password.confirm_modal.ok" />}
        content={messageContent}
      />
    </>
  )
}

export default ForgotPasswordForm
