import React from "react";
import { useParams } from "react-router-dom";
import IntlMessage from "@iso/components/intlMessage";

import ProfileCard from "./components/ProfileCard";
import PaymentCard from "./components/PaymentCard";
import RequestTable from "./components/RequestTable";
import "./styles.scss";
import { userRoleConstants } from "@iso/constants";
import LoginAs from "@iso/components/Common/LoginAs";
import { Link, useLocation } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";

const UserDetails = (props) => {
  const { id } = useParams();
  let location = useLocation();
  let previous_location = location?.state?.previous_location;
  let isFromFinancialPage = previous_location && previous_location.match(/financial/i);
  return (
    <div
      className="admin_user_show mint-tulip-background p-3"
      id="admin_user_show"
    >
      <Row>
        <Col xs="12" md="6" lg="4">
          <h2 className="mb-3">
            <IntlMessage id="admin.users.settings.profile.title" />
            <Link to={isFromFinancialPage ? '/admin/financial?filter=users' : '/admin/users'} className="text-hippie-blue header_back_link d-block d-md-none">
              {"< Back"}
            </Link>
          </h2>
        </Col>
        <Col xs="12" md="6" lg={{ span: 4, offset: 4 }}>
          <Row>
            <Col
              xs="12"
              md="4"
              className="text-center mb-3 pt-2 d-none d-md-block"
            >
              <Link to={isFromFinancialPage ? '/admin/financial?filter=users' : '/admin/users'} className="text-hippie-blue">
                {"< Back"}
              </Link>
            </Col>
            <Col xs="12" md="8">
              <LoginAs
                className="mb-3 w-100"
                id={id}
                role={userRoleConstants.USER_ROLE}
                allowRemove={true}
                allowResetPassword={true}
                allowBan={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Card className="p-3">
        <ProfileCard profileId={id} />
        <PaymentCard profileId={id} />
        <RequestTable profileId={id} />
      </Card>
    </div>
  );
};

export default UserDetails;
