import React, {useState} from "react";
import {Card, Button, Collapse, Badge, Row, Col} from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import EditIcon from "@material-ui/icons/Edit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import ProfileForm from "./ProfileForm";
import { BADGE_VARIANT } from "./constants";
import banAccountActions from "@iso/actions/banAccountActions";

import RequestStatus from "@iso/components/Request/RequestStatus";
import {COLOR_CODE} from "../../../../components/Request/constants";
import alertActions from "../../../../actions/Alert";
const ProfileCard = (props) => {
  const {
    openProfile = true,
    profileId,
    handleUpdateProfile,
    idForUpdate,
  } = props;
  const dispatch = useDispatch();
  const { openAlert } = alertActions;
  const [isEdit, setIsEdit] = React.useState(false);
  const [profile, setProfile] = React.useState();
  React.useEffect(() => {
    const ac = new AbortController();
    UserService.adminGetUserDetails(profileId).then((res) => {
      const {code ,data} = res.data
      ProcessRequest.run({
        res,
        successCallback: (data) => {
          setProfile(data.data);
          dispatch(banAccountActions.setBanAccountData(data.data));
        },
      });
    });
    return () => ac.abort(); // Abort both fetches on unmount
  }, [profileId]);
  
  const handleSendMailVerify = () => {
    UserService.adminSendMailVerify(profileId).then((res) => {
      if (res) {
        const { message, code } = res.data;
        if (res && code === 200 ) {
          dispatch(
            openAlert({
              message: message,
              severity: "success",
            })
          )
        }
      }
    });
  }

  return (
    <>
      {profile && (
        <div className="p-md-3 admin_user_profile_card profile_card">
          <Card.Title className="title p-0 m-0 px-3">
            <span>
              <IntlMessage id="admin.users.settings.profile.subtitle" />
            </span>
            <Button
              className="float-right hover_pointer btn hippie-blue-link edit_btn"
              onClick={() => setIsEdit(true)}
            >
              <EditIcon className="pr-1 pb-1" />
              <IntlMessage id="common.edit" />
            </Button>
          </Card.Title>
          <Collapse in={openProfile}>
            <Card.Body>
              <div className="d-flex" style={{justifyContent: 'space-between'}}>
                <div className="d-flex">
                  <RequestStatus request={profile}/>
                  <div className="request_status_wrapper">
                    <Badge
                      className={`paused ml-3 text-capitalize p-1 status`}
                      style={{"backgroundColor": COLOR_CODE[profile.email_verified_at ? 'verified' : 'unverified']}}
                    >
                      <IntlMessage
                        id={`admin.requests.request_details_card.status.${profile.email_verified_at ? 'verified' : 'unverified'}`}
                      />
                    </Badge>
                  </div>
                </div>
                <div>
                  {
                    !profile.email_verified_at && <Button
                      className="hippie-blue-button app_btn"
                      style={{minWidth: "50px", fontSize: '12px', padding: "3px 12px", height: '30px'}}
                      onClick={() => handleSendMailVerify()}
                    >
                      <IntlMessage id="admin.requests.request_details_card.verify" />
                    </Button>
                  }
                </div>
              </div>
              
              <ProfileForm
                profile={profile}
                setProfile={setProfile}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
              />
            </Card.Body>
          </Collapse>
        </div>
      )}
    </>
  );
};

export default ProfileCard;
