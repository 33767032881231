import React, { useEffect, useState } from "react"
import LeftPart from './LeftPart';
import RightPart from './RightPart';
import {
	Row,
	Col,
	Button
} from "react-bootstrap"

import { Formik, Field, Form } from "formik";
import './styles.scss'
import { useDispatch, useSelector } from "react-redux"
import confirmRequestModalActions from '@iso/actions/confirmRequestModal'
import ReceiveYourConfirmModal from "../../ReceiveConfirm/ReceiveYourConfirmModal";
import RequestService from "@iso/services/RequestService";
import { history } from "@iso/helpers";
import requestStepActions from "@iso/actions/RequestStepActions";
import { isTablet, isMobile } from "react-device-detect"
import { userRoleConstants } from "../../../../constants";
import { setCurrentUser } from "@iso/actions/User"
import { removeCardData } from "@iso/actions/CardDataActions";

const Index = (props) => {
	const dispatch = useDispatch()
	const { id, client_email, signup_first_name, signup_last_name, signup_password, signup_keep_me, is_request, transaction_id } = useSelector((state) => state.requestStepReducer);
	const requestStepData = useSelector((state) => state.requestStepReducer);
	const { loggedIn, user } = useSelector((state) => state.authentication);
	const [updateDetails, setUpdateDetails] = useState({ client_email: '', id: '' })
	const [transactionId, setTransactionId] = useState(false);
	const [isUpdateDetail, setIsUpdateDetail] = useState(false);
	const time_zone = -(new Date().getTimezoneOffset() / 60);
	const cardData = useSelector((state) => state.cardData);

	const setStep = () => {
		dispatch(
			requestStepActions.setDataStep({
				...requestStepData,
				step: 4
			}))
		setTransactionId(transaction_id)
		window.scrollTo(0, 0);
	}

	// Update update details according to state
	useEffect(() => {
		// If no valid user, return
		if (!user) {
			return
		}

		if (signup_first_name) {
			return
		}

		setUpdateDetails({
			id: id,
			client_email: user.email
		})
	}, [user, id])

	useEffect(() => {
		if (!client_email) {
			return
		}

		if (client_email.length === 0) {
			return
		}

		setUpdateDetails({
			id: id,
			client_email: client_email,
			signup_first_name: signup_first_name,
			signup_last_name: signup_last_name,
			signup_password: signup_password,
			signup_keep_me: signup_keep_me,
			time_zone: time_zone,
		})
		window.scrollTo(0, 0);
	}, [client_email, signup_first_name])


	// Update step
	useEffect(() => {
		// if(requestStepData.step == 0){
		// 	history.push("/requests/step1");
		// 	return

		// }
		setStep();
		if (!loggedIn && !client_email && !user) {
			dispatch(confirmRequestModalActions.openModal({
				title: 'user.confirm.form.receive.confirm'
			}))
		}

		if (loggedIn || client_email) {
			dispatch(confirmRequestModalActions.closeModal())
		}
	}, [])

	// When update details changes, and email length is > 0
	// Submit update prayer
	useEffect(() => {
		if (updateDetails?.client_email?.length > 0 && !isUpdateDetail && is_request == false) {
			handleUpdatePrayer()
			setIsUpdateDetail(true)
		}
		window.scrollTo(0, 0);

	}, [updateDetails])

	const handleUpdatePrayer = () => {
		if (!id) {
			return
		}
		RequestService.updateEmailRequest(id, { ...updateDetails, cardData }).then((res) => {
			const { code, data, errors } = res.data;
			const { request, data_login } = data;
			if (code == 200) {
				setTransactionId(request?.transaction?.id);
				dispatch(requestStepActions.setDataStep({
					transaction_id: request?.transaction?.id,
					is_request: true
				}))
				if (data_login) {
					dispatch(setCurrentUser({ ...data_login, rememberMe: 0 }, { redirectAfterLogin: false }))
				}
			}
		}).finally(() => {
			dispatch(removeCardData())
		});;

	}
	return (
		<div className={`container-full-width bg-blue-sky-half-reverse-2`}>
			<div className="container-fixed">
				<div className={`step4-index`}>
					<Row className="fix-row">
						{(isMobile && !isTablet) ? (
							<>
								<Col sm={isTablet ? 6 : 5} id='rightside' className="pt-0 pt-sm-4 position-relative">
									<RightPart requestId={transactionId} email={updateDetails.client_email} />
								</Col>
								<Col sm={isTablet ? 6 : 7} id='leftside' className="custom_left_side pb-0 pt-0 pt-lg-3 pb-sm-4 custom_left_side_mnobile">
									<LeftPart />
								</Col>
							</>
						) : (
							<>
								<Col sm={isTablet ? 6 : 7} id='leftside' className="custom_left_side pb-0 pt-0 pt-lg-3 pb-sm-4">
									<LeftPart />
								</Col>
								<Col sm={isTablet ? 6 : 5} id='rightside' className="pt-0 pt-sm-4 position-relative">
									<RightPart requestId={transactionId} email={updateDetails.client_email} />
								</Col>
							</>
						)}
					</Row>
				</div>
			</div>
		</div>
	)
}

export default Index;
