export const MENU_SIDEBAR = [
  {
    path: "/prayer_partners",
    title: "sidebar.prayer_partners.prayer_request.title",
  },
  {
    path: "/prayer_partners/notification",
    title: "sidebar.prayer_partners.notification.title",
    badge: true,
  },
  {
    path: "/prayer_partners/reports",
    title: "sidebar.prayer_partners.report.title",
  },
  {
    path: "/prayer_partners/payments",
    title: "sidebar.prayer_partners.payments.title",
  },
  {
    path: "/prayer_partners/account_settings",
    title: "sidebar.prayer_partners.account_settings.title",
  },
  {
    path: "/prayer_partners/contact_admin",
    title: "sidebar.prayer_partners.contact_admin.title",
  },
];
