import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";

import IntlMessage from "@iso/components/intlMessage";
import { itemLeavePeriodsDefault, DATA_FORMAT } from "./constants";

import "./styles.scss";

const LeavePeriods = ({
  setFieldValue,
  values,
  touched,
  errors,
  setValues,
  leavePeriods,
  setLeavePeriods,
  isEdit,
  title = "prayer_partners.account_settings.availability.leave_periods.title",
  subtitle = "prayer_partners.account_settings.availability.leave_periods.subtitle",
}) => {

  const time_zone = -(new Date().getTimezoneOffset() / 60);

  const addNewPeriod = () => {
    setLeavePeriods([...leavePeriods, ...itemLeavePeriodsDefault]);
  };

  const [isHide, setIsHide] = React.useState(false);

  const deletePeriod = (indexItem) => {
    const tmp = values?.day_off?.filter((i, index) => index !== indexItem);
    setValues({ ...values, day_off: tmp });

    const tmpView = leavePeriods.filter((i, index) => index !== indexItem);
    setLeavePeriods(tmpView);
  };

  const haveAnyPastLeaves = leavePeriods.find((period, index) => {
    const isFuture =
      period.end_date && moment.utc(period.end_date).utcOffset(time_zone) >= moment(new Date());
    return period.start_date !== null && !isFuture;
  });

  let featureLeavePeriods = leavePeriods.filter(
    (periods) =>
      (periods.end_date && moment.utc(periods.end_date).utcOffset(time_zone) >= moment(new Date())) ||
      periods.start_date === null
  );

  const renderItemPeriods = (periods, index, display = true) => {
    return (
      <div>
        {display && (
          <Form.Group as={Row} className="mb-3" key={index}>
            <Col sm="3">
              <p>
                <IntlMessage id="prayer_partners.account_settings.availability.leave_periods.text" />
              </p>
            </Col>
            <Col sm="9">
              <Form.Group as={Row}>
                {/* Start */}
                <Col sm="8" xs="12" md="8">
                  <Form.Group as={Row}>
                    <Col sm="6" md="6" className="input-calendar">
                      <Form.Label>
                        <IntlMessage id="prayer_partners.account_settings.availability.leave_periods.start_date" />
                      </Form.Label>
                      <Form.Control
                        disabled={!isEdit}
                        name={`day_off[${index}].start_date`}
                        onChange={(value) => {
                          let dateFormat = null;
                          if (value)
                            dateFormat = moment.utc(value).utcOffset(time_zone).format(DATA_FORMAT);
                          setFieldValue(
                            `day_off[${index}].start_date`,
                            dateFormat
                          );
                          setFieldValue(
                            `day_off[${index}].end_date`,
                            dateFormat
                          );
                        }}
                        as={DatePicker}
                        minDate={new Date()}
                        selected={
                          values?.day_off &&
                          values?.day_off.length > index &&
                          values?.day_off[index]?.start_date &&
                          new Date(values?.day_off[index]?.start_date)
                        }
                        dateFormat="MMMM dd, yyyy"
                      />
                    </Col>

                    {/* End */}
                    <Col
                      sm="6"
                      md="6"
                      className="input-calendar input_calendar_before"
                    >
                      <Form.Label>
                        <IntlMessage id="prayer_partners.account_settings.availability.leave_periods.end_date" />
                      </Form.Label>
                      <Form.Control
                        name={`day_off[${index}].end_date`}
                        disabled={
                          !(
                            values?.day_off &&
                            values?.day_off.length > index &&
                            values?.day_off[index]?.start_date
                          ) || !isEdit
                        }
                        onChange={(value) => {
                          let dateFormat = null;
                          if (value)
                            dateFormat = moment.utc(value).utcOffset(time_zone).format(DATA_FORMAT);
                          setFieldValue(
                            `day_off[${index}].end_date`,
                            dateFormat
                          );
                        }}
                        as={DatePicker}
                        minDate={
                          (values?.day_off &&
                            values?.day_off.length > index &&
                            new Date(values?.day_off[index]?.start_date)) ||
                          new Date()
                        }
                        selected={
                          values?.day_off &&
                          values?.day_off.length > index &&
                          values?.day_off[index]?.end_date &&
                          new Date(values?.day_off[index]?.end_date)
                        }
                        dateFormat="MMMM dd, yyyy"
                      />
                    </Col>
                  </Form.Group>
                </Col>

                {isEdit && (
                  <Col sm="4" xs="12" md="4" className="control pt-1">
                    <IconButton
                      size="small"
                      className="add-icon"
                      onClick={addNewPeriod}
                      disabled={!isEdit}
                    >
                      <AddIcon />
                    </IconButton>

                    {index != 0 && (
                      <IconButton
                        size="small"
                        onClick={() => deletePeriod(index)}
                        disabled={!isEdit}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Col>
                )}
              </Form.Group>
            </Col>
          </Form.Group>
        )}
      </div>
    );
  };

  return (
    <div className="leave-period">
      <h5>
        <IntlMessage id={title} />
      </h5>
      <p className="light-gray-color">
        <IntlMessage id={subtitle} />
      </p>
      {featureLeavePeriods.length >= 1 &&
        featureLeavePeriods.map((periods, index) => {
          const isFuture =
            periods.end_date && moment.utc(periods.end_date).utcOffset(time_zone) >= moment(new Date());

          if (periods.start_date === null) {
            return renderItemPeriods(periods, index, isEdit);
          } else if (isFuture) {
            return renderItemPeriods(periods, index, true);
          }
        })}
      {!isEdit && leavePeriods.length === 1 && (
        <p>
          <IntlMessage id="prayer_partners.account_settings.availability.leave_periods.no_registered_leave_period" />
        </p>
      )}

      {haveAnyPastLeaves && (
        <h6 className="hide-past-title" onClick={() => setIsHide(!isHide)}>
          {isHide ? (
            <IntlMessage id="prayer_partners.account_settings.availability.leave_periods.hide_past" />
          ) : (
            <IntlMessage id="prayer_partners.account_settings.availability.leave_periods.show_past" />
          )}
        </h6>
      )}
      <Form.Group
        as={Row}
        className={`hide-past-periods ${isHide ? "d-none" : ""}`}
      >
        {leavePeriods.map((period, index) => {
          const isFuture =
            period.end_date && moment.utc(period.end_date).utcOffset(time_zone) >= moment(new Date());
          if (period.start_date !== null && !isFuture) {
            return (
              <p key={index}>
                {moment.utc(period.start_date).utcOffset(time_zone).format("ll")} -{" "}
                {moment.utc(period.end_date).utcOffset(time_zone).format("ll")}
              </p>
            );
          }
        })}
      </Form.Group>
    </div>
  );
};

export default LeavePeriods;
