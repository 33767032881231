// Available severity: https://material-ui.com/components/snackbars/
//error
//warning
//info
//success

const confirmRequestModalActions = {
  SHOW_REGISTER_MODAL: "register_show_modal",
  HIDE_REGISTER_MODAL: "register_hide_modal",
  SHOW_CUSTOM_REGISTER_MODAL: "register_custom_show_modal",
  SHOW_CUSTOM_REGISTER_MODAL_MOBILE: "register_custom_show_modal_mobile",
  openModal: (data) => ({
    type: confirmRequestModalActions.SHOW_REGISTER_MODAL,
    data,
  }),
  closeModal: () => ({
    type: confirmRequestModalActions.HIDE_REGISTER_MODAL,
  }),
  openCustomModal: (data) => ({
    type: confirmRequestModalActions.SHOW_CUSTOM_REGISTER_MODAL,
    data,
  }),
  openCustomModalMobile: (data) => ({
    type: confirmRequestModalActions.SHOW_CUSTOM_REGISTER_MODAL_MOBILE,
    data,
  })
}

export default confirmRequestModalActions;
