const REQUEST_STEP_ACTIONS = {
  SET_FORM_DATA: "SET_FORM_DATA",
  RESET: "RESET",

  setDataStep: (data) => ({
    type: REQUEST_STEP_ACTIONS.SET_FORM_DATA,
    payload: {
      data,
    },
  }),

  resetData: (data) => ({
    type: REQUEST_STEP_ACTIONS.RESET,
  })
};

export default REQUEST_STEP_ACTIONS;
