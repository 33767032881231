import Select from "@material-ui/core/Select";
import {KeyboardArrowDown} from "@material-ui/icons";
import {Grid, MenuItem} from "@material-ui/core";
import React from "react";

const ViewItem = (props) => {
  return (
    <Grid item xs={props.cols} className="position-relative" style={{color: "#000"}}>
      <label
        className="custom-label MuiFormLabel-root MuiInputLabel-root
                            MuiInputLabel-formControl MuiInputLabel-animated account-form-label
                            MuiInputLabel-shrink MuiInputLabel-filled MuiFormLabel-filled"
        data-shrink="true"
      >
        { props.label }
      </label>
      <div className="view my-control2" style={{color: "#000"}}>
        { props.value }
      </div>
    </Grid>
  )
}

export default ViewItem;