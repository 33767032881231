import React, { Component, useState } from "react"
import IntlMessage from "@iso/components/intlMessage";
import FormContactUs from "./FormContactUs";
import { Row, Col, Button } from "react-bootstrap";
import CloudContactUs from "@iso/assets/images/CloudContactUs.svg"
import PrayerCloudLogo from "@iso/assets/images/PrayerCloudLogo.svg"
import { Link } from "react-router-dom"
import PublicFooter from "@iso/Website/Layout/PublicFooter"
import "./styles.scss"

const FormThankContactUs = (props) => {

    return (
      <div className = "contactUs-thank-container mt-5 mt-md-0">
        <h2 className = "contactUs-thank-header">
          <IntlMessage id = "contactUs.form.thank.header"/>
        </h2>

        <p className ="contactUs-thank-title">
          <IntlMessage id = "contactUs.form.thank.title"/>
        </p>


        <Link to="/">
          <Button className='form-control hippie-blue-button'>
            <b className ="font-size-20px"><IntlMessage id="common.homepage.title" /></b>
          </Button>
        </Link>
      </div>
    )
}

export default FormThankContactUs