import React from "react";
import styled from "styled-components";
import IntlMessage from "@iso/components/intlMessage";
import CandleLighting from "@iso/assets/images/CandleLighting.svg"

import "@iso/assets/common.scss";


const Wrapper = styled.div`
  
  font-family: "BrandonText";
  display: flex;
  flex-direction: column;
  margin-top: 48px;

  @media only screen and (min-width: 768px) {
    min-height: calc(407px - 48px);
    padding-left: 45px;
    padding-right: 61px; 
  }

  @media only screen and (min-width: 1025px) {
    min-height: calc(560px - 48px);
    padding-left: 90px;
    padding-right: 90px;
  }

  @media only screen and (min-width: 1440px) {
    min-height: calc(615px - 48px);
    padding-left: 148px;
    padding-right: 167px;
  }
`

const TitleWrapper = styled.div`

  > img {
    width: auto;
    height: 100%;
  }

  @media only screen and (min-width: 768px) {
    height: 61px;
  }

  @media only screen and (min-width: 1025px) {
    height: 96px;
  }
`
const SubTitleWrapper = styled.div`

  @media only screen and (min-width: 768px) {
    margin-top: 48px;

    > span:not(:first-child) {
      margin-top: 20px;
    }
  }

  @media only screen and (min-width: 1025px) {
    margin-top: 43px;
    max-width: 406px;
  }
`

const ContentSubtitle = styled.span`
  display: block;
  font-family: 'BrandonText';
  font-style: normal;
  color: #6D7278;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media only screen and (min-width: 1025px) {
    font-size: 22px;
    line-height: 34px;
  }
`
const RightSide = (props) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <img src={CandleLighting} className="d-none d-md-block" />
      </TitleWrapper>
      <SubTitleWrapper>
        <ContentSubtitle><IntlMessage id = {
          <>
            With this selection, your Prayer <br />
            Partner will light a candle on your <br />
            behalf while praying for you.
          </>
        } /></ContentSubtitle>
        <ContentSubtitle><IntlMessage id = "user.form.pricingpage.section2.reason.title2" /></ContentSubtitle>
      </SubTitleWrapper>
    </Wrapper>
  );
};

export default RightSide;
