import React from "react";
import { withRouter } from "react-router-dom";
import PrivateHeader from "./PrivateHeader";
import { MENU_SIDEBAR } from "./constants";
import PrivateLayout from "@iso/components/Layout/PrivateLayout";
import IntlMessage from "@iso/components/intlMessage";
import CheckUserRoles from "@iso/utils/CheckUserRoles";
import { USER_PERMISSIONS } from "@iso/constants";

const PrivatePrayerPartnerLayout = (props) => {
  CheckUserRoles.run({ roles: USER_PERMISSIONS.PARTNER.roles, hasNoti: false });
  return (
    <PrivateLayout
      component={props.component}
      headerComponent={PrivateHeader}
      key={props.location.pathname}
      title={<IntlMessage id="sidebar.requests" />}
      menuSidebar={MENU_SIDEBAR}
    />
  );
};

export default withRouter(PrivatePrayerPartnerLayout);
