import React, { Fragment } from "react";
import PublicHeader from "./PublicHeader";
import "./styles.scss";
import SignInModal from "./../Pages/SignIn/SignInModal";
import RegisterModal from "./../Pages/ReceiveConfirm/ReceiveYourConfirmModal";
import CheckYourEmailModal from "./../Pages/CheckEmail/CheckYourEmailModal";
import PublicFooter from "./PublicFooter";
import WelcomePrayerCloudModal from "@iso/Website/Pages/WelcomePrayerCloud/WelcomePrayerCloudModal";
import { isMobile } from "react-device-detect";
import MobileHeader from "@iso/components/Layout/Share/MobileHeader";
import BouncedEmailModal from 'components/Common/BouncedEmailModal'

const LandingLayout = (props) => {
  const Component = props.component;
  const {hasFooter = true, isAboutPage = false, isNoPadding = false} = props;
  return (
    <Fragment>
      <div className="app-main app_container container-lg fluid-container p-0">
        <div className="app-main__outer">
          <div className="app-main__inner">
            {!isMobile && <PublicHeader isBlue={true}></PublicHeader>}
            {isMobile && <MobileHeader isBlue={true} />}
            <div className={` ${!isMobile ? "row m-0" : "row custom_main_about"}`}>
              <div className={`col ${isNoPadding ? '' : 'p-0'}`}>
                <Component />
              </div>
            </div>
            {
              hasFooter && <PublicFooter isAboutPage={isAboutPage}></PublicFooter>
            }
          </div>
        </div>
      </div>
      <SignInModal />

      <RegisterModal></RegisterModal>

      <CheckYourEmailModal />

      <WelcomePrayerCloudModal />
      <BouncedEmailModal></BouncedEmailModal>
    </Fragment>
  );
};

export default LandingLayout;
