import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import FeedbackCard from "./FeedbackCard";
import ViewMoreIcon from "@iso/assets/images/ViewMore.svg";

const FEEDBACK_LIST = [
  "emily",
  "tuan",
  "alexis",
  "brett",
  "alexandra",
  "ellen",
];
const FeedbacksMobile = (props) => {
  const [isViewMore, setIsViewMore] = useState(false);
  const { feedbackList } = props;
  return (
    <div>
      <div className="feedback-container">
        <Row className="feedback-row">
          {FEEDBACK_LIST.map((author, index) => {
            const feedback = feedbackList[author];
            return (
              <Col>
                <FeedbackCard
                  className={index > 2 && !isViewMore ? "hidden" : ""}
                  avatar={feedback["avatar"]}
                  name={feedback["name"]}
                  address={feedback["address"]}
                  denomination={feedback["denomination"]}
                  introduction={feedback["introduction"]}
                  feedback={feedback["feedback"]}
                  leftCloud={feedback["leftCloud"]}
                  height={feedback["height"]}
                  width={feedback["width"]}
                ></FeedbackCard>
              </Col>
            );
          })}
        </Row>
      </div>
      {!isViewMore && (
        <div className="view-more">
          <p>view more</p>
          <img
            src={ViewMoreIcon}
            onClick={() => {
              setIsViewMore(true);
            }}
          ></img>
        </div>
      )}
    </div>
  );
};
export default FeedbacksMobile;
