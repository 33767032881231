import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import IntlMessage from "@iso/components/intlMessage";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
const PLACEHODLER_TEXT = {
  ENTER_NAME: "Enter your name",
  THEIR_EMAIL: "What is their email?",
  NAME: "What is your name?",
  THEIR_NAME: "What is their first name?",
  REASON: "Share the reason for your prayer directly with your Prayer Partner",
  SPECIAL_REQUEST:"Enter special requests (e.g., timing, passages, or prayers)"
};
const FieldAdapter = ({
  formikValues,
  setFormikValues,
  name,
  isDesktop,
  errors,
  touched,
  value,
  rows,
  className,
  inputProps,
  multiline,
  ...props
}) => {
  const onChangeField = (event) => {
    if (name === "recipient_name") {
      setFormikValues({
        ...formikValues,
        recipient_name: event.target.value,
        gift_message_name: event.target.value,
      });
    }
    if (name === "recipient_email") {
      setFormikValues({
        ...formikValues,
        recipient_email: event.target.value,
      });
    }
    if (name === "sender_name") {
      setFormikValues({
        ...formikValues,
        sender_name: event.target.value,
      });
    }
    if (name === "reason") {
      setFormikValues({
        ...formikValues,
        reason: event.target.value,
      });
    }
    if (name === "special_request"){
      setFormikValues({
        ...formikValues,
          prayer_customization: {
            ...formikValues.prayer_customization,
            special_request: event.target.value
          }
      })
    }
  };
  const setLabel = () => {
    if (name === "recipient_name") {
      return formikValues.for_who == "forsomeoneelse" &&
        formikValues.is_gift ? (
        formikValues.recipient_name ? (
          <IntlMessage id="public.request.form.name.label" />
        ) : (
          <>
            <span className="for-focus">
              {" "}
              <IntlMessage id="public.request.form.name.label" />
            </span>
            <span className="text-dark for-no-focus">
              {" "}
              <IntlMessage id="public.request.form.name.placeholder" />
            </span>
          </>
        )
      ) : formikValues.recipient_name ? (
        <IntlMessage id="public.request.form.yourname.label" />
      ) : (
        <>
          <span className="for-focus">
            {" "}
            <IntlMessage id="public.request.form.yourname.label" />
          </span>
          <span className="text-dark for-no-focus">
            {" "}
            <IntlMessage id="public.request.form.yourname.placeholder" />
          </span>
        </>
      );
    }
    if (name === "recipient_email") {
      return formikValues.for_who == "forsomeoneelse" &&
        formikValues.is_gift ? (
        formikValues.recipient_email ? (
          <IntlMessage id="public.request.form.their.email.label" />
        ) : (
          <>
            <span className="for-focus">
              {" "}
              <IntlMessage id="public.request.form.their.email.label" />
            </span>
            <span className="text-dark for-no-focus">
              {" "}
              <IntlMessage id="public.request.form.their.email.label" />
            </span>
          </>
        )
      ) : formikValues.recipient_email ? (
        <IntlMessage id="public.request.form.yourname.label" />
      ) : (
        <>
          <span className="for-focus">
            {" "}
            <IntlMessage id="public.request.form.their.email.label" />
          </span>
          <span className="text-dark for-no-focus">
            {" "}
            <IntlMessage id="public.request.form.their.email.label" />
          </span>
        </>
      );
    }
    if (name === "sender_name") {
      return formikValues.for_who == "forsomeoneelse" &&
        formikValues.is_gift ? (
        formikValues.sender_name ? (
          <IntlMessage id="public.request.form.yourname.placeholder" />
        ) : (
          <>
            <span className="for-focus">
              {" "}
              <IntlMessage id="public.request.form.yourname.placeholder" />
            </span>
            <span className="text-dark for-no-focus">
              {" "}
              <IntlMessage id="public.request.form.yourname.placeholder" />
            </span>
          </>
        )
      ) : formikValues.sender_name ? (
        <IntlMessage id="public.request.form.yourname.placeholder" />
      ) : (
        <>
          <span className="for-focus">
            {" "}
            <IntlMessage id="public.request.form.yourname.placeholder" />
          </span>
          <span className="text-dark for-no-focus">
            {" "}
            <IntlMessage id="public.request.form.yourname.placeholder" />
          </span>
        </>
      );
    }
    if(name === "reason"){
      return formikValues.reason ? (
        <IntlMessage id="public.request.form.reason.label" />
      ) : (
        <>
          <span className="for-focus">
            {" "}
            <IntlMessage id="public.request.form.reason.label" />
          </span>
          <p className="text-dark for-no-focus ">
            {" "}
            <IntlMessage id="public.request.form.reason.placeholder" />{" "}
          </p>
        </>
      )
    }
    if(name === "special_request"){
      return formikValues.prayer_customization.special_request ? <IntlMessage id="public.request.right.form.special_request.title" /> :
        <>
          <span className='for-no-focus'> <IntlMessage id="public.request.right.form.special_request.placeholder" /></span>
          <span className='for-focus'> <IntlMessage id="public.request.right.form.special_request.title" /> </span>
        </>
    }
  };
  const setPlaceholder = () => {
    if (name === "recipient_name") {
      return formikValues.for_who == "forsomeoneelse" && formikValues.is_gift
        ? PLACEHODLER_TEXT.THEIR_NAME
        : PLACEHODLER_TEXT.NAME;
    }
    if (name === "recipient_email") {
      return formikValues.for_who == "forsomeoneelse"
        ? PLACEHODLER_TEXT.THEIR_EMAIL
        : PLACEHODLER_TEXT.ENTER_NAME;
    }
    if (name === "sender_name") {
      return formikValues.for_who == "forsomeoneelse"
        ? PLACEHODLER_TEXT.NAME
        : PLACEHODLER_TEXT.ENTER_NAME;
    }
    if(name === "reason") return PLACEHODLER_TEXT.REASON;
    if(name === "special_request") return PLACEHODLER_TEXT.SPECIAL_REQUEST;
    return "";
  };
  return (
    <>
      <TextField
        fullWidth={true}
        variant="filled"
        className={className}
        value={value}
        placeholder={setPlaceholder()}
        onChange={(event) => onChangeField(event)}
        label={setLabel()}
        multiline={multiline}
        rows={rows ? rows : 1}
        name={name}
        inputProps={inputProps}
        {...props}
      />
      <ErrorMessageWrapper touched={touched} errors={errors} fieldName={name} />
    </>
  );
};
export default FieldAdapter;
