import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as qs from "query-string";
import AuthService from "@iso/services/AuthService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { setCurrentUser } from "@iso/actions/User";
import { useDispatch } from "react-redux";
import { history } from "@iso/helpers";
import alertActions from "@iso/actions/Alert"
import IntlMessage from "@iso/components/intlMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "calc(100vh - 330px - 80px)",
    position: "relative",
  },
  circular: {
    position: "absolute",
    top: "calc(50% - 50px)",
    left: "calc(50% - 20px)",
    transform: "translate(-50%, -50%)",
  },
}));

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { token, user_id } = qs.parse(window.location.search);

  React.useEffect(() => {
    if (token && user_id) {
      AuthService.verifyUser(token, user_id).then((res) => {
        ProcessRequest.run({
          res,
          hasNoti: true,
          successCallback: (data) => {
            dispatch(setCurrentUser(data.data));
            history.push("/");
          },
        });
      });
    }else {
      dispatch(alertActions.openAlertError({
        message: <IntlMessage id="verify.token.invalid"/>
      }))
      history.push("/");
    }
  }, [token, user_id]);

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.circular} />
    </div>
  );
};

export default VerifyEmail;
