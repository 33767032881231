// Available severity: https://material-ui.com/components/snackbars/
//error
//warning
//info
//success

const notificationActions = {
  UPDATE_NOTI: "update_notification",
  NOT_UPDATE_NOTI: "not_update_notification",
  updateNoti: (data) => ({
    type: notificationActions.UPDATE_NOTI,
    data
  }),
  notUpdateNoti: () => ({
    type: notificationActions.NOT_UPDATE_NOTI,
  })
}

export default notificationActions;