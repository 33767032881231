import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import RequestItem from "@iso/components/Request/Item";
import { localTimezone } from "@iso/utils/GetLocalData";
import { DISPLAY_COMPLETED_INFO } from "@iso/constants";
import { Link } from "react-router-dom";
import moment from "moment";
import { timezones } from "@iso/constants";

const RequestPrayInfo = ({ request }) => {
  const displayContent = !DISPLAY_COMPLETED_INFO.find(
    (status) => status === request.status
  );
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  return (
    <>
      {displayContent && (
        <Container className="mt-5">
          <Row className="mt-3">
            <Col xs="12" sm="4" md="4" lg="3" className="bullet">
              <span className="font-weight-bold">
                <IntlMessage id="admin.requests.details.current_assignee" />
              </span>
            </Col>
            {(request.prayer_partner?.first_name ||
              request.prayer_partner?.last_name) && (
              <Col xs="12" sm="8" md="8" lg="9">
                <p
                  className="request_item_value_css"
                  style={{ display: "inline-block", paddingRight: "10px" }}
                >
                  {`${request.prayer_partner?.first_name} ${request.prayer_partner?.last_name}`}
                </p>
                <Link
                  className="non-underline-link"
                  to={`/admin/prayer_partners/${request.prayer_partner_id}/account_settings`}
                  style={{ color: "#499e9e", textDecoration: "underline" }}
                >
                  View Profile
                </Link>
              </Col>
            )}
          </Row>

          <RequestItem
            title={<IntlMessage id="admin.requests.details.round" />}
            value={request.round}
          />

          <RequestItem
            title={<IntlMessage id="admin.requests.details.denomination" />}
            value={request.denomination_label}
          />

          <RequestItem
            title={
              <IntlMessage id="admin.requests.details.assignee_local_time" />
            }
            value={`${moment().utcOffset(request?.prayer_partner?.timezone || time_zone ).format("LT")} (${
              timezones[
                request?.prayer_partner?.timezone || localTimezone().code
              ]
            })`}
          />
        </Container>
      )}
    </>
  );
};

export default RequestPrayInfo;
