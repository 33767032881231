import React from "react"; 
import {  Button } from "react-bootstrap"
import IntlMessage from "@iso/components/intlMessage";
import { Formik, Form } from "formik";
import {AddformValidator, EditPPFormValidator, EditUserFormValidator} from './RecordFormValidator' 
import FinancialService from "@iso/services/FinancialService";
import RecordUserForm from "./RecordUserForm"
import RecordPPForm from "./RecordPPForm"
import { useDispatch } from "react-redux";
import alertActions from "@iso/actions/Alert";
import "./RecordModal.scss"

const AddRecordForm = (props) => {
  const {requestIdList, prayerPartnerList, isEditBtn = false, statusList, editValue, handleSubmit,setEdit } = props; 
  const { openAlert } = alertActions;
  const dispatch = useDispatch();
  let initValue = {
    user_id: editValue?.user_id || '',
    requestID: editValue?.id || '',
    prayer_request_id: editValue?.prayer_request_id || '',
    amount_owed: editValue?.amount_owed || '',
    status: editValue?.status || '',
    note: editValue?.note || '', 
    client: editValue?.prayer_request?.client?.full_name || '',
    amount: editValue?.amount || '0',
    prayer_partner:editValue?.prayer_request?.prayer_partner?.full_name || '',
    role: props.filter,
    type: editValue?.type,
  }
  const [formikValues, setFormikValues] = React.useState(initValue) 
  const handleChangeRequestID = (id) => {
  
    FinancialService.adminFinancialGetDetails(id).then((res) => {
      if(res){
        const { code, data } = res.data;
        if( code === 200){
          setFormikValues(data)
        }
      }
    }) 
  }
  
  const handleUpdate = (data, filter) => {
    FinancialService.adminUpdateDetailsFinancial(data, filter).then((res) => {
      if(res){
        const { code, data } = res.data;
        if( code === 200){
          setFormikValues(data)
          props.SetClickSubmit(!props.clickSubmit)
          setEdit(false) 
          dispatch(
            openAlert({
              message: <IntlMessage id="common.message.update.success" />,
              severity: "success",
            })
          )
        }else{
          dispatch(
            openAlert({
              message: <IntlMessage id="common.message.update.fail.transaction" />,
              severity: "error",
            })
          )
        }
      }
    })
  }
  const handleFieldChange = (name, value) =>{ 
    formikValues[name] = value;
    setFormikValues({ ...formikValues  }); 
  }

  return(
       <Formik
        initialValues={formikValues}
        enableReinitialize={true}
        onSubmit={(values, formikHelper) => { 
          handleSubmit(values)
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={!isEditBtn? AddformValidator: 
          formikValues?.type === 0 ?
          EditUserFormValidator : EditPPFormValidator}
        >
        {({  handleChange, touched, errors }) => (
        <Form noValidate> 
        {
          formikValues?.type === 0 ? 
           <RecordUserForm 
           formikValues={formikValues}
           handleFieldChange={handleFieldChange}
           handleChangeRequestID={handleChangeRequestID}
           requestIdList={requestIdList}
           touched={touched}
           errors={errors}
           />
          :
          <RecordPPForm 
           formikValues={formikValues}
           handleFieldChange={handleFieldChange} 
           requestIdList={requestIdList}
           prayerPartnerList={prayerPartnerList}
           isEditBtn={isEditBtn}
           statusList={statusList}
           touched={touched}
           errors={errors}
          />
        }
       
      {/* Save button */}
        <div className="buttons text-center mt-4">
          {
            !isEditBtn && 
            <Button className="hippie-blue-button" type="submit">
              <IntlMessage id="common.save" />
            </Button>
          }
          
          {
            isEditBtn &&  formikValues?.type !== 0 &&
            <Button className="hippie-blue-button" type="button" onClick={() => handleUpdate(formikValues, 'partner')}>
              <IntlMessage id="common.save" />
            </Button>
          }

          {
            isEditBtn &&  formikValues?.type === 0 &&
            <Button className="hippie-blue-button" type="button" onClick={() => handleUpdate(formikValues, 'user')}>
              <IntlMessage id="common.save" />
            </Button>
          }
        </div>
      </Form>
         )}
      </Formik>
  
  )
}

export default AddRecordForm;