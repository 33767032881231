// Available severity: https://material-ui.com/components/snackbars/
//error
//warning
//info
//success

const signInActions = {
  SHOW_MODAL: "show_modal",
  HIDE_MODAL: "hide_modal",
  openModal: (data) => ({
    type: signInActions.SHOW_MODAL,
    data: data,
  }),
  closeModal: (data) => ({
    type: signInActions.HIDE_MODAL,
    data: data,
  })
}

export default signInActions;