export const COLOR_CODE = {
  unassigned: "#ff9900",
  incomplete: "#6aa84f",
  declined: " #999999",
  paused: " #999999",
  overdue: "#990000",
  overdue_done: "#ff9900",
  overdue_done_approved: "#009e0f",
  approved: "#009e0f",
  completed: "#999999",
};

export const DATE_FORMAT_PRAYER_REQUEST = "MMMM Do, YYYY";
export const RESPONDED_STATUS = ["completed"]

export const CATEGORIES = [
  { id: 'user', label: "I’m a Prayer Cloud user", role: 'users'},
  { id: 'interested', label: "I’m interested in using Prayer Cloud", role: 'users'},
  { id: 'learn', label: "I want to learn more about Prayer Cloud", role: 'users'},
  { id: 'partner', label: "I’m a Prayer Partner",role: 'users'},
  { id: 'to_be_partner', label: 'I want to be a Prayer Partner',role: 'users'},
  { id: "payments", label: "Payments", role: 'prayer_partners' },
  { id: "availability", label: "Availability", role: 'prayer_partners' },
  { id: "technical", label: "Technical", role: 'prayer_partners' },
  { id: "prayer_support", label: "Prayer Support", role: 'prayer_partners' }, 
  { id: 'other', label: 'Other'},
];

