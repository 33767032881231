import React from "react";
import styled from "styled-components";
import TitleImg from "@iso/assets/images/landing_words_title.svg";
import TitleImgMobile from "@iso/assets/images/landing_words_title_mobile.svg";
import QuoteItem from "./QuoteItem";
import QuoteCarousel from "./QuoteCarousel";
import { isMobile, isTablet } from "react-device-detect";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  margin-bottom: 25px;
  font-family: "BrandonText";
  margin-bottom: 35px;
  
  @media only screen and (min-width: 1920px) {
    padding-left: 200px !important;
    padding-right: 200px !important;
    margin-top: 85px !important;
    margin-bottom: 50px !important;
  }

  @media only screen and (max-width: 700px) {
    padding: 30px 36px;
    margin-top: 75px;
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 500px) {
    padding: 30px 31px;
  }
  @media only screen and (max-width: 375px) {
    margin-top: 84px;
  }
`;

const Title = styled.img`
  @media only screen and (min-width: 376px) and (max-width: 500px){
    height: 83px;
  }
`;

const QuoteContainer = styled.div`
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: -30px;
  }
`;

const QuoteList = [
  {
    quote1: "“Sharing your faith is so much ",
    quote2: "better than sending flowers.”",
    author: "Mary,",
    location: "Dallas, Texas",
  },

  {
    quote1: "“It’s really nice to give a gift ",
    quote2:"and give back at the same time.”",
    author: "Sara,",
    location: "Fort Meyers, Florida",
  },

  {
    quote1: "“The most thoughtful gift I’ve ",
    quote2: "ever received. Hands down.”",
    author: "Josh,",
    location: "Scottsdale, Arizona",
  },
];

const QuoteListMobile = [
  {
    quote1: "“Sharing your faith is so much ",
    quote2: "better than sending flowers.”",
    author: "Mary,",
    location: "Dallas, Texas",
  },

  {
    quote1: "“It’s really nice to give a gift ",
    quote2:"and give back at the same time.”",
    author: "Sara,",
    location: "Fort Meyers, Florida",
  },

  {
    quote1: isTablet ? "“The most thoughtful gift I’ve" : "“The most thoughtful gift",
    quote2: isTablet ? " ever received. Hands down.”" : " I’ve ever received. Hands down.”",
    author: "Josh,",
    location: "Scottsdale, Arizona",
  },
];

const Section6 = (props) => {
  return (
    <Wrapper>
      <Title className="d-none d-md-block" src={TitleImg}></Title>
      <Title className="d-md-none" src={TitleImgMobile}></Title>
      <QuoteContainer>
        <QuoteCarousel items={isMobile? QuoteListMobile :QuoteList}></QuoteCarousel>
        {/* <QuoteItem quote="The most thoughtful gift I’ve ever received. Hands down." author="Josh" location="Scottsdale, Arizona"></QuoteItem> */}
      </QuoteContainer>
    </Wrapper>
  );
};

export default Section6;
