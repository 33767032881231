import React, { useState, useEffect } from "react";
import {
  ElementsConsumer,
  CardElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import CardSection from "./CardSection";
import stripeActions from "@iso/actions/Stripe";
import { setCardData } from "@iso/actions/CardDataActions";
import { useDispatch } from "react-redux";
import axios from "axios";
import StripeConfirmModal from "./StripeConfirmModal";
import StripeService from "@iso/services/StripeService";
import UserService from "../../services/UserService";

const { endTriggerStripe, setToken, setCardToken, setSaveCard } = stripeActions;

const CheckoutForm = (props) => {
  const { showForm } = props;
  const stripeTrigger = useSelector((state) => state.stripe.stripeTrigger);
  const saveCard = useSelector((state) => state.stripe.saveCard);
  const paymentIntentResponse = useSelector((state) => state.stripe.paymentIntentResponse);
  const { stripe, elements, handleStripeError, price } = props;
  const dispatch = useDispatch();
  const requestStepData = useSelector((state) => state.requestStepReducer);
  const [stripeConfirm, setStripeConfirm] = React.useState(false);
  const [paymentIntent, setPaymentIntent] = React.useState("");
  const { loggedIn } = useSelector((state) => state.authentication);
  useEffect(() => {
    if (stripeTrigger) {
      function triggerStripe() {

        if (paymentIntentResponse) {
          handleConfirmStripe(paymentIntentResponse)
        } else {
          submitStripe();
        }
      }
      triggerStripe();
    }
  }, [stripeTrigger]);

  const handleConfirmStripe = (paymentIntent) => {
    if (paymentIntent?.status == "succeeded") {
      dispatch(setCardToken(paymentIntent?.id));
      dispatch(endTriggerStripe());
    }
    else if (paymentIntent?.status == "requires_action") {

      setPaymentIntent(paymentIntentResponse?.client_secret)
      setStripeConfirm(true);
      var iframe = document.createElement("iframe");
      iframe.src = paymentIntent?.next_action?.redirect_to_url?.url;

      iframe.id = "stripe-iframe";
      let t = setInterval(function () {
        if (document.getElementById("confirm-stripe-modal")) {
          document
            .getElementById("confirm-stripe-modal")
            .append(iframe);
          clearInterval(t);
        }
      }, 1000);
    } else {
      console.log(paymentIntent);
    }
  }

  const submitStripe = async () => {
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      handleStripeError(error);
      dispatch(endTriggerStripe());
    } else {
      dispatch(setToken(paymentMethod.id));
      dispatch(setCardToken(paymentMethod.id));
      // dispatch(setCardToken(cardToken.token.id));
      dispatch(endTriggerStripe());
      saveCardStripe();
    }
  };

  const saveCardStripe = async () => {
    // Save card success
    if (saveCard) return;

    const saveCardElement = elements.getElement(CardNumberElement);
    const saveCardToken = await stripe
      .createToken(saveCardElement, {
        name: name,
      })
      .then((token, error) => {
        if (error) {
          handleStripeError(error);
        }

        return token;
      });

    let saveCardID = saveCardToken?.token?.id;

    if (requestStepData?.is_save_card && saveCardID) {
      if (loggedIn) {
        UserService.addCardToStripe({
          token: saveCardID,
          is_default_card: false,
        }).then((res) => {
          dispatch(setSaveCard(true))
        });
      } else {
        dispatch(setCardData(false, saveCardID))
      }
    }
  }

  if (typeof window.t2 == "undefined") window.t2 = null;

  const on3DSComplete = () => {
    // Hide the 3DS UI
    setStripeConfirm(false);

    if (!paymentIntentResponse?.client_secret) return;
    if (!stripe) return;

    window.t2 = setTimeout(function () {
      clearInterval(window.t2);
      // Check the PaymentIntent
      stripe.retrievePaymentIntent(paymentIntentResponse?.client_secret).then(function (result) {
        if (result.error) {
          // PaymentIntent client secret was invalid
        } else {
          if (result.paymentIntent.status === "succeeded") {
            // Show your customer that the payment has succeeded
            dispatch(setCardToken(result.paymentIntent.id));
          } else if (
            result.paymentIntent.status === "requires_payment_method"
          ) {
            // Authentication failed, prompt the customer to enter another payment method
            handleStripeError({
              message: "The provided PaymentMethod has failed authentication.",
              //message: result.paymentIntent.last_payment_error.message
            });
          }
          dispatch(endTriggerStripe());
        }
      });
    }, 200);
  };

  window.addEventListener('message', function (ev) {
    if (ev.data === '3DS-authentication-complete') {
      on3DSComplete();
    }
  }, false);

  const [name, setName] = React.useState("");

  return (
    <>
      {
        showForm && <CardSection
          name={name}
          setName={setName}
        />
      }
      <StripeConfirmModal open={stripeConfirm} />
    </>
  );
};

export default function InjectedCheckoutForm(props) {
  const { handleStripeError, price, showForm } = props;
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          handleStripeError={handleStripeError}
          stripe={stripe}
          showForm={showForm}
          elements={elements}
          price={price}
        />
      )}
    </ElementsConsumer>
  );
}
