import React, { Fragment } from "react";
import PublicHeader from "./PublicHeader";
import "./styles.scss";
import SignInModal from "./../Pages/SignIn/SignInModal";
import RegisterModal from "./../Pages/ReceiveConfirm/ReceiveYourConfirmModal";
import CheckYourEmailModal from "./../Pages/CheckEmail/CheckYourEmailModal";
import MobileHeader from "@iso/components/Layout/Share/MobileHeader";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router"

const PublicLayout = (props) => {
  const Component = props.component;
  const location = useLocation(); 
  return (
    <>
      <div className="app-main app_container container-lg fluid-container p-0">
        <div className="app-main__outer">
          <div className="app-main__inner">
            {!isMobile && <PublicHeader></PublicHeader>}
            {
              // contactUs has its own sidebar menu
                isMobile && <MobileHeader/>
            }
            <div className="row m-0 wrapper">
              <div className="col p-0">
                <Component />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignInModal />

      <RegisterModal></RegisterModal>

      <CheckYourEmailModal />
    </>
  );
};

export default PublicLayout;
