import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Leftside from "./Leftside";
import RightSide from "./Rightside";
import TruncatedCloud from "@iso/assets/images/TruncatedCloud.svg";
import LeftSideCloudTablet from "@iso/assets/images/LeftSideCloudTablet.svg";
import CloudPrayerPathnerBottom from "@iso/assets/images/CloudPrayerPathnerBottom.svg";
import { isMobile, isTablet, isMobileSafari } from "react-device-detect";

const Wrapper = styled(Grid)`
  position: relative;
`;

const Section3 = (props) => {
  const isDesktop = !isMobile && !isMobileSafari && !isTablet;

  return (
    <Wrapper container spacing={0}>
      <Grid item xs={12} sm={6} className="left-side-container">
        <Leftside></Leftside>
        <img
          className="truncated-cloud"
          src={
            isDesktop
              ? CloudPrayerPathnerBottom
              : isTablet
              ? LeftSideCloudTablet
              : TruncatedCloud
          }
        ></img>
      </Grid>
      <Grid item xs={12} sm={6}>
        <RightSide></RightSide>
      </Grid>
    </Wrapper>
  );
};

export default Section3;
