import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;
`;
const Quote = styled.div`
  font-size: 36px;
  text-align: center;
  width: 100%;
  margin-top: 12px;
  font-weight: bold;  
  @media only screen and (max-width: 700px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 500px) {
    margin-top: 5px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    font-size: 24px;
  }
  @media only screen and (min-width: 1300px) {
    width: 100%; 
  }
`;
const Quote2 = styled.div`
  font-size: 36px;
  text-align: center;
  width: 100%;
  margin-top: 12px;
  font-weight: bold;  
  @media only screen and (max-width: 700px) {
    font-size: 22px;
  } 
  @media only screen and (max-width: 500px) {
    margin-top: 5px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    font-size: 24px;
  }
  @media only screen and (min-width: 1300px) {
    width: 100%; 
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    margin-top: -4px;
  }
`;

const Author = styled.p`
  text-align: center;
  width: 100%;
  margin-top: 18px;
  font-size: 18px !important;
  @media only screen and (max-width: 375px) {
    margin-top: 45px;
    margin-bottom: 13px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    margin-top: 57px;
    margin-bottom: 13px;
    font-size: 19px !important;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    margin-top: 28px;
  }
`;

const Location = styled.span`
  font-size: 16px;
`;

const QuoteItem = (props) => {
  const { quote1, quote2, author, location } = props;

  return (
    <Wrapper>
      <Quote style={{marginTop: "15px"}}>{quote1}</Quote>
      <Quote2>{quote2}</Quote2>
      <Author>
        <b>{author} </b>
        <Location>{location}</Location>
      </Author>
    </Wrapper>
  );
};

export default QuoteItem;
