import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import RecordForm from "./RecordForm";
import IntlMessage from "@iso/components/intlMessage";
import FinancialService from "@iso/services/FinancialService";
import { STATUS } from '../constants'
import "./RecordModal.scss"

const AddRecordModal = (props) => {
  const { open, handleClose, isEditBtn = false, editValue, handleSubmit,filter, setEdit } = props;
  const [requestIdList,setRequestIdList] = React.useState([])
  const [PrayerPartnerList,setPrayerPartnerList] = React.useState([]) 
  const [UserList,setUserList] = React.useState([])

  useEffect(() => {
    try{
      FinancialService.adminFinancialGetRequestIDList().then((res) => {
        if(res){
          const { code , data } = res.data;
          setRequestIdList(data);  
        }
      })
    }catch(errors){

    }finally{
    }

  },[])

  useEffect(() => {
    try{
      FinancialService.adminFinancialGetPrayerPartnerList().then((res) => {
        if(res){
          const { code , data } = res.data;  
          setPrayerPartnerList(data); 
        }
      })
    }catch(errors){

    }finally{
    }
  }, [])

  return (
    <div>
      <Modal show={open} onHide={handleClose} className="add_record_wrapper">
        <Modal.Header closeButton>
          <Modal.Title>
            {
              isEditBtn ? <IntlMessage id="admin.financial.edit_record.title"/>
              : <IntlMessage id="admin.financial.add_record.title"/>
            }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <RecordForm 
            requestIdList={requestIdList}
            prayerPartnerList={PrayerPartnerList} 
            statusList={STATUS}
            isEditBtn={isEditBtn}
            editValue={editValue}
            handleSubmit={handleSubmit}
            filter={filter} 
            setEdit={setEdit}
            SetClickSubmit={props.SetClickSubmit}
            clickSubmit={props.clickSubmit}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddRecordModal;
