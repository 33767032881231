import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQAccordionStyle from "./AccordionStyle";
import {useDispatch, useSelector} from "react-redux";
import actionsSignIn from "@iso/actions/signinmodal";
import {isTablet} from "react-device-detect";
import {history} from "@iso/helpers";
import './faq_common.scss';

const AccountSettingsFAQ = (props) => {
  const { handleChange, expanded } = props;
  const classes = FAQAccordionStyle();
  const { user } = useSelector((state) => state.authentication)
  const dispatch = useDispatch();

  const handleAccountPage = () => {
    if(!user) {
      dispatch(
        actionsSignIn.openModal({
          type: actionsSignIn.SHOW_MODAL,
        })
      );
    }else {
      history.push("/account?tab=0")
    }
  }


  return (
    <>
      <Accordion
        className={`${classes.accordion} ${classes.expanded} account-setting`}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          className={classes.headingContainer}
          expandIcon={
            <ExpandMoreIcon style={{ fill: "#AFDAF5" }} fontSize="large" />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Account Settings</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            {`How do I update my account
            information?`}
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {isTablet
              ?
                `Once you are signed in, click on your name on the top right corner of the home page.
                 This will take you to your Account Page. Click the edit button on the top right of
                 the profile page to make changes to your profile.`
              :
                `Once you are signed in, click on your
                name on the top right corner of the
                home page. This will take you to your
                Account Page. Click the edit button
                on the top right of the profile page to
                make changes to your profile.`
            }
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
           {`How can I disconnect my account
           from Google or Facebook?`}
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`You can do this through your Google
            or Facebook account. As a result, you
            may lose information saved in your
            account, as you will need to sign up
            for a new account using your email
            address and your own password. We
            will do our best to link your new
            account to your old account;
            however, we cannot guarantee this
            and therefore ask that you make this
            decision with caution.`}
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            {`How do I update the email address in
            my account?`}
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
           {`Once you are signed in, click on your
           name, on the top right corner of the
           home page. This will take you to your `}<span className="text-hippie-blue hover_pointer" onClick={handleAccountPage}>Account Page</span>{`. Click on the edit
           button in the top right corner to
           make a change or other information
           listed in your profile.`}
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            How do I deactivate my account?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`When you deactivate your account,
            you will lose access to all of your
            previous prayers, prayer requests,
            etc. This action cannot be undone. If
            you have any feedback, or if there are
            any improvements that we can make
            to help you, please write to `}
            <a href="mailto:support@prayercloud.com" className="text-hippie-blue">support@prayercloud.com</a>. {`If you are
            sure you want to deactivate your
            account, you can find a link to do so
            after clicking on the settings tab of
            your `} <span className="text-hippie-blue hover_pointer" onClick={handleAccountPage}>Account Page</span>.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AccountSettingsFAQ;
