import React, { useState, useEffect } from "react";
import { Row, Col, Button, Badge, Card } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import {MDBBadge, MDBDataTable} from "mdbreact";
import moment from "moment";
import { history } from "@iso/helpers/history";

import { DATE_FORMAT_PRAYER_REQUEST, SEARCHABLE_FIELDS } from "@iso/constants";
import FlagIcon from "@material-ui/icons/Flag";
import "./styles.scss";
import RequestService from "@iso/services/RequestService";
import { translate } from "@iso/utils/Translate";
import { debounce } from "lodash";
import { InputGroup, FormControl } from "react-bootstrap";

const Home = () => {
  const tabArray = [
    "unassigned",
    "incomplete",
    "overdue",
    "paused",
    "completed",
    "approved",
    "archived",
  ];
  const [requestLists, setRequestLists] = useState({});
  const [currentStatus, setCurrentStatus] = useState("unassigned");
  const [searchValue, setSearchValue] = useState("");
  const [counts, setCounts] = useState({});
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  const headers = {
    unassigned: ["request_id", "sub_request_id", "id", "name", "recipient_email", "frequency", "due_date", "assignee", "round"],
    paused: ["request_id","sub_request_id",  "id", "name", "recipient_email", "frequency", "due_date", "flagged_by", "type"],
    other: ["request_id", "sub_request_id", "id", "name", "recipient_email", "frequency", "due_date", "assignee"],
  };

  useEffect(() => {
    handlePrayerRequests("unassigned");
    return;
  }, []);

  const handleClick = (id) => {
    history.push(`/admin/requests/${id}`);
  };

  const formatTableData = (data, status) => {
    let dataRow = [];
    let header = (headers[status] || headers.other).map((field) => {
      return {
        label: <IntlMessage id={`admin.requests.table_header.${field}`} />,
        field: field,
        sort: field == "round" ? "desc" : "asc",
        searchable: SEARCHABLE_FIELDS.includes(field),
      };
    });

    data.forEach((e, index) => {
      if (e.flag_type) {
        e.flag_type.sort();
      }
      else {
        e.flag_type = [];
      }
      
      dataRow.push({
        request_id: e.transaction_parent?.id || (e.transaction_user?.id || ''),
        sub_request_id: e.transaction_partner?.id || '',
        id: e.id,
        name: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.client_name ? e.client_name.toLocaleLowerCase() : ''}
          >
            {
              e.client_name
            }
          </MDBBadge>
        ),
        recipient_email: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.recipient_email}
          >
            {
              e.recipient_email
            }
          </MDBBadge>
        ),
        frequency: e.frequency_label,
        due_date: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.end_date}
          >
            {moment.utc(e.end_date_utc).utcOffset(time_zone).format(DATE_FORMAT_PRAYER_REQUEST)}
          </MDBBadge>
        ),
        flagged_by: (
          <IntlMessage id={`admin.requests.flagged_by.${e?.flag_by}`} />
        ),
        type:(
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={`${e?.flag_type?.length} ` + (e?.flag_type[0] ? e.flag_type[0] : '')}
          >
            {
              e.flag_type &&
              e.flag_type.map((flag) => {
                return (
                  <FlagIcon key={flag} className={`request_flagged ${flag}`} />
                );
              })
            }
          </MDBBadge>
        ),
        assignee: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e?.prayer_partner?.full_name ? e.prayer_partner.full_name.toLocaleLowerCase() : ''}
          >
            {
              e?.prayer_partner?.full_name
            }
          </MDBBadge>
        ),
        round: e.round,
        clickEvent: () => handleClick(e.id),
      });
    });

    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    };
  };

  const handlePrayerRequests = (status, options) => {
    RequestService.adminGetRequests(status, options).then((res) => {
      if (res) {
        const { code, data, count } = res.data;
        if (res && code === 200) {
          var request_lists = formatTableData(data.data, status);
          setRequestLists(request_lists.data);
          setCurrentStatus(status);
          setCounts(count);
          if (request_lists.data.rows.length == 0) {
            const tbody = document.querySelector(
              "tbody[data-test=table-body] tr td"
            )
            if(tbody) tbody.innerText = `You have no ${status} request.`;
            // <IntlMessage
            //   id="admin.requests.have_no_request"
            //   values={{ status: status }}
            // />
          }
        }
      }
    });
  };

  const debounceSearch = React.useCallback(
    debounce((status, options) => {
      handlePrayerRequests(status, options);
    }, 500),
    []
  );

  React.useEffect(() => {
    debounceSearch(currentStatus, { search: searchValue });
  }, [searchValue]);

  return (
    <div className="mint-tulip-background p-3 admin-request-list-wrapper">
      <Row>
        <Col sm="6" className="text-md-left text-center mb-3">
          <h2>
            <IntlMessage id="admin.requests.title" />
          </h2>
        </Col>
      </Row>

      <Card>
        <Row className="mt-3 mb-3 pl-5 pr-5 row">
          {tabArray.map((tab) => {
            return (
              <Col sm={{ span: "4" }} className="mb-1" key={tab}>
                <Button
                  variant={`${
                    currentStatus == tab ? "active-status" : "light"
                  } btn-block`}
                  onClick={() => {
                    handlePrayerRequests(tab, { search: searchValue });
                  }}
                >
                  <IntlMessage id={`admin.requests.tab.${tab}`} />
                  <Badge
                    className={`ml-2 ${
                      tab == "paused" || tab == "completed"
                        ? ""
                        : "badge-background"
                    }`}
                    pill
                    variant="danger"
                  >
                    {counts ? (counts[tab] == 0 ? "0" : counts[tab]) : 0}
                  </Badge>
                </Button>
              </Col>
            );
          })}
        </Row>
        <Row className="ml-2 mr-2">
          <Col sm="12">
            <Row>
              <Col className="px-4 px-md-0 col-md-4 input_search_data_table" l>
                <InputGroup>
                  <FormControl
                    value={searchValue}
                    placeholder={translate(
                      "admin.requests.search.placeholder"
                    )}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <MDBDataTable
              hover
              paging={true}
              id="requests_list_table"
              entries={25}
              searching={false}
              noBottomColumns={true}
              entriesOptions={[5, 20, 25]}
              data={requestLists}
              className="hide_scrollbar"
              sortRows={['due_date', 'name', 'assignee', 'type']}
              onSearch={(e) => {
                setSearchValue(e);
              }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Home;
