const stripeActions = {
  TRIGGER_STRIPE: "TRIGGER_STRIPE",
  SUBMITTING_STRIPE: "SUBMITTING_STRIPE",
  END_TRIGGER_STRIPE: "END_TRIGGER_STRIPE",
  SET_TOKEN: "SET_TOKEN",
  SET_CARD_TOKEN: "SET_CARD_TOKEN",
  SET_STRIPE_ERRORS: "SET_STRIPE_ERRORS",
  SET_SAVE_CARD: "SET_SAVE_CARD",

  setStripeErrors: (errors) => ({
    type: stripeActions.SET_STRIPE_ERRORS,
    payload: { errors },
  }),
  setLoadingPayment: (value) => ({
    type: stripeActions.SUBMITTING_STRIPE,
    payload: { flag: value },
  }),
  triggerStripe: (value = null) => ({
    type: stripeActions.TRIGGER_STRIPE,
    payload: { paymentIntentResponse: value },
  }),
  endTriggerStripe: () => ({
    type: stripeActions.END_TRIGGER_STRIPE,
  }),
  setToken: (token) => ({
    type: stripeActions.SET_TOKEN,
    token,
  }),
  setCardToken: (cardToken) => ({
    type: stripeActions.SET_CARD_TOKEN,
    cardToken,
  }),
  setSaveCard: (saveCard) => ({
    type: stripeActions.SET_SAVE_CARD,
    saveCard,
  }),
};
export default stripeActions;
