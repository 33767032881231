import FooterMail from "@iso/assets/images/footer_mail.svg";
import FooterInstagram from "@iso/assets/images/instagramLogo.svg";
import FooterFacebook from "@iso/assets/images/footer_facebook.svg";
import FooterTwitter from "@iso/assets/images/footer_twitter.svg";
import FooterLogo from "@iso/assets/images/footer_logo.svg";

export const REQUEST_SIDEBAR = "REQUESTSIDEBAR";
export const ABOUT_SIDEBAR = "ABOUTSIDEBAR";
export const SIGNUP_SIDEBAR = "SIGNUPSIDEBAR";
export const SIGNIN_SIDEBAR = "SIGNINSIDEBAR";
export const SIGNOUT_SIDEBAR = "SIGNOUTSIDEBAR";
export const MENU_SIDEBAR = [
  {
    id: ABOUT_SIDEBAR,
    path: "/about",
    title: "sidebar.public.about",
    className: "black-link",
  },
  {
    id: SIGNUP_SIDEBAR,
    path: "#",
    title: "sidebar.public.signup",
    className: "black-link",
  },
  {
    id: SIGNIN_SIDEBAR,
    path: "#",
    title: "sidebar.public.login",
    className: "black-link",
  },
];

export const PRIVATE_MENU_SIDEBAR = [
  {
    id: ABOUT_SIDEBAR,
    path: "/about",
    title: "sidebar.public.about",
    className: "black-link",
  },
];

export const FOOTER_LIST = {
  logo: FooterLogo,
  col1: {
    title: "PrayerCloud",
    items: [
      {
        title: "About",
        href: "/about",
      },
      {
        title: "Prayer Partners",
        href: "/prayer-partners",
      },
      {
        title: "Contributions",
        href: "/contributions",
      },
      {
        title: "Help / FAQ",
        href: "/faq",
      },
      {
        title: "Terms & Privacy",
        href: "/terms",
      },
      // {
      //   title: "Contact Us",
      //   href: "/contact_us",
      // },
    ],
  },
  col2: {
    items: [
      {
        icon: FooterTwitter,
        href: "https://twitter.com/_PrayerCloud",
      },
      {
        icon: FooterFacebook,
        href: "https://www.facebook.com/PrayerCloudApp",
      },

      {
        icon: FooterInstagram,
        href: "https://www.instagram.com/prayercloud_official/",
      },
    ],
  },
  col3: {
    title: "PrayerCloud",
    items: [
      {
        title: "About",
        href: "/about",
      },
      {
        title: "Prayer Partners",
        href: "/prayer-partners",
      },
      {
        title: "Contributions",
        href: "/contributions",
      },
    ],
  },
  col4: {
    title: "PrayerCloud",
    items: [
      {
        title: "Help / FAQ",
        href: "/faq",
      },
      {
        title: "Terms & Privacy",
        href: "/terms",
      },
      {
        title: "Contact Us",
        href: "/contact_us",
      },
    ],
  },
  col1_mobile: {
    title: "PrayerCloud",
    items: [
      {
        title: "About",
        href: "/about",
      },
      {
        title: "Contributions",
        href: "/contributions",
      },
      {
        title: "Prayer Partners",
        href: "/prayer-partners",
      },
      {
        title: "Help / FAQ",
        href: "/faq",
      },
      {
        title: "Terms & Privacy",
        href: "/terms",
      },
      {
        title: "Contact Us",
        href: "/contact_us",
      },
    ],
  },
};
