import React from "react";
import styled from "styled-components";

import TwoPeoplePray from "@iso/assets/images/TwoPeoplePray.svg";
import IntlMessage from "@iso/components/intlMessage";
import { isMobile, isTablet } from "react-device-detect";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #afdaf5;
  width: 100%;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 50px;
  }

  @media only screen and (min-width: 1025px) {
  }

  @media only screen and (min-width: 1920px) {
  }
`;
const Avatar = styled.img`
  height: 90vw;

  @media only screen and (min-width: 500px) {
    height: 280px;
  }

  @media only screen and (min-width: 768px) {
    height: 292px;
  }

  @media only screen and (min-width: 1025px) {
    height: 390px;
    margin-left: 75px;
  }
  @media only screen and (min-width: 1441px) and (max-width: 1920px) {
    margin-left: 0px;
  }
`;

const SubTitleWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 0px 38px 83px 39px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1025px) {
    padding: 15px 64px 67px 64px;
  }

  @media only screen and (min-width: 768px) {

    > span:not(:first-child) {
      margin-top: 20px;
    }
  }

  @media only screen and (min-width: 1025px) {
    max-width: 437px;
  }
`;

const ContentSubtitle = styled.span`
  display: block;
  font-family: "BrandonText";
  font-style: normal;
  color: #000000;
  font-weight: 600;
  font-size: 18px;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }

  @media only screen and (min-width: 1025px) {
    font-size: 20px;
    line-height: 32px;
  }

  @media only screen and (max-width: 500px) {
    line-height: 30px;
    text-align: center;
  } 
`;

const LeftSide = (props) => {
  return (
    <Wrapper>
      <Avatar src={TwoPeoplePray}></Avatar>
      <SubTitleWrapper>
        <ContentSubtitle>
          {(isMobile || isTablet) ? (
            <IntlMessage
              id={
                <>
                  Our prayer partners{" "}
                  <span style={{ color: "white" }}>
                    have been 
                  </span>{" "}
                  <br></br>
                  <span style={{ color: "white" }}>
                    selected based on
                  </span>{" "}
                  their religious devotion, trustworthiness, and readiness to pray
                  on your behalf.
                </>
              }
            />
          ) : (
            <IntlMessage
              id={
                <>
                  Our prayer partners{" "}
                  <span style={{ color: "white" }}>
                    have been selected 
                  </span>{" "}
                  <br></br>
                  <span style={{ color: "white" }}>
                    based on
                  </span>{" "}
                  their religious devotion, trustworthiness, and readiness to pray
                  on your behalf.
                </>
              }
            />
          )}
        </ContentSubtitle>
      </SubTitleWrapper>
    </Wrapper>
  );
};

export default LeftSide;
