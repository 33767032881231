import * as Yup from "yup"
import axios from "axios";

const SignUpFormValidator = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .label("Password")
      .matches(/^.{8,}$/, "Have at least 8 characters.")
      .matches(/(^[\S]*$)/, "Password must not contain space.")
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain 8 characters including 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character (i.e. $, #, @, !, etc.)"
      )
      .matches(
        /(?=.*[a-z])/,
        "Password must contain 8 characters including 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character (i.e. $, #, @, !, etc.)"
      )
      .matches(
        /(?=.*\d)/,
        "Password must contain 8 characters including 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character (i.e. $, #, @, !, etc.)"
      )
      .matches(
        /(?=.*\W)/,
        "Password must contain 8 characters including 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character (i.e. $, #, @, !, etc.)"
      )
      .required(),
    passwordConfirmation: Yup.string()
      .label("Password Confirmation")
      .oneOf(
        [Yup.ref("password"), null],
        "Passwords does not match! Please try again."
      )
      .required(),
    calling_code: Yup.string().required("Calling code is required"),
  phone_number: Yup.string()
  .typeError("Phone Number must be a number")
  .label("Phone Number")
  .matches(/^.{4,}$/, "Phone number is required field")
  .matches(/^[0-9+ ]+$/, "Only numbers, +, and spaces are allowed")
  .test("checkDupPhone", "Phone number is already taken", function (value) {
    return new Promise((resolve, reject) => {
      return axios
      .get("/users/check_phone_exist", { params: { phone_number: value } })
      .then((res) => {
        if (res.data && res.data.is_exist) resolve(false);
        else resolve(true);
      })
      .catch((err) => {
        // note exists
        resolve(true);
      });
    });
  })
  .required(),
    language: Yup.string().required("Language is required"), 
    religion: Yup.string().required("Religion is required"), 
    birth: Yup.string().required("DOB is required"), 

  })

export { SignUpFormValidator }
