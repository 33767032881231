import axios from "axios";
import { apiEndpoints } from "@iso/helpers";
import { userRoleConstants } from "@iso/constants";

const adminsGetList = (body) => {
  return axios.get(`${apiEndpoints.admins_path}?status=${body.status}`);
};

const createAdmin = (body) => {
  return axios.post(apiEndpoints.admins_path, body);
};

const GetDetailsAdminById = (id) => {
  return axios.get(`${apiEndpoints.admins_path}/${id}/show`);
};

const updateAdminProfile = (body) => {
  return axios.put(`${apiEndpoints.admins_path}/${body.id}/update`, body);
};

const checkActivationCode = (user_id, activation_code) => {
  return axios.get(apiEndpoints.activation_code_path, {
    params: {
      user_id: user_id,
      activation_code: activation_code,
    },
  });
};

const register = (body) => {
  return axios.post(apiEndpoints.admins_register_path, body);
};

const deleteAdminAccount = (id) => {
  return axios.delete(`${apiEndpoints.admins_path}/${id}`);
};
// const banPrayerPartnerAccount = (body) => {
//   return axios.put(
//     `${apiEndpoints.admin_prayer_partners}/${body.id}/ban`,
//     body
//   );
// };
// const banUserAccount = (body) => {
//   return axios.put(`${apiEndpoints.admin_users}/${body.id}/ban`, body);
// };



// toggleBanAccount: 
// { action: "ban", id = null, is_ban_ip: false }
// { action: "unban", id = null, is_unban_ip: false }

const toggleBanAccount = (
  role,
  body = { action: "ban", id: null, is_ban_ip: false }
) => {
  let baseURL;
  switch (role) {
    case userRoleConstants.USER_ROLE:
      baseURL = apiEndpoints.admin_users;
      break;
    case userRoleConstants.PRAYER_ROLE:
      baseURL = apiEndpoints.admin_prayer_partners;
      break;
    default:
      break;
  }

  return axios.put(`${baseURL}/${body.id}/${body.action}`, body);
};

export default {
  adminsGetList,
  createAdmin,
  GetDetailsAdminById,
  updateAdminProfile,
  checkActivationCode,
  register,
  deleteAdminAccount,
  // banPrayerPartnerAccount,
  // banUserAccount,
  toggleBanAccount,
};
