import * as Yup from "yup"
import { NAME_MIN_LENGTH, NAME_MAX_LENGTH } from "./constants"
import axios from "axios"

const AddPrayerFormValidator = Yup.object().shape({
  first_name: Yup.string()
    .label("First name")
    .trim()
    .required()
    .test(
      "minLength",
      "First name is invalid! Please check your input!",
      (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
      "maxLength",
      "First name is invalid! Please check your input!",
      (val) => val && val.length <= NAME_MAX_LENGTH
    ).
    matches(/^[A-Za-z ]+$/, 'The field should have letters only'),
  last_name: Yup.string()
    .label("Last name")
    .trim()
    .required()
    .test(
      "minLength",
      "Last name is invalid! Please check your input!",
      (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
      "maxLength",
      "Last name is invalid! Please check your input!",
      (val) => val && val.length <= NAME_MAX_LENGTH
    ).
    matches(/^[A-Za-z ]+$/ , 'The field should have letters only'),
  email: Yup.string()
    .label("Email")
    .email("Please enter a correct email!")
    .required()
    .test('checkDuplEmail', 'Email is already taken', function (value) {
      return new Promise((resolve, reject) => {
          return axios.get("/users/check_exist", {params: {email: value}}).then((res) => {
            if(res.data && res.data.is_exist)
              resolve(false)
            else 
              resolve(true)
          }).catch(() => {
              // note exists
            resolve(true)
          })
      })
    })
})

export { AddPrayerFormValidator }
