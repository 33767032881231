import React from 'react'
import styled from 'styled-components'

import CandleHandImage from '@iso/assets/images/CandleHandImage.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #AFDAF5;
  width: 100%;


  @media only screen and  (min-width: 768px) and (max-width: 1024px) {
    height: 406px;
  }

  @media only screen and (min-width: 1025px) {
    
  }
  
  @media only screen and (min-width: 1920px) {
    
  }
`
const Avatar = styled.img` 
  height: 90vw;

  @media only screen and (min-width: 500px) {
    height: 280px;
  }
  
  @media only screen and (min-width: 768px) {
    height: 292px;
  }

  @media only screen and (min-width: 1025px) {
    height: 485px;
    // margin-left: 18px;
    margin-left: 75px;
  }
  @media only screen and (min-width: 1441px) and (max-width: 1920px) {
    margin-left: 170px
  }
`;


const LeftSide = props => {

  return <Wrapper>
    <Avatar src={CandleHandImage}></Avatar>
  </Wrapper>
}

export default LeftSide
