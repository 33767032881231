import React from "react";
import { Row, Col, Badge } from "react-bootstrap";

import IntlMessage from "@iso/components/intlMessage";
import "./RequestStatus.scss";
import { COLOR_CODE } from "./constants";

const RequestStatus = (props) => {
  const { request } = props;
  return (
    <>
      <Row className="request_status_wrapper">
        <Col sm="12">
          <span>
            <IntlMessage id="admin.requests.details.status" />
          </span>
          <Badge
            className={`${request.status} ml-3 text-capitalize p-1 status`}
            style={{ "backgroundColor": COLOR_CODE[request.status] }}
          >
            <IntlMessage
              id={`admin.requests.request_details_card.status.${request.status}`}
            />
          </Badge>
        </Col>
      </Row>
    </>
  );
};

export default RequestStatus;
