import React from "react";
import { Row, Col } from "react-bootstrap";
import "./feedbackCard.scss";
import DoubleQuotation from "@iso/assets/images/DoubleQuotation.svg";
import RightCloud from "@iso/assets/images/RightCloud.svg";
import LeftCloudImg from "@iso/assets/images/LeftCloud.svg";
import parse from 'html-react-parser';

const FeedbackCard = (props) => {
  const { avatar, name, address, denomination, introduction, feedback, leftCloud, className, height, width } = props;
  console.log(" height ====> ", height)
  console.log(" width =====>  ", width)
  return (
    <Row className={`feedback-card ${className ?? ''}`}>
      <Col md="5">
        <div className="group-1">
          <img src={avatar} alt="avatar"></img>
          <div className="group-2">
            <p className="name">{name}</p>
            <p className="address">{address}</p>
            <p className="denomination">{denomination}</p>
          </div>
        </div>
        <p className="introduction">{parse(introduction)}</p>
      </Col>
      <Col md="7">
        <div className="feedback">
          <img className="double-quotation" src={DoubleQuotation}></img>
          <img className={`${name === "Alexandra" ? "position-right-cloud-alex" : "right-cloud"}`} src={RightCloud}></img>
          <img height={height} width={width} className={`${name === "Alexandra" ? "position-left-cloud-alex" : "left-cloud"}`} src={leftCloud ? leftCloud : LeftCloudImg}></img>
          <p className='feedback-content'>{parse(feedback)}</p>
        </div>
      </Col>
    </Row>
  );
};

export default FeedbackCard;
