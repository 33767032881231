export const custom_events = {
    CLICK_BUTTON : 'click_button',
    CHANGE_INPUT : 'change_input',
};

export const recommended_events = {
    SIGN_UP : 'sign_up',
    LOGIN : 'login',
    PURCHASE : 'purchase',
    ADD_PAYMENT_INFO : 'add_payment_info',
};