import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from "react-redux"
import requestStepActions from "@iso/actions/RequestStepActions"
import {Grid} from "@material-ui/core";
import PopupBeta from "../../../../../../actions/PopupBeta";
import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const Wrapper = styled.div`
border: 1px solid #FFFFFF;
border-radius: 6px;
padding: 5px 16px;
@media only screen and (max-width: 500px){
    min-width: 107px;
    padding: 5px 13px;
    &.jewish {
      min-width: 85px
    }
}
`

const Title = styled.p`
    font-family: 'BrandonText';
    font-size: 16px;
    text-align: center;
    margin: 0px;
    cursor: pointer;
    @media only screen and (max-width: 375px){
      min-width: 97px!important;
      &.title-jewish {
        min-width: 45px!important;
      }
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
      height: 25px;
      margin-top: 2px;
  }
`

const BeliefItem = props => {
    const [show, setShow] = React.useState(false);
    const history = useHistory()
    const dispatch = useDispatch()
    const requestStepData = useSelector((state) => state.requestStepReducer);
    const {title,id} = props
    const handleClickItem = (title) => {
        gtag('event', custom_events.CLICK_BUTTON, {
          button_label : 'Prayer Partner Denomination',
          button_value : title,
        });
        ReactPixel.track(fb_pixel_event.custom_events.CLICK_BUTTON, {
          button_label : 'Prayer Partner Denomination',
          button_value : title,
        });
        dispatch(
            requestStepActions.setDataStep({
                ...requestStepData,
                prayer_customization: {
                    ...requestStepData.prayer_customization,
                    religion: id,
                    open_select_religion: id == "" && true,
                }
            })
        );

        history.push('/requests/step1');
        // setShow(true)
    }

    const maxWidthItem = (itemID) => {
      switch(itemID) {
        case "anglican":
        case "jewish":
          return {minWidth: "61px"};
        case "catholic":
          return {minWidth: "97px"};
        case "non-denomination":
          return {minWidth: "158px"};
        case "eastern-orthodox":
          return {minWidth: "138px"};
        default:
            return {minWidth: "107px"};
      }
    }

    return (
        <Wrapper className={id}>
            <Title className={`title-${id}`} style={maxWidthItem(id)} onClick = {() => handleClickItem(id) }>{title}</Title>
            <PopupBeta show={show} setShow={setShow}/>
        </Wrapper>
    )
}

export default BeliefItem