import React, { Component,useState } from "react"
import { Modal, Button } from "react-bootstrap"
import {useSelector, useDispatch} from 'react-redux'
import welcomePrayerCloudActions from '@iso/actions/WelcomePrayerCloudActions'
import ModalFormPrayerCloud from '@iso/Website/Pages/WelcomePrayerCloud/ModalFormPrayerCloud'
import ModalFormUserPrayerCloud from "@iso/Website/Pages/WelcomePrayerCloud/ModalFormUserPrayerCloud"
import IntlMessage from "@iso/components/intlMessage"
import './style.scss'
import PopupBeta from "../../../actions/PopupBeta";

const {closeModal} = welcomePrayerCloudActions;
function WelcomePrayerCloudModal(props){

  const dispatch = useDispatch()
  const user = useSelector((state) => state.authentication.user);
  const showModal = useSelector((state) => state.welcomePrayerCloudReducer);
  const [ formSubcribe, setFormSubcribe] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClose = () => {
    dispatch(closeModal());
    setFormSubcribe(false)
  }
  const [countShow, setCountShow] = useState(sessionStorage.getItem('countShow') || 0);

  React.useEffect(() => {
    if(showModal.isOpen && user){
      sessionStorage.setItem('countShow', countShow);
      let countNumber = Number(sessionStorage.getItem('countShow'))
      countNumber += 1
      setCountShow(countNumber)
    }
    else if(!user)
    {
      sessionStorage.setItem('countShow', 0);
      let countNumber = Number(sessionStorage.getItem('countShow'))
      setCountShow(countNumber)
    }
  },[showModal.isOpen])

  const modalForm = () => {
    const shouldShowFormPrayerClound = showModal.isOpen && !formSubcribe && !showModal.isHeader
    if(shouldShowFormPrayerClound)
      return <ModalFormPrayerCloud countShow={countShow}  setFormSubcribe={setFormSubcribe}/>

    return <ModalFormUserPrayerCloud isHeader = {showModal.isHeader} countShow={countShow} setShow={setShow} isSubcribe = {showModal.isSubcribe} formSubcribe={formSubcribe}/>
  }

  return(
  <div >
    <Modal show={showModal.isOpen} onHide={handleClose} className={`${!formSubcribe && `custom_modal_form_prayer_cloud` } modalWelcomePrayerCloudContainer`}>
      {modalForm()}
    </Modal>

    <PopupBeta show={show} setShow={setShow}/>
  </div>
  )
}

export default WelcomePrayerCloudModal
