import React from "react";
import ImgShareRequest from "@iso/assets/images/share-request.svg";
import ImgShareRequestStep4 from "@iso/assets/images/step_4_share_request.svg";
import ImgShareRequestStep4Mobile from "@iso/assets/images/step_4_share_request_mobile.svg";
import ImgYourRequest from "@iso/assets/images/your-request.svg";

import ImgTwitter from "@iso/assets/images/twitter.svg";
import ImgFacebook from "@iso/assets/images/facebook.svg";
import IntlMessage from "@iso/components/intlMessage";

import FooterMail from "@iso/assets/images/footer_mail.svg";
import FooterInstagram from "@iso/assets/images/footer_instagram.svg";
import FooterFacebook from "@iso/assets/images/footer_facebook.svg";
import FooterTwitter from "@iso/assets/images/footer_twitter.svg";

import ImgMail from "@iso/assets/images/mail.svg";
import ImgInstagram from "@iso/assets/images/instagram.svg";
import "./ShareYourRequest.scss";
import ShareRequestTablet from "@iso/assets/images/step4_share_request.svg"
import {isTablet, isMobile} from "react-device-detect"

import { Row, Button, Col } from "react-bootstrap";
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
} from "react-share";

const SHARE_IMAGES = {
  homepage: {
    twitter: ImgTwitter,
    facebook: ImgFacebook,
    mail: ImgMail,
  },
  step4: {
    twitter: ImgTwitter,
    facebook: ImgFacebook,
    mail: ImgMail,
  },
  footer: {
    twitter: FooterTwitter,
    facebook: FooterFacebook,
    mail: FooterMail,
  },
};

const currentURl =
  process.env.REACT_APP_ENV == "development"
    ? "https://staging.prayercloud.com"
    : process.env.REACT_APP_BASE_URL;
const SHARE_SOCIAL_CONTENT = {
  // Any field is optional, but url and title are important
  url: currentURl,
  title: "PrayerCloud",
  hashtag: "#prayerCloud",
  quote:
    "I just discovered PrayerCloud and think you might find it very inspiring. It's the gift of prayer, for someone else or even yourself.",
};

const SHARE_EMAIL_CONTENT = {
  title: "PrayerCloud",
  body: `I just requested a prayer on <a href="${currentURl}">PrayerCloud</a> and I think you might find it interesting, so I wanted to share it. It's really great to be able to share the gift of prayer.`,
};

const SHARE_CONTENT = {
  homepage: {
    facebook: { ...SHARE_SOCIAL_CONTENT },
    twitter: { ...SHARE_SOCIAL_CONTENT, title: SHARE_SOCIAL_CONTENT.quote },
    email: { ...SHARE_EMAIL_CONTENT },
  },
  footer: {
    facebook: { ...SHARE_SOCIAL_CONTENT },
    twitter: { ...SHARE_SOCIAL_CONTENT, title: SHARE_SOCIAL_CONTENT.quote },
    email: { ...SHARE_EMAIL_CONTENT },
  },
  step4: {
    facebook: {
      ...SHARE_SOCIAL_CONTENT,
      quote:
        "I just requested a prayer on PrayerCloud. and I think you might find it interesting, so I wanted to share it. It's really great to be able to share the gift of prayer",
    },
    twitter: {
      ...SHARE_SOCIAL_CONTENT,
      title:
        "I just requested a prayer on PrayerCloud. and I think you might find it interesting, so I wanted to share it. It's really great to be able to share the gift of prayer",
    },
    email: { ...SHARE_EMAIL_CONTENT },
  },
};

const ShareYourRequest = (props) => {
  const { variant, showImage } = props;
  const rootClass =
    variant == "footer"
      ? "mt-3 mb-5 mt-md-0 mb-md-0"
      : "justify-content-center share-your-request flex-column";
  const wrapperClass =
    variant == "footer" ? "" : "action-container d-flex justify-content-center mr-2";
  const iconClass =
    variant == "footer" ? "" : "rounded-circle hippie-blue-button share-button mr-2";

  return (
    <>
      {variant && (
        <Row className={rootClass}>
          {showImage && (
            <div className="title-container step4-img-share-request">
              {
                isTablet
                  ? <img className="share_request_tablet" src={ShareRequestTablet}/>
                  :
                <>
                  <img className={`custom_img_share ${variant}`} src={variant !== 'step4' ? ImgShareRequest : (isMobile && !isTablet ? ImgShareRequestStep4Mobile : ImgShareRequestStep4)}/>
                  <img
                    src={ImgYourRequest}
                    className="mt-5 ml-4 ml-sm-0 ml-lg-2 custom_img_your_request"
                  ></img>
                </>
              }
            </div>
          )}

          <div className={wrapperClass}>
            <Row className="ml-2 custom_share_request_button_row">
              <Col xs={4} lg={4} className="d-flex justify-content-center custom_button_share_request">
                <TwitterShareButton
                  url={SHARE_CONTENT[variant].twitter.url}
                  title={SHARE_CONTENT[variant].twitter.title}
                  hashtag={SHARE_CONTENT[variant].twitter.hashtag}
                  className={iconClass} 
                >
                  <img src={SHARE_IMAGES[variant].twitter}></img>
                </TwitterShareButton>
              </Col>

              <Col xs={4} lg={4} className="d-flex justify-content-center custom_button_share_request">
                <FacebookShareButton
                  url={SHARE_CONTENT[variant].facebook.url}
                  quote={SHARE_CONTENT[variant].facebook.quote}
                  title={SHARE_CONTENT[variant].facebook.title}
                  hashtag={SHARE_CONTENT[variant].facebook.hashtag}
                  className={iconClass}
                >
                  <img src={SHARE_IMAGES[variant].facebook}></img>
                </FacebookShareButton>
              </Col>

              <Col xs={4} lg={4} className="d-flex justify-content-center custom_button_share_request">
                <EmailShareButton
                  url=""
                  subject={SHARE_CONTENT[variant].email.title}
                  body={SHARE_CONTENT[variant].email.body}
                  className={iconClass}
                >
                  <img src={SHARE_IMAGES[variant].mail}></img>
                </EmailShareButton>
              </Col>
            </Row>
          </div>
        </Row>
      )}
    </>
  );
};

export default ShareYourRequest;
