import React, { Fragment, Component } from "react"
import RequestPrayerSeperator from "./components/RequestPrayerSeperator"
import Section1 from "./components/Section1"
import Section2 from "./components/Section2"
import Section3 from "./components/Section3"
import Section4 from "./components/Section4"
import styled from 'styled-components'
import CloudImg from "@iso/assets/images/CloudAboutUs.svg"
import CloudMobileImg from "@iso/assets/images/CloudAboutUsMobile.svg"
import { isTablet } from "react-device-detect";
import "./styles.scss"

const CloudHeaderImage = styled.div`
position: relative;
display: block;
height: 180px;
background-color: #AFDAF5;

@media only screen and (min-width: 1920px) {
    padding-left:180px !important;
    padding-right:180px !important;
  }
`

const CloudHeaderMobileImage = styled.div`
position: relative;
display: block;
height: 50px;
background-color: #AFDAF5;
`

const ImageCloud  = styled.img`
width: 100%;
position:absolute; 
bottom: -34px;
 @media only screen and (min-width: 1920px) {
    bottom: -41px;
    width:82%;
  }    
`;
const AboutPage = (props) => {
  return (
    <>
      <div className={`container-full-width bg_blueSky`}>
        <div className="container-fixed">
          <CloudHeaderImage className="cloud-header d-none d-md-block">
            <ImageCloud src = {CloudImg} alt ="Cloud" />
          </CloudHeaderImage>
        </div>
      </div>

    {/* Mobile */}
    <CloudHeaderMobileImage className="d-block d-md-none custom_cloud_header_mobile_img">
      <ImageCloud src = {CloudMobileImg} alt ="Cloud" />
    </CloudHeaderMobileImage>

    <Section1></Section1>
      {
        !isTablet && <RequestPrayerSeperator withCloud={true} withButton={false}
                                style={{marginTop: '-70px', marginLeft: '160px'}}
                                className="d-none d-lg-block"></RequestPrayerSeperator>
      }
    <Section2></Section2>
      <div className={`container-full-width bg-blue-sky-half`}>
        <div className="container-fixed">
          <Section3></Section3>
        </div>
      </div>
    <Section4></Section4>
    </>
  );
}

export default AboutPage;
