import {Button, Modal} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import IntlMessage from "@iso/components/intlMessage";
import "./styles.scss"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TextField from "@material-ui/core/TextField";
import {translate} from "../../utils/Translate";
import styled from "styled-components";
import { isMobile, isTablet } from "react-device-detect";
import {CircularProgress, Grid} from "@material-ui/core";
import SubscribedThanks from '@iso/assets/images/email_popup_subscribed_thank_you.svg'
import {useHistory} from "react-router";
import AuthService from "@iso/services/AuthService"
import PopupBeta from "../../actions/PopupBeta";

const BeliefContainer = styled(Grid)`
    display: flex;
    margin: auto;
    width: fit-content;
`

const GridCustom = styled(Grid)`
    display: flex;
    padding: 7px;
    @media (max-width: 600px) {
      padding-top: 4px!important;
      padding-bottom: 4px!important;
      &:nth-child(even) {
        padding-right: 0;
      }
      &:nth-child(odd) {
        padding-left: 0;
      }
    }
    @media (max-width: 350px) {
       padding-left: 7px!important;
       padding-right: 7px!important;
    }
`
const Wrapper = styled.div`
    border: 1px solid #DBDEE7;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 16px;
    height:36px;
    cursor: pointer;
    @media (max-width: 600px) {
       padding: 2px 16px;
       height: 36px;
    }
`

const CallToActionBtn = styled(Button)`
    width: 345px;
    height: 60px;
    font-family: "BrandonText";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    background: #499E9E;
    border-radius: 6px;
    border-color: #499E9E;
    @media (max-width: 375px) {
      width: 300px;
   }
`

const Title = styled.p`
    font-family: 'BrandonText';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    height:32px;
`

const EmailPopup = () => {
  const [open, setOpen] = React.useState(false)
  const [step, setStep] = React.useState(1)
  const [showPopup, setShowPopup] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const [religion, setReligion] = React.useState("")
  const [errorsEmailInvalid, setErrorsEmailInvalid] = React.useState(false)
  const [errorsEmailFormat, setErrorsEmailFormat] = React.useState(false)
  const [show, setShow] = React.useState(false);
  const history = useHistory();
  
  const typesMobile = [
    {id : "catholic", label :"Catholic"},
    {id : "anglican", label :"Anglican"},
    {id : "jewish", label :"Jewish"},
    {id : "protestant", label :"Protestant"},
    {id : "eastern-orthodox", label :"Eastern Orthodox"},
    {id : "christian", label :"Christian - Other"},
    {id : "evangelical", label :"Evangelical"},
    {id : "non-denomination", label :"Non-Denominational"},
    {id : "other", label :"Other"},
  ];
  
  const types1 = [
    {id : "catholic", label :"Catholic"},
    {id : "protestant", label :"Protestant"},
    {id : "evangelical", label :"Evangelical"},
    {id : "eastern-orthodox", label :"Eastern Orthodox"},
  ];
  const types2 = [
    {id : "anglican", label :"Anglican"},
    {id : "christian", label :"Christian - Other"},
    {id : "non-denomination", label :"Non-Denominational"},
    {id : "jewish", label :"Jewish"},
    {id : "other", label :"Other"},
  ];
  
  const handleRequestNew = () => {
    history.push('/requests/step1')
    // setShow(true)
  }
  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  useEffect(() => {
    if(errorsEmailFormat){
      setErrorsEmailFormat(false)
      setErrorsEmailInvalid(false)
    }
    if(errorsEmailInvalid){
      setErrorsEmailFormat(false)
      setErrorsEmailInvalid(false)
    }
  }, [email]);

  const handleChangeEmail = (value) => {
    if(validateEmail()){
      setErrorsEmailFormat(false)
      setErrorsEmailInvalid(false)
    }
    setEmail(value)
  }
  const handleSignUp = () => {
    if(!email){
      setErrorsEmailInvalid(true)
      setErrorsEmailFormat(false)
    }
    else if(!validateEmail()){
      setErrorsEmailFormat(true)
      setErrorsEmailInvalid(false)
    }
    else{
      setStep(2);
    }
  }
  
  const handleChooseReligion = (religion) => {
    setReligion(religion);
    setLoading(true);
    AuthService.subscription({
      email: email,
      religion: religion
    }).then((res) => {
      setStep(3);
    }).finally(() => {
      setLoading(false);
    });
    
    // setTimeout(function () {
    //   setShowPopup(false)
    // }, 3000);
  }

  const getStyleWidth = (id) => {
    switch (id) {
      case 'jewish':
        return {maxWidth: '75px'};
      case 'protestant':
        return {maxWidth: '115px'};
      case 'eastern-orthodox':
        return {maxWidth: '170px', paddingLeft: "13px", paddingRight: "13px"};
      case 'christian':
        return {maxWidth: '166px', paddingLeft: "13px", paddingRight: "13px"};
      case 'evangelical':
        return {maxWidth: '118px'};
      case 'non-denomination':
        return {maxWidth: '191px', paddingLeft: "10px", paddingRight: "10px"};
      default:
        return {maxWidth: '98px'};
    }
  }

  const headerWithStep = (step) => {
    switch (step) {
      case 1:
        return (
          <>
            <IntlMessage id="user.email_popup.title"/>
            {
              isMobile & !isTablet ?
                !open && <span style={{fontWeight: "bolder", fontStyle: "italic"}}
                      onClick={() => {
                      setOpen(!open)
                    }}
                >
                  <IntlMessage id="user.email_popup.title.sign_up"/>
                </span>
                :
                <span onClick={() => {
                  setOpen(!open)
                }}>
                  <IntlMessage id="user.email_popup.title.sign_up"/>
                  <KeyboardArrowDownIcon
                    className="cursor-pointer ml-2"
                    style={open ? {transform: "rotateX(180deg)"} : {}}
                  />
                </span>
            }
          </>
        )
      case 2:
        return (
          <div className="header_your_denomination">
            <b>Please Select Your Denomination</b>
          </div>
        )
      case 3:
        return (
          <div className="header-thanks">
            <b>Thank You!</b>
            <div style={{fontSize: "16px"}}>
              You have subscribed.
            </div>
          </div>
        )
    }
  }
  
  const bodyWithStep = (step) => {
    switch (step) {
      case 1:
        return (
          open && <Modal.Body>
            
            <div className="group-input">
              <TextField
                fullWidth={true}
                variant="filled"
                className={`custom_input_popup_email my-control ${(isMobile && !isTablet) && 'is_mobile'}`}
                name="email"
                placeholder={translate("common.email")}
                value={email}
                onChange={(e) => handleChangeEmail(e.target.value)}
                label={
                  <IntlMessage id="common.email"/>
                }
              />
              
              {(isMobile && !isTablet && errorsEmailInvalid) && <p className={`custom_error`}><span className="custom_error_content_required">Email is required</span></p>}
              {(isMobile && !isTablet && errorsEmailFormat) && <p className={`custom_error`}><span className="custom_error_content">Please enter a correct email format</span></p>}
              
              <Button className={`btn-signup ${(isMobile && !isTablet) && 'btn_is_mobile'}`} type="submit" style={{width: '173px'}} onClick={() => handleSignUp()}>
                Sign Up
              </Button>

              {( isTablet && errorsEmailInvalid) && <p className={`custom_error`}><span className="custom_error_content_required">Email is required</span></p>}
              {( isTablet && errorsEmailFormat) && <p className={`custom_error`}><span className="custom_error_content">Please enter a correct email format</span></p>}
              {(!isMobile && errorsEmailInvalid) && <p className={`custom_error`}><span className="custom_error_content_required">Email is required</span></p>}
              {(!isMobile && errorsEmailFormat) && <p className={`custom_error`}><span className="custom_error_content">Please enter a correct email format</span></p>}
            </div>
            
            <div>
              <span className="accept-terms-notice">
                {
                  (!isTablet && isMobile) ?
                    <>
                      <IntlMessage id="user.confirm.form.email_popup.policy1"/>
                      <span className="hippie-blue-link pointer ml-1">
                        <IntlMessage id="user.confirm.form.email_popup.policy2.1"/>
                      </span>
                    </>
                    :
                    <IntlMessage id="user.confirm.form.subscribe.policy1"/>
                }
              <span className="hippie-blue-link pointer ml-1 d-inline-block d-md-inline">
                {
                  (!isTablet && isMobile) ?
                    <IntlMessage id="user.confirm.form.email_popup.policy2.2"/>
                    :
                    <IntlMessage id="user.confirm.form.subscribe.policy2"/>
                }
              </span>
              <span className="ml-md-1">
                <IntlMessage id="user.confirm.form.subscribe.policy3"/>
                <span className="hippie-blue-link pointer ml-md-1">
                  <IntlMessage id="user.confirm.form.subscribe.policy4"/>
                </span>
              </span>
              </span>
            </div>
          
          </Modal.Body>
        )
      case 2:
        return(
          loading
            ?
            <div className="mx-auto text-center">
              <CircularProgress />
            </div>
            :
            ((isMobile && !isTablet)
              ?
              (
                <Modal.Body className="mobile-mr-30" style={{margin: "auto", width: "645px", maxWidth: "375px"}}>
                  <BeliefContainer container spacing={2} xs={12}>
                    {typesMobile.map((type, idx) => {
                      return (
                        <GridCustom item key={idx} onClick={() => {
                          handleChooseReligion(type.id)
                        }}>
                          <Wrapper style={getStyleWidth(type.id)} className={type.id}>
                            <Title>{type.label}</Title>
                          </Wrapper>
                        </GridCustom>
                      )
                    })}
                  </BeliefContainer>
                </Modal.Body>
              )
              :
              <Modal.Body style={{margin: "auto", width: "745px", maxWidth: "100%"}}>
                <BeliefContainer container spacing={2} xs={12}>
                  {types1.map((type, idx) => {
                    return (
                      <GridCustom item key={idx} onClick={() => {
                        handleChooseReligion(type.id)
                      }}>
                        <Wrapper className={type.id}>
                          <Title>{type.label}</Title>
                        </Wrapper>
                      </GridCustom>
                    )
                  })}
                </BeliefContainer>
                <BeliefContainer container spacing={2} xs={12}>
                  {types2.map((type, idx) => {
                    return (
                      <GridCustom item key={idx} onClick={() => {
                        handleChooseReligion(type.id)
                      }}>
                        <Wrapper className={type.id}>
                          <Title>{type.label}</Title>
                        </Wrapper>
                      </GridCustom>
                    )
                  })}
                </BeliefContainer>
              </Modal.Body>
            )
        )
      case 3:
        return (
          <div className="custom_img_subscribed_thanks">
            <img style={{marginTop: "-2px", marginLeft: "16px", width: "108px", height: "108px"}} src={SubscribedThanks} alt="Subscribed Thanks"/>
            {
              (isMobile && !isTablet) && <div style={{margin: "auto", marginTop: "20px"}}>
                <CallToActionBtn onClick={() => handleRequestNew()}><IntlMessage id="landing.request.new.prayer" /></CallToActionBtn>
              </div>
            }
            <PopupBeta show={show} setShow={setShow}/>
          </div>
        )
    }
  }
  
  return (
    showPopup && <div id="popup" className={`cover-email-popup ${(step === 1 && !open) && 'mini-site' }`}>
      
      <div className="modal-dialog">
        <div className="modal-content">
          <div className={`${!open && 'custom_email_popup_title'} modal-header`}>
            <div className="modal-title" style={step === 3 ? {paddingLeft: "50px"} : {}}>
              {headerWithStep(step)}
            </div>
  
              <div>
                <svg className="btn-close" onClick={() => {setShowPopup(false)}} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line x1="18.0168" y1="8.71141" x2="9.35016" y2="16.8364" stroke="#C4C4C4" stroke-width="2"/>
                  <line y1="-1" x2="11.8797" y2="-1" transform="matrix(0.729537 0.683941 0.683941 -0.729537 9.20789 7.98187)" stroke="#C4C4C4" stroke-width="2"/>
                  <circle cx="13" cy="13" r="12" stroke="#C4C4C4" stroke-width="2"/>
                </svg>
              </div>
          </div>
  
          {
            bodyWithStep(step)
          }
        </div>
      </div>
    </div>
  )
  
  /*return (
    <div className={`cover-email-popup ${!(step === 1 && !open) && 'full-site' }`}>
      <Modal
        className="email-popup"
        show={showPopup}
        dis
        onHide={() => {
          setShowPopup(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={step === 3 ? {paddingLeft: "50px"} : {}}>
            {headerWithStep(step)}
          </Modal.Title>
        </Modal.Header>
      
        {
          bodyWithStep(step)
        }
      </Modal>
    </div>
  )*/
}

export default EmailPopup;
