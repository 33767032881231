import React from 'react'
import styled from 'styled-components' 
import Our_Mission from '@iso/assets/images/Our_Mission.svg'
import Our_Mission_Mobile from '@iso/assets/images/Our_Mission_Mobile.svg'
import Our_Mission_Tablet from '@iso/assets/images/about_section1_title.svg'
import Prayers from '@iso/assets/images/Prayers.svg'
import PrayersMobile from '@iso/assets/images/PrayersMobile.svg' 
import PrayersTablet from '@iso/assets/images/about_section1_prayer_tablet.svg'
import TabletCloudFooter from '@iso/assets/images/about_section1_cloud_footer.svg'
import { Grid } from '@material-ui/core'
import IntlMessage from "@iso/components/intlMessage";
import { isMobile, isTablet } from "react-device-detect";
import {Modal} from "react-bootstrap";
import FlagRequestForm from "../../../../../Admin/Pages/SupportCenter/components/SendResponseForm";
import EmailPopup from "../../../../../components/EmailPopup/EmailPopup";
import {useSelector} from "react-redux";
const Wrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  color: #000000;
  font-family: BrandonText;
  padding: 56px 0px;

  @media only screen and (max-width: 900px){
      padding-bottom: 0px;
  }
`

const TitleWrapper = styled.div`
  margin-top: -40px;
  margin-bottom: 40px;

`

const TitleMobileWrapper = styled.div`
  margin-top: -50px;
  z-index: 1;
`
const ValueContainer = styled(Grid)`
  padding: 0px 36px;
  transform: translateX(10%); 
  margin-left: -20px;
  @media only screen and (min-width: 800px) {
    margin-top: -53px !important;
    margin-left: -260px;
  }

  @media only screen and (max-width: 1024px) and (min-width: 500px){
    margin-left: -260px;
  }
   
 
`
const HeaderSubtitle = styled.span`
  font-weight: bold;
  display: block;
  font-size: 22px;
  margin-bottom: 40px;
  padding-right: 50px;
  line-height: 34px;
  @media only screen and (max-width: 500px){
    margin-left: -110px;
    padding: 0 30px; 
  }
  @media only screen and (max-width: 1024px) and (min-width: 501px){
    margin-left: 150px;
    width: 93%; 
  }
`
const HeaderSubtitleTablet = styled.span`
  font-weight: bold;
  display: block;
  font-size: 22px;
  margin-bottom: 40px;
  line-height: 34px;
  font-style: normal;
`

const ContentSubtitle = styled.span` 
  display: block;
  font-size: 18px;
  padding-right: 70px;
  line-height: 32px;
  @media only screen and (max-width: 500px){
    margin-left: -110px;
    padding: 0;
    white-space: pre-line;
    width: 316px;
  }

  @media only screen and (max-width: 1024px) and (min-width: 501px){
    margin-left: 150px;
    width: 93%; 
  }
`

const ContentSubtitleTablet = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
`

const ImagePrayer = styled.img`
  margin-left: 140px;
  @media only screen and (max-width: 500px){
    margin-left: -310px; 
    margin-top: -24px; 
  }
  @media only screen and (max-width: 768px) and (min-width: 501px){
    margin-left: 100px;
    padding: 0px 40px 0px 40px;
  }
`

const ImagePrayerMobile = styled.img`
  margin-left: 140px;
  @media only screen and (max-width: 500px){
    margin-left: -204px; 
    margin-top: 10px; 
  }
  @media only screen and (max-width: 768px) and (min-width: 501px){
    margin-left: 100px;
    padding: 0px 40px 0px 40px;
  }
`

const Subtitle = styled.div`
  margin-left: -10px;
  @media only screen and (max-width: 500px){
    margin: -100px 0 0 0;
  }
`
const Section1 = (props) => {
  
  const user = useSelector((state) => state.authentication.user);
  return <Wrapper container> 
    <TitleWrapper>    
      <img src={isTablet ? Our_Mission_Tablet : Our_Mission} className="tablet-our-mission d-none d-md-block"/>
    </TitleWrapper>
    {/* Mobile */}
    <TitleMobileWrapper>    
      <img src={Our_Mission_Mobile} className="d-block d-md-none"/> 
    </TitleMobileWrapper>

    {
      isTablet ?
        (
          <Grid container style={{marginTop: "50px"}}>
            <Grid item sm={6} className="d-flex justify-content-center align-items-right">
              {/*<ImagePrayer src = {Prayers} alt = "Prayer" />*/}
              <img src = {PrayersTablet} alt = "Prayer" />
            </Grid>
            <Grid item sm={6} className="d-flex justify-content-center align-items-right">
              <div style={{width: "306px", marginLeft: "-50px"}}>
                <HeaderSubtitleTablet><IntlMessage id = "user.form.about.section1.mission.title" /></HeaderSubtitleTablet>
                <ContentSubtitleTablet style={{fontSize: "18px"}}>
                  <div>
                    <IntlMessage id = "user.form.about.section1.reason.title1.tablet" />
                  </div>
                  <div>
                    <IntlMessage id = "user.form.about.section1.reason.title2.tablet" />
                  </div>
                </ContentSubtitleTablet>
              </div>
            </Grid>
          </Grid>
        )
        :
        (
          <ValueContainer container spacing={4}>
            <Grid item xs={12} lg={4} sm={6} className="d-flex justify-content-center align-items-right"
                  style={  {marginTop: '-80px', marginLeft: '120px'}}>
              <ImagePrayer style={{marginLeft: '112px'}} src = {Prayers} alt = "Prayer" className="d-none d-md-block"/>
              <ImagePrayerMobile src = {PrayersMobile} alt = "Prayer" className="d-block d-md-none"/>
            </Grid>
            <Grid item xs={12} lg={4} sm={6} className="d-flex justify-content-center align-items-right our-mission-custom"
                  style={  {paddingTop: '80px', marginLeft: '32px'}}>
              {
                !isMobile &&
                <Subtitle>
                  <HeaderSubtitle><IntlMessage id = "user.form.about.section1.mission.title" /></HeaderSubtitle>
                  <ContentSubtitle style={  {paddingRight: '94px'}}><IntlMessage id = "user.form.about.section1.reason.title1" /></ContentSubtitle>
                  <ContentSubtitle style={  {paddingRight: '60px'}}><IntlMessage id = "user.form.about.section1.reason.title2" /></ContentSubtitle>
                </Subtitle>
              }
      
              {
                isMobile &&
                <Subtitle>
                  <ContentSubtitle className="text-center mt-4"><IntlMessage id = "user.form.about.section1.reason.mobile.title" /></ContentSubtitle>
                </Subtitle>
              }
    
            </Grid>
          </ValueContainer>
        )
    }
    
    { isTablet && <img src={TabletCloudFooter} style={{marginTop: "15px"}} alt=""/>}
  
    { !user && <EmailPopup/>}
    
    </Wrapper>
}
 
export default Section1