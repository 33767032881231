import welcomePrayerPartnerActions from '@iso/actions/welcomePrayerPartnerActions'

const initialState = {
  isOpenModal: false, 
};

export default function WelcomePrayerPartnerReducer(state = initialState, action) {
  
  switch (action.type) {
    case welcomePrayerPartnerActions.SHOW_PRAYER_PARTNER_MODAL:
      return {
        isOpenModal : true,
      }
    case welcomePrayerPartnerActions.HIDE_PRAYER_PARTNER_MODAL: 
      return initialState;
    default:
      return initialState;
  }
}
