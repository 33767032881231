import React, { Component, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AddForm from "./addForm";
import IntlMessage from "@iso/components/intlMessage";


const AddModal = (props) => {
    const { closeModal, showModal } = props
    const handleClose = () => {
        closeModal()
    }
    return (
        <div>
            <Modal show={showModal}
                onHide={handleClose} className="modal-container">
                <Modal.Header closeButton>
                    <Modal.Title className=" mt-5 w-100 text-center font-weight-bold">
                        <IntlMessage id="admin.coupons.form.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddForm handleClose={handleClose} handleCouponRequests={props.handleCouponRequests} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddModal