import * as Yup from "yup"
import {NAME_MIN_LENGTH, NAME_MAX_LENGTH} from "./constants" 

const SignUpFormValidator = Yup.object().shape({
  full_name: Yup.string()
  .label("Full name")
  .required()
  .test(
    "minLength",
    "Please input 2 to 20 characters!",
    (val) => val && val.length >= NAME_MIN_LENGTH
  )
  .test(
    "maxLength",
    "Please input 2 to 20 characters!",
    (val) => val && val.length <= NAME_MAX_LENGTH
  )
  .matches(/^[A-Za-z ]+$/, 'The field should have letters only'),
  email: Yup.string()
    .label("Email")
    .trim()
    .email("Please enter a correct email!")
    .required(),
  password: Yup.string().label("Password").required(),
})
export { SignUpFormValidator }