import React, { Component } from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { SignInFormValidator } from "./validator"
import { Link, Redirect } from "react-router-dom"
import "./styles.scss"
import AuthService from "@iso/services/AuthService"
import { setCurrentUser } from "@iso/actions/User"
import { connect } from "react-redux"
import { userRoleConstants } from "@iso/constants/user"
import RegistrationBox from "@iso/Pages/Common/RegistrationBox/RegistrationBox" 

class CSignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initValue: {
        email: "",
        password: "",
        remember_me: false,
      }, 
    }
  }

  handleSubmit = (values, bag) => {
    AuthService.loginApi(values)
      .then((res) => {
        if (!res) {
          bag.setErrors({ password: "Incorrect login information!" })
          bag.setSubmitting(false)
        }
 
        const { code, data } = res.data
        if (code === 200 && data.role === userRoleConstants.PRAYER_ROLE) {
          this.props.setCurrentUser({ ...data, rememberMe: values.remember_me })
        }  
        else if(data.role !== userRoleConstants.PRAYER_ROLE){
          bag.setErrors({ password: "Incorrect login information!" })
          bag.setSubmitting(false)
        }
        else {
          bag.setErrors({ password: res.data.message })
          bag.setSubmitting(false)
        }
      })
      .catch((res) => {
        bag.setErrors({ password: "Incorrect login information!" })
        bag.setSubmitting(false)
      })
  }

  renderSignInForm() {
    return (
      <Formik
        initialValues={this.state.initValue}
        enableReinitialize={false}
        onSubmit={this.handleSubmit}
        validationSchema={SignInFormValidator}
      >
        {({ values, handleSubmit, handleChange, touched, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form.Group as={Row} controlId="loginEmail">
              <Col sm={{ span: "8", offset: "2" }}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="email"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="loginPassword">
              <Col sm={{ span: "8", offset: "2" }}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Enter password"
                  onChange={handleChange}
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="password"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="loginRememberMe">
              <Col xs="12" className="text-center">
                <Form.Check
                  type="checkbox"
                  name="remember_me"
                  onChange={handleChange}
                  label="Remember me"
                  className="text-bold checkbox_custom"
                />
              </Col>
            </Form.Group>
            <div className="buttons text-center mt-4">
              <Button className="hippie-blue-button sign-in-btn" type="submit">
                Login
              </Button>
            </div>
            <Row>
              <Col xs="12" className="text-center mt-5 small-text">
                <Link
                  className="non-underline-link"
                  to="/prayer_partners/forgot_password"
                >
                  Forgot Password
                </Link>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    )
  }

  render() {
    return (
      <div className="prayer-portal sign-up-container">
        <RegistrationBox title="Log In" form={this.renderSignInForm()} /> 
      </div>
    )
  }
}

const mapStoreToProps = (store) => ({})

const mapDispatchToProps = {
  setCurrentUser: setCurrentUser,
}

const SignIn = connect(mapStoreToProps, mapDispatchToProps)(CSignIn)

export default SignIn
