import React from "react";
import { Row, Col, Navbar, Nav } from "react-bootstrap";
import FullLogoImg from "@iso/Pages/Common/FullLogoImg";
import FullLogoPasswordImg from "@iso/Pages/Common/FullLogoPasswordImg";
import "@iso/assets/common.scss";
import { Link } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import {rgbToHex} from "@material-ui/core";
import {  useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userRoleConstants } from "@iso/constants"

const Header = ({id, ...props}) => {
  const user = useSelector((state) => state.authentication.user);
  const [roleUser, serRoleUser] = React.useState(false)
  const [roleLink, serRoleLink] = React.useState("")
  const bg_header = props.backgroundHeader ? "bg_blueSky" : "bg-white";
  useEffect(() => {
    if (user?.role === userRoleConstants.SUPER_ADMIN_ROLE || user?.role === userRoleConstants.ADMIN_ROLE || user?.role === userRoleConstants.PRAYER_ROLE) {
      if(user?.role === userRoleConstants.SUPER_ADMIN_ROLE ||  user?.role === userRoleConstants.ADMIN_ROLE){
        serRoleLink("/admin")
      }
      else{
        if(window.location.pathname == '/account'){
          serRoleLink("")
        }else{
          serRoleLink("/prayer_partners")
        }
      }
      serRoleUser(true)
    }
  }, [user]);
  return (
    <div className={`container-full-width ${bg_header}`}>
      <div className="container-fixed">
        <Navbar
          id={id}
          expand="md"
          style={{ minHeight: "80px" }}
          className={`navbar-inverse ${props.className} ${bg_header}`}
        >
          <Navbar.Brand className="my-0 mr-md-auto font-weight-normal">
            {
              roleUser ? (
              <Link to={roleLink}>
              {props.backgroundHeader ? <FullLogoPasswordImg /> : <FullLogoImg />}
              </Link>
              ):(
              <Link to="/">
              {props.backgroundHeader ? <FullLogoPasswordImg /> : <FullLogoImg />}
              </Link>
              )
            }

          </Navbar.Brand>
          <Navbar.Toggle aria-controls="pray-cloud-navbar" >
            <MenuIcon style={{ color: "#499e9e" }}/>
          </Navbar.Toggle>
          <Navbar.Collapse id="pray-cloud-navbar" className="text-center">
            <Nav className="navbar-nav ml-auto">
              <hr className="border-1px d-block d-md-none" />
              {props.menu}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
