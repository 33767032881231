import { makeStyles } from "@material-ui/core/styles";

const FAQAccordionStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px 150px",
  },
  accordion: {
    border: "1px solid #AFDAF5",
    borderRadius: "4px",
    boxShadow: "none",
    marginBottom: "12px",
  },
  heading: {
    fontSize: "22px",
    fontWeight: "700",
    padding: "28px 60px", 
    fontFamily: "BrandonText",
    color: '#000000',
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      padding: "28px 40px", 
    },
  },
  body: {
    marginTop: "-20px", 
    padding: "0px 128px 44px 60px",
    fontFamily: "BrandonText",
    display: "flex",
    flexDirection: "column",
    fontSize: "18px",
    [theme.breakpoints.down("md")]: { 
      padding: "0px 40px 44px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 19px 44px",
      minWidth: "303px"
    },
  },
  bodyText: {
    fontFamily: "BrandonText",
    fontSize: "18px",
    color: '#000000',
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  bold: {
    fontWeight: "bold",
  },
  sectionTitle: {
    marginTop: "32px",
    marginBottom: "13px",
  },
  expanded: {},
  headingContainer: {
    height: "90px",
    padding: "0px",
    [theme.breakpoints.down("md")]: {
      height: "60px",
    },
  },
}));

export default FAQAccordionStyle;
