import React from "react";
import styled from "styled-components";
import PrayerPartnerAvatar from "@iso/assets/images/character_subscribe.svg"; 

import "@iso/assets/common.scss";
import { useHistory } from "react-router";

const Avatar = styled.img` 
  height: 347px;
  @media only screen and (max-width: 500px) {
    height: 280px;
    margin-top: -250px;
    z-index: 1;
    margin-left: 33px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 300px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "BrandonText";

  padding: 50px 40px 180px 40px;

  @media only screen and (max-width: 767px) {
    padding-bottom: 40px;
  }

  @media only screen and (min-width: 768px) {
    padding-top: 170px;
    height: 606px;
  }

  @media only screen and (min-width: 1025px) {
    padding: 150px 70px 130px 70px;
  }
  @media only screen and (min-width: 1920px) {
    padding: 150px 350px 150px 70px;
  }
`;

const RightSide = (props) => {
  const history = useHistory();

  const handleRequestNew = () => {
    history.push("/requests/step1");
  };

  return (
    <Wrapper>
      <Avatar src={PrayerPartnerAvatar}></Avatar>
    </Wrapper>
  );
};

export default RightSide;
