import React from "react";
import styled from "styled-components";

import Button from "@iso/components/AtomicComponents/Button.js";
import { useHistory } from "react-router";
import IntlMessage from "@iso/components/intlMessage";
import PopupBeta from "../../../../../actions/PopupBeta";

import gtag from "ga-gtag";
import { custom_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from "react-facebook-pixel";
import Heart from "@iso/assets/images/Heart.svg";
import "./index.scss";

ReactPixel.init(
  process.env.REACT_APP_FACEBOOK_PIXEL_ID,
  fb_pixel_event.advanced_matching,
  fb_pixel_event.options
);

const Section4Wrapper = styled.div`
  margin-top: 22px;
  margin-bottom: 48px;
  text-align: center;

  @media only screen and (max-width: 500px) {
    margin-top: 40px;
    margin-bottom: 75px;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 30px;
    margin-bottom: 41px;
  }

  @media only screen and (min-width: 1025px) {
    margin-top: 23px;
    margin-bottom: 67px;
  }
`;

const TitleWrapper = styled.div``;

const TitleMobileWrapper = styled.div``;

const CallToActionBtn = styled(Button)`
  width: 303px;
  font-family: "BrandonText";
  @media only screen and (min-width: 768px) {
    width: 250px;
    height: 60px;
    margin-left: 40px;
  }

  @media only screen and (max-width: 500px) {
    width: 250px;
    height: 60px;
    margin-left: 5px;
    padding: 10px 10px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 19px;

  @media only screen and (max-width: 500px) {
    margin-top: 29px;
  }
 
  @media only screen and (min-width: 768px) {
    margin-top: 38px;
  }

  @media only screen and (min-width: 1025px) {
    margin-top: 25px;
  }
`;

const TitleWrapperTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    padding-top: 10px;
    flex-direction: row;
    margin-left: 50px;
  }

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    margin-bottom: 15px;
    padding-top: 0px;
  }
`;
const Title = styled.div``;
const ImageWhoPrayer = styled.img`
  max-width: 178px;
  max-height: 75px;
  @media only screen and (min-width: 768px) {
    max-width: 190px;
    max-height: 80px;
    margin-bottom: 44px;
  }
  @media only screen and (min-width: 1025px) {
    max-width: 221px;
    max-height: 93px;
    margin-bottom: 0px;
  }
`;
const Section4 = (props) => {
  const history = useHistory();
  const [show, setShow] = React.useState(false);

  const handleRequestNew = () => {
    gtag("event", custom_events.CLICK_BUTTON, {
      button_label: "Request a Prayer (From Pricing page)",
    });
    ReactPixel.track(fb_pixel_event.custom_events.CLICK_BUTTON, {
      button_label: "Request a Prayer (From Pricing page)",
    });
    history.push("/requests/step1");
  };

  return (
    <Section4Wrapper className="prayer-parner-section-4">
      <div className="group-heart">
        <div>
          <Title className="section1_title heart-text-1">
            <IntlMessage id="user.form.prayer_pathner.bottom1.title" />
          </Title>
        </div>
        <div className="sub-group-heart">
          <img id="heart" src={Heart}></img>
          <Title className="section1_title heart-text-2">
            <IntlMessage id="user.form.prayer_pathner.bottom2.title" />
          </Title>
        </div>
      </div>
      <ButtonWrapper>
        <CallToActionBtn
          handleClick={() => handleRequestNew()}
          variant="primary"
        >
          <IntlMessage id="landing.request.new.prayer" />
        </CallToActionBtn>
      </ButtonWrapper>
      <PopupBeta show={show} setShow={setShow} />
    </Section4Wrapper>
  );
};

export default Section4;
