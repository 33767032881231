import React, { Suspense } from "react";
import { useParams } from "react-router-dom"; 
import IntlMessage from "@iso/components/intlMessage";
import { Row, Col, Card, Button, Badge, Container } from "react-bootstrap";
import Typography from "@material-ui/core/Typography"; 
import { useDispatch } from "react-redux";
import alertActions from "@iso/actions/Alert"; 
import "./Detail.scss";
import { COLOR_CODE } from "./constants";
import FinancialService from "@iso/services/FinancialService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import FinancialDetailBuilder from "./components/FinancialDetailBuilder"; 
import EditIcon from "@material-ui/icons/Edit";
import RecordModal from "./components/RecordModal" 
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import { history } from "@iso/helpers";
const { openAlert } = alertActions;
const FinancialTransDetail = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [requestValue, setRequestValue] = React.useState({ id });
  // const [openChangeStatusModal, setOpenChangeStatusModal] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [clickSubmit, SetClickSubmit] =React.useState(false)
  const getRequestData = () => {
    try {
      FinancialService.adminFinancialGetDetails(id).then((res) => {
        const { code, data, message } = res.data;
        ProcessRequest.run({
          res,
          successCallback: () => {
            setRequestValue(data);
          },
        });
      });
    } catch (error) {
      console.log(error)
    } finally {
    }
  };

  const pushHistoryTrans = () => {
    if(requestValue?.type === 0){
      history.push('/admin/financial?filter=users')
    }else{
      history.push('/admin/financial?filter=prayer_partners')
    }
  }

  const handleDeleteTrans = (transactionID) => {
    FinancialService.adminDeleteFinancial(transactionID).then((res) => {
      const { code, data } = res.data;
      if( code === 200){
        dispatch(
          openAlert({
            message: <IntlMessage id="common.message.update.success" />,
            severity: "success",
          })
        )
        pushHistoryTrans()
      }else{
        dispatch(
          openAlert({
            message: <IntlMessage id="common.message.update.fail" />,
            severity: "error",
          })
        )
      }

    })
  }
  React.useEffect(() => {
    getRequestData();
  }, [id, clickSubmit]);
  
  const handleSubmit = (formikValues) => {
    try{
      FinancialService.adminUpdateDetailsFinancial(formikValues).then((res) => {
        if(res){
          const { code, data} = res.data
          if( code === 200){
            dispatch(
              openAlert({
                message: <IntlMessage id="common.message.update.success" />,
                severity: "success",
              })
            )
          }else{
            dispatch(
              openAlert({
                message: <IntlMessage id="common.message.update.fail" />,
                severity: "error",
              })
            )
          }
        }
      });
    }catch(error){
      console.log(error)
    }finally{}
  };
  
  return (
    <div className="mint-tulip-background p-3 admin_support_request_wrapper">
      <Row className="my-2">
        <Col xs="12" sm="6" className="request_title">
          <h2>
            <IntlMessage id="sidebar.admin.financial.title" />
          </h2>
        </Col>
      </Row>


        <Card className="p-3 height_100per request_detail">
          <Row>
            <Col xs="12" sm="6" md="7">
              <h3 className="font-weight-bold">
                <IntlMessage
                  id="admin.financial.detail.id"
                  values={{ idNumber: requestValue.id }}
                />
              </h3>
            </Col>
            <Col xs="12" md="5" className="hover_pointer text-right mb-3">
              <Row className = "mt-2 mt-sm-0">
                <Col xs="12" sm="6"  md="4" className="text-center text-md-right mb-3" >
                  {/* <Button  className="hippie-blue-button app_btn" onClick={() => setOpenChangeStatusModal(true)}>
                    <Typography variant="body2" noWrap>
                      <IntlMessage id="admin.financial.detail.change_status" />
                    </Typography>
                  </Button> */}
                </Col>
        
                <Col xs="12" sm="6" md="4" className="text-center text-md-right mb-3"  >
                    <Button className="edit_btn app_btn" onClick={() => setEdit(true)}>
                      <Typography variant="body2" noWrap className="pr-2">
                        <EditIcon className="pr-1 pb-1" />
                        <span>
                        <IntlMessage id="admin.financial.detail.edit" />
                        </span>
                      </Typography>
                    </Button>
                </Col>
        
                <Col xs="12" sm="6" md="4"  className="text-center text-md-right mb-3">
                  <Button className="delete_btn app_btn" onClick={() => setOpenDeleteModal(true)}>
                    <Typography variant="body2" noWrap>
                      <IntlMessage id="admin.financial.detail.delete" />
                    </Typography>
                  </Button>
                </Col>   
              </Row>
            </Col> 
          </Row>

          <Row className="request_status_wrapper">
            <Col sm="12">
              <span>
                <IntlMessage id="admin.requests.details.status" />
              </span>
              <Badge
                className={`${requestValue.status} ml-3 text-capitalize p-1 status`}
                style={{ backgroundColor: COLOR_CODE[requestValue.status] }}
              >
                <IntlMessage
                  id={`admin.financial.detail.status.${requestValue.status}`}
                />
              </Badge>
            </Col>
          </Row>

          <FinancialDetailBuilder requestValue={requestValue} />
         </Card>
         <RecordModal
          open={isEdit}
          handleClose={() => setEdit(false)}
          isEditBtn={true}
          editValue={requestValue}
          handleSubmit={handleSubmit}
          setEdit={setEdit}
          SetClickSubmit={SetClickSubmit}
          clickSubmit={clickSubmit}
        /> 


          <ConfirmModal
            open={openDeleteModal}
            handleConfirm={() => handleDeleteTrans(requestValue.id)}
            handleClose={() => setOpenDeleteModal(false)}
            title={<IntlMessage id="confirm_modal.delete.transaction.title" />}
            content={<IntlMessage id="confirm_modal.delete_transaction.content" />}
            okText={<IntlMessage id="common.yes" />}
            cancelText={<IntlMessage id="common.no" />}
          />
        
    </div>
  );
};

export default FinancialTransDetail;
