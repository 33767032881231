import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import "./style/BouncedEmailModal.scss";
import { Button } from "react-bootstrap";
import { ResendEmailValidator } from './validation/validator'
import { useLocation } from 'react-router-dom';
import RequestService from "@iso/services/RequestService";
import { history } from "@iso/helpers/history";
import { store } from "@iso/store/root";
import alertActions from "@iso/actions/Alert";
const { openAlert } = alertActions;

function BouncedEmailModal(props) {
  let initValue = {
    newEmail: '',
    confirmedEmail: "",
  };
  let locationRoute = useLocation();
  const [isShowModal, setIsShowModal] = useState(false)
  const [token, setToken] = useState('')
  const [isShowEmailUpdated, setIsShowEmailUpdated] = useState(false)
  const [formikValues, setFormikValues] = React.useState(initValue);

  const handleClose = () => {
    setIsShowModal(false)
  }
  const handleSubmit = () => {
    RequestService.updateRecipientEmail({
      new_recipient_email: formikValues.newEmail,
      token: token
    }).then((res) => {
      const { code, data } = res.data;
      if (code == 200) {
        setIsShowEmailUpdated(true)
      } else {
        setIsShowModal(false)
      }
    })
  }
  const handleOnChangeField = (e) => {
    const field = e.target.name
    const value = e.target.value
    setFormikValues({ ...formikValues, [field]: value })
  }
  const goToHomePage = () => {
    history.push('/')
  }

  useEffect(() => {
    const params = new URLSearchParams(locationRoute.search);
    if (locationRoute.pathname === '/prayer-request/update-recipient-email'
      && params.get('token')) {
      RequestService.checkUpdateEmailToken(params.get('token')).then((res) => {
        const { code } = res.data;
        if (code == 200) {
          setToken(params.get('token'))
          setIsShowModal(true)
        } else {
          goToHomePage()
          store.dispatch(
            openAlert({
              message: 'Link is expired',
              severity: "error",
            })
          );
        }
      })
    }
  }, [locationRoute]);


  return (
    <div>
      <Modal show={isShowModal} onHide={handleClose}>
        <Modal.Body style={{ padding: '0px' }}>
          {
            isShowEmailUpdated ? (
              <div className="bounced-email-modal">
                <p className="bounce-email-title">Address Updated!</p>
                <p className="result-msg">Your messages will be resent</p>
                <form noValidate>
                  <div className="form-container">
                    <div className="buttons text-center">
                      <Button id="home-btn" className="hippie-blue-button"
                        onClick={(e) => { e.preventDefault(); goToHomePage() }}>
                        Go to Homepage
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            ) :
              (
                <div className="bounced-email-modal">
                  <p className="bounce-email-title">Update Email Address</p>
                  <Formik
                    initialValues={formikValues}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    validationSchema={ResendEmailValidator}
                  >
                    {({ values, handleSubmit, handleChange, touched, errors }) => (
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="form-container">
                          <div className="input-group">
                            <input
                              id="newEmail"
                              name="newEmail"
                              placeholder="Enter recipient Email"
                              className="bounced-email-input"
                              value={formikValues.newEmail}
                              onChange={(e) => handleOnChangeField(e)}
                            />
                            <span className="error-msg">{touched.newEmail && errors.newEmail} </span>
                          </div>
                          <div className="input-group">
                            <input
                              id="confirmedEmail"
                              name="confirmedEmail"
                              placeholder="Confirm recipient Email"
                              className="bounced-email-input"
                              value={formikValues.confirmedEmail}
                              onChange={(e) => handleOnChangeField(e)}
                            />
                            <span className="error-msg">{touched.confirmedEmail && errors.confirmedEmail} </span>
                          </div>
                          <div className="buttons text-center">
                            <Button
                              id="submit-btn"
                              className="hippie-blue-button"
                              type="submit"
                            >
                              Resend Email
                            </Button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              )
          }
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default BouncedEmailModal;
