import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import SaveCardForm from "./SaveCardForm";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);

const SaveStripeCard = ({ toggleShowForm, successCallback }) => {
  return (
    <Elements stripe={stripePromise}>
      <SaveCardForm
        toggleShowForm={toggleShowForm}
        successCallback={successCallback}
      />
    </Elements>
  );
};

export default SaveStripeCard;
