import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQAccordionStyle from "./AccordionStyle";
import{ Link } from "react-router-dom"

const PrayerPartnerFAQ = (props) => {
  const { handleChange, expanded } = props;
  const classes = FAQAccordionStyle();
  return (
    <>
      <Accordion
        className={`${classes.accordion} ${classes.expanded} section-prayer-partner`}
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          className={classes.headingContainer}
          expandIcon={
            <ExpandMoreIcon style={{ fill: "#AFDAF5" }} fontSize="large" />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Prayer Partners</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            What is a Prayer Partner?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`A Prayer Partner is an advocate who
            is responsible for carrying out your
            prayer requests. Prayer Partners
            empathize with your needs, listen and
            pray on your behalf. They are chosen
            for their religious devotion,
            knowledge, as well as their readiness
            and desire to help those in need. They
            are not clergy members, but they are
            religiously devout and informed. To
            learn more about Prayer Partners,
            visit our `}<Link to='/about' className="text-hippie-blue">About Page</Link>.
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            {`What is the religious background of
            the Prayer Partner Community?`}
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
           {` PrayerCloud welcomes all users to
           our platform, regardless of their
            religious background, and our Prayer
            Partners reflect a range of beliefs.
            We are further working to expand our
            network of Prayer Partners and
            denominations. As you move through
            the request process, you will be able
            to select the denomination of your
            Prayer Partner.`}
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle} faq-content`}
          >
            Can I request the same Prayer Partner again?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            Yes. If you were happy working with a previous Prayer Partner, you can request them again,
            and we will do our best to accommodate you. Please include this wish and their first name in the
            ‘special request’ area under custom options. Please allow for a little extra time to make sure that
            this Prayer Partner is available.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PrayerPartnerFAQ;
