import React from "react";
import { Row, Col, Navbar, Nav } from "react-bootstrap";
import { history } from "@iso/helpers/history";
import { Link } from "react-router-dom";
import IntlMessage from "@iso/components/intlMessage";
import Header from "./Share/Header"
import { getUser } from "@iso/actions/User"
import { userRoleConstants } from '@iso/constants';
import NotificationService from "@iso/services/NotificationService";
import notificationActions from "@iso/actions/notificationActions" 
import SupportCenterService from "@iso/services/SupportCenterService";
import contactActions from "@iso/actions/contactActions" 
import { useSelector, useDispatch } from "react-redux";
import { Badge } from "react-bootstrap";

const PrivateHeader = (props) => {
  const { handleLogout, login_path, menuSidebar } = props;
  let user = getUser();
  const [ toggleSuportCenter, setToggleSuportCenter ] = React.useState(false)
  const [ toggleReport, setToggleReport ] = React.useState(false)
  const [ toggleFinancial, setToggleFinancial ] = React.useState(false)

  const dispatch =  useDispatch();
  const alertStore = useSelector((state) => state.alert);

  const handleClick = (path, role) => { 
    history.push(`${path}?filter=${role}`); 
  }

  const myPage = () => {
    switch (user?.role) {
      case userRoleConstants.SUPER_ADMIN_ROLE:
        return `/admin/DetailsAdmin/${user.id}`
      case userRoleConstants.PRAYER_ROLE:
        return "/prayer_partners/account_settings";
      default:
        return "#";
    }
  }

  const setToggle = (section) => {
    switch(section){
      case 'report':
        setToggleReport(!toggleReport)
        break;
      case 'support_centers':
        setToggleSuportCenter(!toggleSuportCenter)
        break;
      case 'financial':
        setToggleFinancial(!toggleFinancial)
        break;
    }
  }

  const getToggle = (section) => {
    switch(section){
      case 'report':
        return toggleReport; 
      case 'support_centers': 
        return toggleSuportCenter; 
      case 'financial': 
        return toggleFinancial; 
    }
  }

  if (!user) {
    user = {
      first_name: "",
      last_name: "",
    };
  }
  const contactState = useSelector((state) => state.contactReducers);
  const [numContact, setNumContact] = React.useState(0);
  
  React.useEffect(() => {
    if (!user.email) {
      sessionStorage.setItem("previous_path", window.location.pathname);
      history.push(login_path);
    }
    setNumContact(contactState.numberOfContact);
  });
  
  const renderSubMenu = (item) => {
    return (
      <>
      <div
      className="text-dark d-block d-md-none py-2" 
      onClick = {() => setToggle(item.section)}
      >
        <span>
            <IntlMessage id={item.title} />
                {item?.section == 'support_centers' && numContact > 0 &&
                  <Badge className="ml-2" pill variant="danger">
                    {numContact}
                  </Badge>
                }
                        
        </span>
      </div>

      <div className={`text-dark ${getToggle(item.section)   ? 'd-block d-md-none':'d-none'}`}>
        {
          item.subMenu.map((subMenuItem, index) => {
            return ( 
              <Nav.Link className="text-dark d-block d-md-none" href = {`${subMenuItem.subPath}`}>
                <IntlMessage id={subMenuItem.title} />
              </Nav.Link>
            )
          })
        }
      </div>
    </>
    )
  }


  React.useEffect(() => { 
      if (user?.role === userRoleConstants.PRAYER_ROLE) {
        NotificationService.getUnreadNoti().then((res) => {
          dispatch(notificationActions.updateNoti(res?.data?.total_unread))
        })
      }
      if (user?.role === userRoleConstants.ADMIN_ROLE || user?.role === userRoleConstants.SUPER_ADMIN_ROLE) {
        SupportCenterService.adminSupportCenterGetListRequests('all','all','','' ).then((res) => {
          dispatch(contactActions.updateNumberOfContact(res?.data?.count?.pending))
        })
      }
  },[alertStore])
  const renderMenu = () => {
    return(
      <>
      {menuSidebar &&
        menuSidebar.map((item, index) => {
          return (
            <>
            {
              !item.subMenu &&  ( !item.role || (item?.role && item?.role === user?.role)) && (

                <Nav.Link
                className="text-dark d-block d-md-none"
                key={index}
                href={item.path}
                >
                <IntlMessage id={item.title} />
              </Nav.Link>
              ) 
            }

            {
              item.subMenu &&  ( !item.role || (item?.role && item?.role === user?.role)) && (
                renderSubMenu(item)  
              )
            }
            </>
          )
      })}
      
      <Nav.Link
        href={myPage()}
        className="text-dark d-none d-sm-block"
      >
        {`${user.first_name} ${user.last_name}`}
      </Nav.Link>

      <Nav.Link
        href="#!"
        onClick={handleLogout}
        className="hippie-blue-link font-weight-bold"
      >
        Log out <span className='d-block d-md-none'> ({`${user.first_name} ${user.last_name}`})</span>
      </Nav.Link>
      </>
    );
  }
  return (
    <Header menu={renderMenu()}>   
    </Header>
  );
};

export default PrivateHeader;
