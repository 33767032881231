import * as Yup from "yup";

const NotificationFormValidator = Yup.object().shape({
  receive_sms: Yup.boolean(),
  receive_email: Yup.boolean(),
  notify_request: Yup.boolean(),
  notify_reminder: Yup.boolean(),
});

export { NotificationFormValidator };
