export const NAME_MIN_LENGTH = 2;
export const NAME_MAX_LENGTH = 20;
export const TAG_MIN_LENGHT = 50;
export const TAG_MAX_LENGHT = 150;
export const BIRTH_DATE_FORMAT = "YYYY-MM-DD";

export const popupConfig = {
  success: {
    title: "prayer_partners.account_settings.profile.save_success.title",
    description:
      "prayer_partners.account_settings.profile.save_success.description",
    ok: true,
  },
  confirm: {
    title: "prayer_partners.account_settings.profile.confirm.title",
    description: "prayer_partners.account_settings.profile.confirm.description",
    ok: true,
    cancel: true,
  },
  tagline: {
    title: "prayer_partners.account_settings.profile.tagline_popup.title",
    description:
      "prayer_partners.account_settings.profile.tagline_popup.description",
    ok: true,
  },
};

export const FIELD_TO_DISPLAY_ACTION_BUTTON_AVATAR = ["pending"];
export const FIELD_TO_DISPLAY_PREVIOUS_AVATAR = ["reject", "approved"];
export const FIELDS_AND_STATUS_DISPLAY_APPROVE_ALL_CHANGE_BUTTON = [
  { title: "first_name_status", status: "pending" },
  { title: "last_name_status", status: "pending" },
  { title: "phone_number_status", status: "pending" },
  { title: "religion_status", status: "pending" },
  { title: "birth_date_status", status: "pending" },
  { title: "city_status", status: "pending" },
  { title: "country_status", status: "pending" },
  { title: "tagline_status", status: "pending" },
];
