import { userConstants } from "@iso/constants"

export const setCardData = (is_default_card, token) => {
  return {
    type: userConstants.SET_CARD,
    payload: {
      is_default_card,
      token
    },
  }
};

export const removeCardData = () => {
  return {
    type: userConstants.REMOVE_CARD,
    payload: {},
  }
};

export const setIsSaveCard = (isSaveCard) => {
  return {
    type: userConstants.SET_IS_SAVE_CARD,
    payload: isSaveCard,
  }
};
