import axios from "axios";
import { apiEndpoints } from "@iso/helpers";

export const adminFinancialGetListRequests = (filter,status) => {
  return axios.get(apiEndpoints.admin_financial, {
      params: {
        filter: filter,
        status: status,
      },
    });
  };

export const adminFinancialExport = (filter, status) => {
  return axios.get(`${apiEndpoints.admin_financial}/export`, {
    params: {
      filter: filter,
      status: status,
    },
  });
  };

export const adminFinancialGetDetails  = (id) => {
  return axios.get(`${apiEndpoints.admin_financial}/${id}/show`);
}

export const adminSetDetailsFinancial = (data) => {
  return axios.post(`${apiEndpoints.set_admin_financial}`, data);
}

export const adminFinancialGetPrayerPartnerList = () => {
  return axios.get(`${apiEndpoints.get_pp_list}`)
}

export const adminFinancialGetRequestIDList = () => {
  return axios.get(`${apiEndpoints.get_requestID_list}`)
}


export const adminUpdateDetailsFinancial = (data, filter) => {
  //return axios.put(`${apiEndpoints.admin_financial}/${data.requestID}/partner`,data)
  return axios.put(`${apiEndpoints.admin_financial}/${data.requestID}/${filter}`,data)

}

export const adminUpdateDetailsUserFinancial = (data) => {
  return axios.put(`${apiEndpoints.admin_financial}/${data.id}/user`)
}

export const adminMarkPaidFinancial = (data, is_revert = false) => {
  return axios.put(`${apiEndpoints.admin_financial}/${data.id}/mark-paid`, {is_revert})
}


export const adminDeleteFinancial = (id) => {
  return axios.delete(`${apiEndpoints.admin_financial}/${id}`)
}
export default {
  adminFinancialGetListRequests,
  adminFinancialExport,
  adminFinancialGetDetails,
  adminUpdateDetailsFinancial,
  adminFinancialGetPrayerPartnerList,
  adminFinancialGetRequestIDList,
  adminSetDetailsFinancial,
  adminUpdateDetailsUserFinancial,
  adminMarkPaidFinancial,
  adminDeleteFinancial
};
