import * as Yup from "yup";
import { coupon_const } from "@iso/constants/validator";
import axios from "axios"

const AddCouponFormValidator = Yup.object().shape({
    check_all_contribution: Yup.boolean(),
    check_all_frequency: Yup.boolean(),
    name: Yup.string()
        .label("Name")
        .trim()
        .required()
        .test(
            "minLength",
            "Name is invalid! Please check your input!",
            (val) => val && val.length >= coupon_const.NAME_MIN_LENGTH
        )
        .test(
            "maxLength",
            "Name is invalid! Please check your input!",
            (val) => val && val.length <= coupon_const.NAME_MAX_LENGTH
        )
        .test('checkExistName', 'Name is already taken', function (value) {
            if (value) {
                return new Promise((resolve, reject) => {
                    return axios.get("/admin/coupons/check_exist_name_code", { params: { name: value } }).then((res) => {
                        if (res.data && res.data.is_exist)
                            resolve(false)
                        else
                            resolve(true)
                    }).catch(() => {
                        // note exists
                        resolve(true)
                    })
                })
            }
        }),
        // .matches(/^[A-Za-z ]+$/, 'The field should have letters only'),
    code: Yup.string()
        .label("Code")
        .trim()
        .required()
        .test(
            "minLength",
            "Code is invalid! Please check your input!",
            (val) => val && val.length >= coupon_const.NAME_MIN_LENGTH
        )
        .test(
            "maxLength",
            "Code is invalid! Please check your input!",
            (val) => val && val.length <= coupon_const.NAME_MAX_LENGTH
        )
        .test('checkExistCode', 'Code is already taken', function (value) {
            if (value) {
                return new Promise((resolve, reject) => {
                    return axios.get("/admin/coupons/check_exist_name_code", { params: { code: value } }).then((res) => {
                        if (res.data && res.data.is_exist)
                            resolve(false)
                        else
                            resolve(true)
                    }).catch(() => {
                        // note exists
                        resolve(true)
                    })
                })
            }
        })
        .matches(/^[a-zA-Z0-9_.-]*$/, 'The field should have letters and numbers only'),
    contribution_ids: Yup.array().label("Contribution")
    .when("check_all_contribution", {
        is: false,
        then: Yup.array()
        .required("Contribution is required")
    }),
    frequency_ids: Yup.array().label("Frequency")
    .when("check_all_frequency", {
        is: false,
        then: Yup.array()
        .required("Frequency is required")
    }),
    limit: Yup.number().label("Limit").required().min(1),
    type: Yup.string().label("Type").required(),
    discount_value: Yup.number()
        .label("Discount Value")
        .when("type", {
            is: 'discount_by_percent',
            then: Yup.number().required("Discount Value is a required field").min(1).max(100)
            .test(
                "maxDigitsAfterDecimal",
                "Less than or equal to 2 decimals",
                (number) => /^\d+(\.\d{1,2})?$/.test(number)
            )
        })
        .when("type", {
            is: 'discount_by_money',
            then: Yup.number().required("Discount Value is invalid").min(1)
            .test(
                "maxDigitsAfterDecimal",
                "Less than or equal to 2 decimals",
                (number) => /^\d+(\.\d{1,2})?$/.test(number)
            )
        })
})

export { AddCouponFormValidator }