import React, { useEffect } from "react";
import IntlMessage from "@iso/components/intlMessage";
import Stripe from "@iso/components/Stripe/Stripe";
import Paypal from "@iso/components/Paypal/Paypal";
import styled from 'styled-components'
import { Row, Col, Button } from "react-bootstrap";

import imageVisaMaster from "@iso/assets/images/visa_master.png";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import "./PayMethodCard.scss";
import { CircularProgress } from "@material-ui/core";
import masterCardLogo from "@iso/assets/images/masterCardLogo.svg";
import visaLogo from "@iso/assets/images/visaLogo.svg";
import paypalLogo from "@iso/assets/images/paypal-icon.svg";
import stripeActions from "@iso/actions/Stripe";
import buttonAdd from "@iso/assets/images/button_add.svg";
import {isTablet} from "react-device-detect";
import { isMobile } from "react-device-detect";
import PowerStriple from "@iso/assets/images/powerStriple.svg";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "../../../../../components/Stripe/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);
const AddPaymentMethodWrapper = styled(Row)`
border-radius: 6px;

border: 1px solid #DBDEE7;
`

const PayMethodCard = ({
  afterAuthorize,
  price,
  loadingPayment,
  setLoadingPayment,
  requestErrors,
  setShowCardDialog,
  defaultCard,
  paymentCards,
  isAddCard = false,
  setAddCard,
  errorReceiveEmail,
  formConfirmData,
  requestId,
  setPayButtonClicked,
  emailExisted,
  openSignInModal,
  couponCodeError,
}) => {
  // const [showStripe, setShowStripe] = React.useState(true);
  const [error, setError] = React.useState("");
  const [errorReceive, setErrorReceive] = React.useState("");
  const dispatch = useDispatch();
  const { user, loggedIn } = useSelector((state) => state.authentication);
  const { errors: stripeErrors } = useSelector(
    (state) => state.stripe,
    shallowEqual
  );

  const handleStripeError = (errorValue) => {
    setError(errorValue?.message);
    dispatch(stripeActions.setLoadingPayment(false));
  };

  // const stripeErrorsPresent = Object.keys(stripeErrors).length > 0;
  const { setCardToken } = stripeActions;

  let img= visaLogo;
  if (defaultCard?.card?.card?.brand === "mastercard") {
    img = masterCardLogo;
  }
  const handlesubmitBtn = () => {
    dispatch(setCardToken(defaultCard?.card?.id));
  }

  const handleClickChange = () => {
    if(paymentCards && paymentCards.length > 0){
      setShowCardDialog(true)
    }
  }

  const submitFormBtn = () => {
    if(!loggedIn){
      setErrorReceive(errorReceiveEmail)
    }
    setPayButtonClicked(true)
  }

  useEffect(() => {
    setErrorReceive("")
  }, [errorReceiveEmail])

  useEffect(() => {
    if(loggedIn){
      setErrorReceive("")
    }
  }, [loggedIn])

  const renderAddCardForm = () => {
    return (
    <>
      {/* Add card form */}
      {
        // (!paymentCards || paymentCards?.length == 0 ) &&
        <Row className={` ${!isMobile && "mb-5" } justify-content-center custom_visa_master_img`}>
          <Col lg="5" md="5" xs = "6"
            className={`text-center mb-4 mb-xl-0 pb-2 ml-firefox border-bottom-hippie-blue`}
          >
            <span
              className="visa_master_img_wrapper hover_pointer"
            >
              <img src={imageVisaMaster} />
            </span>
          </Col>
          <Col lg="5" md="5" xs = "6" className="text-center paypal-container ml-3 custom_paypal_img" style={{position: 'relative'}}>
            {errorReceiveEmail ? <div className="paypal-btn-temp"><img src={paypalLogo}/></div> :
            <Paypal afterAuthorize={afterAuthorize} requestId={requestId} price={price}/>}
          </Col>
        </Row>
      }

        <Stripe
          handleStripeError={handleStripeError}
          price={price}
        />


      <Row className="form-group m-float-group mt-5" >
        {/* {error.length > 0 && (
          <p className="text-center text-danger w-100">{error}</p>
        )} */}
        <Col xs="12" className="text-center d-flex justify-content-center">
          <Button
            className="hippie-blue-button pay_btn d-flex align-items-center justify-content-center"
            type="submit"
            disabled={errorReceive}
            onClick={submitFormBtn}
          >
            <b className="text-uppercase">
              <IntlMessage id="common.pay" />
            </b>
            {loadingPayment && (
              <CircularProgress size={30} className="ml-2"></CircularProgress>
            )}
          </Button>
        </Col>
        {errorReceive && (
            <p className="text-danger px-4">
              <IntlMessage id={errorReceive} />
              {emailExisted && (
                <span>
                  Please&nbsp;
                  <span className='login-span' onClick={openSignInModal}>
                    Sign-In
                  </span>
                </span>
              )}
            </p>
        )}
        {requestErrors && (
          <p className="text-danger mt-3 p-4">
            <IntlMessage id="common.somethings_went_wrong_recheck" />
          </p>
        )}
        {couponCodeError && (
          <p className="text-danger mt-3 p-4">
            { couponCodeError === 1
              ? <IntlMessage id="user.coupons.check_code.error.not_exist" />
              : <IntlMessage id="user.coupons.check_code.error.not_accept" />
            }

          </p>
        )}
      </Row>
    </>
    )
  }
  return (
      <Col xs="12" id="paymentMethodwrapper" className={` ${isAddCard && "custom_payment_method_wrapper"} ${user ? "payment-user" : "payment-none-user"} card_payment_method_wrapper `}>
        <Col xs="12" id="cardPayMethodeWrapper">
          {/* Default */}
        {
          (!paymentCards || paymentCards?.length == 0) &&
          renderAddCardForm(errorReceiveEmail)
        }

        {
          paymentCards && paymentCards.length > 0 &&
          <>
            <AddPaymentMethodWrapper className="mb-4 custom_add_payment_method_wrapper">
              <Col xs="6" className="mt-3">
                <div className="paymentMethodTitle"><IntlMessage id ="public.request.step3.right.payment.method" /></div>
              </Col>

              <Col xs="6" className="d-flex justify-content-end hippie-btn font-size-20px font-weight-bold mt-3" onClick={handleClickChange}>
                <IntlMessage id ="public.request.step3.right.change.button" />
              </Col>

              {
             !isAddCard &&
             <>
              <Row className="mb-3 row ml-0 mt-2">
                <Col>
                  <img src={img} alt="Card Type" />
                  <span className="font-size-18px ml-2 hippie-btn">
                    <IntlMessage id="public.request.step3.right.ending.last4"
                      values={{ last4: defaultCard?.card?.card?.last4 }}/>
                  </span>
                </Col>
              </Row>
              </>
            }

            {
              isAddCard &&
              <>
              {/* Button */}
                <Row className="mb-3 row ml-0 mt-2">
                  <Col onClick={()=> {
                    setAddCard(true)
                    }}>
                    <img src={buttonAdd} alt="Add Button"/>
                    <span className="font-size-18px ml-3" style={{color: "#6D7278"}}><IntlMessage id="public.request.step3.right.add.card" /> </span>
                  </Col>
              </Row>
              </>
            }
            </AddPaymentMethodWrapper>
            {/* Pay button */}
            {
              !isAddCard &&
              <Row className="pay-button-container form-group m-float-group mt-5" style={{margin: '0 -27px'}}>
                {/* {error.length > 0 && (
                  <p className="text-center text-danger w-100">{error}</p>
                )} */}
                <Col xs="12" className="custom_btn text-center d-flex justify-content-center">
                  <Button
                    className="hippie-blue-button pay_btn d-flex align-items-center justify-content-center"
                    type="button"
                    onClick ={handlesubmitBtn}
                  >
                    <b className="text-uppercase">
                      <IntlMessage id="common.pay" />
                    </b>
                    { !isAddCard && loadingPayment && (
                      <CircularProgress size={30} className="ml-2"></CircularProgress>
                    )}
                  </Button>
                </Col>
                {requestErrors && (
                  <p className="text-danger mt-3 p-4">
                    <IntlMessage id="common.somethings_went_wrong_recheck" />
                  </p>
                )}

                {couponCodeError && (
                  <p className="text-danger mt-3 p-4">
                    <IntlMessage id="user.coupons.check_code.error" />
                  </p>
                )}
              </Row>
            }

            {!isAddCard &&
            <Elements stripe={stripePromise}>
              <CheckoutForm showForm={false} handleStripeError={handleStripeError} price={price}/>
            </Elements>
            }
          </>
        }

        </Col>
        {
          isAddCard &&
          renderAddCardForm()
        }
      </Col>
  );
};

export default PayMethodCard;
