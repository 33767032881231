import React from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { Row, Col } from "react-bootstrap";
import {  useSelector } from "react-redux";
import { userRoleConstants } from "@iso/constants";
import {
  FIELD_TO_DISPLAY_ACTION_BUTTON_AVATAR,
  FIELD_TO_DISPLAY_PREVIOUS_AVATAR,
} from "./constants";
import { USER_PERMISSIONS } from "@iso/constants";
import ButtonAvatarActionGroup from "./ButtonAvatarActionGroup";
import StatusText from "./StatusText";

import withAuthorization from "@iso/utils/withAuthorization";
import noAvatarImage from "@iso/assets/images/no-avatar.png";

const ProfileAvatar = (props) => {
  const ButtonAvatarActionGroupWithAuth = withAuthorization(
    USER_PERMISSIONS.SUPER_USER
  )(ButtonAvatarActionGroup);
  const user = useSelector((state) => state.authentication.user);
  const StatusTextWithAuth = withAuthorization(USER_PERMISSIONS.SUPER_USER)(
    StatusText
  );

  const { profile } = props;
  const avatarImg = () => {
    if (
      FIELD_TO_DISPLAY_PREVIOUS_AVATAR.find(
        (item) => item === profile.avatar_status
      )
    ) {
      return profile.avatar || noAvatarImage;
    } else {
      return profile.avatar_pending;
    }
  };

  return (
    <div className="image_wrapper mb-3 mb-md-0 profile_avatar_c">
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        className="avatar"
      >
        <Avatar
          src={avatarImg()}
          sizes={"large"}
          className={`w-100 h-100 mx-auto avatar_border border_${profile.avatar_status}`}
        />
      </Badge>

      <Row className="mt-3 mb-3 mw-100 m-0">
        <Col
          sm="12"
          className={`text-center p-0 text_${profile.avatar_status} m-1`}
        >
          <Col sm="12" className="mb-3">
            <StatusTextWithAuth
              displayCondition={profile.avatar_status}
              itemStatus={profile.avatar_status}
              hideText={profile.avatar_status === "reject"}
            />
          </Col>
        </Col>

        {FIELD_TO_DISPLAY_ACTION_BUTTON_AVATAR.find(
          (item) => item === profile.avatar_status
        ) &&  user?.role === userRoleConstants.SUPER_ADMIN_ROLE && <ButtonAvatarActionGroupWithAuth profile={profile} />}
      </Row>
    </div>
  );
};

const MemoizedProfileAvatar = React.memo(ProfileAvatar);

export default MemoizedProfileAvatar;
