import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Collapse,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import { Formik } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import IntlMessage from "@iso/components/intlMessage";
import { useDispatch } from "react-redux";


import {
  initialValues,
  itemLeavePeriodsDefault,
  settings,
  timezones,
  DEFAULT_TIMEZONE,
} from "@iso/components/PrayerPartner/Availability/constants";

import LeavePeriods from "@iso/components/PrayerPartner/Availability/LeavePeriods";
import "./styles.scss";
import {
  getAvailabilities,
  updateAvailabilities,
} from "@iso/PrayerPartner/api_v1";
import alertActions from "@iso/actions/Alert";
import { localTimezone } from "@iso/utils/GetLocalData";
const { openAlert } = alertActions;

const Availability = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  const [availabilites, setAvailabilities] = useState(null);
  const [leavePeriods, setLeavePeriods] = useState([]);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    try {
      if (!values.timezone) values.timezone = localTimezone().code;
      updateAvailabilities(values).then((res) => {
        if (res.data.code === 200) {
          dispatch(
            openAlert({
              message: " Availability successfully update!",
              severity: "success",
            })
          );
          setIsEdit(false);
        } else {
          dispatch(
            openAlert({
              message: "Incorrect information!",
              severity: "error",
            })
          );
        }
      });
    } catch (err) {
      dispatch(
        openAlert({
          message: "Incorrect information!",
          severity: "error",
        })
      );
    }
  };

  const getDataAvailabilities = () => {
    getAvailabilities().then((res) => {
      if (res && res.data) {
        const { data } = res.data;
        if (data) {
          setAvailabilities(data);
        }
        if (data?.day_off && data.day_off.length > 0) {
          setLeavePeriods([...data.day_off, ...itemLeavePeriodsDefault]);
        } else {
          setLeavePeriods(itemLeavePeriodsDefault);
        }

        if (data?.timezone) {
          const tmpTimezone = timezones.filter(
            (item) => item.code === data.timezone
          );
          if (tmpTimezone.length > 0) setTimezone(tmpTimezone[0]);
        }
      }
    });
  };

  useEffect(() => {
    getDataAvailabilities();
  }, []);

  const { time, day } = settings;

  const defaultValue = null;

  const cancelEdit = (resetForm) => {
    setIsEdit(false);
    resetForm();
    let data = availabilites;
    if (data?.timezone) {
      const tmpTimezone = timezones.filter(
        (item) => item.code === data.timezone
      );
      if (tmpTimezone.length > 0) setTimezone(tmpTimezone[0]);
    } else {
      setTimezone(localTimezone);
    }
  };

  return (
    <div className="p-3 availability">
      <Card.Title className="text-dark title p-0 m-0 px-3">
        <span>
          <IntlMessage id="prayer_partners.account_settings.availability.title" />
        </span>
        <Button
          className="float-right hover_pointer btn hippie-blue-link edit_btn"
          onClick={() => setIsEdit(true)}
        >
          <EditIcon className="pr-1" />
          <IntlMessage id="prayer_partners.account_settings.profile.btn.edit" />
        </Button>
      </Card.Title>
      <Collapse in={true}>
        <Card.Body>
          <h5>
            <IntlMessage id="prayer_partners.account_settings.availability.subtitle" />
          </h5>
          <Formik
            // validationSchema={{}}
            onSubmit={handleSubmit}
            initialValues={availabilites || initialValues}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setValues,
              setFieldValue,
              resetForm,
            }) => {
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group as={Row} className="mb-3 justify-content-md-end">
                    <Col sm="3"></Col>
                    <Col sm="9" className="p-0">
                      <Form.Group as={Row} className="mb-3 day-header px-0">
                        {Object.entries(day).map(([key, value]) => (
                          <div className="day-title" key={key}>
                            <span>{value}</span>
                          </div>
                        ))}
                      </Form.Group>
                    </Col>
                  </Form.Group>

                  {Object.entries(time).map(
                    ([keyTime, timeValue], indexTime) => (
                      <Form.Group
                        as={Row}
                        className="mb-3 day-body-container"
                        key={indexTime}
                      >
                        <Col sm="3">
                          <p className="noWrap">{timeValue}</p>
                        </Col>

                        <Col sm="9" className="p-0">
                          <Form.Group as={Row} className="mb-3 day-body">
                            {Object.entries(day).map(([key, value], index) => (
                              <div className="day-title" key={index}>
                                <Checkbox
                                  disabled={!isEdit}
                                  color="default"
                                  inputProps={{ "aria-label": "checked" }}
                                  name={`time_weekly.${keyTime}[${index}]`}
                                  checked={
                                    values?.time_weekly &&
                                    values?.time_weekly[keyTime][index]
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            ))}
                          </Form.Group>
                        </Col>
                      </Form.Group>
                    )
                  )}
                  <Form.Group
                    as={Row}
                    className="mb-3 justify-content-md-end day-body-container"
                  >
                    <Col sm="3">
                      <p>
                        <IntlMessage id="prayer_partners.account_settings.availability.timezone" />
                      </p>
                    </Col>

                    <Col sm="9">
                      <Autocomplete
                        disabled={!isEdit}
                        id="timezone"
                        className="timezone-select"
                        options={timezones}
                        getOptionLabel={(option) => `${option.label}`}
                        defaultValue={defaultValue || timezone}
                        value={timezone}
                        onChange={(e, value) => {
                          if (value) {
                            setFieldValue("timezone", value.code);
                            setTimezone(value);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="timezone"
                            variant="outlined"
                            placeholder="Timezone"
                            fullWidth={true}
                          />
                        )}
                      />
                    </Col>
                  </Form.Group>

                  <LeavePeriods
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    setValues={setValues}
                    leavePeriods={leavePeriods}
                    setLeavePeriods={setLeavePeriods}
                    isEdit={isEdit}
                  />

                  {isEdit && (
                    <Row className="justify-content-center card_info_control m-0 row">
                      <Button
                        className="card_info_btn_cancel btn_control mx-1 mb-1 btn btn-primary"
                        onClick={() => cancelEdit(resetForm)}
                      >
                        <IntlMessage id="prayer_partners.account_settings.profile.btn.cancel" />
                      </Button>
                      <Button
                        type="submit"
                        disabled={isLoading}
                        className="btn_control hippie-blue-button mx-1 mb-1 btn btn-primary"
                      >
                        <IntlMessage id="prayer_partners.account_settings.profile.btn.save" />
                        {isLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </Row>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Collapse>
    </div>
  );
};

export default Availability;
