import checkemailActions from '@iso/actions/checkemailmodal'

const initialState =
{
  isShowModal: false,
  data: ""
};

export function checkemailmodal(state = initialState, action) {
  switch (action.type) {
    case checkemailActions.SHOW_CHECK_EMAIL_MODAL:
      return {
        isShowModal: true,
        data: action.data
      }
    case checkemailActions.HIDE_CHECK_EMAIL_MODAL:
      return initialState;
    default:
      return initialState;
  }
}
