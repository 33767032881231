import React from "react";
import styled from "styled-components";
import CandleLightingWhite from "@iso/assets/images/CandleLightingWhite.svg"
import CandleHandImage from '@iso/assets/images/CandleHandImage.png'
import IntlMessage from "@iso/components/intlMessage";

const Section3MobileContainer = styled.div`
  background-color: #AFDAF5;
  margin-top: 49px;
  padding: 43px 0px;
  margin-bottom: 10px;
`

const Title = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

const Description = styled.div`
  margin-bottom: 12px;
  text-align: center;

  font-family: BrandonText;
  font-style: normal;
  font-weight: 390;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #1C1634;
  padding: 0 48px;
`

const CandleImage = styled.div` 
  height: 97vw;
  text-align: center;
  margin-top: -45px;

  > img {
    width: auto;
    height: 90vw;
  }
`;


const Section3Mobile = (props) => {


  return (
    <Section3MobileContainer>
      <Title>
        <img src={CandleLightingWhite} />
      </Title>
      <Description>
        <IntlMessage id = {
          <>
            With this selection, your Prayer <br />
            Partner will light a candle on your <br />
            behalf while praying for you.
          </>
        } />
        <br />
        <IntlMessage id = {
          <>
          Candlelight is a symbol of our <br />
          prayer entering the light of God.
          </>
        } />
      </Description>

      <CandleImage >
        <img src={CandleHandImage} />
      </CandleImage>
    </Section3MobileContainer>
  )
}

export default Section3Mobile