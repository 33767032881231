import * as Yup from "yup"

const ResendEmailValidator = Yup.object().shape({
  newEmail: Yup.string()
    .label("Recipient email")
    .email("Please enter a correct email!")
    .required(),
  confirmedEmail: Yup.string()
    .label('Email confirmation')
    .oneOf([Yup.ref('newEmail'), null], 'Addresses don’t match')
    .required('Please enter the email confirmation!'),
})

export { ResendEmailValidator }
