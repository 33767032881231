import React from "react";
import { Row, Col, Button, FormGroup } from "react-bootstrap";
import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";
import IntlMessage from "@iso/components/intlMessage";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import { ResponseValidator } from "./validator";
import ProcessRequest from "@iso/utils/ProcessRequest";
import SupportCenterService from "@iso/services/SupportCenterService";

const SendResponseForm = (props) => {
  const { requestId, handleClose, successCallback } = props;
  let initValue = {
    message_response: "",
  };

  const handleSubmit = async (values, bag) => {
    SupportCenterService.adminSendResponse({...values, id: requestId}).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: false,
        successCallback: () => {
          handleClose();
          successCallback();
        },
        errorCallback: ({errors}) => {
          bag.setErrors(errors)
        }
      });
    });
  };

  return (
    <>
      <Formik
        initialValues={initValue}
        enableReinitialize={false}
        onSubmit={handleSubmit}
        validationSchema={ResponseValidator}
      >
        {({ handleSubmit, handleChange, touched, errors }) => (
          <Form>
            <FormGroup as={Row}>
              <Col sm="12" className="px-5">
                <div className="mt-2 mb-2">
                  <IntlMessage id="admin.support_center.modal.note" />
                </div>
                <TextField
                  id="outlined-multiline-static"
                  label="Your response"
                  multiline
                  rows={4}
                  onChange={handleChange}
                  variant="outlined"
                  name="message_response"
                  fullWidth
                  className="mt-3"
                />

                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="message_response"
                />
              </Col>
            </FormGroup>
            <div className="buttons text-center mt-4">
              <Button className="hippie-blue-button" type="submit">
                <IntlMessage id="common.send" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default SendResponseForm;
