import React from "react";

import {Col, Container, Row} from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import moment from "moment";

const ActivityLog = (props) => {
	const {
		logs
	} = props;
	const time_zone = -(new Date().getTimezoneOffset() / 60);
	function renderLogElement(log) {
		let role = log.user_role ? `(${log.user_role})` : '';
		let LogDate = moment.utc(log.created_at).utcOffset(time_zone).format('MMMM Do, YYYY');
		if(log.user_name === 'Guest'){
			return `<span> ${role} </span> ${log.action} <br/>	<small><span>${LogDate}</span></small>`;
		}else{
			return `<b>${log.user_name}</b> <span> ${role} </span> ${log.action} <br/>	<small><span>${LogDate}</span></small>`;
		}
	}

	if (logs.length <= 0 ){
		return '';
	}

	return (
		<>
			<hr className="separate_hr mt-5"/>
			<Container className="mt-5">
				<h2 className="text-center">
					{<IntlMessage id="admin.requests.details.activity_log"/>}
				</h2>

				<Row>
					<Col lg="2"/>
					<Col lg="8">
						<ul id="activity_log">
							{logs.map((log) => (
								<li>
									<p dangerouslySetInnerHTML={{__html: renderLogElement(log)}}/>
								</li>
							))}
						</ul>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default ActivityLog;
