import * as Yup from "yup";

const FormValidator = Yup.object().shape({
  is_gift: Yup.boolean(),
  reason: Yup.string()
    .label("Reason")
    .matches(/[A-Za-z]/, "Invalid input. Please try again.")
    .test(
      "maxLength",
      "Maximum 600 characters allowed",
      (val) => !val || val.length <= 600
    ),
  recipient_email: Yup.string()
    .label("Email")
    .when("is_gift", {
      is: true,
      then: Yup.string()
        .required("Email is required")
        .email("Please enter a correct email format"),
    }),
  recipient_name: Yup.string()
    .required()
    .label("Name")
    .matches(/[A-Za-z]/, "Invalid input. Please try again.")
    .test(
      "maxLength",
      "Name must be under 25 characters",
      (val) => val && val.length <= 25
    ),
  sender_name: Yup.string()
    .label("From")
    .when("is_gift", {
      is: true,
      then: Yup.string()
        .required("From is required")
        .matches(/[A-Za-z]/, "Invalid input! Please try again.")
        .test(
          "maxLength",
          "Name must be under 25 characters",
          (val) => val && val.length <= 25
        ),
    }),
  prayer_customization: Yup.object({
    special_request: Yup.string()
      .test(
        "maxLength",
        "Maximum 300 characters allowed",
        (val) => !val || val.length <= 300
      )
      .matches(/[A-Za-z]/, "Invalid input. Please try again."),
  }),
});

export { FormValidator };
