import React, { useEffect } from "react"
import Section1 from "./components/Section1"
import Section3 from "./components/Section3"
import Section4 from "./components/Section4"
import styled from 'styled-components'
import { isBrowser, isTablet } from "react-device-detect";
import "./styles.scss"
import PricingHeaderClouds from "./components/headerClouds"
import landing_clouds_seperator from '@iso/assets/images/landing_clouds_seperator.svg'
import Section3Mobile from "./components/Section3/Section3Mobile"


const BottomCloudContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  margin-top: -45px;

  > img {
    width: 100%;
  }

  @media only screen and (max-width: 767px) {
    transform: scale(1.4);
  }

  @media only screen and (min-width: 768px) {
    margin-left: 97px;
    margin-top: -64px;
    max-width: calc(100% - 80px);
  }

  @media only screen and (min-width: 880px) {
    margin-top: -80px;
  }

  @media only screen and (min-width: 1025px) {
    margin-left: 165px;
    margin-top: -121px;
    max-width: calc(100% - 80px - 25px);
  }

  @media only screen and (min-width: 1025px) and (max-width: 1280px) {
    margin-top: -100px;
  }

  @media only screen and (min-width: 1281px) and (max-width: 1440px) {
    margin-top: -110px;
  }

  @media only screen and (min-width: 786px) and (max-width: 1440px) {
    margin-left: 240px;
    max-width: calc(100% - 250px);
  }
`

const PricingPage = (props) => {
  // scroll to top when click link from about
  useEffect(() => {
    window.scrollTo(0, 0)
  });


  return (
    <>
      <div className={`container-full-width bg_blueSky`}>
        <div className="container-fixed">
          <PricingHeaderClouds></PricingHeaderClouds>
        </div>
      </div>

      <Section1></Section1>
      <div className={`container-full-width bg-blue-sky-half`}>
        <div className="container-fixed">
          {(isBrowser || isTablet) 
            ?
            <Section3 />
            :
            <Section3Mobile />
          }

        </div>
      </div>

      <BottomCloudContainer>
        <img src={landing_clouds_seperator}/>
      </BottomCloudContainer>
      
      <Section4></Section4>
    </>
  );
}

export default PricingPage;
