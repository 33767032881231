import React from "react";
import {
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import IntlMessage from "@iso/components/intlMessage";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TAB_MENU } from "@iso/Website/Pages/Account/constants";
import { useLocation } from "react-router";
import * as qs from "query-string";
import { history } from "@iso/helpers";
import { logoutUser, getUser } from "@iso/actions/User";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Nav } from "react-bootstrap";
import userLogoSidebar from "@iso/assets/images/userLogoSidebar.svg"
import "./UserSidebar.scss"

const ActiveTabIndicator = styled.div`
  position: absolute;
  ${(props) => (props.value < 7 ? "top: 240px;" : "")}
  ${(props) => (props.value < TAB_MENU.logout ? "top: 240px;" : "")}
  ${(props) => (props.value < TAB_MENU.deactivate_account ? "top: 180px;" : "")} 
  ${(props) => (props.value < TAB_MENU.change_password ? "top: 120px;" : "")} 
  ${(props) => (props.value < TAB_MENU.my_prayers ? "top: 60px;" : "")}
  ${(props) => (props.value < TAB_MENU.billing_history ? "top: 0px;" : "")}
  ${(props) => ( !isNaN(props.value) && props.location !== '/' && props.location !== '/about' ? "background: rgb(82, 185, 212);" : "")}
  height: 60px;
  left: 0px;
  box-shadow: rgb(236, 244, 248) 0px 1px 0px;
  width: 12px;
  transition: all 0.2s ease-in-out;
`;

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tabs: {
    fontFamily: "BrandonText",  
    // borderRight: `1px solid ${theme.palette.divider}`,
  },
  accordionRoot: {
    "&::before": {
      background: "none",
    },
    margin: "0px !important",
    border: "none",
    outline: "none",
    boxShadow: "none",
  },
  accordionTitle: {
    fontFamily: "BrandonText",
    fontSize: "18px",
    paddingLeft: "74px",
    color: "rgba(0, 0, 0, 1)",
  },
  accordionSummary: {
    margin: "0px",
    height: "60px",
    borderBottom: "1px solid #ECF4F8",
  },
  accordionDetails: {
    flexDirection: "column",
    padding: "0px !important",
  },
  menuActive: { 
    color: "#000000 !important",
    boxShadow: "0px 1px 0px #ECF4F8",
    background: "#ECF4F8!important",  
  },
  tab: {
    color: "#6D7278",
  },
  subMenuActive: {
    fontWeight: "bold",
    background: "#ECF4F8",
    boxShadow: "0px 1px 0px #ECF4F8",
  },
  subMenu: {
    fontSize: "16px !important",
    color: "#000000",
    height: "38px !important",
    "& span": {
      paddingLeft: "95px !important",
    },
  },
  about: {
    fontSize: "18px",
    color: "#000000!important",
    paddingLeft: "50px",
    borderBottom: "1px solid #ECF4F8", 
  }, 
  accordionUserTitle: {
    fontFamily: "BrandonText",
    fontSize: "18px",
    paddingLeft: "32px",
    color: "#1c1634",
  },
  userLogoImage: {
    marginTop: "-5px", 
    marginRight: "10px",
  }
}));

const MobileMenuTabs = (props) => {
  const { value, handleChange, isMobileMenu = false, menu, user } = props;
  const classes = useStyles();
  const accordionActiveStyle = {
    color: "#52B9D4",
    fontWeight: "bold",
  };

  const dispatch = useDispatch();
  const location = useLocation().pathname;
  return (
    <div id="userSidebar">
    <Nav.Link className={classes.about} href="/about">
      <IntlMessage id="common.about" />
    </Nav.Link>
     <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}
        >
          <Typography className={classes.accordionUserTitle}>
            <img src = {userLogoSidebar} className={classes.userLogoImage}/> 
              {user?.first_name ? user?.first_name : ""}
              {user?.lastName ? user?.lastName : ""}
             
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Typography>
          <Tabs
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        orientation="vertical"
        variant="scrollable"
        value={value}
        // onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab
          className={value === TAB_MENU.profile 
                    && location !== '/about' && location !== '/' ? classes.menuActive : classes.tab}
          onClick={() => handleChange(TAB_MENU.profile)}
          label="Profile"
          {...a11yProps(TAB_MENU.profile)}
        />

        <Tab
          className={
            value === TAB_MENU.payment_methods ? classes.menuActive : classes.tab
          }
          onClick={() => handleChange(TAB_MENU.payment_methods)}
          label="Payment Info"
          {...a11yProps(TAB_MENU.payment_methods)}
        />

        <Tab
          className={value === TAB_MENU.my_prayers ? classes.menuActive : classes.tab}
          onClick={() => handleChange(TAB_MENU.my_prayers)}
          label="My Prayers"
          {...a11yProps(TAB_MENU.my_prayers)}
        />

          <Tab
          className={ 
            value === TAB_MENU.change_password ? classes.menuActive : classes.tab
          }
          onClick={() => handleChange(TAB_MENU.change_password)}
          label={
            <IntlMessage id="user.account.settings.change_password.title" />
          }
          {...a11yProps(TAB_MENU.change_password)}
          />

          <Tab
          className={ 
            value === TAB_MENU.deactivate_account ? classes.menuActive : classes.tab
          }
          onClick={() => handleChange(TAB_MENU.deactivate_account)}
          label={
            <IntlMessage id="user.account.settings.deactivate_account.title" />
          }
          {...a11yProps(TAB_MENU.deactivate_account)}
        />

        <Tab
          className={value === TAB_MENU.logout ? classes.menuActive : classes.tab}
          onClick={() => {
            dispatch(logoutUser());
            history.push("/"); 
          }}
          label="Log Out"
          {...a11yProps(TAB_MENU.logout)}
        />
        <ActiveTabIndicator value={value} location={location}/>
        </Tabs>
      </Typography>
        </AccordionDetails>
      </Accordion>

        
    
    </div>
  );
};

export default MobileMenuTabs;
