import React, { Component, useCallback } from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik, FieldArray } from "formik"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { SignUpFormValidator } from "./validator"
import "react-intl-tel-input/dist/main.css"
import "./styles.scss"
import { withRouter } from "react-router"
import { useDispatch } from "react-redux"
import RegistrationBox from "@iso/Pages/Common/RegistrationBox/RegistrationBox"
import { Grid, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { languages } from "@iso/Pages/Common/Languages"
import { prayerPartnerReligions } from "@iso/Pages/Common/Religions"
import { processNumber } from "@iso/Pages/Common/PhoneNumber"
import {
  getUserByActivationCode,
  register,
} from "@iso/PrayerPartner/api_v1"
import AuthService from "@iso/services/AuthService"
import IntlTelInput from "react-intl-tel-input"
import { userRoleConstants } from "@iso/constants/user"
import * as qs from "query-string"
import { useLocation } from "react-router"
import alertActions from "@iso/actions/Alert"
import { setCurrentUser } from "@iso/actions/User"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "rc-time-picker/assets/index.css"
import moment from "moment"
import { localTimezone } from "@iso/utils/GetLocalData";
import welcomePrayerPartnerActions from "@iso/actions/welcomePrayerPartnerActions"
import { debounce } from "lodash";
const { openAlert } = alertActions

const CSignUp = (props) => {
  const [initValue, setInitValue] = React.useState({
    email: "",
    password: "",
    passwordConfirmation: "",
    phone_number: "",
    language: "",
    religion: "",
    user_id: "",
    token: "",
    birth: "",
    timezone: localTimezone().code
  })
  const [errors, setErrors] = React.useState({});
  const [isLoad, setIsLoad] = React.useState(false)

  const dispatch = useDispatch()

  const location = useLocation()
  const { activation_code, user_id } = qs.parse(location.search)

  React.useEffect(() => {
    if (activation_code && user_id) handleGetUser(activation_code, user_id)
    else window.location = "/prayer_partners/sign_in"
  }, [activation_code, user_id])

  const handleGetUser = (activation_code, user_id, bag) => {
    if (!activation_code) {
      window.location = "/prayer_partners/sign_in"
    }
    try {
      getUserByActivationCode(user_id, activation_code).then((res) => {
        if (!res) {
          window.location = "/prayer_partners/sign_in"
        }
        const { code, data } = res.data
        if (code === 200) {
          handleInitValues(data.user, data.activation_code)
        }
      })
    } catch (err) {
      window.location = "/prayer_partners/sign_in"
      return
    }
  }

  const handleInitValues = (user, activation_code) => {
    initValue.email = user.email
    initValue.user_id = user.id
    initValue.token = activation_code

    setInitValue(initValue)
    setIsLoad(true)
  }

  const debounceCheckFormValue = useCallback(debounce(callback => callback(), 500),[])

  const handleValueChange = (name, value) => {
    initValue[name] = value
    setInitValue(initValue)
    debounceCheckFormValue(() => SignUpFormValidator.validate(initValue, {stripUnknown: true, abortEarly: false}).then(() => {
      },
      (errors) => {
        var err = [];
        errors.inner.map(function(error) {
          err[error.path] = error.message;
        })
        if(errors.errors.length === 1 ){
          setErrors({});
        }else{
          setErrors(err);
        }
      })
    )
    return SignUpFormValidator;
  }

  const setCaretPosition = (caretPos) => {
    const elem = document.getElementsByClassName('phone_number')[0];
    if (elem) {
      elem.focus();
      setTimeout(() => {
        elem.setSelectionRange(caretPos, caretPos);
      }, 0);
    }
  };

  const handleValidator = (values) =>{
    SignUpFormValidator.validate(values, {stripUnknown: true, abortEarly: false}).then(() => {     
    },
    (errors) => {
      var err = [];
      errors.inner.map(function(error) {
        err[error.path] = error.message;
      })
      setErrors(err);
      const originLink = window.location.origin;
      const pathName = window.location.pathname;
      const searchLink = window.location.search;
      const currentLink = `${originLink}${pathName}${searchLink}`;
      const idArray = [`#${errors.inner[0].path}`];
      const errorLink = `${currentLink}${idArray[0]}`;
      window.location.href = errorLink;
    }
    )
    return SignUpFormValidator;
  }

  const handleSubmit = (values, bag) => {
    handleValidator(values)
    SignUpFormValidator.validate(values, {stripUnknown: true, abortEarly: false}).then(
      () => {
        try {
          register({
            ...values,
            religion: [values.religion],
            birth: moment(values.birth).format("YYYY-MM-DD"),
          }).then((res) => {
            if (!res) {
              bag.setErrors({ password: "Incorrect information!" })
              bag.setSubmitting(false)
            }
    
            const { code, data } = res.data
            if (code === 200) { 
              //do login
              AuthService.loginApi(values)
                .then((res) => {
                  if (!res) {
                    bag.setErrors({ password: "Incorrect login information!" })
                    bag.setSubmitting(false)
                  }
                  const { code, data } = res.data
                  if (code === 200 && data.role === userRoleConstants.PRAYER_ROLE) {
                    // dispatch(
                    //   openAlert({
                    //     message: " Account successfully created!",
                    //     severity: "success",
                    //   })
                    // )
                    dispatch(setCurrentUser({ ...data, rememberMe: 0 }))
                         // Set new create account
              dispatch(
                welcomePrayerPartnerActions.openModal()
              )
          /////////////////////////
                  } else {
                    bag.setErrors({ password: res.data.message })
                    bag.setSubmitting(false)
                  }
                })
                .catch((res) => {
                  bag.setErrors({ password: "Incorrect login information!" })
                  bag.setSubmitting(false)
                })
            }
            else {
              let errors = res.data.errors
              if (errors) {
                bag.setErrors(errors)
                bag.setSubmitting(false)
              }
            }
          })
        } catch (err) {
          bag.setErrors({ password: "Incorrect information!" })
          bag.setSubmitting(false)
          return
        }
      },
      (errors) => {
        var err = [];
        errors.inner.map(function(error) {
          err[error.path] = error.message;
        })
        setErrors(err);
      }
    );
  }

  const renderSignUpForm = () => {
    return (
      <>
        {isLoad && (
          <Formik
            initialValues={initValue}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            // validationSchema={SignUpFormValidator}
            // validate={(values) => handleValidator(values) }
          >
            {({
              setFieldValue,
              setFieldTouched,
              values,
              handleSubmit,
              handleChange,
              touched,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="email"
                      type="text"
                      value={values.email}
                      disabled={true}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="email"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Create password"
                      onChange={(event) => {
                        setFieldValue("password", event.target.value)
                        handleValueChange("password", event.target.value)
                      }}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="password"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label>Password Confirmation</Form.Label>
                    <Form.Control
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      type="password"
                      placeholder="Re-enter password"
                      onChange={(event) => {
                        setFieldValue(
                          "passwordConfirmation",
                          event.target.value
                        )

                        handleValueChange(
                          "passwordConfirmation",
                          event.target.value
                        )
                      }}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="passwordConfirmation"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label>Phone number</Form.Label>
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control phone_number"
                      defaultCountry="us"
                      placeholder="Enter phone number"
                      fieldId="phone_number"
                      fieldName="phone_number"
                      onPhoneNumberBlur={() => {
                        setFieldTouched("phone_number", true);
                        setFieldTouched("calling_code", true);
                      }}
                      onPhoneNumberChange={(isValid, phone, country, fullNumber) => {
                        fullNumber = fullNumber.replace(/[-]/g, "");
                        setFieldValue("phone_number", fullNumber);
                        handleValueChange("phone_number", fullNumber);
                        setFieldValue("calling_code", country.dialCode);
                        handleValueChange("calling_code", country.dialCode);
                        values.phone_number = fullNumber;
                        setCaretPosition(values.phone_number.length)
                      }}
                      onSelectFlag={(currentNumber, country, fullNumber)=>{
                        fullNumber = fullNumber.replace(/[-]/g, "");
                        values.phone_number = fullNumber;
                        setFieldValue("calling_code", country.dialCode);
                        handleValueChange("calling_code", country.dialCode);
                      }}
                      preferredCountries={["us", "gb", "au", "ca"]}
                      value={ ((values?.phone_number  || "").replace(/[-]/g, ""))}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="phone_number"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label>Language</Form.Label>
                    <Autocomplete
                      id="language"
                      className="language-select"
                      name="language"
                      options={languages}
                      getOptionLabel={(option) => option.label}
                      value={values.country}
                      onChange={(e, value) => {
                        if (value) {
                          setFieldValue("language", value.code)
                          handleValueChange("language", value.code)
                        } else {
                          setFieldValue("language", "")
                          handleValueChange("language", "")
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="language"
                          variant="outlined"
                          placeholder="Language"
                          fullWidth={true}
                        />
                      )}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="language"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label>DOB</Form.Label>
                    <Form.Control
                      id="birth"
                      name="birth"
                      placeholderText={'YYYY-MM-DD'}
                      onSelect={(value) => {
                        setFieldValue("birth", value)
                        handleValueChange("birth", value)
                      }}
                      as={DatePicker}
                      filterDate = {(date) => {
                        return moment() > date;
                      }}
                      selected={values.birth}
                      dateFormat="yyyy-MM-dd"
                      value={values?.birth || null}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="birth"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label>Religion</Form.Label>
                    <FieldArray
                      className="mt-1"
                      name="religion"
                      render={(arrayHelpers) => (
                        <div>
                          {prayerPartnerReligions.map((religion) => (
                            <div key={religion.id}>
                              <label>
                                <input
                                  name="religion"
                                  type="radio"
                                  value={religion.id}
                                  onChange={(e) => {
                                    setFieldValue("religion", religion.id)
                                    handleValueChange("religion", religion.id)
                                  }}
                                />{" "}
                                {religion.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="religion"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                  <Col sm="12" className="text-center">
                    <Button className="hippie-blue-button" type="submit">
                      Create account
                    </Button>{" "}
                  </Col>
                </Form.Group>
              </form>
            )}
          </Formik>
        )}
      </>
    )
  }

  return (
    <div className="prayer-portal sign-up-container">
      <RegistrationBox
        title="Create a Prayer Partner account"
        form={renderSignUpForm()}
      />
    </div>
  )
}

export default CSignUp
