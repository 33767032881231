// Available severity: https://material-ui.com/components/snackbars/
//error
//warning
//info
//success

const alertActions = {
  SHOW_ALERT: "show_alert",
  HIDE_ALERT: "hide_alert",
  openAlert: (data) => ({
    type: alertActions.SHOW_ALERT,
    data,
  }),
  openAlertSuccess: (data) => ({
    type: alertActions.SHOW_ALERT,
    data: { ...data, severity: "success" },
  }),
  openAlertError: (data) => ({
    type: alertActions.SHOW_ALERT,
    data: { ...data, severity: "error" },
  }),
  closeAlert: () => ({
    type: alertActions.HIDE_ALERT,
  }),
};

export default alertActions;
