import React from "react";
import { Button } from "react-bootstrap";
import DoneIcon from "@material-ui/icons/Done";
import IntlMessage from "@iso/components/intlMessage";
import { FIELDS_AND_STATUS_DISPLAY_APPROVE_ALL_CHANGE_BUTTON } from "./constants";
import PrayerPartnerService from "@iso/services/PrayerPartnerService";
import { useDispatch } from "react-redux";

import alertActions from "@iso/actions/Alert";
import prayerPartnerActions from "@iso/actions/PartnerActions";
import moment from "moment";
import { BIRTH_DATE_FORMAT } from "./constants";

const { openAlert } = alertActions;
const { setPrayerPartnerProfile } = prayerPartnerActions;

const ApproveAllButton = (props) => {
  const dispatch = useDispatch();
  const [display, setDisplay] = React.useState(
    FIELDS_AND_STATUS_DISPLAY_APPROVE_ALL_CHANGE_BUTTON.find(
      (item) => props.profile[item.title] === item.status
    )
  );

  const handleApproveAllDetailChanges = () => {
    try {
      PrayerPartnerService.adminUpdatePrayerPartnerProfile({
        ...props.profile,
        birth: moment(props.profile.birth).format(BIRTH_DATE_FORMAT),
      }).then((res) => {
        const { code, data, message } = res.data;

        if (code === 200 && data) {
          setDisplay(false);
          dispatch(setPrayerPartnerProfile({ profile: data }));

          dispatch(
            openAlert({
              message: "Update success",
              severity: "success",
            })
          );
        } else {
          if (typeof message === 'object') {
            if (Object.keys(message).length > 0) {
              dispatch(
                openAlert({
                  message: message[Object.keys(message)[0]],
                  severity: "error",
                })
              );
            }
          }
          else {
            if (Object.keys(message).length > 0) {
              dispatch(
                openAlert({
                  message: message,
                  severity: "error",
                })
              );
            }
          }
        }
      });
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      {(display && !props.profile?.deleted_at) && (
        <Button
          className="approve_all_change_btn float-right hover_pointer edit_btn"
          onClick={handleApproveAllDetailChanges}
        >
          <DoneIcon className="pr-1 pb-1" />
          <IntlMessage id="prayer_partners.account_settings.profile.btn.approve_all_detail_changes" />
        </Button>
      )}
    </>
  );
};

export default ApproveAllButton;
