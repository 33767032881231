import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import LogoJewish from "@iso/assets/images/step2_logo_jewish.png";
import LogoJewishTablet from "@iso/assets/images/step2_logo_jewish_tablet.png";
import OrthodoxGirl from "@iso/assets/images/orthodox_girl.svg";
import CatholicGirl from "@iso/assets/images/catholic_girl.svg";
import AnglicanGirl from "@iso/assets/images/anglican_girl.svg";
import PrayerGirlTablet from "@iso/assets/images/step2_prayer_girl_tablet.svg";
import PrayerGirlCatholicTablet from "@iso/assets/images/step2_prayer_girl_catholic_tablet.svg";
import CloudTablet from "@iso/assets/images/step2_cloud_tablet.svg";
import Cloud from "@iso/assets/images/request_step2_cloud.svg";
import CloudBig from "@iso/assets/images/request_step2_cloud_big.svg";
import TitleAdapter from "./components/TitleAdapter";
import ContentAdapter from "./components/ContentAdapter";
import RequestSignIn from "./components/RequestSignIn";
import { isTablet } from "react-device-detect"

const RightPart = ({
  formikValues,
  handleChange,
  setFormikValues,
  touched,
  errors,
  denomination
}) => {
	
	const { innerWidth: width } = window;
  const mainContent = () => {
    let imagePrayGirl;
    let classPrayGirl;
    switch (denomination) {
      case "eastern-orthodox":
        classPrayGirl = "pray_girl_orthodox";
        imagePrayGirl = isTablet ? PrayerGirlTablet : OrthodoxGirl;
        break;
      case "catholic":
        classPrayGirl = "pray_girl_catholic";
        imagePrayGirl = isTablet ? PrayerGirlCatholicTablet : CatholicGirl;
        break;
      case "anglican":
        classPrayGirl = "pray_girl_anglican";
        imagePrayGirl = isTablet ? PrayerGirlCatholicTablet : AnglicanGirl;
        break;
      case "jewish":
        classPrayGirl = "logo_jewish";
        imagePrayGirl = isTablet ? LogoJewishTablet : LogoJewish;
        break;
      default:
        imagePrayGirl = null;
        break;
    }
    return (
      <Row id="right-part-wrapper-step2">
        <div
          className={`bg_image_cloud ${classPrayGirl}`}
          style={{ backgroundImage: `url(${width < 1920 ? (isTablet ? CloudTablet : Cloud) : CloudBig})` }}
        >

        {imagePrayGirl && (
          <img src={imagePrayGirl} className="image_praying_catholic" />
        )}

        </div>
        <Col className={`margin-image-${denomination ? denomination : 'default'} custom_col`}>

          <div className="denomination_adapter text-center">
            <TitleAdapter className={`step2-title-${denomination}`} denomination={denomination} />
            <ContentAdapter className={`step2-content-${denomination}`} denomination={denomination} />
          </div>
        </Col>
      </Row>
    );
  };

  return <div className={`d-none d-sm-block need-option`}>{mainContent()}</div>;
};

export default RightPart;
