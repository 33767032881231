import Home from "@iso/PrayerPartner/Pages/Home/Index"
import PrayerRequestDetails from "@iso/PrayerPartner/Pages/PrayerRequests/PrayerRequestDetails"
import ReportDashboard from "../Pages/Reports/ReportDashboard"
import PaymentDashboard from "../Pages/Payments/PaymentDashboard"
import AccountSettings from "../Pages/AccountSettings/AccountSettings"
import ContactAdmin from "../Pages/ContactAdmin/ContactAdminPage"
import PaymentBenefitDetails from "../Pages/Payments/PaymentBenefitDetails"
import Notification from "../Pages/Notification/NotificationPage"
export default {
  Home: {
    component: Home,
    path: "/prayer_partners",
  },
  PrayerRequestDetails: {
    component: PrayerRequestDetails,
    path: "/prayer_requests/:id",
  },
  ReportDashboard: {
    component: ReportDashboard,
    path: "/prayer_partners/reports",
  },

  PaymentDashboard: {
    component: PaymentDashboard,
    path: "/prayer_partners/payments",
  },
  AccountSettings: {
    component: AccountSettings,
    path: "/prayer_partners/account_settings",
  },
  ContactAdmin: {
    component: ContactAdmin,
    path: "/prayer_partners/contact_admin",
  },
  PaymentBenefitDetails: {
    component: PaymentBenefitDetails,
    path: "/prayer_partners/payments/:month/:year",
  },
  Notification: {
    component: Notification,
    path: "/prayer_partners/notification",
  },
}
