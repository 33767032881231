import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IntlMessage from "@iso/components/intlMessage";
import PrayerPartners01 from "@iso/assets/images/PrayerPartners01.png";
import ContributionService from "@iso/services/ContributionService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { isMobile, isTablet, isMobileSafari } from "react-device-detect";

import FeedbacksDesktop from "./FeedbacksDesktop";
import FeedbacksMobile from "./FeedbacksMobile";
import FeedbacksTablet from "./FeedbacksTablet";

import {FEEDBACK_LIST} from './FeedbackConfig'

const Wrapper = styled.div`
  justify-content: center;
  font-family: BrandonText;
  padding: 0px 25px 0px 25px;

  @media only screen and (max-width: 500px) {
    padding: 40px 0px 0px 0px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 79.43px;

  @media only screen and (max-width: 500px) {
    margin-bottom: 18.75px;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    margin-bottom: 30px;
  }
`;
const TitleWrapperImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 79.43px;

  @media only screen and (max-width: 500px) {
    margin-bottom: 18.75px;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    margin-bottom: 76px;
    margin-left: 95px
  }
`;
const TitleWrapperTablet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    margin-left: 50px;
    margin-top: -75px;
  }

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
  }
`;

const Title = styled.div``;

const ImageWhoPrayer = styled.img`
  max-width: 372px;
  margin-left: 15px;

  @media only screen and (min-width: 768px) {
    max-width: 451px;
  }

  @media only screen and (min-width: 1025px) {
    max-width: 608px;
    height: 160px
  }
`;

const Section1 = (props) => {
  const isDesktop = !isMobile && !isMobileSafari && !isTablet;
  const [feedbackList] = useState(FEEDBACK_LIST);

  return (
    <Wrapper className="section1_container">
      <TitleWrapper>
        <div className="section1_title_top">
          <IntlMessage id="user.form.prayer_pathner.section1_top.title" />
          <IntlMessage id="user.form.prayer_pathner.section2_top.title" />
        </div>
      </TitleWrapper>
      {isMobile && isTablet ? (
        <TitleWrapperTablet>
          <Title className="section1_title">
            <IntlMessage id="user.form.prayer_pathner.section1.title" />
          </Title>
          <ImageWhoPrayer src={PrayerPartners01} alt="Who Prayer" />
        </TitleWrapperTablet>
      ) : (
        <TitleWrapperImage>
          <Title className="section1_title">
            <IntlMessage id="user.form.prayer_pathner.section1.title" />
          </Title>
          <ImageWhoPrayer src={PrayerPartners01} alt="Who Prayer" />
        </TitleWrapperImage>
      )}
      {isDesktop ? (
        <FeedbacksDesktop feedbackList={feedbackList}></FeedbacksDesktop>
      ) : isTablet ? (
        <FeedbacksTablet feedbackList={feedbackList}></FeedbacksTablet>
      ) : (
        <FeedbacksMobile feedbackList={feedbackList}></FeedbacksMobile>
      )}
    </Wrapper>
  );
};

export default Section1;
