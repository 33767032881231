import React, { Component, useState } from "react"
import IntlMessage from "@iso/components/intlMessage"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { Formik } from "formik"
import { ContactUsFormValidator } from "./validator"
import AuthService from "@iso/services/AuthService"
import { Form, Row, Col, Button } from "react-bootstrap" 
import { ExpandMore } from "@material-ui/icons"
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { aboutMyself } from "@iso/Pages/Common/AboutMyself"
import CheckIcon from '@material-ui/icons/Check'
import { userRoleConstants } from "@iso/constants"
import FormContactLeftPart from "./FormContactLeftPart"
import FormContactRightPart from "./FormContactRightPart"
import alertActions from "@iso/actions/Alert";
import { useDispatch } from 'react-redux';
import "./styles.scss"

const FormContactUs = (props) => {
  const [initValue, setInitValue] = useState({
    email: "",
    category :"",
    country: "",
    subject: "",
    message : ""
  })
  const { openAlert } = alertActions;
  const dispatch = useDispatch()
  const handleSubmit =  (values, bag) => {  
    
    AuthService.contactApi(values).then((res) => {
      const { code, data } = res.data;
      if( code === 200){
        props.setflagSendContact(true);
      }
      else {
         dispatch(
            openAlert({
              message: res.data.errors.message,
              severity: "error",
            })
          );
      }
    }).catch((res) => {
       dispatch(
            openAlert({
              message: <IntlMessage id="common.message.update.fail" />,
              severity: "error",
            })
          ); 
    })
  }

  return(
     <Formik
        initialValues={initValue}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={ContactUsFormValidator}
      >
        {({ values, handleSubmit, handleChange, touched, errors, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
           <Row className = "contactUs-form-control justify-content-between">
            <Col xs="12" md="6" className="left-col">
              <FormContactLeftPart 
                initValue = {initValue}
                errors = {errors}
                touched = {touched}
                setInitValue = {setInitValue}
              />
            </Col>

          <Col xs="12" md="6" className="right-col">
              <FormContactRightPart 
                initValue = {initValue}
                errors = {errors}
                touched = {touched}
                setInitValue = {setInitValue}
              />

          <Row className='form-group m-float-group'>
            <Col xs='12'>
              <Button className='form-control hippie-blue-button w-100' type="submit">
                <b className ="font-size-20px"><IntlMessage id="common.submit" /></b>
              </Button>
           </Col>
          </Row>
          </Col>
          </Row>

          </form>
        )}
      </Formik>
  )
}

export default FormContactUs
