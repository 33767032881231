import React from "react";
import styled from "styled-components";

import FAQHeaderCloud from "@iso/assets/images/faq_header_clouds.svg";
import Cloud_Main from "@iso/assets/images/Cloud_Main.svg";

const Wrapper = styled.div`
  background-color: #afdaf5;
  position: relative;
  height: 150px;
  margin-bottom: 50px;
  margin-top: -2px;

  @media only screen and (max-width: 425px) {
    height: 84px;
    margin-bottom: 0px;
  }
  

  @media only screen and (min-width: 700px) {
    height: 200px;
  }
  @media only screen and (min-width: 1700px) {
    height: 250px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 110px!important;
  }
  @media only screen and (min-width: 1025px){
    height: 175px
  }
  @media only screen and (min-width: 786px) and (max-width: 1440px){
    height: 175px;
    margin-right: 15px;
  }
`;

const Cloud = styled.img`
  width: 100%;
  min-width: 1000px;
  height: auto;
  position: absolute;
  bottom: -30px;

  @media only screen and (max-width: 375px) {
    bottom: -36px;
    left: -72%;
    min-width: 1000px;
    height: 132%;
  }
  
  @media only screen and (min-width: 376px) and (max-width: 425px) {
    bottom: -41px;
    left: -70%;
    min-width: 1066px;
    height: 141%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 112px!important;
    position: absolute;
    left: calc(50% - 500px);
  }
  

  @media only screen and (min-width: 426px) and (max-width: 600px) {
    left: -50%;
  }

  @media only screen and (min-width: 1300px) {
    bottom: -45px;
    height: 185px;
    left: 0;
  }

  @media only screen and (min-width: 1700px) {
    bottom: -42px;
  }
`;
const PricingHeaderClouds = (props) => {
  return (
    <>
      <Wrapper>
        <Cloud src={Cloud_Main}></Cloud>
      </Wrapper>
    </>
  );
};

export default PricingHeaderClouds;
