import React from "react";
import { store } from "@iso/store/root";
import IntlMessage from "@iso/components/intlMessage";
import alertActions from "@iso/actions/Alert";
const { openAlert } = alertActions;

const run = ({
  res,
  i18nSuccess = "common.message.update.success",
  successCallback = null,
  errorCallback = null,
  hasNoti = false,
  notiVariant = "default",
}) => {
  const { code, data, message, errors } = res.data;
  if (code === 200) {
    const successMessage =
      typeof message === "string" ? message : <IntlMessage id={i18nSuccess} />;

    if (hasNoti) {
      store.dispatch(
        openAlert({
          message: successMessage,
          severity: "success",
          variant: notiVariant,
        })
      );
    }

    if (typeof successCallback == "function") {
      successCallback({ data });
    }
  } else {
    if (typeof errorCallback == "function") {
      errorCallback({ errors, message });
    }

    const errorMessage =
      typeof message === "string" ? (
        message
      ) : (
        <IntlMessage id="common.message.update.fail" />
      );

    if (hasNoti) {
      store.dispatch(
        openAlert({
          message: errorMessage,
          severity: "error",
          variant: notiVariant,
        })
      );
    }
  }
};

const ProcessRequest = { run };

export default ProcessRequest;
