import { userConstants } from "@iso/constants"
import { getUser } from "@iso/actions/User"
let user = getUser();
const initialState = user ? { loggedIn: true, user, currentProfile: user } : {}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        currentProfile: action.user,
      }
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        currentProfile: action.user,
      }
    case userConstants.LOGIN_FAILURE:
      return {}
    case userConstants.LOGOUT:
      return {
        loggedIn: false, 
        user: {},
        currentProfile: {}
      }
    case userConstants.SET_CURRENT_USER:
      let currentUser = action.payload.currentUser
      return {
        user: currentUser,
        currentProfile: action.payload.user,
        loggedIn: currentUser.id ? true : false
      }
    case userConstants.SET_CURRENT_PROFILE:
      return {
        ...state,
        currentProfile: action.payload.user,
      }
    default:
      return state
  }
}
