import React, { useState } from "react";
import IntlMessage from "@iso/components/intlMessage";
import Stripe from "@iso/components/Stripe/Stripe";
import Paypal from "@iso/components/Paypal/Paypal";

import { Row, Col, Form, Button } from "react-bootstrap";

import { roundUpAmount } from "@iso/utils/FormatData";
import imageVisaMaster from "@iso/assets/images/visa_master.svg";
import { Link } from "react-router-dom";
import actionsSignIn from "@iso/actions/signinmodal";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { isMobile, isTablet } from "react-device-detect"

import "./RightPart.scss";
import { CircularProgress } from "@material-ui/core";
import PaymentUtils from "@iso/components/Share/Payment/utils";
import masterCardLogo from "@iso/assets/images/masterCardLogo.svg";
import visaLogo from "@iso/assets/images/visaLogo.svg";
import stripeActions from "@iso/actions/Stripe";
import PayMethodCard from "./components/PayMethodCard"
import SelectCardPayment from "./components/SelectCardPayment"
import PowerStriple from "@iso/assets/images/powerStriple.svg"
import ReceiveYourConfirmation from "./components/ReceiveYourConfirmation";
import CouponService from "@iso/services/CouponService";
import RequestService from "@iso/services/RequestService";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';
import gtag from 'ga-gtag';
import { recommended_events } from "@iso/constants";
import requestStepActions from "@iso/actions/RequestStepActions";
import { history } from "@iso/helpers";

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);
const { createRequest } = RequestService;
const {
  triggerStripe,
  setCardToken,
  setToken,
  setSaveCard,
} = stripeActions;

const RightPart = ({
  formikValues,
  handleChange,
  setFormikValues,
  touched,
  errors,
  afterAuthorize,
  price,
  loadingPayment,
  setLoadingPayment,
  switchToLeftSide,
  requestErrors,
  formConfirmData,
  setFormConfirmData,
  requestId,
  coupon,
  setCoupon,
  couponCodeError,
  setCouponCodeError,
}) => {

  const [isAddCard, setAddCard] = React.useState(false);
  const [showCouponContainer, setShowCouponContainer] = React.useState(false);
  const [showCardDialog, setShowCardDialog] = React.useState(false);
  const dispatch = useDispatch();
  const { user, loggedIn } = useSelector((state) => state.authentication);
  const { errors: stripeErrors } = useSelector(
    (state) => state.stripe,
    shallowEqual
  );

  const requestStepData = useSelector((state) => state.requestStepReducer);

  const [paymentCards, setPaymentCards] = React.useState([]);
  const [stripeCustomer, setStripeCustomer] = React.useState();
  const [defaultCard, setDefaultCard] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [errorReceiveEmail, setErrorReceiveEmail] = React.useState(null);
  const [errorPasswordRule, setErrorPasswordRule] = React.useState(null);
  const [payButtonClicked, setPayButtonClicked] = React.useState(false);
  const [emailExisted, setEmailExisted] = useState(false)
  const [isAppliedCoupon, setIsAppliedCoupon] = useState(false)
  const [isDefaultCoupon, setIsDefaultCoupon] = useState(false)
  const [isShowInvalidCoupon, setIsShowInvalidCoupon] = useState(false)
  const [isShowContributionPriceOri, setShowContributionPriceOri] = useState(false)
  const [errorCouponMessage, setErrorCouponMessage] = useState('')
  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [requestErrorsResponse, setRequestErrorsResponse] = React.useState();
  const [errorReceive, setErrorReceive] = React.useState("");
  const [couponInfo, setCouponInfo] = useState(null);

  const checkCoupon = (e) => {
    e.preventDefault()
    if (coupon) {
        CouponService.checkExistCode(coupon, requestStepData.prayer_contribution.id, requestStepData.prayer_frequency.id).then((res) => {
          if (res.data && res.data.is_exist) {
            if(res.data.coupon.type == 'default' || res.data.coupon.total_amount === 0) {
              setIsDefaultCoupon(true)
            } else {
              setIsDefaultCoupon(false)
            }
            setIsAppliedCoupon(true)
            setIsShowInvalidCoupon(false)
            setShowContributionPriceOri(true)
            setCouponCodeError(false)
            setCouponInfo(res.data.coupon)
          } else if (res.data && res.data.is_exist == false) {
            setIsDefaultCoupon(false)
            setIsAppliedCoupon(false)
            setIsShowInvalidCoupon(true)
            setErrorCouponMessage(res.data.message)
            setCouponInfo(null)
            setShowContributionPriceOri(false)
          } else {
            setIsDefaultCoupon(false)
            setIsAppliedCoupon(false)
            setIsShowInvalidCoupon(true)
            setErrorCouponMessage('Code has already been used or is invalid')
            setCouponInfo(null)
            setShowContributionPriceOri(false)
          }
        });
    } else {
      setCouponInfo(null)
      setShowContributionPriceOri(false)
    }
  }

  const submitRequestWithCoupon = (e) => {
    e.preventDefault()
    if (!loggedIn) {
      setErrorReceive(errorReceiveEmail)
    }
    setPayButtonClicked(true);
    if (!loggedIn && !formConfirmData.email) {
      setErrorReceiveEmail({ field: 'email', message: 'common.your_email_error' })
      return
    }

    if (!loggedIn && errorReceiveEmail) {
      return
    }

    dispatch(setLoadingPayment(true));
    createRequest({
      ...requestStepData,
      ...requestStepData.prayer_frequency,
      ...requestStepData.prayer_customization,
      coupon_code: coupon,
      payment_method: 'coupon',
      recipient:
        requestStepData.for_who == "forsomeoneelse"
          ? "for_someone"
          : "for_myself",
      request_id: requestId,
      time_zone: time_zone
    }).then((res) => {
      const { code, data, success, payment, errors, message } = res.data;
      dispatch(setLoadingPayment(false));
      if (code === 200) {
        if (success) {
          let items = [
            {
              'item_id': requestStepData.prayer_contribution.id,
              'item_name': requestStepData.prayer_contribution.name,
              'currency': 'USD',
              'price': requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number,
              'quantity': requestStepData.prayer_frequency.value_number
            }
          ];
          gtag('event', recommended_events.PURCHASE, {
            transaction_id: data.id,
            value: requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number,
            currency: "USD",
            items: items
          });
          ReactPixel.track(fb_pixel_event.recommended_events.PURCHASE, {
            transaction_id: data.id,
            value: requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number,
            currency: "USD",
            items: items
          });


          gtag('event', recommended_events.ADD_PAYMENT_INFO, {
            value: requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number,
            currency: "USD",
            payment_type: requestStepData.payment_method,
            items: items
          });
          ReactPixel.track(fb_pixel_event.custom_events.ADD_PAYMENT_INFO, {
            value: requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number,
            currency: "USD",
            payment_type: requestStepData.payment_method,
            items: items
          });

          dispatch(
            requestStepActions.setDataStep({
              ...requestStepData,
              id: data.id,
              step: 3,
            })
          );

          dispatch(setSaveCard(false))
          dispatch(setCardToken(null));
          dispatch(setToken(null));
          history.push("/requests/step4");
          dispatch(
            requestStepActions.setDataStep({
              ...requestStepData,
              id: data.id,
              client_email: formConfirmData.email,
              signup_first_name: formConfirmData.first_name,
              signup_last_name: formConfirmData.last_name,
              signup_password: formConfirmData.password,
              signup_keep_me: formConfirmData.keep_me,
            })
          );
        }
        else {
          if (payment?.client_secret) {
            dispatch(setLoadingPayment(true));
            dispatch(triggerStripe(payment));
          }
        }
      } else {
        setRequestErrorsResponse(message);
        dispatch(setCardToken(null));
        dispatch(setToken(null));
      }
    });
  }
  let img = visaLogo;
  React.useEffect(() => {
    PaymentUtils.getStripeCustomerOnMount({
      successCallback: (data) => {
        setStripeCustomer(data);
      },
    });
    PaymentUtils.getListCardOnMount({
      successCallback: (data) => {
        setPaymentCards(data);
        setLoading(false)
      },
    });
  }, [user]);

  React.useEffect(() => {
    if (paymentCards && paymentCards.length && stripeCustomer) {
      setDefaultCard({
        card: paymentCards.find(
          (item) => item?.id === stripeCustomer.default_source
        ),
      });
    }
  }, [paymentCards, stripeCustomer]);
  if (defaultCard?.card?.card?.brand === "mastercard") {
    img = masterCardLogo;
  }

  React.useEffect(() => {
    setShowCardDialog(false)
  }, [defaultCard])


  const openSignInModal = () => {
    dispatch(
      actionsSignIn.openModal({
        type: actionsSignIn.SHOW_MODAL,
      })
    )
  }

  return (
    <Row id="right-part-wrapper-step3">
      <Col xs="12" className={`${loggedIn ? 'mb-lg-5' : 'mb-lg-2'} is_sign_in_not_user `} >
        {loggedIn && switchToLeftSide && (
          <Link
            className="text-hippie-blue back__button pl-5"
            onClick={switchToLeftSide}
          >
            {"< Back"}
          </Link>
        )}
        {!loggedIn && (
          <>
            {isMobile && !isTablet && (
              <Link
                className="text-hippie-blue back__button pl-5"
                onClick={switchToLeftSide}
              >
                {"< Back"}
              </Link>)
            }
            <p className="margin-signIn text-center text-md-left">
              <span className="custom_already_member_text">
                <IntlMessage id="public.request.step3.right.already_member" />
              </span>
              <span
                className="text-hippie-blue font-weight-bold mx-1 hover_pointer"
                onClick={openSignInModal}
              >
                <IntlMessage id="common.sign_in" />
              </span>
              <span>
                {isTablet && <IntlMessage id="public.request.step3.right.load_saved_info" />}
              </span>
            </p>
          </>
        )}

        {loggedIn && isTablet && (
          <div style={{ minHeight: "59px" }} />
        )}
      </Col>

      {
        !loggedIn && (<>
          <Col xs="12">
            <h4 className="text-left my-title contribution pl-4 margin-left-contribution title-receive-your-confirmation">
              Receive Your Confirmation
            </h4>
          </Col>

          <ReceiveYourConfirmation
            formData={formConfirmData}
            setFormData={setFormConfirmData}
            setErrorReceiveEmail={setErrorReceiveEmail}
            errorReceiveEmail={errorReceiveEmail}
            payButtonClicked={payButtonClicked}
            setPayButtonClicked={setPayButtonClicked}
            openSignInModal={openSignInModal}
            emailExisted={emailExisted}
            setEmailExisted={setEmailExisted}
          />
        </>)
      }

      {
        !isDefaultCoupon && (
          <Col xs="12" className="margin_top_contribution">
            <h2 className="text-left my-title contribution pl-4 margin-left-contribution">
              <IntlMessage id="public.request.step3.right.title" />
              { isShowContributionPriceOri 
              ? (
                <>
                  <span className="total_contribution_original" style={{"text-decoration": "line-through"}}>
                    ${roundUpAmount(requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number)}
                  </span>
                  <span className="total_contribution">
                    ${couponInfo ? roundUpAmount(couponInfo?.total_amount) : roundUpAmount(price) }
                  </span>
                </>
              ) 
              : 
              (
                <>
                  <span className="total_contribution">
                    ${roundUpAmount(requestStepData?.prayer_contribution?.price * requestStepData?.prayer_frequency?.value_number)}
                  </span>
                </>
              )}
              
            </h2>
            <p className="pl-4 mb-lg-3 margin-left-contribution sub-contribution-text">
              <IntlMessage id="public.request.step3.right.title_notice" />
            </p>
          </Col>
        )
      }
      {
        !loading && !isDefaultCoupon &&
        (
          !showCardDialog ?
            <PayMethodCard
              setShowCardDialog={setShowCardDialog}
              defaultCard={defaultCard}
              setDefaultCard={setDefaultCard}
              paymentCards={paymentCards}
              setPaymentCards={setPaymentCards}
              afterAuthorize={afterAuthorize}
              price={couponInfo ? couponInfo.total_amount : price}
              loadingPayment={loadingPayment}
              setLoadingPayment={setLoadingPayment}
              requestErrors={requestErrors}
              isAddCard={isAddCard}
              setAddCard={setAddCard}
              requestId={requestId}
              errorReceiveEmail={errorReceiveEmail ? errorReceiveEmail.message : null}
              formConfirmData={formConfirmData}
              setPayButtonClicked={setPayButtonClicked}
              emailExisted={emailExisted}
              openSignInModal={openSignInModal}
              couponCodeError={couponCodeError}
            />
            :
            <SelectCardPayment
              defaultCard={defaultCard}
              setDefaultCard={setDefaultCard}
              paymentCards={paymentCards}
              setShowCardDialog={setShowCardDialog}
              setAddCard={setAddCard}
              requestId={requestId}
            />
        )
      }
      {
        isDefaultCoupon &&
        (
          <>
            <Col xs="12" className={`${loggedIn ? "" : "margin_top_gift_coupon"}`}>
              <h2 className="text-left our-gift-to-you pl-4 margin-left-contribution">
                <p className="mb-0 font-weight-bold">Our Gift to You</p>
              </h2>
              <p className="pl-4 mb-3 margin-left-contribution sub-contribution-text">
                A Complimentary Prayer Request
              </p>
            </Col>
            <div className="submit-with-coupon-container">
              <img id="coupon-code-img" src="/Gift4.gif" alt="Coupon Code" />
              <div className="submit-with-coupon">
                <button id="submit-with-coupon-btn" onClick={(e) => submitRequestWithCoupon(e)}>
                  SUBMIT REQUEST
                </button>
                <p className="text-danger">
                  <IntlMessage id={errorReceive ? errorReceive.message : null} />
                </p>
              </div>
            </div>
          </>
        )
      }

      {
        showCouponContainer ?
          (
            <div className={`coupon-code-container ${isDefaultCoupon ? 'pb-0 ' : ''}`}>
              <div className="coupon-form">
                <input
                  id="coupon_code"
                  placeholder="Add Code"
                  className="custom_input_stripe"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <button id="apply-coupon-btn" onClick={(e) => {
                  checkCoupon(e)
                }}>Apply</button>
              </div>
              {isAppliedCoupon && <div className="coupon-success-container">
                <div>
                  <img src="/tick.png"></img>
                </div>
                <p className="coupon-success-msg">Applied</p>
              </div>}
              {isShowInvalidCoupon && 
              <p className='coupon-error-msg'>
                {errorCouponMessage}
              </p>}

              {couponInfo && 
              <div className="coupon-info">
                <div>
                  Price Contribution: ${requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number}
                </div>
                <div className="mt-2">
                  Discount: ${couponInfo?.price_discount}
                </div>
                <div className="mt-2">
                  Total Amount: ${roundUpAmount(couponInfo?.total_amount)}
                </div>
              </div>}
            </div>
          ) : (
            <div className="col-12" id="add-coupon-title">
              <a
                onClick={(e) => {
                  e.preventDefault()
                  setShowCouponContainer(true)
                }}
                id="add-coupon-code-btn" href="#">Add PrayerCloud Code</a>
            </div>
          )
      }
      {
        <div className="power-stripe-container">
          <img src={PowerStriple} />
        </div>
      }
    </Row>
  );
};

export default RightPart;
