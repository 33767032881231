import React, { Suspense } from "react"
import Loader from "react-loaders"
import { useParams, Link } from "react-router-dom"
import { Row, Col, Button, Card } from "react-bootstrap"
import IntlMessage from "@iso/components/intlMessage"

import { sendDemo } from "@iso/PrayerPartner/api_v1"

const RequestDetailsPanel = React.lazy(() => import("./RequestDetailsPanel"))
const PrayerRequestDetails = () => {
  const { id } = useParams()

  return (
    <div className="mint-tulip-background p-3 prayer_partners_request_wrapper">
      <Row className="my-2">
        <Col xs="12" sm="6" className="request_title">
          <h2>
            <IntlMessage id="prayer_partners.requests.title" />
          </h2>
        </Col>
        <Col xs="12" sm="6" className="send_btn text-right">
          <Link to="/prayer_partners">
            <Button
              variant="back_btn_color"
              className="back_btn_color mr-2 ml-2"
              id="btn-back"
            >
              <IntlMessage id="prayer_partners.requests.back" />
            </Button>
          </Link>
          <Button className="hippie-blue-button" onClick={sendDemo}>
            <IntlMessage id="prayer_partners.requests.send_demo" />
          </Button>
        </Col>
      </Row>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
                <h1>Loading...</h1>
              </div>
            </div>
          </div>
        }
      >
        <RequestDetailsPanel prayerRequestId={id} />
      </Suspense>
    </div>
  )
}

export default PrayerRequestDetails
