import React, {useEffect} from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import TextField from "@material-ui/core/TextField";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import actionsSignIn from "@iso/actions/signinmodal";
import {isMobile, isMobileSafari, isTablet} from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import './LeftPart.scss'
import ToolTip from '@iso/assets/images/tool_tip_gray.svg'
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import RequestPrayer from "./components/RequestPrayer";
import FieldAdapter from "./components/FieldAdapter";
import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";
import { escapeObject } from "@iso/helpers/index";

const LeftPart = ({
  formikValues,
  handleChange,
  setFormikValues,
  touched,
  errors,
  needOption,
  setNeedOption
}) => {
  formikValues = escapeObject(formikValues, false);
  const isDesktop = !isMobile && !isMobileSafari && !isTablet
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const [openPopoverReason, setOpenPopoverReason] = React.useState(false);

  const openSignInModal = () => {
    dispatch(
      actionsSignIn.openModal({
        type: actionsSignIn.SHOW_MODAL,
      })
    );
  };
  
  useEffect(() => {
    if (formikValues?.prayer_customization?.religion || formikValues?.prayer_customization?.prayer_type_id || formikValues?.prayer_customization?.open_select_religion) {
      setNeedOption(true);
    }
  }, []);

  const tooltipTemplate = (title, open, setOpen, style, className = null, placement = 'bottom') => {
    return (
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          arrow
          placement={placement}
          open={open}
          onClose={() => setOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={title}
        >
          <img
            style={style}
            className={`tool_tip_icon_up ${className}`}
            src={ToolTip}
            onMouseEnter={() => {
              setOpen(true);
            }}
            onMouseLeave={() => {
              setOpen(false);
            }}
          />
        </Tooltip>
      </ClickAwayListener>
    );
  }
  // const tooltipIsGift = () => {
  //   let title = (<div className='tooltip-wrap'><div className="tooltip-message">Both you and your gift recipient will receive a letter from your Prayer Partner and updates as the prayers are completed.</div></div>);
  //   let style = {top: 0, left: "150px", zIndex: "1"};

  //   return tooltipTemplate(title, openPopoverIsGift, setOpenPopoverIsGift, style)
  // }

  const tooltipReason = () => {
    let title = (<div className={`tooltip-wrap`} style={{maxWidth: "197px"}}><div className="tooltip-message" style={{maxWidth: "197px"}}>Your Prayer Partner will customize your prayer based on your reason.</div></div>);
    let style = isDesktop ? {zIndex: "1", right: "-20px", top: "20px"} : {zIndex: "1", right: "-15px", top: "20px"};

    return isDesktop && tooltipTemplate(title, openPopoverReason, setOpenPopoverReason, style, `${formikValues.for_who === "formyself" ? " reason_margin_top" : " mt-0"}`)
  }

  const renderRequestSignIn = () => {
    return (
      <Row>
        <Col xs="12" className="text-center sign-in-title">
          <IntlMessage id={isTablet ? "public.request.has_account.tablet" : "public.request.has_account"} />
          &nbsp;
          <b onClick={openSignInModal} className="hippie-blue-link pointer">
            <IntlMessage id="sidebar.public.signin" />
          </b>
        </Col>
      </Row>
    );
  };

  return (
    <Row id="left-part-wrapper" className={` ${formikValues.is_gift && "custom_left_part_is_gift"} `}>
      <Col xs="12">
        <div className="d-block d-sm-none">
          {!user && renderRequestSignIn()}
        </div>
      </Col>
      <Col
        lg={isTablet ? { span: 12, offset: 0 } : { span: 7, offset: 3 }}
        md={isTablet ? { span: 12, offset: 0 } : { span: 8, offset: 2 }}
        xs={{ span: 12, offset: 0 }}
        className="tablet-left-part"
      >
        <h2 className="text-center text_request_title_left text-md-left my-title">
          <IntlMessage id="public.request.title" />
        </h2>

        <RequestPrayer
          formikValues={formikValues}
          setFormikValues={setFormikValues}
          tooltipTemplate={tooltipTemplate}
        />

        <Row className="form-group">
          <Col xs="12">
            <FieldAdapter
              name="recipient_name"
              formikValues={formikValues}
              setFormikValues={setFormikValues}
              value={formikValues.recipient_name}
              tooltipTemplate={tooltipTemplate}
              isDesktop={isDesktop}
              errors={errors}
              touched={touched}
              className={`my-control their_name_margin_top ${formikValues.for_who === "forsomeoneelse" ? 'their_name_margin_top_mobile' : ''} ${isDesktop  ? 'their-name-input-container' : ''}`}
              inputProps={{ className: 'data-hj-allow' }}
            />
          </Col>
        </Row>

        <Row className={`form-group ${
            formikValues.for_who == "forsomeoneelse" && formikValues.is_gift
              ? ""
              : "d-none"
            }`}>
          <Col xs="12">
            <FieldAdapter
              name="recipient_email"
              formikValues={formikValues}
              setFormikValues={setFormikValues}
              value={formikValues.recipient_email}
              tooltipTemplate={tooltipTemplate}
              isDesktop={isDesktop}
              errors={errors}
              touched={touched}
              className={`my-control their_name_margin_top mt-3 ${formikValues.for_who === "forsomeoneelse" ? 'their_name_margin_top_mobile' : ''} ${isDesktop  ? 'their-name-input-container' : ''}`}
              inputProps={{ className: 'data-hj-allow' }}
            />
          </Col>
        </Row>

        <Row className={`form-group ${
            formikValues.for_who == "forsomeoneelse" && formikValues.is_gift
              ? ""
              : "d-none"
            }`}>
          <Col xs="12">
            <FieldAdapter
              formikValues={formikValues}
              setFormikValues={setFormikValues}
              value={formikValues.sender_name}
              name="sender_name"
              tooltipTemplate={tooltipTemplate}
              isDesktop={isDesktop}
              errors={errors}
              touched={touched}
              className={`my-control their_name_margin_top mt-3 ${formikValues.for_who === "forsomeoneelse" ? 'their_name_margin_top_mobile' : ''} ${isDesktop  ? 'their-name-input-container' : ''}`}
              inputProps={{ className: 'data-hj-allow' }}
            />
          </Col>
        </Row>

        {/* <Row
          className={`form-group font-size-18px mt-3 ${
            formikValues.for_who == "forsomeoneelse" ? "" : "d-none"
          }`}
        >
          <Col xs="12" className="position-relative">
            <Form.Check
              custom
              type="checkbox"
              className={`checkbox_custom mt-0 ${!formikValues.is_gift ? 'tablet_checkbox_custom' : 'is-gift-checkbox'}`}
              name="is_gift"
              size="sm"
              checked={formikValues.is_gift}
              onChange={(event) => {
                setFormikValues({
                  ...formikValues,
                  is_gift: event.target.checked,
                });
              }}
              label={
                <IntlMessage id="public.request.form.is_git.check_label" />
              }
              id="is_gift"
            />
            {tooltipIsGift()}
          </Col>
        </Row> */}

        {/* <Row
          className={`form-group ${
            formikValues.for_who == "forsomeoneelse"
              ? ""
              : "d-none"
          } position-relative tooltip-group`}
        >
          <Col xs="12">
            <TextField
              fullWidth={true}
              variant="filled"
              className="my-control recipient_margin_top mt-30px"
              name="recipient_email"
              value={formikValues.recipient_email}
              placeholder={
                "Enter gift recipient's email"
              }
              onChange={(event) => {
                setFormikValues({
                  ...formikValues,
                  recipient_email: event.target.value,
                });
              }}
              label={
                <>
                    <span className="for-focus">
                      {" "}
                      <IntlMessage id="public.request.form.is_git.label" />
                    </span>
                  <span className="text-dark for-no-focus">
                      {" "}
                    <IntlMessage id="public.request.form.is_git.placeholder" />{" "}
                    </span>
                </>
              }
            />

            <ErrorMessageWrapper
              touched={touched}
              errors={errors}
              fieldName="recipient_email"
            />
          </Col>
        </Row> */}

        {/* {formikValues.for_who == "forsomeoneelse" && (
          <Row
            className={`form-group ${
              formikValues.for_who == "forsomeoneelse"
                ? ""
                : "d-none"
            } position-relative tooltip-group`}
          >
            <Col xs="12" className="recipient_margin">
              <TextField
                fullWidth={true}
                variant="filled"
                className="my-control"
                name="sender_name"
                value={formikValues.sender_name}
                placeholder={
                  "From: Enter your name"
                  //<IntlMessage id="public.request.form.is_git.placeholder" />
                }
                onChange={(event) => {
                  setFormikValues({
                    ...formikValues,
                    sender_name: event.target.value,
                  });
                }}
                label={
                  formikValues.sender_name ? (
                    <IntlMessage id="public.request.form.from.label" />
                  ) : (
                    <>
                      <span className="for-focus">
                        <IntlMessage id="public.request.form.from.label" />
                      </span>
                      <span className="text-dark for-no-focus">
                        <IntlMessage id="public.request.form.from.placeholder" />
                      </span>
                    </>
                  )
                }
              />
              <ErrorMessageWrapper
                touched={touched}
                errors={errors}
                fieldName="sender_name"
              />
            </Col>
          </Row>
        )} */}

        <Row className={`form-group position-relative tooltip-group tooltip-reason is_custom_reason_field
          ${(formikValues.for_who === "forsomeoneelse" && formikValues.is_gift) ? "custom_reason_field_is_gift" : "custom_reason_field_is_not_gift" }
           ${formikValues.for_who === "forsomeoneelse" && "mt-3"}`}>
          <Col xs="12">
            <FieldAdapter
              formikValues={formikValues}
              setFormikValues={setFormikValues}
              value={formikValues.reason}
              name="reason"
              tooltipTemplate={tooltipTemplate}
              isDesktop={isDesktop}
              errors={errors}
              touched={touched}
              rows={5}
              multiline
              className={`my-control reasion_margin_placeholder ${formikValues.for_who === "formyself" ? "reason_margin_top" : "mt-0"} ${isDesktop ? 'reason-text-area' : ''}` }
              inputProps={{ className: 'data-hj-allow' }}
            />
          </Col>
          {tooltipReason()}
        </Row>
      </Col>
      <div
        className={`m-float-group2 d-block d-sm-none option-wrapper ${
          needOption ? "need-option" : ""
        } `}
        >
          <div className="or-tag">or</div>
          <Button
            className="form-control"
            variant="light"
            onClick={() => setNeedOption(true)}
          >
            <IntlMessage id="public.request.right.more_option" />
          </Button>
      </div>
      <Col
        lg={isTablet ? { span: 12, offset: 0 } : { span: 7, offset: 3 }}
        md={isTablet ? { span: 12, offset: 0 } : { span: 8, offset: 2 }}
        xs={{ span: 12, offset: 0 }}
        className="d-none d-md-block"
      >
        <Row className="form-group">
          <Col xs="12" className="step1-tablet-cover-btn custom-next-button-col">
            <Button
              className="form-control hippie-blue-button next-button button_margin_top"
              type="submit"
            >
              <b className="title_next_btn">
                <IntlMessage id="common.next" />
              </b>
            </Button>
          </Col>
        </Row>

        <div className="d-none d-sm-block mt-2 font-size-18px">
          {!user && renderRequestSignIn()}
        </div>
      </Col>
    </Row>
  );
};

export default LeftPart;
