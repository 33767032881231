import React from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { ResetPasswordFormValidator } from "./validator"
import "./styles.scss"

import { useDispatch } from "react-redux"
import { resetPasswordApi } from "./api_v1"
import { useLocation } from "react-router"
import { setCurrentUser } from "@iso/actions/User"

import alertActions from "@iso/actions/Alert"
import * as qs from "query-string"

const { openAlert } = alertActions

const ResetPasswordForm = ({ loginURL, userRole }) => {
  const location = useLocation()
  const { user_id, token } = qs.parse(location.search)
  const dispatch = useDispatch()

  const [initFormValue, setInitFormValue] = React.useState({
    user_id: user_id,
    token: token,
    password: "",
    passwordConfirmation: "",
  })

  const handleResetPassword = (values, bag) => {
    resetPasswordApi(values)
      .then((res) => {
        if (!res) {
          bag.setErrors({
            passwordConfirmation: "Somethings went wrong!",
          })
          bag.setSubmitting(false)
        }
        const { code, data } = res.data
        if (code === 200 && data.role === userRole) {
          dispatch(
            openAlert({
              message: "Your password has been changed successfully!",
              severity: "success",
            })
          )

          dispatch(
            setCurrentUser({
              ...data,
              rememberMe: values.remember_me,
            })
          )
        } else {
          bag.setErrors({
            passwordConfirmation: data.message,
          })
        }
      })
      .catch((res) => {
        bag.setErrors({
          passwordConfirmation: "Somethings went wrong!",
        })
        bag.setSubmitting(false)
      })
  }

  return (
    <>
      <Formik
        initialValues={initFormValue}
        validationSchema={ResetPasswordFormValidator}
        onSubmit={handleResetPassword}
      >
        {({ values, handleSubmit, handleChange, touched, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Form.Group as={Row} controlId="token">
              <Col sm={{ span: "8", offset: "2" }}>
                <Form.Control
                  name="token"
                  type="hidden"
                  value={values.token}
                  onChange={handleChange}
                />
                <Form.Control
                  name="user_id"
                  type="hidden"
                  value={values.user_id}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-0">
              <Col sm={{ span: "8", offset: "2" }}>
                <Form.Label>New password</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="New password"
                  value={values.password}
                  onChange={handleChange}
                  autoFocus={true}
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="password"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-0">
              <Col sm={{ span: "8", offset: "2" }}>
                <Form.Label>Confirm password</Form.Label>
                <Form.Control
                  name="passwordConfirmation"
                  type="password"
                  placeholder="Re-enter new password"
                  onChange={handleChange}
                />
                <Form.Control
                  name="errorField"
                  type="hidden"
                  disabled={true}
                  placeholder="Re-enter new password"
                  onChange={handleChange}
                />
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="passwordConfirmation"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-1">
              <Col sm="12" className="text-center mt-4">
                <Button className="hippie-blue-button" type="submit">
                  Change my password
                </Button>
              </Col>
            </Form.Group>
          </form>
        )}
      </Formik>
    </>
  )
}

export default ResetPasswordForm
