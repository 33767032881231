import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    flexGrow: 1,
  },
  root: {
    boxShadow: 'none',
  },
  header: {
    height: '121px',
    padding: '28px 50px;',
    borderBottom: '1px solid #D9E9F1',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
  },
  content: {
    padding: "18px 40px",
  },
  gridItemRoot: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  visibleIcon: {
    position: "absolute",
  },
  buttonRoot: {
    height: "50px",
    background: "unset",
    padding: '20px',
    textTransform: "none !important",
    fontSize: '16px',
    fontFamily: "BrandonText",
    color: '#1c1634',
    outline: "none!important",
    '&:hover': {
      background: '#fff',
    },
  },
  buttonSave: {
    background: "rgba(73, 158, 158, 1)",
    boxShadow:'none',
    
    color: "#fff",
    '&:hover': {
      background: "rgba(73, 158, 158, 1)",
      boxShadow:'none',
    }
  },
}));

export default useStyles;
