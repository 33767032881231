import IntlMessage from "@iso/components/intlMessage";
import PrayerDad from "@iso/assets/images/prayer_type_dad.svg";
import PrayerAngel from "@iso/assets/images/prayer_type_angel.svg";
import PrayerMemorial from "@iso/assets/images/prayer_type_memorial.svg";
import PrayerLove from "@iso/assets/images/prayer_type_love.svg";
import PrayerMom from "@iso/assets/images/prayer_type_mom.svg";
import PrayerWork from "@iso/assets/images/prayer_type_work.svg";
import PrayerPet from "@iso/assets/images/prayer_type_pet.svg";
import PrayerPetition from "@iso/assets/images/prayer_type_petition.svg";
import PrayerHealth from "@iso/assets/images/prayer_type_health.svg";
import PrayerBirthday from "@iso/assets/images/prayer_type_birthday.svg";

export const NAME_MIN_LENGTH = 2;
export const NAME_MAX_LENGTH = 20;
export const DATE_FORMAT_PRAYER_REQUEST = "MMMM Do YYYY";
export const TIME_FORMAT_PRAYER_REQUEST = "HH:mm";
export const MINUTE_FORMAT = "HH:mm";
export const DATE_FORMAT = "YYYY-MM-DD";

export const DISPLAY_ACTION_BUTTON = ["unassigned", "incomplete", "overdue"];
export const DISPLAY_FLAG_BUTTON = ["unassigned", "paused"];
export const MINUTE_STEP = 30;
export const SALUTION = [
  {id : "god_bless", label :"God bless"},
  {id : "sincerely", label :"Sincerely"},
  {id : "best_regards", label :"Best regards"},
  {id : "kind_regards", label :"Kind regards"},
  {id : "grace_and_peace", label :"Grace and peace"},
]

export const COLOR_CODE = {
  unassigned: "#ff9900",
  incomplete: "#6aa84f",
  declined: " #999999",
  paused: " #999999",
  overdue: "#990000",
  overdue_done: "#ff9900",
  overdue_done_approved: "#009e0f",
  approved: "#009e0f",
  completed: "#999999",
  active: 'green',
  disabled: '#999999',
  pending: '#ff9900',
  banned: '#999999',
  unverified: "#e22828",
  verified: "#01a2a2",
};

export const DENOMINATIONS = [
  "catholic",
  "protestant",
  "evangelical",
  "christian",
  "eastern_orthodox",
  "anglican",
  "no_preference",
  "non_denominational",
];

export const timezones = {
  0: "UTC +00:00",
  1: "UTC +01:00",
  2: "UTC +02:00",
  3: "UTC +03:00",
  4: "UTC +04:00",
  5: "UTC +05:00",
  6: "UTC +06:00",
  7: "UTC +07:00",
  8: "UTC +08:00",
  9: "UTC +09:00",
  10: "UTC +10:00",
  11: "UTC +11:00",
  12: "UTC +12:00",
  "-11": "UTC -11:00",
  "-10": "UTC -10:00",
  "-9": "UTC -09:00",
  "-8": "UTC -08:00",
  "-7": "UTC -07:00",
  "-6": "UTC -06:00",
  "-5": "UTC -05:00",
  "-4": "UTC -04:00",
  "-3": "UTC -03:00",
  "-2": "UTC -02:00",
  "-1": "UTC -01:00",
};

export const APPROVAL_ACTION = {
  approved: "approved",
};

export const APPROVAL_STATUS = ["overdue_done", "completed"];
export const FLAGGED_STATUS = ["paused"];
export const TOGGLE_PAUSE_STATUS = [
  "paused",
  "unassigned",
  "incomplete",
  "overdue",
];

export const SHOW_DELETE_BUTTON_STATUS = [
  "unassigned",
  "incomplete",
  "declined",
  "paused",
  "overdue",
  "overdue_done",
  "completed",
];

export const SHOW_ARCHIVE_BUTTON_STATUS = [
  "unassigned",
  "incomplete",
  "declined",
  "paused",
  "overdue",
  "overdue_done",
  "completed",
  "approved",
  "overdue_done_approved",
];

export const DENY_CHANGE_ASSIGNEE_STATUS = [
  "paused",
	"approved",
	"overdue_done_approved",
];
export const DISPLAY_PRAYER_INFO_STATUS = [
  "paused",
  "unassigned",
  "incomplete",
  "declined",
];

export const SHOW_FLAG_AREA = ["yellow"]

export const PRAYER_TYPES_AND_ICONS = [
  {
    type: "dad",
    title: "landing.prayers.item1",
    icon: PrayerDad,
  },
  {
    type: "guardian-angel",
    title: "landing.prayers.item2",
    icon: PrayerAngel,
  },
  {
    type: "pet",
    title: "landing.prayers.item3",
    icon: PrayerPet,
  },
  {
    type: "miracle",
    title: "landing.prayers.item4",
    icon: PrayerPetition,
  },
  {
    type: "good-health",
    title: "landing.prayers.item5",
    icon: PrayerHealth,
  },
  {
    type: "birthday",
    title: "landing.prayers.item6",
    icon: PrayerBirthday,
  },
  {
    type: "memorial",
    title: "landing.prayers.item7",
    icon: PrayerMemorial,
  },
  {
    type: "love",
    title: "landing.prayers.item8",
    icon: PrayerLove,
  },
  {
    type: "mom",
    title: "landing.prayers.item9",
    icon: PrayerMom,
  },
  {
    type: "work",
    title: "landing.prayers.item10",
    icon: PrayerWork,
  },
];
