import { Button, Checkbox, Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import ArrowLeft from '@iso/assets/images/account_prayers_arrow_left.svg';
import ArrowForward from "@iso/assets/images/account_prayers_arrow.svg";
import { makeStyles } from '@material-ui/core/styles'
import IntlMessage from '@iso/components/intlMessage'
import moment from 'moment'
import 'moment-timezone'
import { PRAYER_TYPES_AND_ICONS } from '@iso/components/Request/constants'
import PrayerDefault from '@iso/assets/images/prayer_type_default.svg'
import PrayerItemExpandedDetail from './PrayerItemExpandedDetail'
import { Col, Row } from 'react-bootstrap'
import './style.scss'
import { isMobile, isTablet } from 'react-device-detect'
import { STATUS, STATUS_LABEL } from '@iso/components/Request/NotificationLog'
import ChildRequestDesktop from './ChildRequests/ChildRequestDesktop';
import ChildRequestTablet from './ChildRequests/ChildRequestTablet';
import ChildRequestMobile from './ChildRequests/ChildRequestMobile';

const useStyles = makeStyles(theme => ({
  status: {
    marginTop: "30px !important",
  },
  statusMobile: {
    marginTop: "20px !important",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  image: {
    width: "60px",
    height: "60px",
    "@media (max-width: 400px)": {
      width: "50px",
      height: "50px",
    },
    borderLeft: "1px solid #AFDAF5",
    borderBottom: "1px solid #AFDAF5",
    borderBottomLeftRadius: "5px",
    padding: "12px",
  },
  expandButton: {
    textTransform: "none",
    fontFamily: "BrandonText",
    fontSize: "16px",
    border: "none",
    outline: "none !important",
    marginRight: "28px",
  },
  detailsContainer: {
    position: "relative",
    padding: "20px 50px",
    "@media (min-width:600px) and (max-width: 768px)": {
      marginTop: "-30px",
    }
  },
  title: {
    fontSize: "18px",
    fontFamily: "BrandonText",
    fontWeight: "bold",
  },
  detailTitle: {
    fontSize: "14px",
    fontFamily: "BrandonText",
    color: "#6D7278",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  detailTitleFirst: {
    marginTop: "25px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "17px",
    },
  },
  subDetailTitle: {
    fontSize: "14px",
    fontFamily: "BrandonText",
    color: "#6D7278",
  },
  viewDetailsTitle: {
    fontSize: "16px",
    fontFamily: "BrandonText",
    color: "#499E9E",
    cursor: "pointer",
  },
  checkBox: {
    borderRadius: "6px",
    height: "20px",
    width: "20px",
    color: "#499e9e !important",
  },
  detail: {
    fontFamily: "BrandonText",
    marginTop: "5px",
    fontSize: "18px",
    "@media (max-width: 400px)": {
      fontSize: "14px !important",
    },
    color: "#1C1634",
  },
  bold: {
    fontWeight: "bold",
  },
  completed: { color: theme?.status?.completed },
  inProgress: { color: theme?.status?.inProgress },
  partTitle: {
    fontFamily: "BrandonText",
    fontSize: "18px",
    marginTop: "23px",
    marginBottom: "0",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "17px",
    },
  },
  capitalized: {
    textTransform: "capitalize",
  },
  smallAvatar: {
    display: "flex",
    "& > img": {
      width: "50px",
      marginRight: "10px",
    },
    "& > div": {},
  },
  marginLeftItem: {
    marginLeft: "30px",
  },

  completedColor: {
    color: "#52B9D4",
    fontWeight: "700",
    fontSize: "16px"
  },

  inProgressColor: {
    color: "#F26C51",
    fontWeight: "700",
    fontSize: "16px",
  },
  giftRecipientEmail: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.up("md")]: {
      overflow: "unset",
    },
  },
  unopened: { color: theme?.status?.inProgress, fontWeight: "bold", wordBreak: 'break-all' },
  opened: { color: '#52B9D4', fontWeight: "bold", wordBreak: 'break-all' },
}));

const getStatusTextColor = (status, style) => {
  if (!style) {
    return;
  }

  let color = style.completedColor;

  if (status.toLowerCase() == "in-progress") {
    color = style.inProgressColor;
  }
  return color;
};

const getPrayerName = prayer => {
  if (!prayer) {
    return;
  }

  let prayerName = "Request ID ";

  prayerName = prayerName + prayer?.transaction?.id ?? 'N\A';
  return prayerName;
};

const getPrayerDate = prayer => {
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  return moment.utc(prayer.created_at).utcOffset(time_zone).format("MMMM D, YYYY");
};

const getPrayerTypeAndIcon = (type, slug) => {
  let defaultPrayer = {
    type: "default",
    title: "No special prayer",
    icon: PrayerDefault,
  };
  let prayer = defaultPrayer;

  if (!type) {
    return prayer;
  }

  // Find prayer from constants
  const findPrayer = PRAYER_TYPES_AND_ICONS.find(item => item.type == slug);
  if (!findPrayer) {
    prayer = defaultPrayer;
    return prayer;
  }
  // Set prayer if found
  prayer = findPrayer;
  return prayer;
};
const PrayerItemExpanded = props => {
  const {
    prayer,
    isDetail,
    setDetail,
    fetchPrayerDetailsData,
    detailsPrayer,
  } = props;
  const classes = useStyles();
  const { handleToggleExpand, back, setBack } = props;
  const statusText =
    prayer.general_status === "completed" ? "Completed" : "In-progress";
  const statusColor = getStatusTextColor(statusText, classes);
  const prayerTypeAndIcon = getPrayerTypeAndIcon(
    prayer.type,
    prayer.type?.slug
  );
  const prayerDate = getPrayerDate(prayer);
  const prayerName = getPrayerName(prayer);
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  const isDesktop = !isMobile && !isTablet
  const setDetailsPage = id => {
    setDetail(true);
    fetchPrayerDetailsData(id);
  };

  const getNotificationStatusClass = () => {
    return prayer.is_opened_email ? classes.opened : classes.unopened;
  };
  const getNotificationStatusLabel = () => {
    return prayer.is_opened_email
      ? STATUS_LABEL.viewed
      : STATUS_LABEL.send_unopenned;
  };

  useEffect(() => {
    if (back) {
      setBack(false);
      handleToggleExpand(isDetail);
    }
  }, [back]);

  const generalRender = () => {
    return (
      <>

        {isDesktop ? (
          <>
          <Grid item xs={6}>
            <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.for_whom" />
            </Typography>
            <Typography className={`${classes.detail}`}>
              {prayer.recipient === "for_someone" ? (
                <IntlMessage id="public.request.form.for_someone_else"></IntlMessage>
              ) : (
                <IntlMessage id="public.request.form.for_myself" />
              )}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={`${classes.detailTitle} custom-right ml-md-4`}>
              {(prayer.is_gift > 0 || prayer.recipient === "for_someone") ? (
                <IntlMessage id="user.account.request.their_name"></IntlMessage>
              ) : (
                <IntlMessage id="user.account.request.your_name"></IntlMessage>
              )}
            </Typography>
            <Typography className={`${classes.detail} custom-right ml-md-4`}>
              {prayer.recipient_name}
            </Typography>
          </Grid>
          </>
        ) : isTablet ? (
          <>
          <Grid item xs={6}>
            <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.for_whom" />
            </Typography>
            <Typography className={`${classes.detail}`}>
              {prayer.recipient === "for_someone" ? (
                <IntlMessage id="public.request.form.for_someone_else"></IntlMessage>
              ) : (
                <IntlMessage id="public.request.form.for_myself" />
              )}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={`${classes.detailTitle} custom-right ml-md-4`}>
              {(prayer.is_gift > 0 || prayer.recipient === "for_someone") ? (
                <IntlMessage id="user.account.request.their_name"></IntlMessage>
              ) : (
                <IntlMessage id="user.account.request.your_name"></IntlMessage>
              )}
            </Typography>
            <Typography className={`${classes.detail} custom-right ml-md-4`}>
              {prayer.recipient_name}
            </Typography>
          </Grid>
          </>
        ) : (
          <>
          <Grid item xs={7}>
            <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.for_whom" />
            </Typography>
            <Typography className={`${classes.detail}`}>
              {prayer.recipient === "for_someone" ? (
                <IntlMessage id="public.request.form.for_someone_else"></IntlMessage>
              ) : (
                <IntlMessage id="public.request.form.for_myself" />
              )}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography className={`${classes.detailTitle} custom-right ml-md-4`}>
              {(prayer.is_gift > 0 || prayer.recipient === "for_someone") ? (
                <IntlMessage id="user.account.request.their_name"></IntlMessage>
              ) : (
                <IntlMessage id="user.account.request.your_name"></IntlMessage>
              )}
            </Typography>
            <Typography className={`${classes.detail} custom-right ml-md-4`}>
              {prayer.recipient_name}
            </Typography>
          </Grid>
          </>
        )}

        {prayer.is_gift > 0 && (
          <Grid
            item
            xs={12}
            className="align-items-end justify-content-start flex-row d-flex mt-3 mt-md-4 mb-3"
          >
            <Checkbox
              color="primary"
              checked={prayer.is_gift > 0 ? true : false}
              disabled
              className={classes.checkBox}
            ></Checkbox>
            <Typography className={`${classes.detail} ml-2`}>
              <IntlMessage id="user.account.request.gift_notification"></IntlMessage>
            </Typography>
          </Grid>
        )}

        {prayer.is_gift > 0 && (
          <>
            <Grid item xs={6}>
              <Typography className={`${classes.detailTitle}`}>
                <IntlMessage id="user.account.request.gift_recipient_email"></IntlMessage>
              </Typography>
              <Typography
                className={`${classes.detail}  ${classes.giftRecipientEmail}`}
              >
                {prayer.recipient_email}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className={`${classes.detailTitle} custom-right ml-md-4`}>
                <IntlMessage id="public.request.step3.left.request_details.from"></IntlMessage>
              </Typography>
              <Typography className={`${classes.detail} custom-right ml-md-4`}>
                {prayer.sender_name}
              </Typography>
            </Grid>
          </>
        )}

        {prayer.reason && (
          <Grid item xs={12}>
            <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.reason" />
            </Typography>
            <Typography className={`${classes.detail}`}>
              {prayer.reason}
            </Typography>
          </Grid>
        )}

        {prayer.is_gift > 0 && (
          <>
            <Grid item xs={12}>
              <Typography className={classes.detailTitle}>
                <IntlMessage id="public.request.step3.left.gift_message.title" />
              </Typography>
              <Typography className={`${classes.detail}`}>
                {prayer.gift_message}
              </Typography>
            </Grid>
          </>
        )}

        <div className={`${classes.partTitle} desktop`}>
          <IntlMessage id="user.account.request.customization" />
        </div>
        <div className={`${classes.partTitle} tablet display-none-with-tablet tablet-display-none display-none-with-mobile ml-2`}>
          <IntlMessage id="user.account.request.customization" />
        </div>
        <Grid item xs={12}>
          <Typography className={`${classes.title} mobile mobile-display-none`}>
            <IntlMessage id="user.account.request.customization" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={`${classes.detailTitle} mt-3 desktop`}>
            <IntlMessage id="user.account.request.prayer_date" />{" "}
          </Typography>
          <Typography className={`${classes.detailTitle} mt-3 mobile`}>
            <IntlMessage id={isTablet ? 'user.account.request.prayer_date' : 'user.account.request.prayer_date.mobile'} />{" "}
          </Typography>
          <Typography className={`${classes.detail}`}>
            {moment.utc(prayer.start_date).utcOffset(time_zone).format("MMM DD, YYYY")}
          </Typography>
        </Grid>

        <Grid item xs={6} className="denomination-info desktop">
          <Typography className={`${classes.detailTitle} mt-3 ml-md-4`}>
            <IntlMessage id="user.account.request.denomination" />{" "}
          </Typography>
          <Typography className={`${classes.detail} ml-md-4`}>
            {prayer.denomination_label}
          </Typography>
        </Grid>

        <Grid item xs={6} className="denomination-info tablet tablet-display-none display-none-with-tablet">
          <Typography className={`${classes.detailTitle} custom-right mt-3 ml-md-2`}>
            <IntlMessage id={isTablet ? 'user.account.request.denomination.tablet' : 'user.account.request.denomination.mobile'} />{" "}
          </Typography>
          <Typography className={`${classes.detail} custom-right ml-md-2`}>
            {prayer.denomination_label}
          </Typography>
        </Grid>

        {prayer.type && (
          <Grid item xs={12}>
            <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.mosted_request" />{" "}
            </Typography>
            <Typography className={`${classes.detail}`}>
              {prayer.type.name}
            </Typography>
          </Grid>
        )}

        {prayer.special_request && (
          <Grid item xs={12}>
            <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.special_request" />{" "}
            </Typography>
            <Typography className={`${classes.detail}`}>
              {prayer.special_request}
            </Typography>
          </Grid>
        )}
      </>
    );
  };
  return (
    <>
      {isDetail && (
        <PrayerItemExpandedDetail
          prayer={detailsPrayer}
          getFrequencyLabel={props.getFrequencyLabel}
          setDetail={setDetail}
          generalInfoPrayer={prayer}
          transactionId={prayer?.transaction?.id}
        ></PrayerItemExpandedDetail>
      )}
      {!isDetail && (
        <Grid container spacing={0}>
          <Grid item xs={12} className={`${classes.imageContainer} desktop-detail`}>
            <Button
              className={classes.expandButton}
              onClick={() => handleToggleExpand(isDetail)}
              style={isTablet ? {'margin-left':'-15px'} : {}}
              color="primary"
            >
              <img src={ArrowLeft} className="ml-3 mr-3"></img>
              <span className="d-none d-md-inline-block text-hippie-blue">
                <IntlMessage id="user.account.request.back"></IntlMessage>
              </span>{" "}
            </Button>
            <img src={prayerTypeAndIcon.icon} className={classes.image} style={isTablet ? {'position':'relative','top':'-8px','right':'-8px'} : {}}></img>
          </Grid>

          <Grid item xs={12} className={`${classes.imageContainer} tablet display-none-with-tablet tablet-display-none display-none-with-mobile`}>
            <Button
              className={classes.expandButton}
              onClick={() => handleToggleExpand(isDetail)}
              color="primary"
            >
              <img src={ArrowLeft} className="mr-2"></img>
              <span className="d-none d-md-inline-block text-hippie-blue">
                <IntlMessage id="user.account.request.back"></IntlMessage>
              </span>{" "}
            </Button>
            <img src={prayerTypeAndIcon.icon} className={`${classes.image} custom-image`}></img>
          </Grid>

          <Grid item xs={12} md={10} className={`${classes.detailsContainer}` }>
            <Grid container>
              {/*Mobile*/}
              <div className="mobile mobile-display-none icon-request-type-right">
                <img
                  className={classes.image}
                  src={prayerTypeAndIcon.icon}
                ></img>
              </div>
              <Grid item xs={12} className="mobile mobile-display-none">
                <Typography className={`${classes.title} title-request ml-0 mb-2 mobile`}>
                  {prayerName}
                </Typography>
                {prayerDate}
              </Grid>
              <Grid item xs={7} className="tablet display-none-with-tablet tablet-display-none display-none-with-mobile mt-4">
                <Typography className={`${classes.title} title-request ml-0 mobile`}>
                  {prayerName}
                </Typography>
                <Typography className={`${classes.detail} mt-0`}>{prayerDate}</Typography>
              </Grid>
              <Grid item xs={6} className="mobile mobile-display-none custom-status-mobile">
                <Typography
                  className={`${classes.detailTitle}  ${classes.statusMobile} `}
                >
                  <IntlMessage id="user.account.request.status"></IntlMessage>
                </Typography>
                <Typography
                  className={`${classes.detail} ${classes.bold} ${classes.capitalized}`}
                  classes={{ root: statusColor }}
                  style={isMobile && !isTablet ? {'white-space':'nowrap'} : {}}
                >
                  {statusText}
                  {
                    prayer.frequency == 'one_time' && statusText !== 'In-progress' && 
                    <>
                    <span>&nbsp;/</span>
                    <span className={`${getNotificationStatusClass()}`}>
                      &nbsp;{getNotificationStatusLabel()}
                    </span>
                    </>
                  }
                </Typography>
              </Grid>
              <Grid item xs={4} className="tablet display-none-with-tablet tablet-display-none display-none-with-mobile custom-status">
                <Typography
                  className={`${classes.detailTitle}  ${classes.status} mt-0 ml-md-2`}
                >
                  <IntlMessage id="user.account.request.status"></IntlMessage>
                </Typography>
                <Typography
                  className={`${classes.detail} ${classes.bold} ${classes.capitalized} ml-md-2`}
                  classes={{ root: statusColor }}
                >
                  {statusText}
                  {
                    prayer.frequency == 'one_time' && statusText !== 'In-progress' && 
                    <>
                    <span>&nbsp;/</span>
                    <span className={`${getNotificationStatusClass()}`}>
                      &nbsp;{getNotificationStatusLabel()}
                    </span>
                    </>
                  }
                </Typography>
              </Grid>

              {/*Desktop*/}
              <Grid item xs={6} className="pr-5 desktop-detail" style={isTablet ? {'margin-top':'20px'} : {}}>
                <Typography className={classes.title}>{prayerName}</Typography>
                <Typography className={`${classes.detail} mt-0`}>{prayerDate}</Typography>
              </Grid>
              <Grid item xs={6} className="desktop-detail" style={isTablet ? {'margin-top':'20px', 'white-space': 'nowrap'} : {}}>
                <Typography className={`${classes.detailTitle} mt-0 ml-md-4`}>
                  <IntlMessage id="user.account.request.status"></IntlMessage>
                </Typography>
                <Typography
                  className={`${classes.detail} ${classes.bold} ${classes.capitalized} ml-md-4`}
                  classes={{ root: statusColor }}
                >
                  {statusText}
                  {/* {
                    isDesktop ?
                    <>
                      {
                        prayer.frequency == 'one_time' && 
                        <span className={`${getNotificationStatusClass()}`}> /
                          &nbsp;{getNotificationStatusLabel()}
                        </span>
                      }
                    </> :
                    <>
                      {
                        prayer.frequency == 'one_time' && statusText !== 'In-progress' && 
                        <span className={`${getNotificationStatusClass()}`}> /
                          &nbsp;{getNotificationStatusLabel()}
                        </span>
                      }
                    </>
                  } */}
                  {
                    prayer.frequency == 'one_time' && statusText !== 'In-progress' && 
                    <>
                    <span>&nbsp;/</span>
                    <span className={`${getNotificationStatusClass()}`}>
                      &nbsp;{getNotificationStatusLabel()}
                    </span>
                    </>
                  }
                </Typography>
              </Grid>

              {
                <>
                  {/* Contribution Details */}
                  <Grid item xs={12} className="custom-status-mobile">
                    <div className={`${classes.partTitle} text-bold-mobile `}>
                      <IntlMessage id="user.account.request.contribution_detail" />
                    </div>
                  </Grid>
                  {isDesktop ? (
                    <>
                      <Grid item xs={6}>
                        <Typography className={`${classes.detailTitle}`}>
                          <IntlMessage id="user.account.request.prayer_contribution" />{" "}
                        </Typography>
                        <Typography className={`${classes.detail}`}>
                          {prayer.contribution.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography className={`${classes.detailTitle} custom-right ml-md-4`}>
                          <IntlMessage id="user.account.request.frequency" />{" "}
                        </Typography>
                        <Typography className={`${classes.detail} custom-right ml-md-4`}>
                          {props.getFrequencyLabel(prayer.frequency)}
                        </Typography>
                      </Grid>
                    </>
                  ) : isTablet ? (
                    <>
                      <Grid item xs={6}>
                        <Typography className={`${classes.detailTitle}`}>
                          <IntlMessage id="user.account.request.prayer_contribution" />{" "}
                        </Typography>
                        <Typography className={`${classes.detail}`}>
                          {prayer.contribution.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography className={`${classes.detailTitle} custom-right ml-md-4`}>
                          <IntlMessage id="user.account.request.frequency" />{" "}
                        </Typography>
                        <Typography className={`${classes.detail} custom-right ml-md-4`}>
                          {props.getFrequencyLabel(prayer.frequency)}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={7}>
                        <Typography className={`${classes.detailTitle}`}>
                          <IntlMessage id="user.account.request.prayer_contribution" />{" "}
                        </Typography>
                        <Typography className={`${classes.detail}`}>
                          {prayer.contribution.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={5}>
                        <Typography className={`${classes.detailTitle} custom-right ml-md-4`}>
                          <IntlMessage id="user.account.request.frequency" />{" "}
                        </Typography>
                        <Typography className={`${classes.detail} custom-right ml-md-4`}>
                          {props.getFrequencyLabel(prayer.frequency)}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {/* Your prayer dates */}
                  <Grid item xs={12}>
                    <Typography className={`${classes.detailTitle} desktop mb-3`}>
                      <IntlMessage id="user.account.request.your_prayer_dates" />{" "}
                    </Typography>
                    <Typography className={`${classes.detailTitle} tablet display-none-with-tablet tablet-display-none display-none-with-mobile`}>
                      <IntlMessage id="user.account.request.your_prayer_dates" />{" "}
                    </Typography>
                    <Typography className={`${classes.detailTitle} mobile mobile-display-none`}>
                      <Row>
                      <Col xs={5}>
                      <span>
                        <IntlMessage id="user.account.request.prayer_dates" />{" "}
                      </span>
                      </Col>
                      {prayer.frequency !== 'one_time' ?
                        <Col xs={3} style={{ paddingLeft: '7px'}}>
                        <span>
                          <IntlMessage id="user.account.request.status" />{" "}
                        </span>
                        </Col>
                        : ''
                      }
                      </Row>
                    </Typography>
                    {prayer.child_requests.map((prayerDate, idx) => {
                      return (
                        <Typography
                          className={`${classes.detail}`}
                          style={{ width: "120%" }}
                        >
                          {isDesktop ? (
                            <ChildRequestDesktop
                              parentRequest={prayer}
                              childRequest={prayerDate}
                              setDetailsPage={setDetailsPage}
                              classes={classes}
                            ></ChildRequestDesktop>
                          ) : isTablet ? (
                            <ChildRequestTablet
                              parentRequest={prayer}
                              childRequest={prayerDate}
                              setDetailsPage={setDetailsPage}
                              classes={classes}
                            ></ChildRequestTablet>
                          ) : (
                            <ChildRequestMobile
                              parentRequest={prayer}
                              childRequest={prayerDate}
                              setDetailsPage={setDetailsPage}
                              classes={classes}
                            ></ChildRequestMobile>
                          )}
                        </Typography>
                      );
                    })}
                  </Grid>

                  {generalRender()}
                </>
              }
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PrayerItemExpanded;
