import React from "react";
import { Card, Button, Collapse, Badge } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import PaymentMethodForm from "./PaymentMethodForm";

const ProfileCard = (props) => {
  const {
    openPaymentMethods = true,
    profileId,
  } = props;

  const [paymentCards, setPaymentCards] = React.useState();
  const [stripeCustomer, setStripeCustomer] = React.useState();
  const [defaultCard, setDefaultCard] = React.useState({});

  React.useEffect(() => {
    const ac = new AbortController();

    UserService.adminGetStripeCustomer(profileId).then((res) => {
      ProcessRequest.run({
        res,
        successCallback: (data) => {
          setStripeCustomer(data.data);
        },
      });
    });

    UserService.adminGetUserStripeCards(profileId).then((res) => {
      ProcessRequest.run({
        res,
        successCallback: (data) => {
          setPaymentCards(data.data);
        },
      });
    });
    return () => ac.abort(); // Abort both fetches on unmount
  }, [profileId]);

  React.useEffect(() => {
    if (paymentCards && paymentCards.length && stripeCustomer) {
      setDefaultCard({
        card_id: paymentCards.find(
          (item) => item?.id === stripeCustomer.default_source
        )?.id,
      });
    }
  }, [paymentCards, stripeCustomer]);

  return (
    <>
      {paymentCards && (
        <div className="p-md-3 profile_card">
          <Card.Title className="title p-0 m-0 px-3">
            <span>
              <IntlMessage id="admin.users.settings.payment_methods.title" />
            </span>
          </Card.Title>
          <Collapse in={openPaymentMethods}>
            <Card.Body>
              <PaymentMethodForm
                paymentCards={paymentCards}
                setPaymentCards={setPaymentCards}
                defaultCard={defaultCard}
                setDefaultCard={setDefaultCard}
              />
            </Card.Body>
          </Collapse>
        </div>
      )}
    </>
  );
};

export default ProfileCard;
