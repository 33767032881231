import React, { useEffect, useState } from "react";
import { Row, Col, Button, Badge, Card } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import AdminsService from "@iso/services/AdminsService";
import {MDBBadge, MDBDataTable} from "mdbreact";
import { history } from "@iso/helpers/history";
import "./styles.scss";
import AddModal from "./AddModal";
import { SUPER_ADMIN_ROLE, ADMIN_ROLE, BANNED_STATUS, PENDING_STATUS } from "./constants";
import { translate } from "@iso/utils/Translate";

const Admins = (props) => {
  const [adminLists, setAdminList] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("pending");
  const [counts, setCounts] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const formatTableData = (data, status) => {
    let dataRow = [];
    let header = [
      {
        label: <IntlMessage id="admin.prayer_partners.header_table.id" />,
        field: "id",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.prayer_partners.header_table.first_name" />
        ),
        field: "first_name",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.prayer_partners.header_table.last_name" />
        ),
        field: "last_name",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.prayer_partners.header_table.email" />,
        field: "email",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.AdminsTab.header_table.role" />,
        field: "role",
        sort: "asc",
      },
    ];

    if (status === "all") {
      header.push({
        label: <IntlMessage id="admin.AdminsTab.header_table.status" />,
        field: "status",
        sort: "asc",
      });
    }

    data.forEach((e, index) => {
      dataRow.push({
        id: e.id,
        first_name: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.first_name ? e.first_name.toLocaleLowerCase() : ''}
          >
            {
              e.first_name
            }
          </MDBBadge>
        ),
        last_name: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.last_name ? e.last_name.toLocaleLowerCase() : ''}
          >
            {
              e.last_name
            }
          </MDBBadge>
        ),
        email: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.email ? e.email.toLocaleLowerCase() : ''}
          >
            {
              e.email
            }
          </MDBBadge>
        ),
        role:
          e.role === SUPER_ADMIN_ROLE
            ? translate("admin.create.super.admin.role")
            : translate("admin.create.admin.role"),
        status:
          e.status === BANNED_STATUS
            ? translate("admin.AdminsTab.disabled.status.title")
            : e.status === PENDING_STATUS ? translate("admin.AdminsTab.header_table.pending.invitation")
            : <span className ="text-capitalize">{e.status}</span>,
        clickEvent: () => handleClick(e.id),
      });
    });
    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    };
  };

  const handlePrayerRequests = ({ status }) => {
    AdminsService.adminsGetList({ status: status }).then((res) => {
      if (res) {
        const { code, data, count } = res.data;
        if (res && code === 200) {
          const admin_lists = formatTableData(data.data, status);
          setAdminList(admin_lists.data);
          setCounts(count);
          setCurrentStatus(status);
          if (admin_lists.data.rows.length == 0) {
            const tbody = document.querySelector(
              "tbody[data-test=table-body] tr td"
            )
            if(tbody) tbody.innerText = `You have no ${status} partner.`;
          }
        }
      }
    });
  };

  const handleClick = (id) => {
    history.push(`/admin/DetailsAdmin/${id}`);
  };

  useEffect(() => {
    handlePrayerRequests({ status: "pending" });
  }, []);

  return (
    <div className="mint-tulip-background p-3 min-vh-100 admins_list">
      <Row>
        <Col sm="6" className="text-md-left text-center mb-3">
          <h2>
            <IntlMessage id="sidebar.admin.admins.tabs.title" />
          </h2>
        </Col>
        <Col sm="6" className="text-md-right text-center mb-3">
          <Button
            className="hippie-blue-button float-md-right"
            onClick={() => setShowModal(true)}
          >
            <IntlMessage id="admin.prayer_partners.admin.title" />
          </Button>
        </Col>
      </Row>

      <Card className="card_swapper">
        <Row className="mt-3 mb-3 pl-5 pr-5 row">
          <Col xs="12" sm="6" md="3" className="mt-1 p-1 p-0">
            <Button
              variant={`${
                currentStatus === "pending" ? "active-status" : "light"
              } btn-block`}
              onClick={() => handlePrayerRequests({ status: "pending" })}
            >
              <IntlMessage id="admin.AdminsTab.header_table.pending.invitation" />
              <Badge className="ml-2 background-badge" pill variant="danger">
                {counts.pending === 0 ? "" : counts.pending}
              </Badge>
            </Button>
          </Col>
          <Col xs="12" sm="6" md="3" className="mt-1 p-1 p-0">
            <Button
              variant={`${
                currentStatus === "active" ? "active-status" : "light"
              } btn-block`}
              onClick={() => handlePrayerRequests({ status: "active" })}
            >
              <IntlMessage id="admin.AdminsTab.header_table.active" />
              <Badge className="ml-2 background-badge" pill variant="danger">
                {counts.active === 0 ? "" : counts.active}
              </Badge>
            </Button>
          </Col>
          <Col xs="12" sm="6" md="3" className="mt-1 p-1 p-0">
            <Button
              variant={`${
                currentStatus === "banned" ? "active-status" : "light"
              } btn-block`}
              onClick={() => handlePrayerRequests({ status: "banned" })}
            >
              <IntlMessage id="admin.AdminsTab.header_table.disabled" />
              <Badge className="ml-2 background-badge" pill variant="danger">
                {counts.banned === 0 ? "" : counts.banned}
              </Badge>
            </Button>
          </Col>

          <Col xs="12" sm="6" md="3" className="mt-1 p-1 p-0">
            <Button
              variant={`${
                currentStatus === "all" ? "active-status" : "light"
              } btn-block`}
              onClick={() => handlePrayerRequests({ status: "all" })}
            >
              <IntlMessage id="admin.AdminsTab.header_table.all" />
              <Badge className="ml-2 background-badge" pill variant="danger">
                {counts.all === 0 ? "" : counts.all}
              </Badge>
            </Button>
          </Col>
        </Row>
        <Row className="ml-2 mr-2">
          <Col sm="12">
            <MDBDataTable
              hover
              paging={true}
              id="admin_lists_table"
              entries={25}
              searching={false}
              noBottomColumns={true}
              entriesOptions={[5, 20, 25]}
              data={adminLists}
              sortRows={['first_name', 'last_name', 'email']}
              className="hide_scrollbar"
            />
          </Col>
        </Row>
      </Card>

      <AddModal showModal={showModal} closeModal={() => setShowModal(false)} />
    </div>
  );
};

export default Admins;
