import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQAccordionStyle from "./AccordionStyle";
import{ Link } from "react-router-dom"

const ContributionFAQ = (props) => {
  const { handleChange, expanded } = props;
  const classes = FAQAccordionStyle();
  return (
    <>
      <Accordion
        className={`${classes.accordion} ${classes.expanded} section-contribution`}
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          className={classes.headingContainer}
          expandIcon={
            <ExpandMoreIcon style={{ fill: "#AFDAF5" }} fontSize="large" />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Contributions</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            Why am I making a contribution for a prayer?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            Our contribution policy is about receiving support and giving support in return.
            It's providing the opportunity to pay it forward to others who are in need, as is
            the case with many of our prayer partners and/or the causes they support.
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            How do contributions work?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`The majority of your contribution
            is received by your Prayer
            Partner. The rest goes to cover
            the costs of technical
            infrastructure, payment
            infrastructure, as well as our
            platform and staff, so that we can
            continue to provide this service.`}
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            What is the minimum contribution?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            The contribution for a single prayer is $9. Contributions vary based on the type of prayer requested and the frequency. You can visit our <Link to='/contributions' className="text-hippie-blue">Contributions Page</Link> for more details.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ContributionFAQ;
