import React, { useEffect } from "react"
import {
  Row,
  Col,
  Form,
  Button
} from "react-bootstrap"
import IntlMessage from "@iso/components/intlMessage";
import moment from "moment";
import ToolTip from '@iso/assets/images/tool_tip.svg'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { religions, messageTones } from "@iso/Pages/Common/Religions"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@material-ui/icons/Check';
import PrayerTypes from './PrayerTypes';
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { ExpandMore } from "@material-ui/icons";
import { useLocation } from "react-router"
import TextField from '@material-ui/core/TextField';
import * as qs from "query-string"
import TodayIcon from '@material-ui/icons/Today';
import InputAdornment from '@material-ui/core/InputAdornment';
import DatepickerCustom from "../../../../components/Datepicker/DatepickerCustom";
import { showPickerCustom } from "../../../../utils/DatePickerCustomAction";
import { isTablet, isMobile } from "react-device-detect";
import FieldAdapter from "./components/FieldAdapter";
import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const RightPart = ({ formikValues, handleChange, setFormikValues, needOption, touched, errors }) => {
  const inputShowDatetime = document.getElementsByClassName('MuiInputBase-input MuiFilledInput-input MuiInputBase-inputAdornedStart MuiFilledInput-inputAdornedStart')
  if (inputShowDatetime[0]) {
    inputShowDatetime[0].classList.add('data-hj-allow')
  }
  const [open, setOpen] = React.useState(false);
  const [openMessageTone, setOpenMessageTone] = React.useState(false);
  const [openNote, setOpenNote] = React.useState(false);
  const [openNoteDeno, setOpenNoteDeno] = React.useState(false);
  const [openNoteMessageTone, setOpenNoteMessageTone] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [specialRequest, setSpecialRequest] = React.useState('');
  const [focusSpecialRequest, setFocusSpecialRequest] = React.useState(false);
  moment.locale('en');
  var minEndDate = moment(moment()).add(0, 'days');
  if (moment().hour() >= 20) {
    minEndDate = moment(moment()).add(1, 'days');
  }
  useEffect(() => {
    if (formikValues?.prayer_customization?.open_select_religion) {
      setOpen(true);
    }
  }, []);

  const handleChangeSpecialRequest = (e) => {
    setSpecialRequest(e.target.value)
  }

  const triggerOpenPopover = () => {
    setOpenNote(true);
  }

  const triggerClosePopover = () => {
    setOpenNote(false);
  }

  const triggerOpenPopoverDeno = () => {
    setOpenNoteDeno(true);
  }

  const triggerClosePopoverDeno = () => {
    setOpenNoteDeno(false);
  }

  const triggerClosePopoverMessageTone = () => {
    setOpenNoteMessageTone(false);
  }

  const location = useLocation();
  const { id } = qs.parse(location.search);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenMessageTone = () => {
    setOpenMessageTone(true);
  };

  const handleCloseMessageTone = () => {
    setOpenMessageTone(false);
  };


  const mainContent = () => {
    return (
      <Row id="right-part-wrapper">
        <Col lg={isTablet ? { span: 12, offset: 0 } : { span: 7, offset: 2 }} md={{ span: 12, offset: 0 }} xs={{ span: 12, offset: 0 }}>
          <h2 className='text-center text-md-left my-title text_option_left'>
            <IntlMessage id="public.request.right.title" />
          </h2>

          <Row className='form-group'>
            <Col xs='12' className='picker-wrapper'>
              {/*<KeyboardDatePicker
              autoOk
              minDate={moment(moment()).add(1, 'days')}
              allowKeyboardControl={false}
              // disablePast={true}
              disableToolbar
              onClick={() => {setOpenPicker(true)}}
              onClose={() => setOpenPicker(false)}
              variant="inline"
              open={openPicker}
              inputVariant="outlined"
              fullWidth={true}
              className='my-picker step1-custom-options-datepicker'
              placeholder={
                'Prayer\'s Date'
                //<IntlMessage id="public.request.right.form.date.placeholder" />
              }
              value={formikValues?.prayer_customization?.date ? formikValues?.prayer_customization?.date : null}
              format="MMM DD, yyyy"
              InputAdornmentProps={{ position: "start" }}
              onChange={date =>{
                let formattedDate = null
                if(date) formattedDate = moment(date).format("YYYY-MM-DD")

                setFormikValues({
                  ...formikValues,
                  prayer_customization: {
                    ...formikValues.prayer_customization,
                    date: formattedDate
                  },
                });
                setOpenPicker(false)
              }
              }
            />*/}
              <TextField
                fullWidth={true}
                inputProps={{ readOnly: true }}
                onClick={() => { showPickerCustom(moment(), null, true) }}
                style={{ paddingTop: "12px" }}
                variant="filled"
                className="my-control-right-part datepicker-custom mb-0"
                value={formikValues?.prayer_customization?.date ? moment(formikValues?.prayer_customization?.date).format("MMM DD, YYYY") : null}
                placeholder={
                  'Prayer\'s Date'
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <DatepickerCustom
                className='my-picker d-none'
                isOpen={openPicker}
                min={minEndDate}
                value={formikValues?.prayer_customization?.date ? moment(formikValues?.prayer_customization?.date) : null}
                onChange={date => {
                  let formattedDate = null;
                  if (date) formattedDate = moment(date).utcOffset('UTC +00:00').format()
                  setFormikValues({
                    ...formikValues,
                    prayer_customization: {
                      ...formikValues.prayer_customization,
                      date: formattedDate
                    },
                  });
                  setOpenPicker(false)
                }
                }
              />
              <ClickAwayListener onClickAway={triggerClosePopover}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  arrow
                  placement="bottom"
                  open={openNote}
                  onClose={() => triggerClosePopover()}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={<div className='tooltip-wrap'><div className="tooltip-message"><IntlMessage id="public.request.right.form.date.tooltip" /></div></div>}
                >
                  <img
                    className="tool_tip_icon_up"
                    src={ToolTip}
                    onMouseEnter={() => {
                      setOpenNote(true);
                    }}
                    onMouseLeave={() => {
                      setOpenNote(false);
                    }}
                  />
                </Tooltip>
              </ClickAwayListener>
            </Col>
          </Row>
          <Row className='form-group'>
            <Col xs='12' className='picker-wrapper cover-religion'>
              <Select
                fullWidth={true}
                className='my-control2 religion_margin_top '
                IconComponent={ExpandMore}
                value={formikValues?.prayer_customization?.religion || ''}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                onClick={event => {
                  gtag('event', custom_events.CHANGE_INPUT, {
                    input_label: 'Prayer Partner Denomination',
                    input_value: event.target.value,
                    input_type: 'select',
                  });
                  ReactPixel.track(fb_pixel_event.custom_events.CHANGE_INPUT, {
                    input_label: 'Prayer Partner Denomination',
                    input_value: event.target.value,
                    input_type: 'select',
                  });

                }
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                  className: "new-design"

                }}
                onChange={event =>
                  setFormikValues({
                    ...formikValues,
                    prayer_customization: {
                      ...formikValues.prayer_customization,
                      religion: event.target.value
                    }
                  })
                }
                displayEmpty
                inputProps={{
                  'aria-label': 'Without label',
                  'variant': "filled"

                }}
              >
                <MenuItem value="" disabled>
                  <IntlMessage id="public.request.right.form.religion.placeholder" />
                </MenuItem>
                {religions.map((religion) => (
                  <MenuItem key={religion.id} value={religion.id} >
                    {religion.label}
                    <CheckIcon />
                  </MenuItem>
                ))}
              </Select>
              <ClickAwayListener onClickAway={triggerClosePopoverDeno}>

                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  arrow
                  placement="bottom"
                  open={openNoteDeno}
                  onClose={() => triggerClosePopoverDeno()}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  className="deno-tooltip"
                  title={
                    isTablet
                      ?
                      <div className='tooltip-wrap'>
                        <div className="tooltip-message">
                          <div><IntlMessage id="public.request.right.form.denomination.tablet.tooltip1" /></div>
                          <div><IntlMessage id="public.request.right.form.denomination.tablet.tooltip2" /></div>
                        </div>
                      </div>
                      :
                      <>
                        <div className='tooltip-wrap d-none d-lg-block'>
                          <div className="tooltip-message">
                            <IntlMessage id="public.request.right.form.denomination.tooltip1" /><br />
                            <IntlMessage id="public.request.right.form.denomination.tooltip2" /><br />
                            <IntlMessage id="public.request.right.form.denomination.tooltip3" /><br />
                            <IntlMessage id="public.request.right.form.denomination.tooltip4" /><br />
                            <IntlMessage id="public.request.right.form.denomination.tooltip5" />
                          </div>
                        </div>
                        <div className='tooltip-wrap d-block d-lg-none'>
                          <div className="tooltip-message">
                            <IntlMessage id="public.request.right.form.denomination.tooltip1" /><br />
                            <IntlMessage id="public.request.right.form.denomination.tooltip2" /><br />
                            <IntlMessage id="public.request.right.form.denomination.tooltip3" /><br />
                            <IntlMessage id="public.request.right.form.denomination.tooltip4" /><br />
                            <IntlMessage id="public.request.right.form.denomination.tooltip5" />
                          </div>
                        </div>
                      </>
                  }
                >
                  <img
                    className="tool_tip_icon_down"
                    src={ToolTip}
                    onClick={() => {
                      if(isTablet || isMobile){
                        setOpenNoteDeno(!openNoteDeno);
                      }
                    }}
                    onMouseEnter={() => {
                      if(!isTablet && !isMobile){
                        setOpenNoteDeno(true);
                      }
                    }}
                    onMouseLeave={() => {
                      if(!isTablet && !isMobile){
                        setOpenNoteDeno(false);
                      }
                    }}
                  />
                  {/* <InfoOutlinedIcon onClick={() => triggerOpenPopoverDeno()}/> */}
                </Tooltip>
              </ClickAwayListener>
            </Col>
            <Col xs='12' className='picker-wrapper cover-religion message-tone-field'>
              <Select
                fullWidth={true}
                className='my-control2 religion_margin_top '
                IconComponent={ExpandMore}
                value={formikValues?.prayer_customization?.message_tone || ''}
                open={openMessageTone}
                onClose={handleCloseMessageTone}
                onOpen={handleOpenMessageTone}
                onClick={event => {
                  gtag('event', custom_events.CHANGE_INPUT, {
                    input_label: 'Prayer Partner Message Tone',
                    input_value: event.target.value,
                    input_type: 'select',
                  });
                  ReactPixel.track(fb_pixel_event.custom_events.CHANGE_INPUT, {
                    input_label: 'Prayer Partner Message Tone',
                    input_value: event.target.value,
                    input_type: 'select',
                  });

                }
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                  className: "new-design"

                }}
                onChange={event =>
                  setFormikValues({
                    ...formikValues,
                    prayer_customization: {
                      ...formikValues.prayer_customization,
                      message_tone: event.target.value
                    }
                  })
                }
                displayEmpty
                inputProps={{
                  'aria-label': 'Without label',
                  'variant': "filled"

                }}
              >
                <MenuItem value="" disabled>
                  <IntlMessage id="public.request.right.form.message_tone.placeholder" />
                </MenuItem>
                {messageTones.map((messageTone) => (
                  <MenuItem key={messageTone.id} value={messageTone.id} >
                    {messageTone.label}
                    <CheckIcon />
                  </MenuItem>
                ))}
              </Select>
              <ClickAwayListener onClickAway={triggerClosePopoverMessageTone}>

                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  arrow
                  placement="bottom"
                  open={openNoteMessageTone}
                  onClose={() => triggerClosePopoverMessageTone()}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  className="deno-tooltip"
                  title={
                    <div className='tooltip-wrap'>
                      <div className="tooltip-message">
                        <div><IntlMessage id="public.request.right.form.message_tone.tooltip" /></div>
                      </div>
                    </div>
                  }
                >
                  <img
                    className="tool_tip_icon_down"
                    src={ToolTip}
                    onClick={() => {
                      if(isTablet || isMobile){
                        setOpenNoteMessageTone(!openNoteMessageTone);
                      }
                    }}
                    onMouseEnter={() => {
                      if(!isTablet && !isMobile){
                        setOpenNoteMessageTone(true);
                      }
                    }}
                    onMouseLeave={() => {
                      if(!isTablet && !isMobile){
                      setOpenNoteMessageTone(false);
                      }
                    }}
                  />
                  {/* <InfoOutlinedIcon onClick={() => triggerOpenPopoverDeno()}/> */}
                </Tooltip>
              </ClickAwayListener>
            </Col>
          </Row>
          <Row className='form-group font-size-18px remove-hightlight-doubleClick prayerTypesWidth'>
            <Col xs='12'>
              <PrayerTypes formikValues={formikValues} setFormikValues={setFormikValues} />
            </Col>
          </Row>

          <Row className='form-group form-special-request'>
            <Col xs='12'>
              <FieldAdapter
                className={` ${focusSpecialRequest && "custom_focus_special_request"} my-control-right-part prayerTypes_margin remove-hightlight-doubleClick`}
                name="special_request"
                formikValues={formikValues}
                setFormikValues={setFormikValues}
                value={formikValues?.prayer_customization?.special_request}
                rows={5}
                multiline
                onBlur={(e) => {
                  setFocusSpecialRequest(false)
                }}
                onFocus={(e) => {
                  setFocusSpecialRequest(true)
                }}
                inputProps={{ className: 'data-hj-allow' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }


  return (
    <div className={`d-none d-sm-block ${needOption ? 'need-option' : ''}`}>
      {mainContent()}
    </div>
  )
}

export default RightPart;
