import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Table,
  Badge,
  Card,
  Container,
} from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import PrayerPartnerService from "@iso/services/PrayerPartnerService";
import {MDBBadge, MDBDataTable} from "mdbreact";
import "./styles.scss";
import moment from "moment";
import { DATE_FORMAT_PRAYER_REQUEST } from "@iso/components/../constants";
import AddModal from "./AddModal";
import { history } from "@iso/helpers/history";
import { filters } from "./constants";
import { translate } from "@iso/utils/Translate";
import { debounce } from "lodash";
import { InputGroup, FormControl } from "react-bootstrap";

const PrayerPartners = (props) => {
  const [prayerLists, setPrayerList] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("invitation");
  const [showModal, setShowModal] = useState(false);
  const [counts, setCounts] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const handleAddPrayer = () => {
    setShowModal(true);
  };

  const formatTableData = (data) => {
    let dataRow = [];
    let header = [
      {
        label: <IntlMessage id="admin.prayer_partners.header_table.id" />,
        field: "id",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.prayer_partners.header_table.first_name" />
        ),
        field: "first_name",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.prayer_partners.header_table.last_name" />
        ),
        field: "last_name",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.prayer_partners.header_table.email" />,
        field: "email",
        sort: "asc",
      },
      {
        label: (
          <IntlMessage id="admin.prayer_partners.header_table.denomination" />
        ),
        field: "denomination",
      },
    ];
    data.forEach((e, index) => {
      dataRow.push({
        id: e.id,
        first_name: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.first_name ? e.first_name.toLocaleLowerCase() : ''}
          >
            {
              e.first_name
            }
          </MDBBadge>
        ),
        last_name: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.last_name ? e.last_name.toLocaleLowerCase() : ''}
          >
            {
              e.last_name
            }
          </MDBBadge>
        ),
        email: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.email ? e.email.toLocaleLowerCase() : ''}
          >
            {
              e.email
            }
          </MDBBadge>
        ),
        denomination: e.religion_label,
        clickEvent: () => handleClick(e.id),
      });
    });
    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    };
  };

  const handleGetPrayerPartners = (status, options) => {
    PrayerPartnerService.adminGetPrayerPartners(status, options).then((res) => {
      if (res) {
        const { code, data, count } = res.data;

        if (res && code === 200) {
          const prayer_lists = formatTableData(data.data);
          setPrayerList(prayer_lists.data);
          setCounts(count);
          setCurrentStatus(status);

          if (prayer_lists.data.rows.length == 0) {
            const tbody = document.querySelector(
              "tbody[data-test=table-body] tr td"
            );
            if(tbody) tbody.innerText = `You have no ${status} partner.`;
          }
        }
      }
    });
  };

  const handleClick = (id) => {
    history.push(`/admin/prayer_partners/${id}/account_settings`);
  };

  useEffect(() => {
    handleGetPrayerPartners("invitation");
  }, []);

  const debounceSearch = React.useCallback(
    debounce((status, options) => {
      handleGetPrayerPartners(status, options);
    }, 500),
    []
  );

  React.useEffect(() => {
    debounceSearch(currentStatus, { search: searchValue });
  }, [searchValue]);

  return (
    <div className="mint-tulip-background p-3 min-vh-100 prayer_partners_list">
      <Row>
        <Col sm="6" className="text-md-left text-center mb-3">
          <h2>
            <IntlMessage id="admin.prayer_partners.title" />
          </h2>
        </Col>
        <Col sm="6" className="text-md-right text-center mb-3">
          <Button
            className="hippie-blue-button float-md-right"
            onClick={() => setShowModal(true)}
          >
            <IntlMessage id="admin.prayer_partners.btn_add_prayer.title" />
          </Button>
        </Col>
      </Row>

      <Card className="card_swapper">
        <Row className="mt-3 mb-3 pl-5 pr-5 row">
          {filters.map((filter) => {
            return (
              <Col xs="12" sm="6" md="4" className="mt-1 p-1 p-0">
                <Button
                  variant={`${
                    currentStatus === filter.name ? "active-status" : "light"
                  } btn-block`}
                  onClick={() =>
                    handleGetPrayerPartners(filter.name, {
                      search: searchValue,
                    })
                  }
                >
                  <IntlMessage id={filter.titleId} />
                  <Badge className={`ml-2 ${(filter.name === 'current' || filter.name === 'all') ? 'background-badge': ''}`} pill variant="danger">
                    {counts[filter.name] === 0 ? "" : counts[filter.name]} 
                  </Badge>
                </Button>
              </Col>
            );
          })}
        </Row>
        <Row className="ml-2 mr-2">
          <Col sm="12">
            <Row>
              <Col className="px-4 px-md-0 col-md-4 input_search_data_table" l>
                <InputGroup>
                  <FormControl
                    value={searchValue}
                    placeholder={translate(
                      "admin.prayer_partner.search.placeholder"
                    )}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>

            <MDBDataTable
              hover
              paging={true}
              id="prayer_lists_table"
              entries={25}
              searching={false}
              noBottomColumns={true}
              entriesOptions={[5, 20, 25]}
              data={prayerLists}
              sortRows={['first_name', 'last_name', 'email']}
              className="hide_scrollbar"
            />
          </Col>
        </Row>
      </Card>

      <AddModal showModal={showModal} closeModal={() => setShowModal(false)} />
    </div>
  );
};

export default PrayerPartners;
