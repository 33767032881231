import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { KeyboardArrowDown } from "@material-ui/icons";
import PrayerItem from "./PrayerItem";
import UserService from "@iso/services/UserService";
import "./scrollbar.scss";
import "./style.scss";
import { Row } from 'react-bootstrap'
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
  },
  header: {
    height: "121px",
    padding: "28px 50px;",
    borderBottom: "1px solid #D9E9F1",
    display: "flex",
    alignItems: "center",
  },
  content: {
    padding: "14px 35px 30px 35px",
    maxHeight: "700px",
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

const sortOptions = [
  {
    title: "Date",
  },
  {
    title: "Status",
  },
];

const MyPrayers = props => {
  const [prayers, setPrayers] = useState([]);
  const [detailsPrayer, setDetailsPrayer] = useState([]);
  const [loadingPrayers, setLoadingPrayers] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isDetail, setDetail] = useState(false);
  const [back, setBack] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const classes = useStyles();

  const fetchPrayersData = page => {
    setLoadingPrayers(true);
    UserService.getRequests(page)
      .then(res => {
        setPrayers([]);

        if (res.data.code == 200) {
          setCurrentPage(res.data.data.current_page);
          setTotalPage(res.data.data.last_page);
          setPrayers(res.data.data.data);
        }

        setLoadingPrayers(false);
      })
      .catch(err => {
        setPrayers([]);
        setLoadingPrayers(false);
      });
  };

  useEffect(() => {
    fetchPrayersData(currentPage);
  }, []);

  const handleChangePage = (e, page) => {
    setCurrentPage(page);
    fetchPrayersData(page);
  };

  // Set details Prayer Partner
  const fetchPrayerDetailsData = id => {
    setLoadingPrayers(true);
    UserService.getRequestsByID(id)
      .then(res => {
        if (res) {
          const { code, data } = res.data;
          if (code === 200) {
            setDetailsPrayer(data);
          }
          setLoadingPrayers(false);
        }
      })
      .catch(err => {
        setDetailsPrayer([]);
        setLoadingPrayers(false);
      });
  };
  const goBack = () => {
    setBack(true);
  };
  return (
    <Card className={classes.root + " " + "account_page__my_prayers"}>
      {isMinimized ? (
        <div>
          <div className="mobile mobile-display-none">
            <Button
              className={`${classes.expandButton} btn-back`}
              onClick={() => goBack()}
              color="primary"
            >
              <span className="text-hippie-blue">{"< "}{isDetail ? 'Back' : 'My Prayers' }</span>
            </Button>
            <CardHeader className="header-empty" />
          </div>
          <div className="header_pagination tablet display-none-with-tablet tablet-display-none display-none-with-mobile">
            <CardHeader className={`${classes.header} custom_header_my_prayers `} title={"My Prayers"} />
          </div>
          <div className="header_pagination desktop">
            <CardHeader className={`${classes.header} custom_header_my_prayers `} title={"My Prayers"} />
          </div>
        </div>
      ) : (
        <div>
          <div className="header_pagination desktop display-none-with-tablet">
            <CardHeader className={`${classes.header} custom_header_my_prayers `} title={"My Prayers"} />
            <Pagination
              count={totalPage}
              page={currentPage}
              onChange={handleChangePage}
              hideNextButton={currentPage === totalPage}
              hidePrevButton={currentPage === 1}
              shape="rounded"
              siblingCount={0}
            />
          </div>
          <div className="header_pagination tablet tablet-display-none">
            <Row className={`row pl-4`}>
              <Grid xs={5} className={`display-none-with-mobile`}>
                <CardHeader className={`${classes.header} custom_header_my_prayers `} title={"My Prayers"} />
              </Grid>
              <Grid xs={6} className={`mobile mobile-display-none`}>
                <CardHeader className={`${classes.header} custom_header_my_prayers `} title={"My Prayers"} />
              </Grid>
              <Grid xs={12} className={`mobile mobile-display-none mb-3`} style={{display: 'flex', alignItems: 'center', justifyContent: 'right', marginRight: "24px"}}>
                <Pagination
                  count={totalPage}
                  page={currentPage}
                  onChange={handleChangePage}
                  hideNextButton={currentPage === totalPage}
                  hidePrevButton={currentPage === 1}
                  shape="rounded"
                  siblingCount={0}
                />
              </Grid>
              <Grid xs={7} className="display-none-with-mobile">
                <div className={`mt-5`}>
                  <Pagination
                    count={totalPage}
                    page={currentPage}
                    onChange={handleChangePage}
                    hideNextButton={currentPage === totalPage}
                    hidePrevButton={currentPage === 1}
                    shape="rounded"
                    siblingCount={0}
                  />
                </div>
              </Grid>
            </Row>
          </div>
        </div>
      )}

      <CardContent className={`${classes.content}  custom-scrollbar`}>
        {prayers &&
          prayers.map(prayer => {
            return (
              <PrayerItem
                key={prayer.id}
                prayer={prayer}
                setPrayers={setPrayers}
                detailsPrayer={detailsPrayer}
                fetchPrayerDetailsData={fetchPrayerDetailsData}
                setIsMinimized={setIsMinimized}
                isMinimized={isMinimized}
                setDetail={setDetail}
                isDetail={isDetail}
                back={back}
                setBack={setBack}
              ></PrayerItem>
            );
          })}
        {prayers && prayers.length === 0 && !loadingPrayers && (
          <p className="text-center">No prayers yet</p>
        )}
        {loadingPrayers && (
          <div className="d-flex justify-content-center align-items-center">
            <CircularProgress color="primary"></CircularProgress>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default MyPrayers;
