import PrayerPartnerService from "@iso/services/PrayerPartnerService";
import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useParams } from "react-router-dom";
import "./styles.scss";
import ProfileCard from "@iso/components/PrayerPartner/Profile/ProfileCard";
import AvailabilityCard from "@iso/components/PrayerPartner/Availability/AvailabilityCard";
import EarningPercentage from "@iso/components/PrayerPartner/EarningPercentage/EarningPercentage";
import withAuthorization from "@iso/utils/withAuthorization";
import { USER_PERMISSIONS } from "@iso/constants";
import { userRoleConstants } from "@iso/constants";
import LoginAs from "@iso/components/Common/LoginAs";
import { Link, useLocation } from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import ManageNotification from "../../../../PrayerPartner/Pages/AccountSettings/components/ManageNotification";
import AdminsService from "../../../../services/AdminsService";


const AccountSettings = (props) => {
  const { id } = useParams();
  const [profile, setProfile] = React.useState();

  useEffect(() => {
    if (id) {
      PrayerPartnerService.getPrayerPartnerById({id: id}).then((res) => {
        if (res && res.data) {
          const { code, data } = res.data;
          if (res && code === 200) {
            setProfile(data);
          }
        }
      });
    }
  }, [])

  const AvailabilityWithAuth = withAuthorization(USER_PERMISSIONS.SUPER_USER)(
    AvailabilityCard
  );
  const ProfileCardWithAuth = withAuthorization(USER_PERMISSIONS.SUPER_USER)(
    ProfileCard
  );
  const EarningPercentageWithAuth = withAuthorization(
    USER_PERMISSIONS.SUPER_USER
  )(EarningPercentage);
  
  const user = useSelector((state) => state.authentication.user);

  let location = useLocation();
  let previous_location = location?.state?.previous_location;
  let isFromFinancialPage = previous_location && previous_location.match(/financial/i);

  return (
    <>
      <div
        className="admin_account_settings mint-tulip-background p-3"
        id="admin_account_settings"
      >
        <Row>
          <Col xs="12" md="6" lg="4">
            <h2 className="mb-3">
              <IntlMessage id="prayer_partners.account_settings.title" />
              <Link
                to={isFromFinancialPage ? '/admin/financial?filter=prayer_partners' : '/admin/prayer_partners'} 
                className="text-hippie-blue header_back_link d-block d-md-none"
              >
                {"< Back"}
              </Link>
            </h2>
          </Col>
          
          <Col xs="12" md="6" lg={{ span: 4, offset: 4 }}>
            <Row>
              {/*Role super admin */}
              {
                user?.role === userRoleConstants.SUPER_ADMIN_ROLE && 
                <>
                  <Col xs="12" md="4" className="text-center mb-3 pt-2 d-none d-md-block">
                    <Link to={isFromFinancialPage ? '/admin/financial?filter=prayer_partners' : '/admin/prayer_partners'} className="text-hippie-blue">
                      {"< Back"}
                    </Link>
                  </Col>

                  <Col xs="12" md="8" >
                    <LoginAs
                      className="mb-3 w-100"
                      id={id}
                      role={userRoleConstants.PRAYER_ROLE}
                      allowBan={!profile?.deleted_at}
                      allowRemove={!profile?.deleted_at}
                    />
                  </Col>
                </>
              }
               
               {/*Role admin */}
              {
                user?.role !== userRoleConstants.SUPER_ADMIN_ROLE && 
                <Col xs="12" className="text-right mb-3 pt-2 d-none d-md-block" >
                  <Link to={isFromFinancialPage ? '/admin/financial?filter=prayer_partners' : '/admin/prayer_partners'} className="text-hippie-blue">
                    {"< Back"}
                  </Link>
                </Col>
              }
              
            </Row>
          </Col>
        </Row>

        <Card className="p-3">
          {id && (
            <ProfileCardWithAuth
              profileId={id}
              role={user?.role}
              idForUpdate={id}
              handleUpdateProfile={
                PrayerPartnerService.adminSavePrayerPartnerProfile
              }
              handleUpdateNoteAdmin={
                PrayerPartnerService.adminSaveNoteAdmin
              }
            />
          )}
          {id && <AvailabilityWithAuth profileId={id}  role={user?.role} handleUpdateProfile={
                PrayerPartnerService.adminSavePrayerPartnerProfile
              }/>}
          {id && (
            <ManageNotification
              openNotification={true}
              profile={null}
            />
          )}
          {id && <EarningPercentageWithAuth profileId={id} role={user?.role}/>}
        </Card>
      </div>
    </>
  );
};

export default AccountSettings;
