import React from "react";

import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { Formik, Field } from "formik";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import IntlMessage from "@iso/components/intlMessage";
import moment from "moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";

const PrintMonthlyModal = ({
  open,
  handlePrint,
  handleShow,
  handleClose,
  content,
  title,
  okText,
  cancelText,
}) => {
  const currentYear = moment().year();
  const listYear = () => {
    let years = [];
    for (let i = 2010; i < currentYear + 2 ; i++) {
      years.push(i);
    }
    
    return years;
  }
  return (
    <Modal show={open} onHide={handleClose} className="print_model_modal">
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="h4 w-100 text-center">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ month: 1, year: currentYear }}
          onSubmit={(values) => handlePrint(values)}
        >
          {({ values, handleSubmit, handleChange, touched, errors }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Form.Group as={Row} controlId="month">
                <Col sm={{ span: "8", offset: "2" }}>
                  <Form.Group controlId="month">
                    <Field name="month" as="select" className="form-control">
                      {moment.months().map((month, index) => {
                        return (
                          <option value={index + 1} key={month}>
                            {month}
                          </option>
                        );
                      })}
                    </Field>
                  </Form.Group>
                </Col>
              </Form.Group>
              
              <Form.Group as={Row} controlId="month">
                <Col sm={{ span: "8", offset: "2" }}>
                  <Form.Group controlId="month">
                    <Field name="year" as="select" className="form-control">
                      {listYear().map((year, index) => {
                        return (
                          <option value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </Field>
                  </Form.Group>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-1">
                <Col className="text-right mt-1">
                  <Button
                    className="hippie-blue-button btn text-white"
                    onClick={() => 
                      handleShow(values)
                    }
                  >
                    <VisibilityIcon className="mr-1" />
                    <IntlMessage id="common.view" />
                  </Button>
                </Col>
                <Col className="text-left mt-1">
                  <Button className="hippie-blue-button" type="submit">
                    <GetAppIcon />
                    <IntlMessage id="common.export" />
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default PrintMonthlyModal;
