import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQAccordionStyle from "./AccordionStyle";
import './faq_common.scss';
import ButtonMailTo from "./buttonMailTo";
import { useDispatch, useSelector } from "react-redux";
import registerActions from "@iso/actions/confirmRequestModal";
import actionsSignIn from "@iso/actions/signinmodal";
import { history } from '@iso/helpers';

const GiftFAQ = (props) => {
  const { handleChange, expanded } = props;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication)


  const gotoSignup = () => {
    dispatch(
      registerActions.openModal({
        type: registerActions.SHOW_REGISTER_MODAL,
      })
    );
  }

  const handleMyPrayer = () => {
    if(!user) {
      dispatch(
        actionsSignIn.openModal({
          type: actionsSignIn.SHOW_MODAL,
        })
      );
    }else {
      history.push("/account?tab=3")
    }
  }

  const classes = FAQAccordionStyle();
  return (
    <>
      <Accordion
        className={`${classes.accordion} ${classes.expanded} section-gift`}
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          className={classes.headingContainer}
          expandIcon={
            <ExpandMoreIcon style={{ fill: "#AFDAF5" }} fontSize="large" />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Gifts</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            How does a gift work?
          </Typography>
          <Typography className={`${classes.bodyText} faq-content`}>
            {`When you make a request, you can
            select to give a prayer as a gift. After
            you’ve shared the reason for your
            prayer and selected your
            customizations, you have the option
            to personalize your gift message.`}
          </Typography>

          <Typography className={`${classes.bodyText} mt-3 faq-content`}>
            {`The recipient will initially receive a
            gift notification containing your
            personalized message and an
            explanation of their gift.`}
          </Typography>

          <Typography className={`${classes.bodyText} mt-3 faq-content`}>
           {`After your Prayer Partner completes
           the prayer, you and the gift recipient
           will each receive a confirmation
           email, containing a personal message
           from the Prayer Partner to the gift
           recipient.`}
          </Typography>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            Why give a prayer?
          </Typography>
          <Typography className={`${classes.bodyText} `}>
            The gift of a prayer is a unique gesture for any occasion and a
            beautiful way to show that you care. Whether to celebrate a special
            event or to give comfort to a friend, one prayer can make all the
            difference.
          </Typography>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            Can I see if my gift recipient received their prayer?
          </Typography>
          <Typography className={`${classes.bodyText} `}>
            Yes, you can track your gift and see if it has been opened. In order to do this, you will need to <span className="text-hippie-blue hover_pointer" onClick={gotoSignup}>sign up</span> for an account before completing your request. 
            <br/><br/>
            Once you are signed in, click on your name in the top corner (or alternatively on the corner menu on a mobile device) and go to <span className="text-hippie-blue hover_pointer" onClick={handleMyPrayer}>My Prayers</span>. Here you can view all of your requests and in the prayer details, you can see the status of each prayer and whether it is in-progress, sent, or viewed.
          </Typography>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            Why did my gift recipient not receive a gift notification?
          </Typography>
          <Typography className={`${classes.bodyText} `}>
            Please ask your gift recipient to check their spam folder. 
            A gift notification email that includes your gift message is sent out immediately after we receive your request. 
            After your prayer is made, we then send your gift recipient a second email that includes a personal message from their Prayer Partner.
            <br></br><br></br>
            If your recipient finds any emails from PrayerCloud in their spam folder, please ask them to mark them as ‘not spam’ and to add&nbsp;
            <ButtonMailTo
              label="prayers@prayercloud.com"
              mailTo="mailto:prayers@prayercloud.com" 
            />
            &nbsp;to their contacts to ensure they receive all future correspondence.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default GiftFAQ;
