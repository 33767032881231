import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  header: {
    height: "121px",
    padding: '50px 20px !important',
    borderBottom: "1px solid #D9E9F1",
    display: "flex",
    alignItems: "center",
    textAlign: 'center',
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
}));

export default useStyles;
