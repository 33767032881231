import React from "react";
import {
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import IntlMessage from "@iso/components/intlMessage";
import RightSide from "./components/RightSide";
import "./style.scss";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TAB_MENU } from "@iso/Website/Pages/Account/constants";
import { useLocation } from "react-router";
import * as qs from "query-string";
import { history } from "@iso/helpers";
import LeftSide from "./components/LeftSide";
import { logoutUser } from "@iso/actions/User";
import { useSelector, shallowEqual } from "react-redux";

const ActiveTabIndicator = styled.div`
  position: absolute;
  ${(props) => (props.value < 7 ? "top: 240px;" : "")}
  ${(props) => (props.value < TAB_MENU.logout ? "top: 180px;" : "")}
  ${(props) => (props.value < TAB_MENU.change_password ? "top: 120px;" : "")}
  ${(props) => (props.value < TAB_MENU.my_prayers ? "top: 60px;" : "")}
  ${(props) => (props.value < TAB_MENU.billing_history ? "top: 0px;" : "")}
  height: 60px;
  left: 0px;
  background: rgb(82, 185, 212);
  box-shadow: rgb(236, 244, 248) 0px 1px 0px;
  width: 12px;
  transition: all 0.2s ease-in-out;
`;

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tabs: {
    fontFamily: "BrandonText",
    // borderRight: `1px solid ${theme.palette.divider}`,
  },
  accordionRoot: {
    "&::before": {
      background: "none",
    },
    margin: "0px !important",
    border: "none",
    outline: "none",
    boxShadow: "none",
  },
  accordionTitle: {
    fontFamily: "BrandonText",
    fontSize: "18px",
    paddingLeft: "74px",
    color: "rgba(0, 0, 0, 1)",
  },
  accordionSummary: {
    margin: "0px",
    height: "60px",
    borderBottom: "1px solid #D9E9F1",
  },
  accordionDetails: {
    flexDirection: "column",
    padding: "0px !important",
  },
  menuActive: {
    fontWeight: "bold",
    color: "#52B9D4 !important",
    boxShadow: "0px 1px 0px #ECF4F8",
  },
  subMenuActive: {
    fontWeight: "bold",
    background: "#ECF4F8",
    boxShadow: "0px 1px 0px #ECF4F8",
  },
  subMenu: {
    fontSize: "16px !important",
    color: "#000000",
    height: "38px !important",
    "& span": {
      paddingLeft: "95px !important",
    },
  },
}));

const AccountPage = (props) => {
  const location = useLocation();
  const { tab } = qs.parse(location.search);

  const classes = useStyles();
  const [value, setValue] = React.useState(TAB_MENU.profile);
  const [myPrayer, setMyPrayer] = React.useState(false);
  const user = useSelector((state) => state.authentication.user);

  if (!user) {
    history.push("/");
  }

  const handleChange = (newValue) => {
    switch (newValue) {
      case TAB_MENU.logout:
        history.push("/");
        logoutUser();
        break;
      default:
        history.push(`/account?tab=${newValue}`);
        break;
    }
  };

  React.useEffect(() => {
    setValue(parseInt(tab || 0));
    setMyPrayer(tab === "3");
  }, [tab]);

  return (
    <div className={"account_page__wrapper" + (myPrayer ? ' my-prayer' : '')}>
      <h1 className="account_page__title">Your Account</h1>
      <Grid container spacing={0} className="account_page__tabs_wrapper">
        <Grid
          style={{ borderRight: "1px solid #D9E9F1" }}
          item
          xs={12}
          sm={4}
          className="d-none d-md-block pr-md-0"
        >
          <LeftSide value={value} handleChange={handleChange} user={user}></LeftSide>
        </Grid>

        <Grid item xs={12} sm={8} className="pl-lg-0 user_grid_container_right">
          <RightSide value={value} />
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountPage;
