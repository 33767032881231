import React from "react"
import IntlMessage from "@iso/components/intlMessage"
import { store } from "@iso/store/root"
import alertActions from "@iso/actions/Alert"
import { history } from "@iso/helpers"
const run = ({ roles, hasNoti = true }) => {
  const state = store.getState();
  let { user } = state?.authentication
  if(sessionStorage.getItem("user")) {
    user = JSON.parse(sessionStorage.getItem("user"))
  }

  if (user && roles.indexOf(user.role) === -1) {
    history.push("/")
    if (window.location.pathname === '/') {
      return
    }
    if(!hasNoti){
      store.dispatch(
        alertActions.openAlert({
          message: <IntlMessage id="common.message.unauthorize" />,
          severity: "error",
        })
      )
    }
    return
  }
}

export default {
  run,
}
