import React from "react"
import { useDispatch } from "react-redux"
import PrivateHeader from "@iso/components/Layout/PrivateHeader"
import { history } from "@iso/helpers/history"
import { logoutUser } from "@iso/actions/User"
import { withRouter } from "react-router"

import { MENU_SIDEBAR } from "./constants"

const Header = () => {
  const dispatch = useDispatch()
  const handleLogout = () => {
    history.push("/prayer_partners/sign_in")
    dispatch(logoutUser())
  }

  return (
    <PrivateHeader
      handleLogout={handleLogout}
      login_path="/prayer_partners/sign_in"
      menuSidebar={MENU_SIDEBAR}
    />
  )
}

export default withRouter(Header)
