import alertActions from "@iso/actions/Alert";

const initialState = {
  alertShow: false,
  data: {},
};

export function alert(state = initialState, action) {
  switch (action.type) {
    case alertActions.SHOW_ALERT:
      return {
        alertShow: true,
        data: action.data,
      };
    case alertActions.HIDE_ALERT:
      return initialState;
    default:
      return state;
  }
}
