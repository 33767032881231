import React, { Suspense } from "react"
import Loader from "react-loaders"
import { useParams } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import IntlMessage from "@iso/components/intlMessage"
import RequestDetailsPanel from "./RequestDetailsPanel"

const RequestDetails = () => {
  const { id } = useParams()
  return (
    <div className="mint-tulip-background p-3 admin_request_wrapper">
      <Row className="my-2">
        <Col xs="12" sm="6" className="request_title">
          <h2>
            <IntlMessage id="admin.requests.title" />
          </h2>
        </Col>
      </Row>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
                <h1>
                  <IntlMessage id="admin.requests.details.loading" />
                </h1>
              </div>
            </div>
          </div>
        }
      >
        <RequestDetailsPanel prayerRequestId={id} />
      </Suspense>
    </div>
  )
}

export default RequestDetails
