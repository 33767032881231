import React, { Fragment } from "react"
import Sidebar from "./Share/Sidebar"

const PrivateLayout = (props) => {
  const Component = props.component
  const Header = props.headerComponent

  const { menuSidebar } = props

  return (
    <Fragment>
      <div className="app-main app_container container fluid-container p-0">
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Header menuSidebar={menuSidebar} />
            <div className="row m-0 wrapper">
              <Sidebar title={props.title} menuSidebar={menuSidebar} />
              <div className="col-lg-10 col-md-9 p-0 mint-tulip-background pb-3">
                <Component />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default PrivateLayout
