import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import AddFormForgotPassword from './AddFormForgotPassword'
import IntlMessage from "@iso/components/intlMessage"
import './style.scss'
import {useDispatch} from "react-redux";
import actionsSignIn from '@iso/actions/signinmodal'

function ModalFormForgotpassword(props){
  const dispatch = useDispatch();
  const { handleSwitchRegister } = props;
  const [valueForgetPassword, setValueForgetPassword] = React.useState(false);
  const [valueEmail, setValueEmail] = React.useState("");
  const backLogin = function() {
    props.sendToSignIn();
    dispatch(actionsSignIn.openModal({
      type: actionsSignIn.SHOW_MODAL,
      isForgotPassword : false,
    }));
  }
  return(
    <div className  = "forgotPasswordContainer">
      <Modal.Header id = "modalForgotPasswordHeader">
        <Modal.Title className ="modalForgotpasswordTitle">
          <p className = "back_link"
            onClick = {backLogin}>
            &lt; <IntlMessage id="forgot_password.check.email.back" />
          </p>
          <h2 className = "font-weight-bold mb-3 custom_forgot_password_title">
            {valueForgetPassword ?(
              <IntlMessage id="forgot_password.form.submit.email.not.found" />):(
              <IntlMessage id="forgot_password.form.submit" />
            )}
          </h2>
          <p className = "fontStyle">
            {valueForgetPassword ? (
              <>
              <IntlMessage id="forgot password.form.notes.email.not.found" />
              <span className="custom_value_email_not_found">{valueEmail}.</span>
              <p><IntlMessage id="forgot password.form.notes.email.not.found.try.again" /></p>
              </>
            ):(
              <IntlMessage id="forgot_password.form.notes" />
            )
            }
          </p>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <AddFormForgotPassword
        setValueForgetPassword = {setValueForgetPassword}
        setValueEmail = {setValueEmail}
        setShowSignIn={props.setShowSignIn}
       />
      {valueForgetPassword && (
        <div className = "text-center mt-3 font-weight-bold font-size-18px">
          <IntlMessage id="public.request.form.account_title" />
          <span className ="color-hippie-blue ml-1" onClick={() => handleSwitchRegister()}>
          <IntlMessage id="public.request.form.signup_btn_title" /></span>
        </div>
      )}
      </Modal.Body>
    </div>
  )
}

export default ModalFormForgotpassword
