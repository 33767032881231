import React, {useState} from "react";
import { CompleteRequestFormValidator } from "./validator";
import { Modal } from "react-bootstrap";
import { Form, Row, Col, Button, FormText } from "react-bootstrap";
import { Formik } from "formik";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import TimePicker from "rc-time-picker";
import IntlMessage from "@iso/components/intlMessage";
import withAuthorization from "@iso/utils/withAuthorization";
import { USER_PERMISSIONS, userRoleConstants } from "@iso/constants";
import {useDispatch, useSelector} from "react-redux";
import CustomButton from "@iso/components/AtomicComponents/Button";
import TextField from "@material-ui/core/TextField";
import { MINUTE_STEP, MINUTE_FORMAT, DATE_FORMAT, SALUTION } from "./constants";
import "./CompletedPrayerRequestModal.scss";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ModalRequestInfo from "./ModalRequestInfo";
import RequestService from "../../services/RequestService";

const CompletedPrayerRequestModal = ({
  open,
  isInitForm,
  setIsInitForm,
  handleSubmit,
  handleClose,
  request = {},
  minDate = null,
  sendingRequest = false,
}) => {
  let time = request?.time_complete
    ? `2020-10-10 ${request?.time_complete}`
    : "2020-10-10 10:00";
  let date = request?.date_complete
    ? new Date(request.date_complete)
    : new Date();
  const [chainRequest, setChainRequest] = React.useState(false);
  const [isOpenModalRequestDetail, setIsOpenModalRequestDetail] = React.useState(false)
  const { user } = useSelector((state) => state.authentication);

  const getNamePrayer = () => {
    if(request.recipient == "for_myself" && request.client_id == null){
      return request.recipient_name;
    }
    if (request.is_gift && request.client_id) {
      return request.recipient_name;
    }
    if (!request.is_gift && request.client_id) {
      return request.client_name;
    } 
    if(request.client_id == null && request.recipient == "for_someone" && !request.is_gift){
      return request.recipient_name;
    }
    if(request.client_id == null && request.recipient == "for_someone" && request.is_gift){
      return request.recipient_name;
    }
  }

  const capitalizeFirstLetter = (string) => {
    return string.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
  }

	const initForm = {
		date: date,
		time: time,
		place: request?.place_complete || "",
		message: request?.message_complete_translate || (request?.message_complete || ""),
		salution: request?.salution || "god_bless",
    user_name: capitalizeFirstLetter(getNamePrayer()) || ""
	}
	
	React.useEffect(() => {
		setFormikValue(initForm);
    getChainRequest()
	}, [request]);

  const getChainRequest = () => {
    let apiGetChain = null;
    if (user.role === userRoleConstants.PRAYER_ROLE) {
      apiGetChain = RequestService.getPartnerChainRequest(request.id)
    }
    else {
      apiGetChain = RequestService.getAdminChainRequest(request.id)
    }

    if (apiGetChain) {
      apiGetChain.then((res => {
        const {code, message} = res.data;
        if (code === 200) {
          setChainRequest(res.data.data);
        }
      }))
    }
  }

  React.useEffect(() => {
    if(isInitForm){
      setFormikValue(initForm);
      setIsInitForm(false);
    }
  },[isInitForm])
  const [formikValue, setFormikValue] = React.useState(initForm);
  const handleCloseModal = () => {
    handleClose();
    setFormikValue(initForm);
  };

  const handleValueChange = (name, value) => {
    if (name === "user_name") {
      if (request.client_id == null) {
        formikValue['recipient_name'] = value;
      }
      if (request.client_id) {
        if (request.is_gift) {
          formikValue['recipient_name'] = value;
        } else {
          formikValue['client_name'] = value;
        }
      }
    } else {
      formikValue[name] = value;
    }
    setFormikValue(formikValue);
  };

  const handleValueBeforeSubmit = (values) => {
    if(values.time === '') values.time = getDefaultTime()
    handleSubmit({
      ...formikValue,
      id: request.id,
      date: moment(formikValue.date).format(DATE_FORMAT),
      time: values.time.length > 5 ? moment(values.time).format(MINUTE_FORMAT) : values.time,
      salution: values.salution,
    });
  };

  const ModalTitlePrayerPartner = (props) => {
    return (
      <Modal.Title>
        <IntlMessage id="prayer_request.completion_modal.prayer_partner.title" />
      </Modal.Title>
    );
  };

  const ModalTitleForAdmin = (props) => {
    return (
      <Modal.Title>
        <IntlMessage id="prayer_request.completion_modal.admin.title" />
      </Modal.Title>
    );
  };

  const ButtonSubmitPrayerPartner = (props) => {
    return (
      <CustomButton
        className="hippie-blue-button"
        type="submit"
        variant="primary"
        disabled={sendingRequest}
        isSubmitting={sendingRequest}
      >
        <IntlMessage id="common.submit" />
      </CustomButton>
    );
  };

  const ButtonSubmitForAdmin = (props) => {
    return (
      <CustomButton
        className="hippie-blue-button"
        type="submit"
        variant="primary"
        disabled={sendingRequest}
        isSubmitting={sendingRequest}
      >
        <IntlMessage id="common.save" />
      </CustomButton>
    );
  };

  const ModalTitlePrayerPartnerWithAuth = withAuthorization(
    USER_PERMISSIONS.PARTNER
  )(ModalTitlePrayerPartner);

  const ModalTitleForAdminWithAuth = withAuthorization(
    USER_PERMISSIONS.SUPER_USER
  )(ModalTitleForAdmin);

  const ButtonSubmitPrayerPartnerWithAuth = withAuthorization(
    USER_PERMISSIONS.PARTNER
  )(ButtonSubmitPrayerPartner);

  const ButtonSubmitForAdminWithAuth = withAuthorization(
    USER_PERMISSIONS.SUPER_USER
  )(ButtonSubmitForAdmin);

  const PrayerNotice = () => {
    return (
      <i className="custom_notice">
        <IntlMessage id="prayer_request.completion_modal.notice.place" />
      </i>
    );
  };
  
  let placePlaceholder = "Example: St.Joseph's Church, Krakow\n\t\t or At the relic of St.Nicolaus";
  let placeHolderMessage = "Be sure to make it personal/ special. Let them know: \n\n what you prayed for \n what you hope your prayer will bring \n any special details about the prayer\n\n(min 350 max 800 characters)";
  
  const getDefaultTime = () => {
    let defaultTime = '';
    if(formikValue.time === ''){
      defaultTime = "10:00"
    }else{
      defaultTime = formikValue.time.length > 5 ? moment(formikValue.time).format(MINUTE_FORMAT) : formikValue.time
    }
    return defaultTime;
  }

  const handleClickRequestDetail = () =>{
    setIsOpenModalRequestDetail(true)
  }

  return (
    <div>
      <Modal
        show={open}
        onHide={handleCloseModal}
        className="complete_request_modal_wrapper"
      >
        <Modal.Header closeButton>
          <ModalTitleForAdminWithAuth />
          <ModalTitlePrayerPartnerWithAuth />
        </Modal.Header>
        <Modal.Body>
          {user ? (user.role === 'prayer-partner' ? (
              <div className="buttons mt-2 mb-3 position-relative">
                <div id="ButtonShowRequestInfo">
                  <Button
                    className="hippie-blue-button"
                    type="button"
                    onClick={()=> handleClickRequestDetail()}
                  >
                    <IntlMessage id="prayer_partners.payments.view" />
                  </Button>
                  <ModalRequestInfo 
                    request={request} 
                    chainRequest={chainRequest} 
                    open={isOpenModalRequestDetail}
                    setOpen={setIsOpenModalRequestDetail}
                  />
                </div>
              </div>
          ) : '') : ''}
          <Formik
            initialValues={formikValue}
            onSubmit={handleValueBeforeSubmit}
            enableReinitialize={true}
            validationSchema={CompleteRequestFormValidator}
          >
            {({
              handleSubmit,
              handleChange,
              touched,
              errors,
              values,
              setFieldValue,
            }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Form.Group as={Row}>
                    <Col sm={{ span: "8"}}>
                      <Form.Label>
                        <IntlMessage id="prayer_request.completion_modal.label.date" />
                      </Form.Label>
                      <Form.Control
                        name="date"
                        onSelect={(value) => {
                          setFieldValue("date", value);
                          handleValueChange("date", value);
                        }}
                        as={DatePicker}
                        minDate={minDate}
                        selected={formikValue.date}
                      />
                      <ErrorMessageWrapper
                        touched={touched}
                        errors={errors}
                        fieldName="date"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={{ span: "8"  }}>
                      <div className="pb-1">
                        <IntlMessage id="prayer_request.completion_modal.label.time" />
                      </div>
                       <TextField
                        id="time"
                        name="time"
                        type="time"
                        showSecond={false}
                        minuteStep={MINUTE_STEP}
                        defaultValue={getDefaultTime()}
                        onChange={(e) => {
                          setFieldValue("time", e.target.value)
                          handleValueChange("time", e.target.value)
                        }}
                        
                        className=" timepicker_wrapper"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                      <ErrorMessageWrapper
                        touched={touched}
                        errors={errors}
                        fieldName="time"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="place">
                    <Col sm={{ span: "8"  }}>
                      <Form.Label>
                        <IntlMessage id="prayer_request.completion_modal.label.place" />
                        <div><PrayerNotice /></div>
                      </Form.Label>
                      <TextField
                        fullWidth={true}
                        variant="filled"
                        multiline={true}
                        className="my-control"
                        rows={2}
                        name="place"
                        value={values.place}
                        onChange={(e) => {
                          setFieldValue("place", e.target.value);
                          handleValueChange("place", e.target.value);
                        }}
                        placeholder={placePlaceholder}
                      />
                      <ErrorMessageWrapper
                        touched={touched}
                        errors={errors}
                        fieldName="place"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="loginEmail">
                    <Col sm={{ span: "10"}}>
                      <Form.Label>
                        <IntlMessage id="prayer_request.completion_modal.new.label.message"/>
                      </Form.Label>
                      <br/>
                      <Form.Label>
                        {USER_PERMISSIONS.SUPER_USER.roles.includes(user.role) ? (
                          <TextField
                            id="user_name"
                            fullWidth={true}
                            variant="filled"
                            className="my-control user_name_completed_request_admin"
                            rows={2}
                            name="user_name"
                            value={values.user_name}
                            onChange={(e) => {
                              setFieldValue("user_name", e.target.value);
                              handleValueChange("user_name", e.target.value);
                            }}
                            InputProps={{
                              startAdornment: <div className="text-dear pr-2"><div style={{height: "24px", lineHeight: "24px"}}>Dear </div></div>
                            }}
                            multiline
                          />
                        ) : (
                          <IntlMessage
                            id="prayer_request.completion_modal.new.label.dear"
                            values={{ user_name: capitalizeFirstLetter(getNamePrayer()) }}
                          />
                        )}
                      </Form.Label>

                      <Form.Control
                        as="textarea"
                        rows="7"
                        name="message"
                        type="textarea"
                        placeholder={placeHolderMessage}
                        onChange={(e) => {
                          setFieldValue("message", e.target.value);
                          handleValueChange("message", e.target.value);
                        }}
                        value={values.message}
                        className="completed-msg"
                      />

                      <Form.Group as={Row}>
                        <Col sm="5">
                          <Select
                              labelId="religion-label"
                              id="salution"
                              name="salution"
                              value={formikValue.salution}
                              onChange={(e) => {
                                setFieldValue("salution", e.target.value);
                                handleValueChange("salution", e.target.value);
                                setFormikValue({...formikValue, salution:e.target.value});
                              }}
                              className="w-100 mt-2 custom_select_religion remove-border-bottom-muiInput"
                          >
                            {SALUTION.map((item) => {
                              return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.label}
                                  </MenuItem>
                              );
                            })}
                          </Select>
                        </Col>
                        <Col sm="7" className="mt-2">
                          <Form.Label>
                            <IntlMessage
                                id={USER_PERMISSIONS.SUPER_USER.roles.includes(user.role) ? 'prayer_request.completion_modal.new.label.sa_god_bless' : 'prayer_request.completion_modal.new.label.pp_god_bless'}
                                values={{ pp_name: USER_PERMISSIONS.SUPER_USER.roles.includes(user.role) ? request.prayer_partner.first_name : user.first_name }}
                            />
                          </Form.Label>
                        </Col>
                      </Form.Group>

                      <ErrorMessageWrapper
                        touched={touched}
                        errors={errors}
                        fieldName="message"
                      />
                    </Col>
                  </Form.Group>
                  <div className="buttons text-left mb-2">
                    <ButtonSubmitPrayerPartnerWithAuth />
                    <ButtonSubmitForAdminWithAuth />
                  </div>
                </form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompletedPrayerRequestModal;
