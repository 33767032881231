import * as Yup from "yup"
const NAME_MIN_LENGTH = 2
const NAME_MAX_LENGTH = 20
const ProfileInfoValidator = Yup.object().shape({
  first_name: Yup.string()
    .label("First name")
    .required()
    .typeError("This is a required field")
    .test(
      "minLength",
      "Please input 2 to 20 characters!",
      (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
        "maxLength",
        "Please input 2 to 20 characters!",
        (val) => val && val.length <= NAME_MAX_LENGTH
    )
    .matches(/^[A-Za-z ]+$/, 'The field should have letters only'),
  last_name: Yup.string()
    .label("Last name")
    .required()
    .typeError("This is a required field")
    .test(
      "minLength",
      "Please input 2 to 20 characters!",
      (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
        "maxLength",
        "Please input 2 to 20 characters!",
        (val) => val && val.length <= NAME_MAX_LENGTH
    )
    .matches(/^[A-Za-z ]+$/, 'The field should have letters only'),
  email: Yup.string()
    .label("Email")
    .email("Please enter a correct email!")
    .required()
    .typeError("This is a required field"),
  // birth: Yup.string()
  //   .label("Date of birth")
  //   .required()
  //   .typeError("This is a required field"),
    // Disabled due to bug Edit_profile_not_required
  // country: Yup.string().required().typeError("This is a required field"),
  // religion: Yup.array()
  //   .label("Religion")
  //   .required()
  //   .typeError("This is a required field"),
  phone_number: Yup.string()
     .label("Phone number")
     .nullable()
     .matches(/^[0-9+ ]+$/, "Only numbers, +, and spaces are allowed")
})

export { ProfileInfoValidator }
