import React from "react";
import {
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import IntlMessage from "@iso/components/intlMessage";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TAB_MENU } from "@iso/Website/Pages/Account/constants";
import { useHistory } from "react-router";
import { useLocation } from "react-router";
import './MenuForGuest.scss'
const ActiveTabIndicator = styled.div`
  position: absolute;
  ${(props) => (props.value < 7 ? "top: 240px;" : "")}
  ${(props) => (props.value < TAB_MENU.logout ? "top: 180px;" : "")}
  ${(props) => (props.value < TAB_MENU.change_password ? "top: 120px;" : "")}
  ${(props) => (props.value < TAB_MENU.my_prayers ? "top: 60px;" : "")}
  ${(props) => (props.value < TAB_MENU.billing_history ? "top: 0px;" : "")}
  ${(props) => ( !isNaN(props.value) && props.location !== '/' && (props.location.includes("about") ) ? "background: rgb(82, 185, 212);" : "")}
  height: 60px;
  left: 0px;
  box-shadow: rgb(236, 244, 248) 0px 1px 0px;
  width: 12px;
  transition: all 0.2s ease-in-out;
`;

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tabs: {
    fontFamily: "BrandonText", 
    // borderRight: `1px solid ${theme.palette.divider}`,
  },
  accordionRoot: {
    "&::before": {
      background: "none",
    },
    margin: "0px !important",
    border: "none",
    outline: "none",
    boxShadow: "none",
  },
  accordionTitle: {
    fontFamily: "BrandonText",
    fontSize: "18px",
    paddingLeft: "74px",
    color: "rgba(0, 0, 0, 1)",
  },
  accordionSummary: {
    margin: "0px",
    height: "60px",
    borderBottom: "1px solid #D9E9F1",
  },
  accordionDetails: {
    flexDirection: "column",
    padding: "0px !important",
  },
  menuActive: { 
    color: "#000000 !important",
    boxShadow: "0px 1px 0px #ECF4F8",
    background: "#ECF4F8!important",  
  },
  subMenuActive: {
    fontWeight: "bold",
    background: "#ECF4F8",
    boxShadow: "0px 1px 0px #ECF4F8",
  },
  subMenu: {
    fontSize: "16px !important",
    color: "#000000",
    height: "38px !important",
    "& span": {
      paddingLeft: "95px !important",
    },
  },
}));

const MenuForGuest = (props) => {
  const { value, handleChange, isMobileMenu = false, menu } = props;
  const classes = useStyles();
  const history = useHistory()
  const accordionActiveStyle = {
    color: "#52B9D4",
    fontWeight: "bold",
  };
  const location = useLocation();

  const checkActiveTab = (name) => {
    const pathname = location.pathname;
    const findPath = pathname.search(name);

    if (findPath > -1) {
      return true;
    }

    return false;
  };

  return (
    <div className="menuForGuest-container">
      <Tabs
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        orientation="vertical"
        variant="scrollable"
        value={value}
        // onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {menu &&
          menu.map((tab) => {
            if (!tab.subMenu) {
              return (
                <Tab
                  className={checkActiveTab(tab.name) ? classes.menuActive : ""}
                  onClick={() => {handleChange(tab.id); history.push(tab.href)}}
                  label={tab.title}
                  {...a11yProps(tab.id)}
                />
              );
            }

            if (tab.subMenu) {
              const isAccordionActive = () => {
                const isActive = tab.subMenu.forEach((sub) => {
                  if (value === sub.id) {
                    return true;
                  }
                  return false;
                });
                return isActive;
              };

              //   const isSubtabActive = () => {};
              return (
                <Accordion
                  expanded={isAccordionActive()}
                  className={classes.accordionRoot}
                  {...tab.subMenu.forEach((sub) => a11yProps(sub.id))}
                  onClick={() => history.push(tab.href)}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    aria-controls="settings-menu"
                    id="account__settings-menu"
                    onClick={() => handleChange(tab.subMenu[0].id)}
                  >
                    <Typography
                      className={classes.accordionTitle}
                      style={isAccordionActive() ? accordionActiveStyle : {}}
                    >
                      {tab.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {tab.subMenu.map((sub) => {
                      return (
                        <Tab
                          className={`${classes.subMenu}
                                ${
                                  value === sub.id ? classes.subMenuActive : ""
                                }`}
                          onClick={() => handleChange(sub.id)}
                          label={<IntlMessage id={sub.title} />}
                        />
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            }
          })}
        <Tab
          className={value === -1 ? classes.menuActive : ""}
          onClick={() => handleChange(-1)}
          label={"Sign up"}
          {...a11yProps(-1)}
        />
        <Tab
          className={value === -2 ? classes.menuActive : ""}
          onClick={() => handleChange(-2)}
          label={"Log in"}
          {...a11yProps(-2)}
        />
        <ActiveTabIndicator value={value} location={location.pathname}/>
      </Tabs>
    </div>
  );
};

export default MenuForGuest;
