import React, { Fragment, Component } from "react";

import CheckUserRoles from "@iso/utils/CheckUserRoles"
import { USER_PERMISSIONS } from "@iso/constants"
import MobileHeader from "@iso/components/Layout/Share/MobileHeader"
import PublicHeader from "./PublicHeader"
import { isMobile } from "react-device-detect"
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import AuthService from "@iso/services/AuthService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { setCurrentUser } from "@iso/actions/User";
import * as qs from "query-string";

const PrivateLayout = (props) => {
  CheckUserRoles.run({ roles: [...USER_PERMISSIONS.USER.roles, ...USER_PERMISSIONS.PARTNER.roles] })
  const Component = props.component

  const dispatch = useDispatch();
  const location = useLocation();

  const { loginAsId: id, role } = qs.parse(location.search);
  const [loginAsId, setLoginAsId] = React.useState(id);

  React.useEffect(() => {
    if(loginAsId) {
    AuthService.loginAs({ role, id: loginAsId }).then((res) => {
      ProcessRequest.run({
        res,
        successCallback: (data) => {
          dispatch(
            setCurrentUser(
              { ...data.data },
              { redirectAfterLogin: false, remoteLogin: true }
            )
          );
        },
        errorCallback: () => {},
      });
    });
  }
  }, [id]);

  React.useEffect(() => {
    if (!loginAsId && !id) {
      CheckUserRoles.run({
        roles: [...USER_PERMISSIONS.USER.roles, ...USER_PERMISSIONS.PARTNER.roles],
        hasNoti: false,
      });
    }
  }, [loginAsId]);


  return (
    <Fragment>
      <div className="app-main app_container container p-0">
        <div className="app-main__outer">
          <div className="app-main__inner">
            {!isMobile && <PublicHeader></PublicHeader>}
            {isMobile && <MobileHeader />}
            <div className="row m-0 wrapper top-view-tablet">
              <div className="col p-0">
                <Component key={Date.now()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PrivateLayout;
