import React from "react";
import { Carousel } from "react-bootstrap";
import HowItWorksItem from "./HowItWorksItem";
import "./HowItWorks.scss";

const HowItWorksCarousel = (props) => {
  const { items } = props;
  return (
    <Carousel
      className="howItWorks__carousel"
      interval={null}
      prevIcon={
        <span
          aria-hidden="true"
          className="carousel-control-prev-icon  d-none"
        />
      }
      nextIcon={
        <span
          aria-hidden="true"
          className="carousel-control-prev-icon d-none"
        />
      }
    >
      {items.map((item, idx) => {
        return (
          <Carousel.Item key={idx}>
            <HowItWorksItem
              icon={item.icon}
              title={item.title}
              description={item.description}
              style={item.style}
            ></HowItWorksItem>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default HowItWorksCarousel;
