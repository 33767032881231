import React from "react";
import { Row, Col, Fade } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import "./styles.scss";
import { useSelector } from "react-redux";
import { languages } from "@iso/Pages/Common/Languages";
import { SALUTION } from '../Request/constants'
import {USER_PERMISSIONS} from "../../constants";

const TranslateReason = (props) => {
  const {
    title,
    translatedContent,
    originContent,
    lang,
    showOriginContent,
    showTransactionCallback,
    name,
    isEdited = false,
    defaultShow = false,
    request,
  } = props;

  const showSpaceOriginContent = (string) => {
    if (title.props === undefined) {
      return string;
    }
    if (title.props.id === 'admin.requests.details.message' && string != null) {
      return (
          <div>
            {string.split("\n").map((i,key) => {
              return <div><span key={key}>{i}</span><br/></div>;
            })}
          </div>);
    } else {
      return string;
    }
  }

  const { user } = useSelector((state) => state.authentication);

  const userLang = user.language || "en";
  const displayContent = translatedContent || originContent;

  let open = showOriginContent;
  if (showOriginContent == undefined) {
    open = defaultShow;
  }
  const [i18nText, setI18nText] = React.useState("");

  React.useEffect(() => {
    let i18Id = open
      ? "translate.hide_original_lang"
      : "translate.display_original_lang";
    setI18nText(i18Id);
  }, [open]);

  const openTranslationCallback = () => {
    showTransactionCallback({ name, open: !open });
  };

  const getNamePrayer = () => {
    if (request.is_gift && !request.client_id) {
      if (request.recipient == "for_someone") {
        return request.recipient_name + ',';
      }
      else {
        return request.recipient_name + ',';
      }
    }
    if(!request.is_gift && !request.client_id && request.recipient == "for_someone"){
      console.log("request", request.is_gift)
      return request.recipient_name;
    }
    if (!request.is_gift && request.client_id) {
      return request.client_name + ',';
    }
    if(!request.client_id && request.recipient == "for_myself"){
      return request.recipient_name;
    } 
    if(request.is_gift && request.recipient == "for_someone" && request.client_id){
        return request.recipient_name
    }
  }

  const getSalution = (key) => {
    for (var i = 0; i < SALUTION.length; i++) {
      if (SALUTION[i].id === key) {
        return SALUTION[i].label;
      }
    }
  }
  return (
    <Row className="mt-3 translation_wrapper">
      <Col  sm="4" lg="3" className="bullet">
        <span className="font-weight-bold">{title}</span>
      </Col>
      <Col  sm="8" lg="9">
        {title?.props?.id === 'admin.requests.details.message' && (
            <>
              <div>
                <IntlMessage
                    id="prayer_request.completion_modal.new.label.dear"
                    values={{ user_name: getNamePrayer() }}
                />
              </div><br />
            </>
        )}
        <p className="request_item_value_css display_content">
          {showSpaceOriginContent(displayContent)}
          {isEdited ? <span className="text-edited">(Edited)</span> : ""}
        </p>
        {title?.props?.id === 'admin.requests.details.message' && (
            <>
              <div>
                <span>{request ? (request.salution ? getSalution(request.salution) : 'God bless') : 'God bless'}, </span>
                <IntlMessage
                    id={USER_PERMISSIONS.SUPER_USER.roles.includes(user.role) ? 'prayer_request.completion_modal.new.label.sa_god_bless' : 'prayer_request.completion_modal.new.label.pp_god_bless'}
                    values={{ pp_name: USER_PERMISSIONS.SUPER_USER.roles.includes(user.role) ? request?.prayer_partner?.first_name : user?.first_name }}
                />
              </div>
            </>
        )}

        {languages.find(item => item.code === userLang)?.label !== lang && translatedContent && (
          <>
            <p
              className="oracle-color hover_pointer request_item_value_css"
              aria-controls="collapse-text"
              aria-expanded={open}
              onClick={openTranslationCallback}
            >
              <IntlMessage id={i18nText} values={{ lang }} />
            </p>

            <Fade in={open} className={open ? "d-block" : "d-none"}>
              <p className="origin_reason request_item_value_css ml-2 p-4 mt-3">
                {showSpaceOriginContent(originContent)}
              </p>
            </Fade>
          </>
        )}
      </Col>
    </Row>
  );
};

export default TranslateReason;
