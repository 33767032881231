import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./styles.scss";
import { Formik, Field, Form } from "formik";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";
import IntlMessage from "@iso/components/intlMessage";
import { FormValidator } from "./validator";
import { history } from "@iso/helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import requestStepActions from "@iso/actions/RequestStepActions";
import stripeActions from "@iso/actions/Stripe";
import RequestSignIn from "./components/RequestSignIn";
import { Link } from "react-router-dom";
import { isTablet, isMobile } from "react-device-detect";
import otherImg from "@iso/assets/images/other_img.svg";
import OrthodoxMobile from "@iso/assets/images/step2_leading_orthodox_mobile.svg";
import AnglicanMobile from "@iso/assets/images/step2_leading_anglican_mobile.svg";
import CatholicMobile from "@iso/assets/images/step2_leading_catholic_mobile.svg";
import JewishMobile from "@iso/assets/images/step2_leading_jewish_mobile.svg";
import LogoJewishTablet from "@iso/assets/images/step2_logo_jewish_tablet.png";

import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const {
  setSaveCard,
} = stripeActions;

const Index = (props) => {
  const user = useSelector((state) => state.authentication.user);
  const dispatch = useDispatch();
  const requestStepData = useSelector(
    (state) => state.requestStepReducer,
    shallowEqual
  );

  const [formikValues, setFormikValues] = React.useState(
    requestStepData || {
      frequency: null,
      price: {},
    }
  );

  React.useEffect(() => {
    setFormikValues(requestStepData);
  }, [requestStepData]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  if (formikValues.step === 0) {
    history.push("/requests/step1");
  }

  const handleSetValue = (values) => {
    setFormikValues(values);
    let total;
    if (values?.prayer_contribution?.price && values?.prayer_frequency.value_number) {
      total =
        values?.prayer_contribution?.price * values?.prayer_frequency.value_number;
    }

    dispatch(
      requestStepActions.setDataStep({ ...values, total_contribution: total })
    );
  };

  const handleSubmitForm = (values, errors) => {
    gtag('event', custom_events.CLICK_BUTTON, {
      button_label : 'Next step',
      button_value : 2,
    });

    dispatch(
      requestStepActions.setDataStep({
        ...formikValues,
        step: 2,
      })
    );
    dispatch(setSaveCard(false))
    history.push("/requests/step3");
  };
  
  const NextButton = () => {
    return (
      <Button
        className={`form-control hippie-blue-button next-button ${!isTablet && 'ml-lg-5'}`}
        type="submit"
      >
        <b className ="title_next_btn">
          <IntlMessage id="common.next" />
        </b>
      </Button>
    );
  };

  let denomination = requestStepData.prayer_customization?.religion;

  let imageMobile;
  switch (denomination) {
    case "eastern-orthodox":
      imageMobile = OrthodoxMobile;
      break;
    case "catholic":
      imageMobile = CatholicMobile;
      break;
    case "anglican":
      imageMobile = AnglicanMobile;
      break;
    case "jewish":
      imageMobile = JewishMobile;
      break;
    default:
      imageMobile = otherImg;
      break;
  }

  return (
    <div className={`container-full-width bg-blue-sky-half-reverse-2`}>
      <div className="container-fixed">
        <div className={`home-index-step-2 need-option`}>
          <Formik
            initialValues={formikValues}
            enableReinitialize={true}
            onSubmit={handleSubmitForm}
            validationSchema={FormValidator}
          >
            {({values, handleSubmit, handleChange, touched, errors }) => (
              <Form>
                <Row className="m-0 margin_left_step_2_side">
                  <Col xs="12" md="6" id="leftside_step2"  >
                    <LeftPart
                      formikValues={requestStepData}
                      handleChange={handleChange}
                      handleSetValue={handleSetValue}
                      touched={touched}
                      errors={errors}
                      denomination={requestStepData.prayer_customization?.religion}
                    />
                  </Col>
                  {
                    isMobile && !isTablet ? (
                        <Col
                          id="rightside_step2_mobile"
                          xs="12"
                          md="6">
                              <img className="img_other" src={imageMobile} />
                          {
                            requestStepData.prayer_customization?.religion === 'jewish' && (
                              <img className="logo_jewish" src={LogoJewishTablet} />
                            )
                          }
                        </Col>
                    ):
                    (<Col
                      xs="12"
                      md="6"
                      id="rightside_step2"
                      className={`blue-part need-option p-0 setHeight_${requestStepData.prayer_customization?.religion}`}
                    >
                      <RightPart
                        formikValues={formikValues}
                        handleChange={handleChange}
                        setFormikValues={setFormikValues}
                        touched={touched}
                        errors={errors}
                        denomination={requestStepData.prayer_customization?.religion}
                      />
                    </Col>)
                  }

                </Row>
                { isMobile && !isTablet && (
                  <>
                  <Row className="form-group m-float-group d-block d-md-none">
                    <Col xs="12">
                      <NextButton />
                    </Col>
                    <div className="d-block d-md-none mt-2 mx-auto">
                    { !user && <RequestSignIn />}
                    </div>
                  </Row>

                  <div className="d-none d-md-block mt-5 step2-tablet-btn-next">
                    {
                      !isTablet &&
                      <span>
                        <Link to="/requests/step1" className="text-hippie-blue">
                          {"< Back"}
                        </Link>
                      </span>
                    }
                    <NextButton />
                    <div className={isTablet ? '' : "ml-4"}>
                    { !user && <RequestSignIn />}

                    </div>
                  </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Index;
