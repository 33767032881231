import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
  PRICE_OF_DENOMINATION,
  CONTRIBUTION_FREQUENCY,
} from "@iso/Website/Pages/Request/step2/constants";
import "./PriceTable.scss";
import IntlMessage from "@iso/components/intlMessage";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import ContributionService from "@iso/services/ContributionService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { isTablet, isMobile } from "react-device-detect";

import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const PriceTable = ({
  formikValues,
  handleSetValue,
  denomination,
  handleSelect,
  touched,
  errors,
}) => {
  const contributionTabletOrders = {
    // anglican: {
    //   prayer: 1,
    //   'prayer-candle-incense': 2,
    //   'prayer-candle': 3,
    //   'anglican-rosary': 4
    // },
    // 'eastern-orthodox': {
    //   prayer: 1,
    //   'prayer-candle-incense': 2,
    //   'prayer-candle': 3,
    //   'prayer-rope': 4
    // }
  }
  const customClass = {
    jewish: {
      tablet: {
        'memorial-candle': 'mt--30px'
      },
      mobile: {
        'memorial-candle': 'mt--25px'
      }
    }
  }
  const isDesktop = !isMobile && !isTablet
  const deviceName = isDesktop ? 'desktop' : (isTablet ? 'tablet' : 'mobile')
  
  const [priceData, setPriceData] = React.useState();
  const [frequencyData, setFrequencyData] = React.useState();

  const getAllContribution = ({ religion }) => {
    ContributionService.getContributions({ religion }).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: false,
        i18nSuccess: "common.fetch.success",
        successCallback: () => {
          setPriceData(res.data.data);
        },
      });
    });
  };


  const renderPrice = (priceData) => {
    return (
      priceData && priceData.map((item, index) => {
        const selectedClass =
          formikValues?.prayer_contribution?.id === item.id
            ? "selected"
            : "";
        const slugClass = item.slug === 'prayer-candle' && denomination === 'jewish' ? 'prayer-candle-jewish' : item.slug;
        const customClassName = customClass[denomination] && customClass[denomination][deviceName] && customClass[denomination][deviceName][item.slug]
        return (
          <Col
            xs="6"
            sm="6"
            lg={isTablet ? 6 : 3}
            key={item.id}
            className={` custom_table_margin_price text-center hover_pointer mw-100 p-0 m-0 margin-price-${priceData.length} ${customClassName}`}
            onClick={() => {
              handleSetValue({
                ...formikValues,
                prayer_contribution: item,
                contribution_id: item.id,
              });
              gtag('event', custom_events.CLICK_BUTTON, {
                button_label: 'Select Prayer Contribution',
                button_value: item.name,
              });
              ReactPixel.track(fb_pixel_event.custom_events.CLICK_BUTTON, {
                button_label: 'Select Prayer Contribution',
                button_value: item.name,
              });
            }}
          >
            <div
              className={`select_fake_wrapper mx-auto my-2 prayer-option prayer-partner_${denomination ? denomination: 'non-denomination'} prayer-candle-${((item.slug === "prayer") || (index === 0)) ? "" : (index + 1)} ${selectedClass} ${slugClass}`}
            >
              <div className="prefix-option"></div>
              <h5 className="pt-3 font-size-16px">
                {item.price}
                &nbsp;
                <IntlMessage id="common.price.unit.dollar" />
              </h5>
              <p className="description px-1">
                <IntlMessage id={item.name} />
              </p>
              <div className="suffix-option"></div>
            </div>

            <Form.Check
              custom
              type="radio"
              name="prayer_contribution"
              label={<IntlMessage id={item.name} />}
              id={item.name}
              key={item.name}
              className="custom-control my-2 d-none"
              defaultChecked={selectedClass === 'selected'}
            />
          </Col>
        );
      })
    )
  }

  const getAllFrequency = ({ religion }) => {
    ContributionService.getAllFrequencies({ religion }).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: false,
        i18nSuccess: "common.fetch.success",
        successCallback: () => {
          setFrequencyData(res.data.data)
        },
      });
    });
  };


  React.useEffect(() => {
    getAllContribution({ religion: denomination });
    getAllFrequency({ religion: denomination });
  }, [denomination]);

  // Set default contribution
  React.useEffect(() => {
    if (priceData) {
      if ((isTablet || isMobile) && contributionTabletOrders[denomination]) {
        let order = contributionTabletOrders[denomination]
        priceData.sort(function (item1, item2) {
          if (!order[item1['slug']] || !order[item2['slug']]) {
            return 0;
          }
          if (order[item1['slug']] < order[item2['slug']]) {
            return -1
          }
          if (order[item1['slug']] > order[item2['slug']]) {
            return 1
          }
          return 0;
        })
      }
      priceData.forEach(price => {
        console.log("formikValues::::", formikValues)
        if (price.slug === 'prayer-candle') {
          if (!formikValues?.prayer_contribution?.id) {
            if (formikValues.prayer_contribution.price !== "18-32") {
              handleSetValue({
                ...formikValues,
                prayer_contribution: price,
                contribution_id: price.id,
              })
            } else {
              if (formikValues.prayer_customization.religion) {
                console.log("formikValues.prayer_customization.religion::::", formikValues.prayer_customization.religion)
                switch (formikValues.prayer_customization.religion) {
                  case "catholic":
                    console.log("asdasdas")
                    handleSetValue({
                      ...formikValues,
                      prayer_contribution: {
                        id: 1,
                        name: "The Holy Rosary",
                        price: 28,
                        slug: "rosary"
                      },
                      contribution_id: 1,
                    })
                    break;
                  case "eastern-orthodox":
                    handleSetValue({
                      ...formikValues,
                      prayer_contribution: {
                        id: 2,
                        name: "The Prayer Rope",
                        price: 28,
                        slug: "prayer-rope"
                      },
                      contribution_id: 2,
                    })
                    break;
                  case "jewish":
                    handleSetValue({
                      ...formikValues,
                      prayer_contribution: {
                        id: 7,
                        name: "Memorial with candle, 23rd psalm, and Keriah",
                        price: 18,
                        slug: "memorial-candle"
                      },
                      contribution_id: 7,
                    })
                    break;
                  case "anglican":
                    handleSetValue({
                      ...formikValues,
                      prayer_contribution: {
                        id: 3,
                        name: "The Anglican Rosary",
                        price: 28,
                        slug: "anglican-rosary"
                      },
                      contribution_id: 3,
                    })
                    break;
                  default:
                    handleSetValue({
                      ...formikValues,
                      prayer_contribution: price,
                      contribution_id: price.id,
                    })
                    break;
                }
              } else {
                handleSetValue({
                  ...formikValues,
                  prayer_contribution: price,
                  contribution_id: price.id,
                })
              }
            }
          } else if (formikValues.prayer_customization.religion === "jewish") {
            handleSetValue({
              ...formikValues,
              prayer_contribution: price,
              contribution_id: price.id,
            })
          }
        }
      });
    }
  }, [priceData])

  return (
    <>
      {priceData && (
        <>
          <Row className="pricing_table_wrapper">
            {renderPrice(priceData)}
          </Row>
          <ErrorMessageWrapper
            touched={{ prayer_contribution: { id: "true" } }}
            errors={errors}
            fieldName="prayer_contribution.id"
            className="margin_contribution custom_error_contribution"
          />
        </>
      )}

      {frequencyData && (
        <Row className="container frequency_wrapper mt-3">
          <h2 className="my-sub-title">
            <IntlMessage id="public.request.step2.frequency.title" />
          </h2>

          {frequencyData.map((item) => {
            return (
              <Col xs="12" key={item.id}>
                <Form.Check
                  custom
                  type="radio"
                  name="frequency"
                  value={item.code}
                  className="custom-control my-2 font-size-18px custom_frequency_data"
                  label={
                    <span className="for-focus">
                      {
                        (isTablet && item.code === 'one_time' && denomination === "anglican")
                          ?
                          <IntlMessage id="public.request.step2.frequency.catholic.one_time" />
                          :
                          <IntlMessage id={item.name} />
                      }
                    </span>
                  }
                  id={item.code}
                  checked={formikValues?.prayer_frequency?.code === item.code}
                  onChange={(event) => {
                    handleSetValue({
                      ...formikValues,
                      prayer_frequency: item,
                      frequency: item.code,
                    });
                  }}
                  onClick={(event) => {
                    gtag('event', custom_events.CHANGE_INPUT, {
                      input_type: 'radio',
                      input_value: event.target.value,
                      input_label: 'Select Prayer Frequency'
                    });
                    ReactPixel.track(fb_pixel_event.custom_events.CHANGE_INPUT, {
                      input_type: 'radio',
                      input_value: event.target.value,
                      input_label: 'Select Prayer Frequency'
                    });
                  }}
                />
              </Col>
            );
          })}

          <ErrorMessageWrapper
            touched={{ prayer_frequency: { code: "true" } }}
            errors={errors}
            fieldName="prayer_frequency.code"
            className="custom_error_frequency"
          />
        </Row>
      )}
      <p className="total_contribution mt-4">
        <IntlMessage id="public.request.step2.total_contribution.title" />
        <span className="step2-total-price">
          {formikValues?.total_contribution && (
            <IntlMessage id="common.price.unit.dollar" />
          )}
          {formikValues?.total_contribution}</span>
      </p>
    </>
  );
};

export default PriceTable;
