import { Button, Checkbox, Grid, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import PrayerDad from "@iso/assets/images/landing_prayers_dad.svg";
import ArrowDownward from "@iso/assets/images/account_prayers_arrow_down.svg";
import ArrowLeft from '@iso/assets/images/account_prayers_arrow_left.svg';
import Location from '@iso/assets/images/account_prayers_location.svg';
import { makeStyles } from "@material-ui/core/styles";
import { ArrowForwardIos } from "@material-ui/icons";
import IntlMessage from "@iso/components/intlMessage";
import DefaultAvatar from "@iso/assets/images/account_profile_avatar_default.svg";
import moment from "moment";
import { PRAYER_TYPES_AND_ICONS } from "@iso/components/Request/constants";
import PrayerDefault from "@iso/assets/images/prayer_type_default.svg";
import UserService from "@iso/services/UserService";
import { isMobile, isTablet } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { SALUTION } from '../../../../../../components/Request/constants'

const useStyles = makeStyles(theme => ({
	imageContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	image: {
		width: "60px",
		height: "60px",
		"@media (max-width: 400px)": {
			width: "50px",
			height: "50px",
		},
		borderLeft: "1px solid #AFDAF5",
		borderBottom: "1px solid #AFDAF5",
		borderBottomLeftRadius: "5px",
		padding: "12px",
	},
	expandButton: {
		textTransform: "none",
		fontFamily: "BrandonText",
		fontSize: "16px",
		border: "none",
		outline: "none !important",
		marginRight: "28px",
	},
	detailsContainer: {
		position: "relative",
		padding: "20px 26px",
	},
	title: {
		fontSize: "18px",
		fontFamily: "BrandonText",
		fontWeight: "bold",
	},
	detailIncomplete: {
		minHeight: '350px',
	},
	detailTitle: {
		fontSize: "14px",
		fontFamily: "BrandonText",
		color: "#6D7278",
		marginTop: "25px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "10px",
		},
	},
	subDetailTitle: {
		fontSize: "14px",
		fontFamily: "BrandonText",
		color: "#6D7278",
		marginLeft: "80px",
	},
	viewDetailsTitle: {
		fontSize: "16px",
		fontFamily: "BrandonText",
		color: "#499E9E",
		marginLeft: "50px",
		cursor: "pointer",
	},
	checkBox: {
		borderRadius: "6px",
		height: "20px",
		width: "20px",
		color: "#499e9e !important",
	},
	detail: {
		fontFamily: "BrandonText",
		marginTop: "5px",
		fontSize: "14px",
		color: "#1C1634",
	},
	bold: {
		fontWeight: "bold",
	},
	completed: { color: theme?.status?.completed },
	inProgress: { color: theme?.status?.inProgress },
	partTitle: {
		fontFamily: "BrandonText",
		fontSize: "18px",
		marginTop: "40px",
		marginBottom: "10px",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			marginTop: "20px",
			marginBottom: "0",
		},
	},
	capitalized: {
		textTransform: "capitalize",
	},
	smallAvatar: {
		display: "flex",
		"& > img": {
			width: "54px",
			height: "54px",
			objectFit: "contain",
			marginRight: "21px",
			borderRadius: "50%",
		},
		"& > div": {},
	},
	marginLeftItem: {
		marginLeft: "30px",
	},

	completedColor: {
		color: "#52B9D4",
	},

	inProgressColor: {
		color: "#F26C51",
	},
	breakWord: {
		width: "235px",
		wordWrap: "break-word",
	},
	breakWordMobile: {
		width: "190px",
		wordWrap: "break-word",
	},
	breakWordMsg: {
		width: "auto",
		wordWrap: "break-word",
		[theme.breakpoints.down("sm")]: {
			width: "auto",
		},
	},
}));

const getStatusTextColor = (status, style) => {
	if (!style) {
		return;
	}

	let color = style.completedColor;

	if (status.toLowerCase() == "in-progress") {
		color = style.inProgressColor;
	}
	return color;
};
const getPrayerName = prayer => {
	// const time_zone = -(new Date().getTimezoneOffset() / 60);
	if (!prayer) {
		return;
	}

	let prayerName = "Prayer Number ";

	// if (prayer.type) {
	//   prayerName = prayer.type.name + " ";
	// }

	// const prayerDate = moment.utc(prayer.created_at).utcOffset(time_zone).format("MMMM D YYYY");
	prayerName = prayerName + prayer?.id ?? 'N\A';
	return prayerName;
};

const getPrayerTypeAndIcon = (type, slug) => {
	let defaultPrayer = {
		type: "default",
		title: "No special prayer",
		icon: PrayerDefault,
	};
	let prayer = defaultPrayer;

	if (!type) {
		return prayer;
	}

	// Find prayer from constants
	const findPrayer = PRAYER_TYPES_AND_ICONS.find(item => item.type == slug);
	if (!findPrayer) {
		prayer = defaultPrayer;
		return prayer;
	}
	// Set prayer if found
	prayer = findPrayer;
	return prayer;
};
const PrayerItemExpandedDetail = props => {
	const { prayer, setDetail, generalInfoPrayer, transactionId } = props;
	const classes = useStyles();
	const time_zone = -(new Date().getTimezoneOffset() / 60);
	const { handleToggleExpand } = props;
	const { user } = useSelector((state) => state.authentication);
	const getSalution = (key) => {
		for (var i = 0; i < SALUTION.length; i++) {
			if (SALUTION[i].id === key) {
				return SALUTION[i].label;
			}
		}
	}
	const formatContent = (string) => {
		return (
			<div>
				{string.split("\n").map((i,key) => {
					return <div><span key={key}>{i}</span><br/></div>;
				})}
			</div>);
	}

	const statusText =
		prayer.status === "overdue_done_approved" || prayer.status === "approved"
			? "Completed"
			: "In-progress";
	const statusColor = getStatusTextColor(statusText, classes);
	const prayerTypeAndIcon = getPrayerTypeAndIcon(
		prayer.type,
		prayer.type?.slug
	);
	const prayerName = getPrayerName(prayer);
	const generalRender = () => {
		return (
			<>
				<Grid item xs={6}>
					<Typography className={classes.detailTitle}>
						<IntlMessage id="user.account.request.for_whom" />
					</Typography>
					<Typography className={`${classes.detail}`}>
						{prayer.is_gift ? (
							<IntlMessage id="public.request.form.for_someone_else"></IntlMessage>
						) : (
							<IntlMessage id="public.request.form.for_myself" />
						)}
					</Typography>
				</Grid>

				<Grid item xs={6}>
					<Typography className={`${classes.detailTitle} ml-5`}>
						{prayer.is_gift > 0 ? (
							<IntlMessage id="user.account.request.their_name"></IntlMessage>
						) : (
							<IntlMessage id="user.account.request.your_name"></IntlMessage>
						)}
					</Typography>
					<Typography className={`${classes.detail} ml-5`}>
						{prayer.recipient_name}
					</Typography>
				</Grid>

				{prayer.is_gift > 0 && (
					<Grid
						item
						xs={6}
						className="align-items-end justify-content-start flex-row d-flex"
					>
						<Checkbox
							color="primary"
							checked={prayer.is_gift > 0 ? true : false}
							disabled
							className={classes.checkBox}
						></Checkbox>
						<Typography className={`${classes.detail} ml-2`}>
							<IntlMessage id="user.account.request.gift_notification"></IntlMessage>
						</Typography>
					</Grid>
				)}

				{prayer.is_gift > 0 && (
					<Grid item xs={6}>
						<Typography className={`${classes.detailTitle} ml-5`}>
							<IntlMessage id="user.account.request.gift_recipient_email"></IntlMessage>
						</Typography>
						<Typography className={`${classes.detail} ml-5`}>
							{prayer.recipient_email}
						</Typography>
					</Grid>
				)}

				{prayer.reason && (
					<Grid item xs={12}>
						<Typography className={classes.detailTitle}>
							<IntlMessage id="user.account.request.reason" />
						</Typography>
						<Typography className={`${classes.detail}`}>
							{prayer.reason}
						</Typography>
					</Grid>
				)}

				{prayer.is_gift > 0 && (
					<>
						<div className={classes.partTitle + " part-title"}>
							<IntlMessage id="public.request.step3.left.gift_message.title" />
						</div>
						<Grid item xs={12}>
							<Typography className={classes.detailTitle}>
								<IntlMessage id="user.account.request.extra_request" />
							</Typography>
							<Typography className={`${classes.detail}`}>
								{prayer.gift_message}
							</Typography>
						</Grid>
					</>
				)}

				<div className={classes.partTitle + " part-title"}>
					<IntlMessage id="user.account.request.customization" />
				</div>

				<Grid item xs={6}>
					<Typography className={classes.detailTitle}>
						<IntlMessage id="user.account.request.prayer_date" />{" "}
					</Typography>
					<Typography className={`${classes.detail}`}>
						{moment.utc(prayer.end_date).utcOffset(time_zone).format("MMM DD, YYYY")}
					</Typography>
				</Grid>

				<Grid item xs={6}>
					<Typography className={`${classes.detailTitle} ml-5`}>
						<IntlMessage id="user.account.request.denomination" />{" "}
					</Typography>
					<Typography className={`${classes.detail} ml-5`}>
						{prayer.denomination_label}
					</Typography>
				</Grid>

				{prayer.type && (
					<Grid item xs={12}>
						<Typography className={classes.detailTitle}>
							<IntlMessage id="user.account.request.mosted_request" />{" "}
						</Typography>
						<Typography className={`${classes.detail}`}>
							{prayer.type.name}
						</Typography>
					</Grid>
				)}

				{prayer.special_request && (
					<>
						<Grid item xs={12}>
							<Typography className={classes.detailTitle}>
								<IntlMessage id="user.account.request.special_request" />{" "}
							</Typography>
							<Typography className={`${classes.detail}`}>
								{prayer.special_request}
							</Typography>
						</Grid>
					</>
				)}
			</>
		);
	};
	return (
		<Grid container spacing={0}>
			<Grid item xs={12} className={`${classes.imageContainer} desktop`}>
				<Button
					className={classes.expandButton}
					onClick={() => setDetail(false)}
					color="primary"
				>
					<img src={ArrowLeft} className="ml-3 mr-2"></img>
					<span className="d-none d-md-inline-block text-hippie-blue">
            <IntlMessage id="user.account.request.back"></IntlMessage>
          </span>{" "}
				</Button>
				<img src={prayerTypeAndIcon.icon} className={classes.image}></img>
			</Grid>

			<Grid item xs={12} className={`${classes.imageContainer} table tablet-display-none display-none-with-mobile`}>
				<Button
					className={`${classes.expandButton}`}
					onClick={() => setDetail(false)}
					color="primary"
				>
					<img src={ArrowLeft} className="ml-2 mr-3"></img>
					<span className="d-none d-md-inline-block text-hippie-blue">
            <IntlMessage id="user.account.request.back"></IntlMessage>
          </span>{" "}
				</Button>
				<img src={prayerTypeAndIcon.icon} className={` ${classes.image} custom-image`}></img>
			</Grid>

			<Grid item xs={12} md={12} className={ ` ${classes.detailsContainer} ml-2`}>
				<Grid container>
					{/*Mobile*/}
					{statusText === "Completed" ?
						(
							<Grid item xs={12} md={12}>
								<div className="mobile mobile-display-none icon-request-type-right-mobile ">
									<img className={classes.image} src={prayerTypeAndIcon.icon} style={isMobile && !isTablet ? {'position':'relative','right':'-7px'} : {}}></img>
								</div>
								<Typography className={`${classes.title} title-request mobile mobile-display-none`}>
									{prayerName}
								</Typography>
							</Grid>
						) :
						(
							<Grid item xs={12} md={12}>
								<div className="mobile mobile-display-none icon-request-type-right ">
									<img className={classes.image} src={prayerTypeAndIcon.icon}></img>
								</div>
								<Typography className={`${classes.title} title-request mobile mobile-display-none`}>
									{prayerName}
								</Typography>
							</Grid>
						)
					}
					<Typography className={`${classes.title} detail-request-custom title-request tablet tablet-display-none display-none-with-mobile ml-2`}>
						{prayerName}
					</Typography>
					{/*<Grid item xs={6} className="mobile">*/}
					{/*  <Typography className={`${classes.detailTitle}`}>*/}
					{/*    <IntlMessage id="user.account.request.status"></IntlMessage>*/}
					{/*  </Typography>*/}
					{/*  <Typography*/}
					{/*    className={`${classes.detail} ${classes.bold} ${classes.capitalized}`}*/}
					{/*    classes={{ root: statusColor }}*/}
					{/*  >*/}
					{/*    {statusText}*/}
					{/*  </Typography>*/}
					{/*</Grid>*/}

					{/*Desktop*/}
					<Grid item xs={12} className="pr-5 desktop mb-2">
						<Typography className={classes.title}>{prayerName}</Typography>
					</Grid>

					{/*  Prayer partner */}
					{prayer.prayer_partner?.first_name && statusText === "Completed" && (
						<>
							<Grid item xs={10}>
								<Typography className={`${classes.title} mt-2 mb-4 `}>
									<IntlMessage id="user.account.request.view_details.your_prayer_partner" />{" "}
								</Typography>
								<Typography className={` ${classes.detailTitle}`}>
									<IntlMessage id="user.account.request.about_prayer_partner" />{" "}
								</Typography>
								<div  style={{lineHeight: "24px"}} className={`${classes.detail} ${classes.smallAvatar}`}>
									<img
										src={
											prayer.prayer_partner.avatar
												? prayer.prayer_partner.avatar
												: DefaultAvatar
										}
										alt="Prayer partner's avatar"
									></img>
									<div>
                        <span>
                          <span className="text-hippie-blue">
                            {prayer.prayer_partner.first_name}
                          </span>
                          , {prayer.prayer_partner.age}
                        </span>
										<br />
										<div className={isMobile && !isTablet ? `${classes.breakWordMobile}` : `${classes.breakWord}`}>
											{generalInfoPrayer.prayer_partner?.tagline}
										</div>
										<div>
											<img src={Location} className="mr-2 mt-0"></img>
											{prayer?.prayer_partner?.city ?? ''}
											<span>, </span>
											{prayer?.prayer_partner?.country ?? ''}
										</div>
									</div>
								</div>
							</Grid>
						</>
					)}

					{/* {
            statusText === "In-progress" &&
              //  For in process
            <>
              {generalRender()}

              {prayer.contribution && (
                <>
                  <div className={classes.partTitle}>
                    <IntlMessage id="user.account.request.contribution_detail" />
                  </div>

                  <Grid item xs={6}>
                    <Typography className={classes.detailTitle}>
                      <IntlMessage id="user.account.request.prayer_contribution" />{" "}
                    </Typography>
                    <Typography className={`${classes.detail}`}>
                      {generalInfoPrayer.contribution.name}
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid item xs={6}>
                <Typography className={`${classes.detailTitle} ml-5`}>
                  <IntlMessage id="user.account.request.frequency" />{" "}
                </Typography>
                <Typography className={`${classes.detail} ml-5`}>
                  { props.getFrequencyLabel(prayer.frequency)}
                </Typography>
              </Grid>

              {prayer.prayer_partner?.first_name && statusText === "Completed" && (
                <>
                  <div className={classes.partTitle}>
                    <IntlMessage id="user.account.request.prayer_partner" />
                  </div>

                  <Grid item xs={8}>
                    <Typography className={classes.detailTitle}>
                      <IntlMessage id="user.account.request.about_prayer_partner" />{" "}
                    </Typography>
                    <div className={`${classes.detail} ${classes.smallAvatar}`}>
                      <img
                        src={
                          prayer.prayer_partner.avatar
                            ? prayer.prayer_partner.avatar
                            : DefaultAvatar
                        }
                        alt="Prayer partner's avatar"
                      ></img>
                      <div>
                        <span>
                          <span className="text-hippie-blue">
                            {prayer.prayer_partner.first_name}
                          </span>
                          , {prayer.prayer_partner.age}
                        </span>
                        <br />
                        <span>{prayer.prayer_partner?.tagline}</span>
                      </div>
                    </div>
                  </Grid>
                </>
              )}

              {prayer.message_complete && (
                <Grid item xs={12}>
                  <Typography className={classes.detailTitle}>
                    <IntlMessage id="user.account.request.message_for_prayer_partner" />{" "}
                  </Typography>
                  <Typography className={`${classes.detail}`}>
                    {prayer.message_complete}
                  </Typography>
                </Grid>
              )}
                  </>
              }  */}

					{
						//  statusText === "Completed" &&
						/* For completed */
						<>
							{statusText !== "Completed" && (
								<Grid xs={11} className={ `${classes.detailIncomplete}` + (isTablet ? ' ml-2' : '')}>
									<Grid xs={isTablet ? 12 : (isMobile ? 9 : 6)} >
										<Typography className={` ${classes.title} mt-3 mb-4 custom-in-complete`}>
											<IntlMessage id="user.account.request.view_details.your_prayer_is_process" />{" "}
										</Typography>
									</Grid>
									<Grid xs={isTablet ? 11 :  (isMobile ? 12 : 7)}>
										<Typography className={`${classes.detail} custom-in-complete`}>
											<IntlMessage id="user.account.request.view_details.your_prayer_is_process.message" />{" "}
										</Typography>
									</Grid>
								</Grid>
							)}
							{/* Contribution Details */}
							{/*<div className={classes.partTitle + " part-title"}>*/}
							{/*  <IntlMessage id="user.account.request.contribution_detail" />*/}
							{/*</div>*/}

							{/*<Grid item xs={6}>*/}
							{/*  <Typography className={classes.detailTitle}>*/}
							{/*    <IntlMessage id="user.account.request.prayer_contribution" />{" "}*/}
							{/*  </Typography>*/}
							{/*  <Typography className={`${classes.detail}`}>*/}
							{/*    {generalInfoPrayer.contribution.name}*/}
							{/*  </Typography>*/}
							{/*</Grid>*/}

							{/*<Grid item xs={6}>*/}
							{/*  <Typography className={`${classes.detailTitle} ml-5`}>*/}
							{/*    <IntlMessage id="user.account.request.frequency" />{" "}*/}
							{/*  </Typography>*/}
							{/*  <Typography className={`${classes.detail} ml-5`}>*/}
							{/*    {props.getFrequencyLabel(prayer.frequency)}*/}
							{/*  </Typography>*/}
							{/*</Grid>*/}

							{/*<Grid item xs={12} className="desktop">*/}
							{/*  <Typography className={`${classes.detailTitle}`}>*/}
							{/*    <IntlMessage id="user.account.request.your_prayer" />{" "}*/}
							{/*  </Typography>*/}
							{/*  <Typography className={`${classes.detail}`}>*/}
							{/*    {moment.utc(prayer.end_date).utcOffset(time_zone).format("MMM DD YYYY")}*/}
							{/*    <span className={`${classes.subDetailTitle}`}>*/}
							{/*      <IntlMessage id="user.account.request.status" />*/}
							{/*    </span>*/}
							{/*    <span*/}
							{/*      className={`font-weight-bold ${*/}
							{/*        statusText === "Completed"*/}
							{/*          ? classes.completedColor*/}
							{/*          : classes.inProgressColor*/}
							{/*      }`}*/}
							{/*    >*/}
							{/*      {statusText}*/}
							{/*    </span>*/}
							{/*  </Typography>*/}
							{/*</Grid>*/}

							{/*/!*  Prayer partner *!/*/}
							{/*{prayer.prayer_partner?.first_name && statusText === "Completed" && (*/}
							{/*  <div className={classes.partTitle + " part-title"}>*/}
							{/*    <IntlMessage id="user.account.request.prayer_partner" />*/}
							{/*  </div>*/}
							{/*)}*/}

							{statusText === "Completed" && prayer && prayer?.message_complete && (
								<Grid item xs={12}>
									<Typography className={`${classes.partTitle} text-bold-mobile title-message-pp`}>
										<IntlMessage id="user.account.request.message_for_prayer_partner" />{" "}
									</Typography>
									<Typography className={classes.detailTitle}>
										{moment.utc(prayer.end_date).utcOffset(time_zone).format("MMM DD, YYYY")}
									</Typography>
									<div style={{fontSize: '14px', marginTop: "13px"}}>
										<IntlMessage
											id="user.account.request.label.dear"
											values={{ user_name: (prayer?.is_gift ? prayer?.recipient_name : prayer?.client_name) }}
										/>
									</div>
									<Typography
										className={`${classes.detail} ${classes.breakWordMsg}`}
                    style={isMobile && !isTablet ? {marginTop: "13px", lineHeight: "24px", width: '270px'} : {marginTop: "13px", lineHeight: "24px"}}
									>
										{formatContent(prayer?.translations?.message_complete.en || prayer?.message_complete)}
									</Typography>
									<div  style={{fontSize: '14px', marginTop: "13px", lineHeight: "24px"}}>
										<span>{prayer ? (prayer.salution ? getSalution(prayer.salution) : 'God bless') : 'God bless'}, </span>
                    <div className="text-your-pp">
                      <IntlMessage
                        id={'prayer_request.completion_modal.new.label.pp_god_bless'}
                        values={{ pp_name: prayer?.prayer_partner?.first_name }}
                      />
                    </div>
									</div>
								</Grid>
							)}
						</>
					}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PrayerItemExpandedDetail;
