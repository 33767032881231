import React, { Component, useState } from "react"
import IntlMessage from "@iso/components/intlMessage"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { Form, Row, Col, Button } from "react-bootstrap"
import { ExpandMore } from "@material-ui/icons"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { aboutMyself } from "@iso/Pages/Common/AboutMyself"
import CheckIcon from "@material-ui/icons/Check"
import { userRoleConstants } from "@iso/constants"
import TextField from "@material-ui/core/TextField"
import "./styles.scss"
import { getNames } from "country-list"
import { HIGH_ORDER_COUNTRIES } from './constants'

function getCountries () {
  return [...HIGH_ORDER_COUNTRIES, getNames().filter((name) => !HIGH_ORDER_COUNTRIES.includes(name)).sort()].flat()
}

const FormContactLeftPart = (props) => {
  const { initValue, errors, touched, setInitValue } = props
  const countries = getCountries()
  return (
    <>
      <Form.Group as={Row} controlId="loginEmail" className="contact-field">
        <Col sm={{ span: "12" }} className="margin-field-mobile">
          <TextField
            inputProps={{ className: 'data-hj-allow' }}
            fullWidth={true}
            variant="filled"
            className={`my-control ${errors.email ? 'border-errors' : ''}`}
            name="email"
            type="text"
            value={initValue.email}
            onChange={(event) =>
              setInitValue({
                ...initValue,
                email: event.target.value,
              })
            }
            label={ 
              initValue.email !== "" ? 
              <div style ={{color: 'rgba(0, 0, 0, 0.4)'}}>
               <IntlMessage id="contactUs.form.email.label" />
               </div>
               :
              <>
                <span className="for-focus">
                  {" "}
                  <IntlMessage id="contactUs.form.email.label" />
                </span> 

                <span className="for-no-focus">
                  {" "}
                  <IntlMessage id="contactUs.form.email.address.label" />
                </span>
              </>
            }
          />

          <ErrorMessageWrapper
            touched={touched}
            errors={errors}
            className="error_contact_msg"
            fieldName="email"
          />
        </Col>
      </Form.Group>

      {/* About myself*/}
      <Form.Group as={Row} controlId="aboutmyself" className="contact-field">
        <Col sm={{ span: "12" }} className="margin-field-mobile">
          <Select
            fullWidth={true}
            className={`my-control2 margin-about-myself ${errors.category ? 'border-errors2' : ''}`}
            value={initValue.category}
            IconComponent={ExpandMore}
            name="category"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              className: "menuListContactUs menuCategory",
            }}
            
            onChange={(event) => {
              setInitValue({
                ...initValue,
                category: event.target.value,
              })
            }}
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
              variant: "filled",
            }}
          >
            <MenuItem value="" disabled>
              <IntlMessage id="contactUs.form.aboutMyself.placeholder" />
            </MenuItem>
            {aboutMyself.map((aboutMyselfItem) => (
              <MenuItem key={aboutMyselfItem.id} value={aboutMyselfItem.id}>
                {aboutMyselfItem.label}
                <CheckIcon />
              </MenuItem>
            ))}
          </Select>
          <ErrorMessageWrapper
            touched={touched}
            errors={errors}
            className="error_contact_msg"
            fieldName="category"
          />
        </Col>
      </Form.Group>

      {/* Countries*/}
      <Form.Group as={Row} controlId="contries" className="contact-field">
        <Col sm={{ span: "12" }} className="margin-field-mobile">
          <Select
            fullWidth={true}
            className="my-control2"
            IconComponent={ExpandMore}
            value={initValue.country}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              className: "menuListContactUs", 
            }}
            onChange={(event) => {
              setInitValue({
                ...initValue,
                country: event.target.value,
              })
            }}
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
              variant: "filled",
            }}
          >
            <MenuItem value="" disabled>
              {
                <>
                  <IntlMessage id="contactUs.form.countries.placeholder" />
                  <span className="ml-2 text-muted">
                  <IntlMessage id="contactUs.form.subject.option.label" />
                  </span>
                </>
              }
              
            </MenuItem>
            {countries.map((country, idx) => (
              <MenuItem key={idx} value={country}>
                {country}
                <CheckIcon />
              </MenuItem>
            ))}
          </Select>
        </Col>
      </Form.Group>

      {/* Subject */}
      <Form.Group as={Row} controlId="subject" className="subject-left contact-field">
        <Col sm={{ span: "12" }} className="margin-field-mobile">
          <TextField
            inputProps={{ className: 'data-hj-allow' }}
            fullWidth={true}
            variant="filled"
            className="my-control"
            name="subject"
            type="text"
            onChange={(event) => {
              setInitValue({
                ...initValue,
                subject: event.target.value,
              })
            }}
            label={
              <>
                <IntlMessage id="contactUs.form.subject.label" />
                <span className="ml-2 text-muted">
                  <IntlMessage id="contactUs.form.subject.option.label" />
                </span>
              </>
            }
          />
          <ErrorMessageWrapper
            touched={touched}
            errors={errors}
            className="error_contact_msg"
            fieldName="subject"
          />
        </Col>
      </Form.Group>
    </>
  )
}

export default FormContactLeftPart
