import React, { Suspense, useEffect } from "react";
import { Router } from "react-router-dom";
import _ from "lodash";
import Loader from "react-loaders";
import { Provider } from "react-redux";
import { history } from "./helpers";
import { ToastContainer } from "react-toastify";
import PrivateLayout from "./Website/Layout/Private";
import PublicLayout from "./Website/Layout/Public";
import AdminPrivateLayout from "./Admin/Layout/Private";
import AdminPublicLayout from "./Admin/Layout/Public";
import PrayerPublicLayout from "./PrayerPartner/Layout/PublicLayout";
import PrayerPrivatePartnerLayout from "./PrayerPartner/Layout/PrivateLayout";
import { Route, Redirect, Switch } from "react-router-dom";
import NotFound from "./Pages/Common/NotFound";
import privateRoutes from "./Website/routes/private";
import publicRoutes from "./Website/routes/public";
import landingRoutes from "./Website/routes/landing";
import theme from "@iso/config/theme";
import { ThemeProvider } from "@material-ui/core/styles";

// import prayerRoutes from "./PrayerPartner/routes.js_"
import adminPrivateRoutes from "./Admin/routes/private";
import adminPublicRoutes from "./Admin/routes/public";

import prayerPartnerPrivateRoutes from "./PrayerPartner/routes/private";
import prayerPartnerPublicRoutes from "./PrayerPartner/routes/public";

import { store } from "@iso/store/root";
import { getUser } from "@iso/actions/User";
import { useSelector, shallowEqual } from "react-redux";
import "./assets/base.scss";
import LandingLayout from "./Website/Layout/LandingLayout";

import * as qs from "query-string";
import { setCurrentUser } from "@iso/actions/User";
import { useDispatch } from "react-redux";
import AuthService from "@iso/services/AuthService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { REDIRECT_URL, UNSET_HEIGHT_ROOT_PAGES } from "@iso/constants";
import unsetHeight from '@iso/utils/unsetHeight';

function App() {
  const dispatch = useDispatch();
  const { loginAsId, role } = qs.parse(window.location.search);
  const [access_denided, setAccessDenied ] = React.useState(false)
  const [ipAddress, setIpAddress ] = React.useState('')
  
  const handleLoginAsId = () => {
    if(loginAsId) {
      AuthService.loginAs({ role, id: loginAsId }).then((res) => {
        ProcessRequest.run({
          res,
          successCallback: (data) => {
            dispatch(
              setCurrentUser(
                { ...data.data },
                { redirectAfterLogin: false, remoteLogin: true }
              )
            );
            window.location = `${REDIRECT_URL[role]}`;
          },
          errorCallback: () => {},
        });
      });
    }
  }
  
  const user = useSelector((state) => state.authentication, shallowEqual) || getUser();

  useEffect(() => {
    handleLoginAsId();
    UNSET_HEIGHT_ROOT_PAGES.forEach(item => {
      const regex = new RegExp(item, "i");
      const pathname = window.location.pathname
      if(pathname.match(regex)){ unsetHeight(); }
    })
  }, [])

  return (
    <div id="app-wrapper" style={{display: 'none'}}>
    <ThemeProvider theme={theme} >
      <Provider store={store}>
        <Router history={history}>
          <div className="App">
            <Suspense
              fallback={
                <div className="loader-container">
                  <div className="loader-container-inner">
                    <div className="text-center">
                      <Loader type="line-scale-party" />
                    </div>
                  </div>
                </div>
              }
            >
              <Switch>
                { access_denided &&
                  <Redirect to={{
                    pathname: "/access_denied",
                    search: `?ip=${ipAddress}`
                  }} />
                }
              
                {_.map(privateRoutes, (route, key) => {
                  const { component, path } = route;
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={(route) =>
                        user ? (
                          <PrivateLayout component={component} />
                        ) : (
                          <Redirect to="/" />
                        )
                      }
                    />
                  );
                })}

                {_.map(publicRoutes, (route, key) => {
                  const { component, path } = route;
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={(route) => <PublicLayout component={component} />}
                    />
                  );
                })}

                {_.map(landingRoutes, (route, key) => {
                  const { component, path, hasFooter, isAboutPage, isNoPadding } = route;
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      isAboutPage={isAboutPage}
                      isNoPadding={isNoPadding}
                      render={(route) => (
                        <LandingLayout component={component} hasFooter ={hasFooter} isAboutPage={isAboutPage} isNoPadding={isNoPadding} />
                      )}
                    />
                  );
                })}

                {_.map(prayerPartnerPrivateRoutes, (route, key) => {
                  const { component, path } = route;
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={(route) => (
                        <PrayerPrivatePartnerLayout component={component} />
                      )}
                    />
                  );
                })}

                {_.map(prayerPartnerPublicRoutes, (route, key) => {
                  const { component, path } = route;
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={(route) => (
                        <PrayerPublicLayout component={component} />
                      )}
                    />
                  );
                })}

                {_.map(adminPrivateRoutes, (route, key) => {
                  const { component, path } = route;
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={(route) => (
                        <AdminPrivateLayout component={component} />
                      )}
                    />
                  );
                })}

                {_.map(adminPublicRoutes, (route, key) => {
                  const { component, path } = route;
                  return (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={(route) => (
                        <AdminPublicLayout component={component} />
                      )}
                    />
                  );
                })}

                <Route component={NotFound} />
              </Switch>
            </Suspense>
            <ToastContainer />
          </div>
        </Router>
      </Provider>
    </ThemeProvider>
    </div>
  );
}

export default App;
