import * as Yup from "yup";
import {
  CONTRIBUTION_FREQUENCY_FOR_VALIDATE,
} from "./constants";

const priceSchema = {
  id: Yup.mixed()
    .required("Please select a contribution option")
    .label("Price"),
  name: Yup.string().required(),
};

const frequencySchema = {
  code: Yup.mixed()
    .required("Please select a preferred option")
    .oneOf(
      CONTRIBUTION_FREQUENCY_FOR_VALIDATE,
      "Please select a preferred option"
    )
    .label("Frequency"),
};

const FormValidator = Yup.object().shape({
  prayer_frequency: Yup.object().shape(frequencySchema),
  prayer_contribution: Yup.object().shape(priceSchema),
});

export { FormValidator };
