import React from "react"
import PrayerTypeService from '@iso/services/PrayerTypeService'
import IntlMessage from "@iso/components/intlMessage";
import { Form } from "react-bootstrap";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ExpandMore } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import { isMobile, isTablet } from "react-device-detect";
import { initialState } from "../../../../../reducers/request_step.reducer";
import './PrayerTypesDropdown.scss'
const PrayerTypesDropdown = ({ formikValues, setFormikValues, setValueMostRequest, valueMostRequest }) => {
  const [types, setTypes] = React.useState([]);
  const most_types = [
    'birthday',
    'good-health',
    'guardian-angel'
  ];

  React.useEffect(() => {
    if (types.length > 0)
      return
    PrayerTypeService.list().then((res) => {
      const { code, data } = res.data
      if (code === 200) {
        setTypes(data);
      }
    });
  });
  const [seeMore, setSeeMore] = React.useState(false);

  const getDataFromSlug = (id) => {
    let item = types.find((it) => {
      return it.slug.id == id
    });

    if (item)
      return item
    else
      return null
  }



  return (
    <div className={` ${valueMostRequest && "background_focus_changed"} ${valueMostRequest && !isMobile && "bg_lighter_hippie_blue"} `}>
      <p className="_custom_label padding_custom_label" style={!valueMostRequest ? { color: '#6D7278' } : { color: '#499e9e' }}>
        <IntlMessage
          id={"public.request.right.form.most.label"}
        />
      </p>

      <Select
        fullWidth={true}
        className={`custom-prayertypes`}
        IconComponent={ExpandMore}
        value={formikValues?.prayer_customization?.prayer_type_id || ''}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          className: "new-design",
        }}
        onOpen={event => {
          setValueMostRequest(true)
        }}
        onChange={event => {
          setValueMostRequest(false)
        }}
        displayEmpty
        inputProps={{
          "aria-label": "Without label",
          variant: "filled",
        }}
      >
        <MenuItem value="" disabled>
          <IntlMessage id="public.request.step3.left.customization.default_requested_prayer" />
        </MenuItem>
        <MenuItem key={0} value={0} onClick={() => setFormikValues({
          ...formikValues,
          prayer_customization: {
            ...formikValues.prayer_customization,
            prayer_type_id: null
          }
        })}>
          Not selected
          <CheckIcon />
        </MenuItem>
        {types.map((type) => {
          return (
            <MenuItem key={type?.id} value={type?.id} onClick={() => setFormikValues({
              ...formikValues,
              prayer_customization: {
                ...formikValues.prayer_customization,
                prayer_type_id: type.id
              }

            })}>
              {type.name}
              <CheckIcon />
            </MenuItem>
          )
        })}
      </Select>
    </div>
  )
}

export default PrayerTypesDropdown;
