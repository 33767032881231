// Available severity: https://material-ui.com/components/snackbars/
//error
//warning
//info
//success

const checkemailActions = {
  SHOW_CHECK_EMAIL_MODAL: "show_check_email_modal",
  HIDE_CHECK_EMAIL_MODAL: "hide_check_email_modal",
  openCheckEmailModal: (data) => ({
    type: checkemailActions.SHOW_CHECK_EMAIL_MODAL,
    data
  }),
  closeCheckEmailModal: () => ({
    type: checkemailActions.HIDE_CHECK_EMAIL_MODAL,
  })
}

export default checkemailActions;