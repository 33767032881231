import React from "react";
import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { Card, Button, Collapse, Badge } from "react-bootstrap";

import IntlMessage from "@iso/components/intlMessage";
import { MDBDataTable } from "mdbreact";
import { history } from "@iso/helpers/history";

const RequestTable = ({ profileId, openRequestTable = true }) => {
  const tabArray = ["all"];
  const [requestLists, setRequestLists] = React.useState({});
  const [currentStatus, setCurrentStatus] = React.useState("unassigned");

  const headers = {
    all: [
      "id",
      "frequency",
      "reason",
      "contribution_type",
      "revenue",
      "request_status",
    ],
  };

  const formatTableData = (data, status) => {
    let dataRow = [];
    let header = (headers[status] || headers.all).map((field) => {
      return {
        label: (
          <IntlMessage
            id={`admin.user.settings.request_table.table_header.${field}`}
          />
        ),
        field: field,
        sort: field == "round" ? "desc" : "asc",
      };
    });

    dataRow = data.map((e) => {
      return {
        id: e.id,
        frequency: e.frequency_label,
        reason: e.reason_translate,
        contribution_type: e.contribution.name,
        revenue: (
          <IntlMessage
            id="admin.user.settings.request_table.table_header.revenue_price"
            values={{ price: e.contribution.price }}
          />
        ),
        request_status: <span className="text-capitalize">{e.status}</span>,
        clickEvent: () => handleClick(e.id),
      };
    });
    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    };
  };

  const handleClick = (id) => {
    history.push(`/admin/requests/${id}`);
  };

  React.useEffect(() => {
    UserService.adminGetUserRequests(profileId).then((res) => {
      ProcessRequest.run({
        res,
        successCallback: (data) => {
          var request_lists = formatTableData(data.data);

          setRequestLists(request_lists.data);
          setCurrentStatus("all");
          if (request_lists.data.rows.length == 0) {
            const tdSelector = document.querySelector(
              "tbody[data-test=table-body] tr td"
            )
            if(tdSelector) {
              tdSelector.innerText = `You have no request.`;
            }
          }
        },
      });
    });
  }, [profileId]);

  return (
    <div className="p-md-3 profile_card">
      <Card.Title className="title p-0 m-0 px-3">
        <span>
          <IntlMessage id="admin.users.settings.requests_table.title" />
        </span>
      </Card.Title>
      <Collapse in={openRequestTable} className="p-0 mt-5">
        <Card.Body>
          <MDBDataTable
            hover
            bordered={true}
            paging={true}
            id="requests_list_table"
            entries={25}
            searching={false}
            noBottomColumns={true}
            entriesOptions={[5, 20, 25]}
            data={requestLists}
            className="hide_scollbar hide_scrollbar"
            displayEntries={false}
            info={false}
          />
        </Card.Body>
      </Collapse>
    </div>
  );
};

export default RequestTable;
