import React from 'react'
import styled from 'styled-components'
import GiveImg from "@iso/assets/images/landing_give_text.svg"
import GiveImgPhone from "@iso/assets/images/landing_give_text_phone.svg"
import TheGiftOfPrayer from '@iso/assets/images/the_gift_of_prayer.svg'
import GirlPointingRight from '@iso/assets/images/girl_pointing_right.svg'
import GirlPointingRightMobile from '@iso/assets/images/girl_pointing_right_mobile.svg'
import CloudRightImg from '@iso/assets/images/landing_cloud_right.svg'
import CloudLeftImg from '@iso/assets/images/landing_cloud_left.svg'
import { Grid } from '@material-ui/core'
import Button from '../../../../../components/AtomicComponents/Button'
import {useHistory, useLocation} from 'react-router'
import IntlMessage from "@iso/components/intlMessage";
import "./styles.scss"
import { isMobile, isTablet } from "react-device-detect";
import actionsSignIn from "@iso/actions/signinmodal";
import {useDispatch, useSelector} from "react-redux";
import PopupBeta from "../../../../../actions/PopupBeta";
import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const Wrapper = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    //background-color: #AFDAF5;

    font-family: BrandonText;
    padding: 56px 0px;

    @media only screen and (min-width: 768px){
        padding-bottom: 0px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        padding-bottom: 69px;
    }
`

const TitleWrapper = styled.div`
    display: flex;
    align-items: center
`

const GirlPhoneWrapper = styled.div`

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    @media only screen and (min-width: 768px)  and (max-width: 1440px){
        margin-top: 70px;
        margin-bottom: -30px;
    }
    
    @media only screen and (min-width: 1441px){
        margin-top: 70px;
        margin-bottom: -70px;
    }

    @media only screen and (max-width: 375px){
        margin-top: 23px;
        margin-bottom: 25px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        margin-top: 30px;
        margin-bottom: 31px;
    }
`

const CallToActionBtn = styled(Button)`
    width: 303px;
    font-family: "BrandonText";
    @media only screen and (min-width: 768px){
        width: 250px;
        height: 60px;
    }
    @media only screen and (max-width: 375px){
        width: 250px;
        height: 50px;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        width: 290px;
        height: 55px;
        margin-top: 10px;
        margin-bottom: 0px;
    }
`

const Subtitle = styled.p`

    font-size: 18px;
    text-align: center;
    line-height: 32px;
    margin-top: 20px;
    color: #1C1634;
    @media (min-width: 768px) and (max-width: 1024px){
        margin-top: -70px;
        margin-bottom: 24px;
        margin-left: -4px;
    }
    @media only screen and (min-width: 1024px){
        margin-top: -60px;
    }
    @media only screen and (max-width: 375px){
        color: #000000;
        font-size: 16px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        color: #000000;
        font-size: 19px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
`

const GirlPointingImg = styled.img`
    position: relative;
    z-index: 3;
    width: 100%;
    min-width: 1000px;
    @media (min-width: 1920px){
        max-width: 1440px;
    }
    @media (min-width: 768px) and (max-width: 1024px){
        min-width: 1400px;
    }
    @media only screen and (max-width: 375px){
        height: 250px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        height: 275px;
    }
`

const CloudLeft = styled.img`
    position: absolute;
    
    @media (max-width: 1440px){
        left: -130px;
    }
    
    @media (min-width: 1441px){
        right: 1400px;
    }

    @media (min-width: 1921px){
        right: 1600px;
    }
`

const CloudRight = styled.img`
    position: absolute;
    top: 100px;
    @media (max-width: 1440px){
        right: -200px;
    }
    @media only screen and (min-width: 1441px){
        left: 1320px;
    }
    @media only screen and (min-width: 1921px){
        left: 1620px;
    }
`

const Section1 = (props) => {
    const location = useLocation()
    let params = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const history = useHistory()
    const [show, setShow] = React.useState(false);
    const { user } = useSelector((state) => state.authentication || null)

    const handleRequestNew = () => {
        gtag('event', custom_events.CLICK_BUTTON, {
            button_label : 'Request a Prayer',
        });
        ReactPixel.track(fb_pixel_event.custom_events.CLICK_BUTTON, {
            button_label : 'Request a Prayer',
        });
        history.push('/requests/step1')
    //   setShow(true)
    }
    
    const redirect = () => {
      if (params.get('redirect') === 'account') {
        if (!(user?.id || null)) {
          setTimeout(() => {
            dispatch(
              actionsSignIn.openModal({
                type: actionsSignIn.SHOW_MODAL,
              })
            );
          }, 500)
        } else {
          history.push("/account?tab=3")
        }
      }
    }
    redirect()
  
    return <Wrapper container className="pt-2" id="section1">

        <GirlPhoneWrapper className="d-flex d-md-none">
            {
                (isMobile && !isTablet) ? (
                    <GirlPointingImg src={GirlPointingRightMobile} />
                ):(
                    <GirlPointingImg src={GirlPointingRight} />
                )
            }
        </GirlPhoneWrapper>
        <TitleWrapper>
            <img src={GiveImg} className="d-none d-md-block"/>
            <img src={GiveImgPhone} style={{height: "113.65px"}} className="d-block d-md-none custom_give_img_phone"/>
            <img src={TheGiftOfPrayer} className="d-none d-md-block ml-4"></img>
        </TitleWrapper>

        <Subtitle>A unique gesture for any occasion</Subtitle>
        <CallToActionBtn handleClick={() => handleRequestNew()} variant="primary"><IntlMessage id="landing.request.new.prayer" /></CallToActionBtn>
        <GirlPhoneWrapper className="d-none d-md-flex tablet-girl-and-phone" style={{marginTop: "39px"}}>
            <GirlPointingImg src={GirlPointingRight} />
        </GirlPhoneWrapper>

        <CloudLeft className="d-none d-lg-block" src={CloudLeftImg}></CloudLeft>
        <CloudRight className="d-none d-lg-block" src={CloudRightImg}></CloudRight>
        <PopupBeta show={show} setShow={setShow}/>
    </Wrapper>
}

export default Section1
