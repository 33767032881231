const BAN_STATUS = "banned";

const BAN_ACCOUNT_ACTIONS = {
  SET_BAN_ACCOUNT_DATA: "SET_BAN_ACCOUNT_DATA",
  RESET_DATA: "RESET_DATA",

  resetBanAccountData: () => ({
    type: BAN_ACCOUNT_ACTIONS.RESET_DATA,
  }),
  setBanAccountData: (profile) => ({
    type: BAN_ACCOUNT_ACTIONS.SET_BAN_ACCOUNT_DATA,
    payload: {
      data: {
        account_id: profile.id,
        is_banned: profile.status === BAN_STATUS,
      },
    },
  }),
};

export default BAN_ACCOUNT_ACTIONS;
