import React, { Component,useState } from "react"
import { Modal } from "react-bootstrap"
import {useSelector, useDispatch} from 'react-redux'
import actionsSignIn from '@iso/actions/signinmodal'
import ModalFormSignIn from './ModalFormSignIn'
import ForgotPasswordForm from './../ForgotPassword/ForgotPasswordForm'
import confirmRequestModalActions from '@iso/actions/confirmRequestModal'
import './style.scss'

const {closeModal} = actionsSignIn;
function SignInModal(props){
  const dispatch = useDispatch()
  const {isOpen, isForgotPassword, title, backDropClassName} = useSelector((state) => state.signinmodal);
  const requestStepData = useSelector((state) => state.requestStepReducer);

const handleSwitchRegister = () => {
  dispatch(confirmRequestModalActions.openModal({
    title: title
  }))
  // handleClose()
}
  const handleClose = () => {
    if (requestStepData.step === 4){
      return
    }
    dispatch(closeModal({
      isOpen : false,
      isForgotPassword : true,
    }));
    setShowSignIn(true)
  }

  const [showSignIn, setShowSignIn] = useState(true);
  const isForgotPasswordForm = (!isForgotPassword && showSignIn);
  const sendToSignIn = () => {
      setShowSignIn(true);
  }

  const sendToForgotPassword = () => {
    setShowSignIn(false);
  }

  return(
  <div>
    <Modal
      show={isOpen}
      onHide={handleClose}
      backdropClassName={backDropClassName}
      className = "modalSignInContainer">
      {
        isForgotPasswordForm ? <ModalFormSignIn handleSwitchRegister={handleSwitchRegister} sendToForgotPassword = {sendToForgotPassword} />
        : <ForgotPasswordForm handleSwitchRegister={handleSwitchRegister} sendToSignIn = {sendToSignIn} setShowSignIn={setShowSignIn}/>
      }
    </Modal>
  </div>
  )
}

export default SignInModal
