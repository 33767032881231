import React from "react";

import { Checkbox, TextField } from "@material-ui/core";
import { Button, Row, Col, Container, Card, Form } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DetailItem from "./DetailItem";
import "./RequestDetails.scss";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import requestStepActions from "@iso/actions/RequestStepActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { isTablet } from "react-device-detect";

import { requestDetailsValidator } from "../validators";
import { escapeObject } from "@iso/helpers/index";

const RequestDetails = ({ handleChange, formikValues, setFormikValues, touched, requestErrors, setGift, setForWho, className }) => {
  const handleSubmit = (values) => { };
  const requestStepData = useSelector(
    (state) => state.requestStepReducer,
    shallowEqual
  );
  const dispatch = useDispatch();

  const [valueRecipientEmail, setValueRecipientEmail] = React.useState(false)
  const [valueSenderName, setValueSenderName] = React.useState(false)
  const [valueReason, setValueReason] = React.useState(false)
  const [valueRecipientName, setValueRecipientName] = React.useState(false)
  const [values, setValues] = React.useState(formikValues);
  const [edit, setEdit] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [isAutoSaved, setIsAutoSaved] = React.useState(false);

  const forSomeoneElse = values.for_who == "forsomeoneelse";
  const isGift = values.is_gift;


  const isChangeValue = () => {
    return (
      values.is_gift !== formikValues.is_gift ||
      values.for_who !== formikValues.for_who ||
      values.recipient_name !== formikValues.recipient_name ||
      values.recipient_email !== formikValues.recipient_email ||
      values.sender_name !== formikValues.sender_name ||
      values.reason !== formikValues.reason
    );
  }

  const validateValues = () => {
    requestDetailsValidator.validate(values, { stripUnknown: true, abortEarly: false }).then(
      () => {
        setErrors({});
      },
      (errors) => {
        var err = [];
        errors.inner.map(function (error) {
          err[error.path] = error.message;
        })

        setErrors(err);
      }
    );
  }
  const handleEdit = (isAuto = false) => {
    if (edit) {
      try {
        requestDetailsValidator.validate(values, { stripUnknown: true, abortEarly: false }).then(
          () => {
            if (!isAuto) {
              setEdit(false);
            }
            setErrors({});
            setGift(isGift);
            let data = {
              ...formikValues,
              is_gift: isGift,
              for_who: values.for_who,
              recipient_name: values.recipient_name,
              recipient_email: values.recipient_email,
              sender_name: values.sender_name,
              reason: values.reason,
            };
            setFormikValues(data);
            dispatch(requestStepActions.setDataStep(data));
          },
          (errors) => {
            var err = [];
            errors.inner.map(function (error) {
              err[error.path] = error.message;
            })

            setErrors(err);
          }
        );
      } catch (error) { }
    } else {
      setIsAutoSaved(false);
      setEdit(true);
    }
  };
  const autoSaveTimeout = React.useRef(null);
  React.useEffect(() => {
    if(isChangeValue()){
      setIsAutoSaved(true);
      if (values.for_who == "formyself") {
        values.is_gift = false;
        setValues(values);
      }
      if (!values.is_gift) {
        values.recipient_email = "";
        setValues(values);
      }
      clearTimeout(autoSaveTimeout.current);
      autoSaveTimeout.current = setTimeout(() => { handleEdit(true) }, 1000)
    }
  }, [values])

  return (
    <Container className={`wrapper_request_details ${className} `}>
      <Row>
        <Col sm="12">
          <Card className={`card_wrapper ${edit && 'border-none'}`}>
            <Card.Body>
              <Card.Title className="custom_card_title" style={(isTablet && !edit) ? { marginBottom: "16px" } : {}}>
                <Row>
                  <Col xs="8">
                    <IntlMessage id="public.request.step3.left.request_details.title" />
                  </Col>
                  <Col
                    xs="4"
                    className="text-right text-hippie-blue hover_pointer padding_right_handle_click padding_handle_click"
                  >
                    <span onClick={(e) => { handleEdit() }}>
                      <b>
                        {edit ? (
                          isAutoSaved ? (
                            isChangeValue() ? (<span style={{ color: "#cd5651" }}><IntlMessage id="common.auto_saved" /></span>) : <IntlMessage id="common.auto_saved" />
                          ) : (
                            isChangeValue() ? (<span style={{ color: "#cd5651" }}><IntlMessage id="common.save" /></span>) : <IntlMessage id="common.save" />
                          )
                        ) : (
                          <IntlMessage id="common.edit" />
                        )}
                      </b>
                    </span>
                  </Col>
                </Row>
              </Card.Title>
              <>
                {!edit ? (
                  <Row>
                    <Col sm={isTablet ? 12 : 6}>
                      <DetailItem
                        label="public.request.step3.left.request_details.for_who"
                        className="_first_custom_label"
                        displayValue={`public.request.step3.left.request_details.${values.for_who}`}
                      />

                      <ErrorMessageWrapper
                        touched={{ for_who: true }}
                        errors={requestErrors}
                        fieldName="for_who"
                        className="mb-2"
                      />
                    </Col>

                    <Col sm={isTablet ? 12 : 6}
                      className="custom_recipient_name_is_not_edit"
                      style={isTablet ? { marginTop: "-4px" } : {}}>
                      <DetailItem
                        label={`${isGift ? `public.request.step3.left.request_details.recipient_name.${values.for_who}` : `public.request.step3.left.request_details.recipient_name.forsomeoneelse.is_gift`}`}
                        displayValue={values.recipient_name}
                      />
                      <ErrorMessageWrapper
                        touched={{ recipient_name: true }}
                        errors={requestErrors}
                        fieldName="recipient_name"
                        className="mb-2"
                      />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row className="margin_top_for_whom custom_label_their_name">
                      <Col lg="6" className="mt-lg-2 mb-lg-1 custom_for_whom_is_edit bg_lighter_hippie_blue">
                        <p className="_custom_label _first_custom_label custom_margin_for_who">
                          <IntlMessage
                            id={
                              "public.request.step3.left.request_details.for_who"
                            }
                          />
                        </p>
                        <Select
                          labelId="for_who-label"
                          id="for_who"
                          defaultValue={values.for_who}
                          selected={values.for_who}
                          value={values.for_who}
                          name="for_who"
                          disabled={!edit}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              for_who: e.target.value,
                              recipient_name: ''
                            });
                            setForWho(e.target.value);
                          }}
                          className="w-100 margin-for-whom custom_select_religion"
                        >
                          {["formyself", "forsomeoneelse"].map((item) => {
                            return (
                              <MenuItem key={item} value={item}>
                                <IntlMessage
                                  id={`public.request.step3.left.request_details.${item}`}
                                />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Col>

                      <Col lg="6" className={`text-md-right ${!isTablet ? 'pr-md-0' : ''} step3-recipient-name step3-request-details-input custom_recipient_name_is_edit`}>
                        <TextField
                          id="recipient_name"
                          label={
                            <IntlMessage
                              id={`${isGift ? `public.request.step3.left.request_details.recipient_name.${values.for_who}` : `public.request.step3.left.request_details.recipient_name.forsomeoneelse.is_gift`}`}
                            />
                          }
                          value={values.recipient_name}
                          onBlur={() => {
                            setValueRecipientName(false)
                          }}
                          onFocus={() => {
                            setValueRecipientName(true)
                          }}
                          className={`${!isTablet &&
                            "w-100"} bg_lighter_hippie_blue custom_label_text_input margin_right_recipient_name ${valueRecipientName && "custom_label_color_hover background_focus_changed"}
                           ${errors.recipient_name &&
                            "error_border bg-error"}`}
                          onChange={e => {
                            setValues({
                              ...values,
                              recipient_name: e.target.value,
                            })

                            validateValues()
                          }
                          }
                        />

                        <ErrorMessageWrapper
                          touched={{ recipient_name: true }}
                          errors={errors}
                          fieldName="recipient_name"
                          className={`mb-2 text-left ${errors.recipient_name && "custom_error_message_recipient_name"} `}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {forSomeoneElse && (
                  <Row className={`form-group ${!edit && "custom_margin_form_group"} `}>
                    <Col lg="12" className={` ${edit && 'custom_title_request_detail'} `}>
                      <Form.Check
                        custom
                        className={`checkbox_custom gift_notify_margin ${(!edit && !isGift) && "custom_gift_notify_not_edit"}`}
                        type="checkbox"
                        name="gift_notification"
                        checked={values.is_gift}
                        label={
                          <IntlMessage id="public.request.step3.left.request_details.gift_notification" />
                        }
                        disabled={!edit}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            is_gift: !values.is_gift,
                          });

                          setGift(!values.is_gift)
                        }}
                        id="gift_notification"
                      />
                    </Col>

                    {forSomeoneElse && isGift && (
                      <>
                        {!edit ? (
                          <Col lg="6" className="mt-md-2">
                            <DetailItem className="margin_top_recipient_email"
                              label={`public.request.step3.left.request_details.gift_recipient_email`}
                              displayValue={values.recipient_email}

                            />
                            <ErrorMessageWrapper
                              touched={{ recipient_email: true }}
                              errors={requestErrors}
                              fieldName="recipient_email"
                              className="text-left"
                            />
                          </Col>
                        ) : (
                          <Col lg="6" className={` margin_top_recipient_email mt-md-2 text-md-left ${!isTablet ? 'pr-md-0' : ''}`}>
                            <TextField
                              id="recipient_email"
                              label={
                                <IntlMessage
                                  id={`public.request.step3.left.request_details.gift_recipient_email`}
                                />
                              }
                              value={values.recipient_email}
                              className={`custom_recipient_email_width w-100 bg_lighter_hippie_blue custom_label_text_input custom_label_color
                                ${values.recipient_email && 'custom-label-email'}
                                ${valueRecipientEmail && "background_focus_changed"}
                                 ${errors.recipient_email && 'error_border bg-error'}`}
                              multiline
                              onBlur={() => {
                                setValueRecipientEmail(false)
                              }}
                              onFocus={() => {
                                setValueRecipientEmail(true)
                              }}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  recipient_email: e.target.value,
                                })
                                validateValues()
                              }}
                            />
                            <ErrorMessageWrapper
                              touched={{ recipient_email: true }}
                              errors={errors}
                              fieldName="recipient_email"
                              className="text-left error-step3 custom_error_recipient_mail"
                            />
                          </Col>
                        )}
                      </>
                    )}


                    {forSomeoneElse && isGift && (
                      <>
                        {!edit ? (
                          <>
                            <Col lg="6" className="mt-md-2 custom_sender_name_is_not_gift">
                              <DetailItem className="margin_top_sender_name_is_not_gift"
                                label={`public.request.step3.left.request_details.from`}
                                displayValue={values.sender_name}
                              />
                              <ErrorMessageWrapper
                                touched={{ sender_name: true }}
                                errors={requestErrors}
                                fieldName="sender_name"
                              />
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col lg="6" className={`margin_top_sender_name mt-md-2 text-md-right ${isTablet && 'step3-sender-name pr-md-0'}`}>
                              <TextField
                                id="sender_name"
                                label={
                                  <IntlMessage
                                    id={`public.request.step3.left.request_details.edit.from`}
                                  />
                                }
                                value={values.sender_name}
                                className={`margin_right_sender_name w-100 bg_lighter_hippie_blue custom_label_text_input custom_label_color
                                ${values.sender_name && 'custom-label-email'}
                                 ${errors.sender_name && 'error_border bg-error'}`}
                                multiline
                                onBlur={() => {
                                  setValueSenderName(false)
                                }}
                                onFocus={() => {
                                  setValueSenderName(true)
                                }}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    sender_name: e.target.value,
                                  })
                                  validateValues()
                                }}
                              />
                              <ErrorMessageWrapper
                                touched={{ sender_name: true }}
                                errors={errors}
                                fieldName="sender_name"
                                className="text-left ml-md-3 custom_error_sender_name custom_error_message_sender_name"
                              />
                            </Col>
                          </>
                        )}
                      </>
                    )}
                  </Row>
                )}

                {!edit ? (
                  <>
                    <DetailItem className={`margin_top_label_reason ${isGift && "margin_top_label_reason_is_gift"}`}
                      label="public.request.form.reason.label"
                      displayValue={values.reason}
                    />
                    {/*<ErrorMessageWrapper*/}
                    {/*  touched={{ reason: true }}*/}
                    {/*  errors={requestErrors}*/}
                    {/*  fieldName="reason"*/}
                    {/*/>*/}
                  </>
                ) : (
                  <Row className={` custom_reason_myself_edit ${forSomeoneElse && "custom_reason_for_someone_else"}`}>
                    <Col lg="12"
                      className={`step3-request-details-input ${isGift && "margin_top_label_reason_is_edit"}`}
                      style={(isTablet && !isGift) ? { marginBottom: "24px" } : {}}
                    >
                      <TextField
                        id="reason"
                        label={
                          <IntlMessage
                            id={`public.request.form.reason.label`}
                          />
                        }
                        onBlur={() => {
                          setValueReason(false)
                        }}
                        onFocus={() => {
                          setValueReason(true)
                        }}
                        value={values.reason}
                        className={` ${!isGift && "margin_bottom_reason_request_detail"} ${!forSomeoneElse && "margin_top_for_myself"} custom_reason_request_detail bg_lighter_hippie_blue custom_label_text_input ${valueReason && " background_focus_changed custom_label_color_hover"}`}
                        multiline
                        onChange={e =>
                          setValues({ ...values, reason: e.target.value })
                        }
                      />
                      <ErrorMessageWrapper
                        touched={{ reason: true }}
                        errors={errors}
                        fieldName="reason"
                        className="error-step3"
                      />
                    </Col>
                  </Row>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RequestDetails;
