import React, {useState} from 'react'
import {Modal} from "react-bootstrap";

const PopupBeta = ({show, setShow}) => {

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal className='popup-beta' show={show} onHide={handleClose}>
        <Modal.Body>
          Hi, we are in beta mode and still launching our site.
          To sign up for our mailing list or for questions please email <a href="mailto:support@prayercloud.com">support@prayercloud.com</a>.
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PopupBeta;