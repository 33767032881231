import React from "react";
import { Card, Button, Row, Col, Form, Spinner } from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";

import { Formik } from "formik";
import IntlTelInput from "react-intl-tel-input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import alertActions from "@iso/actions/Alert";
import IntlMessage from "@iso/components/intlMessage";

import {
  RELIGIONS,
  USER_PERMISSIONS,
  DATE_FORMAT_PROFILE_FORM,
} from "@iso/constants";
import { BIRTH_DATE_FORMAT } from "./constants";

import StatusText from "./StatusText";
import ProfileAvatar from "./ProfileAvatar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import prayerPartnerActions from "@iso/actions/PartnerActions";

import { ProfileInfoFormValidator } from "./validator";
import "./styles.scss";
import "@iso/assets/common.scss";
import withAuthorization from "@iso/utils/withAuthorization";
import ButtonGroupTwo from "@iso/components/AtomicComponents/ButtonGroupTwo";
import ProcessRequest from "@iso/utils/ProcessRequest";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import PrayerPartnerService from "@iso/services/PrayerPartnerService";

const ProfileForm = (props, ref) => {
  const {
    handleUpdateProfile,
    handleUpdateNoteAdmin,
    onButtonClick,
    isEdit,
    setIsEdit,
    isLoading,
    profile,
    isPending,
  } = props;

  const initialValues = {
    first_name: "",
    last_name: "",
    denomination: "",
    phone_number: "",
    tagline: "",
    city: " ",
    country: " ",
    birth: new Date(),
    admin_note: "",
  };

  const nonDisplayBirth = ((profile.birth == null) && (profile.status === 'invitation'));

  const defaultValue = new Object({
    ...initialValues,
    ...profile,
    birth: nonDisplayBirth ? '' : new Date(profile.birth),
  });
  const { openAlert } = alertActions;
  const { setPrayerPartnerProfile } = prayerPartnerActions;
  const dispatch = useDispatch();
  const [ adminNotes, setAdminNotes] = React.useState("");
  const [ isEditAdminNote, setIsEditAdminNote ] = React.useState(false);
  const [formikValues, setFormikValues] = React.useState(defaultValue);
  const [errorMessages, setErrorMessages] = React.useState({});
  const inputPhone = React.useRef(null);
  const handleValueChange = (name, value) => {
    formikValues[name] = value;
    setFormikValues({ ...formikValues, timestamp: Date.now() });
  };

  const StatusTextWithAuth = withAuthorization(USER_PERMISSIONS.SUPER_USER)(
    StatusText
  );

  React.useEffect(() => {
    setFormikValues({ ...defaultValue });
  }, [profile]);

  const handleSubmit = (values, bag) => {
    try {
      handleUpdateProfile({
        ...values,
        birth: moment(values.birth).format(BIRTH_DATE_FORMAT),
      }).then((res) => {
        const { code, data, message } = res.data;
        if (code === 200 && data) {
          dispatch(setPrayerPartnerProfile({ profile: data}));
          ProcessRequest.run({
            hasNoti: true,
            res,
            errorCallback: () => {
              const { message } = res.data;
              if (message) {
                setErrorMessages(message);
              }
            },
          });
          setIsEdit(false);
        }
        else {
          if (typeof message === 'object') {
            if (Object.keys(message).length > 0) {
              dispatch(
                openAlert({
                  message: message[Object.keys(message)[0]],
                  severity: "error",
                })
              );
            }
          }
          else {
            dispatch(
              openAlert({
                message: message,
                severity: "error",
              })
            );
          }
        }
      });
    } catch (error) {
    }
  };
  const setCaretPosition = (caretPos) => {
    const elem = document.getElementsByClassName('phone_number')[0];
    if (elem) {
      elem.focus();
      setTimeout(() => {
        elem.setSelectionRange(caretPos, caretPos);
      }, 0);
    }
  };
  const handleSubmitNoteAdmin = (values, id) => {
    try {
      handleUpdateNoteAdmin(
          values, id
      ).then((res) => {
        setIsEditAdminNote(false)
      });
    } catch (error) {
    }
  }

  const getNoteAdminPP = ({ id }) => {
    PrayerPartnerService.adminGetNoteAdmin({ id }).then((res) => {
      if (res && res.data) {
        const { data } = res.data;
        if(data){
          setAdminNotes(data[0]?.note)
        }
      }
    })
  }

  React.useEffect(() => {
    if (profile) {
      getNoteAdminPP({ id: profile.id });
    }
  }, [profile]);

  const cancelEdit = () => {
    setIsEdit(false);
    setFormikValues(defaultValue);
  };

  return (
    <>
    <Formik
      validationSchema={ProfileInfoFormValidator}
      enableReinitialize={true}
      initialValues={formikValues}
      onSubmit={handleSubmit}
    >
      {({
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        handleChange,
        resetForm,
        values,
        touched,
        errors,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit} className="p-2">
            <Row>
              <Col xs={12} md={3} className="p-0">
                <ProfileAvatar profile={profile} />
              </Col>
              <Col xs={12} md={9} className="p-3 pl-md-4">
                <Row>
                  <Col sm="12">
                    <Form.Group controlId="formFirstName" className="mb-1">
                      <TextField
                        label="First name"
                        variant="outlined"
                        name="first_name"
                        autoFocus
                        value={values.first_name}
                        onChange={(e) => {
                          handleValueChange("first_name", e.target.value);
                        }}
                        error={touched.first_name && !!errors.first_name}
                        helperText={touched.first_name && errors.first_name}
                        disabled={!isEdit}
                        className={`custom_material_input border_fieldset_${profile.first_name_status}`}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" className="mb-3">
                    <StatusTextWithAuth
                      displayCondition={profile.first_name_status}
                      itemStatus={profile.first_name_status}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <Form.Group controlId="formLastName" className="mb-1">
                      <TextField
                        label="Last name"
                        variant="outlined"
                        name="last_name"
                        value={values.last_name}
                        onChange={(e) => {
                          handleValueChange("last_name", e.target.value);
                        }}
                        error={touched.last_name && !!errors.last_name}
                        helperText={touched.last_name && errors.last_name}
                        disabled={!isEdit}
                        className={`custom_material_input border_fieldset_${profile.last_name_status}`}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm="12" className="mb-3">
                    <StatusTextWithAuth
                      displayCondition={profile.last_name_status}
                      itemStatus={profile.last_name_status}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <Form.Group controlId="formEmail" className="mb-1">
                      <TextField
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={values.email}
                        error={touched.email && !!errors.email}
                        disabled={true}
                        className={`custom_material_input border_fieldset_${profile.email_status}`}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm="12" className="mb-3">
                    <StatusTextWithAuth
                      displayCondition={profile.email_status}
                      itemStatus={profile.email_status}
                    />
                  </Col>
                </Row>

                <Form.Group
                  controlId="formEmail"
                  className="row phone-input mb-1"
                >
                  <Col sm="12">
                    <Form.Label className="phone_number_label">
                      <IntlMessage id="common.phone_number" />
                    </Form.Label>
                    <IntlTelInput
                      ref={inputPhone}
                      containerClassName="intl-tel-input"
                      inputClassName="form-control phone_number"
                      defaultCountry="us"
                      placeholder="Enter phone number"
                      fieldId="phone_number"
                      fieldName="phone_number"
                      onPhoneNumberBlur={() => {
                        setFieldTouched("phone_number", true);
                        setFieldTouched("calling_code", true);
                      }}
                      onPhoneNumberChange={(isValid, phone, country, fullNumber) => {
                        fullNumber = fullNumber.replace(/[-]/g, "");
                        setFieldValue("phone_number", fullNumber);
                        handleValueChange("phone_number", fullNumber);
                        setFieldValue("calling_code", country.dialCode);
                        handleValueChange("calling_code", country.dialCode);
                        values.phone_number = fullNumber;
                        setCaretPosition(values.phone_number.length)
                      }}
                      onSelectFlag={(currentNumber, country, fullNumber)=>{
                        fullNumber = fullNumber.replace(/[-]/g, "");
                        values.phone_number = fullNumber;
                        setFieldValue("calling_code", country.dialCode);
                        handleValueChange("calling_code", country.dialCode);
                      }}
                      preferredCountries={["us", "gb", "au", "ca"]}
                      value={ ((values?.phone_number  || "").replace(/[-]/g, ""))}
                      disabled={!isEdit}
                    />
                    <ErrorMessageWrapper
                      className={` ${errors.phone_number && 'custom_error_phone_number'}`}
                      touched={{ phone_number: true }}
                      errors={{ ...errorMessages, ...errors }}
                      fieldName="phone_number"
                    />
                  </Col>
                  <Col sm="12 custom_status_profile_phone">
                    <StatusTextWithAuth
                      displayCondition={profile.phone_number_status}
                      itemStatus={profile.phone_number_status}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="select_religion mb-2">
                  <Col sm="12">
                    <Form.Label className="custom_label_select_religion">
                      <InputLabel id="select_religion-label">
                        <IntlMessage id="common.denomination" />
                      </InputLabel>
                    </Form.Label>

                    <Select
                      labelId="religion-label"
                      id="religion"
                      selected={values.religion[0]}
                      value={values.religion[0]}
                      name="religion"
                      disabled={!isEdit}
                      onChange={(e) => {
                        handleValueChange("religion", [e.target.value]);
                      }}
                      className="w-100 mt-2 custom_select_religion remove-border-bottom-muiInput"
                    >
                      {RELIGIONS.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col sm="12" className="mb-1 custom_status_profile_religion">
                    <StatusTextWithAuth
                      displayCondition={profile.religion_status}
                      itemStatus={profile.religion_status}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Col xs="8">
                    <Form.Label className="custom_label">
                      <InputLabel id="birth_date-label">
                        <IntlMessage id="common.birth_date" />
                      </InputLabel>
                    </Form.Label>
                    <Form.Control
                      name="birth"
                      onSelect={(value) => {
                        setFieldValue("birth", value);
                        handleValueChange("birth", value);
                      }}
                      as={DatePicker}
                      selected={formikValues.birth}
                      disabled={!isEdit}
                      dateFormat={DATE_FORMAT_PROFILE_FORM}
                    />
                  </Col>
                  <Col xs="4" className="label_age p-0">
                    <span>
                      <IntlMessage id="common.age" />: &nbsp;
                      {moment().diff(formikValues.birth, "years")}
                    </span>
                  </Col>
                  <Col sm="12" className="mb-3">
                    <StatusTextWithAuth
                      displayCondition={profile.birth_status}
                      itemStatus={profile.birth_status}
                    />{" "}
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="12">
                    <Form.Group controlId="formCity" className="mb-1">
                      <TextField
                        label="City"
                        variant="outlined"
                        name="city"
                        value={values.city || ""}
                        onChange={(e) => {
                          handleValueChange("city", e.target.value);
                        }}
                        error={touched.city && !!errors.city}
                        helperText={touched.city && errors.city}
                        disabled={!isEdit}
                        className={`custom_material_input border_fieldset_${profile.city_status}`}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" className="mb-3">
                    <StatusTextWithAuth
                      displayCondition={profile.city_status}
                      itemStatus={profile.city_status}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col sm="12">
                    <Form.Group controlId="formCountry" className="mb-1">
                      <TextField
                        label="Country"
                        variant="outlined"
                        name="country"
                        value={values.country || ""}
                        onChange={(e) => {
                          handleValueChange("country", e.target.value);
                        }}
                        error={touched.country && !!errors.country}
                        helperText={touched.country && errors.country}
                        disabled={!isEdit}
                        className={`custom_material_input border_fieldset_${profile.country_status}`}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="12" className="mb-3">
                    <StatusTextWithAuth
                      displayCondition={profile.country_status}
                      itemStatus={profile.country_status}
                    />
                  </Col>
                </Form.Group>

                <Col xs={12} className="p-0 mb-2">
                  <Row className="mt-2 mb-2">
                    <Col sm="12">
                      <Form.Group controlId="formTagline" className="mb-1">
                        <TextField
                          multiline={true}
                          rows={3}
                          type="textarea"
                          label="Tagline"
                          variant="outlined"
                          name="tagline"
                          value={values.tagline || ""}
                          onChange={(e) => {
                            handleValueChange("tagline", e.target.value);
                          }}
                          error={touched.tagline && !!errors.tagline}
                          helperText={touched.tagline && errors.tagline}
                          disabled={!isEdit}
                          className={`custom_material_input border_fieldset_${profile.tagline_status}`}
                        />
                      </Form.Group>
                    </Col>

                    <Col sm="12" className="mb-3">
                      <StatusTextWithAuth
                        displayCondition={profile.tagline_status}
                        itemStatus={profile.tagline_status}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
            {isEdit && (
              <Row className="justify-content-center card_info_control m-0 row">
                <Button
                  className="card_info_btn_cancel btn_control mx-1 mb-1 btn btn-primary"
                  onClick={() => cancelEdit(resetForm)}
                >
                  <IntlMessage id="prayer_partners.account_settings.profile.btn.cancel" />
                </Button>
                <Button
                  type="submit"
                  disabled={isLoading}
                  className="btn_control hippie-blue-button mx-1 mb-1 btn btn-primary"
                >
                  <IntlMessage id="prayer_partners.account_settings.profile.btn.save" />
                  {isLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </Row>
            )}
          </Form>
        );
      }}
  </Formik>
        <Card.Title className="title p-0 m-0 px-3 custom_header_edit_note">
          <span>
            <IntlMessage id="prayer_partners.account_settings.admin.note.title" />
          </span>
          {
            <Button
              className="float-right hover_pointer btn hippie-blue-link edit_btn"
              onClick={() => setIsEditAdminNote(true)}
              >
              <EditIcon className="pr-1 pb-1" />
              <IntlMessage id="prayer_partners.account_settings.profile.btn.edit" />
            </Button>
          }

      </Card.Title>
      <Row className={`custom_header_admin_note`}>
        <Col xs={3} md={3} ></Col>
        <Col xs={12} md={9} className="p-3 pl-md-4 custom_body_admin_note">
              <TextField
                multiline={true}
                rows={5}
                type="textarea"
                label="Admin's note"
                variant="outlined"
                name="note"
                value={adminNotes || " "}
                onChange={(e) => {
                  setAdminNotes(e.target.value)
                }}
                disabled={!isEditAdminNote}
                className={`custom_material_input custom_input_margin-left`}
              />
        </Col>
      </Row>
      {isEditAdminNote && (
        <Row className="justify-content-center card_info_control m-0 row">
          <Button
            className="card_info_btn_cancel btn_control mx-1 mb-1 btn btn-primary"
            onClick={() => {
              getNoteAdminPP({id: profile.id})
              setIsEditAdminNote(false)
            }}
          >
            <IntlMessage id="prayer_partners.account_settings.profile.btn.cancel" />
          </Button>
          <Button
            type="button"
            onClick={() => handleSubmitNoteAdmin(adminNotes, profile.id)}
            disabled={isLoading}
            className="btn_control hippie-blue-button mx-1 mb-1 btn btn-primary"
          >
            <IntlMessage id="prayer_partners.account_settings.profile.btn.save" />
            {isLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Row>
      )}
  </>
  );
};

export default ProfileForm;
