import React from "react";
import IntlMessage from "@iso/components/intlMessage";
import { Modal, Button } from "react-bootstrap";


const DialogMessage = ({ open, handleOK, handleClose, title, description, cancel, ok }) => {
  return (
    <Modal
      show={open}
      onHide={handleClose}  
      className='my-dialog'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <IntlMessage id={title}/>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <IntlMessage id={description} />
      </Modal.Body>

      <Modal.Footer className='text-center' style={{display: 'block'}}>
        {ok && (
          <Button 
            onClick={handleOK} 
            variant="primary"
                        className="btn hippie-blue-button text-white"

          >
            OK
          </Button>
        )}
        {cancel && (
          <Button onClick={handleClose} variant="primary" autoFocus
                      className="btn hippie-blue-button text-white"
          >
            <IntlMessage id="prayer_partners.account_settings.profile.btn.cancel"/>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DialogMessage;
