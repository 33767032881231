import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import "./styles.scss";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import { ContinueAsGuestValidator } from "./validator";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import requestStepActions from "@iso/actions/RequestStepActions";
import TextField from "@material-ui/core/TextField";
import IntlMessage from "@iso/components/intlMessage";
import { isMobileOnly } from "react-device-detect";

function FormContinueAsGuest(props) {
  const { handleSwitchSignup } = props;
  const requestStepData = useSelector(state => state.requestStepReducer);

  let initValue = {
    email: "",
  };

  const dispatch = useDispatch();

  const handleSubmit = (values, bag) => {
    dispatch(
      requestStepActions.setDataStep({
        ...requestStepData,
        client_email: values.email,
      })
    );
  };

  return (
    <div className={`container ${!isMobileOnly ? 'p-5' : ''}`} id="continue_as_guest__wrapper">
      <Row>
        <Col xs={12}>
          <h2 className="text-center text-bold title">Continue as a guest</h2>

          <p className="text-center subtitle">{`Please add your email to receive
          a confirmation`}</p>
        </Col>
      </Row>
      <Formik
        initialValues={initValue}
        enableReinitialize={false}
        onSubmit={handleSubmit}
        validationSchema={ContinueAsGuestValidator}
      >
        {({ handleSubmit, handleChange, touched, errors }) => (
          <form onSubmit={handleSubmit} noValidate className="form">
            <Form.Group as={Row}>
              <Col>
                <TextField
                  fullWidth={true}
                  variant="filled"
                  className="my-control"
                  name="email"
                  type="text"
                  placeholder="Your email"
                  onChange={handleChange}
                  label={<IntlMessage id="public.request.form.email.label" />}
                />
                {/* <Form.Control
                name="email"
                type="text"
                placeholder="Email"
                onChange={handleChange}
                maxLength={30}
              /> */}
                <ErrorMessageWrapper
                  touched={touched}
                  errors={errors}
                  fieldName="email"
                  shouldDisplayErrorDiv={false}
                />
              </Col>
            </Form.Group>

            <div className="buttons text-center recieve-information-button-container">
              <Button
                className="hippie-blue-sign-in-button w-100 confirm-button"
                type="submit"
              >
                Receive Confirmation
              </Button>
            </div>

            <div className="text-center mt-3 font-weight-bold suggest-sign-up">
              No account yet?{" "}
              <span
                className="color-hippie-blue sign-up"
                onClick={handleSwitchSignup}
              >
                Sign Up
              </span>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
const mapStoreToProps = store => ({});

const mapDispatchToProps = {};

const AddFormContinueAsGuest = connect(
  mapStoreToProps,
  mapDispatchToProps
)(FormContinueAsGuest);
export default AddFormContinueAsGuest;
