import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";

const getListCardOnMount = ({ successCallback, errorCallback }) => {
  UserService.getAllSavedCards().then((res) => {
    if (typeof successCallback == "function") successCallback(res.data.data);
    if (typeof errorCallback == "function") errorCallback(res.data);
  });
};

const getStripeCustomerOnMount = ({ successCallback, errorCallback }) => {
  UserService.getStripeCustomer().then((res) => {
    if (typeof successCallback == "function") successCallback(res.data.data);
    if (typeof errorCallback == "function") errorCallback(res.data);
  });
};

const getListCard = ({ successCallback, errorCallback }) => {
  UserService.getAllSavedCards().then((res) => {
    ProcessRequest.run({
      res,
      successCallback: (data) => {
        // setPaymentCards(data.data);
        if (typeof successCallback == "function")
          successCallback(res.data.data);
      },
      errorCallBack: (data) => {
        if (typeof errorCallback == "function") errorCallback(data);
      },
    });
  });
};

const handleDetachCard = ({ id, successCallback, errorCallback, closeModalCallback }) => {
  UserService.detachCard({ id }).then((res) => {
    ProcessRequest.run({
      res,
      successCallback: (data) => {
        if (typeof successCallback == "function") {
          getListCard({ successCallback });
        }
        closeModalCallback(false);
        // setOpenConfirmDeleteModal(false);
      },
    });
  });
};

export default {
  getStripeCustomerOnMount,
  getListCardOnMount,
  getListCard,
  handleDetachCard,
};
