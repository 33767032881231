import React from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";

const FlagRequestConfirmModal = (props) => {
  const { showModal, closeModal } = props;
  return (
    <div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <IntlMessage id="prayer_partners.requests.flag_modal_confirm.title"/>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group as={Row}>
            <Col sm={{ span: "8", offset: "2" }}>
              <IntlMessage id="prayer_partners.requests.flag_modal_confirm.body" />
            </Col>
          </Form.Group>
          <div className="buttons text-center mt-4">
            <Button
              className="hippie-blue-button"
              type="button"
              onClick={closeModal}
            >
              <IntlMessage id="common.close" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FlagRequestConfirmModal;
