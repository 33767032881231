import React from "react"; 
import {  Form } from "react-bootstrap"
import { ExpandMore } from "@material-ui/icons"
import Select from "@material-ui/core/Select"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import IntlMessage from "@iso/components/intlMessage";
import MenuItem from "@material-ui/core/MenuItem"
import { translate } from "@iso/utils/Translate";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {STATUS_USER} from './../constants'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import "./RecordModal.scss"
import "./RecordUserForm.scss"

const RecordUserForm = (props) => {
  const {formikValues, handleFieldChange, handleChangeRequestID,requestIdList, touched, errors } = props; 

  return(
    <>
     <FormControl variant="outlined" id="client" className="w-100 mb-4" > 
        <TextField
          label={<IntlMessage id ="admin.financial.header_table.item.client"/> }
          disabled={true}
          variant="outlined"
          name="first_name"
          autoFocus
          value = {formikValues?.client} 
          onChange={(e) => handleFieldChange('client', e.target.value)} 
          />
        </FormControl>
        
        <FormControl variant="outlined" id="prayer_request_id" className="w-100 mb-4" >
        <TextField
              name="requestID"
              disabled={true}
              variant="outlined"
              value={formikValues?.prayer_request_id}
              label={<IntlMessage id ="admin.financial.add_record.requestID.title"/> }
              placeholder={translate(
                "admin.financial.add_record.requestID.title"
              )}
              fullWidth={true}
          />
        </FormControl>
        {/* Request ID */}
 
        {/*  */}
         <FormControl variant="outlined" className="w-100 custom_field_record_user_form_status" >
        <InputLabel><IntlMessage id="admin.financial.add_record.status.title"/></InputLabel>
        <Select
            fullWidth={true} 
            value={formikValues?.status || ''}
            IconComponent={ExpandMore} 
            name="status"
            label= {translate("admin.financial.add_record.status.title")}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              className: "menuList",
            }}
            onChange={(e) => handleFieldChange('status', e.target.value)} 
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
              variant: "filled",
            }}
          >
             {STATUS_USER.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.label}
                {/* <CheckIcon /> */}
                </MenuItem>
            ))}
            
          </Select> 
          <ErrorMessageWrapper
          touched={touched}
          errors={errors}
          fieldName="status"
        />
      </FormControl>

        <FormControl variant="outlined" id="amount" className="w-100 custom_field_record_user_form_amount" >  
          <CurrencyTextField
          label={<IntlMessage id ="admin.financial.header_table.amount"/> }
          variant="outlined"
          autoFocus
          name="amount"
          value = {formikValues?.amount} 
          currencySymbol="$" 
          outputFormat="string"
          textAlign="left"
          decimalCharacter="."
          digitGroupSeparator=","
          onChange={(e, value) => {handleFieldChange('amount', value)}}
          className="currencyTextField"
          />
            <ErrorMessageWrapper
            touched={touched}
            errors={errors}
            fieldName="amount"
            />
          </FormControl>
        
        {/* Notes */}

        <Form.Group controlId="note" className="mb-4">
        <TextField
          label={<IntlMessage id ="admin.financial.add_record.notes.title"/> }
          variant="outlined"
          name="note"
          autoFocus
          className="w-100"
          rows={4}
          multiline
          value = {formikValues?.note || '' } 
          onChange={(e) => handleFieldChange('note', e.target.value)} 
          />
      </Form.Group>
    </>
  
  )
}

export default RecordUserForm;