import React from "react";
import { Card, Button, Collapse, Form, Row, Col, Spinner } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { Formik } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import "./EarningPercentage.scss";
import IntlMessage from "@iso/components/intlMessage";
import ButtonGroupTwo from "@iso/components/AtomicComponents/ButtonGroupTwo";
import { PercentageValidator } from "./validators";
import ProcessRequest from "@iso/utils/ProcessRequest";
import { userRoleConstants } from "@iso/constants";
import PrayerPartnerService from "@iso/services/PrayerPartnerService";

const EarningPercentage = (props) => {
  const { profile } = useSelector(
    (state) => state.prayerPartnerReducer,
    shallowEqual
  );
  const [isEdit, setIsEdit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { role } = props
  let [formikValues, setFormikValues] = React.useState({
    percentage: 0,
  });

  React.useEffect(() => {
    setFormikValues({ percentage: profile.percentage_label });
  }, [profile]);

  let profileIsPresent = Object.keys(profile).length;
  const cancelEdit = (resetForm) => {
    setIsEdit(false);
    resetForm();
  };

  const handleUpdatePercentage = (values) => {
    try {
      PrayerPartnerService.adminUpdatePercentage({
        ...values,
        id: profile.id,
      }).then((res) => {
        ProcessRequest.run({res});
        setIsEdit(false);
        setFormikValues({ percentage: res?.data?.data?.percentage_label });
      });
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      {profileIsPresent && (
        <div className="p-md-3 account_settings profile_card earning_percentage common">
          <Card.Title className="title p-0 m-0 px-3 custom_title-earning">
            <span>
              <IntlMessage id="prayer_partners.account_settings.earning_percentage.title" />
            </span>
            {
              (role === userRoleConstants.SUPER_ADMIN_ROLE && !profile?.deleted_at) &&
              <Button
              className="float-right hover_pointer btn hippie-blue-link edit_btn btn-primary"
              onClick={() => setIsEdit(true)}
              >
                <EditIcon className="pr-1 pb-1" />
                <IntlMessage id="prayer_partners.account_settings.profile.btn.edit" />
              </Button>
            }
            
          </Card.Title>
          <Collapse in={true}>
            <Card.Body className="p-md-3 p-0">
              <h5 className="mb-3">
                <IntlMessage id="prayer_partners.account_settings.earning_percentage.note" />
              </h5>

              <Formik
                initialValues={formikValues}
                enableReinitialize={true}
                validationSchema={PercentageValidator}
                onSubmit={(values, { setSubmitting }) => {
                  handleUpdatePercentage(values);
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 1000);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  resetForm,
                }) => {
                  return (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row>
                        <Col xs="12" sm="6" md="4" lg="3">
                          <Form.Group controlId="forPercent" className="mb-1">
                            <TextField
                              label="Percentage"
                              variant="outlined"
                              name="percentage"
                              type="number"
                              value={values.percentage}
                              onChange={handleChange}
                              error={touched.percentage && !!errors.percentage}
                              helperText={
                                touched.percentage && errors.percentage
                              }
                              disabled={!isEdit}
                              className={`custom_material_input input_percentage`}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {isEdit && (
                        <Row className="justify-content-center card_info_control m-0 row">
                          <Button
                            className="card_info_btn_cancel btn_control mx-1 mb-1 btn btn-primary"
                            onClick={() => cancelEdit(resetForm)}
                          >
                            <IntlMessage id="prayer_partners.account_settings.profile.btn.cancel" />
                          </Button>
                          <Button
                            type="submit"
                            disabled={isLoading}
                            className="btn_control hippie-blue-button mx-1 mb-1 btn btn-primary"
                          >
                            <IntlMessage id="prayer_partners.account_settings.profile.btn.save" />
                            {isLoading && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </Button>
                        </Row>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </Card.Body>
          </Collapse>
        </div>
      )}
    </>
  );
};

export default EarningPercentage;
