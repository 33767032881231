import React, { useState } from "react";
import { Row, Col, Navbar, Nav } from "react-bootstrap";
import FullLogoImg from "@iso/Pages/Common/FullLogoImg";
import FullLogoPasswordImg from "@iso/Pages/Common/FullLogoPasswordImg";
import "@iso/assets/common.scss";
import { Link } from "react-router-dom";
import ProfileMobileDrawer from "./MobileDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DrawerMenuMobileActions from "@iso/actions/DrawerMenuMobileActions";
import logoMobile from "@iso/assets/images/logo_full_mobile.svg"
import PublicSubMenuSidebar from "../../../Website/Layout/PublicSubMenuSidebar";
import actionsSignIn from "@iso/actions/signinmodal";
import registerActions from "@iso/actions/confirmRequestModal";
import IntlMessage from "@iso/components/intlMessage";
import { isMobile } from "react-device-detect";
import {
  ABOUT_SIDEBAR,
  MENU_SIDEBAR,
  PRIVATE_MENU_SIDEBAR,
  REQUEST_SIDEBAR, SIGNIN_SIDEBAR, SIGNOUT_SIDEBAR,
  SIGNUP_SIDEBAR
} from "../../../Website/Layout/constants";
import {logoutUser} from "../../../actions/User";

const MobileHeader = (props) => {
  const { isBlue } = props;
  const bg_header = isBlue ? "bg_blueSky" : "bg-white";
  const { drawerOpen } = useSelector(
    (state) => state.drawerMenuMobileReducer,
    shallowEqual
  );
  const handleLogout = () => {
    dispatch(logoutUser());
  };
  const onClickMenu = (item) => {
    switch (item) {
      case REQUEST_SIDEBAR:
        break;
      case ABOUT_SIDEBAR:
        break;
      case SIGNUP_SIDEBAR:
        dispatch(
          registerActions.openModal({
            type: registerActions.SHOW_REGISTER_MODAL,
          })
        );
        break;
      case SIGNIN_SIDEBAR:
        dispatch(
          actionsSignIn.openModal({
            type: actionsSignIn.SHOW_MODAL,
          })
        );
        break;
      case SIGNOUT_SIDEBAR:
        handleLogout();
        break;
    }
  };

  const dispatch = useDispatch();
  const handleToggleDrawer = () => {
    dispatch(DrawerMenuMobileActions.toggleOpen());
  };
  const {user, loggedIn} = useSelector((state) => state.authentication);
  // Set content menu
  const menu = loggedIn ? PRIVATE_MENU_SIDEBAR :  MENU_SIDEBAR;
  return (
    <div className={`container-full-width ${bg_header}`}>
      <div className="container-fixed">
        <Navbar
          expand="md"
          style={{ height: "70px", paddingTop: "0px", paddingBottom: "0px" }}
          className={`navbar-inverse ${props.className} ${bg_header} ${window.location.pathname === '/contact_us' && "header-white-for-mobile"} prayer-mobile-header prayer-tablet-header`}
        >
          <Navbar.Brand className="my-0 mr-md-auto font-weight-normal">
            <Link to="/">
              {props.backgroundHeader ? <FullLogoPasswordImg /> : <FullLogoImg src={logoMobile} />}
            </Link>
          </Navbar.Brand>
          <div className={`d-none ${isMobile && "d-md-block"}`}>
          <div className={`navbar-nav ml-auto navbar-nav d-flex header-tablet`}>
            {menu &&
            menu.map((item, index) => {
              return (
                <Nav.Link
                  className={`${item.className} `}
                  key={index}
                  href={item.path}
                  onClick={() => onClickMenu(item.id)}
                >
                  <IntlMessage id={item.title} />
                </Nav.Link>
              );
            })}
            { loggedIn && <PublicSubMenuSidebar user={user} isBlue ={isBlue} />}
          </div>
          </div>
          <Navbar.Toggle
            className="ml-auto"
            aria-controls="pray-cloud-navbar"
            onClick={() => handleToggleDrawer()}
          >
            <MenuIcon style={{ color: "#499e9e", fontSize: "2.5rem", marginTop: "3px", marginRight: "3px" }} />
          </Navbar.Toggle>
          <ProfileMobileDrawer
            isDrawerOpen={drawerOpen}
            handleToggleDrawer={handleToggleDrawer}
          ></ProfileMobileDrawer>
        </Navbar>
      </div>
    </div>
  );
};

export default MobileHeader;
