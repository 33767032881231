import * as Yup from "yup"
import { NAME_MIN_LENGTH, NAME_MAX_LENGTH } from "./constants";

const FlagRequestValidator = Yup.object().shape({
  reason: Yup.string()
    .label("Reason")

    .required()
})

export { FlagRequestValidator }

