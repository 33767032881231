const NOTI_DEFINATION = [
  {
    type: 'request_new' ,
    label: 'prayer.notification.new.request', 
    title: 'prayer.notification.new.request.content',
    subtitle: 'prayer.notification.new.request.view.request.details',
    path: 'prayer.notification.new.request.path'
  },
  {
    type: 'request_reminder' ,
    label: 'prayer.notification.completion.reminder.request', 
    title: 'prayer.notification.completion.reminder.request.content',
    subtitle: 'prayer.notification.completion.reminder.request.view.request.details'
  },
  {
    type: 'request_deleted' ,
    label: 'prayer.notification.deleted.request', 
    title: 'prayer.notification.deleted.request.content',
    subtitle: 'prayer.notification.deleted.request.view.contact.admin' 
  },
  {
    type: 'request_paused' ,
    label: 'prayer.notification.paused/flagged.request', 
    title: 'prayer.notification.paused/flagged.request.content',
    subtitle: 'prayer.notification.paused/flagged.request.view.request.details' 
  },
  {
    type: 'request_unpaused' ,
    label: 'prayer.notification.unpaused.request', 
    title: 'prayer.notification.unpaused.request.content',
    subtitle: 'prayer.notification.unpaused.request.view.request.details' 
  },
  {
    type: 'request_reassign' ,
    label: 'prayer.notification.assigned.request', 
    title: 'prayer.notification.assigned.request.content',
    subtitle: 'prayer.notification.assigned.request.view.request.details' 
  },
  {
    type: 'contact_admin_response' ,
    label: 'prayer.notification.admin.responds.request', 
    title: 'prayer.notification.admin.responds.request.content',
    subtitle: 'prayer.notification.admin.responds.request.contact.admin' 
  },
  {
    type: 'profile_approved' ,
    label: 'prayer.notification.profile.change.approved.request', 
    title: 'prayer.notification.profile.change.approved.request.content',
    subtitle: 'prayer.notification.profile.change.approved.request.view.account.settings' 
  },
  {
    type: 'profile_reject' ,
    label: 'prayer.notification.profile.change.rejected.request', 
    title: 'prayer.notification.profile.change.rejected.request.content',
    subtitle: 'prayer.notification.profile.change.rejected.request.view.account.settings' 
  },
  {
    type: 'user_approved' ,
    label: 'prayer.notification.profile.approve.content',
    title: 'prayer.notification.profile.approve',
    subtitle: 'prayer.notification.profile.approve.view.account.settings'
  },
] 

export { NOTI_DEFINATION }