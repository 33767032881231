import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import * as qs from "query-string";
import UserSidebar from "@iso/components/Layout/Share/UserSidebar"; 
import { TAB_MENU } from "@iso/Website/Pages/Account/constants";
import { history } from "@iso/helpers";
import { useLocation } from "react-router";
import { useDispatch, useSelector, connect } from "react-redux";
import DrawerMenuMobileActions from "@iso/actions/DrawerMenuMobileActions";
import { Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import IntlMessage from "@iso/components/intlMessage";
import MenuForGuest from "@iso/components/MobileMenu/MenuForGuest";
import actionsSignIn from "@iso/actions/signinmodal";
import GuestMenuConstant from "@iso/components/MobileMenu/constants";
import actionsSignUp from "@iso/actions/confirmRequestModal";
import PrayerCloudIConMobile from "@iso/assets/images/prayerCloud_icon_header_mobile.svg"

import "./MobileDrawer.scss"
const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  }, 
});

function ProfileMobileDrawer(props) {
  const location = useLocation();
  const {
    isDrawerOpen,
    handleToggleDrawer,
    triggerSignIn,
    triggerSignUp,
  } = props;
  const classes = useStyles();
  const { tab } = qs.parse(location.search);
  const dispatch = useDispatch();
  const { user, loggedIn } = useSelector((state) => state.authentication);
  
  const [value, setValue] = React.useState(TAB_MENU.profile);

  const handleChange = (newValue) => {
    if (newValue === -1) {
      triggerSignUp();
      dispatch(
        actionsSignUp.openModal({
          type: actionsSignUp.SHOW_REGISTER_MODAL,
        })
      );

      // dispatch(DrawerMenuMobileActions.toggleOpen());
      return;
    }

    if (newValue === -2) {
      triggerSignIn();
      dispatch(
        actionsSignIn.openModal({
          type: actionsSignIn.SHOW_MODAL,
        })
      );
      // dispatch(DrawerMenuMobileActions.toggleOpen());
      return;
    }

    dispatch(DrawerMenuMobileActions.toggleOpen());
    history.push(`/account?tab=${newValue}`);
  };

  React.useEffect(() => {
    if (user) {
      setValue(parseInt(tab));
    }
  }, [tab]);
  
  const MenuForUser = () => (
    <div className={`${classes.list} mobile-menu`} role="presentation">
      <div className="mobile-menu__tabs_wrapper">
        <UserSidebar value={value} handleChange={handleChange} user={user}></UserSidebar>
      </div>
    </div>
  );

  const RenderMenuForGuest = () => (
    <div className={`${classes.list} mobile-menu`} role="presentation">
      <div className="mobile-menu__tabs_wrapper">
        <MenuForGuest
          handleChange={handleChange}
          menu={GuestMenuConstant}
          value={value}
        ></MenuForGuest>
      </div>
    </div>
  );

  return (
    <div>
      <Drawer
        anchor={"left"}
        open={isDrawerOpen}
        onClose={() => handleToggleDrawer()}
        className={classes.backgroundDrawer}
        id="mobileHeader-container"
      >
        <div className="prayerCloudLogo">
          <Link to='/' onClick={() => handleToggleDrawer()}><img src={PrayerCloudIConMobile} /></Link>
        </div>
        {loggedIn && MenuForUser()}

        {!loggedIn && RenderMenuForGuest()}

        <div className="mx-auto request_menu_mobile_btn">
          <Container>
            <Nav.Link
              className="prayer-hippe-button"
              href="/requests/step1"
            >
              <span className="hippie-btn-text"><IntlMessage id="sidebar.public.request" /></span>
            </Nav.Link>  
          </Container>
        </div>
      </Drawer>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  triggerSignUp: (messaga) => {
    dispatch(
      actionsSignUp.openModal({
        type: actionsSignUp.SHOW_REGISTER_MODAL,
      })
    );
    dispatch(DrawerMenuMobileActions.toggleOpen());
  },
  triggerSignIn: (messaga) => {
    dispatch(
      actionsSignIn.openModal({
        type: actionsSignIn.SHOW_MODAL,
      })
    );
    dispatch(DrawerMenuMobileActions.toggleOpen());
  },
});

export default connect(null, mapDispatchToProps)(ProfileMobileDrawer);
