/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import {
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
} from "@stripe/react-stripe-js";

import {isMobile, isTablet} from "react-device-detect"
import IntlMessage from "@iso/components/intlMessage";
import { Row, Col, Form } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./CardSection.scss";
import requestStepActions from "@iso/actions/RequestStepActions";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import stripeActions from "@iso/actions/Stripe";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "red",
      iconColor: "red",
    },
  },
  hidePostalCode: true,
};

function CardSection(props) {
  const dispatch = useDispatch();
  const stepValues = useSelector(
    (state) => state.requestStepReducer,
    shallowEqual
  );
  const cardData = useSelector((state) => state.cardData);

  const { user, loggedIn } = useSelector((state) => state.authentication);

  const { name, setName } = props;

  const { errors: stripeErrors } = useSelector(
    (state) => state.stripe,
    shallowEqual
  );

  const handleSetErrorsStripe = (fieldName, message) => {
    dispatch(stripeActions.endTriggerStripe());
    dispatch(
      stripeActions.setStripeErrors({
        ...stripeErrors,
        [fieldName]: message,
      })
    );

    dispatch(stripeActions.setLoadingPayment(false));
  };
  
  return (
    <Row className={`stripe_card_session custom_stripe_card_session`}>
      <Col xs="12" className="custom_stripe_name_on_card">
        <input
          id="name"
          required
          placeholder="Name on card"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          className="custom_input_stripe"
        />
      </Col>
      <Col xs="12" className="custom_stripe_card_number">
        <CardNumberElement
          id="cardNumber"
          options={{
            ...CARD_ELEMENT_OPTIONS,
            placeholder: "Card number",
            showIcon: true,
          }}
          className={`custom_element_stripe ${stripeErrors["cardNumber"] ? 'error-border' : ''}`}
          onChange={(e) => {
            handleSetErrorsStripe("cardNumber", e?.error?.message || "");
          }}
        />
        {stripeErrors["cardNumber"] && (
          <ErrorMessageWrapper
            touched={{ cardNumber: true }}
            fieldName="cardNumber"
            errors={stripeErrors}
          />
        )}
      </Col>
      <Col xs="12" className="custom_stripe_element_mini">
        <Row>
          <Col xs="6" className="custom_input_element_stripe_expiry">
            <CardExpiryElement
              id="expiry"
              options={{ ...CARD_ELEMENT_OPTIONS, placeholder: "MM / YY" }}
              className={`custom_element_stripe ${stripeErrors["expiry"] ? 'error-border': ''}`}
              onChange={(e) => {
                handleSetErrorsStripe("expiry", e?.error?.message || "");
              }}
            />
            {stripeErrors["expiry"] && (
              <ErrorMessageWrapper
                touched={{ expiry: true }}
                fieldName="expiry"
                errors={stripeErrors}
              />
            )}
          </Col>
          <Col xs="6" className="custom_input_element_stripe_cvc">
            <CardCvcElement
              id="cvc"
              options={{ ...CARD_ELEMENT_OPTIONS, placeholder: "CVV" }}
              className={`custom_element_stripe ${stripeErrors["cvc"] ? 'error-border': ''}`}

              onChange={(e) => {
                handleSetErrorsStripe("cvc", e?.error?.message || "");
              }}
            />
            {stripeErrors["cvc"] && (
              <ErrorMessageWrapper
                touched={{ cvc: true }}
                fieldName="cvc"
                errors={stripeErrors}
              />
            )}
          </Col>

        </Row>
      </Col>

      {(loggedIn || cardData.is_save_card) && (
        <Col xs="12">
          <Form.Check
            custom
            className="checkbox_custom save_card"
            type="checkbox"
            name="is_save_card"
            checked={stepValues.is_save_card}
            label={
              <IntlMessage id="public.request.step3.right.request_details.save_card" />
            }
            onChange={(e) => {
              dispatch(
                requestStepActions.setDataStep({
                  ...stepValues,
                  is_save_card: !stepValues.is_save_card,
                })
              );
            }}
            id="is_save_card"
          />
        </Col>
      )}
    </Row>
  );
}
export default CardSection;
