import React from "react"
import { Row, Col, Button, Badge, Card } from "react-bootstrap"
import IntlMessage from "@iso/components/intlMessage"
import {
  getPrayerPaymentReports,
  sendPrintPaymentRequest,
  getReportByMonth
} from "@iso/PrayerPartner/api_v1";
import {MDBBadge, MDBDataTable} from "mdbreact";
import "./styles.scss";
import moment from "moment";
import { DATE_FORMAT_PRAYER_REQUEST } from "@iso/components/../constants";
import PrintIcon from "@material-ui/icons/Print";
import { useDispatch, useSelector } from "react-redux";
import alertActions from "@iso/actions/Alert";
import PrintMonthlyModal from "./Modal/PrintMonthlyModal";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import LinearProgress from "@material-ui/core/LinearProgress";
import { history } from "@iso/helpers/history"


const PaymentDashboard = () => {
  const { openAlert } = alertActions
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.authentication)

  const DEFAULT_STATUS = "outstanding"

  const [currentStatus, setCurrentStatus] = React.useState(DEFAULT_STATUS)
  const [requestLists, setRequestLists] = React.useState()
  const [counts, setCounts] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)

  const [openModal, setOpenModal] = React.useState(false)
  const [openThankModal, setOpenThankModal] = React.useState(false)

  const handlePrayerRequests = (status) => {
    setIsLoading(true)
    setCurrentStatus(status)
    try {
      getPrayerPaymentReports(status).then((res) => {
        if (res) {
          const { code, data, count } = res.data
          if (res && code === 200) {
            const reqList = formatTableData(data[status])
            setRequestLists(reqList.data)
            setCounts({
              outstanding: data["outstanding"].length,
              paid: data["paid"].length,
            })
            if (reqList.data.rows.length == 0) {
              const tbody = document.querySelector(
                "tbody[data-test=table-body] tr td"
              )
              if(tbody) tbody.innerText = `You have no ${status} request.`
            }
          }
        }
      })
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const formatTableData = (data) => {
    let dataRow = []
    let header = [
      {
        label: <IntlMessage id="prayer_partners.table.title.parent_transaction_id" />,
        field: 'parent_id',
        sort: 'desc'
      },
      {
        label: <IntlMessage id="prayer_partners.table.title.transaction_id" />,
        field: 'id',
        sort: 'desc'
      },
      {
        label: <IntlMessage id="prayer_partners.table.title.request_id" />,
        field: "prayer_request_id",
        sort: "asc",
      },
      {
        label: <IntlMessage id="prayer_partners.table.title.client_name" />,
        field: "client_name",
        sort: "asc",
      },
      {
        label: <IntlMessage id="prayer_partners.table.title.date_complete" />,
        field: "date_complete",
        sort: "desc",
      },
      {
        label: <IntlMessage id="prayer_partners.table.title.amount_earned" />,
        field: "formatted_amount",
        sort: "asc",
      },
    ]
    data.forEach((e, index) => {
      dataRow.push({
        parent_id: e.prayer_request?.transaction_parent?.id || (e.prayer_request?.transaction_user?.id || ''),
        id: e.id,
        prayer_request_id: e.prayer_request_id || 'N/A',
        client_name: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e?.prayer_request?.client_name ? e?.prayer_request?.client_name.toLocaleLowerCase() : 'n/a'}
          >
            {
              e?.prayer_request?.client_name ? e.prayer_request.client_name : 'N/A'
            }
          </MDBBadge>
        ),
        date_complete: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e?.prayer_request?.date_complete ? e.prayer_request.date_complete : ''}
          >
            {
              e.prayer_request
              ? ( e.prayer_request.date_complete ?
                moment(e.prayer_request.date_complete).format(
                  DATE_FORMAT_PRAYER_REQUEST
                ) : 'Incomplete'
              )
              : 'N/A'
            }
          </MDBBadge>
        )
        ,
        formatted_amount: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.amount_owed}
          >
            {
              e.formatted_amount || '$0.00'
            }
          </MDBBadge>
        )
      })
    })
    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    }
  }

  const handleSendPrintPaymentRequest = (values) => {
    setIsLoading(true)
    try {
      sendPrintPaymentRequest({ month: values.month, year: values.year }).then((res) => {
        const { code, data, message } = res.data
        if (code === 200) {
          setOpenThankModal(true);
        } else {
          dispatch(
            openAlert({
              message: message.replace(values.month, moment.months()[values.month-1]),
              severity: "error",
            })
          )
        }
      })
    } catch (error) {
    } finally {
      setOpenModal(false)
      setIsLoading(false)
    }
  }

  const handleShowPaymentRequest = (values) => {
    setIsLoading(true)
    try {
      getReportByMonth(values).then((res) => {
        const { code, data, message } = res.data
        if (code === 200) {
          history.push(`/prayer_partners/payments/${values.month}/${values.year}`);
        } else {
          dispatch(
            openAlert({
              message: message.replace(values.month, moment.months()[values.month-1]),
              severity: "error",
            })
          )
        }
      })
    } catch (error) {
    } finally {
      setOpenModal(false)
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    handlePrayerRequests(DEFAULT_STATUS)
  }, [])

  return (
    <>
      {isLoading && <LinearProgress />}
      <div className="mint-tulip-background p-3 payment_dashboard_wrapper">
        <Row>
          <Col sm="6" className="text-md-left text-center mb-3">
            <h2>
              <IntlMessage id="prayer_partners.payments.title" />
            </h2>
          </Col>
        </Row>

        <Card className="table_wrapper">
          <Row className="mt-3 mb-3 pl-5 pr-5 row">
            <Col sm="4">
              <Button
                variant={`${
                  currentStatus == "outstanding" ? "active-status" : "light"
                } btn-block`}
                onClick={() => {
                  handlePrayerRequests("outstanding")
                }}
              >
                <IntlMessage id="prayer_partners.payments.status.outstanding" />
                <>
                  {counts.outstanding > 0 && (
                    <Badge className="ml-2" pill variant="danger">
                      {counts.outstanding}
                    </Badge>
                  )}
                </>
              </Button>
            </Col>
            <Col sm="4">
              <Button
                variant={`${
                  currentStatus == "paid" ? "active-status" : "light"
                } btn-block`}
                onClick={() => {
                  handlePrayerRequests("paid")
                }}
              >
                <IntlMessage id="prayer_partners.payments.status.paid" />
                <>
                  {counts.paid > 0 && (
                    <Badge className="ml-2" pill variant="danger">
                      {counts.paid}
                    </Badge>
                  )}
                </>
              </Button>
            </Col>
            <Col
              sm="4"
              className="text-center text-truncate hover_pointer print_d"
            >
              <a
                className="print_payments_btn"
                onClick={() => setOpenModal(true)}
              >
                <PrintIcon className={`flag_color_icon`} />
                <span className="font-weight-bold print_title">
                  <IntlMessage id="prayer_partners.payments.print_monthly_benefit_statement" />
                </span>
              </a>
            </Col>
          </Row>
          <Row className="ml-2 mr-2">
            <Col sm="12">
              <MDBDataTable
                hover
                paging={true}
                id="requests_list_table"
                entries={25}
                searching={false}
                noBottomColumns={true}
                entriesOptions={[5, 20, 25]}
                data={requestLists}
                sortRows={['date_complete', 'formatted_amount', 'client_name']}
                className="hide_scrollbar"
              />
            </Col>
          </Row>

          <p className="notes_bottom p-3">
            <IntlMessage id="prayer_partners.payments.notes" />
          </p>
        </Card>
        <PrintMonthlyModal
          open={openModal}
          handlePrint={handleSendPrintPaymentRequest}
          handleShow={handleShowPaymentRequest}
          handleClose={() => setOpenModal(false)}
          title={<IntlMessage id="prayer_partners.payments.modal.title" />}
        />

        <ConfirmModal
          open={openThankModal}
          handleConfirm={() => setOpenThankModal(false)}
          handleClose={() => setOpenThankModal(false)}
          title={
            <IntlMessage id="prayer_partners.payments.modal.confirm.title" />
          }
          okText="Ok"
          content={
            <IntlMessage
              id="prayer_partners.payments.modal.confirm.content"
              values={{ email: user && user.email }}
            />
          }
          contentFooter={
            <IntlMessage id="prayer_partners.payments.modal.confirm.content_footer" />
          }
        />
      </div>
    </>
  )
}

export default PaymentDashboard
