import { Grid } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { FOOTER_LIST } from "./constants";
import IntlMessage from "@iso/components/intlMessage";
import FooterCloud from "@iso/assets/images/FooterCloud.svg";
import FooterCloudMobile from "@iso/assets/images/FooterCloudMobile.svg";
import FooterCloudTablet from "@iso/assets/images/FooterCloudTablet.svg";
import { Row } from "react-bootstrap";
import registerActions from "@iso/actions/confirmRequestModal";
import { useDispatch } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";

const FooterWrapper = styled.div`
  position: relative;
  background: #b0daf4;
  padding: 45px 40px;

  @media only screen and (min-width: 768px) {
    padding: 45px 57px 45px 0px;
  }

  @media only screen and (min-width: 900px) {
    height: 306px;
    padding: 60px 95px;
  }

  @media only screen and (min-width: 1500px) {
    padding: 60px 130px;
  }

  @media only screen and (min-width: 1700px) {
    padding: 60px 70px;
  }
`;

const Logo = styled.img`
  @media only screen and (min-width: 900px) {
    margin-top: -28px;
  }

`;

const NavListContainer = styled.div`
  font-family: "BrandonText";
  color: #2e82a0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -25px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    margin-top: -10px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    text-align: center;
    justify-content: flex-end;
    align-items: center;
    margin-top: -10px;
    height: 100%;
  }
`;

const NavListContainerLink = styled.div`
  font-family: "BrandonText";
  color: #2e82a0;
  display: inline-block;
  align-items: center;
  margin-top: 30px;
  height: 100%;
  margin-left: 1rem;
  position: relative;
  z-index: 1;
`;

const NavListItem = styled.a`
  margin-top: 20px;
  color: #2e82a0;
  font-size: 20px;

  &:hover {
    text-decoration: none;
    color: #2e82a0;
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 20px;
    font-size: 20px;
    margin-left: -8px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 20px;
    margin-left: 21px;
    &.link {
      margin-left: 0;
      margin-right: 30px;

      &:nth-child(3) {
        margin-right: 18px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const ReserveCopy = styled.p`
  font-family: "BrandonText";
  color: #2e82a0;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 16px;
  }
`;
const ReserveCopyWeb = styled.p`
  font-family: "BrandonText";
  color: #2e82a0;
  font-size: 16px;
  line-height: 34px;
  margin: 0;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 14px;
    margin-top: -13px;
  }
`;

const PublicFooter = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const ImageContainer = styled.div`
    height: 93px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: right;
    background-position-y: bottom;
    @media only screen and (min-width: 1024px) and (max-width: 1919px) {
      height: 157px;
      width: 100%;
      background-size: 1440px auto;
    }
    @media only screen and (min-width: 1920px) {
      height: 157px;
      left: -200px;
      width: 100%;
      background-size: 1440px auto;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    height: 74px;
    background-size: auto;
  }
  @media only screen and (min-width: 768px) {
  }

  }
  `;

  const ColumnContainer = styled.div`
    margin-left: 23px;
  `;
  return (
    <div className={`container-full-width bg_blueSky`}>
      <div className="container-fixed">
        <FooterWrapper className="public-footer">
          <ImageContainer
            className="d-block footer-cloud"
            style={{ backgroundImage: `url(${isMobile ? (isTablet ? FooterCloudTablet : FooterCloudMobile) : FooterCloud})` }}
          ></ImageContainer>

          <Grid container className="pl-20 margin_bottom_footer">
            <Grid item xs={12} lg={3} sm={8}
                  className={` ${isMobile ? "custom_logo_footer_mobile justify-content-center text-center text-lg-left text-md-left mb-md-0"
                    : "justify-content-center text-center text-lg-left text-md-left mb-3 mb-md-0"}`}
            >
              <Logo src={FOOTER_LIST.logo} className="footer-logo"/>
            </Grid>

            {isMobile && !isTablet ? <></> : (<Grid container item xs={12} lg={10} className="d-block d-md-none justify-content-start ">
              <ReserveCopy className="d-md-block text-center mb-4 font-size-14px" style={{ marginTop: "-15px" }}>
                <IntlMessage id="common.reserved.copyright.mobile1" /> <IntlMessage id="common.reserved.copyright.mobile2" />
              </ReserveCopy>
            </Grid>)}

            {
              isMobile && !isTablet 
              ? (<Grid item xs={12} lg={9} className="d-block d-md-none d-lg-block justify-content-center">
                  <NavListContainer className="nav-list-container">
                    {FOOTER_LIST.col1_mobile.items.map((item, idx) => {
                      return (
                        <>
                          <NavListItem key={idx} href={item.href} className="nav-list-item">
                            {item.title}
                          </NavListItem>
                        </>
                      );
                    })}
                    <NavListItem
                      className="hover_pointer"
                      onClick={() => dispatch(registerActions.openModal())}
                    > Sign up/Login </NavListItem>
                  </NavListContainer>
                </Grid>) 
            : (<Grid item xs={12} lg={9} className="d-block d-md-none d-lg-block justify-content-center">
                <NavListContainer className="nav-list-container">
                  {FOOTER_LIST.col1.items.map((item, idx) => {
                    return (
                      <>
                        <NavListItem key={idx} href={item.href} className="nav-list-item">
                          {item.title}
                        </NavListItem>
                      </>
                    );
                  })}
                  <NavListItem
                    className="hover_pointer"
                    onClick={() => dispatch(registerActions.openModal())}
                  > Sign up/Login </NavListItem>
                </NavListContainer>
              </Grid>)
            }
            {
              <Grid item sm={4} className="d-none d-md-block d-lg-none justify-content-center">
                {/* <ShareYourRequest variant="footer" style ={{marginTop: '-15px'}}/> */}
                <NavListContainer>
                  {FOOTER_LIST.col2.items.map((item, idx) => {
                    return (
                      <>
                        <NavListItem key={idx} href={item.href} className="list-social-item">
                          <img src={item.icon} />
                        </NavListItem>
                      </>
                    );
                  })}
                </NavListContainer>
              </Grid>
            }
            <Grid container item xs={12} lg={9} className="d-none d-md-flex justify-content-start">
              <ReserveCopyWeb className="d-none d-md-block ml-3"> <IntlMessage
                id="common.reserved.copyright" /></ReserveCopyWeb>
            </Grid>

            {
              isMobile && isTablet 
              ? (
              <>
              <Grid item xs={12} lg={12} className="d-md-block d-none d-lg-none justify-content-center" style={{"position": "relative",
                  "left": "310px",
                  "top": "-45px"}}>
                <NavListContainerLink>
                  {FOOTER_LIST.col3.items.map((item, idx) => {
                    return (
                      <>
                        <NavListItem key={idx} href={item.href} className="link">
                          {item.title}
                        </NavListItem>
                      </>
                    );
                  })}
                </NavListContainerLink>
              </Grid>
              <Grid item xs={12} lg={12} className="d-md-block d-none d-lg-none justify-content-center"style={{"position": "relative",
                  "left": "145px",
                  "top": "-60px"}}>
                <NavListContainerLink>
                  {FOOTER_LIST.col4.items.map((item, idx) => {
                    return (
                      <>
                        <NavListItem key={idx} href={item.href} className="link">
                          {item.title}
                        </NavListItem>
                      </>
                    );
                  })}
                  <NavListItem
                    className="hover_pointer link"
                    onClick={() => dispatch(registerActions.openModal())}
                  > Sign up/Login </NavListItem>
                </NavListContainerLink>
              </Grid>
              </>
            )
              : 
              (<Grid item xs={12} lg={7} className="d-md-block d-none d-lg-none justify-content-center">
              <NavListContainerLink>
                {FOOTER_LIST.col1.items.map((item, idx) => {
                  return (
                    <>
                      <NavListItem key={idx} href={item.href} className="link">
                        {item.title}
                      </NavListItem>
                    </>
                  );
                })}
                <NavListItem
                  className="hover_pointer link"
                  onClick={() => dispatch(registerActions.openModal())}
                > Sign up/Login </NavListItem>
              </NavListContainerLink>
            </Grid>)
            }

            <Grid container item xs={12} lg={3}
                  className="d-flex d-md-none d-lg-flex justify-content-lg-end justify-content-center pr-3 ml-auto social-link-container" style={{"margin-top":"25px !important"}}>
              {/* <ShareYourRequest variant="footer" style ={{marginTop: '-15px'}}/> */}
              <NavListContainer className="social-link-wrapper">
                <Row className="mt-0 mt-md-3">
                  <ReserveCopy className="d-none d-md-block ml-3"> <NavListItem href={"/contact_us"} className="link">Contact Us</NavListItem></ReserveCopy>
                  {FOOTER_LIST.col2.items.map((item, idx) => {
                    return (
                      <>
                        <ColumnContainer xs="4" className="mt-4 mb-5 mt-md-0 mb-md-0">
                          <NavListItem key={idx} href={item.href}>
                            <img src={item.icon} height={idx !== 2 ? '28px' : '24px'} />
                          </NavListItem>
                        </ColumnContainer>
                      </>
                    );
                  })}
                </Row>
              </NavListContainer>
            </Grid>
            {isMobile && !isTablet && 
            (<ReserveCopy className="d-md-block text-center mb-4 font-size-14px" style={{"position": "relative",
              "top": "20px",
              "left": "5px",
              "margin": "auto"}}>
                <IntlMessage id="common.reserved.copyright.mobile2" style={{'font-size':'14px !important'}} />
              </ReserveCopy>)}
          </Grid>
        </FooterWrapper>
      </div>
    </div>
  );
};

export default PublicFooter;
