import React, { Fragment } from "react";

const PublicLayout = (props) => {
  const Component = props.component;
  return (
    <Fragment>
      <div className="app-main app_container container p-0">
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Component />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PublicLayout;
