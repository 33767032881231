import React from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import AuthService from "@iso/services/AuthService"
import { setCurrentUser } from "@iso/actions/User"
import { connect } from "react-redux"
import { userRoleConstants } from "@iso/constants/user"
import { history } from "@iso/helpers/history"
import IntlMessage from "@iso/components/intlMessage"
import CreateNewPasswordBox from "@iso/Pages/Common/RegistrationBox/CreateNewPasswordBox"
import CloudImg from "@iso/assets/images/CloudCrtPassword.svg"
import './formPasswordExpiredStyles.scss'
import { useHistory } from "react-router";
import actionsSignIn from '@iso/actions/signinmodal'
function FormPasswordExpired(props) {

  const dispatch = useDispatch();
  const history = useHistory();
  const { data } = props;

  const handleSubmit =  () => { 
    history.push('/')
    dispatch(
      actionsSignIn.openModal({isForgotPassword : true})
    )
  }
  const renderForm = () => {
    return (
      <form  noValidate>
        <Form.Group as={Row} className = "d-block">
         <Form.Text className = "text-msg">
              <span className ="text-center d-block"><IntlMessage id="confirm.reset.password.expired.title1" /> </span>
              <span className ="text-center d-block"><IntlMessage id="confirm.reset.password.expired.title2"/> </span>
          </Form.Text>
        <Button variant="primary" size="lg"
        block className ="hippie-blue-sign-in-button py-3 font-weight-bold mx-auto" type="button" onClick = {handleSubmit}>
            <IntlMessage id="forgot_password.form.submit" />
        </Button>
        </Form.Group>
      </form>
    )
  }
  return (
    <div className = "form-password-expired-container">
       <div className="d-none d-md-block img-container">
            <img src = {CloudImg} alt ="Cloud" className="image_cloud"/>
      </div>

      <CreateNewPasswordBox  title ="Link Expired" form = { renderForm() }/>
    </div>
   )
}

export default FormPasswordExpired
