// Available severity: https://material-ui.com/components/snackbars/
//error
//warning
//info
//success

const welcomePrayerPartnerActions = {
  SHOW_PRAYER_PARTNER_MODAL: "show_welcome_prayer_partner_modal",
  HIDE_PRAYER_PARTNER_MODAL: "hide_welcome_prayer_partner_modal",
  openModal: () => ({
    type: welcomePrayerPartnerActions.SHOW_PRAYER_PARTNER_MODAL,
  }),
  closeModal: () => ({
    type: welcomePrayerPartnerActions.HIDE_PRAYER_PARTNER_MODAL,
  })
}

export default welcomePrayerPartnerActions;