export const STATUS = {
  in_progress: "in_progress",
  send_unopenned: "send_unopenned",
  viewed: "viewed",
};

export const STATUS_LABEL = {
    in_progress: "Unopened",
    send_unopenned: "Unopened",
    viewed: "Opened",
  };
  