import { userRoleConstants } from "@iso/constants";

export const MENU_SIDEBAR = [
  {
    path: "/admin",
    title: "sidebar.admin.requests.title",
  },
  {
    path: "/admin/prayer_partners",
    title: "sidebar.admin.prayer_partners.title",
  },
  {
    path: "/admin/users",
    title: "sidebar.admin.users.title",
    role: userRoleConstants.SUPER_ADMIN_ROLE,
  },
  {
    title: "sidebar.admin.reports.title",
    section: 'report',
    subMenu: [
      {
        title: "sidebar.admin.reports.general.title",
        subPath: '/admin/reports'
      },
      {
        title: "sidebar.admin.reports.request.title",
        subPath: '/admin/reports/requests'
      },
      {
        title: "sidebar.admin.reports.prayer_partners_title",
        subPath: '/admin/reports/prayer-partners'
      },
    ]
  },

  {
    path: "/admin/financial",
    title: "sidebar.admin.financial.title",
    section: "financial",
    role: userRoleConstants.SUPER_ADMIN_ROLE,
    subMenu: [
      {
        title: "sidebar.admin.financial.user.title",
        role: "users",
        subPath: '/admin/financial?filter=users'
      },
      {
        title: "sidebar.admin.financial.prayer_partners.title",
        role: "prayer_partners",
        subPath: '/admin/financial?filter=prayer_partners'
      },
    ],
  },
  // {
  //   path: "/admin/financial",
  //   title: "sidebar.admin.financial.title",
  //   role: userRoleConstants.SUPER_ADMIN_ROLE,
  // },
  // {
  //   title: "sidebar.admin.reports.title",
  //   section: 'report',
  //   subMenu: [
  //     {
  //       title: "sidebar.admin.reports.general.title", 
  //       subPath: '/admin/reports'
  //     },
  //     {
  //       title: "sidebar.admin.reports.request.title", 
  //       subPath: '/admin/reports/requests'
  //     },
  //     {
  //       title: "sidebar.admin.reports.prayer_partners_title", 
  //       subPath: '/admin/reports/prayer-partners'
  //     },
  //   ]
  // },
  {
    title: "sidebar.admin.support_center.title",
    section: 'support_centers',
    subMenu: [
      {
        title: "sidebar.admin.support_center.user.title",
        subPath: '/admin/support_centers?filter=users' 
      },
      {
        title: "sidebar.admin.support_center.prayer_partners_title",
        subPath: '/admin/support_centers?filter=prayer_partners' 
      },
    ],
  },
  {
    path: "/admin/adminsTab",
    title: "sidebar.admin.admins.tabs.title",
    role: userRoleConstants.SUPER_ADMIN_ROLE,
  },
  {
    path: "/admin/coupons",
    title: "sidebar.admin.coupons.title",
  },
];
