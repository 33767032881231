import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom"
import "./RequestStatement.scss"
import { history } from "@iso/helpers/history"

const RequestStatement = ({ value, title, displayValueClassName, title_href, link_href, isArr = false }) => { 
  const previous_location = history.location.pathname;
  return (
    
      <Row className="mt-3 request_item_wrapper">
        <Col xs="12" sm="4" md="4" lg="3" className="bullet">
          <span className="font-weight-bold">{title}</span>
        </Col>
        {
          !isArr ? 
          <Col xs="12" sm="8" md="8" lg="9" className= {`${displayValueClassName} margin-left-content`} style={{marginTop: '4px'}}>
          {value}
          <Link to={{pathname: link_href, state: { previous_location: previous_location}}} className = "link-hippie ml-2"> {title_href}</Link>
          </Col>
          :
          <Col xs="12" sm="8" md="8" lg="9" className= {`${displayValueClassName} margin-left-content`} style={{marginTop: '4px'}}>
            {
              value && value.map((item,idx) => {
                return(
                  <Link to ={`${link_href}/${item.id}`} className ="link-blue-light"> 
                    #{item.id}
                    {idx < value.length-1 && ', ' }
                  </Link>
                )
              })
            }
          </Col>
        }
        
      </Row>
    

   
  );
};

export default RequestStatement;
