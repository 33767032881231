import React from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import CustomButton from "@iso/components/AtomicComponents/Button";

const DeclineConfirmModal = ({ open, handleClose, declineRequest }) => {
    return (
        <Modal show={open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <IntlMessage id="prayer_partners.requests.modal.warning.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <IntlMessage id="prayer_partners.requests.modal.warning.content.1" />
                </p>
                <p>
                    <IntlMessage id="prayer_partners.requests.modal.warning.content.2" />
                </p>
                <p>
                    <IntlMessage id="prayer_partners.requests.modal.warning.content.3" />
                </p>
            </Modal.Body>
            <Modal.Footer  className="text-center" style={{ display: "block" }}>
                <CustomButton
                    variant="primary"
                    handleClick={declineRequest}
                    disabled={false}
                    isSubmitting={false}
                    style={{width:'150px'}}
                >
                    <IntlMessage id="common.yes" />
                </CustomButton>
                <CustomButton
                    handleClick={handleClose}
                    disabled={false}
                    style={{width:'150px'}}
                >
                    <IntlMessage id="common.cancel" />
                </CustomButton>
            </Modal.Footer>
        </Modal>
    );
};

export default DeclineConfirmModal;
