import React from "react";
import { Image, Card } from "react-bootstrap";

import IntlMessage from "@iso/components/intlMessage";
import PendingImg from "@iso/assets/images/clock.png";
import ApprovedImg from "@iso/assets/images/check-mark.png";
import ErrorImg from "@iso/assets/images/error.png";

const TaglineMessage = ({ status, suggest, show }) => {
  const config = {
    pending: {
      message: <IntlMessage id="prayer_partners.account_settings.profile.message.pending"/>,
      image: PendingImg,
      className: "pending",
    },
    approved: {
      message: <IntlMessage id="prayer_partners.account_settings.profile.message.approved"/>,
      image: ApprovedImg,
      className: "approved",
    },
    reject: {
      message: <IntlMessage id="prayer_partners.account_settings.profile.message.reject"/>,
      image: ErrorImg,
      className: "reject",
    },
  };

  return (
    <div
      className={`justify-content-md-middle tagline-status  ${config[status]?.className}`}
    >
      {status && (
        <>
	        {
		        show && (<div style={{display: "flex"}}>
			        <Image src={config[status]?.image} roundedCircle/>
			        <p>{config[status]?.message}</p>
		        </div>)
					}
          {status === "reject" && (
            <Card>
              <Card.Title><IntlMessage id="prayer_partners.account_settings.profile.tagline.sugest.label"/></Card.Title>
              <Card.Text>{suggest}</Card.Text>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export { TaglineMessage };
