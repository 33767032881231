import React from "react";
import RequestStatement from "./RequestStatement";
import IntlMessage from "@iso/components/intlMessage";
import { Container } from "react-bootstrap"; 
import "./FinancialDetailBuilder.scss"
import { DATE_FORMAT } from "../constants"
import moment from "moment";  

const FinancialDetailBuilder = ({ requestValue }) => {
  const time_zone = -(new Date().getTimezoneOffset() / 60);

  return (
    <Container className="wrapper_details">
      <div className="pb-4">
        <RequestStatement
          title={<IntlMessage id="admin.financial.details.date_created.title" />} 
          value={moment.utc(requestValue.created_at).utcOffset(time_zone).format(DATE_FORMAT)}
        />

        <RequestStatement
          title={ <IntlMessage id="admin.financial.details.created_by.title" /> }
          value={requestValue?.created_by ? requestValue?.created_by?.full_name : requestValue?.created_by_text} 
        />
        {
          // 0 : user 
          // 1 : prayer partner
          requestValue?.type !== 0 ?
          <>
          <RequestStatement
          title={<IntlMessage id="admin.financial.details.pp" />}
          value={requestValue?.user?.full_name}
          title_href={ <IntlMessage id={"admin.financial.detail.view.profile"} /> }  
          link_href= {`/admin/prayer_partners/${requestValue?.user?.id}/account_settings`}
          />
 
          <RequestStatement
            title={<IntlMessage id="admin.financial.header_table.amount_owed" />} 
            value={ requestValue?.formatted_amount }
          />
          </>
          :
          <>
          <RequestStatement
          title={<IntlMessage id="admin.financial.details.client" />}
          value={requestValue?.prayer_request?.client_name}
          link_href = {`/admin/users/${requestValue?.prayer_request?.client_id}`}
          title_href={ <IntlMessage id={"admin.financial.detail.view.profile"} /> }  
          />
 
          <RequestStatement
            title={<IntlMessage id="admin.financial.details.amount_received" />}
            value={ requestValue?.formatted_amount }
          />
          </>
        }
        

        <RequestStatement
          title={<IntlMessage id="admin.financial.details.notes" />}
          value={requestValue.note}
        />
      </div>

      {
        requestValue?.prayer_request?.id && 
        <>
          <hr className="statement_hr"/>
          <div className="pb-4">
            {
              requestValue?.child_requests?.length > 0 ?
              <RequestStatement
              title={<IntlMessage id="admin.financial.details.requestID" />}
              value={requestValue?.child_requests}
              isArr={true}
              link_href = {`/admin/requests`}
              />
              :
              <RequestStatement
              title={<IntlMessage id="admin.financial.details.requestID" />}
              value={`#${requestValue?.prayer_request?.id}`}
              link_href = {`/admin/requests/${requestValue?.prayer_request?.id}`}
              title_href={ <IntlMessage id={"admin.financial.detail.view.request_details"} /> }  
              />
            }
            

            { 
              requestValue?.type !== 0 &&
              <RequestStatement
              title={ <IntlMessage id="admin.financial.details.client" /> } 
              value={requestValue?.prayer_request?.client_name}
              link_href = {`/admin/users/${requestValue?.prayer_request?.client_id}`}
              title_href={ <IntlMessage id={"admin.financial.detail.view.profile"} /> }    
            />
            }
            
            
            <RequestStatement
              title={<IntlMessage id="admin.financial.details.contribution" />}
              value={requestValue?.prayer_request?.contribution?.name} 
            />
    
            <RequestStatement
              title={<IntlMessage id="admin.financial.details.revenue" />}
              value={`${requestValue?.formatted_revenue}`}
            />

            {
              requestValue?.type !== 0 && 
              <RequestStatement
              title={<IntlMessage id="admin.financial.details.pp.percent" />} 
              value={`${requestValue?.fixed_percentage_label ? requestValue?.fixed_percentage_label : requestValue?.user.percentage_label}%`}
            />
            }
          </div>
        </>
      }
      
    </Container>
  );
};

export default FinancialDetailBuilder;
