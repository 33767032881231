import stripeActions from "@iso/actions/Stripe";

const initialState = {
  stripeTrigger: false,
  saveCard: false,
  token: null,
  cardToken: null,
  paymentIntentResponse: null,
  errors: {},
};
export default function stripe(state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case stripeActions.SET_STRIPE_ERRORS:
      Object.keys(payload.errors).forEach((key) => {
        if (!payload.errors[key]) {
          delete payload.errors[key];
        }
      });
      return {
        ...state,
        errors: payload.errors,
      };
    case stripeActions.TRIGGER_STRIPE:
      return {
        ...state,
        stripeTrigger: true,
        paymentIntentResponse: action.payload.paymentIntentResponse,
      };
    case stripeActions.SUBMITTING_STRIPE:
      return {
        ...state,
        loadingPayment: action.payload.flag,
      };
    case stripeActions.END_TRIGGER_STRIPE:
      return {
        ...state,
        stripeTrigger: false,
        paymentIntentResponse: null
      };
    case stripeActions.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case stripeActions.SET_CARD_TOKEN:
      return {
        ...state,
        cardToken: action.cardToken,
      };
    case stripeActions.SET_SAVE_CARD:
      return {
        ...state,
        saveCard: action.saveCard,
      };
    default:
      return state;
  }
}
