import React, { useState } from "react";
import { Card } from "react-bootstrap";

import IntlMessage from "@iso/components/intlMessage";
import "./styles.scss";
import { useDispatch } from "react-redux";
import CompletionDetails from "@iso/components/Request/CompletionDetails";

import RequestService from "@iso/services/RequestService";
import alertActions from "@iso/actions/Alert";
import {
  APPROVAL_ACTION,
  SHOW_DELETE_BUTTON_STATUS,
	SHOW_ARCHIVE_BUTTON_STATUS
} from "@iso/components/Request/constants";

import ConfirmModal from "@iso/components/Common/ConfirmModal";
import CompletedPrayerRequestModal from "@iso/components/Request/CompletedPrayerRequestModal";

import LinearProgress from "@material-ui/core/LinearProgress";
import RequestDisplayBuilder from "@iso/components/Request/RequestDisplayBuilder";
import RequestDetailsHeader from "@iso/components/Request/RequestDetailsHeader";
import RequestStatus from "@iso/components/Request/RequestStatus";
import HTTPRequests from "./utils";
import ActivityLog from "./ActivityLog";
import {DENY_CHANGE_ASSIGNEE_STATUS} from "../../../components/Request/constants";
import ChangeAssigneeModal from "./ChangeAssigneeModal";
const RequestDetailsPending = ({
  requestValue,
  forceShowTransactionContent,
  hideAction,
  showTransactionCallback,
  setRequestValue,
}) => {
  const { openAlert } = alertActions;
  const [showApproveButton, setShowApproveButton] = React.useState(true);
  const [openConfirmArchiveRequest, setOpenConfirmArchiveRequest] = React.useState(false);
  const [openConfirmDeleteRequest, setOpenConfirmDeleteRequest] = useState(
    false
  );
	
	const [showChangeAssigneeModal, setShowChangeAssigneeModal] = useState(false);
  const [request, setRequest] = useState(requestValue);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [openConfirmEditModal, setOpenConfirmEditModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [updateValues, setUpdateValues] = React.useState({});
  const [sendingRequest, setSendingRequest] = React.useState(false);

  const dispatch = useDispatch();
  const handleApprove = () => {
    setSendingRequest(true);
    try {
      RequestService.adminApprovalRequest({
        id: requestValue.id,
        action: APPROVAL_ACTION["approved"],
      }).then((res) => {
        const { code, data, message } = res.data;
        if (code === 200 && data) {
          setShowApproveButton(false);
          setOpenConfirmModal(false);
          setRequestValue(data);
          dispatch(
            openAlert({
              message: <IntlMessage id="common.message.update.success" />,
              severity: "success",
            })
          );
        } else {
          dispatch(
            openAlert({
              message: <IntlMessage id="common.message.update.fail" />,
              severity: "error",
            })
          );
        }
      });
    } catch (error) {
    } finally {
      setSendingRequest(false);
    }
  };

  const handleUpdateCompletionMessage = () => {
    setSendingRequest(true);
    setOpenConfirmEditModal(false);
    try {
      RequestService.adminUpdateCompletion({
        ...updateValues,
        id: requestValue.id,
      }).then((res) => {
        const { code, data, message } = res.data;
        if (code === 200 && data) {
          setOpenConfirmEditModal(false);
          setRequestValue({
            ...data,
            message_complete_translate: data.message_complete_translate,
            message_complete_edited: true
          });
          dispatch(
            openAlert({
              message: <IntlMessage id="common.message.update.success" />,
              severity: "success",
            })
          );
        } else {
          dispatch(
            openAlert({
              message: message || (
                <IntlMessage id="common.message.update.fail" />
              ),
              severity: "error",
            })
          );
        }
      });
    } catch (error) {
    } finally {
      setSendingRequest(false);
    }
  };

  const handleOpenConfirmEditModal = (values) => {
    setUpdateValues(values);
    setOpenConfirmEditModal(true);
    setOpenEditModal(false);
  };

  const handleCloseConfirmEditModal = () => {
    setOpenConfirmEditModal(false);
    setOpenEditModal(true);
  };

  const displayDeleteButton = SHOW_DELETE_BUTTON_STATUS.find(
    (status) => requestValue.status == status
  );
	
	const displayArchiveButton = SHOW_ARCHIVE_BUTTON_STATUS.find(
		(status) => requestValue.status == status
	);
	
  const handleTranslationResult = (_requestData) => {
    setRequestValue({...requestValue, ..._requestData});
    setRequest(_requestData);
    // setOpenEditModal(true);
  };
	
	const displayChangeAssigneeButton = !DENY_CHANGE_ASSIGNEE_STATUS.find(
		(status) => requestValue.status == status
	);

  React.useEffect(() => {
    setRequest(requestValue);
  }, [requestValue]);

  return (
    <>
      {sendingRequest && <LinearProgress />}
      {requestValue && (
        <Card className="p-3 height_100per completion-container">
          <RequestDetailsHeader
            request={requestValue}
            setRequest={setRequestValue}
            showApproveButton={showApproveButton}
            showEditButton={true}
            showDeleteButton={displayDeleteButton}
            showChangeAssigneeButton={
	            !hideAction && displayChangeAssigneeButton
            }
            showArchiveButton={displayArchiveButton}
            actionForChangeAssigneeButton={() =>
	            setShowChangeAssigneeModal(true)
            }
            actionForDeleteButton={() => setOpenConfirmDeleteRequest(true)}
            actionForArchiveButton={() => setOpenConfirmArchiveRequest(true)}
            actionForApproveButton={() => setOpenConfirmModal(true)}
            actionForEditButton={() => setOpenEditModal(true)}
            callTranslationResult={handleTranslationResult}
          />

          <RequestStatus request={request} />

          <CompletionDetails
            requestValue={requestValue}
            showTransactionCallback={showTransactionCallback}
            forceShowTransactionContent={forceShowTransactionContent}
            showEditModal={() => setOpenEditModal(true)}
            updateRequest={setRequestValue}
            callTranslationResult={handleTranslationResult}
          />

          <hr />
          <RequestDisplayBuilder
            request={request}
            showOriginContent={
              forceShowTransactionContent &&
              forceShowTransactionContent["reason"]
            }
            showTransactionCallback={showTransactionCallback}
            forceShowTransactionContent={forceShowTransactionContent}
          />

          <ActivityLog
            logs={requestValue?.logs}
          />
        </Card>
      )}
      <ConfirmModal
        open={openConfirmModal}
        handleConfirm={handleApprove}
        handleClose={() => setOpenConfirmModal(false)}
        title={
          <IntlMessage id="admin.requests.details.approve_request.confirm_modal.title" />
        }
        okText={<IntlMessage id="common.approve" />}
        cancelText={<IntlMessage id="common.cancel" />}
        content={
          <IntlMessage id="admin.requests.details.approve_request.confirm_modal.content" />
        }
      />

      <ConfirmModal
        open={openConfirmEditModal}
        handleConfirm={handleUpdateCompletionMessage}
        handleClose={handleCloseConfirmEditModal}
        title={
          <IntlMessage id="prayer_request.completion_modal.confirm_modal.title" />
        }
        okText={<IntlMessage id="common.ok" />}
        cancelText={<IntlMessage id="common.cancel" />}
        content={
          <IntlMessage id="prayer_request.completion_modal.confirm_modal.content" />
        }
        sendingRequest={sendingRequest}
      />
	
	    {
		    openEditModal && (<CompletedPrayerRequestModal
			    open={openEditModal}
			    handleClose={() => setOpenEditModal(false)}
			    handleSubmit={handleOpenConfirmEditModal}
			    request={request}
			    minDate=""
		    />)
			}
	
	    {showChangeAssigneeModal && (
		    <ChangeAssigneeModal
                showCloseButton={true}
			    showModal={showChangeAssigneeModal}
			    closeModal={() => setShowChangeAssigneeModal(false)}
			    requestId={requestValue.id}
			    request={requestValue}
			    handleSubmit={(values) =>
				    HTTPRequests.handleChangeAssignee({
					    values,
					    request: requestValue,
					    setShowChangeAssigneeModal,
					    setRequestValue,
				    })
			    }
		    />
	    )}

      {openConfirmDeleteRequest && (
        <ConfirmModal
          open={openConfirmDeleteRequest}
          handleConfirm={() =>
            HTTPRequests.handleDeleteRequest({
              requestValue,
              setOpenConfirmDeleteRequest,
            })
          }
          handleClose={() => setOpenConfirmDeleteRequest(false)}
          title={<IntlMessage id="confirm_modal.delete.title" />}
          content={<IntlMessage id="confirm_modal.delete.content" />}
          okText={<IntlMessage id="common.yes" />}
          cancelText={<IntlMessage id="common.no" />}
        />
      )}

      {openConfirmArchiveRequest && (
        <ConfirmModal
          open={openConfirmArchiveRequest}
          handleConfirm={() =>
            HTTPRequests.handleArchiveRequest({
              requestValue,
              setOpenConfirmArchiveRequest,
            })
          }
          handleClose={() => setOpenConfirmArchiveRequest(false)}
          title={<IntlMessage id="confirm_modal.archive.title" />}
          content={<IntlMessage id="confirm_modal.archive.content" />}
          okText={<IntlMessage id="common.yes" />}
          cancelText={<IntlMessage id="common.no" />}
        />
      )}
    </>
  );
};

export default RequestDetailsPending;
