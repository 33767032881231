import requestStepActions from "@iso/actions/RequestStepActions";
import moment from "moment";
import { getCookie, escapeObject} from "../helpers";

export const initialState = {
  // for_who: "",
  for_who: "formyself",
  is_gift: false,
  recipient_name: "",
  recipient_email: "",
  sender_name: "",
  client_name: "",
  client_email: "",
  gift_message_name: "",
  reason: "",
  id: null,
  prayer_customization: {
    date: null,
    special_request: "",
    religion: "",
    open_select_religion: false,
    prayer_type_id: null,
    message_tone: '',
  },
  contribution_id: "",
  prayer_contribution: {
    id: "",
    value: "",
    price: null,
    unit: "common.price.unit.dollar",
    description: "",
  },
  frequency: "",
  prayer_frequency: {
    id: "",
    code: "",
    name: "",
    value_number: "",
  },
  gift_message:
    "There are so many gifts to give in this world. But the gift of a personalized prayer, made on your behalf, felt most fitting to show you just how much I care.",
  gift_notification: true,
  is_save_card: false,
  step: 0,
  signup_first_name: '',
  signup_last_name: '',
  signup_password: '',
  signup_keep_me: '',
  is_request: false, 
  transaction_id: '',
  coupon_code: '',
};

export default function requestStepReducer(state = initialState, action) {
  const payload = action.payload;

  if (getCookie("requestStep")) {
      state = JSON.parse(getCookie("requestStep"))
      state = escapeObject(state, false)
  }
  switch (action.type) {
    case requestStepActions.SET_FORM_DATA:
      let data =  {
        ...state,
        ...payload.data,
      };
      data = escapeObject(data);
      document.cookie = `requestStep=${JSON.stringify(data)}; expires=0; path=/`;
      data = escapeObject(data, false);
      return data;
    case requestStepActions.RESET:
      return initialState;
    default:
      return state;
  }
}
