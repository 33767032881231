import React from "react" 
import FormPasswordExpired from "./FormPasswordExpired"
import CreateNewPassword from "@iso/Website/Pages/CreateNewPassword/CreateNewPassword"
import AuthService from "@iso/services/AuthService"
import PublicHeader from "@iso/Website/Layout/PublicHeader"
import { useLocation } from "react-router"
import * as qs from "query-string"

const ResetPassword = (props) => {
  const [expired, setExpired] = React.useState(false);
  const location = useLocation()
  const { user_id, token } = qs.parse(location.search)
  const intialValue = {
    user_id: user_id,
    token: token,
  }
  AuthService.expiredMailApi(intialValue).then((res) => {
    const { status } = res.data
    setExpired(status)
  })
  
   return (
    !expired ? <FormPasswordExpired /> :<CreateNewPassword /> 
  )
}

export default ResetPassword
