import React, {useEffect, useState} from "react";
import {Card, Col, Container, Form, Row, Button} from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import "./styles.scss";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'; 
import ReportsService from "@iso/services/ReportsService"
import moment from "moment";
import {KeyboardDatePicker } from "@material-ui/pickers";

import {MDBBadge, MDBDataTable} from "mdbreact";
import { history } from "@iso/helpers/history";
import alertActions from "@iso/actions/Alert"
import {useDispatch} from "react-redux";
const { openAlert } = alertActions

const RequestReports = (props) => {
  
  const dispatch = useDispatch();
  const [values, setValues] = useState() 
  const [tableLists, setTableList] = useState([]);
  const [showSelectFrom, setShowSelectFrom] = useState(false);
  const [showSelectTo, setShowSelectTo] = useState(false);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD")
  }
	const handleReportRequests = () => { 
    ReportsService.adminReportsPartnerByDate(formatDate(selectedDate?.fromDate?selectedDate?.fromDate:'1970-01-01'), 
    formatDate(selectedDate.toDate)).then((res) => {
			if (res) {
				const { code } = res.data;
				if (res && code === 200 ) {
          setValues(res.data)  
          const tableValues = formatTableDataPrayerPartner(res.data.data)
          setTableList(tableValues.data); 
          if (!tableValues) {
            const tbody = document.querySelector(
              "tbody[data-test=table-body] tr td"
            )
            if(tbody) tbody.innerText = `There is no reports.`
          }
				}
			}
    })
  };
  
  const [selectedDate, setSelectedDate] = useState({
    fromDate: null,
    toDate: new Date(),
  });

  const handleSubmit = () => {
    handleReportRequests();
  }
  const handleShowSelectFrom = () => {
    setShowSelectFrom(!showSelectFrom);
  };
  const handleShowSelectTo = () => {
    setShowSelectTo(!showSelectTo);
  };
  const handleDateChangeRaw = e => {
    e.preventDefault();
    return false;
  };

	useEffect(() => {
		handleReportRequests();
  }, []);

  const handleClickName = (id) => {
    history.push(`/admin/prayer_partners/${id}/account_settings`);
  }
  const formatTableDataPrayerPartner = (data) => {
    let dataRow = [];
    let header = [
      {
        label: <IntlMessage id="admin.reporting.pp.prayer_partner.table.title" />,
        field: "id",
        sort: "asc", 
      },
      {
        label: (
          <IntlMessage id="admin.reporting.pp.name.table.title" />
        ),
        field: "name",
        sort: "asc",   
      },
      {
        label: (
          <IntlMessage id="admin.reporting.pp.age.table.title" />
        ),
        field: "age",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.pp.language.table.title" />,
        field: "language",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.pp.country.table.title" />,
        field: "country",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.pp.completed_prayers.table.title" />,
        field: "completed_prayers",
        sort: "asc", 

      },
      {
        label: <IntlMessage id="admin.reporting.pp.accepted.table.title" />,
        field: "accepted",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.pp.no_response.table.title" />,
        field: "no_response",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.pp.late.table.title" />,
        field: "late",
        sort: "asc",
      },
      {
        label: <IntlMessage id="admin.reporting.pp.total_earnings.table.title" />,
        field: "total_earnings",
        sort: "asc",
      },
    ];
    
    data.forEach((e, index) => {
      dataRow.push({
        id: e.id,
        
        name: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.full_name ? e.full_name.toLocaleLowerCase() : ''}
          >
            <div onClick={() => handleClickName(e.id)}>{e.full_name}</div>
          </MDBBadge>
        ),
        age: e.age,
        language: e.language,
        country: e.country, 
        completed_prayers: e.complete_request,
        accepted: e.accepted_percent,
        no_response: e.no_response_percent,
        late: e.late_percent,
        total_earnings: '$' + e.total_earnings,
      });
    });
    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    };
  };

  const handleExportExcel = () => {
    ReportsService.adminExportReportsPartnerByDate(
      formatDate(selectedDate?.fromDate ? selectedDate?.fromDate : '1970-01-01'),
      formatDate(selectedDate.toDate)
    ).then((res) => {
      if (res.data.code === 200) {
        dispatch(
          openAlert({
            message: <IntlMessage id="admin.export.notification.success"/>,
            severity: "success",
          })
        );
      }
      else {
        dispatch(
          openAlert({
            message: <IntlMessage id="admin.export.notification.error"/>,
            severity: "error",
          })
        );
      }
    })
  }
	return (
		<div className="mint-tulip-background p-3 min-vh-100 reports_list">
      <Row className="my-2">
        <Col xs="12" sm="6" className="request_title">
          <h2>
            <IntlMessage id="admin.reporting.pp.title" />
          </h2>
        </Col>
      </Row>
			<Card className="card_swapper">
     
				<Container> 
				  <div> <IntlMessage id="admin.reporting.request.date.title" /> </div>
				{/* Date picker */}
          <Row className="mt-2"> 
            <Col xs="12" lg="3" className='picker-wrapper mb-3 mb-xs-0'> 
              <KeyboardDatePicker
                autoOk 
                allowKeyboardControl={false}
                inputProps={{readOnly: true}}
                disableToolbar
                inputVariant="outlined"
                fullWidth={true}
                className='my-picker' 
                open={showSelectFrom}
                onClick={handleShowSelectFrom}
                onClose={handleShowSelectFrom} 
                maxDate={moment(selectedDate.toDate).subtract(1, 'days')}

                label={<IntlMessage id="admin.reporting.request.date.from.title" />} 
                value={selectedDate?.fromDate ? selectedDate?.fromDate : null}
                format="MMM DD, yyyy"
                InputAdornmentProps={{ position: "end" }}
                onChange={date =>{ 
                    setSelectedDate({
                      ...selectedDate,
                      fromDate: date,
                    }) 
                }
                }
              />
            </Col>

            <Col xs="12" lg="3" className="picker-wrapper mb-3 mb-xs-0">
              <KeyboardDatePicker
                autoOk 
                allowKeyboardControl={false} 
                inputProps={{readOnly: true}}
                disableToolbar
                inputVariant="outlined"
                fullWidth={true}
                className='my-picker'
                open={showSelectTo}
                onClick={handleShowSelectTo}
                onClose={handleShowSelectTo} 
                maxDate={moment()}
                label={<IntlMessage id="admin.reporting.request.date.to.title" />} 
                value={selectedDate?.toDate ? selectedDate?.toDate : null }
                format="MMM DD, yyyy"
                InputAdornmentProps={{ position: "end" }}
                onChange={date =>{ 
                  setSelectedDate({
                    ...selectedDate,
                    toDate: date,  
                  }) 
                }
                }
                />
            </Col>

            <Col xs="12" lg="2" className="mb-3 mb-xs-0">
              <Button className="hippie-blue-button" type="button" onClick={handleSubmit}>
                <IntlMessage id="common.show" />
              </Button>
            </Col>
          </Row>
          {/* Request list */} 
        <div className="list-requests">
            <p><IntlMessage id ="admin.reporting.pp.count.title"/>
              <span className="unit-dollar">
                {values?.count}{/* {values.total} */}
              </span>  
            </p>
        </div>

          {/* export excel */}
          <div className="export-excel" onClick={handleExportExcel}>
            <ArrowUpwardIcon className="arrowUpward"/>
            <IntlMessage id="admin.reporting.request.export.excel.title" />
          </div>

          {/* Table */}

          <MDBDataTable
            hover  
            paging={true}
            id="table_lists_table"
            entries={25}
            searching={false}
            noBottomColumns={true}
            entriesOptions={[5, 20, 25]}
            sortRows={['name']}
            data={tableLists}
            className="prayer-table" 
          /> 
				</Container>  
			</Card>
		</div>
	);
};

export default RequestReports;
