import React from "react"
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl'
import AppLocale from './config/translation'
export default function I18nProvider({children}) {
	const messages = AppLocale.en;
	return(
		<IntlProvider messages={messages} locale="en" defaultLocale="en">
			{children}
		</IntlProvider>
	);
}