import axios from "axios";
import { userRoleConstants } from "@iso/constants";
import { apiEndpoints } from "@iso/helpers";

const loginApi = (body) => {
  return axios.post(apiEndpoints.login_path, body);
};

const loginSocialApi = (body) => {
  return axios.post(apiEndpoints.social_login_path, body);
};

const registerApi = (body) => {
  return axios.post(apiEndpoints.register_path, body);
};

const checkEmailExistsApi = (email) => {
  return axios.get(`${apiEndpoints.email_exists}?email=${email}`);
};

const subscription = (body) => {
  return axios.post('/subscription', body);
};

export const requestResetPasswordApi = (body) => {
  return axios.post("/password_reset/request", body);
};

export const resetPasswordApi = (body) => {
  return axios.post("/password_reset/reset", body);
};

export const contactApi = (body) => {
  return axios.post(apiEndpoints.contact_page, body);
};

export const subscribeApi = (body) => {
  return axios.post("/users/update_religion", body);
};
export const loginAs = ({ role = "pp", id }) => {
  switch (role) {
    case "pp":
    case userRoleConstants.PRAYER_ROLE:
      return axios.put(`admin/prayer_partners/${id}/login`);
    case userRoleConstants.USER_ROLE:
      return axios.put(`admin/users/${id}/login`);
    default:
      break;
  }
};
export const deletePrayerPartner = (id) => {
  return axios.delete(`admin/prayer_partners/${id}`);
};

export const expiredMailApi = (body) => {
  return axios.post("/password_reset/check_token_expired", body);
}
export const adminDeleteUser = (id) => {
  return axios.delete(`admin/users/${id}`);
};
export const adminDisableAdmin = (id) => {
  return axios.put(`admins/${id}/disable`);
};
export const verifyUser = (token, user_id) => {
  return axios.get(`/verify`, {
    params: {
      user_id,
      token
    },
  });
}

export const checkBanIPAddress = () => {
  return axios.get(`/check_ip_address`);
}


export default {
  loginApi,
  loginSocialApi,
  requestResetPasswordApi,
  registerApi,
  resetPasswordApi,
  subscribeApi,
  loginAs,
  deletePrayerPartner,
  expiredMailApi,
  adminDeleteUser,
  adminDisableAdmin,
  contactApi,
  verifyUser,
  checkBanIPAddress,
  subscription,
  checkEmailExistsApi,
}
