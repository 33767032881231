import React, { Fragment } from "react";

const PrayerPublicLayout = (props) => {
  const Component = props.component;
  return (
    <Fragment>
      <div className="app-main">
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Component />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PrayerPublicLayout;