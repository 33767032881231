export const COLOR_CODE = {
  unassigned: "#ff9900",
  incomplete: "#6aa84f",
  declined: " #999999",
  paused: " #999999",
  overdue: "#990000",
  overdue_done: "#ff9900",
  overdue_done_approved: "#009e0f",
  approved: "#009e0f",
  completed: "#999999",
};

export const DATE_FORMAT = "MMMM Do, YYYY"; 

export const CATEGORIES = [
  { id: 'user', label: 'I`m a Prayer Cloud user'},
  { id: 'interested', label: 'I`m interested in using Prayer Cloud'},
  { id: 'learn', label: 'I want to learn more about Prayer Cloud'},
  { id: 'partner', label: 'I`m a Prayer Partner'},
  { id: 'to_be_partner', label: 'I want to be a Prayer Partner'},
  { id: 'other', label: 'Other'},
  { id: "payments", label: "Payments" },
  { id: "availability", label: "Availability" },
  { id: "technical", label: "Technical" },
  { id: "prayer_support", label: "Prayer Support" }, 
];


export const STATUS = [
  {id: 'outstanding', label: 'Outstanding'},
  {id: 'paid', label: 'Paid'},
]

export const STATUS_USER = [
  {id: 'received', label: 'Received'},
  {id: 'refunded', label: 'Refunded'},
]