import { Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    font-family: BrandonText;
    padding:  20px;

    @media only screen and (max-width: 900px) {
        padding: 60px 30px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 38px 35px;
        padding-right: 45px;
        padding-bottom: 0;
    }
    @media only screen and (max-width: 500px) {
        padding-top: 40px;
        padding-left: 47px;
        padding-right: 47px;
    }
`

const Icon = styled.img`
	width: 50%;
	min-height: 55px;
	@media only screen and (max-width: 375px) {
	  min-height: 50px;
	  height: 50px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px){
        width: 50%;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        min-height: 50px;
        height: 57px;
        margin-left: -23px
    }
`

const Title = styled.p`
    color: #000;
    margin-top: 42px;
    font-weight: bold;
    font-size: 22px;
    @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 0!important;
        min-height: auto!important;
        margin-top: 20px;
    }
    min-height: 34px;
    @media(max-width: 500px) {
        min-height: 34px;
        margin: 20px 0 6px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        font-size: 25px;
        margin-bottom: 14px;
    }
`


const Description = styled.p`
    margin-top: 8px;
    font-size: 18px;
    line-height: 32px;
    @media only screen and (max-width: 375px) {
        font-size: 16px;
        margin-top: -5px;
        width: 303px;
        color: #000000;
        padding-right: 45px;
        line-height: 24px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        font-size: 18px;
        margin-top: -5px;
        width: 310px;
        color: #000000;
        padding-right: 30px;
        line-height: 24px;
    }
`

const HowItWorksItem = props => {
    const {icon, title, description, style} = props;
    return <Wrapper>
        <Icon isContributionIcon={title.props.id.includes('item3')} src={icon}></Icon>
        <Title className="style_title" >{title}</Title>
        <Description style={style}>{description}</Description>
    </Wrapper>
}


export default HowItWorksItem
