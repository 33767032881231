import React from "react";
import SaveStripeCard from "@iso/components/Stripe/SaveCard";
import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";

import PaymentCard from "@iso/components/Share/Payment/PaymentCard";
import PaymentCardFooter from "@iso/components/Share/Payment/PaymentCardFooter";
import { makeStyles } from "@material-ui/core/styles";
import { Row } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "22px",
    [theme.breakpoints.up('lg')]: {
      maxWidth: "241px",
      marginRight: "25px",
    }
  },
}));

const PaymentCardItem = (props) => {
  const {
    item,
    defaultCard,
    setDefaultCard,
    setOpenConfirmDeleteModal,
    setSelectedCard,
    allowDelete = true,
    allowSetDefaultCard = true,
  } = props;

  const classes = useStyles();

  const handleSetDefaultCard = ({ card_id }) => {
    if (!allowSetDefaultCard) return;

    UserService.setDefaultStripeCard({ card_id }).then((res) => {
      ProcessRequest.run({
        res,
        successCallback: (data) => {
          setDefaultCard({ card_id });
        },
      });
    });
  };

  return (
    <Row className={`${classes.root} ml-0 align-items-center`}>
      <PaymentCard
        cardType={item?.card.brand}
        exp={`${item?.card?.exp_month}/${item?.card?.exp_year}`}
        name={item?.billing_details?.name}
        lastDigit={item?.card?.last4}
        isPrefer={defaultCard?.card_id == item?.id}
        id={item?.id}
        setDefaultCard={handleSetDefaultCard}
      />
      <PaymentCardFooter
        handleDelete={() => {
          setSelectedCard(item);
          setOpenConfirmDeleteModal(true);
        }}
        lastDigit={item?.card?.last4}
        cardType={item?.card.brand}
        isPrefer={defaultCard?.card_id == item?.id}
        allowDelete={allowDelete}
      />
    </Row>
  );
};

export default PaymentCardItem;
