import * as Yup from "yup";
import {
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  TAG_MIN_LENGHT,
  TAG_MAX_LENGHT,
} from "./constants.js";
import axios from "axios";

const errorTaglineLengthMessage = `Your tagline must contain ${TAG_MIN_LENGHT} to ${TAG_MAX_LENGHT} characters.`;

const ProfileInfoFormValidator = Yup.object().shape({
  first_name: Yup.string()
    .label("First name")
    .trim()
    .required()
    .test(
      "minLength",
      "First name must contain from 2 to 20 characters.",
      (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
      "maxLength",
      "First name must contain from 2 to 20 characters.",
      (val) => val && val.length <= NAME_MAX_LENGTH
    )
    .matches(/^\S+(?: \S+)*$/, "Invalid input. Please try again.")
    .matches(
      /^[^`~!@#$%^&*/()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/,
      "Invalid input. Please try again."
    ),
  last_name: Yup.string()
    .label("Last name")
    .trim()
    .required()
    .test(
      "minLength",
      "Last name must contain from 2 to 20 characters.",
      (val) => val && val.length >= NAME_MIN_LENGTH
    )
    .test(
      "maxLength",
      "Last name must contain from 2 to 20 characters.",
      (val) => val && val.length <= NAME_MAX_LENGTH
    )
    .matches(/^\S+(?: \S+)*$/, "Invalid input. Please try again.")
    .matches(
      /^[^`~!@#$%^&*/()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/,
      "Invalid input. Please try again."
    ),
  phone_number: Yup.number()
    .typeError("Phone Number must be a number")
    .label("Phone Number")
    .required()
    .test("checkDupPhone", "Phone number is already taken", function (value) {
      return new Promise((resolve, reject) => {
        return axios
          .get("/users/check_phone_exist", { params: { phone_number: value } })
          .then((res) => {
            if (res.data && res.data.is_exist) resolve(false);
            else resolve(true);
          })
          .catch(() => {
            // note exists
            resolve(true);
          });
      });
    }),

  tagline: Yup.string()
    .label("Tagline")
    .trim()
    .required()
    .min(TAG_MIN_LENGHT, function () {
      return errorTaglineLengthMessage;
    })
    .max(TAG_MAX_LENGHT, function () {
      return errorTaglineLengthMessage;
    })
    .matches(/\w*[a-zA-Z]\w*/, "Invalid input. Please try again."),
});

export { ProfileInfoFormValidator };
