import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";

const theme = createMuiTheme({
  status: {
    inProgress: "#F26C51",
    completed: "#499E9E",
  },
  palette: {
    primary: {
      main: "#499E9E",
    },
    secondary: {
      main: "#F26C51",
    },
    success: {
      main: "#52B9D4",
    },
  },
});

export default theme;
