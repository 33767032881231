import axios from "axios";
import { apiEndpoints } from "@iso/helpers";

const updateProfile = (body) => {
  return axios.post(`${apiEndpoints.user_path}/update_profile`, body);
};

const getProfile = () => {
  return axios.get(`${apiEndpoints.user_path}/profile`);
};

const getRequests = (page) => {
  page = page || 1;
  return axios.get(`${apiEndpoints.user_path}/requests?page=${page}`);
};

const getRequestsByStatus = (status) => {
  return axios.get(`${apiEndpoints.user_path}/requests`,status);
};

const getRequestsByID = (id) => {
  return axios.get(`${apiEndpoints.user_path}/request/${id}`);
};

const changePassword = (body) => {
  return axios.post(`${apiEndpoints.user_path}/change_password`, body);
};
const addCardToStripe = (body) => {
  return axios.post(`${apiEndpoints.user_path}/stripe/cards`, body);
};
const getAllSavedCards = () => {
  return axios.get(`${apiEndpoints.user_path}/stripe/cards`);
};
const setDefaultStripeCard = ({ card_id }) => {
  return axios.put(`${apiEndpoints.user_path}/stripe/cards/${card_id}/default`);
};
const getStripeCustomer = () => {
  return axios.get(`${apiEndpoints.user_path}/stripe/customer`);
};
const detachCard = ({ id }) => {
  return axios.delete(`${apiEndpoints.user_path}/stripe/cards/${id}`);
};
const deactivateAccount = (body) => {
  return axios.post(`${apiEndpoints.user_path}/deactivate_account`, body);
};

const adminGerUsers = (status, options = {}) => {
  return axios.get(apiEndpoints.admin_users, {
    params: {
      status: status,
      ...options,
    },
  });
};

const adminSendMailVerify = (id) => {
  return axios.get(`${apiEndpoints.admin_users}/${id}/send-verify`);
};

const adminGetUserDetails = (id) => {
  return axios.get(`${apiEndpoints.admin_users}/${id}/show`);
};

const adminGetUserStripeCards = (id) => {
  return axios.get(`${apiEndpoints.admin_users}/${id}/get_card_stripe`);
};

const adminGetStripeCustomer = (id) => {
  return axios.get(`${apiEndpoints.admin_users}/${id}/get_customer_stripe`);
};

const adminGetUserRequests = (id) => {
  return axios.get(`${apiEndpoints.admin_users}/${id}/requests`);
};

const adminUpdateUserProfile = (body) => {
  return axios.put(`${apiEndpoints.admin_users}/${body.id}/update`, body);
};

const adminResetUserPassword = (id) => {
  return axios.put(`${apiEndpoints.admin_users}/${id}/reset_password`);
};

const UserService = {
  updateProfile,
  changePassword,
  getRequests,
  addCardToStripe,
  getAllSavedCards,
  detachCard,
  deactivateAccount,
  setDefaultStripeCard,
  getStripeCustomer,
  getProfile,
  
  adminSendMailVerify,
  adminGerUsers,
  adminGetUserDetails,
  adminGetUserStripeCards,
  adminGetStripeCustomer,
  adminGetUserRequests,
  adminUpdateUserProfile,
  adminResetUserPassword,
  getRequestsByStatus,
  getRequestsByID,
};

export default UserService;
