import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from '@material-ui/icons/Pause';
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from "@material-ui/core/Typography";

import IntlMessage from "@iso/components/intlMessage";
import RequestService from "../../services/RequestService";
import { useSelector } from "react-redux";
import { userRoleConstants } from "../../constants";
import { store } from "@iso/store/root";
import alertActions from "@iso/actions/Alert";
const { openAlert } = alertActions;

const TogglePauseButton = ({ actionForTogglePauseButton, status }) => {
  const IconPlayPause = ({ status }) => {
    switch (status) {
      case "paused":
        return <PlayArrowIcon className="pr-1 pb-1" />;
      default:
        return <PauseIcon className="pr-1 pb-1" />;
    }
  };

  return (
    <Button
      className="lighter-pink-color app_btn text-dark mx-1"
      onClick={actionForTogglePauseButton}
    >
      <IconPlayPause status={status} />
      <IntlMessage
        id={`admin.requests.request_details_card.toggle_pause_button.${status}`}
      />
    </Button>
  );
};

const RequestDetailsHeader = ({
  request,
  setRequest,
  showApproveButton,
  showEditButton,
  showDeleteButton,
  showArchiveButton,
  showChangeAssigneeButton,
  showTogglePauseButton,

  actionForApproveButton,
  actionForChangeAssigneeButton,
  actionForTogglePauseButton,
  actionForEditButton,
  actionForDeleteButton,
  actionForArchiveButton,
  callTranslationResult
}) => {
  const [isTranslating, setTranslating] = React.useState(false);
  const [requestValue, setRequestValue] = React.useState(request);
  const isShowNoticeWrongEmail = request.is_gift && request.recipient === "for_someone" ? true : false
  const user = useSelector((state) => state.authentication.user);
  const isSuperAdmin = user?.role === userRoleConstants.SUPER_ADMIN_ROLE;
  const handleTranslate = async () => {
    setTranslating(true);
    try {
      const { data } = await RequestService.adminTranslateRequest({ id: requestValue.id });
      let requestData = {
        ...requestValue,
        ...data.data
      };
      setRequestValue(requestData)
      setRequest(requestData)
      if (callTranslationResult) callTranslationResult(data.data);
    } catch (err) {
      // TODO
    } finally {
      setTranslating(false);
    }
  };
  useEffect(() => {
    console.log(request);
  }, [])
  const showButtonTranslate = () => {
    return (requestValue?.special_request && !requestValue?.special_request_translate)
      || (requestValue?.reason && !requestValue?.reason_translate)
      || (requestValue?.message_complete && !requestValue?.message_complete_translate);
  }
  const noticeWrongEmail = () => {
    RequestService.noticeWrongEmail(requestValue.id).then((res) => {
      const { code, message } = res.data;
      if (code === 200) {
        store.dispatch(
          openAlert({
            message: <IntlMessage id="common.message.update.success" />,
            severity: "success",
          })
        );
      } else {
        store.dispatch(
          openAlert({
            message: message,
            severity: "error",
          })
        );
      }
    });
  }

  return (
    <Row>
      <Col xs="12" sm="6" md="5">
        <div className="d-flex">
          <h3 className="font-weight-bold">
            { request.recipient == 'for_someone' 
              ? (request.client_name !== 'Guest User' 
                  ? (request.is_gift) 
                      ? (`${request.client_name }'s`) 
                      : (`${request.client_name }'s`) 
                  : (request.is_gift) 
                      ? (`${request.client_name + ' (' + request.sender_name }'s)`) 
                      : (`${request.client_name + ' (' + ((request.sender_name !== null) ? request.sender_name : request.recipient_name) }'s)`)) + ' Prayer Request'
              : (request.client_name !== 'Guest User' 
                  ? (request.client_id === null) 
                      ? `${request.recipient_name}'s` 
                      : `${request.client_name}'s` 
                  : (`${request.client_name + ' (' + request.recipient_name }'s)`)) + ' Prayer Request'
            }
          </h3>
          {
            <Button
              size="md"
              className="hippie-blue-button my-auto ml-3"
              style={{ maxHeight: '33px', paddingBottom: '30px' }}
              disabled={!showButtonTranslate()}
              onClick={showButtonTranslate() ? handleTranslate : () => {
              }}
            >
              {isTranslating ? "Translating..." : 'Translate'}
            </Button>
          }
        </div>
      </Col>

      <Col xs="12" sm="6" md="7" className="text-center text-md-right mb-3 group-button-header-request">
        {showTogglePauseButton && actionForTogglePauseButton && !request.is_archived && (
          <TogglePauseButton
            status={request.status}
            actionForTogglePauseButton={actionForTogglePauseButton}
          />
        )}

        {showApproveButton && actionForApproveButton && !request.is_archived && (
          <Button
            className="hippie-blue-button app_btn mx-1"
            onClick={actionForApproveButton}
          >
            <ThumbUpIcon className="pr-1 pb-1" />
            <IntlMessage id="admin.requests.details.pending.approve.text" />
          </Button>
        )}

        {showEditButton && actionForEditButton && !request.is_archived && (
          <Button
            className="light-gray-button app_btn mx-1"
            onClick={actionForEditButton}
          >
            <EditIcon className="pr-1 pb-1" />
            <IntlMessage id="common.edit" />
          </Button>
        )}

        {showDeleteButton && actionForDeleteButton && isSuperAdmin && !request.is_archived && (
          <Button
            className="light-gray-button app_btn mx-1"
            onClick={actionForDeleteButton}
          >
            <DeleteIcon className="pr-1 pb-1" />
            <IntlMessage id="common.delete" />
          </Button>
        )}

        {showArchiveButton && actionForArchiveButton && !request.is_archived && (
          <Button
            className="light-gray-button app_btn mx-1"
            onClick={actionForArchiveButton}
          >
            <IntlMessage id="common.archived" />
          </Button>
        )}

        {showChangeAssigneeButton && actionForChangeAssigneeButton && !request.is_archived && (
          <Button
            className="hippie-blue-button app_btn mx-1"
            onClick={actionForChangeAssigneeButton}
          >
            <Typography variant="body2" noWrap>
              <IntlMessage id="admin.requests.details.change_assignee" />
            </Typography>
          </Button>
        )}
        {isShowNoticeWrongEmail && (
          <Button
            id='notice_wrong_email_btn'
            className="hippie-blue-button w-[150px] mt-1 ml-3 lighter-pink-color"
            style={{ color: 'black' }}
            onClick={(e) => {
              e.preventDefault()
              noticeWrongEmail()
            }}
          >
            <Typography variant="body2" noWrap>
              Notice wrong email
            </Typography>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default RequestDetailsHeader;
