import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from "react-async-script-loader";

const PaypalCheckout = (props) => {
  const env = `${process.env.REACT_APP_PAYPAL_MODE}`;
  const client = {
    "`${env}`": `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`
  };

  const createOrder = (data, actions) => {
    const description = props.requestId ? `Prayer Request - Payment #${props.requestId}` : ''
    return actions.order.create({
      purchase_units: [
        {
          description: description,
          amount: {
            currency_code: "USD",
            value: Math.round(props.price * 100) / 100
          }
        }
      ]
    });
  };

  const onApprove = (data, actions) => {
    actions.order.capture().then(details => {
      const paymentData = {
        orderID: data.orderID,
        payment_method: "paypal"
      };
      onAuthorize(paymentData);
    });
  };

  const onAuthorize = (payment) => {
    props.afterAuthorize(payment);
  }

  const PayPalButton = window.paypal.Buttons.driver('react',{React,ReactDOM})

  return(
    <PayPalButton env={env}
      client={client}
      createOrder={createOrder}
      commit={true} //to show pay now button after login to paypal account
      onApprove={onApprove}
      style={{
        layout: 'horizontal',
        tagline: 'false',
        color: 'white',
        height: 30,
      }}
      />
  );
}

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`)(PaypalCheckout);
