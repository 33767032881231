import axios from "axios";
import {apiEndpoints} from "@iso/helpers";

export const getNotiList = (page) => {
	return axios.get(`${apiEndpoints.pp_notification}?page_size=10&page=${page}`);
};

export const clearAllNoti = () => {
	return axios.delete(`${apiEndpoints.pp_notification}`);
};

export const updateNotiField = (id) => {
	return axios.get(`${apiEndpoints.pp_notification}/${id}/read`);
};

export const getUnreadNoti = (id) => {
	return axios.get(`${apiEndpoints.pp_notification}/total_unread`);
}

export default {
	getNotiList,
	clearAllNoti,
	updateNotiField,
	getUnreadNoti
};
