import React from "react";
import ImgThankyou from "@iso/assets/images/thankyou.svg";
import ImgThankyouSmall from "@iso/assets/images/thankyou-small.svg";
import ImgThankyouTablet from "@iso/assets/images/thankyou_tablet.svg";
import { isTablet } from "react-device-detect";

import { Row, Col } from "react-bootstrap";
// import "./TitleAdapter.scss";
const Title = (props) => {

    return (
      <Row className={`flex-column ${isTablet && 'align-items-start'}`}>
        <img src={ImgThankyou} className="title_image d-none d-lg-block align-self-start img-thanks" />
        <img src={isTablet ? ImgThankyouTablet : ImgThankyouSmall} className={`title_image d-lg-none ${!isTablet && 'align-self-center '}`} />
      </Row>
    );
};

export default Title;
