import React from "react";
import { Link } from "react-router-dom";
import IntlMessage from "@iso/components/intlMessage";
import { TextField } from "@material-ui/core";
import { Button, Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import "./LeftPart.scss";
import GiftMessage from "./components/GiftMessage";
import RequestDetails from "./components/RequestDetails";
import CustomizationDetails from "./components/CustomizationDetails";
import ContributionDetails from "./components/ContributionDetails";
import { initialState } from "@iso/reducers/request_step.reducer";
import { areEqualShallow } from "@iso/utils/ShallowEqual";
import { isTablet } from "react-device-detect";

const LeftPart = ({
  formikValues,
  handleChange,
  setFormikValues,
  touched,
  requestErrors,
}) => {
  const isCustomizationUnchanged = () => {
    return areEqualShallow(
      formikValues.prayer_customization,
      initialState.prayer_customization
    );
  };

  const renderCustomization =
    formikValues?.prayer_customization &&
    Object.keys(formikValues?.prayer_customization).length > 0 &&
    !isCustomizationUnchanged();

  const tabletHasCustomization = () => {
    let customization = formikValues.prayer_customization;
    //hide customization widget in tablet (For myself, no gift, no customization)
    if (isTablet) {
      return !(
        formikValues.for_who === "formyself" &&
        !customization.date &&
        !customization.prayer_type_id &&
        customization.religion === ""
      );
    }
    return true;
  };

  const [giftMessageStepOne, setgiftMessageStepOne] = React.useState(0);
  const [isForWho, setForWho] = React.useState(formikValues.for_who);
  const [isGift, setGift] = React.useState(formikValues.is_gift); // for case renderGiftMessage false

  React.useEffect(() => {
    //if giftMessageStepOne === 1 show gift message in step 1
    setgiftMessageStepOne(giftMessageStepOne+1);
  }, [isForWho, isGift]);

  return (
    <>
      <Row id="left-part-wrapper-step3">
        <Col
          lg={isTablet ? { span: 12, offset: 0 } : { span: 10, offset: 1 }}
          md={isTablet ? { span: 12, offset: 0 } : { span: 10, offset: 2 }}
          xs={{ span: 12, offset: 0 }}
          className="p-0"
        >
          <Link
            to="/requests/step2"
            className="text-hippie-blue back__button pl-5 pt-1"
          >
            {"< Back"}
          </Link>
          <h2 className="text-left pl-4 pl-md-5 ml-3 ml-md-0 mb-md-0 my-title mt-3">
            <IntlMessage id="public.request.step3.left.title" />
          </h2>

          <div className="px-4 pt-2">
            <Row>
              <Col xs={12} className="p-3 pl-md-4 left-part-container">
                {isGift && isForWho === "forsomeoneelse" && giftMessageStepOne === 1 && (
                  <GiftMessage
                    formikValues={formikValues}
                    setFormikValues={setFormikValues}
                    requestErrors={requestErrors}
                  />
                )}

                <RequestDetails
                  formikValues={formikValues}
                  setFormikValues={setFormikValues}
                  requestErrors={requestErrors}
                  setGift={setGift}
                  setForWho={setForWho}
                  className={(isGift && isForWho === "forsomeoneelse" && giftMessageStepOne === 1) && 'custom_request_detail_is_gift_top'}
                />

                {(giftMessageStepOne !== 1 && isGift && isForWho === "forsomeoneelse") &&(
                  <GiftMessage
                    formikValues={formikValues}
                    setFormikValues={setFormikValues}
                    requestErrors={requestErrors}
                    className='custom_margin_gift_message'
                  />
                )}

                {tabletHasCustomization() && (
                  <CustomizationDetails
                    formikValues={formikValues}
                    setFormikValues={setFormikValues}
                    requestErrors={requestErrors}
                  />
                )}

                <ContributionDetails
                  formikValues={formikValues}
                  setFormikValues={setFormikValues}
                  requestErrors={requestErrors}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LeftPart;
