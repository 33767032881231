import * as Yup from "yup";

const ForgotPasswordFormValidator = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .email("Please enter a correct email!")
    .required("Please enter an email address!"),
});

export { ForgotPasswordFormValidator };
