import * as Yup from "yup"

const ResponseValidator = Yup.object().shape({
  message_response: Yup.string()
    .label("Response")
    .trim()
    .required()
})

export { ResponseValidator }
