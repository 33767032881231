import React from "react"
import RegistrationBox from "@iso/Pages/Common/RegistrationBox/RegistrationBox"
import ForgotPasswordForm from "@iso/components/ForgotPassword/Form"

const ForgotPassword = () => {
  return (
    <div className="prayer-portal sign-up-container">
      <RegistrationBox
        title="Forgot password"
        form={<ForgotPasswordForm loginURL="/prayer_partners/sign_in" />}
      />
    </div>
  )
}

export default ForgotPassword
