import { Typography } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";
import OurValueClouds from "@iso/assets/images/landing_value_clouds.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import requestStepActions from "@iso/actions/RequestStepActions";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  font-family: BrandonText;
  padding: 18px;
  position: relative;
  color: #000000;
  align-items: center;
  @media only screen and (min-width: 1440px) {
    padding: 13px;
  }

  @media only screen and (max-width: 375px){
    margin-bottom: -40px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    margin-bottom: -40px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Icon = styled.img`
width: 56px;
height: 56px;
@media only screen and (min-width: 376px) and (max-width: 500px){
  width: 62px;
  height: 62px;
}
`;

const Title = styled.p`
  margin-top: 12px;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  @media only screen and (max-width: 500px){
    width: 120px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    font-size: 18px;
    width: 150px;
  }
`;

const PrayerItem = (props) => {
  const { icon, title, index } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const requestStepData = useSelector((state) => state.requestStepReducer);
  const handleClickItem = (prayer_type_id) => {
    dispatch(
      requestStepActions.setDataStep({
        ...requestStepData,
        prayer_customization: {
          ...requestStepData.prayer_customization,
          prayer_type_id: prayer_type_id,
        },
      })
    );

    history.push("/requests/step1");
    // props.setShow(true)
  };

  return (
    <Wrapper onClick={() => handleClickItem(index)} className="hover_pointer" >
      <Icon index={index} src={icon}></Icon>
      <Title>{title}</Title>
    </Wrapper>
  );
};

export default PrayerItem;
