import React from "react";

import { Modal, Button } from "react-bootstrap";
import "./styles.scss";

const StripeConfirmModal = ({ open }) => {
  return (
    <Modal show={open} className="stripe-confirm-modal">
      <Modal.Body>
        <div id="confirm-stripe-modal" style={{ overflow: "auto" }}></div>
      </Modal.Body>
    </Modal>
  );
};

export default StripeConfirmModal;
