export const CATEGORIES = [
  { id: "payments", name: "Payments" },
  { id: "availability", name: "Availability" },
  { id: "technical", name: "Technical" },
  { id: "prayer_support", name: "Prayer Support" },
  { id: "other", name: "Other" },
];

export const MIN_MESSAGE_LENGTH = 200;
export const MAX_MESSAGE_LENGTH = 800;
