import { TAB_MENU } from "@iso/Website/Pages/Account/constants"
import Welcome from "@iso/Website/Pages/Home/Welcome"
import Step1 from "@iso/Website/Pages/Request/step1/Index"
import Step2 from "@iso/Website/Pages/Request/step2/Index"
import Step3 from "@iso/Website/Pages/Request/step3/Index"
import Step4 from "@iso/Website/Pages/Request/step4/Index"
import HomeLogin from "@iso/Website/Pages/Home/HomeLogin"
import ContactUs from "@iso/Website/Pages/ContactUs/main"

export default {
  HomeLogin: {
    component: HomeLogin,
    path: "/homelogin",
  },
  Step1: {
    component: Step1,
    path: "/requests/step1",
  },

  Step2: {
    component: Step2,
    path: "/requests/step2",
  },

  Step3: {
    component: Step3,
    path: "/requests/step3",
  },

  Step4: {
    component: Step4,
    path: "/requests/step4",
  },

  ContactUs: {
    component: ContactUs,
    path: "/contact_us",
  },
}
