import welcomePrayerCloudActions from '@iso/actions/WelcomePrayerCloudActions'

const initialState = {
  isOpen: false,
  isSubcribe: false,
  isHeader: false,
};

export default function welcomePrayerCloudReducer(state = initialState, action) {

  switch (action.type) {
    case welcomePrayerCloudActions.SHOW_PRAYER_CLOUD_MODAL:
      return { ...state, ...action.data.payload}
    case welcomePrayerCloudActions.HIDE_PRAYER_CLOUD_MODAL:
      return initialState;
    default:
      return initialState;
  }
}
