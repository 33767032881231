import { Grid } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import LeftSide from './LeftSide'
import RightSide from './RightSide'
import {isTablet, isMobile} from "react-device-detect";
import "./index.scss"

const Wrapper = styled(Grid)` 
    padding: 60px 100px 20px;
    @media only screen and (max-width: 700px){
        padding: 30px 37px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
      padding-bottom: 39px;
  }
`

const Section8 = props => {
  return <Wrapper container id="section8" spacing={3}>

    {
      isMobile && !isTablet
        ?
        (
          <>
            {/* Mobile */}
            <Grid item xs={12} className="d-flex justify-content-center">
              <RightSide></RightSide>
            </Grid>
            <Grid className="section8-left-side" item xs={12} sm={isTablet ? 6 : 12} md={8}>
              <LeftSide className="ml-1"></LeftSide>
            </Grid>
          </>
        )
        :
        (
          <>
            {/* Browser and Tablet */}
            <Grid className="section8-left-side" item xs={12} sm={isTablet ? 6 : 12} md={8}>
              <LeftSide className="ml-1"></LeftSide>
            </Grid>


            <Grid className="section8-right-side" item xs={12} sm={isTablet ? 6 : 12} md={4}>
              <RightSide></RightSide>
            </Grid>
          </>
        )
    }

  </Wrapper>
}

export default Section8