import "./bootstrap.js";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import HorizontalApp from './HorizontalApp'
import ErrorBoundary from './ErrorBoundary'
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { getToken, removeToken, clearUserData } from "./utils/TokenService";
import {Provider, shallowEqual, useSelector} from "react-redux";
import { store } from "./store/root";
import I18nProvider from "./I18nProvider";
import Alert from "@iso/components/Alert";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { logoutUser } from "@iso/actions/User";
import { getUser } from "@iso/actions/User";
import { userRoleConstants } from "@iso/constants"
import { isMobile, isMobileOnly } from "react-device-detect";
import alertActions from "@iso/actions/Alert"; 
import moment from "moment";
const { openAlert } = alertActions;
moment.locale('en');
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.interceptors.request.use((config) => {
  const reconfig = config;
  reconfig.headers["Content-Type"] =
    reconfig.headers["Content-Type"] || "application/json";

  const token = getToken({
    remoteLogin: sessionStorage.getItem("remoteLogin") == "true",
  });

  if (token && !config.notUserToken) {
    reconfig.headers.authorization = `Bearer ${token}`;
  }
  return reconfig;
});
const user = getUser();

axios.interceptors.response.use(
  (response) => {
    if (response.data.code === 403) {
      clearUserData();
      if (window.location.pathname !== '/') {
        window.location.reload();
      }
    }
    if (response.data.code === 401) {
      if (window.location.pathname !== '/' && window.location.pathname !== '/requests/step3') {
        removeToken();
        if (sessionStorage.getItem("remoteLogin") == "true") {
          sessionStorage.removeItem('user');
          sessionStorage.removeItem('token');
          window.location.reload();
        }
        else {
          logoutUser();
        }
      }
      if (user?.role === userRoleConstants.ADMIN_ROLE) {
        window.location = "/404";
      }
    }

    return response;
  },
  (error) => {
    if (error.response && error.response.status) {
      if (error.response.status === 401) {
        removeToken();
        logoutUser();
        window.location.reload();
      }
      if (error.response.status === 429){
        store.dispatch(
          openAlert({
            message: "Server is busy, please try again later!",
            severity: "error",
          }))
      }
    }
    return Promise.reject(error);
  }
);

let resizetimeout = null;

const changeWindowSize = () => {
  let menuElement = document.getElementById("mobileHeader-container");


  const isAdminPath = window.location.pathname.match(/\/admin/)
  const isPrayerPartnerPath = window.location.pathname.match(/\/prayer_partners/)
  const isPrayerPartnerRequestPath = window.location.pathname.match(/\/prayer_requests/)
  const disableRotate = isAdminPath || isPrayerPartnerPath || isPrayerPartnerRequestPath

  if(resizetimeout)
    clearTimeout(resizetimeout);
  resizetimeout = setTimeout(function(){
    if(!isMobile || (window.innerWidth <= window.innerHeight) || checkInputFocusOnMobile() || disableRotate){
      const appWrapper = document.getElementById("app-wrapper")
      if(appWrapper) appWrapper.style.display = "block";
      document.getElementById("hor-wrapper").style.display = "none";
      if (menuElement) {
        menuElement.style.display = "block";
      }

    }else{
      document.getElementById("app-wrapper").style.display = "none";
      document.getElementById("hor-wrapper").style.display = "flex";
      if (menuElement) {
        menuElement.style.display = "none";
      }
    }
  },100);
}

// check case mobile screen is shrinked by native keyboard
function checkInputFocusOnMobile() {
  return window.innerWidth > window.innerHeight && isMobileOnly && window.orientation == 0
}

window.removeEventListener("resize", changeWindowSize);
window.addEventListener('resize', changeWindowSize);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <I18nProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <App />
          <HorizontalApp/>          
        </MuiPickersUtilsProvider>
        <Alert />
      </I18nProvider>
    </Provider>
  </ErrorBoundary>,
    document.getElementById("root")
  );


changeWindowSize();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
