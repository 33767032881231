import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ImgVisaCard from "@iso/assets/images/visa_card.svg";
import ImgMasterCard from "@iso/assets/images/master_card.svg";
import ImgMobileVisaCard from "@iso/assets/images/mobile_visa_card.svg";
import ImgMobileMasterCard from "@iso/assets/images/mobile_master_card.svg";
import IconPrefer from "@iso/assets/images/icon_prefer.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Col } from "react-bootstrap";
import { isMobile, isTablet } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#fff",
    position: "relative",
    height: "155px",
    width: "100%",
    backgroundSize: "cover",
    borderRadius: "6px",
    [theme.breakpoints.down('md')]: {
      padding: "5px",
      height: "50px",
      maxWidth: "80px",
      justifyContent: "space-between",
    }
  },

  isPrefer: {
    border: "2px solid #499E9E",
    boxSizing: "border-box",
    borderRadius: "6px",
  },

  img: {
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  exp: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "12px",
    [theme.breakpoints.down('sm')]: {
      margin: "0",
    }
  },
  lastDigit: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "12px",
    [theme.breakpoints.down('sm')]: {
      margin: "0",
    }
  },
  name: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "12px",
    [theme.breakpoints.down('sm')]: {
      margin: "0",
    }
  },
  iconPrefer: {
    position: "absolute",
    color: "#499E9E",
    width: "25px",
    height: "25px",
    top: "-10px",
    right: "-10px",
    [theme.breakpoints.down('sm')]: {
      width: "15px",
      height: "15px",
      top: "-5px",
      right: "-5px",
    }
  },
}));

const PaymentCard = ({
  id,
  cardType = "mastercard",
  exp = "10/2025",
  name = "Phillip	Smith",
  lastDigit = "1234",
  isPrefer,
  setDefaultCard,
}) => {
  const classes = useStyles();
  const isMobileScreen = useMediaQuery("(max-width:960px)");
  const onlyMobileScreen = (isMobile && !isTablet);
  let img = onlyMobileScreen ? ImgMobileVisaCard : ImgVisaCard;
  if (cardType === "mastercard") {
    img = onlyMobileScreen ? ImgMobileMasterCard : ImgMasterCard;
  }
  return (
    <Col
      lg="12"
      xs="4"
      className={`${classes.root} hover_pointer`}
      onClick={() => setDefaultCard({ card_id: id })}
      key={id}
      style={{backgroundImage: `url(${img})`, backgroundSize: `${(isMobile) ? `81px 52px` : `100%`}`, backgroundRepeat: 'no-repeat', border: `2px solid ${isPrefer ? `#499E9E` : ``}`}}
    >
      <p className={`${classes.exp} mt-lg-3`}>{exp}</p>
      <p className={classes.lastDigit}>{`${isMobileScreen ? `xxxx` : `xxxx xxxx xxxx`} ${lastDigit}`}</p>
      <p className={classes.name}>{name}</p>
      {isPrefer && <img src={IconPrefer} className={classes.iconPrefer} />}
    </Col>
  );
};

export default PaymentCard;
