import axios from "axios";
import { apiEndpoints } from "@iso/helpers";

const couponsGetList = (body) => {
    return axios.get(`${apiEndpoints.coupon_path}`);
};

const createCoupon = (body) => {
    return axios.post(apiEndpoints.coupon_path, body);
};

const deleteCoupon = (id) => {
    return axios.delete(`${apiEndpoints.coupon_path}/${id}`);
}

const checkExistCode = (code, prayer_contribution_id, frequency_id) => {
    return axios.get(`${apiEndpoints.coupon_path}/check_exist?code=${code}&contribution_id=${prayer_contribution_id}&frequency_id=${frequency_id}`);
}

export default {
    couponsGetList,
    createCoupon,
    deleteCoupon,
    checkExistCode
};