import React, { Component,useState } from "react"
import { Modal } from "react-bootstrap" 
import {useSelector, useDispatch} from 'react-redux'; 
import IntlMessage from "@iso/components/intlMessage"
import { Row,Col,Form,Button } from "react-bootstrap"
import welcomePrayerCloud_logo from "@iso/assets/images/welcomeUserPrayerCloud.svg"
import welcomePrayerCloudLogoTablet from "@iso/assets/images/welcomePrayerCloud-tablet.svg"
import { Link } from "react-router-dom"
import { useHistory } from 'react-router'
import welcomePrayerCloudActions from '@iso/actions/WelcomePrayerCloudActions'
import './style.scss'
import { isMobile, isTablet, isIPad13 } from "react-device-detect";
import PopupBeta from "../../../actions/PopupBeta";

function ModalFormUserPrayerCloud(props){

  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state.authentication.user);
  const handleClick = () => {
    history.push('/requests/step1')
    // props.setShow(true)
    dispatch(
      welcomePrayerCloudActions.closeModal()
    )
  }

  const getWelcomeLogo = () => {
    return isIPad13 || isTablet ? welcomePrayerCloudLogoTablet : welcomePrayerCloud_logo
  }
  return(
    <React.Fragment>
      <Modal.Header id = "formUserPrayerCloudHeader" closeButton= {isMobile ? true :false}>
      </Modal.Header>
      
      <Modal.Body className = "text-center font-size-18px custom_modal_sign_in"> 
      <Modal.Title className ="formPrayerCloudTitle">
         <img src={getWelcomeLogo()} alt ="welcome prayer cloud" style={isTablet ? {marginLeft: "21px"} : {}}/>
         <p className = "font-size-16px mt-3 black-light-color custom_user_email_subscribe">{user.email}</p>
          {/* <p className = "black-light-color font-size-22px font-weight-bold"> <IntlMessage id = "user.form.thank.prayercloud.title"/>
            <p className ="black-light-color font-size-22px font-weight-bold"><IntlMessage id = "user.form.thank.subscribed.title"/></p>
          </p> */}
         {
           (props.isHeader && props.countShow === 1) ?
            (
              <p className = "black-light-color font-size-22px font-weight-bold"> 
                <IntlMessage id = "user.form.welcome.prayercloud.title"/>
                <p className ="black-light-color font-size-22px font-weight-bold">
                {user.full_name}!
                </p>
              </p>
            ):
            (
              <p className = "black-light-color font-size-22px font-weight-bold"> <IntlMessage id = "user.form.thank.prayercloud.title"/>
                <p className ="black-light-color font-size-22px font-weight-bold"><IntlMessage id = "user.form.thank.subscribed.title"/></p>
              </p>
            )
         }
        </Modal.Title>
        <Button className="hippie-blue-button font-size-18px font-weight-bold custom_button_request_subscribe" 
        id = "receivebtn" type="submit" onClick = { handleClick }>
          <IntlMessage id="public.request.title" />
        </Button>

        <Link to= "/account" className = "explore-account" > <IntlMessage id="user.form.welcome.prayercloud.explore.account.title" /> </Link>
        <hr className={` general_hr ${!props.formSubcribe && 'border_hr'}`}/> 
      </Modal.Body>
      </React.Fragment>
  )
}

export default ModalFormUserPrayerCloud
