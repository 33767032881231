import React, {useState} from "react";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";
import { Row, Col, Button } from "react-bootstrap";

import { Formik, Field, Form } from "formik";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import stripeActions from "@iso/actions/Stripe";
import RequestService from "@iso/services/RequestService";
import CouponService from "@iso/services/CouponService";
import requestStepActions from "@iso/actions/RequestStepActions";
import { history } from "@iso/helpers";
import confirmRequestModalActions from '@iso/actions/confirmRequestModal'
import CompleteRequestButton from "./components/CompleteRequestButton";
import OopsPopup from "./components/OopsPopup";
import { isMobile, isTablet } from "react-device-detect";
import CheckoutForm from "../../../../components/Stripe/CheckoutForm";
import {Elements} from "@stripe/react-stripe-js";
import gtag from 'ga-gtag';
import { recommended_events } from "@iso/constants";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

const { createRequest } = RequestService;

const {
  triggerStripe,
  setCardToken,
  setToken,
  setSaveCard,
  setLoadingPayment,
} = stripeActions;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const INIT_FORM = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  keep_me: true,
}

const mobileWidth = 500;

const Index = (props) => {
  const dispatch = useDispatch();
  const [formConfirmData, setFormConfirmData] = useState(INIT_FORM)
  const { cardToken, loadingPayment } = useSelector((state) => state.stripe);
  const { user } = useSelector((state) => state.authentication);

  const requestStepData = useSelector((state) => state.requestStepReducer);
  const [formikValues, setFormikValues] = React.useState(requestStepData);
  const [emailRecipient, setEmailRecipient] = React.useState(requestStepData.recipient_email)
  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const [windowDimensions, setWindowDimensions] = React.useState();
  const [isShowContribution, setIsShowContribution] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState("");
  const [couponCodeError, setCouponCodeError] = React.useState(false);

  const [
    enterRecipientEmailPopup,
    setEnterRecipientEmailPopup,
  ] = React.useState(false);

  const [requestErrors, setRequestErrors] = React.useState();
  const [requestId, setRequestId] = React.useState();

  const handleSwitchShowContribution = () => {
    setIsShowContribution(!isShowContribution);
  };

  if (formikValues.step === 0) {
    history.push("/requests/step1");
  }

  const handleToggleOops = (value) => {
    setEnterRecipientEmailPopup(value);
  };

  const handleCheckGiftAndEmail = () => {
    const isGift = requestStepData.is_gift;
    const recipientEmail = requestStepData.recipient_email;

    if (isGift) {
      if (recipientEmail.length > 0) {
        return true;
      }

      handleToggleOops(true);
      return false;
    }
    else{
      setEmailRecipient("")
    }
    requestStepData.recipient_email = emailRecipient;
    return true;
  };

  const handleSubmit = (values, bag) => {
    console.log("values", values);
    console.log("requestStepData", requestStepData);
    console.log("couponCode", couponCode);
    // Check if the request is a gift,
    // isGift: Recipient's email has to be valid, else, return and show pop up
    const isValidatedGiftAndEmail = handleCheckGiftAndEmail();
    if (!isValidatedGiftAndEmail) {
      return;
    }
    dispatch(setLoadingPayment(true));

    // Check coupon code
    if (couponCode && couponCode != "") {
        CouponService.checkExistCode(couponCode, requestStepData.prayer_contribution.id, requestStepData.prayer_frequency.id).then((res) => {
          if (res.data && res.data.is_exist) {
            setCouponCodeError(null);
            dispatch(
              requestStepActions.setDataStep({ ...values, total_contribution: res.data.coupon.total_amount })
            );
            dispatch(triggerStripe());
          } else {
            setCouponCodeError(1);
            dispatch(setLoadingPayment(false));
          }
        });
      
    } else {
      setCouponCodeError(null);
      dispatch(triggerStripe());
    }
    
  };

  React.useEffect(() => {
    dispatch(setLoadingPayment(true));
    const paymentData = {payment_method: 'draft'}
    createRequest({
      ...requestStepData,
      ...paymentData,
      ...requestStepData.prayer_frequency,
      ...requestStepData.prayer_customization,
      coupon_code: couponCode,
      recipient:
        requestStepData.for_who == "forsomeoneelse"
          ? "for_someone"
          : "for_myself",
    }).then((res) => {
      const { code, data, errors } = res.data;
      dispatch(setLoadingPayment(false));
      if (code === 200) {
        setRequestId(data.id);
      } else {
        setRequestErrors(errors);
        dispatch(setCardToken(null));
        dispatch(setToken(null));
      }
    });
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (cardToken) {
      saveRequest({
        stripeToken: cardToken,
        payment_method: "stripe",
      });
    }
  }, [cardToken]);

  const saveRequest = (payment) => {
    dispatch(setLoadingPayment(true));
    createRequest({
      ...requestStepData,
      ...payment,
      ...requestStepData.prayer_frequency,
      ...requestStepData.prayer_customization,
      coupon_code: couponCode,
      recipient:
        requestStepData.for_who == "forsomeoneelse"
          ? "for_someone"
          : "for_myself",
      request_id: requestId,
      time_zone: time_zone
    }).then((res) => {
      const { code, data, success, payment, errors, message } = res.data;
      dispatch(setLoadingPayment(false));
      if (code === 200) {
        if (success) {
          let items = [
            {
              'item_id' : requestStepData.prayer_contribution.id,
              'item_name' : requestStepData.prayer_contribution.name,
              'currency' : 'USD',
              'price' : requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number,
              'quantity' : requestStepData.prayer_frequency.value_number
            }
          ];
          gtag('event', recommended_events.PURCHASE, {
            transaction_id : data.id,
            value : requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number,
            currency: "USD",
            items: items
          });
          ReactPixel.track(fb_pixel_event.recommended_events.PURCHASE, {
            transaction_id : data.id,
            value : requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number,
            currency: "USD",
            items: items
          });
    

          gtag('event', recommended_events.ADD_PAYMENT_INFO, {
            value : requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number,
            currency: "USD",
            payment_type: requestStepData.payment_method,
            items: items
          });
          ReactPixel.track(fb_pixel_event.custom_events.ADD_PAYMENT_INFO, {
            value : requestStepData.prayer_contribution.price * requestStepData.prayer_frequency.value_number,
            currency: "USD",
            payment_type: requestStepData.payment_method,
            items: items
          });
    
          dispatch(
            requestStepActions.setDataStep({
              ...requestStepData,
              id: data.id,
              step: 3,
            })
          );
          
          dispatch(setSaveCard(false))
          dispatch(setCardToken(null));
          dispatch(setToken(null));
          history.push("/requests/step4");
          dispatch(
              requestStepActions.setDataStep({
                ...requestStepData,
                id: data.id,
                client_email: formConfirmData.email,
                signup_first_name: formConfirmData.first_name,
                signup_last_name: formConfirmData.last_name,
                signup_password: formConfirmData.password,
                signup_keep_me: formConfirmData.keep_me,
              })
          );
        }
        else {
          if (payment?.client_secret) {
            dispatch(setLoadingPayment(true));
            dispatch(triggerStripe(payment));
          }
        }
      } else {
        setRequestErrors(message);
        dispatch(setCardToken(null));
        dispatch(setToken(null));
      }
    });
  };

  var propsRightPart = {
    loadingPayment: loadingPayment,
    setLoadingPayment: setLoadingPayment,
    afterAuthorize:saveRequest,
    price: formikValues.total_contribution,
    requestId:requestId,
    formConfirmData:formConfirmData,
    setFormConfirmData:setFormConfirmData,
    requestErrors:requestErrors,
    coupon:couponCode,
    setCoupon:setCouponCode,
    couponCodeError:couponCodeError,
    setCouponCodeError:setCouponCodeError,
  }
  if(isMobile && !isTablet && isShowContribution) {
    propsRightPart = {...propsRightPart, 
      switchToLeftSide: handleSwitchShowContribution
    }
  }
  return (
    <div className={`step3-index`}>
      <Formik initialValues={formikValues} onSubmit={handleSubmit}>
        {({ values, handleSubmit, handleChange, touched, errors }) => (
          <Form>
            <Row>
              <Col sm="6" className="pt-3 step3-left-side">
                {!isShowContribution && (
                  <LeftPart
                    formikValues={formikValues}
                    setFormikValues={setFormikValues}
                    requestErrors={requestErrors}
                  />
                )}
              </Col>
              {(isMobile && !isTablet) && !isShowContribution && (
                <CompleteRequestButton
                  handleSwitchShowContribution={handleSwitchShowContribution}
                  resetRequestErrors={() => setRequestErrors()}
                />
              )}
              <Col sm={isShowContribution ? "12" : "6"} id="rightside">
              {!(isMobile && !isTablet) && (
                  <RightPart
                    {...propsRightPart}
                  />
                )}
                {(isMobile && !isTablet) && isShowContribution && (
                  <RightPart
                    {...propsRightPart}
                  />
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <OopsPopup
        isOpen={enterRecipientEmailPopup}
        handleOpen={() => handleToggleOops(true)}
        handleClose={() => handleToggleOops(false)}
      />
    </div>
  );
};

export default Index;
