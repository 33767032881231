import React from "react";
import DefaultAvatar from "@iso/assets/images/account_profile_avatar_default.svg";

const ProfileAvatar = ({ user }) => {
  let avatar = user?.avatar || DefaultAvatar

  return (
    <div className="d-flex align-items-center">
      <img src={avatar} className="avatar" alt="Avatar"></img>
    </div>
  );
};

export default ProfileAvatar;
