import React from "react";
import styled from "styled-components";
import {isMobile} from "react-device-detect";

const Wrapper = styled.div`
  
  position: relative;
  padding: 0px!important;
`;

const Icon = styled.img`
`;

const Content = styled.p`
  position: absolute;
  top: 210px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 7px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 22px;
`;

const Description = styled.p`
   font-size: 18px;
`;

const ItemForTablet = (props) => {
    const {icon, title, description, index} = props;

    return (
        <Wrapper>
            <Icon index={index} style={{marginTop: (index === 2) && "-17px"}} src={icon}></Icon>
            <Content>
                <Title>{title}</Title>
                <Description index={index}>{description}</Description>
            </Content>
        </Wrapper>
    );
};

export default ItemForTablet;