import SignUp from "@iso/PrayerPartner/Pages/SignUp/SignUp"
import SignIn from "@iso/PrayerPartner/Pages/SignIn/SignIn"
import ForgotPassword from "@iso/PrayerPartner/Pages/ForgotPassword/ForgotPassword"
import ResetPassword from "@iso/PrayerPartner/Pages/ResetPassword/ResetPassword"

export default {
  SignUp: {
    component: SignUp,
    path: "/prayer_partners/sign_up",
  },
  SignIn: {
    component: SignIn,
    path: "/prayer_partners/sign_in",
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/prayer_partners/forgot_password",
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/prayer_partners/reset_password",
  },
}
