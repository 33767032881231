import React, { Component, useState } from "react";
import { Row, Col, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import IntlMessage from "@iso/components/intlMessage";
import Header from "@iso/components/Layout/Share/Header";
import {
  MENU_SIDEBAR,
  PRIVATE_MENU_SIDEBAR,
  REQUEST_SIDEBAR,
  ABOUT_SIDEBAR,
  SIGNUP_SIDEBAR,
  SIGNIN_SIDEBAR,
  SIGNOUT_SIDEBAR,
} from "./constants";
import actionsSignIn from "@iso/actions/signinmodal";
import registerActions from "@iso/actions/confirmRequestModal";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, getUser } from "@iso/actions/User";
import { useLocation } from "react-router";
import PublicSubMenuSidebar from "./PublicSubMenuSidebar";
import { getToken, removeToken, clearUserData } from "../../utils/TokenService";
import PopupBeta from "../../actions/PopupBeta";
import { history } from "@iso/helpers";
import { fb_pixel_event } from "@iso/constants";
import ReactPixel from 'react-facebook-pixel';

import gtag from 'ga-gtag';
import { custom_events } from "@iso/constants";

const PublicHeader = (props) => {
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, fb_pixel_event.advanced_matching, fb_pixel_event.options);

  const { isBlue = false } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  let backgroundHeader = isBlue;
  const {user, loggedIn} = useSelector((state) => state.authentication);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const [dspPrayerBtn, setDspPrayerBtn] =React.useState(false);
  const [classNav, setClassNav] =React.useState('');
  const [show, setShow] = React.useState(false);
  const checkFlagShowPrayerButton = () => {
    switch(location.pathname){
      case '/':
      case '/requests/step1':
      case '/requests/step2':
      case '/requests/step3':
      case '/requests/step4':
      case '/account':
        setDspPrayerBtn(false);
        break;
      default:
        setDspPrayerBtn(true);
        break;
    }
		
		if (location.pathname === '/requests/step4') {
			setClassNav('header-step4')
		}
  }

  React.useEffect(() => {
    checkFlagShowPrayerButton()
  },[])

  // Set content menu
  const menu = loggedIn ? PRIVATE_MENU_SIDEBAR :  MENU_SIDEBAR;

  // Onclick menu items
  const onClickMenu = (item) => {
    switch (item) {
      case REQUEST_SIDEBAR:
        break;
      case ABOUT_SIDEBAR:
        break;
      case SIGNUP_SIDEBAR:
        console.log(123)
        clearUserData()
        removeToken()
        dispatch(
          registerActions.openModal({
            type: registerActions.SHOW_REGISTER_MODAL,
          })
        );
        break;
      case SIGNIN_SIDEBAR:
        dispatch(
          actionsSignIn.openModal({
            type: actionsSignIn.SHOW_MODAL,
          })
        );
        break;
      case SIGNOUT_SIDEBAR:
        handleLogout();
        break;
    }
  };

  const handleRequestNew = () => {
    gtag('event', custom_events.CLICK_BUTTON, {
      button_label : 'Request a Prayer (From FAQ page)',
    });
    ReactPixel.track(fb_pixel_event.custom_events.CLICK_BUTTON, {
      button_label : 'Request a Prayer (From FAQ page)',
    });

    history.push('/requests/step1')
    // setShow(true)
  }

  const renderMenu = () => {
    return (
      <>
      {/* Request a Prayer button */}
      { dspPrayerBtn &&
        (
          <>
          <Nav.Item
          className='prayer-hippe-button nav-link'
          onClick={() => {
            handleRequestNew()
            onClickMenu(REQUEST_SIDEBAR)
          }}
          style={{marginRight:'20px', cursor: 'pointer'}}
          >
            <IntlMessage id="sidebar.public.request" />
          </Nav.Item>

            <PopupBeta show={show} setShow={setShow}/>
            </>
        )
        
      }
      {menu &&
        menu.map((item, index) => {
          return (
            <Nav.Link
              className={`${item.className} `}
              key={index}
              href={item.path}
              onClick={() => onClickMenu(item.id)}
            >
              <IntlMessage id={item.title} />
            </Nav.Link>
          );
        })}
      { loggedIn && <PublicSubMenuSidebar user={user} isBlue ={isBlue} dspPrayerBtn={dspPrayerBtn} />}
      </>
    );
  };

  return (
    <Header
      menu={renderMenu()}
      className={`public-header ${classNav}`}
      id="public-header"
      backgroundHeader={backgroundHeader}
    ></Header>
  );
};

export default PublicHeader;
