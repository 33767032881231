import axios from "axios";
import { apiEndpoints } from "@iso/helpers";

const getPrayerPartnerById = ({ id }) => {
  return axios.get(`${apiEndpoints.admin_prayer_partners}/${id}/show`);
};

const getPrayerProfile = () => {
  return axios.get(apiEndpoints.prayer_requests);
};

const adminGetAvailabilitiesById = ({ id }) => {
  return axios.get(
    `${apiEndpoints.admin_prayer_partners}/${id}/availabilities`
  );
};

const adminPutUpdateAvatarStatus = ({ id, status }) => {
  return axios.put(
    `${apiEndpoints.admin_prayer_partners}/${id}/avatar_${status}`
  );
};

const adminUpdateAvailabilities = (body) => {
  return axios.put(`admin/prayer_partners/${body.id}/availabilities/update`, body);
};

const adminUpdatePrayerPartnerProfile = (body) => {
  return axios.put(
    `${apiEndpoints.admin_prayer_partners}/${body.id}/update_profile`,
    body
  );
};

const adminSavePrayerPartnerProfile = (body) => {
  return axios.put(
    `${apiEndpoints.admin_prayer_partners}/${body.id}/save_profile`,
    body
  );
};

const adminSaveNoteAdmin = (values, id) => {
  return axios.put(
    `${apiEndpoints.admin_prayer_partners}/${id}/save_note_admin`,
    {note: values}
  );
};

const adminSaveNotificationSetting = (body) => {
  return axios.post(
    `${apiEndpoints.admin_prayer_partners}/update_notify`,
    body
  );
};

const adminChangeSeen = (body) => {
  return axios.post(
    `${apiEndpoints.admin_prayer_partners}/change_seen`,
    body
  );
};

const adminGetNoteAdmin = ({ id }) => {
  return axios.get(
    `${apiEndpoints.admin_prayer_partners}/${id}/show_note_admin`
  );
};

const createPrayerPartner = (body) => {
  return axios.post("/prayer_partner", body);
};

export const adminGetPrayerPartners = (status, options) => {
  return axios.get(apiEndpoints.admin_prayer_partners, {
    params: {
      status: status,
      ...options
    },
  });
};

export const adminGetPrayersByDenomination = (denomination, request_id) => {
  return axios.get(apiEndpoints.admin_prayer_partners_denominations, {
    params: {
      denomination: denomination,
      request_id: request_id,
    },
  })
}
export const adminUpdatePercentage = (body) => {
  return axios.put(`${apiEndpoints.admin_prayer_partners}/${body.id}/update_percentage`, body)
}

export default {
  getPrayerPartnerById,
  getPrayerProfile,
  adminGetAvailabilitiesById,
  adminPutUpdateAvatarStatus,
  adminUpdatePrayerPartnerProfile,
  createPrayerPartner,
  adminGetPrayerPartners,
  adminGetPrayersByDenomination,
  adminUpdatePercentage,
  adminSaveNotificationSetting,
  adminUpdateAvailabilities,
  adminSavePrayerPartnerProfile,
  adminSaveNoteAdmin,
  adminGetNoteAdmin,
  adminChangeSeen,
};
