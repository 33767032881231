const prayerPartnerActions = {
  GET_PRAYER_PARTNER_PROFILE_BY_ID: "GET_PRAYER_PARTNER_PROFILE_BY_ID",
  GET_PRAYER_PARTNER_AVAILABILITIES_BY_ID:
    "GET_PRAYER_PARTNER_AVAILABILITIES_BY_ID",

  setPrayerPartnerProfile: ({ profile }) => ({
    type: prayerPartnerActions.GET_PRAYER_PARTNER_PROFILE_BY_ID,
    payload: {
      profile,
    },
  }),
};

export default prayerPartnerActions;
