import React from "react";
import { Row, Col } from "react-bootstrap";
import Cloud from "@iso/assets/images/Cloud.svg";
import CloudTablet from "@iso/assets/images/step2_cloud_tablet.svg";
import ThankYouCloudImage from "@iso/assets/images/thank_you_cloud.svg";
import ThankYouCloudImageBig from "@iso/assets/images/thank_you_cloud_big.svg";
import Title from "./components/Title";
import RequestDetails from "./components/RequestDetails";
import { isTablet } from "react-device-detect"

const RightPart = (props) => {
	const { email = "", requestId } = props
	const { innerWidth: width } = window;

	return (

		<Row id="right-part-wrapper-step4">

			<Col className="custom_col">
				<Title/>
				<RequestDetails email={email} requestId={requestId}></RequestDetails>
			</Col>
			<div
				className="bg_image_cloud d-none d-sm-block"
				style={{ backgroundImage: `url(${width < 1920 ? ThankYouCloudImage : ThankYouCloudImageBig})` }}
			></div>
		</Row>

	);
};

export default RightPart;
