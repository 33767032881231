import React from "react";
import "./styles.scss";

import { Card, Button, Row, Col } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import { Formik, Field, Form } from "formik";
import { CATEGORIES } from "./constants";
import { useSelector, useDispatch } from "react-redux";
import { sendContactAdmin } from "@iso/PrayerPartner/api_v1";

import alertActions from "@iso/actions/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import { ContactAdminValidator } from "./validators";
const { openAlert } = alertActions;

const ContactAdminPage = () => {
  const { user } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [formikValue, setFormikValue] = React.useState({});

  React.useEffect(() => {
    if (user) {
      setFormikValue({
        email: user.email,
        category: "payments",
        message: "",
      });
    }
  }, [user]);

  const handleSubmit = (values, bag) => {
    setIsSending(true);
    try {
      sendContactAdmin(values).then((res) => {
        const { code, data, errors } = res.data;
        if (code == 200) {
          setOpenModal(true);
          setIsSending(false);
          bag.resetForm();
        } else {
          setIsSending(false);
          bag.setErrors(errors);
        }
      });
    } catch (error) {
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      {isSending && <LinearProgress />}
      <div className="contact_page_wrapper mint-tulip-background p-3">
        <Row className="my-2">
        <Col xs="12" sm="12" className="request_title">
          <h1>
            <IntlMessage id="prayer_partners.contact_admin.title" />
          </h1>
        </Col>
        </Row>
        <Card className="card_wrapper p-5">
          <Formik
            initialValues={formikValue}
            enableReinitialize={true}
            validationSchema={ContactAdminValidator}
            onSubmit={handleSubmit}
          >
            {({ values, handleSubmit, handleChange, touched, errors }) => (
              <Form className="form-group">
                <Row className="form-group">
                  <Col xs="12" lg="4">
                    <label htmlFor="email">
                      <span>
                        <IntlMessage id="common.your_email" />
                      </span>
                      <small id="emailHelp" className="form-text text-muted">
                        <IntlMessage id="prayer_partners.contact_admin.form.notes.email" />
                      </small>
                    </label>

                    <Field
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="email"
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col xs="12" lg="4">
                    <label htmlFor="category">
                      <span>
                        <IntlMessage id="common.select_a_category" />
                      </span>
                      <small id="category" className="form-text text-muted">
                        <IntlMessage id="prayer_partners.contact_admin.form.notes.category" />
                      </small>
                    </label>
                    <Field
                      name="category"
                      as="select"
                      className="form-control"
                      value={values.category}
                      onChange={handleChange}
                    >
                      {CATEGORIES.map((category) => {
                        return (
                          <option value={category.id} key={category.id}>
                            {category.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="category"
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col xs="12">
                    <label htmlFor="message">
                      <span>
                        <IntlMessage id="prayer_partners.contact_admin.form.notes.message" />
                      </span>
                    </label>

                    <Field
                      name="message"
                      as="textarea"
                      className="form-control"
                      placeholder="Type here ..."
                      value={values.message}
                      onChange={handleChange}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="message"
                    />
                  </Col>
                </Row>
                <div className="text-center">
                  <Button className="hippie-blue-button" type="submit">
                    <IntlMessage id="common.submit" />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card>

      </div>

      <ConfirmModal
        open={openModal}
        handleConfirm={() => setOpenModal(false)}
        handleClose={() => setOpenModal(false)}
        title={<IntlMessage
            id="prayer_partners.contact_admin.modal.thank_modal.title"
          />}
        okText="Ok"
        content={
          <IntlMessage
            id="prayer_partners.contact_admin.modal.thank_modal.body"
            values={{ email: formikValue.email }}
          />
        }
        contentFooter={
          <IntlMessage id="prayer_partners.contact_admin.modal.thank_modal.fotter" />
        }
      />
    </>
  );
};

export default ContactAdminPage;
