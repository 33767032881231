import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import IntlMessage from "@iso/components/intlMessage";
import { sendDemo } from "@iso/PrayerPartner/api_v1";

const PageHeader = (props) => {
  const { title, backURL, requestDemo } = props;
  return (
    <Row className="my-2">
      <Col xs="12" sm="6" className="request_title">
        <h2 className="text-center text-md-left">
          <IntlMessage id={title} />
        </h2>
      </Col>
      <Col xs="12" sm="6" className="send_btn text-right">
        {backURL && (
          <Link to={backURL}>
            <Button
              variant="back_btn_color"
              className="back_btn_color mr-2 ml-2"
              id="btn-back"
            >
              <IntlMessage id="common.back" />
            </Button>
          </Link>
        )}

        {requestDemo && (
          <Button className="hippie-blue-button" onClick={sendDemo}>
            <IntlMessage id="prayer_partners.requests.send_demo" />
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default PageHeader;
