import * as Yup from "yup";

const requestDetailsValidator = Yup.object().shape({
  is_gift: Yup.boolean(),
  reason: Yup.string()
    .label("Reason")
    .test(
      "maxLength",
      "Maximum 600 characters allowed",
      (val) => !val || val.length <= 600
    ),
  sender_name: Yup.string()
    .label("From")
    .when("is_gift", {
      is: true,
      then: Yup.string()
        .matches(/[A-Za-z]/, "Invalid input! Please try again.")
        .test(
          "maxLength",
          "Name must be under 25 characters",
          (val) => val && val.length <= 25
        )
      .required("From is required"),
    }),
  recipient_name: Yup.string()
    .required()
    .label("Name")
    .matches(/[A-Za-z]/, "Invalid input! Please try again.")
    .test(
      "maxLength",
      "Name must be under 25 characters",
      (val) => val && val.length <= 25
    ),
  recipient_email: Yup.string()
    .label("Email")
    .when("is_gift", {
      is: true,
      then: Yup.string()
        .required("Email is required")
        .email("Please enter a correct email format"),
    }),
});

const requestCustomizationValidator = Yup.object().shape({
  prayer_customization: Yup.object().shape({
    special_request: Yup.string()
      .max(300, ({ max }) => {
        return `Maximum ${max} characters allowed`;
      })
      // .test(
      //   "len",
      //   "Invalid input! Please try again.",
      //   function (value) {
      //     if (value == '') {
      //       return true
      //     } else {
      //       let letters = /^[A-Za-z0-9\n ]+$/
      //       if (value.match(letters)) {
      //         return true
      //       }
      //       return false
      //     }
      //   }
      // )
  }),
});
const giftMessageValidator = Yup.object().shape({
  gift_message: Yup.string().test(
    "len",
    "Maximum 600 characters allowed",
    (val) => val.length <= 600
  ),
});

export {
  requestDetailsValidator,
  requestCustomizationValidator,
  giftMessageValidator,
};
