import { combineReducers } from "redux"
import { authentication } from "@iso/reducers/authentication.reducer"
import { cardData } from "@iso/reducers/cardData.reducer"
import { alert } from "@iso/reducers/alert"
import { prayerPartnerReducer } from "@iso/reducers/prayer_partner.reducer"
import stripe from "@iso/reducers/Stripe"
import requestStepReducer from "@iso/reducers/request_step.reducer"
import {signinmodal}  from "@iso/reducers/signinmodal"
import confirmRequestModal from '@iso/reducers/confirmRequestModal'
import {checkemailmodal}  from "@iso/reducers/checkemailmodal"
import welcomePrayerCloudReducer  from "@iso/reducers/welcomePrayerCloudReducer"
import drawerMenuMobileReducer  from "@iso/reducers/drawer_menu_mobile_reducer"
import banAccountReducers  from "@iso/reducers/ban_account.reducer"
import notificationReducers  from "@iso/reducers/notification"
import welcomePrayerPartnerReducer from "@iso/reducers/WelcomePrayerPartnerReducer"
import contactReducers  from "@iso/reducers/contact"

export const structure = combineReducers({
  authentication,
  alert,
  prayerPartnerReducer,
  stripe,
  requestStepReducer,
  signinmodal,
  checkemailmodal,
  confirmRequestModal,
  welcomePrayerCloudReducer,
  drawerMenuMobileReducer,
  banAccountReducers,
  notificationReducers,
  welcomePrayerPartnerReducer,
  contactReducers,
  cardData
})
