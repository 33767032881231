import React, { Component,useState } from "react"
import { Modal } from "react-bootstrap"
import {useSelector, useDispatch} from 'react-redux';
import AuthService from "@iso/services/AuthService"
import IntlMessage from "@iso/components/intlMessage"
import { Button } from "react-bootstrap"
import { connect } from "react-redux"
import PrayerTypes from './PrayerTypes';
import welcomePrayerCloud_logo from "@iso/assets/images/welcomePrayerCloud.svg"
import alertActions from "@iso/actions/Alert";
import './style.scss'
import { isMobile } from "react-device-detect";

function ModalFormPrayerCloud(props){
  const dispatch = useDispatch()
  const { openAlert } = alertActions;
  const showModal = useSelector((state) => state.welcomePrayerCloudReducer);
  const [deno, setDeno] = useState("");
  const [isValid, setIsValid] = useState(true)
  const [firstAccess, setFirstAccess] = useState(true);
  const user = useSelector((state) => state.authentication.user);
  
  const validate = () => { 
    if(deno === ''){
      setIsValid(false)
      return false;
    }
    else{
      setIsValid(true)
      return true;
    }
  }

  React.useEffect(() => {
    if(!firstAccess){
      validate();
    }
  },[deno])

  const handleSubmit = () => {
    setFirstAccess(false);
    if(validate()){
      let subcribe  = {religion: [deno]}
      AuthService.subscribeApi(subcribe).then((res) => {
        const { code, data } = res.data;
        if (code === 200 ) {
          props.setFormSubcribe(true);
        }else {
           dispatch(
              openAlert({
                message: res.message,
                severity: "error",
              })
            );
        }
      }).catch((res) => {
         dispatch(
              openAlert({
                message: <IntlMessage id="common.message.update.fail" />,
                severity: "error",
              })
            );
      });
    }
  }

  return(
    <React.Fragment>
      <Modal.Header id = "formPrayerCloudHeader" closeButton={isMobile ? true : false}>  </Modal.Header>
      <Modal.Body className = "text-left font-size-18px">
      <Modal.Title className ="formPrayerCloudTitle mb-2 mb-md-0">
         <img src = {welcomePrayerCloud_logo} alt ="welcome prayer cloud"/>
         {
           (!showModal.isSubcribe && props.countShow === 1) && (<p className = "blue-light-color font-size-22px font-weight-bold custom_welcome_to_prayercloud_subscribe"><IntlMessage id = "user.form.welcome.prayercloud.title"/></p>)
         }
         <p className = "font-size-22px font-weight-bold custom_title_denomination_subscrise"><IntlMessage id = "user.form.welcome.prayercloud.denomination.title"/></p>
         <p className = "font-size-16px black-light-color custom_text_to_subscribe_for_calendar"> <IntlMessage id = "user.form.welcome.prayercloud.denomination.calendar.title"/></p>
        </Modal.Title>
        <div className = "margin-prayer-types">
          <PrayerTypes setDeno = {setDeno} deno = {deno}/>

          <div className={`pl-2 text-danger ${!isValid ? 'd-block' : 'd-none' }`}>
            <IntlMessage id = "user.form.welcome.prayercloud.denomination.title"/>
          </div>
        </div>

        

        <Button className="hippie-blue-button font-size-18px font-weight-bold custom_button_receive_calendar_subscribe" id = "receivebtn" type = "button"
        onClick = {handleSubmit}>
          <IntlMessage id="user.form.welcome.prayercloud.receive.calendar.title" />
        </Button>
      </Modal.Body>
      </React.Fragment>
  )
}

export default ModalFormPrayerCloud
