import React, { useEffect, useState } from "react"
import {
  Card,
  Collapse,
  Button,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap"
import EditIcon from "@material-ui/icons/Edit"
import { Autocomplete } from "@material-ui/lab"
import { TextField } from "@material-ui/core"
import { useDispatch } from "react-redux"

import { languages } from "@iso/Pages/Common/Languages"
import IntlMessage from "@iso/components/intlMessage"
import "./styles.scss"
import { updateLanguagePrayer } from "@iso/PrayerPartner/api_v1"
import alertActions from "@iso/actions/Alert"

const { openAlert } = alertActions

const SiteLanguage = ({ currentLanguage }) => {
  const [defaultValue, setDefaultValue] = useState(languages.find((item) => item.code === currentLanguage))
  const [isEdit, setIsEdit] = useState(false)
  const [language, setLanguage] = useState(currentLanguage)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const submit = () => {
    try {
      setIsLoading(true)
      updateLanguagePrayer({ language }).then((res) => {
        const { code } = res.data
        if (code === 200) {
          setIsLoading(false)
          if (res.data.code === 200) {
            dispatch(
              openAlert({
                message: "Language successfully update!",
                severity: "success",
              })
            )
            setLanguage(res.data?.data?.language);
            setDefaultValue(languages.find((item) => item.code === res.data?.data?.language))
            setIsEdit(false)
          } else {
            dispatch(
              openAlert({
                message: "Incorrect information!",
                severity: "error",
              })
            )
          }
        }
      })
    } catch (err) {
      dispatch(
        openAlert({
          message: "Incorrect information!",
          severity: "error",
        })
      )
    }
  }

  return (
    <div className="p-3 site-language">
      <Card.Title className="title p-0 m-0 px-3">
        <span>
          <IntlMessage id="prayer_partners.account_settings.site_language.title" />
        </span>
        <Button
          className="float-right hover_pointer hippie-blue-link btn edit_btn"
          onClick={() => setIsEdit(true)}
        >
          <EditIcon className="pr-1 pb-1" />
          <IntlMessage id="prayer_partners.account_settings.profile.btn.edit" />
        </Button>
      </Card.Title>
      <Collapse in={true}>
        <Card.Body>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column md="3">
              <IntlMessage id="prayer_partners.account_settings.site_language.language.label" />
            </Form.Label>
            <Col sm="5">
              <Autocomplete
                disabled={!isEdit}
                id="language"
                className="language-select"
                name="language"
                options={languages}
                getOptionLabel={(option) => option.label}
                value={defaultValue || { code: "", label: "" }}
                onChange={(e, value) => {
                  if (value) {
                    setLanguage(value.code)
                    setDefaultValue(languages.find((item) => item.code === value.code))
                  } else {
                    setLanguage(currentLanguage)
                    setDefaultValue(languages.find((item) => item.code === currentLanguage))

                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="language"
                    variant="outlined"
                    placeholder="Language"
                    fullWidth={true}
                  />
                )}
              />
            </Col>
          </Form.Group>
          {isEdit && (
            <Row className="justify-content-center card_info_control m-0 row">
              <Button
                className="card_info_btn_cancel btn_control mx-1 mb-1 btn btn-primary"
                onClick={() => setIsEdit(false)}
              >
                <IntlMessage id="prayer_partners.account_settings.profile.btn.cancel" />
              </Button>
              <Button type="submit" onClick={submit} disabled={isLoading}
              className='btn_control hippie-blue-button mx-1 mb-1 btn btn-primary'>
                <IntlMessage id="prayer_partners.account_settings.profile.btn.save" />
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </Row>
          )}
        </Card.Body>
      </Collapse>
    </div>
  )
}

export default SiteLanguage
