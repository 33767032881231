export const userConstants = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_CURRENT_PROFILE: "SET_CURRENT_PROFILE",
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGOUT: "USERS_LOGOUT",
  SET_CARD: "SET_CARD",
  REMOVE_CARD: "REMOVE_CARD",
  SET_IS_SAVE_CARD: "SET_IS_SAVE_CARD",
};
export const userRoleConstants = {
  USER_ROLE: "user",
  ADMIN_ROLE: "admin",
  SUPER_ADMIN_ROLE: "super-admin",
  PRAYER_ROLE: "prayer-partner",
};

export const USER_PERMISSIONS = {
  SUPER_USER: {
    roles: [userRoleConstants.ADMIN_ROLE, userRoleConstants.SUPER_ADMIN_ROLE],
    accountSettingURL: "#!",
  },
  PARTNER: {
    roles: [userRoleConstants.PRAYER_ROLE],
    accountSettingURL: "/prayer_partners/account_settings",
  },
  USER: {
    roles: [userRoleConstants.USER_ROLE],
    accountSettingURL: "/account?tab=0",
  },
};

export const REDIRECT_URL = {
  [userRoleConstants.PRAYER_ROLE]: "/prayer_partners/account_settings",
  [userRoleConstants.USER_ROLE]: "/account",
};
