import React from "react";

import { Container } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import RequestItem from "@iso/components/Request/Item";

import './RequestFlaggedBuilder.scss'
const RequestFlaggedBuilder = ({ request }) => {
  return (
    <Container className="mt-5 mb-5 pb-3 request_flagged_builder_container">
      <RequestItem
        title={<IntlMessage id="admin.requests.details.type_of_flag" />}
        value={request.flag_type}
        component="flag"
      />
      <RequestItem
        title={<IntlMessage id="admin.requests.details.flagged_by" />}
        value={
          <IntlMessage id={`admin.requests.flagged_by.${request.flag_by}`} />
        }
      />
      <RequestItem
        title={<IntlMessage id="admin.requests.details.reason_for_flag" />}
        value={request.flag_message}
      />
    </Container>
  );
};

export default RequestFlaggedBuilder;
