import React from "react";
import RequestStatement from "./RequestStatement";
import moment from "moment";
import { DATE_FORMAT_PRAYER_REQUEST, CATEGORIES } from "../constants";
import IntlMessage from "@iso/components/intlMessage";
import { Container } from "react-bootstrap"; 
const RequestDetailBuilder = ({ requestValue }) => {
  const fromPrayerPartner = requestValue.from === "prayer_partner";
  const time_zone = -(new Date().getTimezoneOffset() / 60);

  return (
    <Container className="wrapper_completion_details">
      <div className="pb-4  ">
        <RequestStatement
          title={<IntlMessage id="admin.support_center.detail.item.from" />}
          value={
            <IntlMessage
              id={`admin.support_center.detail.item.from_${requestValue.from}`}
            />
          }
        />

        <RequestStatement
          title={
            <IntlMessage
              id={`admin.support_center.detail.item.${
                fromPrayerPartner ? "date_received" : "date_created"
              }`}
            />
          }
          value={moment.utc(requestValue.created_at).utcOffset(time_zone).format(
            DATE_FORMAT_PRAYER_REQUEST
          )}
        />

        {fromPrayerPartner && (
          <RequestStatement
            title={
              <IntlMessage id="admin.support_center.detail.item.prayer_partner" />
            }
            value={requestValue?.user?.full_name}
          />
        )}

        <RequestStatement
          title={<IntlMessage id="admin.support_center.detail.item.email" />}
          value={requestValue.email}
        />

        <RequestStatement
          title={
            <IntlMessage
              id={`admin.support_center.detail.item.${
                fromPrayerPartner ? "category" : "type"
              }`}
            />
          }  
          value={ CATEGORIES?.find(category => category?.id === requestValue?.category)?.label}
        />

        {!fromPrayerPartner && (
          <RequestStatement
            title={
              <IntlMessage id="admin.support_center.detail.item.country" />
            }
            value={requestValue.country}
          />
        )}

        {!fromPrayerPartner && (
          <RequestStatement
            title={
              <IntlMessage id="admin.support_center.detail.item.subject" />
            }
            value={requestValue.subject}
          />
        )}

        <RequestStatement
          title={<IntlMessage id="admin.support_center.detail.item.reason" />}
          value={requestValue.message}
        />
      </div>
    </Container>
  );
};

export default RequestDetailBuilder;
