import { Col, Row } from "react-bootstrap";
import React from "react";
import moment from "moment";
import IntlMessage from "@iso/components/intlMessage";
import { STATUS_LABEL } from "@iso/components/Request/NotificationLog";
import ArrowForward from "@iso/assets/images/account_prayers_arrow.svg";

const ChildRequestMobile = ({
  parentRequest,
  childRequest,
  setDetailsPage,
  classes,
}) => {
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  const isShowNotificationStatus =
    childRequest.client_confirmation_notification ||
    childRequest.gift_confirmation_notification ||
    childRequest.complete_notification;

  const getStatusClass = () => {
    return childRequest.status === "overdue_done_approved" ||
      childRequest.status === "approved"
      ? classes.completedColor
      : classes.inProgressColor;
  };
  const getStatusLabel = () => {
    return childRequest.status === "overdue_done_approved" ||
      childRequest.status === "approved"
      ? "Completed"
      : "In-progress";
  };
  const getNotificationStatusClass = () => {
    return childRequest.is_opened_email ? classes.opened : classes.unopened;
  };
  const getNotificationStatusLabel = () => {
    return childRequest.is_opened_email
      ? STATUS_LABEL.viewed
      : STATUS_LABEL.send_unopenned;
  };
  return (
    <Row>
      <Col
        xs={parentRequest.frequency !== "one_time" ? "4" : "7"}
        className={`custom-font-size`}
      >
        {moment
          .utc(childRequest.end_date)
          .utcOffset(time_zone)
          .format("MMM DD, YYYY")}
      </Col>
      {parentRequest.frequency !== "one_time" && (
        <>
          <Col xs="3" style={{paddingLeft: '0'}}>
            {
              getStatusLabel() !== 'Completed' &&
              (
                <span className={`${getStatusClass()}`} style={{ fontSize: '14px' }}>{getStatusLabel()}</span>
              )
            }
            {isShowNotificationStatus && getStatusLabel() !== 'In-progress' && getStatusLabel() !== 'Completed' &&(
              <span className={`${getStatusClass()}`}>
                &nbsp;/&nbsp;<br></br>
              </span>
            )}
            {isShowNotificationStatus && getStatusLabel() !== 'In-progress' && (
              <span className={`${getNotificationStatusClass()}`} style={{ fontSize: '14px' }}>
                {getNotificationStatusLabel()}
              </span>
            )}
          </Col>
        </>
      )}
      <Col xs="5" className={`mobile`}>
        <span
          className={`${classes.viewDetailsTitle}`}
          onClick={() => setDetailsPage(childRequest.id)}
        >
          <span
            className={
              parentRequest.frequency === "one_time"
                ? "custom-detail-note mobile"
                : "custom-note-mobile mobile"
            }
          >
            <IntlMessage id="user.account.request.note.mobile" />
            <img src={ArrowForward} className="ml-2"></img>
          </span>
        </span>
      </Col>
    </Row>
  );
};

export default ChildRequestMobile;
