import React from "react";
import * as Yup from "yup";

import { MAX_MESSAGE_LENGTH } from "./constants";
import IntlMessage from "@iso/components/intlMessage";

const ContactAdminValidator = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .email(<IntlMessage id="common.validators.email" />)
    .required(<IntlMessage id="common.validators.require_field" />),
  category: Yup.string().label("Category").required(),
  message: Yup.string()
    .label("Message")
    .required(<IntlMessage id="common.validators.require_field" />)
    .max(MAX_MESSAGE_LENGTH, ({ max }) => {
      return `Your message allows up to ${MAX_MESSAGE_LENGTH} characters`;
    }),
});

export { ContactAdminValidator };
