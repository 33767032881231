import React, { useEffect, useState } from "react";
import { Row, Col, Button, Badge, Card } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import { translate } from "@iso/utils/Translate";
import { MDBBadge, MDBDataTable, MDBBtn } from "mdbreact";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import CouponService from "@iso/services/CouponService";
import { useDispatch } from "react-redux";
import alertActions from "@iso/actions/Alert";
import AddModal from "./addModal";
import { COUPON_TYPE } from "./constants";
import "./styles.scss";
const { openAlert } = alertActions;


const Coupons = (props) => {
    const dispatch = useDispatch();

    const [couponsList, setCouponsList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [openConfirmDeleteCoupon, setOpenConfirmDeleteCoupon] = useState(false);
    const [couponSelectedId, setCouponSelectedId] = useState(null);

    const formatTableData = (data) => {
        let dataRow = [];
        let header = [
            {
                label: <IntlMessage id="admin.coupons.header_table.id" />,
                field: "id",
                sort: "asc",
            },
            {
                label: (
                    <IntlMessage id="admin.coupons.header_table.name" />
                ),
                field: "name",
                sort: "asc",
            },
            {
                label: (
                    <IntlMessage id="admin.coupons.header_table.code" />
                ),
                field: "code",
                sort: "asc",
            },
            {
                label: <IntlMessage id="admin.coupons.header_table.type" />,
                field: "type",
                sort: "asc",
            },
            {
                label: <IntlMessage id="admin.coupons.header_table.description" />,
                field: "description",
                sort: "asc",
            },
            {
                label: <IntlMessage id="admin.coupons.header_table.contribution" />,
                field: "contribution",
                sort: "asc",
            },
            {
                label: <IntlMessage id="admin.coupons.header_table.frequency" />,
                field: "frequency",
                sort: "asc",
            },
            {
                label: <IntlMessage id="admin.coupons.header_table.limit" />,
                field: "limit",
                sort: "asc",
            },
            {
                label: <IntlMessage id="admin.coupons.header_table.number_used" />,
                field: "number_used",
                sort: "asc",
            },
            {
                label: <IntlMessage id="admin.coupons.header_table.discount_value" />,
                field: "discount_value",
                sort: "asc",
            },
            {
                label: <IntlMessage id="admin.coupons.header_table.actions" />,
                field: "actions",
            },
        ];

        data.forEach((e, index) => {
            dataRow.push({
                id: e.id,
                name: (
                    <MDBBadge
                        style={{ fontSize: "16px", fontWeight: "100" }}
                        key={index}
                        searchvalue={e.name ? e.name.toLocaleLowerCase() : ''}
                    >
                        {
                            e.name
                        }
                    </MDBBadge>
                ),
                code: (
                    <MDBBadge
                        style={{ fontSize: "16px", fontWeight: "100" }}
                        key={index}
                        searchvalue={e.code ? e.code.toLocaleLowerCase() : ''}
                    >
                        {
                            e.code
                        }
                    </MDBBadge>
                ),
                type: (
                    <MDBBadge
                        style={{ fontSize: "16px", fontWeight: "100" }}
                        key={index}
                        searchvalue={e.type ? e.type.toLocaleLowerCase() : ''}
                    >
                        {
                            COUPON_TYPE[e.type]
                        }
                    </MDBBadge>
                ),
                description: (
                    <MDBBadge
                        style={{ fontSize: "16px", fontWeight: "100" }}
                        key={index}
                        searchvalue={e.description ? e.description.toLocaleLowerCase() : ''}
                    >
                        {
                            e.description
                        }
                    </MDBBadge>
                ),
                contribution: (
                    <MDBBadge
                        style={{ fontSize: "16px", fontWeight: "100" }}
                        key={index}
                        searchvalue={e.contribution ? e.contribution.name.toLocaleLowerCase() : ''}
                    >
                        {
                            e.contribution ? e.contribution.name : ((e.is_apply_all_contribution) ? 'All' : (e.multiple_contribution) ? e.multiple_contribution : '')
                        }
                    </MDBBadge>
                ),
                frequency: (
                    <MDBBadge
                        style={{ fontSize: "16px", fontWeight: "100" }}
                        key={index}
                        searchvalue={e.frequency ? e.frequency.name.toLocaleLowerCase() : ''}
                    >
                        {
                            e.frequency ? e.frequency.name : ((e.is_apply_all_frequency) ? 'All' : (e.multiple_frequency) ? e.multiple_frequency : '')
                        }
                    </MDBBadge>
                ),
                limit: (
                    <MDBBadge
                        style={{ fontSize: "16px", fontWeight: "100" }}
                        key={index}
                        searchvalue={e.limit ? e.limit : ''}
                    >
                        {
                            e.limit
                        }
                    </MDBBadge>
                ),
                number_used: (
                    <MDBBadge
                        style={{ fontSize: "16px", fontWeight: "100" }}
                        key={index}
                        searchvalue={e.number_used ? e.number_used : ''}
                    >
                        {
                            e.number_used
                        }
                    </MDBBadge>
                ),
                discount_value: (
                    <MDBBadge
                        style={{ fontSize: "16px", fontWeight: "100" }}
                        key={index}
                        searchvalue={e.discount_value ? e.discount_value : ''}
                    >
                        { 
                        e.type == 'discount_by_money'
                        ? '$' + e.discount_value
                        : e.type == 'discount_by_percent'
                        ? e.discount_value + '%'
                        : e.discount_value
                        }
                        
                    </MDBBadge>
                ),
                actions: (
                    <MDBBtn color='link' rounded size='sm' onClick={() => handleClickDeleteCoupon(e.id)}>
                        Delete
                    </MDBBtn>
                )
                // clickEvent: () => handleClick(e.id),
            });
        });
        return {
            data: {
                columns: header,
                rows: dataRow,
            },
        };
    }

    const handleCouponRequests = () => {
        CouponService.couponsGetList().then((res) => {
            if (res) {
                const { code, data, count } = res.data;
                if (res && code === 200) {
                    const coupons = formatTableData(data.data);
                    setCouponsList(coupons.data);
                }
            }
        });
    }

    const handleClickDeleteCoupon = (id) => {
        setCouponSelectedId(id);
        setOpenConfirmDeleteCoupon(true);
    }

    const handleDeleteCoupon = () => {

        if (couponSelectedId) {
            CouponService.deleteCoupon(couponSelectedId).then((res) => {
                if (res) {
                    const { code } = res.data;
                    if (code === 200) {
                        setOpenConfirmDeleteCoupon(false);
                        dispatch(
                            openAlert({
                                message: <IntlMessage id="admin.coupons.delete.success" />,
                                severity: "success"
                            })
                        )
                        // update list
                        updateListWhenDeleteCoupon(couponSelectedId);
                    } else {
                        dispatch(
                            openAlert({
                                message: <IntlMessage id="admin.coupons.delete.fail" />,
                                severity: "fail"
                            })
                        )
                    }
                }
            });
        }
    }

    const updateListWhenDeleteCoupon = (id) => {
        var arrayTmp = couponsList.rows; // make a separate copy of the array
        const newArray = arrayTmp.filter(function(tmp) { 
            return tmp.id !== id 
        });
        setCouponsList({ ...couponsList, rows: newArray });
    }

    useEffect(() => {
        handleCouponRequests();
    }, []);

    return (
        <div className="mint-tulip-background p-3 min-vh-100 admins_list">
            <Row>
                <Col sm="6" className="text-md-left text-center mb-3">
                    <h2>
                        <IntlMessage id="sidebar.admin.coupons.title" />
                    </h2>
                </Col>
                <Col sm="6" className="text-md-right text-center mb-3">
                    <Button
                        className="hippie-blue-button float-md-right"
                        onClick={() => setShowModal(true)}
                    >
                        <IntlMessage id="admin.coupons.add" />
                    </Button>
                </Col>
            </Row>

            <Card className="card_swapper">
                <Row className="ml-2 mr-2">
                    <Col sm="12">
                        <MDBDataTable
                            hover
                            paging={true}
                            id="coupons_list_table"
                            entries={10}
                            searching={false}
                            noBottomColumns={true}
                            entriesOptions={[5, 10, 20]}
                            data={couponsList}
                            sortRows={['name', 'code', 'description']}
                            className="hide_scrollbar"
                        />
                    </Col>
                </Row>
            </Card>

            <AddModal 
                showModal={showModal} 
                closeModal={() => setShowModal(false)} 
                handleCouponRequests={handleCouponRequests}
            />

            {openConfirmDeleteCoupon && (
                <ConfirmModal
                    open={openConfirmDeleteCoupon}
                    handleConfirm={handleDeleteCoupon}
                    handleClose={() => setOpenConfirmDeleteCoupon(false)}
                    title={<IntlMessage id="confirm_modal.coupon.delete.title" />}
                    content={<IntlMessage id="confirm_modal.coupon.delete.content" />}
                    okText={<IntlMessage id="common.yes" />}
                    cancelText={<IntlMessage id="common.no" />}
                />
            )}

        </div>
    )
}

export default Coupons