import DrawerMenuMobileActions from "@iso/actions/DrawerMenuMobileActions";

const initialState = {
  drawerOpen: false,
};

const drawerMenuMobileReducer = (state = initialState, action) => {
  switch (action.type) {
    case DrawerMenuMobileActions.SHOW_DRAWER_MENU:
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    default:
      return state;
  }
};

export default drawerMenuMobileReducer;
