const settings = {
  day: {
    sun: "Sun",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
  },
  time: {
    "6AM": "6 A.M. - 8 A.M.",
    "8AM": "8 A.M. - 10 A.M.",
    "10AM": "10 A.M. - 12 P.M.",
    "12PM": "12 P.M. - 2 P.M.",
    "2PM": "2 P.M. - 4 P.M.",
    "4PM": "4 P.M. - 6 P.M.",
    "6PM": "6 P.M. - 8 P.M.",
    "8PM": "8 P.M. - 10 P.M.",
    "10PM": "10 P.M. - 12 P.M.",
    "12AM": "12 A.M. - 2 A.M.",
    "2AM": "2 A.M. - 4 P.M.",
    "4AM": "4 A.M. - 6 A.M.",
  },
};

const timezones = [
  { code: 0, label: "UTC +00:00" },
  { code: 1, label: "UTC +01:00" },
  { code: 2, label: "UTC +02:00" },
  { code: 3, label: "UTC +03:00" },
  { code: 4, label: "UTC +04:00" },
  { code: 5, label: "UTC +05:00" },
  { code: 6, label: "UTC +06:00" },
  { code: 7, label: "UTC +07:00" },
  { code: 8, label: "UTC +08:00" },
  { code: 9, label: "UTC +09:00" },
  { code: 10, label: "UTC +10:00" },
  { code: 11, label: "UTC +11:00" },
  { code: 12, label: "UTC +12:00" },
  { code: -11, label: "UTC -11:00" },
  { code: -10, label: "UTC -10:00" },
  { code: -9, label: "UTC -09:00" },
  { code: -8, label: "UTC -08:00" },
  { code: -7, label: "UTC -07:00" },
  { code: -6, label: "UTC -06:00" },
  { code: -5, label: "UTC -05:00" },
  { code: -4, label: "UTC -04:00" },
  { code: -3, label: "UTC -03:00" },
  { code: -2, label: "UTC -02:00" },
  { code: -1, label: "UTC -01:00" },
];

const checked = [true, true, true, true, true, true, true];
const uncheck = [false, false, false, false, false, false, false];

const itemLeavePeriodsDefault = [
  {
    start_date: null,
    end_date: null,
  },
];

const initialValues = {
  time_weekly: {
    "6AM": checked,
    "8AM": checked,
    "10AM": checked,
    "12AM": uncheck,
    "2PM": checked,
    "4PM": checked,
    "6PM": checked,
    "8PM": checked,
    "10PM": uncheck,
    "12PM": checked,
    "2AM": uncheck,
    "4AM": uncheck,
  },
};

const DATA_FORMAT = "YYYY-MM-DD";

const DEFAULT_TIMEZONE = {
  code: null,
  label: "Fail to get timezone.",
};

export {
  timezones,
  settings,
  initialValues,
  itemLeavePeriodsDefault,
  DATA_FORMAT,
  DEFAULT_TIMEZONE,
};
