import * as Yup from "yup"

const SignInFormValidator = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .email("Please enter a correct email!")
    .required(),
  password: Yup.string().label("Password").required(),
})

export { SignInFormValidator }
