import React from "react"
import "./styles.scss"
import RegistrationBox from "@iso/Pages/Common/RegistrationBox/RegistrationBox"
import ForgotPasswordForm from "@iso/components/ForgotPassword/Form"
import IntlMessage from "@iso/components/intlMessage"

const ForgotPassword = () => {
  return (
    <div className="prayer-portal sign-up-container">
      <RegistrationBox
        title={<IntlMessage id="common.message.error.forgot_password" />}
        form={<ForgotPasswordForm loginURL="/admin/sign_in" />}
      />
    </div>
  )
}

export default ForgotPassword
