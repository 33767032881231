import * as Yup from "yup"

const AddformValidator = Yup.object().shape({
  requestID: Yup.string()
  .label("Prayer number") ,
  user_id: Yup.string()
  .label("Prayer Partner")  
  .required(),
  amount_owed: Yup.number()
  .label("Amount Owed") 
  .required()
  .typeError("Amount Owed must be a number"),
  status: Yup.string()
  .label("Status") 
  .required(),
})

const EditPPFormValidator = Yup.object().shape({
  requestID: Yup.string()
  .label("Prayer number"),
  user_id: Yup.string()
  .label("Prayer Partner")  
  .required(),
  amount_owed: Yup.number()
  .label("Amount Owed") 
  .required()
  .typeError("Amount Owed must be a number"),
  status: Yup.string()
  .label("Status") 
  .required(),
  // revenue: Yup.string()
  // .label("Revenue") 
  // .required(),
  // pp_percent: Yup.string()
  // .label("Prayer Partner's percentage") 
  // .required(),
})

const EditUserFormValidator = Yup.object().shape({
  requestID: Yup.string()
  .label("Prayer number")
  .required(),
  amount: Yup.string()
  .label("Amount")  
  .required()
  .typeError("Amount must be a number"),
  client: Yup.number()
  .label("Client") 
  .required()
})
export { AddformValidator, EditPPFormValidator, EditUserFormValidator }
