import React from "react";
import IntlMessage from "@iso/components/intlMessage"; 
import { Row, Col, Form, Button } from "react-bootstrap"; 
import masterCardLogo from "@iso/assets/images/masterCardLogo.svg";
import visaLogo from "@iso/assets/images/visaLogo.svg"; 
import buttonAdd from "@iso/assets/images/button_add.svg";
import UserService from "@iso/services/UserService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import PowerStriple from "@iso/assets/images/powerStriple.svg"
import { isMobile } from "react-device-detect";
import "./selectCardPayment.scss"; 

const SelectCardPayment = ({   
  setShowCardDialog,
  defaultCard,
  setDefaultCard,
  paymentCards, 
  setAddCard,
}) => {
  const handleChangeRadio = (card_id) => { 
    setDefaultCard({
      card: paymentCards.find(
        (item) => item?.id === card_id
      ),
    });
    setAddCard(false) 
  }

  const handleClickRadio = () => {
      handleChangeRadio(defaultCard?.card?.id)
  }
   return (
      <Col xs="12" id="select-payment-wrapper">
        <Col xs="12" id="cardWrapper">
          <Row className="mb-4 custom_payment_method_title">
            <Col xs="6">
              <div className="paymentMethodTitle"><IntlMessage id ="public.request.step3.right.your_cards" /></div>
            </Col>

            <Col xs="6" className="d-flex justify-content-end hippie-btn font-size-20px font-weight-bold" onClick={()=> setShowCardDialog(false)}>
              <IntlMessage id ="common.cancel" />
            </Col>
          </Row> 
          
          <Row className="form-group font-size-14px">
          { 
            paymentCards.map((item) => {
              let checked = defaultCard?.card?.id ==  item?.id
              return (
                <Col xs="12"> 
                  <Form.Check 
                    custom
                    type="radio"
                    name="cardPayment"  
                    className=  {`custom-radio mb-3 ${checked ? 'active-radio': ''}`}
                    value={item?.id}
                    checked={checked } 
                    onChange={
                      (event) => {
                        handleChangeRadio(event.target.value)
                    }
                    } 
                    onClick = {() => {
                      checked && 
                      handleClickRadio()
                    }}
                    label = {
                      <div>
                        <img src = {`${item?.card?.brand === 'visa' ? visaLogo : masterCardLogo}`}/>
                        <span className="ml-2" style={{color: '#254050'}}>{`**** **** **** ${item?.card?.last4}`}</span>
                      </div>
                    }
                    id={item?.id}
                  />
                </Col>
              )
            })
          }
                <Col xs="12"> 
                  <Form.Check 
                    custom
                    type="radio"
                    name="cardPayment"  
                    className="custom-radio mb-3 mt-1"
                    value='addcard'
                    onChange={ () => 
                      {
                        setAddCard(true)
                        setShowCardDialog(false) 
                      }
                    } 
                    label = {
                      <div> 
                          <img src = {buttonAdd}/>
                          <span className="font-size-14px ml-2 " style={{color: '#254050'}}>
                            <IntlMessage id="public.request.step3.right.add.card" />
                          </span>
                      </div>
                    }
                    id="addCard"
                  />
                </Col>  
           </Row>
        </Col>  
      </Col> 
  );
};

export default SelectCardPayment;
