export const NAME_MIN_LENGTH = 2;
export const NAME_MAX_LENGTH = 20;
export const filters = [
  {
    name: "invitation",
    titleId: "admin.prayer_partners.tabs.invitaion_sent.title",
  },
  {
    name: "pending",
    titleId: "admin.prayer_partners.tabs.pending.title",
  },
  {
    name: "changes",
    titleId: "admin.prayer_partners.tabs.profile_changes.title",
  },
  {
    name: "current",
    titleId: "admin.prayer_partners.tabs.current.title",
  },
  {
    name: "former",
    titleId: "admin.prayer_partners.tabs.former.title",
  },
  {
    name: "all",
    titleId: "admin.prayer_partners.tabs.all.title",
  },
];
