import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { DISPLAY_COMPLETED_INFO } from "@iso/constants";
import {
  DATE_FORMAT_PRAYER_REQUEST, SALUTION,
  TIME_FORMAT_PRAYER_REQUEST,
} from "./constants";

import IntlMessage from "@iso/components/intlMessage";
import TranslateReason from "@iso/components/TranslateReason/TranslateReason";
import RequestItem from "@iso/components/Request/Item";
import moment from "moment";

import withAuthorization from "@iso/utils/withAuthorization";
import { USER_PERMISSIONS } from "@iso/constants";
import "./CompletionDetails.scss";
import { useSelector } from "react-redux";
import RequestService from "../../services/RequestService";

const getSalution = (key) => {
  for (var i = 0; i < SALUTION.length; i++) {
    if (SALUTION[i].id === key) {
      return SALUTION[i].label;
    }
  }
}

const showSpaceOriginContent = (string) => {
  return (
    <div>
      {string.split("\n").map((i,key) => {
        return <div><span key={key}>{i}</span><br/></div>;
      })}
    </div>);
}

const CompletionDetails = (props) => {
  const {
    openEditModal,
    requestValue,
    showTransactionCallback,
    forceShowTransactionContent,
    callTranslationResult,
  } = props;

  const [getLangMessage, setGetLangMessage] = React.useState(requestValue.message_complete_language);
  const [isTranslating, setTranslating] = React.useState(false);

  const displayContent = DISPLAY_COMPLETED_INFO.find(
    (status) => status === requestValue.status
  );

  React.useEffect(() => {
    if(requestValue.message_complete_edited){
      setGetLangMessage("English")
    }
  }, [requestValue.message_complete_edited]);

  const TranslateContent = ({ requestValue }) => {
    const { user } = useSelector((state) => state.authentication);
    const displayContent = requestValue.message_complete;
    const request = requestValue;

    const getNamePrayer = () => {
      if (request) {
        if (request.is_gift) {
          return request.recipient_name + ',';
        }
        if (!request.is_gift && request.client_id) {
          return request.client_name + ',';
        }else {
          return 'Guest' + ',';
        }
      } else {
        return 'Guest' + ',';
      }
    }

    return (
      <div>
        <TranslateReason
          title={<IntlMessage id="admin.requests.details.message" />}
          translatedContent={requestValue.message_complete_translate}
          isEdited={requestValue.message_complete_edited}
          originContent={requestValue.message_complete}
          lang={getLangMessage}
          showOriginContent={
            forceShowTransactionContent &&
            forceShowTransactionContent["message_complete"]
          }
          showTransactionCallback={showTransactionCallback}
          name="message_complete"
          request={requestValue}
        />
      </div>
    );
  };

  const Message = ({ requestValue }) => {
    return (
      <RequestItem
        title="Message: "
        value={requestValue.message_complete_translate || requestValue.message_complete}
        displayValueClassName="custom_height_for_display_value"
        request={requestValue}
      />
    );
  };

  const MessageWithAuth = withAuthorization(USER_PERMISSIONS.SUPER_USER)(
    TranslateContent
  );
  const MessageWithPrayerAuth = withAuthorization(USER_PERMISSIONS.PARTNER)(
    Message
  );

  return (
    <>
      {displayContent && (
        <Container className="wrapper_completion_details">
          <hr className="mx-auto mt-5 mb-3" />
          <h3 className="text-center">
            <IntlMessage id="text_completion_details" />
          </h3>
          <div className="pb-4 pl-0">
            <RequestItem
              title="Date: "
              value={moment(requestValue.date_complete).format(
                DATE_FORMAT_PRAYER_REQUEST
              )}
            />
            <RequestItem
              title="Time: "
              value={moment(`2020-10-14 ${requestValue.time_complete}`).format(
                TIME_FORMAT_PRAYER_REQUEST
              )}
            />
            <RequestItem title="Place: " value={requestValue.place_complete} />

            {/* WARNING -- THIS HAVE ISSUE IF USER HAVE MULTIPLE ROLE */}
            <MessageWithAuth requestValue={requestValue} />
            <MessageWithPrayerAuth requestValue={requestValue} />
          </div>
        </Container>
      )}
    </>
  );
};

export default CompletionDetails;
