import prayerPartnerActions from "@iso/actions/PartnerActions";

const initialState = {
  profile: {},
};

export function prayerPartnerReducer(state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case prayerPartnerActions.GET_PRAYER_PARTNER_PROFILE_BY_ID:
      return {
        profile: payload.profile,
      };
    default:
      return state;
  }
}
