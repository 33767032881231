import React, { useState } from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik" 
import AdminsService from "@iso/services/AdminsService";
import { AddAdminFormValidator } from "./validator"
import alertActions from "@iso/actions/Alert"
import { useDispatch } from "react-redux"
import Select from "@material-ui/core/Select";  
import { TextField } from "@material-ui/core";
import IntlMessage from "@iso/components/intlMessage"; 
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel'; 
const { openAlert } = alertActions

function AddForm(props) {
  let initValue = {
    first_name: "",
    last_name: "",
    email: "",
    role: "admin",
  }

  const [formikValues, setFormikValues] = useState(initValue);
  const dispatch = useDispatch()
  const admins = [
    { id: 'admin', label: 'Admin'},
    { id: 'super-admin', label: 'Super Admin'}, 
  ];
 
  const handleSubmit = async (values, bag) => {
 
    AdminsService.createAdmin(values)
    props.handleClose()
    dispatch(
      openAlert({
        message: <IntlMessage id="common.message.send.fail" />,
        severity: "success",
      })
    )
  }

  const handleFieldChange = (name, value) =>{
    formikValues[name] = value;
    setFormikValues({ ...formikValues  }); 
  }
  return (
    <Formik
      initialValues={formikValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={AddAdminFormValidator}
    >
      {({ handleSubmit, handleChange, touched, errors }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Row>
            <Col sm="12">
              <Form.Group controlId="formFirstName" className="mb-4">
                <TextField
                  label={<IntlMessage id ="common.first_name"/> }
                  variant="outlined"
                  name="first_name"
                  autoFocus
                  value = {formikValues.first_name}
                  onChange={(e) => handleFieldChange('first_name', e.target.value)}
                  error={touched.first_name && !!errors.first_name}
                  helperText={touched.first_name && errors.first_name} 
                  />
              </Form.Group> 
            </Col>
          </Row>
          
          <Form.Group controlId="formLastName" className="mb-4">
            <TextField
              label={<IntlMessage id ="common.last_name"/> }
              variant="outlined"
              name="last_name"
              autoFocus
              value = {formikValues.last_name} 
              onChange={(e) => handleFieldChange('last_name', e.target.value)}
              error={touched.last_name && !!errors.last_name}
              helperText={touched.last_name && errors.last_name} 
              />
          </Form.Group>

          <Form.Group controlId="formEmail" className="mb-4">
            <TextField
              label= {<IntlMessage id ="common.email"/> }
              variant="outlined"
              name="email"  
              onChange={(e) => handleFieldChange('email', e.target.value)}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email} 
              />
          </Form.Group>
      
          <FormControl variant="outlined"  >
            <InputLabel><IntlMessage id ="admin.create.admin.acount.select.role"/> </InputLabel>
            <Select
              native
              value = {formikValues.role} 
              onChange={(e) => handleFieldChange('role', e.target.value)} 
              label= {<IntlMessage id ="admin.create.admin.acount.select.role"/>}
            > 
              {admins.map((admin) => (
                <option key={admin.id} value={admin.id} >
                  {admin.label} 
                </option>
              ))}
            </Select>
          </FormControl> 
          {/* Invitation button */}
          <div className="buttons text-center mt-4">
            <Button className="hippie-blue-button" type="submit">
              <IntlMessage id="admin.prayer_partner.form.submit" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default AddForm
