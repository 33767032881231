import {Grid} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Leftside from "./Leftside";
import RightSide from "./Rightside";

const Wrapper = styled(Grid)`
  position: relative;
  margin-top: 50px;

  @media only screen and (min-width: 768px) {
    margin-top: 85px;
  }

  @media only screen and (min-width: 1025px) {
    margin-top: 80px;
  }
`;


const Section3 = (props) => {
  return (
    <Wrapper container spacing={0}>
      <Grid item xs={12} sm={6}>
        <Leftside></Leftside>
      </Grid>
      <Grid item xs={12} sm={6}>
        <RightSide></RightSide>
      </Grid>
    </Wrapper>
  );
};

export default Section3;
