import React, { useState } from "react"
import {
  Button,
  Card,
  Row,
  Spinner,
  Collapse,
  Form,
  Col,
} from "react-bootstrap"
import EditIcon from "@material-ui/icons/Edit"
import DoneIcon from "@material-ui/icons/Done";
import { Formik } from "formik"
import { Checkbox } from "@material-ui/core"
import {shallowEqual, useDispatch, useSelector} from "react-redux"
import _, { isEmpty } from "lodash";

import IntlMessage from "@iso/components/intlMessage"
import { updatePrayerNotify, getPrayerProfile } from "@iso/PrayerPartner/api_v1"
import "./styles.scss"

import StatusText from "../../../../../components/PrayerPartner/Profile/StatusText";
import alertActions from "@iso/actions/Alert"
import { NotificationFormValidator } from "./validator"
import {USER_PERMISSIONS} from "../../../../../constants";
import PrayerPartnerService from "../../../../../services/PrayerPartnerService";
import withAuthorization from "@iso/utils/withAuthorization";
import {useParams} from "react-router-dom";
const { openAlert } = alertActions

const initialValues = {
  receive_sms: true,
  receive_email: true,
  notify_request: true,
  notify_reminder: true,
  receive_sms_status: '',
}

const ManageNotification = ({ openNotification }) => {
  const enableSms = !process.env.REACT_APP_DISABLE_SMS
  const [formData, setFormData] = useState(initialValues)
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector((state) => state.authentication.user);
  const isAdmin = USER_PERMISSIONS.SUPER_USER.roles.includes(user?.role);
  const serviceSubmitNotification = isAdmin ? PrayerPartnerService.adminSaveNotificationSetting : updatePrayerNotify;
  const { id } = useParams();
  const dispatch = useDispatch()
  const { profile } = useSelector(
    (state) => state.prayerPartnerReducer,
    shallowEqual
  );

  const StatusTextWithAuth = withAuthorization(USER_PERMISSIONS.SUPER_USER)(
    StatusText
  );

  const getProfile = () => {
    PrayerPartnerService.getPrayerPartnerById({ id: id }).then((res) => {
      const { code, data, message } = res.data;
      if (code === 200 && data) {
        setFormData(data);
      }
    });
  }

  const getProfilePP = () => {
    getPrayerProfile().then((res) => {
      const { code, data, message } = res.data;
      if (code === 200 && data){
        setFormData(data)
      }
    })
  }

  React.useEffect(() => {
    if (id && isAdmin) {
      if (isEdit) {
        getProfile();
      }
      getProfile();
    }
  }, [id, isEdit]);

  React.useEffect(() => {
    if (!isAdmin){
      getProfilePP()
      if(!isEmpty(profile)) {
        setFormData(profile)
      }
    }else {
      getProfile()
      if(!isEmpty(profile)) {
        setFormData(profile)
      }
    }
  },[])

  const configBody = {
    true: 1,
    false: 0,
  }

  const handleSubmit = async (values) => {
    try {
      let tmp = { ...values }
      Object.keys(tmp).forEach(function (key) {
        return (tmp[key] = configBody[tmp[key]])
      })

      if (isAdmin) {
        tmp['user_id'] = id;
      }
      serviceSubmitNotification(tmp).then((res) => {
        setIsLoading(false)
        if (res.data.code === 200) {
          dispatch(
            openAlert({
              message: "Manage notification successfully update!",
              severity: "success",
            })
          )
          setIsEdit(false)
        } else {
          dispatch(
            openAlert({
              message: "Something went wrong!",
              severity: "error",
            })
          )
        }
      })
    } catch (err) {
      dispatch(
        openAlert({
          message: "Something went wrong!",
          severity: "error",
        })
      )
    }
  }

  const verifyChange = () => {
    PrayerPartnerService.adminChangeSeen({user_id: id}).then((res) => {
      setIsLoading(false)
      if (res.data.code === 200) {
        setFormData(res.data.data)
      }
    })
  }

  React.useEffect(() => {
    setFormData(profile)
  }, [profile])

  const cancelEdit = (resetForm) => {
    setIsEdit(false);
    resetForm();
  }
  console.log(formData)

  return (
    <div className="p-3 account_settings manage-notification">
      <Card.Title className="title p-0 m-0 px-3">
        <span>
          <IntlMessage id="prayer_partners.account_settings.manage_notification.title" />
        </span>
        { !profile?.deleted_at && <Button
          className="float-right hover_pointer btn hippie-blue-link edit_btn"
          onClick={() => setIsEdit(true)}
        >
          <EditIcon className="pr-1 pb-1"/>
          <IntlMessage id="prayer_partners.account_settings.profile.btn.edit"/>
        </Button>}
        { (isAdmin && formData.receive_sms_status === 'changed') && (
          <Button
            className="float-right hover_pointer btn hippie-blue-link edit_btn change_seen_btn"
            onClick={() => verifyChange()}
          >
            <DoneIcon className="pr-1 pb-1" />
            <IntlMessage id="prayer_partners.account_settings.profile.btn.change.seen"/>
          </Button> 
        )}
      </Card.Title>
      <Collapse in={openNotification}  style={{clear: 'both'}}>
        <Card.Body className={!isEdit && 'not-edit-form'}>
          <Formik
            validationSchema={NotificationFormValidator}
            onSubmit={handleSubmit}
            initialValues={ formData }
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setValues,
              resetForm
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={4}>
                    <p className='noWrap'>
                      <IntlMessage id="prayer_partners.account_settings.manage_notification.notify.title" />
                    </p>
                  </Col>

                  <Col xs={12} md={4}>
                    <Form.Group controlId="formRequest" className={!isAdmin && 'disabled-checkbox'}>
                      <Checkbox
                        checked={values.notify_request}
                        name="notify_request"
                        value={values.notify_request}
                        onChange={handleChange}
                        disabled={!(isAdmin && isEdit)}
                        helperText={errors.notify_request}
                      />
                      <p>
                        <IntlMessage id="prayer_partners.account_settings.manage_notification.requests.label" />
                      </p>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={4}>
                    <Form.Group controlId="formReminder" className={!isAdmin && 'disabled-checkbox'}>
                      <Checkbox
                        checked={values.notify_reminder}
                        name="notify_reminder"
                        onChange={handleChange}
                        value={values.notify_reminder}
                        disabled={!(isAdmin && isEdit)}
                      />
                      <p>
                        <IntlMessage id="prayer_partners.account_settings.manage_notification.reminders.label" />
                      </p>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={4}>
                    <p className='noWrap'>
                      <IntlMessage id="prayer_partners.account_settings.manage_notification.receive.title" />
                    </p>
                  </Col>

                  <Col xs={12} md={4}>
                    <Form.Group controlId="formEmail" className={!isAdmin && 'disabled-checkbox'}>
                      <Checkbox
                        checked={values.receive_email}
                        name="receive_email"
                        value={values.receive_email}
                        onChange={handleChange}
                        disabled={!(isAdmin && isEdit)}
                      />
                      <p>
                        <IntlMessage id="prayer_partners.account_settings.manage_notification.email.label" />
                      </p>
                    </Form.Group>
                  </Col>
                  <>
                  {enableSms && (
                    <Col xs={12} md={4}>
                      <Form.Group controlId="formSMS">
                        <Checkbox
                          checked={values.receive_sms}
                          name="receive_sms"
                          value={values.receive_sms}
                          onChange={handleChange}
                          disabled={!isEdit}
                        />
                        <p>
                          <IntlMessage id="prayer_partners.account_settings.manage_notification.sms.label" />
                        </p>
                      </Form.Group>
                      <StatusTextWithAuth
                        displayCondition={formData.receive_sms_status}
                        itemStatus={formData.receive_sms_status}
                      />
                    </Col>
                  )}
                  </>
                </Row>
                {isEdit && (
                  <Row className="justify-content-center card_info_control m-0 row">
                    <Button
                      className="card_info_btn_cancel btn_control mx-1 mb-1 btn btn-primary"
                      onClick={() => cancelEdit(resetForm)}
                    >
                      <IntlMessage id="prayer_partners.account_settings.profile.btn.cancel" />
                    </Button>
                    <Button type="submit" disabled={isLoading} className='btn_control hippie-blue-button mx-1 mb-1 btn btn-primary'>
                      <IntlMessage id="prayer_partners.account_settings.profile.btn.save" />
                      {isLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
          {
            !isAdmin && (
              <p className="light-gray-color mt-3">
                (*) <IntlMessage id="prayer_partners.account_settings.manage_notification.note" />
              </p>
            )
          }
        </Card.Body>
      </Collapse>
    </div>
  )
}

export default ManageNotification
