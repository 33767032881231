import axios from "axios";
import { apiEndpoints } from "@iso/helpers";

export const adminSupportCenterGetListRequests = (filter,status, search, category) => {    
  return axios.get(apiEndpoints.admin_support_center, {
      params: {
        filter: filter,
        status: status,
        search: search, 
        category: category,
      },
    });
  };

export const getRequestDetail  = (id) => {
  return axios.get(`${apiEndpoints.admin_support_center}/${id}/show`);  
}

export const adminSendResponse  = (body) => {
  return axios.post(`admin/contacts/${body.id}/response`, body);  
}

export default {
    adminSupportCenterGetListRequests,
    getRequestDetail,
    adminSendResponse
};
