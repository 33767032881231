import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Leftside from "./Leftside";
import RightSide from "./Rightside";
import CloudBottomImg from "@iso/assets/images/landing_partner_cloud_bottom.svg";
import CloudTopImg from "@iso/assets/images/landing_partner_cloud_top.svg";
import { isTablet } from "react-device-detect"

const Wrapper = styled(Grid)`
  position: relative;
  margin-top: 50px;

  @media only screen and (min-width: 900px) {
    margin-top: 80px;
  }
`;

const CloudTop = styled.img`
  position: absolute;
  top: -100px;
  right: 9%;
  @media only screen and (min-width: 1920px){
    right: 400px;
    height: 139.5px;
    top: -90px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1919px){
    right: 120px;
    height: 139.5px;
    top: -90px;
  }
`;

const CloudBottom = styled.img`
 
  position: absolute;
  
  @media only screen and (min-width: 1920px) {
    max-width: 1148px;
    width: 100%;
    bottom: -42px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-width: 767px) and (max-width: 1919px) {
    max-width: 1148px;
    width: 100%;
    bottom: -42px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-width: 1440px) and (max-width: 1919px) {
    bottom: -75px;
  }

  @media only screen and (max-width: 500px){
    height: 67px;
    left: -30px;
    bottom: -90px;
  }
`;

const Section5 = (props) => {
  return (
    <Wrapper container id="section5" spacing={0}>
      <CloudTop className="d-none d-lg-block" src={CloudTopImg}></CloudTop>
      <Grid item xs={12} sm={isTablet ? 6 : 12} md={6}>
        <Leftside></Leftside>
      </Grid>
      <Grid item xs={12} sm={isTablet ? 6 : 12} md={6}>
        <RightSide></RightSide>
      </Grid>
      <CloudBottom className="section5-footer-cloud" src={CloudBottomImg}></CloudBottom>
    </Wrapper>
  );
};

export default Section5;
