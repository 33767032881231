import { Grid } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import LeftSide from './LeftSide'
import RightSide from './RightSide'
import {isTablet} from "react-device-detect";
import "./index.scss"

const Wrapper = styled(Grid)`
    background: #B0DAF4;
    padding: 45px 100px;

    @media only screen and (max-width: 700px){
        padding: 30px 37px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        padding-bottom: 39px;
    }
`


const Section7 = props => {
    return (
        <Wrapper id="section7" container spacing={3}>
            <Grid item xs={12} sm={isTablet ? 6 : 12} md={6}>
                <LeftSide></LeftSide>
            </Grid>
  
            <Grid item xs={12} sm={isTablet ? 6 : 12} md={6}>
                <RightSide></RightSide>
            </Grid>
        </Wrapper>
    )
}

export default Section7