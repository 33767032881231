import React from "react"
import { get } from "lodash"

export default function ErrorMessageWrapper({
  touched = {},
  errors = {},
  fieldName,
  className = "",
  shouldDisplayErrorDiv = true,
}) {
  const haveErrors = get(errors, fieldName)
  const shouldShowError = get(touched, fieldName) && haveErrors

  return (
    <div className={`pl-1 text-danger error-message ${className} ${shouldShowError || shouldDisplayErrorDiv ? '' : 'd-none'}`}>
      {shouldShowError ? haveErrors : ""}
    </div>
  )
}
