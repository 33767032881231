import React, {useEffect} from "react";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import TitleForTogglePauseModal from "@iso/components/Request/TitleForTogglePauseModal";
import ContentForTogglePauseModal from "@iso/components/Request/ContentForTogglePauseModal";
import IntlMessage from "@iso/components/intlMessage";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";

import HTTPRequests from "@iso/Admin/Pages/Requests/utils";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PauseRequestValidator } from "@iso/components/Request/validator";
import _ from "lodash";
import { Formik, Field, Form } from "formik";

import './ModalTogglePauseRequest.scss';

const ModalTogglePauseRequest = (props) => {
  const { open, requestValue, setRequestValue, handleClose, handleShowChangeAssign } = props;

  const formRef = React.useRef();

  const [stateErrors, setErrors] = React.useState({});

  const handleConfirmUnpause = () => {
    HTTPRequests.handleTogglePauseRequest({
      requestValue,
      setToggleConfirmPauseRequest: handleClose,
      setRequestValue,
      handleShowChangeAssigneeModal: handleShowChangeAssign,
    })
  };

  const handleConfirmPause = (values) => {
    try {
      PauseRequestValidator.validate(values).then(
        () => {
          HTTPRequests.handleTogglePauseRequest({
            requestValue,
            setRequestValue,
            setToggleConfirmPauseRequest: handleClose,
            handleShowChangeAssigneeModal: handleShowChangeAssign,
            flag_types: values,
          });

          handleClose();
          setErrors({});
        },
        (error) => {
          setErrors({ [error.path]: error.message, timestamp: Date.now() });
        }
      );
    } catch (error) {
    }
  };

  const handleConfirm = () => {
    if (requestValue.status === "paused") {
      return handleConfirmUnpause();
    } else {
      if (formRef.current) {
        return formRef.current.handleSubmit();
      }
    }
  };

  return (
    <ConfirmModal
      open={open}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      title={<TitleForTogglePauseModal status={requestValue.status} />}
      okText={<IntlMessage id="common.ok" />}
      cancelText={<IntlMessage id="common.cancel" />}
      content={<ContentForTogglePauseModal status={requestValue.status} endDate={requestValue.end_date} />}
    >
      {requestValue.status !== "paused" && (
        <div className="container text-left modal_toggle_wrapper">
          <Formik
            innerRef={formRef}
            initialValues={{
              flag_types: [],
            }}
            onSubmit={(values) => {
              handleConfirmPause(values);
              return;
            }}
          >
            {({ values, handleSubmit, handleChange, touched, errors }) => (
              <Form className="checkbox_for_flag">
                <h5>
                  <IntlMessage id="prayer_request.pause_modal.select_a_reason" />
                </h5>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value="red"
                      name="flag_types"
                    />
                  }
                  label={
                    <IntlMessage id="prayer_request.pause_modal.red_flag" />
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value="yellow"
                      name="flag_types"
                    />
                  }
                  label={
                    <IntlMessage id="prayer_request.pause_modal.yellow_flag" />
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value="purple"
                      name="flag_types"
                    />
                  }
                  label={
                    <IntlMessage id="prayer_request.pause_modal.purple_flag" />
                  }
                />
                <ErrorMessageWrapper
                  touched={{ flag_types: true }}
                  errors={stateErrors}
                  fieldName="flag_types"
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </ConfirmModal>
  );
};

export default ModalTogglePauseRequest;
