import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  Collapse,
  Row,
  Col,
  Image,
  Form,
  Spinner,
} from "react-bootstrap";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";

import { Formik } from "formik";
import { TextField } from "@material-ui/core";

import noAvatarImage from "@iso/assets/images/no-avatar.png";
import editImage from "@iso/assets/images/pencile.png";
import addImage from "@iso/assets/images/add.png";
import PendingImage2 from "@iso/assets/images/clock-regular.svg";
import IntlMessage from "@iso/components/intlMessage";
import IntlTelInput from "react-intl-tel-input";
import { ProfileInfoFormValidator } from "./validator";
import "./styles.scss";
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper";
import {
  RELIGIONS,
  USER_PERMISSIONS,
  DATE_FORMAT_PROFILE_FORM,
} from "@iso/constants";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 30,
    height: 30,
    // border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const ProfileForm = ({
  handleSubmit,
  onButtonClick,
  isEdit,
  setIsEdit,
  isLoading,
  profile,
  isPending,
  getAvatar,
}) => {

  const cancelEdit = (resetForm) => {
    setIsEdit(false);
    resetForm();
  };

  const [formikValues, setFormikValues] = React.useState(
    new Object({ ...profile })
  );

  const handleValueChange = (name, value) => {
    formikValues[name] = value;
    setFormikValues(formikValues);
  };

  const setCaretPosition = (caretPos) => {
    const elem = document.getElementsByClassName('phone_number')[0];
    if (elem) {
      elem.focus();
      setTimeout(() => {
        elem.setSelectionRange(caretPos, caretPos);
      }, 0);
    }
  };

  return (
    <Formik
      validationSchema={ProfileInfoFormValidator}
      enableReinitialize={true}
      initialValues={profile || formikValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => { 
        handleSubmit(values)
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setFieldTouched,
      }) => (
        
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} lg={3}>
              <div className="image_wrapper mb-4">
                <Badge
                  className="avatar"
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <a href="#" onClick={onButtonClick}>
                      <SmallAvatar
                        alt=""
                        src={isPending ? editImage : addImage}
                      />
                    </a>
                  }
                >
                  <Avatar
                    src={
                      (isPending ? getAvatar : profile?.avatar) ||
                      noAvatarImage
                    }
                    sizes={"large"}
                    style={{
                      width: "100%",
                      height: "100%",
                      opacity: isPending ? 0.5 : 1,
                    }}
                  />
                  {(isPending && (
                    <Image
                      src={PendingImage2}
                      roundedCircle
                      className="image_pending"
                    />
                  )) ||
                    ""}
                </Badge>
              </div>
            </Col>
            <Col xs={12} lg={9}>
              <Form.Group controlId="formFirstName">
                <TextField
                  label="First name"
                  variant="outlined"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  error={touched.first_name && !!errors.first_name}
                  helperText={touched.first_name && errors.first_name}
                  disabled={!isEdit}
                />
              </Form.Group>

              <Form.Group controlId="formLastName">
                <TextField
                  label="Last name"
                  variant="outlined"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  error={touched.last_name && !!errors.last_name}
                  helperText={touched.last_name && errors.last_name}
                  disabled={!isEdit}
                />
              </Form.Group>

              <Col sm="12" className="px-0 mb-3">
                <Form.Label className="phone_number_label prayer_partners">
                  <IntlMessage id="common.phone_number" />
                </Form.Label>
                <IntlTelInput
                  containerClassName="intl-tel-input"
                  inputClassName="form-control phone_number"
                  defaultCountry="us"
                  placeholder="Enter phone number"
                  fieldId="phone_number"
                  fieldName="phone_number"
                  onPhoneNumberBlur={() => {
                    setFieldTouched("phone_number", true);
                    setFieldTouched("calling_code", true);
                  }}
                  onPhoneNumberChange={(isValid, phone, country, fullNumber) => {
                    fullNumber = fullNumber.replace(/[-]/g, "");
                    setFieldValue("phone_number", fullNumber);
                    handleValueChange("phone_number", fullNumber);
                    setFieldValue("calling_code", country.dialCode);
                    handleValueChange("calling_code", country.dialCode);
                    values.phone_number = fullNumber;
                    setCaretPosition(values.phone_number.length)
                  }}
                  onSelectFlag={(currentNumber, country, fullNumber)=>{
                    fullNumber = fullNumber.replace(/[-]/g, "");
                    values.phone_number = fullNumber;
                    setFieldValue("calling_code", country.dialCode);
                    handleValueChange("calling_code", country.dialCode);
                  }}
                  preferredCountries={["us", "gb", "au", "ca"]}
                  value={ ((values?.phone_number  || "").replace(/[-]/g, ""))}
                  disabled={!isEdit}
                />
                <ErrorMessageWrapper 
                  touched={{phone_number: true}}
                  errors={errors}
                  fieldName="phone_number"
                  className="font-size_error custom_error_phone_number"
                />

            
              <Form.Group as={Row} className="select_religion mb-4">
                  <Col sm="12">
                    <Form.Label className="custom_label_select_religion">
                      <InputLabel id="select_religion-label">
                        <IntlMessage id="common.denomination" />
                      </InputLabel>
                    </Form.Label>

                    <Select
                      labelId="religion-label"
                      id="religion"
                      defaultValue={
                        formikValues.religion && formikValues.religion[0]
                      }
                      value={formikValues.religion && formikValues.religion[0]} 
                      name="religion"
                      disabled={!isEdit}
                      onChange={(e) => {
                        setFieldValue("religion", [e.target.value]);
                        handleValueChange("religion", [e.target.value]);
                      }}
                      className="w-100 mt-2 custom_select_religion remove-border-bottom-muiInput"
                    >
                      {RELIGIONS.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Col>
                   
                </Form.Group>
                

                <Form.Group as={Row}>
                  <Col sm="12">
                    <Form.Group controlId="formCity" className="mb-1">
                      <TextField
                        label="City"
                        variant="outlined"
                        name="city"
                        value={values.city || ""}
                        onChange={(e) => {
                          setFieldValue("city", e.target.value);
                          handleValueChange("city", e.target.value);
                        }}
                        error={touched.city && !!errors.city}
                        helperText={touched.city && errors.city}
                        disabled={!isEdit}
                        className={`custom_material_input border_fieldset_${profile.city_status}`}
                      />
                    </Form.Group>
                  </Col> 
                </Form.Group>


                <Form.Group as={Row}>
                  <Col sm="12">
                    <Form.Group controlId="formCountry" className="mb-1">
                      <TextField
                        label="Country"
                        variant="outlined"
                        name="country"
                        value={values.country || ""}
                        onChange={(e) => {
                          setFieldValue("country", e.target.value);
                          handleValueChange("country", e.target.value);
                        }}
                        error={touched.country && !!errors.country}
                        helperText={touched.country && errors.country}
                        disabled={!isEdit}
                        className={`custom_material_input border_fieldset_${profile.country_status}`}
                      />
                    </Form.Group>
                  </Col> 
                </Form.Group>

               
              </Col>
            </Col> 
            


            <Col xs={12}>
              <Form.Group controlId="formTagline">
                <TextField
                  placeholder={
                    "Your Tagline tells the user about yourself in up to 150 characters\nWhat's important to you? What do you love? Your (former) Occupation? \n(For example: mother, grandmother, retired nurse, chocolate lover, devout Christian)"
                    // <IntlMessage id="prayer_partners.account_setting.profile.tagline.placeholder" />
                  }
                  multiline={true}
                  rows={3}
                  type="textarea"
                  label="Tagline"
                  variant="outlined"
                  name="tagline"
                  value={values.tagline}
                  onChange={handleChange}
                  error={touched.tagline && !!errors.tagline}
                  helperText={touched.tagline && errors.tagline}
                  disabled={!isEdit}
                />
              </Form.Group>
            </Col>
          </Row>
          {isEdit && (
            <Row className="justify-content-center card_info_control m-0"> 
              <Button 
                className="card_info_btn_cancel btn_control mx-1 mb-1 btn btn-primary"
                onClick={() => cancelEdit(resetForm)}
              >
                <IntlMessage id="prayer_partners.account_settings.profile.btn.cancel" />
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                className="btn_control hippie-blue-button mx-1 mb-1 btn btn-primary"
              >
                <IntlMessage id="prayer_partners.account_settings.profile.btn.save" />
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
