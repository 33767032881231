import React from "react"
import RegistrationBox from "@iso/Pages/Common/RegistrationBox/RegistrationBox"
import ResetPasswordFrom from "@iso/components/ResetPassword/Form"
import { userRoleConstants } from "@iso/constants/user"

const ForgotPassword = () => {
  return (
    <div className="prayer-portal sign-up-container">
      <RegistrationBox
        title="Reset your password"
        form={
          <ResetPasswordFrom
            loginURL="/prayer_partners/sign_in"
            userRole={userRoleConstants.PRAYER_ROLE}
          />
        }
      />
    </div>
  )
}

export default ForgotPassword
