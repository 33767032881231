import { Typography } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";
import OurValueClouds from "@iso/assets/images/landing_value_clouds.svg";
import OurValueCloudsCommunity from "@iso/assets/images/landing_value_clouds_Community.svg";
import OurValueCloudsEmpath from "@iso/assets/images/landing_value_clouds_Empath.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  font-family: BrandonText;
  padding: 30px;
  background: #afdaf5;
  border-radius: 6px;
  max-width: 360px;
  height: 100%;
  position: relative;
  padding-bottom: 100px;

  @media only screen and (max-width: 375px) {
    padding-bottom: 50px;
    height: 287px;
  }

  @media only screen and (min-width: 376px) and (max-width: 500px){
    padding-bottom: 50px;
    height: 315px;
  }

  @media only screen and (min-width: 900px) {
    margin-top: 75px;
    padding-bottom: 33px;
  }

  @media only screen and (min-width: 1280px) {
    margin-top: 60px;
    padding-bottom: 13px;
  }

  @media only screen and (min-width: 1500px) {
    padding-bottom: 13px;
  }

  min-width: 100%;
`;

const Icon = styled.img`
  position: absolute;
  left: 0;
  right: 0; 
  top: -90px;

  ${(props) => props.index === 0 && "left: -130px; width: 303px;" &&
  !isMobile 
    && `left: -93px; width: 265px; top: -85px;
        ` 
  }
  ${(props) => props.index === 1 && "width: 220px;" && 
  !isMobile 
    && ` 
      width: 201px;
        ` 
  }
  }

    @media only screen and (max-width: 500px) {
    ${(props) => props.index === 0 && "left: 0;  top: -18%; width: 80%"}
    ${(props) => props.index === 1 && "top: -54px; margin-left : auto; margin-right: auto; "}
        ${(props) =>
      props.index === 2 && "left: 0;  top: auto; bottom: 172.750px;  width: 100%"}
  }
    
  @media only screen and (min-width: 768px) {
    margin-left : auto; 
    margin-right: auto;
    ${(props) => props.index === 0 && "height: 220px !important; left: -82px; width: 276px"}
    ${(props) => props.index === 1 && "top: -80px;height: 220px !important;"}
        ${(props) =>
      props.index === 2 && "width: 303px !important; left: 0px; top: -52px"}
  }
  
  @media only screen and (min-width: 1265px) {
    ${(props) =>
      props.index === 2 && "width: 100% !important; top: -88px !important;"}
  }

  @media only screen and (min-width: 1440px) { 
    ${(props) =>
      props.index === 2 && "width: 100% !important; top: -80px !important;"} 
  }
`;

const Title = styled.p`
  margin-top: 110px;
  font-weight: bold;
  font-size: 22px;
  text-align: center;

  @media only screen and (max-width: 500px) {
    margin-bottom: 5px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    font-size : 24px;
    ${(props) =>
      props.index === 2 && "margin-top: 130px;"} 
  }
`;

const Description = styled.p`
  margin-top: -5px;
  text-align: center;
  color: rgba(28, 22, 52, 1);
  font-size: 18px;
  @media only screen and (max-width: 375px) {
    width: 243px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    margin-top: 5px;
    padding: 0 20px;
    ${(props) => props.index === 1 &&
    isMobile &&
    "margin-bottom:28px;" } 
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    width: 243px;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
    margin-top: 5px;
    padding: 0 18px;
  }
  ${(props) => props.index === 0 && 
    !isMobile && 
    "padding-left:30px; padding-right:30px;" }

  ${(props) => props.index === 1 && 
    !isMobile && 
    "padding-left:45px; padding-right:40px;" } 

`;

const BottomCloud = styled.img`
  bottom: -5px;
  left: 0;
  width: 100%;
  position: absolute;
  ${(props) => props.index === 1 && 
    !isMobile && "bottom: -1px"
  }

  @media only screen and (max-width: 500px) {
    bottom: -1px;
    left: -1px;
    width: 101%;
  }
`;

const ValueItem = (props) => {
  const { icon, title, description, index } = props;
  let srcCloud = "";
  switch(index){
    case 0:
      srcCloud = OurValueClouds;
      break;
    case 1:
      srcCloud = OurValueCloudsEmpath;
      break;
    case 2:
      srcCloud = OurValueCloudsCommunity;
      break;
    default:
      srcCloud = OurValueClouds;
      break;
  }
  return (
    <Wrapper>
      <Icon index={index} src={icon}></Icon>
      <Title index={index}>{title}</Title>
      <Description index={index}>{description}</Description>
      <BottomCloud index={index} src={srcCloud}></BottomCloud>
    </Wrapper>
  );
};

export default ValueItem;
