import notificationActions from '@iso/actions/notificationActions'

const initialState = {
  isUpdate: false, 
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case notificationActions.UPDATE_NOTI:
      return {
        isUpdate : true,
        data: action.data,
      }
      case notificationActions.NOT_UPDATE_NOTI:
      return initialState;
    default:
      return initialState;
  }
}
