import React from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik"

import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { CreateNewPasswordValidator } from "./validator"
import { useDispatch } from "react-redux"
import AuthService from "@iso/services/AuthService"
import { setCurrentUser } from "@iso/actions/User"
import { connect } from "react-redux"
import { userRoleConstants } from "@iso/constants/user"
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import TextField from '@material-ui/core/TextField'
import IntlMessage from "@iso/components/intlMessage"
import alertActions from "@iso/actions/Alert"
import { useLocation } from "react-router"
import * as qs from "query-string"
import './styles.scss'
function FormCreateNewPassword(props) {

  const dispatch = useDispatch();
  const { openAlert } = alertActions;
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const location = useLocation();
  const { user_id, token } = qs.parse(location.search) ;

  const [initFormValue, setInitFormValue] = React.useState({
    user_id: user_id,
    token: token,
    password: "",
    confirm_password: "",
  })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit =  (values, bag) => {

    AuthService.resetPasswordApi(values)
    .then((res) => {
      if (!res) {
        bag.setErrors({
          passwordConfirmation: "Somethings went wrong!",
        })
        bag.setSubmitting(false)
      }
      const { code, data } = res.data
      if (code === 200 && data.role === userRoleConstants.USER_ROLE) {
          props.setflagCrtPassword(true);
          props.setData(data);
      } else {
        if (data.message) {
          dispatch(
              openAlert({
                message: data.message,
                severity: "error",
              })
          )
        }
        bag.setErrors({
          passwordConfirmation: data.message,
        })
      }
    })
    .catch((res) => {
      bag.setErrors({
        passwordConfirmation: "Somethings went wrong!",
      })
      bag.setSubmitting(false)
    })

  }

  return (
    <Formik
    initialValues={initFormValue}
    enableReinitialize={false}
    onSubmit={handleSubmit}
    validationSchema={CreateNewPasswordValidator}
  >

    {({ handleSubmit, handleChange, touched, errors }) => (
      <form onSubmit={handleSubmit} noValidate>
        <Form.Group as={Row} className = "d-block p-3">
        <Col>
           <TextField
              fullWidth={true}
              variant="filled"
              className='my-control'
              name="password"
              type={values.showPassword ? 'text' : 'password'}
              onChange={handleChange}
              label={
                <IntlMessage id="prayer_partner.request.form.newpassword.label" />
              }

              InputProps={{
              endAdornment:(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password VisibilityOff"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          />

            <ErrorMessageWrapper
              touched={touched}
              errors={errors}
              fieldName="password"
              className ="font-size-12px p-1"/>
          </Col>
          <Col>
            <TextField
              fullWidth={true}
              variant="filled"
              className='my-control'
              name="confirm_password"
              type="password"
              onChange={handleChange}
              label={
                <IntlMessage id="prayer_partner.request.form.confirm.newpassword.label" />
              }
            />

            <ErrorMessageWrapper
              touched={touched}
              errors={errors}
              fieldName="confirm_password"
              className ="font-size-12px p-1"/>
          </Col>

          <Col>
            <Button variant="primary" size="lg"
            block className ="hippie-blue-sign-in-button py-3 font-weight-bold" type="submit">
              <IntlMessage id="prayer_partner.create_new_password.submit" />
            </Button>
          </Col>
        </Form.Group>


        </form>
      )}
    </Formik>
  )
}

export default FormCreateNewPassword
