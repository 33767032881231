import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Card,
  Table,
} from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import { Link, useParams } from "react-router-dom";
import {
  getReportByMonth,
  sendPrintPaymentRequest,
} from "@iso/PrayerPartner/api_v1";
import PageHeader from "@iso/components/Common/PageHeader";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import { formatCurrency } from "@iso/utils/FormatData";
import alertActions from "@iso/actions/Alert";
import { DATE_FORMAT_PRAYER_REQUEST } from "@iso/constants";
import "./PaymentBenefitDetails.scss";
import ConfirmModal from "@iso/components/Common/ConfirmModal";

const PaymentBenefitDetails = () => {
  const { openAlert } = alertActions;
  const { user } = useSelector((state) => state.authentication);

  const dispatch = useDispatch();
  const { month, year } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [reports, setReports] = React.useState();
  const [openThankModal, setOpenThankModal] = React.useState(false);

  const handleSendPrintPaymentRequest = () => {
    setIsLoading(true);
    try {
      sendPrintPaymentRequest({ month, year }).then((res) => {
        const { code, data, message } = res.data;
        if (code === 200) {
          setOpenThankModal(true);
        } else {
          dispatch(
            openAlert({
              message: message,
              severity: "error",
            })
          );
        }
      });
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (month) {
      try {
        getReportByMonth({ month, year }).then((res) => {
          setIsLoading(true);
          const { code, data, message } = res.data;
          if (code == 200 && data) {
            setReports(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            dispatch(
              openAlert({
                message: message,
                severity: "error",
              })
            );
          }
        });
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  }, [month]);

  return (
    <>
      {isLoading && <LinearProgress />}
      <div className="mint-tulip-background p-3 payment_benefit_details_wrapper">
        <PageHeader
          title="prayer_partners.payments.title"
          backURL="/prayer_partners/payments"
        />

        {reports && (
          <Card className="table_wrapper p-3">
            <div>
              <span
                className="hippie-blue-link hover_pointer float-right"
                onClick={handleSendPrintPaymentRequest}
              >
                <GetAppIcon className="mr-1 light-gray-color" />
                Export
              </span>
            </div>

            <h1 className="gray-color text-center pt-5">
              <IntlMessage id="prayer_partners.payments_details.main_title" />
            </h1>

            <div className="p-4">
              <p className="title">
                <IntlMessage id="prayer_partners.payments_details.prayer_partner" />
                <span>{reports.prayerPartnerName}</span>
              </p>
              <p className="title">
                <IntlMessage id="prayer_partners.payments_details.reporting_period" />
                <span>{reports.month}</span>
              </p>

              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>
                      <IntlMessage id="prayer_partners.payments_details.table.transaction_id" />
                    </th>
                    <th>
                      <IntlMessage id="prayer_partners.payments_details.table.request_id" />
                    </th>
                    <th>
                      <IntlMessage id="prayer_partners.payments_details.table.client_name" />
                    </th>
                    <th>
                      <IntlMessage id="prayer_partners.payments_details.table.completion_date" />
                    </th>
                    <th>
                      <IntlMessage id="prayer_partners.payments_details.table.amount_earned" />
                    </th>
                    <th>
                      <IntlMessage id="prayer_partners.payments_details.table.status" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reports.requests &&
                    reports.requests.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.prayer_request_id}</td>
                          <td>{item?.prayer_request?.client_name}</td>
                          <td>
                            {moment(item.date_complete).format(
                              DATE_FORMAT_PRAYER_REQUEST
                            )}
                          </td>
                          <td>{item.formatted_amount}</td>
                          <td>
                            {item.status}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              <div>
                <p>
                  <b>
                  <IntlMessage id="prayer_partners.payments_details.summary" />
                  </b>
                  <ul>
                    <li>
                      <IntlMessage
                        id="prayer_partners.payments_details.total_amount_earned"
                        values={{
                          amount: formatCurrency(reports && reports.amount),
                        }}
                      />
                    </li>
                    <li>
                      <IntlMessage
                        id="prayer_partners.payments_details.amount_paid"
                        values={{
                          amount: formatCurrency(reports && reports.amountPaid),
                        }}
                      />
                    </li>
                    <li>
                      <IntlMessage
                        id="prayer_partners.payments_details.amount_outstanding"
                        values={{
                          amount: formatCurrency(
                            reports && reports.amountOutstanding
                          ),
                        }}
                      />
                    </li>
                  </ul>
                </p>
              </div>
              <p className="notes_bottom p-3">
                <IntlMessage id="prayer_partners.payments.notes" />
              </p>
            </div>
          </Card>
        )}
      </div>

      <ConfirmModal
        open={openThankModal}
        handleConfirm={() => setOpenThankModal(false)}
        handleClose={() => setOpenThankModal(false)}
        title={
          <IntlMessage id="prayer_partners.payments.modal.confirm.title" />
        }
        okText="Ok"
        content={
          <IntlMessage
            id="prayer_partners.payments.modal.confirm.content"
            values={{ email: user && user.email }}
          />
        }
        contentFooter={
          <IntlMessage id="prayer_partners.payments.modal.confirm.content_footer" />
        }
      />
    </>
  );
};

export default PaymentBenefitDetails;
