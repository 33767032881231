import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import actionsSignIn from '@iso/actions/signinmodal'
import { useDispatch } from "react-redux"
import { isTablet } from "react-device-detect"
const RequestSignIn = () => {
  const dispatch = useDispatch();
  const openSignInModal = () => {
    dispatch(
      actionsSignIn.openModal({
        type: actionsSignIn.SHOW_MODAL,
      }))
  }
  return (
    <Row className = "mt-4 font-size-20px">
      <Col xs="12" lg="10" className="text-center">
        <IntlMessage id={isTablet ? "public.request.has_account.tablet" : "public.request.has_account"}/>
        &nbsp;

       <b onClick ={openSignInModal} className = "hippie-blue-link pointer">
        <IntlMessage id='sidebar.public.signin' /></b>
      </Col>
    </Row>
  );
};

export default RequestSignIn;
