import React from "react";
import IntlMessage from "@iso/components/intlMessage";
import ImgOtherGirl from '@iso/assets/images/other_girl.svg'
import "./ContentAdapter.scss"
import { Row, Image } from "react-bootstrap";

const ContentAdapter = ({ denomination }) => {
  const OrthodoxContent = () => {
    return (
      <Row className="text-center content-orthodox">
        <IntlMessage
          id={`public.request.step2.right.content.${denomination}`}
        />
      </Row>
    );
  };

  const CatholicContent = () => {
    return (
      <Row className="text-center mt-5 content-catholic p-0 p-md-2">
        <IntlMessage
          id={`public.request.step2.right.content.${denomination}`}
        />
      </Row>
    );
  };

  const AnglicanContent = () => {
    return (
      <Row className="text-center mt-3 content-anglican">
        <IntlMessage
          id={`public.request.step2.right.content.${denomination}`}
        />
      </Row>
    );
  };

  const JewishContent = () => {
    return (
      <Row className="text-center mt-3 content-jewish">
        <IntlMessage
          id={`public.request.step2.right.content.${denomination}`}
        />
      </Row>
    );
  };

  const OtherContent = () => {
    return (
      <div className="mt-5 content-other" >
        <Image src={ImgOtherGirl} roundedCircle />
      </div>
    );
  };

  switch (denomination) {
    case "eastern-orthodox":
      return <OrthodoxContent />;
    case "catholic":
      return <CatholicContent />;
    case "anglican":
      return <AnglicanContent />;
    case "jewish":
      return <JewishContent />;
    default:
      return <OtherContent />;
  }
};

export default ContentAdapter;
