import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";

import ChangeAssigneeModal from "./ChangeAssigneeModal";
import {
  DENY_CHANGE_ASSIGNEE_STATUS,
  TOGGLE_PAUSE_STATUS,
  SHOW_DELETE_BUTTON_STATUS,
	SHOW_ARCHIVE_BUTTON_STATUS,
  SHOW_FLAG_AREA,
} from "@iso/components/Request/constants";
import RequestDisplayBuilder from "@iso/components/Request/RequestDisplayBuilder";
import RequestDetailsHeader from "@iso/components/Request/RequestDetailsHeader";

import RequestStatus from "@iso/components/Request/RequestStatus";
import RequestPrayInfo from "@iso/components/Request/RequestPrayInfo";
import CompletionDetails from "@iso/components/Request/CompletionDetails";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import ModalTogglePauseRequest from "@iso/components/Request/ModalTogglePauseRequest";
import HTTPRequests from "./utils";
import RequestFlaggedBuilder from "@iso/components/Request/RequestFlaggedBuilder";
import ActivityLog from "./ActivityLog";

const RequestDetailsCard = (props) => {
  const {
    requestValue,
    forceShowTransactionContent,
    hideAction,
    showTransactionCallback,
    setRequestValue,
    showChangeAssigneeModal,
    setShowChangeAssigneeModal
  } = props;

  const [tempRequestValue, setTempRequestValue] = useState(null);
  const [openConfirmPauseRequest, setToggleConfirmPauseRequest] = useState(
    false
  );
  const [openConfirmDeleteRequest, setOpenConfirmDeleteRequest] = useState(
    false
  );
  const [openConfirmArchiveRequest, setOpenConfirmArchiveRequest] = useState(false);

  const displayChangeAssigneeButton = !DENY_CHANGE_ASSIGNEE_STATUS.find(
    (status) => requestValue.status == status
  );
  const displayTogglePauseButton = TOGGLE_PAUSE_STATUS.find(
    (status) => requestValue.status == status
  );
  const displayDeleteButton = SHOW_DELETE_BUTTON_STATUS.find(
    (status) => requestValue.status == status
  );
	
	const displayArchiveButton = SHOW_ARCHIVE_BUTTON_STATUS.find(
		(status) => requestValue.status == status
	);

  return (
    <>
      {requestValue && (
        <Card className="p-3 height_100per request_detail">
          <RequestDetailsHeader
            request={requestValue}
            setRequest={setRequestValue}
            showChangeAssigneeButton={
              !hideAction && displayChangeAssigneeButton
            }
            actionForChangeAssigneeButton={() =>
              setShowChangeAssigneeModal(true)
            }
            showTogglePauseButton={!hideAction && displayTogglePauseButton}
            actionForTogglePauseButton={() =>
              setToggleConfirmPauseRequest(true)
            }
            showDeleteButton={!hideAction && displayDeleteButton}
            actionForDeleteButton={() => setOpenConfirmDeleteRequest(true)}
            showArchiveButton={!hideAction && displayArchiveButton}
            actionForArchiveButton={() => setOpenConfirmArchiveRequest(true)}
          />

          <RequestStatus request={requestValue} />

          {requestValue.flag_type &&
            SHOW_FLAG_AREA.find((item) =>
              requestValue.flag_type.includes(item)
            ) && <RequestFlaggedBuilder request={requestValue} />}
          <RequestPrayInfo request={requestValue} />
          <CompletionDetails
            requestValue={requestValue}
            showTransactionCallback={showTransactionCallback}
            forceShowTransactionContent={forceShowTransactionContent}
          />

          <hr />
          <RequestDisplayBuilder
            request={requestValue}
            showOriginContent={
              forceShowTransactionContent &&
              forceShowTransactionContent["reason"]
            }
            showTransactionCallback={showTransactionCallback}
            forceShowTransactionContent={forceShowTransactionContent}
          />

          <ActivityLog
            logs={requestValue?.logs}
          />
        </Card>
      )}

      {showChangeAssigneeModal && (
        <ChangeAssigneeModal
          showCloseButton={true}
          showModal={showChangeAssigneeModal}
          closeModal={() => {
            setShowChangeAssigneeModal(false);
            if (tempRequestValue !== null) {
              setRequestValue(tempRequestValue)
            }
          }}
          requestId={requestValue.id}
          request={requestValue}
          handleSubmit={(values) =>
            HTTPRequests.handleChangeAssignee({
              values,
              request: requestValue,
              setShowChangeAssigneeModal,
              setRequestValue,
            })
          }
          setShowChangeAssigneeModal={setShowChangeAssigneeModal}
          setRequestValue={setRequestValue}
        />
      )}

      {openConfirmPauseRequest && (
        <ModalTogglePauseRequest
          open={openConfirmPauseRequest}
          handleClose={() => setToggleConfirmPauseRequest(false)}
          handleShowChangeAssign={(data) => {
            if (data.status !== 'paused') {
              setShowChangeAssigneeModal(true);
              setTempRequestValue(data);
            } else {
              setRequestValue(data)
            }
          }}
          requestValue={requestValue}
          setRequestValue={setRequestValue}
        />
      )}

      {openConfirmDeleteRequest && (
        <ConfirmModal
          open={openConfirmDeleteRequest}
          handleConfirm={() =>
            HTTPRequests.handleDeleteRequest({
              requestValue,
              setOpenConfirmDeleteRequest,
            })
          }
          handleClose={() => setOpenConfirmDeleteRequest(false)}
          title={<IntlMessage id="confirm_modal.delete.title" />}
          content={<IntlMessage id="confirm_modal.delete.content" />}
          okText={<IntlMessage id="common.yes" />}
          cancelText={<IntlMessage id="common.no" />}
        />
      )}
	
	    {openConfirmArchiveRequest && (
		    <ConfirmModal
			    open={openConfirmArchiveRequest}
			    handleConfirm={() =>
				    HTTPRequests.handleArchiveRequest({
					    requestValue,
					    setOpenConfirmArchiveRequest,
				    })
			    }
			    handleClose={() => setOpenConfirmArchiveRequest(false)}
			    title={<IntlMessage id="confirm_modal.archive.title" />}
			    content={<IntlMessage id="confirm_modal.archive.content" />}
			    okText={<IntlMessage id="common.yes" />}
			    cancelText={<IntlMessage id="common.no" />}
		    />
	    )}
    </>
  );
};

export default RequestDetailsCard;
