import confirmRequestModalActions from '@iso/actions/confirmRequestModal'

const initialState = { isOpen: false, title: "user.confirm.form.receive" };

function confirmRequestModal(state = initialState, action) {
  switch (action.type) {
    case confirmRequestModalActions.SHOW_REGISTER_MODAL:
      return {
        isOpen: true,
        title: action?.data?.title || state?.title,
      }

    case confirmRequestModalActions.HIDE_REGISTER_MODAL:
      return {
        isOpen: false,
        title: "user.confirm.form.receive"
      }
    case confirmRequestModalActions.SHOW_CUSTOM_REGISTER_MODAL:
      return {
        isOpen : true,
        title : "user.confirm.subscribe.title",
      }
    case confirmRequestModalActions.SHOW_CUSTOM_REGISTER_MODAL_MOBILE:
      return {
        isOpen : true,
        title : "user.confirm.subscribe.title.mobile",
      }
    case confirmRequestModalActions.SHOW_CHANGE_ASSIGN_MODAL:
      return {
        isOpen : true,
      }
    default:
      return initialState;
  }
}

export default confirmRequestModal
