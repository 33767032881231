import axios from "axios";
import {apiEndpoints} from "@iso/helpers";

export const adminReportsGetRequests = () => {
	return axios.get(apiEndpoints.admin_reports)
};
export const adminReportsRequestByDate = (startDate, endDate) => {
	return axios.get(`${apiEndpoints.admin_request_reports_by_date}?start_date=${startDate}&end_date=${endDate}`)
};
export const adminExportReportsRequestByDate = (startDate, endDate) => {
	return axios.get(`${apiEndpoints.admin_export_request_reports_by_date}?start_date=${startDate}&end_date=${endDate}`)
};
export const adminExportReportsPartnerByDate = (startDate, endDate) => {
	return axios.get(`${apiEndpoints.admin_export_partner_reports_by_date}?start_date=${startDate}&end_date=${endDate}`)
};
export const adminReportsPartnerByDate = (startDate, endDate) => {
	return axios.get(`${apiEndpoints.admin_partner_reports_by_date}?start_date=${startDate}&end_date=${endDate}`, {
		headers: {
			"Content-Disposition": "attachment;filename=report.xls",
			"Content-type": "application/vnd.ms-excel"
		}
	})
};

export default {
	adminReportsGetRequests,
	adminReportsRequestByDate,
	adminReportsPartnerByDate,
	adminExportReportsRequestByDate,
	adminExportReportsPartnerByDate,
};
