import React from "react";
import { useSelector } from "react-redux";

const withAuthorization = ({ roles = [] } = {}) => {
  return (WrappedComponent) => {
    const { user } = useSelector((state) => state.authentication);
    const CAuthorization = (props) => {
      if (!roles.length || roles.indexOf(user?.role) !== -1) {
        return <WrappedComponent {...props} />;
      } else {
        return null;
      }
    };

    return CAuthorization;
  };
};

export default withAuthorization;
