import React, { Component, useState, useEffect } from "react"
import IntlMessage from "@iso/components/intlMessage";
import FormContactUs from "./FormContactUs";
import { Row, Col, Button } from "react-bootstrap";
import CloudContactUs from "@iso/assets/images/CloudContactUs.svg"
import FullLogoImg from "@iso/Pages/Common/FullLogoImg";
import FormThankContactUs from "./FormThankContactUs"
import  ContactUs from "./ContactUs"
import PublicFooter from "@iso/Website/Layout/PublicFooter"
import CloudImg from "@iso/assets/images/CloudContact.svg"
import PublicHeader from "@iso/Website/Layout/PublicHeader"
import { isMobile } from "react-device-detect";
import MobileHeader from "@iso/components/Layout/Share/MobileHeader";
import "./styles.scss"
import CloudContactMobile from "@iso/assets/images/cloud-contact-mobile.svg"

const Main = (props) => {
    const [flagSendContact,setflagSendContact] = useState(false);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [flagSendContact])

    return (
      <div className = "contact-container mt-0 mt-md-5">
        {/*<PublicHeader/>*/}
        <div className = "mobile-header d-block d-md-none">
          {isMobile && <MobileHeader isBlue={true} />}
          <div className="cloud-header-mobile d-block d-md-none">
            <img src={CloudContactMobile} style ={{ width: "100vw" }}/>
          </div>
        </div>
        {
          !flagSendContact ? <ContactUs setflagSendContact = {setflagSendContact}/>
          : <FormThankContactUs />
        }
        <PublicFooter/>
        </div>
    )
}

export default Main
