import * as Yup from "yup";
const MIN_LENGHT_MESSAGE = 350
const MAX_LENGHT_MESSAGE = 800

const CompleteRequestFormValidator = Yup.object().shape({
  date: Yup.string().label("Date").trim().required(),
//  time: Yup.string().label("time").trim().required(),
  place: Yup.string()
    .label("Place")
    .trim()
    .max(100, function ({ max }) {
      return `Your input is too long! Place must be under ${max} characters.`;
    }),
  message: Yup.string()
    .label("Message")
    .required()
    .trim()
    .min(MIN_LENGHT_MESSAGE, function ({ min }) {
      return `Your message is too short! Message must be from ${MIN_LENGHT_MESSAGE} to ${MAX_LENGHT_MESSAGE} characters.`;
    })
    .max(MAX_LENGHT_MESSAGE, function ({ max }) {
      return `Your message is too long! Message must be from ${MIN_LENGHT_MESSAGE} to ${MAX_LENGHT_MESSAGE} characters.`;
    })
});

const PauseRequestValidator = Yup.object().shape({
  flag_types: Yup.string().required("Please select a reason for flagging"),
});

export { CompleteRequestFormValidator, PauseRequestValidator };
