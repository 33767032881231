import React, { useState } from "react";
import styled, { css } from "styled-components";
import PrayerItemExpanded from "./PrayerItemExpanded";
import PrayerItemExpandedDetail from "./PrayerItemExpandedDetail";
import PrayerItemMinimized from "./PrayerItemMinimized";
import IntlMessage from "@iso/components/intlMessage"

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #afdaf5;
  border-radius: 6px;
  margin-top: 18px;
  @media (max-width: 800px) {
    margin-top: 0px !important;
    margin-bottom: 18px;
  }
`;
const WrapperExpanded = styled.div`
  background: #ffffff;
  border: 1px solid #afdaf5;
  margin-top: 18px;
  @media (max-width: 400px) {
    margin-top: 0px !important;
  }
  border-radius: 6px;
`;

const PrayerItem = (props) => {
  const { prayer, setPrayers, detailsPrayer, fetchPrayerDetailsData, setIsMinimized, isMinimized, setDetail, isDetail, back, setBack } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShowDetail, setIsShowDetail] = useState(false)
  const handleToggleExpand = () => {
    if(isDetail){
      setDetail(!isDetail)
    }else{
      setIsExpanded(!isExpanded);
      setIsMinimized(!isExpanded);
      setIsShowDetail(!isExpanded);
    }
  };

  const getFrequencyLabel = (frequencyID) => {
    switch(frequencyID){
      case 'one_time':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.one_time" /> 
        ) 
      case 'daily':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.daily_1_week" /> 
        )  
      case 'weekly':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.weekly_1_month" /> 
        ) 
      case 'monthly':
        return (
          <IntlMessage id="public.request.step2.frequency.eastern-orthodox.monthly_1_year" /> 
        ) 
    }
  }
  return (
      <>
      {!isExpanded && (!isMinimized || isShowDetail) && (
        <Wrapper>
        <PrayerItemMinimized
          prayer={prayer}
          handleToggleExpand={handleToggleExpand}
          getFrequencyLabel={getFrequencyLabel}
        ></PrayerItemMinimized>
        </Wrapper>
      )}
      {isExpanded && (
        <WrapperExpanded>
        <PrayerItemExpanded
          prayer={prayer}
          back={back}
          setBack={setBack}
          setPrayers={setPrayers}
          handleToggleExpand={handleToggleExpand}
          getFrequencyLabel={getFrequencyLabel} 
          setDetail={setDetail}
          isDetail={isDetail}
          fetchPrayerDetailsData={fetchPrayerDetailsData}
          detailsPrayer={detailsPrayer}
        ></PrayerItemExpanded>
        </WrapperExpanded>
      )}
    </>
  );
};

export default PrayerItem;
