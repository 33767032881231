import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

import CustomButton from "@iso/components/AtomicComponents/Button";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import ProfileAvatar from "./ProfileAvatar";
import ProfileInfo from "./ProfileInfo";
import { useSelector, shallowEqual } from "react-redux";

import UserService from "@iso/services/UserService";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { isMobile } from "react-device-detect";
import { translate } from "@iso/utils/Translate";
import { userRoleConstants } from "@iso/constants/user";
import NotificationModal from "./NotificationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  header: {
    height: "121px",
    padding: "28px 50px;",
    borderBottom: "1px solid #D9E9F1",
    display: "flex",
    alignItems: "center",
  },
  content: {
    padding: "30px 35px",
  },
}));

const Profile = (props) => {
  const dispatch = useDispatch();
  const [currentProfile, setCurrentProfile] = useState();
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [edit, setEdit] = React.useState(false);
  const [openModalNoti, setOpenModalNoti] = useState(false);

  const getUserInfo = () => {
    UserService.getProfile().then((res) => {
      const { code, data } = res.data;
      if (code === 200) {
        setCurrentProfile(res.data.data);
      }
    });
  };
 
  const formRef = React.useRef();

  const classes = useStyles();

  const handleToggleEdit = () => {
    if (currentProfile?.role == userRoleConstants.USER_ROLE) {
      setEdit(!edit);
    } else {
      setOpenModalNoti(true)
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleSubmitInfoForm = (values) => {
    if (edit && formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleCancelEdit = () => {
    if (formRef.current) {
      formRef.current.setValues({
        first_name: currentProfile.first_name || "",
        last_name: currentProfile.last_name || "",
        email: currentProfile.email || "",
        birth: currentProfile.birth || "",
        religion: currentProfile.religion ? currentProfile.religion[0] : [],
        country: currentProfile.country || "",
        phone_number: currentProfile.phone_number || "",
        ...currentProfile,
      });
      formRef.current.setTouched({}, false);
    }

    handleToggleEdit();
  };

  const editTitle = () => {
    let title = 'Profile'

    if (isMobile) {
      title = 'Profile'
      return title
    }

    if (edit) {
      title = 'Editing Profile'
    }

    return title
  }

  const handleCloseModalNoti = () => {
    setOpenModalNoti(false)
  }

  return (
    <Card className={classes.root + " " + "account_page__profile"}>
      <CardHeader
        className={`${classes.header} info-header pl-md-5 text-left`}
        action={
          <div className="d-flex justify-content-center align-items-center align-self-center">
            {edit && (
              <span
                className="button__cancel mr-4"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </span>
            )}
            <CustomButton
              className="button__editProfile"
              variant="primary"
              aria-label="edit account"
              style={{padding: "15px"}}
              handleClick={() => {
                edit ? handleSubmitInfoForm() : handleToggleEdit();
              }}
            >
              {loadingProfile ? (
                <CircularProgress
                  size="20px"
                  className="ml-2"
                  style={{ color: "white" }}
                ></CircularProgress>
              )
                :
                (!edit ? "Edit" : "Save")
              }
            </CustomButton>
          </div>
        }
        title={
          <span>
            <span className='mobile_avatar d-block d-xl-none'>
              {
                currentProfile && <ProfileAvatar user={currentProfile} />
              }
            </span>
            <span>{editTitle()}</span>
          </span>
        }
      />
      <CardContent className={`${classes.content} position-relative`}>
        <Grid container spacing={0} className="justify-content-around">
          <Grid item xs={12} lg={4} className='d-none d-xl-block'>
            {currentProfile && <ProfileAvatar user={currentProfile} />}
          </Grid>

          <Grid item xs={12} lg={7}>
            {currentProfile && (
              <ProfileInfo
                formRef={formRef}
                user={currentProfile}
                edit={edit}
                handleToggleEdit={handleToggleEdit}
                handleSubmit={handleSubmitInfoForm}
                setProfile={setCurrentProfile}
                setLoadingProfile={setLoadingProfile}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
      <NotificationModal open={openModalNoti} handleClose={handleCloseModalNoti} />
    </Card>
  );
};

export default Profile;
