import React from "react";
import { Row, Col } from "react-bootstrap";

const RequestStatement = ({ value, title, displayValueClassName }) => {  
  return (
    <Row className="mt-3 request_item_wrapper">
      <Col xs="12" sm="4" md="4" lg="3" className="bullet">
        <span className="font-weight-bold">{title}</span>
      </Col>
      <Col xs="12" sm="8" md="8" lg="9" className= {`${displayValueClassName} margin-left-content`}>
        {value}
      </Col>
    </Row>
  );
};

export default RequestStatement;
