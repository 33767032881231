import React, { Component, useState } from "react"; 
import Button from "@material-ui/core/Button";
import { history } from "@iso/helpers";
import UserLogo from "@iso/assets/images/userLogo.svg"
import { makeStyles } from "@material-ui/core/styles"; 

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'capitalize',
    fontSize: '16px',
    color: '#1c1634',
    fontFamily: 'BrandonText',
  },
  hasPrayerBtn: {
    marginTop: '-5px',
    marginLeft: '10px',
  },

}))
const PublicSubMenuSidebar = (props) => {
  const handleClick = (event) => {
    history.push("/account");
  };

  const { user, isBlue, dspPrayerBtn } = props;
  const classes = useStyles();
  
  return (
    <React.Fragment>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color={`${!isBlue ? "primary" : ""}`}
        className={`${dspPrayerBtn && classes.hasPrayerBtn}`}
      >
        <img src = {UserLogo} className="mr-2"/> 
        <span className={classes.title}>{user?.full_name}</span>
      </Button>
    </React.Fragment>
  );
};

export default PublicSubMenuSidebar;
