import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import LeftSlideIcon from "@iso/assets/images/LeftSlideIcon.svg";
import RightSlideIcon from "@iso/assets/images/RightSlideIcon.svg";
import ActiveDot from "@iso/assets/images/ActiveDot.svg";
import InactiveDot from "@iso/assets/images/InactiveDot.svg";
import FeedbackCard from "./FeedbackCard";

const FEEDBACK_LIST = [
  ["emily", "tuan"],
  ["alexis", "brett"],
  ["alexandra", "ellen"],
];
const TOTAL_PAGE = FEEDBACK_LIST.length;
const FeedbacksTablet = (props) => {
  const { feedbackList } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [dotList, setDotList] = useState([]);
  const [currentFeedbackList, setCurrentFeedbackList] = useState([]);

  const goToNextPage = () => {
    if (currentPage < TOTAL_PAGE) {
      setCurrentPage(currentPage + 1);
    }
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    setCurrentFeedbackList(FEEDBACK_LIST[currentPage - 1]);
    const dots = [];
    for (let i = 0; i < TOTAL_PAGE; i++) {
      dots.push(i === currentPage - 1 ? "ACTIVE" : "INACTIVE");
    }
    setDotList(dots);
  }, [currentPage]);
  return (
    <div>
      <div className="feedback-container">
        <Row className="feedback-row">
          <Col md={12}>
            {currentFeedbackList[0] && feedbackList[currentFeedbackList[0]] && (
              <FeedbackCard
                avatar={feedbackList[currentFeedbackList[0]]["avatar"]}
                name={feedbackList[currentFeedbackList[0]]["name"]}
                address={feedbackList[currentFeedbackList[0]]["address"]}
                denomination={
                  feedbackList[currentFeedbackList[0]]["denomination"]
                }
                introduction={
                  feedbackList[currentFeedbackList[0]]["introduction"]
                }
                feedback={feedbackList[currentFeedbackList[0]]["feedback"]}
                leftCloud={
                  feedbackList[currentFeedbackList[0]]["leftCloud"] ?? null
                }
                height={feedbackList[currentFeedbackList[0]]["height"]}
                width={feedbackList[currentFeedbackList[0]]["width"]}
              ></FeedbackCard>
            )}
          </Col>
          <Col md={12}>
            {currentFeedbackList[1] && feedbackList[currentFeedbackList[1]] && (
              <FeedbackCard
                avatar={feedbackList[currentFeedbackList[1]]["avatar"]}
                name={feedbackList[currentFeedbackList[1]]["name"]}
                address={feedbackList[currentFeedbackList[1]]["address"]}
                denomination={
                  feedbackList[currentFeedbackList[1]]["denomination"]
                }
                introduction={
                  feedbackList[currentFeedbackList[1]]["introduction"]
                }
                feedback={feedbackList[currentFeedbackList[1]]["feedback"]}
                leftCloud={
                  feedbackList[currentFeedbackList[1]]["leftCloud"] ?? null
                }
                height={feedbackList[currentFeedbackList[1]]["height"]}
                width={feedbackList[currentFeedbackList[1]]["width"]}
              ></FeedbackCard>
            )}
          </Col>
          <img
              className="left-slide-icon"
              src={LeftSlideIcon}
              onClick={() => {
                goToPreviousPage();
              }}
            ></img>
            <img
              className="right-slide-icon"
              src={RightSlideIcon}
              onClick={() => {
                goToNextPage();
              }}
            ></img>
        </Row>
      </div>
      <div className="dot-container">
        <div className="dots">
          {dotList.map((dot, index) => {
            return (
              <img
                className="dot"
                src={dot === "ACTIVE" ? ActiveDot : InactiveDot}
                onClick={() => {
                  setCurrentPage(index + 1);
                }}
              ></img>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default FeedbacksTablet;
