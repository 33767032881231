import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";

import ChangeAssigneeModal from "./ChangeAssigneeModal";
import {
  FLAGGED_STATUS,
  SHOW_DELETE_BUTTON_STATUS,
	SHOW_ARCHIVE_BUTTON_STATUS,
} from "@iso/components/Request/constants";
import RequestDisplayBuilder from "@iso/components/Request/RequestDisplayBuilder";
import RequestFlaggedBuilder from "@iso/components/Request/RequestFlaggedBuilder";
import RequestDetailsHeader from "@iso/components/Request/RequestDetailsHeader";
import RequestStatus from "@iso/components/Request/RequestStatus";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import LinearProgress from "@material-ui/core/LinearProgress";
import ModalTogglePauseRequest from "@iso/components/Request/ModalTogglePauseRequest";

import HTTPRequests from "./utils";
import ActivityLog from "./ActivityLog";
const RequestDetailsFlagged = (props) => {
  const {
    requestValue,
    forceShowTransactionContent,
    hideAction,
    showTransactionCallback,
    setRequestValue,
    showChangeAssigneeModal,
    setShowChangeAssigneeModal
  } = props;

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [tempRequestValue, setTempRequestValue] = useState(null);
  const [loading, setLoading] = useState(false);
	const [openConfirmArchiveRequest, setOpenConfirmArchiveRequest] = useState(false);
  const [openConfirmPauseRequest, setToggleConfirmPauseRequest] = useState(
    false
  );

  const [openConfirmDeleteRequest, setOpenConfirmDeleteRequest] = useState(
    false
  );

  const [showUnPauseButton, setShowUnPauseButton] = React.useState(
    FLAGGED_STATUS.find((status) => requestValue.status === status)
  );

  const displayDeleteButton = SHOW_DELETE_BUTTON_STATUS.find(
    (status) => requestValue.status == status
  );

  const displayArchiveButton = SHOW_ARCHIVE_BUTTON_STATUS.find(
    (status) => requestValue.status == status
  );

  return (
    <>
      {loading && <LinearProgress />}
      {requestValue && (
        <Card className="p-3 height_100per request_detail request_details_flagged">
          <RequestDetailsHeader
            request={requestValue}
            setToggleConfirmPauseRequest={setToggleConfirmPauseRequest}
            setOpenAlertModal={setOpenAlertModal}
            actionForChangeAssigneeButton={() => setOpenAlertModal(true)}
            actionForTogglePauseButton={() =>
              setToggleConfirmPauseRequest(true)
            }
            showChangeAssigneeButton={false}
            showTogglePauseButton={showUnPauseButton}
            showDeleteButton={displayDeleteButton}
            actionForDeleteButton={() => setOpenConfirmDeleteRequest(true)}
            showArchiveButton={displayArchiveButton}
            actionForArchiveButton={() => setOpenConfirmArchiveRequest(true)}
          />

          <RequestStatus request={requestValue} />
          <RequestFlaggedBuilder request={requestValue} />
          <hr className="separate_hr" />
          <RequestDisplayBuilder
            request={requestValue}
            showOriginContent={
              forceShowTransactionContent &&
              forceShowTransactionContent["reason"]
            }
            showTransactionCallback={showTransactionCallback}
            forceShowTransactionContent={forceShowTransactionContent}
          />
          <ActivityLog
            logs={requestValue?.logs}
          />
        </Card>
      )}
      
      {showChangeAssigneeModal && (
        <ChangeAssigneeModal
            showCloseButton={false}
          showModal={showChangeAssigneeModal}
          closeModal={() => {
            setShowChangeAssigneeModal(false);
            if (tempRequestValue !== null) {
              setRequestValue(tempRequestValue)
            }
          }}
          requestId={requestValue.id}
          request={requestValue}
          handleSubmit={(values) =>
            HTTPRequests.handleChangeAssignee({
              values,
              request: requestValue,
              setShowChangeAssigneeModal,
              setRequestValue,
            })
          }
          setShowChangeAssigneeModal={setShowChangeAssigneeModal}
          setRequestValue={setRequestValue}
        />
      )}

      {openAlertModal && (
        <ConfirmModal
          open={openAlertModal}
          handleConfirm={() => setOpenAlertModal(false)}
          handleClose={() => setOpenAlertModal(false)}
          title={
            <IntlMessage id="admin.requests.request_details_card.alert_modal.title" />
          }
          okText={<IntlMessage id="common.close" />}
          content={
            <IntlMessage id="admin.requests.request_details_card.alert_modal.content" />
          }
        />
      )}

      {openConfirmPauseRequest && (
        <ModalTogglePauseRequest
          open={openConfirmPauseRequest}
          handleShowChangeAssign={(data) => {
            if (data.status !== 'paused') {
              setShowChangeAssigneeModal(true);
              setTempRequestValue(data);
            } else {
              setRequestValue(data)
            }
          }}
          handleClose={() => setToggleConfirmPauseRequest(false)}
          requestValue={requestValue}
          setRequestValue={setRequestValue}
        />
      )}

      {openConfirmDeleteRequest && (
        <ConfirmModal
          open={openConfirmDeleteRequest}
          handleConfirm={() =>
            HTTPRequests.handleDeleteRequest({
              requestValue,
              setOpenConfirmDeleteRequest,
            })
          }
          handleClose={() => setOpenConfirmDeleteRequest(false)}
          title={<IntlMessage id="confirm_modal.delete.title" />}
          content={<IntlMessage id="confirm_modal.delete.content" />}
          okText={<IntlMessage id="common.yes" />}
          cancelText={<IntlMessage id="common.no" />}
        />
      )}

      {openConfirmArchiveRequest && (
        <ConfirmModal
          open={openConfirmArchiveRequest}
          handleConfirm={() =>
            HTTPRequests.handleArchiveRequest({
              requestValue,
              setOpenConfirmArchiveRequest,
            })
          }
          handleClose={() => setOpenConfirmArchiveRequest(false)}
          title={<IntlMessage id="confirm_modal.archive.title" />}
          content={<IntlMessage id="confirm_modal.archive.content" />}
          okText={<IntlMessage id="common.yes" />}
          cancelText={<IntlMessage id="common.no" />}
        />
      )}
    </>
  );
};

export default RequestDetailsFlagged;
