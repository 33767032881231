import React from "react";
import {
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import IntlMessage from "@iso/components/intlMessage";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TAB_MENU } from "@iso/Website/Pages/Account/constants";
import { useLocation } from "react-router";
import * as qs from "query-string";
import { history } from "@iso/helpers";
import { logoutUser, getUser } from "@iso/actions/User";
import { useDispatch } from "react-redux";

const ActiveTabIndicator = styled.div`
  position: absolute;
  ${(props) => (props.value < 7 ? "top: 240px;" : "")}
  ${(props) => (props.value < TAB_MENU.logout ? "top: 180px;" : "")}
  ${(props) => (props.value < TAB_MENU.change_password ? "top: 120px;" : "")}
  ${(props) => (props.value < TAB_MENU.my_prayers ? "top: 60px;" : "")}
  ${(props) => (props.value < TAB_MENU.billing_history ? "top: 0px;" : "")}
  height: 60px;
  left: 0px;
  background: rgb(82, 185, 212);
  box-shadow: rgb(236, 244, 248) 0px 1px 0px;
  width: 12px;
  transition: all 0.2s ease-in-out;
`;

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tabs: {
    fontFamily: "BrandonText",
    // borderRight: `1px solid ${theme.palette.divider}`,
  },
  accordionRoot: {
    "&::before": {
      background: "none",
    },
    margin: "0px !important",
    border: "none",
    outline: "none",
    boxShadow: "none",
  },
  accordionTitle: {
    fontFamily: "BrandonText",
    fontSize: "18px",
    paddingLeft: "74px",
    color: "rgba(0, 0, 0, 1)",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "36px",
    },
  },
  accordionSummary: {
    margin: "0px",
    height: "60px",
    borderBottom: "1px solid #D9E9F1",
  },
  accordionDetails: {
    flexDirection: "column",
    padding: "0px !important",
  },
  menuActive: {
    fontWeight: "bold",
    color: "#52B9D4 !important",
    boxShadow: "0px 1px 0px #ECF4F8",
  },
  subMenuActive: {
    fontWeight: "bold",
    background: "#ECF4F8",
    boxShadow: "0px 1px 0px #ECF4F8",
  },
  subMenu: {
    fontSize: "16px !important",
    color: "#000000",
    height: "38px !important",
    "& span": {
      "@media (min-width: 1440px)" : {
        paddingLeft: "95px !important",
      },
      "@media (min-width: 600px) and (max-width: 800px)" : {
        paddingLeft: "50px !important",
      }
    },
  },
}));

const MobileMenuTabs = (props) => {
  const { value, handleChange, isMobileMenu = false, menu } = props;
  const classes = useStyles();
  const accordionActiveStyle = {
    color: "#52B9D4",
    fontWeight: "bold",
  };

  const dispatch = useDispatch();

  return (
    <>
      <Tabs
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        orientation="vertical"
        variant="scrollable"
        value={value}
        // onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab
          className={value === TAB_MENU.profile ? classes.menuActive : ""}
          onClick={() => handleChange(TAB_MENU.profile)}
          label="Profile"
          {...a11yProps(TAB_MENU.profile)}
        />

        <Tab
          className={
            value === TAB_MENU.payment_methods ? classes.menuActive : ""
          }
          onClick={() => handleChange(TAB_MENU.payment_methods)}
          label="Payment Info"
          {...a11yProps(TAB_MENU.payment_methods)}
        />

        <Tab
          className={value === TAB_MENU.my_prayers ? classes.menuActive : ""}
          onClick={() => handleChange(TAB_MENU.my_prayers)}
          label="My Prayers"
          {...a11yProps(TAB_MENU.my_prayers)}
        />
        <Accordion
          expanded={
            value === TAB_MENU.change_password ||
            value === TAB_MENU.deactivate_account
          }
          className={classes.accordionRoot}
          {...a11yProps(TAB_MENU.change_password)}
          {...a11yProps(TAB_MENU.deactivate_account)}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            aria-controls="settings-menu"
            id="account__settings-menu"
            onClick={() => handleChange(TAB_MENU.change_password)}
          >
            <Typography
              className={classes.accordionTitle}
              style={
                value === TAB_MENU.change_password ||
                value === TAB_MENU.deactivate_account
                  ? accordionActiveStyle
                  : {}
              }
            >
              Settings
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Tab
              className={`${classes.subMenu}
                    ${
                      value === TAB_MENU.change_password
                        ? classes.subMenuActive
                        : ""
                    }`}
              onClick={() => handleChange(TAB_MENU.change_password)}
              label={
                <IntlMessage id="user.account.settings.change_password.title" />
              }
            />
            <Tab
              className={`${classes.subMenu}
                  ${
                    value === TAB_MENU.deactivate_account
                      ? classes.subMenuActive
                      : ""
                  }`}
              onClick={() => handleChange(TAB_MENU.deactivate_account)}
              label={
                <IntlMessage id="user.account.settings.deactivate_account.title" />
              }
            />
          </AccordionDetails>
        </Accordion>
        <Tab
          className={value === TAB_MENU.logout ? classes.menuActive : ""}
          onClick={() => {
            dispatch(logoutUser());
            history.push("/");
            // handleChange(TAB_MENU.logout)
          }}
          label="Log Out"
          {...a11yProps(TAB_MENU.logout)}
        />
        <ActiveTabIndicator value={value} />
      </Tabs>
    </>
  );
};

export default MobileMenuTabs;
