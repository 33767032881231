import React from "react";
import styled, { css } from "styled-components";

import { Spinner } from "react-bootstrap";
import "./Button.scss"
const ButtonStyled = styled.button`
  margin: 5px;
  color: black;
  background: #fff;
  border-radius: 6px;
  padding: 15px 30px;
  font-weight: bold;
  font-size: 20px;
  border: none !important;
  outline: none !important;
  height: 48px;
  line-height: 1;
  min-width: 90px;
  box-shadow: inset 0 -3px 0 rgba(0,0,0,.2);

${(props) =>
  props.variant == "default" &&
  css`
    min-width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 30px;
    box-shadow: unset !important;
  `};

${(props) =>
  props.variant == "submit" &&
  css`
    color: white;
  `};
${(props) =>
  props.variant == "primary" &&
  css`
    color: white;
    background: #499e9e;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.2) !important;
  `}

  }
}
`;

const Button = (props) => {
  const {
    children,
    handleClick,
    className,
    variant,
    isSubmitting,
    disabled,
    type = "button",
    style,
  } = props;

  return (
    <ButtonStyled
      onClick={handleClick}
      className={className}
      variant={variant}
      disabled={disabled}
      style={style}
      type={type}
    >
      {children}
      {isSubmitting && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="ml-2 mb-1"
        />
      )}
    </ButtonStyled>
  );
};

export default Button;
