import React, { Suspense } from "react";
import styled from "styled-components";
import horizontalImage from "@iso/assets/images/horizontalApp.svg" 
import IntlMessage from "@iso/components/intlMessage"
import { isMobile } from "react-device-detect";


const HorizontalContainer = styled.div`
  background-color: #AFDAF5;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const MobileHorizontalImage = styled.img` 

`
const WarningMessage = styled.p`
  font-family: BrandonText;
  font-size: 16px;
  line-height: 22.24px;
  color: #000000;
  text-align: center;
  width: 320px;
  margin-top: 30px;
  font-weight: 400px;
`

const HorizontalApp = () => {

  return (
    <HorizontalContainer id="hor-wrapper" style={{display: 'none', position: 'fixed', zIndex: "999999"}}>
         <MobileHorizontalImage src={horizontalImage} /> 
         <WarningMessage><IntlMessage id="mobile.horizontal.warning.message" /></WarningMessage>
    </HorizontalContainer>
  )
}

export default HorizontalApp
