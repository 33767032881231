import * as Yup from "yup"
let user_prayer = false
const ContactUsFormValidator = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .email("Please input a valid email")
    .required(),
  subject: Yup.string()
    .label("Subject")
    .test(
      "maxLength",
      "Maximum 100 characters allowed",
      (val) => !val || val.length <= 100
    ),
  category: Yup.string().label("Category").required(),
  message: Yup.string()
    .label("Reason")
    .required()
    .when("category", {
      is: "user",
      then: Yup.string().test(
        "maxLength",
        "Maximum 1000 characters allowed",
        (val) => !val || val.length <= 1000
      ),
      otherwise: Yup.string().test(
        "maxLength",
        "Maximum 500 characters allowed",
        (val) => !val || val.length <= 500
      ),
    }),
})

export { ContactUsFormValidator }
