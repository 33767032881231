import { Button, Grid, Typography } from '@material-ui/core'
import React from 'react'
import ArrowForward from '@iso/assets/images/account_prayers_arrow.svg'
import { makeStyles } from '@material-ui/core/styles'
import { PRAYER_TYPES_AND_ICONS } from '@iso/components/Request/constants'
import PrayerDefault from '@iso/assets/images/prayer_type_default.svg'
import moment from 'moment'
import IntlMessage from '@iso/components/intlMessage'
import { STATUS, STATUS_LABEL } from '@iso/components/Request/NotificationLog'
import { isMobile, isTablet } from 'react-device-detect'
import './style.scss'

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      borderRight: "1px solid #AFDAF5",
    },
 
  },
  image: {
    width: "40px",
    height: "40px",
  },
  imageContainerMobile: {
    position: "absolute",
    top: 0,
    left: 0,
    borderRight: "1px solid #AFDAF5",
    borderBottom: "1px solid #AFDAF5",
  },
  expandButton: {
    position: "absolute",
    textTransform: "none",
    top: "12px",
    right: "15px",
    fontFamily: "BrandonText",
    fontSize: "16px",
    border: "none",
    outline: "none !important",
    [theme.breakpoints.down("xs")]: {
      right: "6px",
      top: "21px",
    },
  },

  detailsContainer: {
    position: "relative",
    padding: "20px 28px",
  },
  detailsContainerTablet: {
    position: "relative",
    padding: "10px 28px",
  },
  title: {
    fontSize: "18px",
    fontFamily: "BrandonText",
    fontWeight: "bold",
    color: '#1c1634',
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
  },
  titleMobile: {
    marginLeft: "55px",
  },
  detailTitle: {
    fontSize: "14px",
    fontFamily: "BrandonText",
    color: "#6D7278",
    marginTop: "25px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "17px",
    }
  },
  detail: {
    fontFamily: "BrandonText",
    marginTop: "5px",
    fontSize: "16px",
    color: "#1C1634",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    }
  },
  capitalized: {
    textTransform: "capitalize",
  },
  bold: {
    fontWeight: "bold",
  },
  completed: { color: theme?.status?.completed },
  inProgress: { color: theme?.status?.inProgress },
  unopened: { color: theme?.status?.inProgress, fontWeight: "bold" },
  opened: { color: '#52B9D4', fontWeight: "bold" },
}));

const getStatusTextColor = (status, style) => {
  if (!style) {
    return;
  }

  let color = style.completed;

  if (status.toLowerCase() == "in-progress") {
    color = style.inProgress;
  }
  return color;
};

const getPrayerTypeAndIcon = (type, slug) => {
  let defaultPrayer = {
    type: "default",
    title: "No special prayer",
    icon: PrayerDefault,
  };
  let prayer = defaultPrayer;

  if (!type) {
    return prayer;
  }

  // Find prayer from constants
  const findPrayer = PRAYER_TYPES_AND_ICONS.find((item) => item.type == slug);
  if (!findPrayer) {
    prayer = defaultPrayer;
    return prayer;
  }
  // Set prayer if found
  prayer = findPrayer;
  return prayer;
};

const getPrayerName = (prayer) => {
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  if (!prayer) {
    return;
  }

  let prayerName = "Prayer Request, ";

  if (prayer.type) {
    prayerName = `${prayer.type.name}, `;
  }

  const prayerDate = moment.utc(prayer.created_at).utcOffset(time_zone).format("MMMM D YYYY");
  prayerName = prayerName + prayerDate;
  return prayerName;
};
const getPrayerDate = (prayer) => {
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  if (!prayer) {
    return;
  }
  return moment.utc(prayer.created_at).utcOffset(time_zone).format("MMMM D YYYY");
};

const PrayerItemMinimized = (props) => {
  const isDesktop = !isTablet && !isMobile
  const { prayer } = props;
  const classes = useStyles();
  const { handleToggleExpand } = props;

  const prayerTypeAndIcon = getPrayerTypeAndIcon(
    prayer.type,
    prayer.type?.slug
  );

  const mobileImageClass = isMobile ? classes.imageContainerMobile : {};
  const mobileTitleClass = isMobile ? classes.titleMobile : {};
  const statusText = prayer.general_status === "completed" ? "Completed" : "In-progress"; 
  const statusColor = getStatusTextColor(statusText, classes);
  const getMadeBy = () => {
    let made_by = '';
    switch(statusText){
      case 'Completed':
        made_by = 'Multiple Prayer Partners';
        if(prayer?.prayer_partner_assigned.length <= 1){
          made_by = prayer?.prayer_partner?.full_name + 
                  `${prayer?.prayer_partner?.city ? `, ${prayer?.prayer_partner?.city}`: ''}` 
        }
        break;
      default:
        made_by = 'Not yet completed';
        break;
    }
    return made_by; 
  }

  const prayerName = getPrayerName(prayer);
  const prayerNameTablet = "Prayer Request ";
  const prayerDateTablet = getPrayerDate(prayer);
  const renderNotificationStatus = (notificationLog) => {
    return notificationLog ? STATUS_LABEL[notificationLog.status] : 'Unopened'
  }
  const getNotificationStatusClass = (notificationLog) => {
    if(notificationLog){
      if(notificationLog.status === STATUS.in_progress || notificationLog.status === STATUS.send_unopenned){
        return classes.unopened;
      }else{
        return classes.opened
      }
    }
    return classes.unopened;
  }

  return (
    <Grid container spacing={0} style={{ position: "relative" }}>
      <Grid
        item
        xs={12}
        md={3}
        className={`${classes.imageContainer} ${mobileImageClass} desktop`}
      >
        <img className={classes.image} src={prayerTypeAndIcon.icon}></img>
      </Grid>

      <Grid
        item
        xs={12}
        md={isMobile ? 12 : 9}
        className={classes.detailsContainer}
        onClick={ () => isMobile ? handleToggleExpand() : ''}
      >
        <div className="mobile icon-request-type">
          <img className={classes.image} src={prayerTypeAndIcon.icon}></img>
        </div>
        <div className={` ${classes.detailsContainerTablet} tablet tablet-display-none table-title-request display-none-with-mobile`}>
          <span className={`${classes.title} title-request`}>
            {prayerNameTablet}
          </span>
          <span className={`${classes.detailTitle} ${mobileTitleClass} ml-0 title-request`}>
            {prayerDateTablet}
          </span>
        </div>
        <div className={`mobile mobile-display-none`}>
          <Typography className={`${classes.title} ${mobileTitleClass} title-request`}>
            {prayerName}
          </Typography>
        </div>
        <div className={`desktop`}>
          <Typography className={`${classes.title} ${mobileTitleClass} title-request`}>
            {prayerName}
          </Typography>
        </div>
        <Button
          className={`${classes.expandButton} btn-item-detail desktop`}
          onClick={() => handleToggleExpand()}
          color="primary"
        >
          <span className="d-none d-md-inline-block  text-hippie-blue">
            <IntlMessage id="user.account.request.details"></IntlMessage>
          </span>{" "}
          <img src={ArrowForward} className="ml-2"></img>
        </Button>
        <Button
          className={`${classes.expandButton} btn-item-detail tablet display-none-with-mobile`}
          onClick={() => handleToggleExpand()}
          color="primary"
        >
          <span className="d-none d-md-inline-block  text-hippie-blue">
            <IntlMessage id="user.account.request.details"></IntlMessage>
          </span>{" "}
          <img src={ArrowForward} className="ml-2"></img>
        </Button>
        <Grid container>
          <Grid item xs={isDesktop ? 4 : 6}>
            <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.status"></IntlMessage>
            </Typography>
            <Typography
              className={`${classes.detail} ${classes.bold} ${classes.capitalized}`}
              classes={{ root: statusColor }}
            >
              {statusText}
            </Typography>
          </Grid>

          <Grid item xs={isDesktop ? 4 : 6}>
            <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.for"></IntlMessage>
            </Typography>
            <Typography className={`${classes.detail}`}>
              {prayer.recipient_name} 
              {prayer.is_gift === 1 && ', Gift'}
            </Typography>
          </Grid>

          {isDesktop && 
            <Grid item xs={isDesktop ? 4 : 6}>
              <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.confirmation"></IntlMessage>
              </Typography>
              <Typography className={`${classes.detail}`}>
                {renderNotificationStatus(prayer.client_confirmation_notification)}
              </Typography>
            </Grid>
          }

          <Grid item xs={isDesktop ? 4 : 6}>
            <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.frequency_label"></IntlMessage>
            </Typography>
            <Typography className={`${classes.detail}`}>
              { props.getFrequencyLabel(prayer.frequency)}
            </Typography>
          </Grid>

          <Grid item xs={isDesktop ? 4 : 6}>
            <Typography className={classes.detailTitle}>
              <IntlMessage id="user.account.request.request_id"></IntlMessage>
            </Typography>
            <Typography className={`${classes.detail}`}>
              {prayer?.transaction?.id ?? 'N\A'}
            </Typography>
          </Grid> 
          {
            isDesktop && prayer.is_gift == 1 &&
              <Grid item xs={isDesktop ? 4 : 6}>
                <Typography className={classes.detailTitle}>
                  <IntlMessage id="user.account.request.gift_notification"></IntlMessage>:
                </Typography>
                <Typography className={`${classes.detail} ${getNotificationStatusClass(prayer.gift_confirmation_notification)}`}>
                  {renderNotificationStatus(prayer.gift_confirmation_notification)}
                </Typography>
              </Grid>
          }
          {
            !isDesktop && 
              <Grid item xs={isDesktop ? 4 : 6}>
                <Typography className={classes.detailTitle}>
                <IntlMessage id="user.account.request.confirmation"></IntlMessage>
                </Typography>
                <Typography className={`${classes.detail}`}>
                  {renderNotificationStatus(prayer.client_confirmation_notification)}
                </Typography>
              </Grid>
          }
          {
            !isDesktop && prayer.is_gift == 1 &&
              <Grid item xs={isDesktop ? 4 : 6}>
                <Typography className={classes.detailTitle}>
                  <IntlMessage id="user.account.request.gift_notification"></IntlMessage>:
                </Typography>
                <Typography className={`${classes.detail}  ${getNotificationStatusClass(prayer.gift_confirmation_notification)}`}>
                  {renderNotificationStatus(prayer.gift_confirmation_notification)}
                </Typography>
              </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrayerItemMinimized;
