import React from 'react'
import styled from 'styled-components'
import {isMobile, isTablet} from 'react-device-detect'
import SubscribeImg from '@iso/assets/images/landing_subscribe_character.svg' 
import SubscribeImgMobile from '@iso/assets/images/landing_subscribe_character_mobile.svg' 
import SubscribeImgTablet from '@iso/assets/images/landing_subscribe_character_tablet.svg'

const Wrapper =styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const SubscribeImage = styled.img`
    @media only screen and (min-width: 960px) {
        margin-top: 20px;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        height: 225px;
    }
`

const LeftSide = props => {
    return <Wrapper>
        <SubscribeImage className="subscribe-img" src={isMobile ? (isTablet ? SubscribeImgTablet : SubscribeImgMobile) : SubscribeImg} alt="Subscribe character"></SubscribeImage>
    </Wrapper>
}

export default LeftSide