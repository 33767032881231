import React from "react"
import { useDispatch } from "react-redux"
import PrivateHeader from "@iso/components/Layout/PrivateHeader"
import { history } from "@iso/helpers/history"
import { logoutUser } from "@iso/actions/User"
import { withRouter } from "react-router"
import { MENU_SIDEBAR } from "./constants"

const Header = () => {
  const dispatch = useDispatch()
  const handleLogout = () => {
    history.push("/admin/sign_in")
    dispatch(logoutUser())
  }

  return (
    <div>
    <PrivateHeader handleLogout={handleLogout} login_path="/admin/sign_in" menuSidebar={MENU_SIDEBAR} />
     </div>
  )
}

export default withRouter(Header)
