import React from "react";

import { Modal, Button } from "react-bootstrap";
import CustomButton from "@iso/components/AtomicComponents/Button";

const ConfirmModal = (props) => {
  const {
    open,
    handleConfirm,
    handleClose,
    content,
    title,
    okText,
    cancelText,
    contentFooter,
    sendingRequest = false,
    submitBtnVariant = "primary",
    cancelBtnVariant = "",
    titleStyle="",
    goToBtnText
  } = props
  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className={titleStyle}>{title}</Modal.Title>
      </Modal.Header>
      {content && (
        <Modal.Body>
          {content && <p>{content}</p>}
          {contentFooter && <p>{contentFooter}</p>}

          {props.children}

        </Modal.Body>
      )}
      <Modal.Footer className="text-center" style={{ display: "block" }}>
        {okText && (
          <CustomButton
            variant={submitBtnVariant}
            handleClick={handleConfirm}
            disabled={sendingRequest}
            isSubmitting={sendingRequest}
            style={{width:'150px'}}
          >
            {okText}
          </CustomButton>
        )}
        {cancelText && (
          <CustomButton
            variant={cancelBtnVariant}
            handleClick={handleClose}
            disabled={sendingRequest}
            style={{width:'150px'}}
          >
            {cancelText}
          </CustomButton>
        )}
        {
          goToBtnText && (
            <CustomButton
            variant={submitBtnVariant}
            handleClick={handleConfirm}
            disabled={sendingRequest}
          >
            {goToBtnText}
          </CustomButton>
          )
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
