import axios from "axios";

export const getUserByActivationCode = (user_id, activation_code) => {
  return axios.get("/get_user_by_activation_code", {
    params: {
      user_id: user_id,
      activation_code: activation_code,
    },
  });
};

export const register = (body) => {
  return axios.post("/prayer_partner/register", body);
};

export const getPrayerRequests = (status, options) => {
  return axios.get("/prayer_requests", {
    params: {
      status: status,
	    ...options
    },
  });
};

export const getPrayerPaymentReports = (status) => {
  return axios.get("/prayer_partners/payments/report");
};

export const sendDemo = () => {
  return axios.post("/prayer_requests/demo_request");
};

export const showPrayerRequest = (id) => {
  return axios.get(`/prayer_requests/${id}`);
};

export const getPrayPartnerReports = () => {
  return axios.get("/prayer_partners/report");
};

export const getPrayerProfile = () => {
  return axios.get("/prayer_partners/profile");
};

export const updatePrayerProfile = (body) => {
  return axios.post("/prayer_partners/update_profile", body);
};

export const sendPrintPaymentRequest = ({ month, year }) => {
  return axios.get(`/prayer_partners/payments/${month}/report?year=${year}`);
};

export const downloadPrayerRequestPDF = ({ id }) => {
  return axios.get(`prayer_requests/${id}/download`, {
    headers: {
      "Content-Type": "application/pdf",
    },
    responseType: "blob",
  });
};

export const sendContactAdmin = (body) => {
  return axios.post("/prayer_partners/contact", body);
};

export const updatePrayer = (id, body) => {
  return axios.put(`/prayer_partners/${id}/update`, body);
};

export const updatePrayerNotify = (body) => {
  return axios.post("/prayer_partners/update_notify", body);
};

export const updateAvatarPrayer = (body) => {
  return axios.post("/prayer_partners/update_avatar", body);
};

export const updateLanguagePrayer = (body) => {
  return axios.post("/prayer_partners/update_language", body);
};

export const getReportByMonth = ({ month, year }) => {
  return axios.get(`/prayer_partners/payments/${month}/report_by_month?year=${year}`);
};
export const getAvailabilities = () => {
  return axios.get("/prayer_partners/availabilities/show");
};

export const updateAvailabilities = body => {
  return axios.post("/prayer_partners/availabilities/update", body);
}
