import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import IntlMessage from "@iso/components/intlMessage";
import PrayerPartners01 from '@iso/assets/images/PrayerPartners01.svg'
import ContributionService from "@iso/services/ContributionService"
import ProcessRequest from "@iso/utils/ProcessRequest"
import ContributionBox from "../Contributions"
import Frame from '@iso/assets/images/Frame.png'
import DoveImg from '@iso/assets/images/DoveImage.svg'
import { isMobile, isTablet } from "react-device-detect";

import "./index.scss"


const Wrapper = styled.div`
  justify-content: center;
  font-family: BrandonText;
  padding: 40px 0px;

  @media only screen and (max-width: 900px){
    padding-bottom: 0px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 

  @media only screen and (min-width: 768px) {
    flex-direction: row; 
    margin-left: 50px;
  }

  @media only screen and (min-width: 1025px) {
    flex-direction: row; 
    margin-bottom: 15px;
  }
`
const TitleWrapperTablet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 

  @media only screen and (min-width: 768px) {
    flex-direction: row; 
    margin-left: 50px;
    margin-top: -75px;
  }

  @media only screen and (min-width: 1025px) {
    flex-direction: row; 
    margin-bottom: 15px;
  }
`

const Title = styled.div``

const SubtitleWrapper = styled.div`
  padding: 0 10px;
  text-align: center;

  @media only screen and (min-width: 390px) {
    padding: 0 23px;
  }

  @media only screen and (min-width: 414px) {
    padding: 0 30px;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 10px;
    padding: 0 67px;
  }

  @media only screen and (min-width: 1025px) {
    margin: 0 auto;
    max-width: 785px;
    padding: 0;
  }

`


const BorderDashedSkyBlue = styled.div`
  width: 100%;
  max-width: 292px;
  margin: 40px auto 34px auto;

  > img {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 39px;
    margin-top: 35px;
    max-width: 500px;
  }

  @media only screen and (min-width: 1025px) {
    margin-bottom: 45px;
    max-width: 605px;
    margin-top: 50px;

    > img {
      width: 605px;
      height: 0px;
      border: 1.5px solid #AFDAF5;
    }
  }
`

const SkyLine = styled.div`
  border: 2px solid #AFDAF5;
  width: 100%;
`

const DoveImage = styled.img`
  @media only screen and (max-width: 500px){
    display: none !important;
  }
`
const DoveImageTablet = styled.img`
@media only screen and (max-width: 500px){
  display: none !important;
}
`

const ImageWhoPrayer = styled.img`
  max-width: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    max-width: 387px;
  }

  @media only screen and (min-width: 1025px) {
    max-width: 764px;
  }
`

const ContributionContainer = styled.div`
  display: flex;
  padding: 0 49px;

  @media only screen and (min-width: 768px) {
    padding: 0 75px;
    gap: 53px;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 1025px) {
    padding: 0 100px;
    gap: 117px;
    align-items: center;
  }

  @media only screen and (min-width: 1440px) {
    padding: 0 173px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 44px!important;
  }
`

const ContributionListContainer = styled.div`

  @media only screen and (min-width: 768px) {
    
  }

  @media only screen and (min-width: 1025px) {
    margin-top: -26px;
  }
`

const ContributionLevelsTitle = styled.div`
  margin-bottom: 30px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    margin-bottom: 37px;
  }

  @media only screen and (min-width: 1025px) {
    margin-bottom: 40px;
  }
`

const ContributionList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 19px;

  @media only screen and (min-width: 768px) {
    max-width: 309px;
  }

  @media only screen and (min-width: 1025px) {
    max-width: 634px;
  }
`

const DoveImgContainer = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
    width: 254px;
    height: 265px;
  }

  @media only screen and (min-width: 1025px) {
    width: 289.85px;
    height: 303.07px;
  }
`
const DoveImgContainerTablet = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
    width: 254px;
    height: 265px;
    margin-top: 50px;
  }

  @media only screen and (min-width: 1025px) {
    width: 289.85px;
    height: 303.07px;
  }
`
const Section1 = (props) => {

  const [priceData, setPriceData] = useState();
  const [denomination, setDenomination] = useState('');

  const getAllContribution = ({ religion }) => {
    ContributionService.getContributions({ religion }).then((res) => {
      ProcessRequest.run({
        res,
        hasNoti: false,
        i18nSuccess: "common.fetch.success",
        successCallback: () => {
          setPriceData(res.data.data);
        },
      });
    });
  };

  useEffect(() => {
    getAllContribution({ religion: denomination });
  }, []);


  return <Wrapper className="pricing-page-wrapper">
    {isMobile && isTablet 
    ? <TitleWrapperTablet>
        <Title className='section1_title'><IntlMessage id="user.form.pricingpage.section1.title" /></Title>
        <ImageWhoPrayer src={PrayerPartners01} alt="Who Prayer" />
      </TitleWrapperTablet>
    : <TitleWrapper>
        <Title className='section1_title'><IntlMessage id="user.form.pricingpage.section1.title" /></Title>
        <ImageWhoPrayer src={PrayerPartners01} alt="Who Prayer" />
      </TitleWrapper>
    }

    <SubtitleWrapper>
      <span className='section1_subtitle'>
        <IntlMessage id="user.form.pricingpage.section1.reason.title1" />
      </span>
    </SubtitleWrapper>

    <BorderDashedSkyBlue>
      <SkyLine />
    </BorderDashedSkyBlue>

    <ContributionContainer>
      <ContributionListContainer>
        <ContributionLevelsTitle>
          <span class="contribution-levels-title">Contribution Levels</span>
        </ContributionLevelsTitle>
        <ContributionList>
          {
            priceData && priceData.map((item, key) => {
              return <ContributionBox key={'contribution-' + key} index={key} item={item} denomination={denomination} />
            })
          }
          <ContributionBox index={3} item={{price: '18-32', name: 'Denomination specific special prayers'}} denomination={denomination} />
        </ContributionList>
      </ContributionListContainer>

      {isMobile && isTablet 
        ? <DoveImgContainerTablet>
            <DoveImageTablet src={DoveImg} alt="Prayer" />
          </DoveImgContainerTablet>
        : <DoveImgContainer>
            <DoveImage src={Frame} alt="Prayer" />
          </DoveImgContainer>
      }
    </ContributionContainer>
  </Wrapper>
}

export default Section1