import React from "react";
import styled from "styled-components";
import IntlMessage from "@iso/components/intlMessage";
import PencilPrayer from "@iso/assets/images/PencilPrayer.png";

import "@iso/assets/common.scss";

const Wrapper = styled.div`
  font-family: "BrandonText";
  display: flex;
  flex-direction: column;
  margin-top: 33px;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    min-height: calc(407px - 48px);
    padding-left: 28px;
    padding-right: 61px;
    margin-top: 57px;
  }

  @media only screen and (min-width: 1025px) {
    min-height: calc(560px - 48px);
    padding-left: 90px;
    padding-right: 90px;
  }

  @media only screen and (min-width: 1440px) {
    min-height: calc(615px - 48px);
    padding-left: 148px;
    padding-right: 167px;
  }
`;

const TitleWrapper = styled.div`
  
`;
const SubTitleWrapper = styled.div`
  @media only screen and (max-width: 767px) {
    text-align: center;
    padding-left: 32px;
    padding-right: 31px;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 65px;

    > span:not(:first-child) {
      margin-top: 20px;
    }
  }

  @media only screen and (min-width: 1025px) {
    margin-top: 43px;
    max-width: 406px;
    margin-bottom: 50px;
  }
`;

const ContentSubtitle = styled.span`
  display: block;
  font-family: "BrandonText";
  font-style: normal;
  color: #1C1634;
  font-weight: 600;
  font-size: 20px;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media only screen and (min-width: 1025px) {
    font-size: 24px;
    line-height: 34px;
    padding-right: 20px;
  }
`;
const RightSide = (props) => {
  return (
    <Wrapper>
      <SubTitleWrapper>
        <ContentSubtitle>
          <IntlMessage
            id={
              <>
                <span style={{ color: "#50BBD3" }}>Our Prayer Partners</span>{" "}
                <br />
                take the time to customise <br></br> your prayer based on your reason and
                their depth of knowledge.
              </>
            }
          />
        </ContentSubtitle>
      </SubTitleWrapper>
      <TitleWrapper>
        <img src={PencilPrayer} className="d-md-block pencil-image" />
      </TitleWrapper>
    </Wrapper>
  );
};

export default RightSide;
