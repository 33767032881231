export const fb_pixel_event = {
    custom_events : {
        CLICK_BUTTON : 'click_button',
        CHANGE_INPUT : 'change_input',
        LOGIN : 'login',
    },
    recommended_events : {
        SIGN_UP : 'CompleteRegistration',
        PURCHASE : 'Purchase',
        ADD_PAYMENT_INFO : 'AddPaymentInfo',
    },
    options : {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    },
    advanced_matching : {}
}
