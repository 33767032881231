import React, { useState, useEffect } from "react";
import {Button, Card} from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import { store } from "@iso/store/root";
import { Link } from "react-router-dom"
import NotificationService from "@iso/services/NotificationService";
import { NOTI_DEFINATION } from './constants'
import notificationActions from "@iso/actions/notificationActions"
import { localTimezone } from "@iso/utils/GetLocalData";
import { useDispatch } from "react-redux"
import moment from "moment";
import "./styles.scss"
import DeleteIcon from "@material-ui/icons/Delete";
import alertActions from "@iso/actions/Alert";
const { openAlert } = alertActions;

const Notification = () => {
  const [notiList, setNotiList] = useState([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalNotify, setTotalNotify] = useState(0)
  const timezone = localTimezone().code;
  const dispatch = useDispatch()
  const getHrefPath = (type, id) => {
    let href = '';
    switch(type){
      case 'user_approved':
        href = `/prayer_partners`
        break;
      case 'profile_reject':
      case 'profile_approved':
        href = '/prayer_partners/account_settings'
        break;
      case 'request_reassign':
      case 'request_deleted':
        href = '/prayer_partners/contact_admin'
        break;
      default:
        href = `/prayer_requests/${id}`
        break;
    }
    return href;
  }

  const setSingleNotiInfo = (type) => {
    return NOTI_DEFINATION.filter((item) => item.type === type)[0];
  }
  const handleClick = (id) => {
    try{
      NotificationService.updateNotiField(id).then((res) => {
        if(res){
          const { code, data } = res.data;
          if(code === 200){
            for(let cnt = 0 ; cnt < notiList.length; cnt++ ){
             if(notiList[cnt].id === id){
               notiList[cnt].read = 1
               setNotiList(notiList);
               break;
             }
            }
            getNotiList();
          }
        }
      })
    }catch(errors){
      console.log(errors)
    }finally{}
    
  }

  const clearAllNoti = () => {
	  setLoading(true)
    try{
      NotificationService.clearAllNoti().then((response) => {
        let res = response.data;
        if(res){
          const { code, notifications } = res;
          if(code === 200){
            setTotalNotify(notifications.total)
            setNotiList(notifications.data);
            document.querySelector('.app-main').scrollTop = 0;
	
	          store.dispatch(
		          openAlert({
			          message: <IntlMessage id="common.clear.notification.success" />,
			          severity: "success",
			          variant: "default",
		          })
	          );
          }
        }
	      setLoading(false)
      })
    }
    catch(errors){
	    setLoading(false)
    }finally{
    }
  }

  const getNotiList = () => {
    let newPage = page + 1;
    setPage(newPage);
    let top = document.querySelector('.app-main').scrollTop;
    
    try{
      NotificationService.getNotiList(newPage).then((response) => {
        let res = response.data;
        if(res){
          const { code, notifications } = res;
          if(code === 200){
            setTotalNotify(notifications.total)
            setNotiList(notiList.concat(notifications.data));
            NotificationService.getUnreadNoti().then((res) => {
              dispatch(notificationActions.updateNoti(res?.data?.total_unread))
            })
            document.querySelector('.app-main').scrollTop = top;
          }
        }
      })
    }
    catch(errors){
      // console.log(errors)
    }finally{
    }
  }

  useEffect(() => {
      getNotiList()
  },[])
  const RenderNoti = (props) => {
    const {notiData, noti} = props
     return(
       <div className={`render-noti-wrapper p-3 ${noti.read === 0 ? 'bg-unread' : 'bg-read'}`} onClick={() => handleClick(noti.id)}>
         <div className="title"><IntlMessage id={ notiData.title }
           values={{id : noti?.model_id, time: noti?.option_value?.time }}/>
         </div>
         {
            notiData.type === 'user_approved' &&
            <div>
              <IntlMessage id={notiData.label} />
            </div>
         }
         {
           noti.notification_type === 'contact_admin_response' ?
           <div className="hippie-blue-color"><IntlMessage id={notiData.subtitle} values={{ adminName: noti?.option_value?.email }}/> </div>
           :
           <Link to={() => getHrefPath(noti.notification_type ,noti?.model_id)} className="subtitle">
              <IntlMessage id={notiData.subtitle} />
           </Link>
         }
         <div className="text-muted">{moment(noti.created_at).add(timezone, 'hours').format('MMMM Do, YYYY - hh:mm A')}</div>
       </div>
     )
   }

  return(
    <div className="noti_wrapper mint-tulip-background p-3 overflow-hidden">
       <div className="justify-content-between">
	       <h2 className="mb-3 justify-content-between d-flex">
		       <IntlMessage id="sidebar.prayer_partners.notification.title" />
		       <Button
			       className="light-gray-button app_btn mx-1"
			       onClick={clearAllNoti}
			       disabled={loading}
		       >
			       <DeleteIcon className="pr-1 pb-1" />
			       <IntlMessage id="common.clear.notification" />
		       </Button>
	       </h2>
       </div>
        <Card className="p-3 overflow-auto">
          {
              notiList.length === 0 ?
              <div className="text-none-noti"><IntlMessage id="prayer.none.notification" /></div>
              :
              notiList.map((noti) => {
              let notiData = setSingleNotiInfo(noti?.notification_type)
              return (
                  <RenderNoti
                    notiData={notiData}
                    noti={noti}
                  />
              )
            })
          }
  
          {
            (totalNotify > notiList.length) && (<Button className="hippie-blue-button font-size-18px text-center mt-5 font-weight-bold" id="btnLoadMore" type = "button"
                  onClick={() => {getNotiList()}}>
            <IntlMessage id="common.load_more" />
          </Button>)
          }
        </Card>
    </div>
  )
}

export default Notification;
