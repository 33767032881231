import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FlagIcon from '@material-ui/icons/Flag'
import IntlMessage from "@iso/components/intlMessage";
import { SALUTION } from "./constants";

import './Item.scss'
import { useSelector } from 'react-redux'

const RequestItem = ({ value, title, component, displayValueClassName, request }) => {
  const { user } = useSelector((state) => state.authentication);
  const getNamePrayer = () => {
    if (request) {
      if (request.is_gift && !request.client_id) {
        if (request.recipient == "for_someone") {
          return request.recipient_name + ',';
        }
        else {
          return request.recipient_name + ',';
        }
      }
      if(!request.is_gift && !request.client_id && request.recipient == "for_someone"){
        return request.recipient_name;
      }
      if (!request.is_gift && request.client_id) {
        return request.client_name + ',';
      }
      if(!request.client_id && request.recipient == "for_myself"){
        return request.recipient_name;
      } 
      if(request.is_gift && request.recipient == "for_someone" && request.client_id){
          return request.recipient_name
      }
    } else {
      return 'Guest' + ',';
    }
  }

  const getSalution = (key) => {
      for (var i = 0; i < SALUTION.length; i++) {
          if (SALUTION[i].id === key) {
              return SALUTION[i].label;
          }
      }
  }
  const ValueComponent = () => {
    switch (component) {
      case "flag":
        return value.map((flag) => (
          <FlagIcon key={flag} className={`request_flagged ${flag}`} />
        ));

      default:
          if (title === 'Message: ' && value != null) {
              return (
                  <div>
                      <div>
                          <IntlMessage
                              id="prayer_request.completion_modal.new.label.dear"
                              values={{ user_name: getNamePrayer() }}
                          />
                      </div><br/>
                      <div>
                          {value.split("\n").map((i,key) => {
                              return <span key={key}>{i}<br/></span>;
                          })}
                      </div><br/>
                      <div>
                          <span>{request ? (request.salution ? getSalution(request.salution) : 'God bless') : 'God bless'}, </span>
                          <IntlMessage
                              id={user.role === 'super-admin' ? 'prayer_request.completion_modal.new.label.sa_god_bless' : 'prayer_request.completion_modal.new.label.pp_god_bless'}
                              values={{ pp_name:  user.first_name }}
                          />
                      </div>
                  </div>);
          } else {
              return <p className="request_item_value_css p-0 m-0">{value}</p>;
          }
    }
  };

  return (
    <Row className="mt-3 request_item_wrapper">
      <Col sm="4" lg="3" className="bullet">
        <span className="font-weight-bold">{title}</span>
      </Col>
      <Col sm="8" lg="9" className= {`${displayValueClassName} margin-left-content`}>
        {value && <ValueComponent />}
      </Col>
    </Row>
  );
};

export default RequestItem;
