import React, { Component } from 'react';
import { Notifier } from '@airbrake/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    const airBrakeProjectId = process.env.REACT_APP_AIRBRAKE_PROJECT_ID;
    const airBrakeProjectKey = process.env.REACT_APP_AIRBRAKE_PROJECT_KEY;

    if(airBrakeProjectId && airBrakeProjectKey)
      this.airbrake = new Notifier({
        projectId: airBrakeProjectId,
        projectKey: airBrakeProjectKey
      });
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    const airBrakeProjectId = process.env.REACT_APP_AIRBRAKE_PROJECT_ID;
    const airBrakeProjectKey = process.env.REACT_APP_AIRBRAKE_PROJECT_KEY;

    if(airBrakeProjectId && airBrakeProjectKey)
      this.airbrake.notify({
        error: error,
        params: {info: info}
      });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
