// Available severity: https://material-ui.com/components/snackbars/
//error
//warning
//info
//success

const WelcomePrayerCloudActions = {
  SHOW_PRAYER_CLOUD_MODAL: "show_welcome_prayer_cloud_modal",
  HIDE_PRAYER_CLOUD_MODAL: "hide_welcome_prayer_cloud_modal",
  openModal: (data) => ({
    type: WelcomePrayerCloudActions.SHOW_PRAYER_CLOUD_MODAL,
    data
  }),
  closeModal: () => ({
    type: WelcomePrayerCloudActions.HIDE_PRAYER_CLOUD_MODAL,
  })
}

export default WelcomePrayerCloudActions;