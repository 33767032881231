import React, {useState} from "react";
import {Badge, Button, Card, Col, FormControl, InputGroup, Row,} from "react-bootstrap";
import IntlMessage from "@iso/components/intlMessage";
import {getPrayerRequests, sendDemo} from "@iso/PrayerPartner/api_v1";
import {MDBBadge, MDBDataTable} from "mdbreact";
import "./styles.scss";
import moment from "moment";
import {DATE_FORMAT_FOR_PRAYER_REQUEST} from "@iso/constants";

import {history} from "@iso/helpers/history";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import RequestService from "@iso/services/RequestService";
import ProcessRequest from "@iso/utils/ProcessRequest";
import FlagRequestModal from "@iso/PrayerPartner/Pages/PrayerRequests/FlagRequestModal";
import CompletedPrayerRequestModal from "@iso/components/Request/CompletedPrayerRequestModal";
import NotifyModal from "@iso/PrayerPartner/Pages/PrayerRequests/NotifyModal";
import {debounce} from "lodash";
import { useSelector, useDispatch } from "react-redux";
import ConfirmModal from "@iso/components/Common/ConfirmModal";
import { translate } from "@iso/utils/Translate";
import AcceptConfirmModal from '../PrayerRequests/AcceptConfirmModal'
import DeclineConfirmModal from '../PrayerRequests/DeclineConfirmModal'
import { showPrayerRequest } from '../../api_v1'
const Home = (props) => {
  const [state, setState] = React.useState({
    request_lists: [],
    current_status: "unassigned",
    openFlag: false,
    openCompleteModal: false,
    openModalNotify: false,
    requestId: null,
    prayerRequest: {},
  });
  const [isInitForm, setIsInitForm] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState("");
  const [openNotifyModal, setOpenNotifyModal] = React.useState(false);
  const [openAcceptConfirm, setOpenAcceptConfirm] = React.useState(false);
  const [openDeclineConfirm, setOpenDeclineConfirm] = React.useState(false);
  const [idSelected, setIdSelected] = React.useState(false);
	let disableRedirect = false;
  const openAcceptConfirmModal = (id) => {
    showPrayerRequest(id).then((res) => {
      if (res) {
        switch (res.data.data.frequency) {
          case 'daily':
          case 'weekly':
          case 'monthly':
            setOpenAcceptConfirm(true);
            setIdSelected(id);
            break;
          default:
            handleAcceptRequestOnTime(id);
            break;
        }
      }
    });

  };
  const openDeclineConfirmModal = (id) => {
    showPrayerRequest(id).then((res) => {
      if (res) {
        switch (res.data.data.frequency) {
          case 'daily':
          case 'weekly':
          case 'monthly':
            setOpenDeclineConfirm(true);
            setIdSelected(id);
            break;
          default:
            handleDeclineRequestOnTime(id);
            break
        }
      }
    });
  };
  const dispatch = useDispatch()
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  const justCreated = useSelector(
    (reducer) =>  reducer.welcomePrayerPartnerReducer
  );

  const [openFlagModal, setOpenFlagModal] = React.useState(false)
  const [isOpenCrtAccModal, setOpenCrtAccModal] = React.useState(false)
  React.useEffect(() => {
    if(justCreated.isOpenModal){
      setOpenCrtAccModal(justCreated.isOpenModal)
    }
  },[justCreated.isOpenModal])

  const handleCloseCreatedModal = () => {
    setOpenCrtAccModal(false)
  }
	
  const STATUS_OTHER = 'other';
	const handlePrayerRequests = (status, options) => {
		getPrayerRequests(status, options).then((res) => {
			if (res) {
				const { code, data, count } = res.data;
				if (res && code === 200) {
					var request_lists = formatTableData(data.data, status);
					
					setState({
						request_lists: request_lists.data,
						counts: count,
						current_status: status,
					});
					if (request_lists.data.rows.length == 0) {
						const tbody = document.querySelector(
							"tbody[data-test=table-body] tr td"
						)
            if(tbody) tbody.innerText = `You have no ${status} request.`;
						// <IntlMessage
						//   id="admin.requests.have_no_request"
						//   values={{ status: status }}
						// />
					}
				}
			}
		});
	};

  const sendDemoRequest = () => {
    sendDemo();
    handlePrayerRequests("unassigned");
  };

  const handleAcceptRequest = () => {
    RequestService.acceptPrayerRequest(idSelected).then((res) => {
      ProcessRequest.run({ res });
      setOpenAcceptConfirm(false);
      handlePrayerRequests("unassigned");
    });
  };

  const handleDeclineRequest = () => {
    RequestService.declinePrayerRequest(idSelected).then((res) => {
      ProcessRequest.run({ res });
      setOpenDeclineConfirm(false);
      handlePrayerRequests("unassigned");
    });
  };

  const handleAcceptRequestOnTime = (id) => {
    RequestService.acceptPrayerRequest(id).then((res) => {
      ProcessRequest.run({ res });
      setOpenAcceptConfirm(false);
      handlePrayerRequests("unassigned");
    });
  };

  const handleDeclineRequestOnTime = (id) => {
    RequestService.declinePrayerRequest(id).then((res) => {
      ProcessRequest.run({ res });
      setOpenDeclineConfirm(false);
      handlePrayerRequests("unassigned");
    });
  };

  const handleCompleteRequest = (values) => {
    RequestService.confirmCompletePrayerRequest(values.id, values)
      .then((res) => {
        ProcessRequest.run({
          res,
          successCallback: () => {
            handlePrayerRequests(state.current_status);
            setState({
              ...state,
              openCompleteModal: false,
              openModalNotify: true,
            });
            setOpenNotifyModal(true);
          },
        });
        setIsInitForm(true)
      })
    .catch((error) => {});
  };

  const closeFlagModal = () => {
    setState({
      ...state,
      openFlag: false,
    });
    setOpenFlagModal(false);
    handlePrayerRequests("unassigned");
  };

  const closeAcceptConfirmModal = () => {
    setOpenAcceptConfirm(false);
  };

  const closeDeclineConfirmModal = () => {
    setOpenDeclineConfirm(false);
  };

  const formatTableData = (data, status) => {
    let dataRow = [];
    let header = [
      {
        label: <IntlMessage id="prayers.requests.table_header.request_id" />,
        field: "request_id",
        sort: "asc",
        width: 150,
      },
      {
        label: <IntlMessage id="prayers.requests.table_header.sub_request_id" />,
        field: "sub_request_id",
        sort: "asc",
        width: 150,
      },
      {
        label: <IntlMessage id="prayers.requests.table_header.id" />,
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: <IntlMessage id="prayers.requests.table_header.client_name" />,
        field: "client_name",
        sort: "asc",
        width: 150,
      },
      {
        label: <IntlMessage id="prayers.requests.table_header.due_date" />,
        field: "due_date",
        sort: "asc",
        width: 150,
      },
      {
        label: <IntlMessage id="prayers.requests.table_header.contribution" />,
        field: "contribution",
        sort: "asc",
        width: 150,
      },
      {
        label: <IntlMessage id="prayers.requests.table_header.reason" />,
        field: "reason",
        sort: "asc",
        width: 174,
      },
      {
        label: (
          <IntlMessage id="prayers.requests.table_header.special_request" />
        ),
        field: "special_request",
        width: 174,
      },
    ];
    if (status === STATUS_OTHER) {
      header.push({
        label: (<IntlMessage id="prayers.requests.table_header.status"/>),
        field: "options",
        width: 120,
      });
    }
    else {
      header.unshift({
        label: (<IntlMessage id="prayers.requests.table_header.actions"/>),
        field: "options",
        width: 120,
      })
    }
    data.forEach((e, index) => {
      dataRow.push({
        request_id: e.transaction_parent?.id || (e.transaction_user?.id || ''),
        sub_request_id: e.transaction_partner?.id || '',
        id: e.id,
        client_name: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={index}
            searchvalue={e.client_name ? e.client_name.toLocaleLowerCase() : ''}
          >
            {
              e.client_name
            }
          </MDBBadge>
        ),
        due_date: (
          <MDBBadge
            style={{fontSize: "16px", fontWeight: "100"}}
            key={e.end_date}
            searchvalue={e.end_date}
          >
            {moment.utc(e.end_date_utc).utcOffset(time_zone).format(DATE_FORMAT_FOR_PRAYER_REQUEST)}
          </MDBBadge>
        ),
        contribution: e.contribution?.name,
        reason: (
	        <MDBBadge
		        style={{fontSize: "16px", fontWeight: "100", maxWidth: "150px"}}
		        key={e.end_date}
		        searchvalue={e.reason_translate || e.reason}
	        >
		        <div className="ellipsis">{e.reason_translate || e.reason}</div>
	        </MDBBadge>
        ),
        special_request: (
          <div style={{maxWidth: "150px"}} className="ellipsis">{e.special_request_translate || e.special_request}</div>
        ),
        options: optionField(e, status, index),
	      clickEvent: () => handleClick(e.id),
      });
    });
    return {
      data: {
        columns: header,
        rows: dataRow,
      },
    };
  };
	
	const handleClick = (id) => {
		if (!disableRedirect) {
			history.push(`/prayer_requests/${id}`)
		}
		disableRedirect = false
	};
	
  function optionLabel(status) {
    if (status === STATUS_OTHER) {
      return <IntlMessage id="prayers.requests.table_header.status" />;
    }

    return <IntlMessage id="prayers.requests.table_header.actions" />;
  }
  function optionField(e, status, index) {
    if (status === STATUS_OTHER) {
      return (
        <MDBBadge
          style={{fontSize: "16px", fontWeight: "100"}}
          key={index}
          searchvalue={translate(`admin.requests.request_details_card.status.${e.status}`)}
        >
          <IntlMessage id={`admin.requests.request_details_card.status.${e.status}`}/>
        </MDBBadge>
      );
    }
    return (
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            displayEmpty={true}
            defaultValue=""
            className="my-control2"
            key={e.id}
            onClick={() => {disableRedirect = true;}}
        >
          <MenuItem value="">
            <em>
              <IntlMessage id="common.select"/>
            </em>
          </MenuItem>
          {e.status === "unassigned" && (
              <MenuItem
                  value="accept"
                  onClick={() => openAcceptConfirmModal(e.id)}
              >
                <IntlMessage id="common.accept"/>
              </MenuItem>
          )}
          {e.status === "unassigned" && (
              <MenuItem
                  value="decline"
                  onClick={() => openDeclineConfirmModal(e.id)}
                  className="text-danger"
              >
                <IntlMessage id="common.decline"/>
              </MenuItem>
          )}
          {(e.status === "incomplete" || e.status === "overdue") && (
              <MenuItem
                  value="complete"
                  onClick={() => {
                    setState({
                      ...state,
                      prayerRequest: e,
                      current_status: e.status,
                      openCompleteModal: true,
                    });
                  }}
                  className="text-hippie-blue"
              >
                <IntlMessage id="text_complete_request"/>
              </MenuItem>
          )}
          {(e.status === "unassigned" || e.status === "overdue") && (
              <MenuItem
                  value="flag"
                  onClick={() =>{
                      setState({
                        ...state,
                        openFlag: !state.openFlag,
                        requestId: e.id,
                      });
                      setOpenFlagModal(true)
                    }
                  }
                  className="text-warning"
              >
                <IntlMessage id="common.flag"/>
              </MenuItem>
          )}
          {e.status === "incomplete" && (
            <MenuItem
              value="flag"
              onClick={() =>{
                setState({
                  ...state,
                  openFlag: !state.openFlag,
                  openCompleteModal: false,
                  requestId: e.id,
                });
                setOpenFlagModal(true)
              }
              }
              className="text-warning"
            >
              <IntlMessage id="common.flag"/>
            </MenuItem>
          )}
        </Select>
    )
  }

  React.useEffect(() => {
    handlePrayerRequests("unassigned");
  }, []);

  const handleClickModal = () => {
    history.push('/prayer_partners/account_settings')
  }

  const debounceSearch = React.useCallback(
    debounce((status, options) => {
      handlePrayerRequests(status, options);
    }, 500),
    []
  );

  React.useEffect(() => {
    debounceSearch(state.current_status, { search: searchValue });
  }, [searchValue]);

  return (
    <div className="mint-tulip-background p-3 prayer_partner-request-list-wrapper">
      <Row>
        <Col sm="6" className="text-md-left text-center mb-3">
          <h2>
            <IntlMessage id="prayer_partners.requests.title" />
          </h2>
        </Col>
        <Col sm="6" className="text-md-right text-center mb-3">
          <Button
            className="hippie-blue-button float-md-right"
            onClick={sendDemoRequest}
          >
            <IntlMessage id="prayer_partners.requests.send_demo" />
          </Button>
        </Col>
      </Row>

      <Card>
        <Row className="mt-3 mb-3 pl-5 pr-5 row">
          <Col sm>
            <Button
              variant={`${
                state.current_status == "unassigned" ? "active-status" : "light"
              } btn-block`}
              onClick={() => {
                handlePrayerRequests("unassigned", { search: searchValue });
              }}
            >
              Unassigned
              <Badge className="ml-2" pill variant="danger">
                {state.counts
                  ? state.counts.unassigned == 0
                    ? ""
                    : state.counts.unassigned
                  : 0}
              </Badge>
            </Button>
          </Col>
          <Col sm>
            <Button
              variant={`${
                state.current_status == "incomplete" ? "active-status" : "light"
              } btn-block`}
              onClick={() => {
                handlePrayerRequests("incomplete", { search: searchValue });
              }}
            >
              Incomplete
              <Badge className="ml-2" pill variant="danger">
                {state.counts
                  ? state.counts.incomplete == 0
                    ? ""
                    : state.counts.incomplete
                  : 0}
              </Badge>
            </Button>
          </Col>
          <Col sm>
            <Button
              variant={`${
                state.current_status == "overdue" ? "active-status" : "light"
              } btn-block`}
              onClick={() => {
                handlePrayerRequests("overdue", { search: searchValue });
              }}
            >
              Overdue
              <Badge className="ml-2" pill variant="danger">
                {state.counts
                  ? state.counts.overdue == 0
                    ? ""
                    : state.counts.overdue
                  : 0}
              </Badge>
            </Button>
          </Col>
          <Col sm>
            <Button
              variant={`${
                state.current_status == "other" ? "active-status" : "light"
              } btn-block`}
              onClick={() => {
                handlePrayerRequests("other", { search: searchValue });
              }}
            >
              Other
              <Badge className="ml-2 badge-background" pill variant="danger">
                  {state.counts
                    ? state.counts.other == 0
                      ? ""
                      : state.counts.other
                    : 0}
                </Badge>
            </Button>
          </Col>
        </Row>
        <Row className="ml-2 mr-2">
          <Col sm="12">
	          <Row>
		          <Col className="px-4 px-md-0 col-md-4 input_search_data_table" l>
			          <InputGroup>
				          <FormControl
					          value={searchValue}
					          placeholder={translate(
						          "prayer_partners.requests.search.placeholder"
					          )}
					          onChange={(e) => {
						          setSearchValue(e.target.value);
					          }}
				          />
			          </InputGroup>
		          </Col>
	          </Row>
            <MDBDataTable
              hover
              scrollX
              paging={true}
              id="requests_list_table"
              entries={25}
              searching={false}
              noBottomColumns={true}
              entriesOptions={[5, 20, 25]}
              data={state.request_lists}
              className="hide_scrollbar pp_requests_list_table"
              sortRows={['due_date', 'client_name', 'options', 'transaction', 'parent_transaction']}
              onSearch={(e) => {
                setSearchValue(e)
              }}
            />
          </Col>
        </Row>
      </Card>
      <FlagRequestModal
        showModal={openFlagModal}
        requestId={state.requestId}
        closeModal={() => closeFlagModal()}
        successCallback={() => handlePrayerRequests("unassigned")}
      />
	
	    {
		    state.openCompleteModal &&
		    (<CompletedPrayerRequestModal
			    open={state.openCompleteModal}
			    handleClose={() => {
				    setState({...state, openCompleteModal: false});
				    handlePrayerRequests(state.current_status)
			    }}
			    handleSubmit={(values) => handleCompleteRequest(values)}
			    request={state.prayerRequest}
			    setIsInitForm={setIsInitForm}
			    isInitForm={isInitForm}
		    />)
	    }
      <NotifyModal
        open={openNotifyModal}
        handleClose={(values) => {
          setOpenNotifyModal(false);
        }}
      />

      <ConfirmModal
      open={isOpenCrtAccModal}
      handleConfirm={() => handleClickModal()}
      handleClose={() => handleCloseCreatedModal()}
      title={<IntlMessage id="confirm_modal.welcome_pp.title"/>}
      content={<IntlMessage id="confirm_modal.welcome_pp.content" />}
      goToBtnText={<IntlMessage id="confirm_modal.welcome_pp.button" />}
      />
      <AcceptConfirmModal
          open={openAcceptConfirm}
          handleClose={closeAcceptConfirmModal}
          acceptRequest={handleAcceptRequest}
          idRequest={idSelected}
      />
      <DeclineConfirmModal
          open={openDeclineConfirm}
          handleClose={closeDeclineConfirmModal}
          declineRequest={handleDeclineRequest}
          idRequest={idSelected}
      />
    </div>
  );
};

export default Home;
