import * as Yup from "yup"

const TellUsAboutFormValidator = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .email("Please enter a correct email!")
    .required(),
  firstName: Yup.string().label("First Name").required(),
  lastName: Yup.string().label("Last Name").required(),
  cityAndCountry: Yup.string().label("City and Country").required(),
  denomination: Yup.string().label("Denomination").required(),
  reason: Yup.string()
    .label("Reason")
    .required()
    .test(
      "minLength",
      "Reason minimum 200 characters!",
      (val) => val && val.length >= 200
    ),
})

export { TellUsAboutFormValidator }