import React from "react";
import styled from "styled-components";
import ImgShareRequest from "@iso/assets/images/landing_share_title.svg";
import ImgShareRequestMobile from "@iso/assets/images/landing_share_title_mobile.svg";
// import ImgYourRequest from '@iso/assets/images/your-request.svg'
import ImgTwitter from "@iso/assets/images/twitter.svg";
import ImgFacebook from "@iso/assets/images/facebook.svg";
import ImgMail from "@iso/assets/images/mail.svg";
import ImgInstagram from "@iso/assets/images/instagram.svg";
import { Grid } from "@material-ui/core";
import IntlMessage from "@iso/components/intlMessage";
import ShareYourRequest from "@iso/components/ShareRequest/ShareYourRequest";
import "../index.scss"

import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
} from "react-share";
import "./ShareYourRequest.scss";
import {isMobile, isTablet} from "react-device-detect";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "BrandonText";
`;

const TitleShare = styled.img`
  @media only screen and (min-width: 376px) and (max-width: 500px){
    height: 65px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media only screen and (min-width: 376px) and (max-width: 500px){
    width: 100%;
  }
`;

const TitleShareText = styled.p`
  font-weight: bold;
  font-size: 36px;
  margin-left: 15px;
  margin-bottom: 0px;
  color: #1C1634;
  @media only screen and (min-width: 976px) {
    font-size: 46px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    font-size: 38px;
  }
`;
const Subtitle = styled.p`
  font-size: 18px;
  margin-top: 15px;
  color: #1C1634;
  
  @media only screen and (max-width: 375px) {
    margin-top: 25px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    font-size: 22px;
  }
  @media only screen and (min-width: 900px) {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 170px;
  }
`;

const ShareButtonsContainer = styled.div`
 
  @media only screen and (min-width: 1300px) {
    margin-right: 240px;
    margin-top: -10px;
  } 

  @media only screen and (max-width: 375px) {
     margin-top: -30px;
     margin-bottom: 60px;
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    margin-top: -30px!important;
    margin-bottom: 57px;
  }
`;

const shareContent = {
  // Any field is optional, but url and title are important
  url: window.location.hostname,
  title: "PrayerCloud",
  hashtag: "#prayerCloud",
  quote: "",
};

// Content used in email
const emailContent = {
  body: `PrayerCloud - ${window.location.hostname}`,
};

const LeftSide = (props) => {
  return (
    <Wrapper className="share-your-request-landing">
      <TitleContainer>
        <TitleShare
          src={isMobile && !isTablet ? ImgShareRequestMobile : ImgShareRequest}
          className="section8-share-img"
        ></TitleShare>
        {
          !isTablet && (
              <>
                <TitleShareText className="d-md-none" style={{lineHeight: "32px"}}>
                  <IntlMessage id="landing.share.title.mobile"/>
                </TitleShareText>
                <TitleShareText className="d-none d-md-block">
                  <IntlMessage id="landing.share.title"/>
                </TitleShareText>
              </>
            )
        }
      </TitleContainer>

      {
        isTablet && (
            <TitleShareText className="tablet-section8-title-shared">
              <IntlMessage id="landing.share.title"/>
            </TitleShareText>
          )
      }

      <Subtitle className="tablet-section8-subtitle-shared">
        <IntlMessage id="landing.share.subtitle" />
      </Subtitle>

      <ShareButtonsContainer className="btn-shared-ground" style={{marginTop: (isMobile ? "-58px" : '0px')}}>
        <ShareYourRequest variant="homepage" />
      </ShareButtonsContainer>
    </Wrapper>
  );
};

export default LeftSide;
