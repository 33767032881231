import _ from "lodash";

export const RELIGION_IDS = [
  "catholic",
  "protestant",
  "anglican",
  "eastern-orthodox",
  "evangelical",
  "none-denominational",
  "",
];

export const CONTRIBUTION_FREQUENCY = (denomination) => {
  switch (denomination) {
    case "eastern-orthodox":
      return [
        {
          value: "one_time",
          label: "public.request.step2.frequency.eastern-orthodox.one_time",
          valueNumber: 1,
        },
        {
          value: "daily",
          label: "public.request.step2.frequency.eastern-orthodox.daily_1_week",
          valueNumber: 7,
        },
        {
          value: "weekly",
          label:
            "public.request.step2.frequency.eastern-orthodox.weekly_1_month",
          valueNumber: 4,
        },
        {
          value: "monthly",
          label:
            "public.request.step2.frequency.eastern-orthodox.monthly_1_year",
          valueNumber: 12,
        },
      ];
      break;
    case "catholic":
      return [
        {
          value: "one_time",
          label: "public.request.step2.frequency.catholic.one_time",
          valueNumber: 1,
        },
        {
          value: "daily",
          label: "public.request.step2.frequency.catholic.daily_1_week",
          valueNumber: 7,
        },
        {
          value: "weekly",
          label: "public.request.step2.frequency.catholic.weekly_1_month",
          valueNumber: 4,
        },
        {
          value: "monthly",
          label: "public.request.step2.frequency.catholic.monthly_1_year",
          valueNumber: 12,
        },
      ];
      break;
    case "anglican":
      return [
        {
          value: "one_time",
          label: "public.request.step2.frequency.anglican.one_time",
          valueNumber: 1,
        },
        {
          value: "daily",
          label: "public.request.step2.frequency.anglican.daily_1_week",
          valueNumber: 7,
        },
        {
          value: "weekly",
          label: "public.request.step2.frequency.anglican.weekly_1_month",
          valueNumber: 4,
        },
        {
          value: "monthly",
          label: "public.request.step2.frequency.anglican.monthly_1_year",
          valueNumber: 12,
        },
      ];
      break;
    default:
      return [
        {
          value: "one_time",
          label: "public.request.step2.frequency.other.one_time",
          valueNumber: 1,
        },
        {
          value: "daily",
          label: "public.request.step2.frequency.other.daily_1_week",
          valueNumber: 7,
        },
        {
          value: "weekly",
          label: "public.request.step2.frequency.other.weekly_1_month",
          valueNumber: 4,
        },
        {
          value: "monthly",
          label: "public.request.step2.frequency.other.monthly_1_year",
          valueNumber: 12,
        },
      ];
      break;
  }
};

export const PRICE_OF_DENOMINATION = (denomination) => {
  switch (denomination) {
    case "eastern-orthodox":
      return [
        {
          value: "single_prayer",
          price: 6,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.eastern-orthodox.single_prayer",
        },
        {
          value: "with_candle",
          price: 10,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.eastern-orthodox.with_candle",
        },
        {
          value: "with_candle_increase",
          price: 14,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.eastern-orthodox.with_candle_increase",
        },
        {
          value: "prayer_rope",
          price: 28,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.eastern-orthodox.prayer_rope",
        },
      ];

    case "catholic":
      return [
        {
          value: "single_prayer",
          price: 6,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.catholic.single_prayer",
        },
        {
          value: "with_candle",
          price: 10,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.catholic.with_candle",
        },
        {
          value: "with_candle_increase",
          price: 14,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.catholic.with_candle_increase",
        },
        {
          value: "holy_rosary",
          price: 28,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.catholic.holy_rosary",
        },
      ];
    case "anglican":
      return [
        {
          value: "single_prayer",
          price: 6,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.anglican.single_prayer",
        },
        {
          value: "with_candle",
          price: 10,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.anglican.with_candle",
        },
        {
          value: "with_candle_increase",
          price: 14,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.anglican.with_candle_increase",
        },
        {
          value: "prayer_rope",
          price: 28,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.anglican.prayer_rope",
        },
      ];

    default:
      return [
        {
          value: "single_prayer",
          price: 6,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.other.single_prayer",
        },
        {
          value: "with_candle",
          price: 10,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.other.with_candle",
        },
        {
          value: "with_candle_increase",
          price: 14,
          unit: "common.price.unit.dollar",
          description:
            "public.request.step2.pricing.description.other.with_candle_increase",
        },
      ];

      break;
  }
};

export const CONTRIBUTION_FREQUENCY_FOR_VALIDATE = _.uniq(
  _.flatten(
    RELIGION_IDS.map((item) =>
      CONTRIBUTION_FREQUENCY(item).map((fre) => fre.value)
    )
  )
);

export const PRICE_DENOMINATION_FOR_VALIDATE = _.uniq(
  _.flatten(
    RELIGION_IDS.map((item) =>
      PRICE_OF_DENOMINATION(item).map((pri) => pri.price)
    )
  )
);
