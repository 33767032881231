import React, { Component } from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Formik } from "formik"
import ErrorMessageWrapper from "@iso/Pages/Common/ErrorMessageWrapper"
import { CreateAdminPasswordValidator } from "./validator"
import "react-intl-tel-input/dist/main.css"
import "./styles.scss" 
import { useDispatch } from "react-redux"
import RegistrationBox from "@iso/Pages/Common/RegistrationBox/RegistrationBox" 
import AdminsService from "@iso/services/AdminsService";
import AuthService from "@iso/services/AuthService" 
import { userRoleConstants } from "@iso/constants/user"
import * as qs from "query-string"
import { useLocation } from "react-router"
import alertActions from "@iso/actions/Alert"
import { setCurrentUser } from "@iso/actions/User" 
import "react-datepicker/dist/react-datepicker.css"
import "rc-time-picker/assets/index.css" 
import IntlMessage from "@iso/components/intlMessage";
import {SUPER_ADMIN_ROLE, ADMIN_ROLE } from "./constants"
import {translate} from '@iso/utils/Translate';
const { openAlert } = alertActions


const CreateAdminPassword = (props) => {
  const [initValue, setInitValue] = React.useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    role: "",
    passwordConfirmation: "",
    user_id: "",
  })

  const [isLoad, setIsLoad] = React.useState(false)

  const dispatch = useDispatch()

  const location = useLocation()
  const { activation_code, user_id } = qs.parse(location.search)

  React.useEffect(() => {
    if (activation_code && user_id) checkActivation(activation_code, user_id)
    else window.location = "/admin/sign_in"
  }, [activation_code, user_id])

  const checkActivation = (activation_code, user_id, bag) => {
    if (!activation_code) {
        window.location = "/admin/sign_in"
    }
    try {
      AdminsService.checkActivationCode(user_id, activation_code).then((res) => {
        if (!res) {
          window.location = "/admin/sign_in"
        }
        const { code, data } = res.data
        if (code === 200) {
          handleInitValues(data.user, data.activation_code)
        }
      })
    } catch (err) {
        window.location = "/admin/sign_in"
      return
    }
  }

  const handleInitValues = (user, activation_code) => {
    initValue.email = user.email
    initValue.first_name = user.first_name
    initValue.last_name = user.last_name
    initValue.role = user.role === SUPER_ADMIN_ROLE ? translate("admin.create.super.admin.role") 
    : translate("admin.create.admin.role")
    initValue.user_id = user.id
    initValue.token = activation_code 
    setInitValue(initValue)
    setIsLoad(true)
  }

  const handleValueChange = (name, value) => {
    initValue[name] = value
    setInitValue(initValue)
  }

  const handleSubmit = (values, bag) => {  
    try {
      AdminsService.register({
        ...values, 
      }).then((res) => {
        if (!res) {
          bag.setErrors({ password: "Incorrect information!" })
          bag.setSubmitting(false)
        }

        const { code, data } = res.data
        if (code === 200) {
           
          //do login
          AuthService.loginApi(values)
            .then((res) => {
              if (!res) {
                bag.setErrors({ password: "Incorrect login information!" })
                bag.setSubmitting(false)
              }
              const { code, data } = res.data
              if (code === 200 && (data.role === userRoleConstants.ADMIN_ROLE ||
                data.role === userRoleConstants.SUPER_ADMIN_ROLE)) {
                dispatch(
                  openAlert({
                    message: " Account successfully created!",
                    severity: "success",
                  })
                )
                dispatch(setCurrentUser({ ...data, rememberMe: 0 }))
              } else {
                bag.setErrors({ password: "Incorrect login information!" })
                bag.setSubmitting(false)
              }
            })
            .catch((res) => {
              bag.setErrors({ password: "Incorrect login information!" })
              bag.setSubmitting(false)
            })
        }
        else {
          let errors = res.data.errors
          if (errors) {
            bag.setErrors(errors)
            bag.setSubmitting(false)
          }
        }
      })
    } catch (err) {
      bag.setErrors({ password: "Incorrect information!" })
      bag.setSubmitting(false)
      return
    }
  }

  const renderCrtAdminPasswordForm = () => {
    return (
      <>
      {
        isLoad && (
          <Formik
            initialValues={initValue}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={CreateAdminPasswordValidator}
          >
            {({
              setFieldValue, 
              values,
              handleSubmit, 
              touched,
              errors,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                  {/* Email */}
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label><IntlMessage id = "common.email"/></Form.Label>
                    <Form.Control
                      name="email"
                      type="text"
                      value={values.email}
                      disabled={true}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="email"
                    />
                  </Col>
                </Form.Group>
                {/* First name */}
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label><IntlMessage id = "common.first_name"/></Form.Label>
                    <Form.Control
                      name="first_name"
                      type="text"
                      value={values.first_name}
                      onChange={(event) => {
                        setFieldValue("first_name", event.target.value)
                        handleValueChange("first_name", event.target.value)
                      }}
                      placeholder="First name"
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="first_name"
                    />
                  </Col>
                </Form.Group>
                {/* Last name */}
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label><IntlMessage id = "common.last_name"/></Form.Label>
                    <Form.Control
                      name="last_name"
                      type="text"
                      value={values.last_name}
                      placeholder="Last name"
                      onChange={(event) => {
                        setFieldValue("last_name", event.target.value)
                        handleValueChange("last_name", event.target.value)
                      }}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="last_name"
                    />
                  </Col>
                </Form.Group>
                {/* Role */}
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label><IntlMessage id = "admin.AdminsTab.header_table.role"/></Form.Label>
                    <Form.Control
                      name="role"
                      type="text"
                      value={values.role} 
                      readOnly
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="role"
                    />
                  </Col>
                </Form.Group>
                {/* Password */}
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label><IntlMessage id = "admin.sign_in.password.label"/></Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      placeholder="Create password"
                      onChange={(event) => {
                        setFieldValue("password", event.target.value)
                        handleValueChange("password", event.target.value)
                      }}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="password"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: "8", offset: "2" }}>
                    <Form.Label><IntlMessage id = "admin.create.admin.acount.password.confirmmation"/></Form.Label>
                    <Form.Control
                      name="passwordConfirmation"
                      type="password"
                      placeholder="Re-enter password"
                      onChange={(event) => {
                        setFieldValue(
                          "passwordConfirmation",
                          event.target.value
                        )

                        handleValueChange(
                          "passwordConfirmation",
                          event.target.value
                        )
                      }}
                    />
                    <ErrorMessageWrapper
                      touched={touched}
                      errors={errors}
                      fieldName="passwordConfirmation"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                  <Col sm="12" className="text-center">
                    <Button className="hippie-blue-button" type="submit">
                        <IntlMessage id = "admin.create.admin.create.account"/> 
                    </Button>{" "}
                  </Col>
                </Form.Group>
              </form>
            )}
          </Formik>
        )
        
      }
      </>
    )
  }

  return (
    <div className="prayer-portal sign-up-container">
      <RegistrationBox
        title={<IntlMessage id ="admin.create.admin.account" />}
        form={renderCrtAdminPasswordForm()}
      />
    </div>
  )
}

export default CreateAdminPassword
