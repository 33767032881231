import { Typography } from "@material-ui/core";
import React from "react";
import FAQHeaderClouds from "./components/headerClouds";
import FAQAccordions from "./FAQAccordions";
import "./styles.scss";

const FAQPage = (props) => {
  return (
    <>
      <div className={`container-full-width bg_blueSky`}>
        <div className="container-fixed">
          <FAQHeaderClouds></FAQHeaderClouds>
        </div>
      </div>
      <p className="faq__title">FAQ's</p>
      <FAQAccordions></FAQAccordions>
    </>
  );
};

export default FAQPage;
