import React from "react"
import { isBrowser, isMobile, isTablet } from "react-device-detect"
import styled from "styled-components"

const Wrapper = styled.div`
    padding: 50px 0 50px 0;


    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0 13px 70px 13px;
    }

    @media (min-width: 1025px) {
        padding: 0 104px 67px 104px;
    }
`

const VideoContainer = styled.div`
    width: 100%;
    height: 648px;

    > iframe {
        width: 100%;
        height: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        height: 417px;
    }

    @media (min-width: 1025px) {
        height: 535px;
    }
`


export default function SectionVideo() {

    console.log("isMobile", isMobile)
    console.log("isTablet", isTablet)

    return (
        <Wrapper>
            <VideoContainer >
                <iframe src={(isBrowser || isTablet) ? "https://player.vimeo.com/video/803930092?h=c398d87bc0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" : "https://player.vimeo.com/video/803139461?h=1a1806b050&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    title="PrayerCloud - How it Works">
                </iframe>
            </VideoContainer>
        </Wrapper>
    )
}