import * as Yup from "yup";

const ResetPasswordFormValidator = Yup.object().shape({
  password: Yup.string()
    .label("Password")
    .matches(/^.{8,}$/, "Your password must have at least 8 characters.")
    .matches(/(^[\S]*$)/, "Password must not contain space.")
    .matches(
      /(?=.*[A-Z])/,
      "Contain at least 1 uppercase (i.e. A, B, C, D, etc.)"
    )
    .matches(
      /(?=.*[a-z])/,
      "Contain at least 1 lowercase (i.e. a, b, c, d, etc.)"
    )
    .matches(
      /(?=.*\d)/,
      "Contain at least 1 number digit (i.e. 1, 2, 3, 4, etc.)"
    )
    .matches(
      /(?=.*\W)/,
      "Contain at least 1 special character (i.e. $, #, @, !, etc.)"
    )
    .required(),
  passwordConfirmation: Yup.string()
    .label("Password Confirmation")
    .oneOf(
      [Yup.ref("password"), null],
      "Passwords does not match! Please try again."
    )
    .required(),
});

export { ResetPasswordFormValidator };
