import React, { Component, useState } from "react"
import Section1 from "@iso/Website/Pages/LandingPage/components/Section1/index"

const HomeLogin = (props) => {
    return (
      <>
      <Section1 > </Section1>
      </>
    )
}

export default HomeLogin
