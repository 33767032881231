import { Col, Row } from "react-bootstrap";
import React from "react";
import moment from "moment";
import IntlMessage from "@iso/components/intlMessage";
import { STATUS_LABEL } from "@iso/components/Request/NotificationLog";
import ArrowForward from '@iso/assets/images/account_prayers_arrow.svg'

const ChildRequestTablet = ({
  parentRequest,
  childRequest,
  setDetailsPage,
  classes
}) => {
  const time_zone = -(new Date().getTimezoneOffset() / 60);
  const isShowNotificationStatus =
    childRequest.client_confirmation_notification ||
    childRequest.gift_confirmation_notification ||
    childRequest.complete_notification;

  const getStatusClass = () => {
    return childRequest.status === "overdue_done_approved" ||
      childRequest.status === "approved"
      ? classes.completedColor
      : classes.inProgressColor;
  };
  const getStatusLabel = () => {
    return childRequest.status === "overdue_done_approved" ||
      childRequest.status === "approved"
      ? "Completed"
      : "In-progress";
  };
  const getNotificationStatusClass = () => {
    return childRequest.is_opened_email ? classes.opened : classes.unopened;
  };
  const getNotificationStatusLabel = () => {
    return childRequest.is_opened_email
      ? STATUS_LABEL.viewed
      : STATUS_LABEL.send_unopenned;
  };

  return (
    <Row>
      <Col xs={parentRequest.frequency !== "one_time" ? "4" : "6"}>
        {moment
          .utc(childRequest.end_date)
          .utcOffset(time_zone)
          .format("MMM DD, YYYY")}
      </Col>
      {parentRequest.frequency !== "one_time" && (
        <>
          <Col xs="4" style={{ 'white-space': "nowrap" }}>
            <span className={`${classes.subDetailTitle}`}>
              <IntlMessage id="user.account.request.status" />
            </span>
            {
              getStatusLabel() !== 'Completed' && (
                <span className={`${getStatusClass()}`} style={{ fontSize: '16px' }}>{getStatusLabel()}</span>
              )
            }
            {isShowNotificationStatus && getStatusLabel() !== 'In-progress' && getStatusLabel() !== 'Completed' && (
              <span className={`${getStatusClass}`} style={{ fontSize: '16px' }}>&nbsp;/&nbsp;<br></br></span>
            )}
            {isShowNotificationStatus && getStatusLabel() !== 'In-progress' && (
              <span className={`${getNotificationStatusClass()}`} style={{ fontSize: '16px' }}>
                {getNotificationStatusLabel()}
              </span>
            )}
          </Col>
        </>
      )}
      <Col
        xs={parentRequest.frequency !== "one_time" ? "4" : "6"}
        className={parentRequest.frequency === "one_time" ? "custom-note" : ""}
      >
        <span
          className={`${classes.viewDetailsTitle}`}
          onClick={() => setDetailsPage(childRequest.id)}
        >
          <span>
            <IntlMessage id="user.account.request.note.mobile" />{" "}
            <img src={ArrowForward} className="ml-2"></img>
          </span>
        </span>
      </Col>
    </Row>
  );
};

export default ChildRequestTablet;
