import React, { Component, useEffect, useState } from "react"
import { Modal } from "react-bootstrap" 
import AddForm from "./AddForm"
import IntlMessage from "@iso/components/intlMessage"
import "./styles.scss" 
const AddModal = (props) => { 
  const { closeModal, showModal } = props 
  const handleClose = () => {
    closeModal()
  }
  return (
    <div>
      <Modal show={ showModal } 
      onHide={ handleClose } className ="modal-container">
        <Modal.Header closeButton>
          <Modal.Title className = " mt-5 w-100 text-center font-weight-bold">
            <IntlMessage id="admin.new.admin.add" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddForm handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddModal
