export const DATE_FORMAT_REPORT_REQUEST = "MMMM Do, YYYY"; 

export const STATUS_REPORTS = [
    { id: 'unassigned', label: 'Pending Acceptance'},
    { id: 'incomplete', label: 'Incomplete'},
    { id: 'declined', label: 'Declined'},
    { id: 'paused', label: 'Paused'}, 
    { id: 'overdue', label: 'Overdue'},
    { id: 'overdue_done', label: 'Pending Approval'},
    { id: "overdue_done_approved", label: "Completed" }, 
    { id: "approved", label: "Completed" },
    { id: "completed", label: "Pending Approval" }, 
  ];