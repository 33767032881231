import React from "react";
import IntlMessage from "@iso/components/intlMessage";
import RequestService from "@iso/services/RequestService";
import { store } from "@iso/store/root";
import { history } from "@iso/helpers";
import alertActions from "@iso/actions/Alert";
const { openAlert } = alertActions;

const handleTogglePauseRequest = ({
  requestValue,
  setRequestValue,
  setToggleConfirmPauseRequest,
  handleShowChangeAssigneeModal,
  flag_types,
}) => {
  try {
    let status = requestValue.status == "paused" ? "unpause" : "paused";

    RequestService.adminTogglePauseRequest({
      id: requestValue.id,
      status,
      flag_types,
    }).then((res) => {
      const { code, data, message } = res.data;
      if (code === 200 && data) {
        if (data.status == 'overdue') {
          handleShowChangeAssigneeModal(data)
        }
        else {
          handleShowChangeAssigneeModal(data)
          setRequestValue(data);
          store.dispatch(
            openAlert({
              message: <IntlMessage id="common.message.update.success" />,
              severity: "success",
            })
          );
        }
      } else {
        store.dispatch(
          openAlert({
            message: <IntlMessage id="common.message.update.fail" />,
            severity: "error",
          })
        );
      }
    });
  } catch (error) {
    store.dispatch(
      openAlert({
        message: <IntlMessage id="common.message.update.fail" />,
        severity: "error",
      })
    );
  } finally {
    setToggleConfirmPauseRequest(false);
  }
};

const handleDeleteRequest = ({ requestValue, setOpenConfirmDeleteRequest }) => {
  try {
    RequestService.adminDeleteRequest({
      id: requestValue.id,
    }).then((res) => {
      const { code, data, message } = res.data;
      if (code === 200) {
        store.dispatch(
          openAlert({
            message: <IntlMessage id="common.message.delete.success" />,
            severity: "success",
          })
        );
        history.push("/admin");
      } else {
        store.dispatch(
          openAlert({
            message: <IntlMessage id="common.message.update.fail" />,
            severity: "error",
          })
        );
      }
    });
  } catch (error) {
    store.dispatch(
      openAlert({
        message: <IntlMessage id="common.message.update.fail" />,
        severity: "error",
      })
    );
  } finally {
    setOpenConfirmDeleteRequest(false);
  }
};

const handleArchiveRequest = ({ requestValue, setOpenConfirmArchiveRequest }) => {
  try {
    RequestService.adminArchiveRequest({
      id: requestValue.id,
    }).then((res) => {
      const { code, data, message } = res.data;
      if (code === 200) {
        store.dispatch(
          openAlert({
            message: <IntlMessage id="common.message.archive.success" />,
            severity: "success",
          })
        );
        history.push("/admin");
      } else {
        store.dispatch(
          openAlert({
            message: <IntlMessage id="common.message.update.fail" />,
            severity: "error",
          })
        );
      }
    });
  } catch (error) {
    store.dispatch(
      openAlert({
        message: <IntlMessage id="common.message.update.fail" />,
        severity: "error",
      })
    );
  } finally {
    setOpenConfirmArchiveRequest(false);
  }
};

const handleChangeAssignee = ({
  values,
  isAutoAssign = false,
  request,
  setShowChangeAssigneeModal,
  setRequestValue,
}) => {
  try {
    RequestService.adminChangeAssignee(request.id, values.prayer_partner, isAutoAssign).then(
      (res) => {
        const { code, data, message } = res.data;
        if (code === 200 && data) {
          console.log('KETQUA')
          console.log(data)
          console.log(setRequestValue ? 'OKEKEEKKE' : 'VLLLL')
          setRequestValue(data);
          store.dispatch(
            openAlert({
              message: (
                <IntlMessage id="admin.requests.details.change_assignee.success" />
              ),
              severity: "success",
            })
          );
        } else {
          store.dispatch(
            openAlert({
              message: <IntlMessage id="common.message.update.fail" />,
              severity: "error",
            })
          );
        }
      }
    );
  } catch (error) {
    store.dispatch(
      openAlert({
        message: <IntlMessage id="common.message.update.fail" />,
        severity: "error",
      })
    );
  } finally {
    setShowChangeAssigneeModal(false);
  }
};

export default {
  handleTogglePauseRequest,
  handleChangeAssignee,
  handleDeleteRequest,
	handleArchiveRequest,
};
