import React, { useEffect } from "react";
import RequestPrayerSeperator from "./components/RequestPrayerSeperator";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import {isMobile} from 'react-device-detect'
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";
import Section7 from "./components/Section7";
import Section8 from "./components/Section8";
import unsetHeight from '@iso/utils/unsetHeight';
import SectionVideo from "./components/SectionVideo";

const LandingPage = (props) => {

    useEffect(() => {
        unsetHeight()
    }, [])

    return (
      <>
        <div className={`container-full-width bg_blueSky-with-margin`}>
          <div className="container-fixed">
            <Section1></Section1>
          </div>
        </div>
      <Section2></Section2>
      <SectionVideo></SectionVideo>
      <RequestPrayerSeperator withCloud={!isMobile}></RequestPrayerSeperator>
      <Section3></Section3>
      <RequestPrayerSeperator withCloud={false} style={isMobile ? {marginTop: '50px'} : {marginTop: '180px'}} className="d-md-none custom_btn_section_4"></RequestPrayerSeperator>
      <Section4></Section4>
      <RequestPrayerSeperator withCloud={false} className="section4-btn-prayer-request" style={isMobile ? {marginBottom: '30px', marginTop: '50px'} : {marginBottom: '130px', marginTop: '45px'}}></RequestPrayerSeperator>
        <div className={`container-full-width bg-blue-sky-half`}>
          <div className="container-fixed">
            <Section5></Section5>
          </div>
        </div>
      <Section6></Section6>
        <div className={`container-full-width bg_blueSky`}>
          <div className="container-fixed">
            <Section7></Section7>
          </div>
        </div>
      <Section8></Section8>
      </>
    );
}

export default LandingPage;
