import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FAQAccordionStyle from "./AccordionStyle";
import { history } from '@iso/helpers';
import { useSelector,useDispatch } from "react-redux";
import actionsSignIn from "@iso/actions/signinmodal";
import ButtonMailTo from "./buttonMailTo";

const ManageAndBillingFAQ = (props) => {
  const { handleChange, expanded } = props;
  const classes = FAQAccordionStyle();
  const { user } = useSelector((state) => state.authentication)
  const dispatch = useDispatch();

  const handleMyPrayer = () => {
    if(!user) {
      dispatch(
        actionsSignIn.openModal({
          type: actionsSignIn.SHOW_MODAL,
        })
      );
    }else {
      history.push("/account?tab=3")
    }
  }

  const handleRequestAPrayer = () => { 
    history.push("/requests/step1")
  }

  return (
    <>
      <Accordion
        className={`${classes.accordion} ${classes.expanded}`}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          className={classes.headingContainer}
          expandIcon={
            <ExpandMoreIcon style={{ fill: "#AFDAF5" }} fontSize="large" />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            Manage Prayers {`&`} Billings
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            Where are my request confirmation and prayer completion emails? I never received them. 
          </Typography>
          <Typography className={`${classes.bodyText} `}>
            Please check your spam folder if you requested a prayer and did not receive a confirmation email. A confirmation email is sent to you immediately after we receive your request.<br></br><br></br>
            Once your prayer has been made, you will receive a second email confirming the completion of your prayer along with a personal message from your Prayer Partner.<br></br><br></br>
            If you find emails from PrayerCloud in your spam folder, please mark them as ‘not spam’ and add&nbsp;
            <ButtonMailTo
              label="prayers@prayercloud.com"
              mailTo="mailto:prayers@prayercloud.com" 
            />
            &nbsp;to your contacts to ensure that you receive all future correspondence.
          </Typography>
          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            Do I have pending prayers on my account?
          </Typography>
          <Typography className={`${classes.bodyText} `}>
            Visit <span className="text-hippie-blue hover_pointer" onClick={handleMyPrayer}>My Prayers</span> in the
            account section, then click on the prayer details button to see
            whether it has been completed or is pending.
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}  mt-24px`}
          >
            How do I purchase a recurring prayer?
          </Typography>
          <Typography className={`${classes.bodyText}`}>
            When you <span className="text-hippie-blue hover_pointer" onClick={handleRequestAPrayer}>Request A Prayer</span>
            , you'll have the option to choose a recurring schedule for your
            selected prayer.
          </Typography>

          <Typography
            className={`${classes.bodyText} ${classes.bold} ${classes.sectionTitle}`}
          >
            Am I going to be billed again?
          </Typography>
          <Typography className={`${classes.bodyText} `}>
            No. All prayer requests are billed in full when your order is
            complete.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ManageAndBillingFAQ;
