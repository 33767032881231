import LandingPage from "@iso/Website/Pages/LandingPage/index"
import FAQPage from "../Pages/FAQ"
import TermPage from "../Pages/Terms/Index"
import ResetPassword from "@iso/Website/Pages/CreateNewPassword/ResetPassword"
import AboutPage from "@iso/Website/Pages/About/index"
import PricingPage from "@iso/Website/Pages/PricingPage/index"
import PrayerPartner from "@iso/Website/Pages/PrayerPartner/index"
import VerifyUser from "@iso/Website/Pages/VerifyUser/VerifyUser";

export default {
  Landing: {
    component: LandingPage,
    path: "/", 
  },
  FAQ: {
    component: FAQPage,
    path: "/faq", 
  },
  Terms: {
    component: TermPage,
    path: "/terms",
    hasFooter: false,
  },
  CreateNewPassword: {
    component: ResetPassword,
    path: "/user/reset_password",
    hasFooter: false,
  },
  AboutPage: {
    component: AboutPage,
    path: "/about",
    isAboutPage: true,
  },
  VerifyUser: {
    component: VerifyUser,
    path: "/users/verify",
  },
  UpdateEmailAddress: {
    component: LandingPage,
    path: "/prayer-request/update-recipient-email", 
  },
  PricingPage: {
    component: PricingPage,
    path: "/contributions",
    isNoPadding: true,
  },
  PrayerPartner: {
    component: PrayerPartner,
    path: "/prayer-partners",
    isNoPadding: true,
  },
}
